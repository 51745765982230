import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard'
import {getCSSVariableValue} from '../assets/ts/_utils'
import {WithChildren} from '../helpers'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UserProfilePage = lazy(() => import('../pages/user-profile'))
  const Devices = lazy(() => import('../pages/devices'))
  const Offenders = lazy(() => import('../pages/inmates/Inmates'))
  const Alerts = lazy(() => import('../pages/alerts/Alerts'))
  const Supervisors = lazy(() => import('../pages/supervisors/Supervisors'))
  const Facility = lazy(() => import('../pages/facility/Facility'))
  const LiveTrack = lazy(() => import('../pages/live-track'))
  const FacilityReplay = lazy(() => import('../pages/facility-replay'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registrtion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}

        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='profile/:id/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='user-profile/:id'
          element={
            <SuspensedView>
              <UserProfilePage />
            </SuspensedView>
          }
        />

        {/* OFFENDER MANAGEMNT ROUTING STARTS */}

        <Route
          path='inmates/*'
          element={
            <SuspensedView>
              <Offenders />
            </SuspensedView>
          }
        />

        <Route
          path='devices/:id?'
          element={
            <SuspensedView>
              <Devices />
            </SuspensedView>
          }
        />

        <Route
          path='supervisors/:id?'
          element={
            <SuspensedView>
              <Supervisors />
            </SuspensedView>
          }
        />
        <Route
          path='alerts/:id?'
          element={
            <SuspensedView>
              <Alerts />
            </SuspensedView>
          }
        />
        <Route
          path='facility/*'
          element={
            <SuspensedView>
              <Facility />
            </SuspensedView>
          }
        />
        <Route
          path='live-track/*'
          element={
            <SuspensedView>
              <LiveTrack />
            </SuspensedView>
          }
        />
        <Route
          path='facility-replay/*'
          element={
            <SuspensedView>
              <FacilityReplay />
            </SuspensedView>
          }
        />

        {/* OFFENDER MANAGEMNT ROUTING ENDS */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
