import {z} from 'zod'

/** Schema check for env variables. Can use zod to further validate or just check that the variable exists. */
const envSchema = {
  REACT_APP_BASE_LAYOUT_CONFIG_KEY: z.string().trim().nonempty(),
  REACT_APP_API_URL: z.string().trim().nonempty(),
  REACT_APP_VERSION: z.string().trim().nonempty(),
  REACT_APP_SOCKET_URL: z.string().trim().nonempty(),

  REACT_APP_SASS_PATH: z.string().trim().nonempty(),
  REACT_APP_SASS_VARIABLES_PATH: z.string().trim().nonempty(),

  REACT_APP_BOOTSTRAP_DOCS_LINK: z.string().trim().nonempty(),

  REACT_APP_THEME_NAME: z.string().trim().nonempty(),
  REACT_APP_THEME_DEMO: z.string().trim().nonempty(),
  REACT_APP_THEME_API_URL: z.string().trim().nonempty(),

  REACT_APP_PURCHASE_URL: z.string().trim().nonempty(),
  REACT_APP_PREVIEW_URL: z.string().trim().nonempty(),
  REACT_APP_PREVIEW_REACT_URL: z.string().trim().nonempty(),
  REACT_APP_PREVIEW_DOCS_URL: z.string().trim().nonempty(),

  REACT_APP_GROWTHBOOK_ACTIVE: z.string().trim().nonempty(),
  REACT_APP_GROWTHBOOK_API_HOST: z.string().trim().nonempty(),
  REACT_APP_GROWTHBOOK_CLIENT_KEY: z.string().trim().nonempty(),
}

const envVariables: {[key: string]: string | number | undefined} = {}
Object.keys(envSchema).forEach((key) => {
  envVariables[key] = process.env[key]
})

if (!!process.env.SKIP_ENV_VALIDATION === false) {
  const parsed = /** @type {MergedSafeParseReturn} */ z.object(envSchema).safeParse(envVariables) // on client we can only validate the ones that are exposed

  if (parsed.success === false) {
    console.error('❌ Invalid environment variables:', parsed.error.flatten().fieldErrors)
    throw new Error('Invalid environment variables')
  }
}
