import {createSlice} from '@reduxjs/toolkit'
import {_getSystemSettings} from './system.actions'
import {SystemSettingsType} from './system.types'
import {ApiRequestState, apiRequestStateDefault} from '../api.types'
import {addAPICases} from '../api.helpers'

export type UserState = {
  settings: ApiRequestState<SystemSettingsType>
}

const initialState: UserState = {
  settings: {...apiRequestStateDefault},
}

const system = createSlice({
  name: 'system',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addAPICases(builder, _getSystemSettings, 'settings')
  },
})

export const systemReducer = system.reducer
