/* eslint-disable jsx-a11y/anchor-is-valid */
import {useInterval} from 'react-use'
import React, {useEffect, useMemo, useState} from 'react'
import AlertDetails from '../../../alerts/AlertDetails'
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert'
import WatchOffIcon from '@mui/icons-material/WatchOff'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../../../state/store'
import RadioButtonUncheckedTwoToneIcon from '@mui/icons-material/RadioButtonUncheckedTwoTone'
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone'
import {getType} from '../../../../../helpers/general'
import './AlertsList.scss'
import {_getAlertById, _getAlerts} from '../../../../../state/V2/alerts/alerts.actions'
import {AlertPaginationRequestBody} from '../../../../../state/V2/alerts/alerts.types'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  orientationType: string
  mHeight: string
}
const AlertList: React.FC<Props> = ({className, orientationType, mHeight}) => {
  const intl = useIntl()
  const [visible, setVisible] = useState<boolean>(false)
  const selectedAlert = useSelector((state: RootState) => state.alerts.selected.data)
  const [alertWorked] = useState<boolean>(false)
  const [shouldFlash, setShouldFlash] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()
  const alerts = useSelector((state: RootState) => state.alerts.list.data.results)
  const podAssignment = useSelector((state: RootState) => state.supervisor.podAssignment)

  const params = useMemo(
    (): AlertPaginationRequestBody => ({
      limit: 200,
      page: 1,
      filters: [{field: 'workedById', operation: 'eq', value: null}],
    }),
    []
  )
  useEffect(() => {
    const fetchData = () => {
      dispatch(_getAlerts(params))
    }

    fetchData()

    const interval = setInterval(fetchData, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch, params, podAssignment])

  const getIcon = (type: string) => {
    type = type.toLowerCase()
    switch (type) {
      case 'low battery':
        return <BatteryAlertIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'low heart rate':
        return <MonitorHeartIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'band tamper':
        return <WatchOffIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'low_battery_level':
        return <BatteryAlertIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'low_heart_rate':
        return <MonitorHeartIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'band_tamper':
        return <WatchOffIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'band_offline':
        return <WatchOffIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'heart_rate_offline':
        return <WarningAmberIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />
      case 'high_heart_rate':
        return <MonitorHeartIcon className='svg-icon-1 ' style={{color: '#d87a16'}} />

      default:
        return null
    }
  }

  const getOutline = (accepted: boolean) => {
    if (accepted === true) {
      return 'rgba(0,0,0,.6)'
    } else {
      return 'rgba(216, 122, 22, .6)'
    }
  }

  useEffect(() => {
    if (alertWorked === false) {
      setShouldFlash(true)
    }
  }, [alertWorked])

  useInterval(() => {
    setShouldFlash(true)
    setTimeout(() => {
      setShouldFlash(false)
    }, 4000) // stop flashing after 4 seconds
  }, 10000) // run every 10 seconds

  return (
    <>
      <div className='card' style={{maxHeight: mHeight, overflowY: 'auto'}}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title text-dark font-16'>
            {intl.formatMessage({id: 'DASHBOARD.ALERTS'})}
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            {/* end::Menu */}
          </div>
        </div>

        <div className='card-body overflow-auto pt-0'>
          {alerts &&
            alerts.map((v) => {
              return (
                <div
                  className={`d-flex align-items-center text-capitalize mb-7 rounded p-5 ${
                    shouldFlash && !v.accepted ? 'flash' : ''
                  }`}
                  style={{
                    background: 'black',
                    cursor: 'pointer',
                    border: `solid ${getOutline(v.accepted)}`,
                  }}
                  key={v.id}
                  onClick={async () => {
                    setVisible(true)
                    await dispatch(_getAlertById(v.id))
                  }}
                >
                  {/* begin::Icon */}
                  <span className='svg-icon  me-5'>{getIcon(v.type)}</span>
                  {/* end::Icon */}
                  {/* begin::Title */}
                  <div className='flex-grow-1 me-2'>
                    <a className='text-hover-primary font-14 text-gray-800'>{getType(v.type)}</a>
                    {/* <span className='text-muted fw-semibold d-block'>Due in 2 Days</span> */}
                  </div>
                  <div className='flex-grow-1 me-2 text-end'>
                    <a className='text-hover-primary font-14 text-gray-800'>
                      {v.participant.firstName} {v.participant.lastName}
                    </a>
                    {/* <span className='text-muted fw-semibold d-block'>Due in 2 Days</span> */}
                  </div>
                  {v.accepted ? (
                    <div style={{marginLeft: 10}}>
                      <TaskAltTwoToneIcon style={{color: '#d87a16'}} />
                    </div>
                  ) : (
                    <div style={{marginLeft: 10}}>
                      <RadioButtonUncheckedTwoToneIcon style={{color: '#515159'}} />
                    </div>
                  )}
                  {/* end::Title */}
                  {/* begin::Lable */}
                  {/* <span className='fw-bold text-warning py-1'>+28%</span> */}
                  {/* end::Lable */}
                </div>
              )
            })}
        </div>
      </div>
      <AlertDetails
        alert={selectedAlert || null}
        visible={visible}
        onClose={() => {
          setVisible(false)
          // setSelectedAlert(null)
          dispatch(_getAlerts(params))
        }}
      />
    </>
  )
}

export {AlertList}
