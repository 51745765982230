import axios, {AxiosRequestConfig} from 'axios'
import {removeAuth} from '../app/modules/auth'

const aX = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const request = async (config: AxiosRequestConfig<any>) => {
  //fallback to v1 if no version is specified
  if (!/v.\//.test(config.url || '')) {
    config.url = 'v1/' + config.url
    console.warn('Falling back to API V1. please use V2 if available.')
  }
  try {
    const token: string | null = localStorage.getItem('tappToken')

    if (token !== null) {
      const parsedToken = JSON.parse(token)
      config.headers = {}
      config.headers.Authorization = `Bearer ${parsedToken.api_token}`
    }

    const {data, status} = await aX(config)
    return {...data, status}
  } catch (e: any) {
    const {message, response} = e
    if (response) {
      const {data, status} = response
      if (status === 401) {
        removeAuth()
        window.location.reload()
      }

      return {...data, status}
    }

    return {msg: process.env.NODE_ENV === 'production' ? 'Oops! Something went wrong.' : message}
  }
}

export {request}
