import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {
  _getCells,
  _getFacilities,
  _getLocationCount,
  _getLocations,
  _getPods,
} from './facility.actions'
import {CellState, FacilityState, FacilityType, LocationsState, PodState} from './facility.types'
import {has} from 'lodash'

/*--------------------- Facility ------------------------*/

const initialFacilityState: FacilityState = {
  list: [],
  facility: {},
}

const facility = createSlice({
  name: 'facility',
  initialState: initialFacilityState,
  reducers: {
    setFacility: (state, action: PayloadAction<Partial<FacilityType>>) => {
      state.facility = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(_getFacilities.fulfilled, (state, action) => {
        return {...state, error: undefined, list: action.payload.data}
      })
      .addCase(_getFacilities.rejected, (state, action) => ({
        ...state,
        error: action.error,
        list: [],
      }))
  },
})

/*--------------------- Pods ------------------------*/

const initialPodState: PodState = {
  isLoading: false,
}

const pods = createSlice({
  name: 'pods',
  initialState: initialPodState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(_getPods.pending, (state) => {
        state.isLoading = true
      })
      .addCase(_getPods.fulfilled, (state, action) => {
        state.isLoading = false
        if (has(action.meta.arg, 'facilityId')) {
          state.facilityPods = action.payload.data
        } else {
          state.list = action.payload.data
        }
        return state
      })
      .addCase(_getPods.rejected, (state, action) => ({
        ...state,
        error: action.error,
      }))
  },
})

/*--------------------- Cells ------------------------*/
const initialCellsState: CellState = {
  isLoading: false,
}

const cells = createSlice({
  name: 'cells',
  initialState: initialCellsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(_getCells.pending, (state, action) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(_getCells.fulfilled, (state, action) => ({
        ...state,
        isLading: false,
        list: action.payload.data,
      }))
      .addCase(_getCells.rejected, (state, action) => ({
        ...state,
        isLading: false,
        error: action.error,
      }))
  },
})

/*--------------------- Location ------------------------*/

const locationsInitialState: LocationsState = {
  isLoading: false,
  counts: {total: 0, locations: []},
}

const locations = createSlice({
  name: 'locations',
  initialState: locationsInitialState,
  reducers: {
    updateLocationData: (state, action: PayloadAction<{location: string; value: string}[]>) => {
      action.payload.forEach(({location, value}) => {
        const index = state.counts.locations.findIndex((res) => res.location === location)
        const change = Number(value)
        if (isNaN(change)) return

        if (index === -1) {
          if (change > 0) state.counts.locations.push({id: -1, location, count: change})
          return
        }

        state.counts.locations[index].count += change
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(_getLocations.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(_getLocations.fulfilled, (state, action) => {
      state.isLoading = false
      state.status = action.payload.status
      state.list = action.payload.data
    })
    builder.addCase(_getLocations.rejected, (state, action) => {
      state.status = 500
      state.error = action.error
    })
    builder.addCase(_getLocationCount.fulfilled, (state, action) => {
      state.isLoading = false
      state.status = action.payload.status
      state.counts = action.payload.data
    })
    builder.addCase(_getLocationCount.rejected, (state, action) => {
      state.status = 500
      state.error = action.error
    })
    // builder.addCase(_getParticipantLocations.pending, (state, action) => {
    //   state.isLoading = true
    // })
    // builder.addCase(_getParticipantLocations.fulfilled, (state, action) => {
    //   state.isLoading = false
    //   state.locationsCount = action.payload
    // })
    // builder.addCase(_getParticipantLocations.rejected, (state, action) => {
    //   state.isError = true
    // })
  },
})

export const {updateLocationData} = locations.actions

export const {setFacility} = facility.actions
export const facilityReducer = facility.reducer
export const podsReducer = pods.reducer
export const cellsReducer = cells.reducer
export const locationsReducer = locations.reducer
