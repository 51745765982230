import {FC, useMemo} from 'react'
import {useSelector} from 'react-redux'
import DonutGraph from '../../../modules/profile/components/DonutGraph/DonutGraph'
import {RootState} from '../../../../state/store'
import {useIntl} from 'react-intl'
interface IProps {
  setFilter: (filter: {location?: string; risk?: number}) => void
}
const RiskChart: FC<IProps> = ({setFilter}) => {
  const participants = useSelector((state: RootState) => state.participant.list)
  const intl = useIntl()

  //Currently dependant on entire assigned participant list being pulled down at the start.
  // If we move away from this, the risk counts will need to be requested from an api call.
  const risks = useMemo(() => {
    const risks = [0, 0, 0]
    participants.forEach(({risk}) => {
      risks[risk - 1] += 1
    })
    return risks
  }, [participants])

  return (
    <>
      {risks && (
        <div className='card mb-xl-8 mb-5'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label font-24 mb-1'>
                {intl.formatMessage({id: 'DASHBOARD.CHART'})}
              </span>
            </h3>
          </div>
          <div className='row flex-column flex-md-row justify-content-start align-items-center riskChartContainer p-7'>
            <DonutGraph setFilter={setFilter} risks={risks} />{' '}
          </div>
        </div>
      )}
    </>
  )
}
export default RiskChart
