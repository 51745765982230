import {createSlice} from '@reduxjs/toolkit'
import {_getRoles, _login} from './user.actions'
import {ApiRequestState, apiRequestStateDefault} from '../api.types'
import {addAPICases} from '../api.helpers'
import {UserClaims} from '../../../app/modules/auth'
import {UserRole} from './user.types'

export type UserState = {
  login: ApiRequestState<UserClaims>
  roles: ApiRequestState<UserRole[]>
}

const initialState: UserState = {
  login: {...apiRequestStateDefault},
  roles: {...apiRequestStateDefault},
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addAPICases(builder, _login, 'login')
    addAPICases(builder, _getRoles, 'roles')
  },
})

export const userReducer = user.reducer
