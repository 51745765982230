/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {_login} from '../../../../state/V2/user/user.actions'
import {useDispatch} from 'react-redux'
import {AppDispatch} from '../../../../state/store'
import {_getFacilities, _getPods} from '../../../../state/V2/facility/facility.actions'
import {_getUserPods} from '../../../../state/V2/supervisor/supervisor.actions'
import {isApiError} from '../../../../state/V2/api.helpers'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .strict()
    .lowercase()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const dispatch = useDispatch<AppDispatch>()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setFieldError, setSubmitting}) => {
      try {
        setLoading(true)
        const login = await dispatch(_login(values))

        if (isApiError(login)) {
          saveAuth(undefined)
          setFieldError('password', login.error.message)
          setSubmitting(false)
          setLoading(false)
          return
        }

        const claims = login.payload.data

        localStorage.setItem('currentUser', JSON.stringify(claims))

        saveAuth({api_token: claims.token})

        setCurrentUser(claims)
        dispatch(_getFacilities())
        dispatch(_getUserPods())
        dispatch(_getPods({facilityId: claims.facility?.id ?? 1}))
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        <h1 className='mb-3 text-white'>Log in to your account</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx('form-control form-control-solid', {
            'is-invalid': formik.touched.email && formik.errors.email,
          })}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container' style={{color: '#b86207'}}>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Link */}

            {/* end::Link */}
          </div>
        </div>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx('form-control form-control-solid', {
            'is-invalid': formik.touched.password && formik.errors.password,
          })}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-orange btn-height d-flex align-items-center justify-content-center w-100 mb-10'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label text-white'>Login</span>}
          {loading && (
            <span className='indicator-progress text-white' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm ms-2 align-middle'></span>
            </span>
          )}
        </button>
        <Link to='/auth/forgot-password' className='text-orange fs-6' style={{marginLeft: '5px'}}>
          Forgot Password ?
        </Link>
      </div>
      {/* end::Action */}
    </form>
  )
}
