import {Button, DialogActions, DialogTitle, Popper} from '@mui/material'
import {RefObject, useEffect, useRef, useState} from 'react'

export let globalAudio: RefObject<HTMLAudioElement>
export let playAudio: (src: string) => void

const AudioPlayer = () => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [showAllowAudio, setShowAllowAudio] = useState(false)
  const [allowAudio, setAllowAudio] = useState(false)

  //externalizing playAudio and audioRef to be used by other components
  globalAudio = audioRef
  playAudio = async (src: string) => {
    const audio = audioRef.current
    if (!audio) return

    const triggerAudio = async () => {
      try {
        await audio.play()
      } catch (e) {
        if ((e as {code: number}).code !== 0) {
          console.error('AudioError:', e)
          return
        } 
        setAllowAudio(false)
        setShowAllowAudio(true)
      }
    }

    audio.src = src
    if (audio.readyState === 4) triggerAudio()
    else audio.oncanplaythrough = triggerAudio
  }

  useEffect(() => {
    const audio = audioRef.current
    if (!audio) return

    audio.addEventListener('error', (e) => console.error(e))
    if (!allowAudio) {
      /**
       * Browsers have restrictions that audio cannot be play unless triggered by user interaction (click). https://developer.chrome.com/blog/autoplay/
       * This Captures first click event to unlock the audio element by playing it once with no source.
       * From that point on the audio element is fully unlocked and can be triggered via js code
       **/
      const prepAudio = async () => {
        await audio.play()
        audio.pause()
        setAllowAudio(true)
        document.removeEventListener('click', prepAudio)
      }

      //   setTimeout(triggerAudio, 2000)
      document.addEventListener('click', prepAudio)

      return () => {
        document.removeEventListener('click', prepAudio)
        document.removeEventListener('touchstart', prepAudio)
      }
    }
  }, [allowAudio])

  //hide the allow audio after 6 seconds (because the trigger for it is likely already complete)
  useEffect(() => {
    if (!showAllowAudio) return
    const hideAllowAudio = () => {
      setShowAllowAudio(false)
    }
    const timeout = setTimeout(hideAllowAudio, 6000)

    return () => {
      clearTimeout(timeout)
    }
  }, [showAllowAudio])

  return (
    <div
      id='globalAudioContainer'
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: 1,
      }}
    >
      <audio ref={audioRef}></audio>

      <Popper
        open={showAllowAudio}
        anchorEl={document.querySelector('#globalAudioContainer')}
        style={{zIndex: 1000, backgroundColor: 'black', cursor: 'pointer'}}
        onClick={(event) => {
          setAllowAudio(true)
          audioRef.current?.play()
          setShowAllowAudio(false)
          event.stopPropagation()
        }}
      >
        <div style={{display: 'flex'}}>
          <DialogTitle>Allow Audio?</DialogTitle>
          <DialogActions>
            <Button>Agree</Button>
          </DialogActions>
        </div>
      </Popper>
    </div>
  )
}
export default AudioPlayer
