import {createAsyncThunk} from '@reduxjs/toolkit'
import {request} from '../../../helpers/request'
import {
  SupervisorListResponse,
  UserPodType,
  UserPodsResponse,
  SupervisorType,
} from './supervisor.types'
import {ApiResponse} from '../api.types'

/*--------------------- Supervisor ------------------------*/
export interface SupervisorState {
  id?: number
  first_name?: string
  last_name?: string
  street?: string
  city?: string
  state?: string
  zip_code?: string
  email?: string
  password?: string
  phone?: string
  role_type?: string
  isEdit?: boolean
  facility_id?: number
}

export const _getSupervisors: any = createAsyncThunk('_getSupervisors', async (params) => {
  const response: SupervisorListResponse = await request({
    url: `v2/supervisor?include=updatedBy,createdBy`,
    method: 'get',
    params,
  })
  return response
})

export const _createSupervisor = createAsyncThunk(
  'supervisor/_create',
  async (data: Partial<Omit<SupervisorType, 'id'>>) => {
    const response: ApiResponse<SupervisorType> = await request({
      url: 'v2/supervisor',
      data,
      method: 'post',
    })
    return response
  }
)

export const _updateSupervisor = createAsyncThunk(
  'v2/supervisor/_update',
  async (data: Partial<SupervisorType> & {id: number}) => {
    const response: ApiResponse<SupervisorType> = await request({
      url: `v2/supervisor/${data.id}`,
      data,
      method: 'patch',
    })
    return response
  }
)

export const _getSupervisorById: any = createAsyncThunk(
  '_getSupervisorsById',
  async (id: number) => {
    const response: ApiResponse<SupervisorType> = await request({
      url: `v2/supervisor/${id}`,
      method: 'get',
    })
    return response
  }
)

/*--------------------- User Pods ------------------------*/

export const _getUserPods = createAsyncThunk('_getUserPods', async () => {
  const response: UserPodsResponse = await request({
    url: `v2/user-pod?limit=200`,
    method: 'get',
  })
  return response
})

export const _saveUserPods = createAsyncThunk(
  '_saveUserPods',
  async (data: Partial<UserPodType>) => {
    const response: UserPodsResponse = await request({
      url: `v2/user-pod`,
      method: 'put',
      data,
    })
    return response
  }
)
