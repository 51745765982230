import React, {useId} from 'react'
import './DonutGraph.scss'

export default function DonutGraph(props: any) {
  const {risks} = props
  const totalInmates = risks.reduce(
    (accumulator: string, currentValue: any) => parseInt(accumulator) + parseInt(currentValue),
    0
  )

  const coordArray: {
    coords: string
    offset: number
    color: string
    level: string | undefined
    risk: number
    count: number
  }[] = []

  const initialOffset = 0
  let totalLength = 0

  // ***
  // Because of the way the data is structured from api [null, low, high, med],
  // we need to reorder the risk array to match the order of the colors and
  // levels arrays below.
  //***
  const colors = ['#D32029', '#DB7100', '#FCF200', '#CCCCCC']
  const levels = ['HIGH RISK', 'MEDIUM RISK', 'LOW RISK', undefined]

  // The orderedRiskArray corrects the order of indexes from the api.
  const orderedRiskArray = [2, 3, 1, 0]
  // const risks = risks.map((risk: any) => risk.count)

  // finalRiskArray is the new order of the count elements after changing their indexes.
  // The logic solves for the case where the api data structure is missing any risk levels.
  const finalRiskArray = orderedRiskArray
    .filter((index: number) => risks[index] !== undefined && risks[index] !== null)
    .map((index: number) => risks[index])

  for (let i = 0; i < risks.length; i++) {
    let start = Math.ceil((finalRiskArray[i] / totalInmates) * 100)
    if (isNaN(start)) start = 0
    totalLength += start
    let duration = 100 - start
    coordArray.push({
      coords: `${start} ${duration}`,
      offset: i === 0 ? initialOffset : 100 - totalLength + start,
      color: colors[i],
      level: levels[i],
      risk: 3 - i,
      count: finalRiskArray[i],
    })
  }
  const donutId = useId()
  const riskId = useId()

  return (
    <figure className='row align-items-center'>
      <div className='figure-content col-6'>
        <svg
          width='100%'
          height='100%'
          viewBox='0 0 42 42'
          className='donut'
          aria-labelledby='risk-title risk-desc'
          role='img'
        >
          <circle cx='21' cy='21' r='15.91549430918954' fill='#000' role='presentation'></circle>
          {coordArray.map((circle: any) => (
            <circle
              cx='21'
              cy='21'
              r='15.91549430918954'
              fill='transparent'
              stroke={circle.color}
              strokeWidth='3'
              strokeDasharray={circle.coords}
              strokeDashoffset={circle.offset}
              key={donutId + circle.level}
            >
              <title className='text-capitalize'>{circle.level}</title>
              <desc className='text-capitalize'>{circle.level} Inmates</desc>
            </circle>
          ))}
          <g className='chart-text'>
            <text x='50%' y='50%' className='chart-number'>
              {totalInmates}
            </text>
          </g>
        </svg>
      </div>
      <figcaption className='col-6'>
        <ul
          className='figure-key-list'
          aria-hidden='true'
          role='presentation'
          style={{textAlign: 'center'}}
        >
          {coordArray.map((riskLevel) => (
            <li
              key={riskId + riskLevel.level}
              onClick={() => props.setFilter({risk: riskLevel.risk})}
              style={{cursor: 'pointer'}}
            >
              <span className='shape-square' style={{backgroundColor: riskLevel.color}}></span>
              <span>{riskLevel.level ? riskLevel.level : 'N/A'}</span>
              <span>{riskLevel.count}</span>
            </li>
          ))}
        </ul>
      </figcaption>
    </figure>
  )
}
