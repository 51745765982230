import {createSlice, current} from '@reduxjs/toolkit'
import {
  _createSupervisor,
  _getSupervisorById,
  _getUserPods,
  _getSupervisors,
  _saveUserPods,
  _updateSupervisor,
} from './supervisor.actions'
import {findIndex} from 'lodash'
import {SupervisorState} from './supervisor.types'

const initialState: SupervisorState = {
  isLoading: false,
  list: {pagination: {page: 0, limit: 0, count: 0}, results: []},
}

const supervisorSlice = createSlice({
  name: 'supervisor',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(_getSupervisors.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(_getSupervisors.fulfilled, (state, action) => {
        state.isLoading = false
        state.status = action.payload.status
        state.list = action.payload.data
      })
      .addCase(_getSupervisors.rejected, (state, action) => {
        state.status = action.payload.status
        state.error = action.error
      })
      .addCase(_createSupervisor.fulfilled, (state, action) => {
        if (action.payload.data !== undefined) {
          const results = [...current(state.list).results, action.payload.data]
          const updatedList = {...state.list, results}
          state.save = action.payload.data
          state.list = updatedList
        }
      })
      .addCase(_createSupervisor.rejected, (state, action) => ({
        ...state,
        error: action.error,
        save: undefined,
      }))
      .addCase(_updateSupervisor.fulfilled, (state, action) => {
        if (action.payload.data !== undefined) {
          const results = [...current(state.list).results, action.payload.data]
          const supervisorId = action.meta.arg.id

          const index = findIndex(results, {id: supervisorId})
          results.splice(index, 1, action.payload.data)

          const updatedList = {...state.list, results}
          state.save = action.payload.data
          state.list = updatedList
        }
      })
      .addCase(_updateSupervisor.rejected, (state, action) => ({
        ...state,
        error: action.error,
        save: undefined,
      }))
      .addCase(_getUserPods.fulfilled, (state, action) => {
        state.podAssignment = action.payload.data
      })
      .addCase(_getUserPods.rejected, (state, action) => {
        state.status = 500
        state.error = action.error
      })
      .addCase(_getSupervisorById.rejected, (state, action) => {
        state.status = 500
        state.error = action.error
      })
      .addCase(_getSupervisorById.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(_getSupervisorById.fulfilled, (state, action) => {
        state.isLoading = false
        state.supervisorInfo = action.payload.data
      })
      .addCase(_saveUserPods.rejected, (state, action) => {
        state.status = 500
        state.error = action.error
      })
      .addCase(_saveUserPods.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(_saveUserPods.fulfilled, (state, action) => {
        state.isLoading = false
        state.podAssignment = action.payload.data
      })
  },
  reducers: {},
})

export default supervisorSlice.reducer
