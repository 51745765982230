/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {Battery1BarOutlined} from '@mui/icons-material'
import {RootState} from '../../../../state/store'

type Props = {
  className: string
}

const Battery: React.FC<Props> = ({className}) => {
  const {list: participants, isLoading} = useSelector((state: RootState) => state.participant)

  const getBatteryColor = (battery: number) => {
    if (battery <= 10) {
      return 'red'
    } else return 'yellow'
  }

  return (
    <>
      {isLoading ? (
        <div> Loading... </div>
      ) : (
        <div className={`card ${className}`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label font-24 mb-1'>LOW BATTERY %</span>
            </h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body overflow-auto py-3'>
            {/* begin::Table container */}
            <div className='table-responsive text-capitalize overflow-auto'>
              {/* begin::Table */}
              <table className='table-row-dashed table-row-gray-300 gs-0 gy-4 table align-middle'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px text-gray-700'>Name</th>
                    <th className='min-w-120px text-gray-700'>Location</th>
                    <th className='min-w-120px text-gray-700'>
                      <Battery1BarOutlined style={{color: '#ffffff'}} />
                    </th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {participants.length > 0 &&
                    participants
                      .filter(
                        (participant) =>
                          participant.participantBattery !== null &&
                          (participant.participantBattery?.batteryLevel || 0) < 20
                      )
                      .map((participant) => {
                        return (
                          <tr key={participant.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <Link
                                    to={`/profile/${participant.id}/overview`}
                                    state={{id: participant.id}}
                                    className='text-light-white text-hover-primary fs-6 text-capitalize text-break'
                                  >
                                    {participant.firstName} {participant.lastName}
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex flex-column w-100 me-2'>
                                <div className='d-flex flex-stack mb-2'>
                                  <span className='text-light-white fs-7 fw-semibold me-2'>
                                    {participant.participantLocationTime?.location}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span
                                className='text-light-white fs-7 fw-semibold me-2 text-center'
                                style={{
                                  color: `${getBatteryColor(
                                    participant.participantBattery?.batteryLevel || 0
                                  )}`,
                                }}
                              >
                                {participant.participantBattery?.batteryLevel
                                  ? `${participant.participantBattery?.batteryLevel} %`
                                  : 'N/A'}
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      )}
    </>
  )
}

export default Battery
