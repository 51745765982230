import {createAsyncThunk} from '@reduxjs/toolkit'
import {request} from '../../../helpers/request'
import {SystemSettingsRequestResponse} from './system.types'

export const _getSystemSettings = createAsyncThunk('systemSettings', async () => {
  const response: SystemSettingsRequestResponse = await request({
    url: 'v2/system-settings',
    method: 'get',
  })
  return response
})
