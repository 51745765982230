/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {getSocket} from '../../../../helpers/socket'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {updateLocationData} from '../../../../state/V2/facility/facility.reducers'

import AlertDetails from '../../../../app/pages/alerts/AlertDetails'
import {useIntl} from 'react-intl'
import {Alert} from '../../../../state/V2/alerts/alerts.types'
import {alertActions} from '../../../../state/V2/alerts/alerts.reducers'
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const {config} = useLayout()

  const [visible, SetVisible] = useState<boolean>(false)
  const [alert, setAlert] = useState<Alert | null>(null)
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    const socket = getSocket()
    socket.on('registered', (data: {count: number}) => {
      dispatch(alertActions.setCount(data.count))
    })
    socket.on('alert_accepted', (data: {count: number; data: Alert}) => {
      dispatch(alertActions.setCount(data.count))
      dispatch(alertActions.updateAlert(data.data))
    })

    socket.on('locationCount', (data: {data: {location: string; value: string}[]}) => {
      dispatch(updateLocationData(data.data)) // dispatch location data update
    })
    socket.on('notify', ({count, data: alert}: {count: number; data: Alert}) => {
      toast.error(
        <div
          onClick={() => {
            setAlert(alert)
            SetVisible(true)
          }}
        >
          New Alert!{' '}
          {intl.formatMessage({
            id: `ALERT.${alert.type}`,
          })}{' '}
          for inmate {alert.participant.firstName} {alert.participant.lastName}
        </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'dark',
        }
      )
      dispatch(alertActions.addAlert(alert))
      dispatch(alertActions.setCount(count))
    })

    return () => {
      socket.off('connect')
      socket.off('disconnect')
      socket.off('registered')
      socket.off('notify')
      socket.off('locationCount')
      socket.off('location')
    }
  }, [dispatch, intl])

  return (
    <div className='d-flex align-items-stretch justify-content-flex-end flex-shrink-0'>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          style={{marginRight: 10}}
          className={clsx('symbol cursor-pointer', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <ManageAccountsIcon fontSize='large' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none me-n3 ms-2' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
      <AlertDetails
        alert={alert}
        visible={visible}
        onClose={() => {
          SetVisible(false)
          setAlert(null)
        }}
      />
    </div>
  )
}

export {Topbar}
