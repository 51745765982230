import * as React from 'react'

type Props = {
  backgroundColor?: boolean
  showLabels?: boolean
}

const FultonNorthSvg: React.FC<Props> = ({backgroundColor, showLabels=true}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='100%'
      height='100%'
      version='1.1'
      viewBox='0 0 1921.42 841.48'
      xmlSpace='preserve'
      style={backgroundColor ? {} : {filter: 'invert(1)'}}
    >
      <g id='WALLS'>
        <rect
          x={0.71}
          y={351.66}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={79.03}
          height={13.34}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 0.71,498.45 0.71,521.17 18.53,521.17 18.53,542.35 177.45,542.35 177.45,471.58 168.44,471.58 168.44,445.93 182.86,445.93  182.86,437.03 168.44,437.01 168.44,420.05 158.43,420.05 158.43,484.92 164.11,485 164.11,529 31.88,529 31.88,507.83  14.05,507.83 14.05,498.45  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 220.67,437.03 220.67,445.93 233.18,445.93 233.18,420.51 412.94,420.51 412.94,401.97 437.83,401.97 437.83,454.32 474.47,454.32  474.47,576.78 564.95,577.02 564.95,578.35 573.85,578.35 573.85,577.02 591,577.02 591,568.12 582.11,568.12 582.11,507.47  573.21,507.47 573.21,568.12 487.81,568.12 487.81,457.38 573.21,457.38 573.21,469.66 582.11,469.66 582.11,461.32 608.52,461.32  608.52,443.53 582.11,443.53 582.11,413.09 608.52,413.09 608.52,393.08 526.62,393.08 526.62,440.7 487.81,440.7 487.81,440.98  446.73,440.98 446.73,393.08 412.94,393.08 412.94,381.28 404.04,381.28 404.04,411.61 224.28,411.61 224.28,437.03  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 146.06,293.83 159.41,293.83 159.41,305.22 168.44,305.22 168.44,380.02 158.43,380.02 158.43,365 117.55,365 117.55,351.66  155.1,351.66 155.1,318.59 146.06,318.57  '
        />
        <rect
          x={146.06}
          y={114.63}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={13.34}
          height={141.39}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 165.32,0.71 165.32,55.13 146.06,55.13 146.06,76.82 159.41,76.82 159.41,68.48 178.66,68.48 178.66,14.05 182,14.05 182,0.71  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 560.5,694.01 560.5,694.01 560.5,840.77 597.78,840.77 597.78,827.42 573.84,827.42 573.85,747.59 595.25,747.59 595.25,738.69  573.85,738.69 573.85,681 560.55,681.1  '
        />
        <rect
          x={637.81}
          y={827.42}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={42.19}
          height={13.34}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 670.76,692.82 679.65,692.82 679.65,747.59 633.06,747.59 633.06,738.69 670.76,738.69  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 670.76,621.65 679.65,621.65 679.65,568.12 661.06,568.12 661.06,577.02 670.76,577.02  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 239.82,215.75 239.82,274.88 248.72,274.88 248.72,271.76 281.37,271.76 281.37,320.41 248.72,320.41 248.72,299.35 239.82,299.35  239.82,329.3 404.04,329.3 404.04,341.24 412.94,341.24 412.94,336.82 420.72,336.82 420.72,313.73 423.95,313.73 423.95,309.29  420.72,309.29 420.72,269.82 424.06,269.82 424.06,239.24 420.72,239.24 420.72,239.11 404.04,239.11 404.04,320.42 290.26,320.41  290.26,262.87 248.72,262.87 248.72,215.75  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 248.72,165.71 404.04,165.71 404.04,160.57 420.72,160.57 420.72,185.73 404.04,185.73 404.04,174.61 248.72,174.61 248.72,177.94  239.82,177.94 239.82,150.97 248.72,150.97  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 219.81,0.71 427.91,0.71 427.91,12.11 554.52,12.11 554.52,27.68 522.82,27.68 522.82,37.69 515.04,37.69 515.04,27.68  427.91,27.68 427.91,96.9 515.04,96.9 515.04,75.5 522.82,75.5 522.82,105.8 420.72,105.8 420.72,107.19 404.04,107.19  404.04,14.05 290.26,14.05 290.26,63.25 248.72,63.25 248.72,113.16 239.82,113.16 239.82,63.25 233.07,63.25 233.07,41.71  241.96,41.71 241.96,54.36 281.29,54.36 281.37,14.05 241.96,14.05 241.96,17.25 233.07,17.25 233.07,14.05 219.81,14.05  '
        />
        <rect
          x={788.64}
          y={568.12}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={10.52}
          height={8.9}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 465.1,301.35 465.1,309.29 463.99,309.29 463.99,313.73 465.1,313.73 465.1,325.69 491.61,325.69 665.51,149.45 665.51,118.31  661.07,118.31 661.07,147.63 489.75,321.24 469.55,321.24 469.55,301.35  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 625.69,12.11 1326.11,12.11 1326.11,23.51 1312.77,23.51 1312.77,23.23 1197.92,23.23 1197.92,128.32 1189.03,128.32  1189.03,23.23 1038.9,23.23 1038.9,186.01 1030,186.01 1030,183.79 882.37,183.79 882.37,193.07 873.48,193.07 873.48,160.01  882.37,160.01 882.37,174.89 1030,174.89 1030,83.56 981.39,83.56 981.39,25.45 882.37,25.45 882.37,122.2 873.48,122.2  873.48,118.31 859.02,118.31 859.02,110.53 873.48,110.53 873.48,27.68 664.26,27.68 664.26,110.53 790.07,110.53 790.07,118.31  656.48,118.31 656.48,27.68 625.69,27.68  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1312.77,67.99 1326.11,67.99 1326.11,78.83 1438.24,78.83 1438.24,94.39 1337.04,94.39 1337.04,281.88 1319.25,281.88  1319.25,94.39 1312.77,94.39  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1476.05,78.83 1476.05,94.39 1639.35,94.39 1639.35,436.06 1635.46,436.06 1635.46,453.85 1648.25,453.85 1648.25,532.05  1683.82,532.05 1683.82,559.08 1693.83,559.08 1693.83,522.04 1658.26,522.04 1658.26,92.17 1745.28,92.17 1745.28,78.83  '
        />
        <rect
          x={1763.07}
          y={78.83}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={13.34}
        />
        <rect
          x={1789.76}
          y={78.83}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={13.34}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1907.36,92.17 1907.36,427.47 1920.71,427.47 1920.71,78.83 1816.45,78.83 1816.45,92.17  '
        />
        <rect
          x={1907.36}
          y={445.26}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={13.34}
          height={9.45}
        />
        <rect
          x={1907.36}
          y={472.51}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={13.34}
          height={9.45}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1747.51,616.91 1655.78,616.91 1655.78,625.8 1646.89,625.8 1646.89,608.01 1683.82,608.01 1683.82,596.89 1693.83,596.89  1693.83,608.01 1757.52,608.01 1757.52,731.52 1797.55,731.52 1797.55,682.59 1806.45,682.59 1806.45,731.52 1907.36,731.52  1907.36,499.76 1920.71,499.76 1920.71,744.86 1760.85,744.86 1760.85,794.07 1688.33,794.07 1688.33,780.73 1747.51,780.73  '
        />
        <rect
          x={1502.5}
          y={658.05}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={29.22}
          height={8.9}
        />
        <rect
          x={1371.89}
          y={827.42}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={13.34}
        />
        <rect
          x={1345.2}
          y={827.42}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={13.34}
        />
        <rect
          x={1318.29}
          y={827.42}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={9.12}
          height={13.34}
        />
        <rect
          x={1291.6}
          y={827.42}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={13.34}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1152.61,666.95 1226.43,666.95 1254.84,694.07 1254.84,827.42 1248.6,827.42 1248.6,840.77 1273.81,840.77 1273.81,827.42  1263.74,827.42 1263.74,694.07 1292.15,666.95 1361.75,666.95 1361.75,658.05 1271.56,658.05 1271.56,666.95 1279.26,666.95  1259.29,686.42 1238.58,666.95 1244.87,666.95 1244.87,658.05 1152.61,658.05  '
        />
        <rect
          x={1220.8}
          y={827.42}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={10.01}
          height={13.34}
        />
        <rect
          x={1192.99}
          y={827.42}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={10.01}
          height={13.34}
        />
        <rect
          x={1167.2}
          y={827.42}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8}
          height={13.34}
        />
        <rect
          x={1139.39}
          y={827.42}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={10.01}
          height={13.34}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1097.84,658.05 1114.8,658.05 1114.8,666.95 1112.3,666.95 1112.3,827.42 1121.6,827.42 1121.6,840.77 1095.57,840.77  1095.57,828.54 1103.4,828.54 1103.4,666.95 1097.84,666.95  '
        />
        <rect
          x={1068.88}
          y={828.54}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={12.23}
        />
        <rect
          x={1042.19}
          y={828.54}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={12.23}
        />
        <rect
          x={1015.29}
          y={828.54}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={9.12}
          height={12.23}
        />
        <rect
          x={986.93}
          y={828.54}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={10.56}
          height={12.23}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 789.05,657.09 795.72,657.09 795.72,658.05 796.84,658.05 796.84,666.95 795.72,666.95 795.72,669.14 789.05,669.14  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 789.05,709.17 795.72,709.17 795.72,766.26 798.92,766.26 798.92,772.93 785.71,772.93 785.71,747.59 785.86,747.61 785.86,738.69  789.05,738.69  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 835.76,713.06 835.76,719.74 835.94,719.74 835.94,766.26 832.28,766.26 832.28,772.93 844.83,772.93 844.83,747.31 864.58,747.31  864.58,742.86 844.83,742.86 844.83,713.06  '
        />
        <rect
          x={836.87}
          y={658.05}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={69.01}
          height={8.9}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 784.54,827.42 947.36,827.42 947.36,747.31 926.86,747.31 926.86,742.86 947.36,742.86 947.36,666.95 943.69,666.95 943.69,658.05  947.36,658.05 947.36,656.69 960.71,656.69 960.71,658.05 961.43,658.05 961.43,666.95 960.71,666.95 960.71,686.73 980.49,666.95  979.22,666.95 979.22,658.05 1060.03,658.05 1060.03,666.95 986.78,666.95 960.71,693.02 960.71,828.54 969.13,828.54  969.13,840.77 784.54,840.77  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1543.23,402.09 1549.52,395.8 1564.04,410.32 1564.04,436.06 1597.65,436.06 1597.65,453.85 1576.27,453.85 1576.27,577.02  1550.69,577.02 1550.69,568.12 1554.03,568.12 1554.03,543.34 1529.25,568.12 1532.9,568.12 1532.9,577.02 1502.92,577.02  1502.92,568.12 1522.81,568.12 1554.03,536.81 1554.03,453.85 1545.69,453.85 1545.69,437.17 1554.03,437.17 1554.03,412.89  '
        />
        <rect
          x={1519.6}
          y={437.17}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.29}
          height={16.68}
        />
        <rect
          x={1491.8}
          y={437.17}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={10.01}
          height={16.68}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1401,391.72 1420.63,391.72 1420.63,437.17 1474.01,437.17 1474.01,453.85 1460.67,453.85 1460.67,568.12 1465.11,568.12  1465.11,577.02 1448.71,577.02 1448.71,568.12 1452.88,568.12 1452.88,453.85 1411.73,453.85 1411.73,568.12 1415.35,568.12  1415.35,577.02 1398.95,577.02 1398.95,568.12 1402.84,568.12 1402.84,408.4 1401,408.4  '
        />
        <rect
          x={1374.31}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={7.78}
          height={16.68}
        />
        <rect
          x={1346.35}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={9.06}
          height={16.68}
        />
        <rect
          x={1319.57}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={7.87}
          height={16.68}
        />
        <rect
          x={1292.88}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={7.78}
          height={16.68}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1277.87,568.12 1286.61,568.12 1266.23,548.19 1245.59,568.12 1253.4,568.12 1253.4,577.02 1072.18,577.02 1072.18,568.12  1119.8,568.12 1119.8,565.62 1127.59,565.62 1127.59,568.12 1232.79,568.12 1261.74,540.16 1261.74,408.4 1257.29,408.4  1257.29,391.72 1273.98,391.72 1273.98,408.4 1271.75,408.4 1271.75,540.16 1299.34,568.12 1361.13,568.12 1361.13,577.02  1277.87,577.02  '
        />
        <rect
          x={1230.61}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={7.78}
          height={16.68}
        />
        <rect
          x={1202.8}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={16.68}
        />
        <rect
          x={1176.52}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={7.38}
          height={16.68}
        />
        <rect
          x={1149.83}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={7.78}
          height={16.68}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1096.45,391.72 1130.92,391.72 1130.92,408.4 1127.59,408.4 1127.59,527.81 1119.8,527.81 1119.8,510.04 1084.77,510.04  1084.77,502.25 1119.8,502.25 1119.8,408.4 1096.45,408.4  '
        />
        <rect
          x={1068.65}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={16.68}
        />
        <rect
          x={1041.6}
          y={391.72}
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.14}
          height={16.68}
        />
        <polygon
          stroke='#000000'
          strokeWidth={1.4173}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 947.36,581.63 960.71,581.63 960.71,577.02 1032.15,577.02 1032.15,568.12 1006.86,568.12 1006.86,559.31 999.07,559.31  999.07,568.12 959.04,568.12 959.04,478.44 999.07,478.44 999.07,530.4 1006.86,530.4 1006.86,510.04 1011.57,510.04  1011.57,502.25 1006.86,502.25 1006.86,478.09 1031.88,502.25 1029.37,502.25 1029.37,510.04 1046.96,510.04 1046.96,502.25  1038.28,502.25 1006.86,471.91 1006.86,408.4 1022.69,408.4 1022.69,391.72 985.42,391.72 985.42,350.87 1492.01,350.87  1516.49,375.35 1522.78,369.06 1494.58,340.86 1337.04,340.86 1337.04,317.47 1319.25,317.47 1319.25,340.86 1197.92,340.86  1197.92,235.62 1189.03,235.62 1189.03,340.86 1147.88,340.86 1147.88,297.49 1138.98,297.49 1138.98,340.86 1102.98,340.86  1102.98,298.6 1094.08,298.6 1094.08,340.86 1036.95,340.86 1036.95,258.68 1148.16,258.43 1148.16,248.42 1038.9,248.42  1038.9,226.05 1030,226.05 1030,250.51 882.37,250.51 882.37,233.11 873.48,233.11 873.48,259.96 882.37,259.96 882.37,259.68  882.37,259.4 1025.83,259.4 1025.83,340.86 976.53,340.86 976.53,381.06 882.37,381.06 882.37,336.69 873.48,336.69 873.48,387.74  822.72,387.74 822.72,412.29 849.85,412.29 849.85,417.34 858.74,417.34 858.74,412.29 858.74,407.29 996.9,407.29 996.85,469.54  858.74,469.54 858.74,452.93 849.85,452.93 849.85,478.04 858.74,478.04 858.74,478.44 947.92,478.44 947.92,568.12 839.19,568.12  839.19,577.02 947.36,577.02  '
        />
        <rect
          x={662.18}
          y={394.6}
          stroke='#000000'
          strokeWidth={0.8504}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={17.79}
          height={17.79}
        />
        <rect
          x={662.18}
          y={443.53}
          stroke='#000000'
          strokeWidth={0.8504}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={17.79}
          height={17.79}
        />
        <polygon
          stroke='#000000'
          strokeWidth={0.8504}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 947.36,625.56 947.36,619.44 948.47,619.44 948.47,618.74 950.7,618.74 950.7,619.44 960.71,619.44 960.71,625.56  '
        />
        <polygon
          stroke='#000000'
          strokeWidth={0.8504}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 947.36,581.63 947.36,583.85 948.47,583.85 948.47,584.55 950.7,584.55 950.7,583.85 960.71,583.85 960.71,581.63  '
        />
        {showLabels &&
        (
          <g id='labels'>
            <g>
              <path d='M261.64,369.41v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v2.9 h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S261.64,369.55,261.64,369.41z' />
              <path d='M268.94,369.22l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18s-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C268.91,369.34,268.92,369.28,268.94,369.22z M271.41,364.4l-0.84,2.82h1.67L271.41,364.4z' />
              <path d='M276.16,369.9v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H276.16z' />
              <path d='M283.4,369.9v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H283.4z' />
              <path d='M293.63,365.16v3.28l0.29,0.48h0.41l0.3-0.49v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.98c0,0.07-0.04,0.18-0.12,0.31l-0.46,0.76c-0.09,0.17-0.24,0.25-0.43,0.25 h-0.93c-0.29,0-0.48-0.13-0.56-0.4c-0.09,0.27-0.27,0.4-0.54,0.4h-0.87c-0.25,0-0.43-0.09-0.54-0.28l-0.42-0.71 c-0.08-0.13-0.12-0.24-0.12-0.33v-5.96c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.15 s0.14,0.22,0.14,0.35v5.83l0.29,0.47h0.42l0.31-0.49v-3.26c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14s0.25,0.05,0.35,0.14 S293.63,365.03,293.63,365.16z' />
              <path d='M297.93,369.22l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18s-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C297.9,369.34,297.91,369.28,297.93,369.22z M300.4,364.4l-0.84,2.82h1.67L300.4,364.4z' />
              <path d='M308.14,366.17v3.24c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14s-0.15-0.21-0.15-0.35 v-3.24l-1.98-2.51v-1.04c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.36v0.68l1.48,1.9 l1.5-1.9v-0.68c0-0.14,0.05-0.25,0.15-0.35c0.1-0.1,0.21-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.14s0.15,0.21,0.15,0.37v1.03 L308.14,366.17z' />
              <path d='M274.51,381.32v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11H277 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H274.51z  M274.51,379.22v1.11h0.98v-1.11H274.51z' />
              <path d='M280.24,383.31h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S280.1,383.31,280.24,383.31z' />
              <path d='M287.49,383.31H289v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S287.35,383.31,287.49,383.31z' />
              <path d='M294.73,383.31h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S294.6,383.31,294.73,383.31z' />
            </g>
            <g>
              <path d='M331.87,228.25v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v2.9 h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S331.87,228.39,331.87,228.25z' />
              <path d='M342.79,221.68l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C342.16,220.96,342.55,221.2,342.79,221.68z M341.29,222.17 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S341.36,222.02,341.29,222.17z' />
              <path d='M346.39,228.74v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H346.39z' />
              <path d='M354.12,220.96h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S353.98,220.96,354.12,220.96z M355.61,221.95v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H355.61z' />
              <path d='M361.37,220.96h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S361.23,220.96,361.37,220.96z' />
              <path d='M373.09,221.46v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S373.09,221.32,373.09,221.46z' />
              <path d='M378.23,220.96h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36 c-0.1,0.1-0.22,0.14-0.34,0.14h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73 c0,0.14,0.05,0.26,0.14,0.36s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02 c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04 s-0.64,0.43-1.04,0.43h-2c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85 c0.11-0.14,0.25-0.26,0.44-0.36S378.03,220.96,378.23,220.96z' />
              <path d='M345.46,242.16h1.78c0.14,0,0.26,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C345.08,242.06,345.24,242.16,345.46,242.16z' />
              <path d='M354.48,236.35H351v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15H351v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S354.61,236.35,354.48,236.35z' />
              <path d='M357.26,243.14v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H357.26z' />
              <path d='M364.51,243.14v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H364.51z' />
              <path d='M344.75,254.55v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H344.75z  M344.75,252.46v1.11h0.98v-1.11H344.75z' />
              <path d='M350.47,256.55h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S350.33,256.55,350.47,256.55z' />
              <path d='M357.24,256.58v-5.89c0-0.26,0.09-0.48,0.28-0.67s0.41-0.28,0.67-0.28h3.07c0.25,0,0.47,0.09,0.66,0.28 s0.28,0.41,0.28,0.67v5.89c0,0.26-0.09,0.49-0.28,0.67s-0.41,0.28-0.67,0.28h-3.06c-0.27,0-0.49-0.09-0.67-0.28 S357.24,256.84,357.24,256.58z M361.22,256.55v-5.81h-3v5.81H361.22z' />
              <path d='M364.47,256.53v-2.4c0-0.27,0.1-0.5,0.29-0.69s0.43-0.29,0.7-0.29v-2.9c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2 c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35v2.9c0.27,0,0.5,0.09,0.69,0.28s0.29,0.42,0.29,0.7v2.41 c0,0.27-0.1,0.5-0.29,0.69s-0.43,0.29-0.69,0.29h-2.99c-0.28,0-0.51-0.1-0.71-0.3S364.47,256.79,364.47,256.53z M365.47,254.13 v2.41h2.99v-2.41H365.47z M366.45,250.74v2.41h1.02v-2.41H366.45z' />
            </g>
            <g>
              <path d='M331.87,88.66v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v2.9 h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S331.87,88.79,331.87,88.66z' />
              <path d='M342.79,82.08l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C342.16,81.36,342.55,81.6,342.79,82.08z M341.29,82.58l-1.02,2.07 c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19c0.12,0,0.22-0.06,0.29-0.19 l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07c-0.07-0.15-0.17-0.23-0.31-0.23 S341.36,82.42,341.29,82.58z' />
              <path d='M346.39,89.15v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H346.39z' />
              <path d='M354.12,81.36h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S353.98,81.36,354.12,81.36z M355.61,82.36v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H355.61z' />
              <path d='M361.37,81.36h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S361.23,81.36,361.37,81.36z' />
              <path d='M373.09,81.87v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S373.09,81.73,373.09,81.87z' />
              <path d='M378.23,81.36h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36 c-0.1,0.1-0.22,0.14-0.34,0.14h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73 c0,0.14,0.05,0.26,0.14,0.36s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02 c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04 s-0.64,0.43-1.04,0.43h-2c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85 c0.11-0.14,0.25-0.26,0.44-0.36S378.03,81.36,378.23,81.36z' />
              <path d='M345.46,102.57h1.78c0.14,0,0.26,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C345.08,102.46,345.24,102.57,345.46,102.57z' />
              <path d='M354.48,96.76H351v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15H351v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S354.61,96.76,354.48,96.76z' />
              <path d='M357.26,103.55v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H357.26z' />
              <path d='M364.51,103.55v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H364.51z' />
              <path d='M344.75,114.96v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H344.75z  M344.75,112.86v1.11h0.98v-1.11H344.75z' />
              <path d='M350.47,116.95h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S350.33,116.95,350.47,116.95z' />
              <path d='M357.24,116.99v-5.89c0-0.26,0.09-0.48,0.28-0.67s0.41-0.28,0.67-0.28h3.07c0.25,0,0.47,0.09,0.66,0.28 s0.28,0.41,0.28,0.67v5.89c0,0.26-0.09,0.49-0.28,0.67s-0.41,0.28-0.67,0.28h-3.06c-0.27,0-0.49-0.09-0.67-0.28 S357.24,117.25,357.24,116.99z M361.22,116.95v-5.81h-3v5.81H361.22z' />
              <path d='M364.49,111.09v-0.42c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h4.46v3.02c0,0.16-0.06,0.29-0.18,0.4l-1.81,1.55 v2.29c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-2.49 c0-0.19,0.06-0.33,0.17-0.43l1.82-1.54v-1.83h-2.98c-0.07,0.28-0.23,0.42-0.49,0.42c-0.14,0-0.25-0.05-0.35-0.14 S364.49,111.23,364.49,111.09z' />
            </g>
            <g>
              <path d='M459.36,54.78v3.28l0.29,0.48h0.41l0.3-0.49v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.98c0,0.07-0.04,0.18-0.12,0.31l-0.46,0.76c-0.09,0.17-0.24,0.25-0.43,0.25 h-0.93c-0.29,0-0.48-0.13-0.56-0.4c-0.09,0.27-0.27,0.4-0.54,0.4h-0.87c-0.25,0-0.43-0.09-0.54-0.28l-0.42-0.71 c-0.08-0.13-0.12-0.24-0.12-0.33v-5.96c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.15 s0.14,0.22,0.14,0.35v5.83l0.29,0.47h0.42l0.31-0.49v-3.26c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14s0.25,0.05,0.35,0.14 S459.36,54.64,459.36,54.78z' />
              <path d='M463.66,58.83l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18s-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C463.63,58.95,463.64,58.89,463.66,58.83z M466.13,54.01l-0.84,2.82h1.67L466.13,54.01z' />
              <path d='M470.87,53.07v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S470.87,53.21,470.87,53.07z' />
              <path d='M482.59,52.73h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S482.73,52.73,482.59,52.73z' />
              <path d='M485.39,59.03v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S485.39,59.16,485.39,59.03z M486.37,54.28h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57c0-0.14-0.05-0.25-0.14-0.35 s-0.21-0.14-0.35-0.14h-2.5V54.28z' />
              <path d='M455.46,72.93h1.78c0.14,0,0.26,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C455.07,72.83,455.23,72.93,455.46,72.93z' />
              <path d='M460.01,73.92v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H460.01z' />
              <path d='M470.9,66.85l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C470.27,66.13,470.66,66.37,470.9,66.85z M469.4,67.34l-1.02,2.07 c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19c0.12,0,0.22-0.06,0.29-0.19 l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07c-0.07-0.15-0.17-0.23-0.31-0.23 S469.48,67.19,469.4,67.34z' />
              <path d='M475.61,66.13h2.56c0.31,0,0.61,0.13,0.89,0.4s0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36s-0.21,0.15-0.33,0.15 c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42c-0.06-0.21-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.13c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C475.04,66.24,475.3,66.13,475.61,66.13z' />
              <path d='M486.21,67.13h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S486.35,67.13,486.21,67.13z' />
              <path d='M488.99,67.47v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S488.99,67.61,488.99,67.47z' />
            </g>
            <g>
              <path d='M708.92,267.13h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S708.78,267.13,708.92,267.13z' />
              <path d='M720.64,267.64v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S720.64,267.5,720.64,267.64z' />
              <path d='M722.92,268.48v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S722.92,268.61,722.92,268.48z' />
              <path d='M730.21,274.23l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C730.18,274.36,730.19,274.3,730.21,274.23z M732.68,269.41l-0.84,2.82h1.67L732.68,269.41z' />
              <path d='M737.42,274.43v-6.79c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35v2.33 l3.15-2.69c0.11-0.09,0.23-0.13,0.37-0.13c0.14,0,0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.35c0,0.14-0.06,0.25-0.16,0.34l-3.57,3.04 l3.58,3.09c0.1,0.08,0.15,0.19,0.14,0.32c0,0.13-0.05,0.25-0.14,0.35c-0.1,0.11-0.21,0.16-0.35,0.16c-0.13,0-0.25-0.04-0.36-0.13 l-3.14-2.7v2.35c0,0.14-0.05,0.25-0.14,0.35c-0.1,0.1-0.21,0.14-0.35,0.14c-0.13,0-0.25-0.05-0.35-0.14 S737.42,274.57,737.42,274.43z' />
              <path d='M749.14,268.13h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S749.28,268.13,749.14,268.13z' />
              <path d='M711.14,288.34h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.88c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C710.75,288.23,710.91,288.34,711.14,288.34z' />
              <path d='M720.15,282.53h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S720.28,282.53,720.15,282.53z' />
              <path d='M727.89,282.04v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S727.89,281.9,727.89,282.04z' />
              <path d='M730.17,282.88v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S730.17,283.01,730.17,282.88z' />
              <path d='M741.89,282.53h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S742.03,282.53,741.89,282.53z' />
              <path d='M744.69,288.83v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S744.69,288.96,744.69,288.83z M745.67,284.09h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V284.09z' />
            </g>
            <g>
              <path d='M698.59,502.25h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S698.46,502.25,698.59,502.25z' />
              <path d='M710.32,502.75v7.28H709l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S710.32,502.61,710.32,502.75z' />
              <path d='M712.6,503.59v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S712.6,503.73,712.6,503.59z' />
              <path d='M719.89,509.35l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C719.86,509.48,719.87,509.41,719.89,509.35z M722.36,504.53l-0.84,2.82h1.67L722.36,504.53z' />
              <path d='M727.09,509.55v-6.79c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35v2.33 l3.15-2.69c0.11-0.09,0.23-0.13,0.37-0.13c0.14,0,0.25,0.05,0.35,0.15c0.09,0.1,0.14,0.22,0.14,0.35c0,0.13-0.06,0.25-0.16,0.34 l-3.57,3.04l3.58,3.09c0.1,0.08,0.15,0.19,0.15,0.32c0,0.13-0.05,0.25-0.14,0.35c-0.1,0.11-0.21,0.16-0.35,0.16 c-0.13,0-0.25-0.04-0.36-0.13l-3.14-2.7v2.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14c-0.13,0-0.25-0.05-0.35-0.14 S727.09,509.68,727.09,509.55z' />
              <path d='M738.82,503.25h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S738.95,503.25,738.82,503.25z' />
              <path d='M700.81,523.45h1.78c0.14,0,0.26,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C700.43,523.35,700.59,523.45,700.81,523.45z' />
              <path d='M709.83,517.65h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S709.96,517.65,709.83,517.65z' />
              <path d='M717.57,517.15v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S717.57,517.01,717.57,517.15z' />
              <path d='M719.84,517.99v-1.33h4.97V518c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S719.84,518.13,719.84,517.99z' />
              <path d='M731.57,517.65h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S731.71,517.65,731.57,517.65z' />
              <path d='M734.36,523.94v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S734.36,524.08,734.36,523.94z M735.35,519.2h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V519.2z' />
            </g>
            <g>
              <path d='M515.49,494.47h2.56c0.31,0,0.61,0.13,0.89,0.4s0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36s-0.21,0.15-0.33,0.15 c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42c-0.06-0.21-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.12c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C514.92,494.58,515.18,494.47,515.49,494.47z' />
              <path d='M521.62,495.81v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S521.62,495.94,521.62,495.81z' />
              <path d='M532.52,495.19l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C531.9,494.47,532.29,494.71,532.52,495.19z M531.02,495.68 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S531.1,495.53,531.02,495.68z' />
              <path d='M536.14,501.76v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S536.14,501.9,536.14,501.76z M537.12,497.02h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V497.02z' />
              <path d='M547.84,495.46h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S547.98,495.46,547.84,495.46z' />
              <path d='M518.02,516.16v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 c-0.29,0.29-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S518.02,516.3,518.02,516.16z M519,511.42h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57c0-0.14-0.05-0.25-0.14-0.35 s-0.21-0.14-0.35-0.14H519V511.42z' />
              <path d='M528.9,509.59l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C528.27,508.87,528.66,509.11,528.9,509.59z M527.4,510.08 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S527.48,509.93,527.4,510.08z' />
              <path d='M536.15,509.59l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C535.52,508.87,535.91,509.11,536.15,509.59z M534.65,510.08 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S534.72,509.93,534.65,510.08z' />
              <path d='M541.73,511.93v-0.25l-1.02-1.45v5.94c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35v-7.29H541l1.21,1.76l1.25-1.76h1.25v7.29c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-5.92l-1,1.41v0.28c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 c-0.14,0-0.25-0.05-0.35-0.15S541.73,512.06,541.73,511.93z' />
              <path d='M519.99,528.07v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H519.99z  M519.99,525.97v1.11h0.98v-1.11H519.99z' />
              <path d='M525.71,530.06h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S525.57,530.06,525.71,530.06z' />
              <path d='M532.96,530.06h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S532.82,530.06,532.96,530.06z' />
              <path d='M540.25,523.27h0.49c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14 v3.25h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35v2.54c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-3.95 c-0.14,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-6.79c0-0.14,0.05-0.25,0.15-0.35S540.11,523.27,540.25,523.27z M540.73,528.5 v1.56h2.98v-1.56H540.73z' />
            </g>
            <g>
              <path d='M726.91,59.82h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S726.78,59.82,726.91,59.82z M728.4,60.81v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H728.4z' />
              <path d='M733.69,67.11v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S733.69,67.25,733.69,67.11z M734.68,62.37h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57c0-0.14-0.05-0.25-0.14-0.35 s-0.21-0.14-0.35-0.14h-2.5V62.37z' />
              <path d='M740.92,66.13v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.82 c0,0.13,0.05,0.24,0.15,0.34s0.21,0.14,0.35,0.14h2.02c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.34v-5.81 c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v5.79c0,0.41-0.15,0.76-0.44,1.05 s-0.64,0.44-1.04,0.44h-2.02c-0.41,0-0.75-0.14-1.04-0.43S740.92,66.53,740.92,66.13z' />
              <path d='M751.03,59.82h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36 c-0.1,0.1-0.22,0.14-0.34,0.14h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73 c0,0.14,0.05,0.26,0.14,0.36s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02 c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04 s-0.64,0.43-1.04,0.43h-2c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04V63.4c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85 c0.11-0.14,0.25-0.26,0.44-0.36S750.83,59.82,751.03,59.82z' />
              <path d='M762.66,61.16v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S762.66,61.3,762.66,61.16z' />
              <path d='M774.39,60.81h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 c-0.1,0.1-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35 s-0.22,0.14-0.35,0.14h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35 S774.52,60.81,774.39,60.81z' />
              <path d='M778.28,59.82h2.56c0.31,0,0.61,0.13,0.89,0.4s0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36s-0.21,0.15-0.33,0.15 c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42c-0.06-0.21-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.13c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C777.71,59.93,777.97,59.82,778.28,59.82z' />
              <path d='M784.41,61.16v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S784.41,61.3,784.41,61.16z' />
              <path d='M792.15,59.82h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S792.01,59.82,792.15,59.82z' />
              <path d='M803.87,60.32v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S803.87,60.18,803.87,60.32z' />
              <path d='M809.02,59.82h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36 c-0.1,0.1-0.22,0.14-0.34,0.14h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73 c0,0.14,0.05,0.26,0.14,0.36s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02 c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04 s-0.64,0.43-1.04,0.43h-2c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04V63.4c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85 c0.11-0.14,0.25-0.26,0.44-0.36S808.81,59.82,809.02,59.82z' />
              <path d='M757.41,79.02v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H757.41z M757.41,76.92 v1.11h0.98v-1.11H757.41z' />
              <path d='M763.13,81.01h1.51V75.2h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S762.99,81.01,763.13,81.01z' />
              <path d='M774.39,81.99h-4.48v-3.4c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S774.52,81.99,774.39,81.99z' />
              <path d='M777.15,81.04v-5.89c0-0.26,0.09-0.48,0.28-0.67s0.41-0.28,0.67-0.28h3.07c0.25,0,0.47,0.09,0.66,0.28 s0.28,0.41,0.28,0.67v5.89c0,0.26-0.09,0.49-0.28,0.67s-0.41,0.28-0.67,0.28h-3.06c-0.27,0-0.49-0.09-0.67-0.28 S777.15,81.31,777.15,81.04z M781.13,81.01V75.2h-3v5.81H781.13z' />
            </g>
            <g>
              <path d='M902.94,88.44h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S902.8,88.44,902.94,88.44z' />
              <path d='M914.66,88.95v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S914.66,88.81,914.66,88.95z' />
              <path d='M916.94,89.79v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S916.94,89.92,916.94,89.79z' />
              <path d='M924.23,95.55l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18s-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C924.2,95.67,924.21,95.61,924.23,95.55z M926.7,90.72l-0.84,2.82h1.67L926.7,90.72z' />
              <path d='M931.44,95.74v-6.79c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35v2.33 l3.15-2.69c0.11-0.09,0.23-0.13,0.37-0.13c0.14,0,0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.35c0,0.14-0.06,0.25-0.16,0.34l-3.57,3.04 l3.58,3.09c0.1,0.08,0.15,0.19,0.15,0.32c0,0.13-0.05,0.25-0.14,0.35c-0.1,0.11-0.21,0.16-0.35,0.16c-0.13,0-0.25-0.04-0.36-0.13 l-3.14-2.7v2.35c0,0.14-0.05,0.25-0.14,0.35c-0.1,0.1-0.21,0.14-0.35,0.14c-0.13,0-0.25-0.05-0.35-0.14S931.44,95.88,931.44,95.74 z' />
              <path d='M943.16,89.44h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S943.3,89.44,943.16,89.44z' />
              <path d='M912.4,109.65h1.78c0.14,0,0.26,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C912.02,109.54,912.18,109.65,912.4,109.65z' />
              <path d='M921.42,103.84h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S921.55,103.84,921.42,103.84z' />
              <path d='M924.2,110.63v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H924.2z' />
              <path d='M931.45,110.63v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H931.45z' />
              <path d='M908.06,122.04v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H908.06z  M908.06,119.95v1.11h0.98v-1.11H908.06z' />
              <path d='M913.78,124.04h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S913.65,124.04,913.78,124.04z' />
              <path d='M925.04,125.02h-4.48v-3.4c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S925.17,125.02,925.04,125.02z' />
              <path d='M931.04,124.53v-2.05h-2.96v-4.74c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15 s0.15,0.22,0.15,0.35v3.74h1.97v-2.88c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.35 v2.88c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14v2.06 c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.14S931.04,124.66,931.04,124.53z' />
              <path d='M935.1,124.35l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18s-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C935.07,124.47,935.08,124.41,935.1,124.35z M937.57,119.52l-0.84,2.82h1.67L937.57,119.52z' />
            </g>
            <g>
              <path d='M902.93,294.83h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S902.8,294.83,902.93,294.83z' />
              <path d='M914.66,295.33v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S914.66,295.19,914.66,295.33z' />
              <path d='M916.94,296.17v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S916.94,296.3,916.94,296.17z' />
              <path d='M924.23,301.93l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18s-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C924.2,302.05,924.21,301.99,924.23,301.93z M926.7,297.1l-0.84,2.82h1.67L926.7,297.1z' />
              <path d='M931.43,302.12v-6.79c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35v2.33 l3.15-2.69c0.11-0.09,0.23-0.13,0.37-0.13c0.14,0,0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.35c0,0.14-0.06,0.25-0.16,0.34l-3.57,3.04 l3.58,3.09c0.1,0.08,0.15,0.19,0.15,0.32c0,0.13-0.05,0.25-0.14,0.35c-0.1,0.11-0.21,0.16-0.35,0.16c-0.13,0-0.25-0.04-0.36-0.13 l-3.14-2.7v2.35c0,0.14-0.05,0.25-0.14,0.35c-0.1,0.1-0.21,0.14-0.35,0.14c-0.13,0-0.25-0.05-0.35-0.14 S931.43,302.26,931.43,302.12z' />
              <path d='M943.16,295.82h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S943.3,295.82,943.16,295.82z' />
              <path d='M912.4,316.03h1.78c0.14,0,0.26,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C912.02,315.93,912.18,316.03,912.4,316.03z' />
              <path d='M921.42,310.22h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S921.55,310.22,921.42,310.22z' />
              <path d='M924.2,317.01v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H924.2z' />
              <path d='M931.45,317.01v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H931.45z' />
              <path d='M908.06,328.42v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H908.06z  M908.06,326.33v1.11h0.98v-1.11H908.06z' />
              <path d='M913.78,330.42h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S913.65,330.42,913.78,330.42z' />
              <path d='M925.04,331.4h-4.48V328c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S925.17,331.4,925.04,331.4z' />
              <path d='M931.04,330.91v-2.05h-2.96v-4.74c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15 s0.15,0.22,0.15,0.35v3.74h1.97v-2.88c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.35 v2.88c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14v2.06 c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.14S931.04,331.05,931.04,330.91z' />
              <path d='M940.03,329v0.43c0,0.54-0.19,1.01-0.58,1.4s-0.85,0.58-1.39,0.58h-3.01v-7.79h3.01c0.54,0,1.01,0.19,1.39,0.58 s0.58,0.85,0.58,1.4v0.42c0,0.58-0.22,1.08-0.67,1.49C939.8,327.93,940.03,328.42,940.03,329z M939.02,326.01v-0.4 c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-2v2.38h2c0.27,0,0.5-0.1,0.69-0.29S939.02,326.28,939.02,326.01z M939.02,329.43 v-0.41c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-1.99v2.39h1.99c0.27,0,0.5-0.1,0.69-0.29S939.02,329.7,939.02,329.43z' />
            </g>
            <g>
              <path d='M929.86,215.26v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v2.9 h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S929.86,215.4,929.86,215.26z' />
              <path d='M937.16,215.07l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18s-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C937.13,215.19,937.14,215.13,937.16,215.07z M939.63,210.24l-0.84,2.82h1.67L939.63,210.24z' />
              <path d='M944.38,215.75v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H944.38z' />
              <path d='M951.62,215.75v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H951.62z' />
              <path d='M961.85,211.01v3.28l0.29,0.48h0.41l0.3-0.49v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.98c0,0.07-0.04,0.18-0.12,0.31l-0.46,0.76c-0.09,0.17-0.24,0.25-0.43,0.25 h-0.93c-0.29,0-0.48-0.13-0.56-0.4c-0.09,0.27-0.27,0.4-0.54,0.4h-0.87c-0.25,0-0.43-0.09-0.54-0.28l-0.42-0.71 c-0.08-0.13-0.12-0.24-0.12-0.33v-5.96c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.15 s0.14,0.22,0.14,0.35v5.83l0.29,0.47h0.42l0.31-0.49v-3.26c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14s0.25,0.05,0.35,0.14 S961.85,210.88,961.85,211.01z' />
              <path d='M966.15,215.07l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C966.12,215.19,966.13,215.13,966.15,215.07z M968.62,210.24l-0.84,2.82h1.67L968.62,210.24z' />
              <path d='M976.36,212.01v3.24c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14s-0.15-0.21-0.15-0.35 v-3.24l-1.98-2.51v-1.04c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.36v0.68l1.48,1.9 l1.5-1.9v-0.68c0-0.14,0.05-0.25,0.15-0.35c0.1-0.1,0.21-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.14s0.15,0.21,0.15,0.37v1.03 L976.36,212.01z' />
              <path d='M942.73,227.16v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H942.73z  M942.73,225.07v1.11h0.98v-1.11H942.73z' />
              <path d='M948.46,229.16h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S948.32,229.16,948.46,229.16z' />
              <path d='M959.71,230.14h-4.48v-3.4c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S959.85,230.14,959.71,230.14z' />
              <path d='M966.96,230.13h-0.49c-0.13,0-0.25-0.05-0.35-0.14s-0.15-0.21-0.15-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14v-3.25 H963c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-2.55c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15h3.96 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.79c0,0.14-0.05,0.25-0.14,0.35S967.1,230.13,966.96,230.13z M966.48,224.9 v-1.55h-2.99v1.55H966.48z' />
            </g>
            <g>
              <path d='M1085.82,123.51l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1085.19,122.79,1085.58,123.03,1085.82,123.51z M1084.32,124.01 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1084.4,123.85,1084.32,124.01z' />
              <path d='M1089.42,130.09v-7.29h3.49c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v1.85c0,0.41-0.14,0.75-0.43,1.04 s-0.64,0.43-1.04,0.43h-2.5v2.48c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 S1089.42,130.23,1089.42,130.09z M1090.42,126.62h2.5c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.85 c0-0.14-0.05-0.25-0.15-0.35s-0.21-0.14-0.35-0.14h-2.5V126.62z' />
              <path d='M1101.14,123.79h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1101.27,123.79,1101.14,123.79z' />
              <path d='M1108.88,123.3v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1108.88,123.16,1108.88,123.3z' />
              <path d='M1118.9,122.79h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1118.76,122.79,1118.9,122.79z M1120.38,123.79v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1120.38z' />
              <path d='M1129.31,123.51l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1128.68,122.79,1129.07,123.03,1129.31,123.51z M1127.81,124.01 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1127.89,123.85,1127.81,124.01z' />
              <path d='M1132.92,130.09v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1132.92,130.23,1132.92,130.09z M1133.91,125.35h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V125.35z' />
              <path d='M1142.14,125.85v-0.25l-1.02-1.45v5.94c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35v-7.29h1.27l1.21,1.76l1.25-1.76h1.25v7.29c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-5.92l-1,1.41v0.28c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 c-0.14,0-0.25-0.05-0.35-0.15S1142.14,125.98,1142.14,125.85z' />
              <path d='M1098.65,141.99v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1098.65z  M1098.65,139.89v1.11h0.98v-1.11H1098.65z' />
              <path d='M1104.38,143.98h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1104.24,143.98,1104.38,143.98z' />
              <path d='M1115.63,144.97h-4.48v-3.4c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S1115.76,144.97,1115.63,144.97z' />
              <path d='M1122.88,144.96h-0.49c-0.13,0-0.25-0.05-0.35-0.14s-0.15-0.21-0.15-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14v-3.25 h-3.47c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-2.55c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15h3.96 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.79c0,0.14-0.05,0.25-0.14,0.35S1123.02,144.96,1122.88,144.96z M1122.4,139.72 v-1.55h-2.99v1.55H1122.4z' />
              <path d='M1125.69,144.3l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C1125.66,144.42,1125.67,144.36,1125.69,144.3z M1128.16,139.47l-0.84,2.82h1.67L1128.16,139.47z' />
            </g>
            <g>
              <path d='M1229.54,175.74l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1228.91,175.02,1229.3,175.26,1229.54,175.74z M1228.04,176.24 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1228.11,176.08,1228.04,176.24z' />
              <path d='M1233.14,182.32v-7.29h3.49c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v1.85c0,0.41-0.14,0.75-0.43,1.04 s-0.64,0.43-1.04,0.43h-2.5v2.48c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 S1233.14,182.46,1233.14,182.32z M1234.14,178.85h2.5c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.85 c0-0.14-0.05-0.25-0.15-0.35s-0.21-0.14-0.35-0.14h-2.5V178.85z' />
              <path d='M1244.85,176.02h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1244.99,176.02,1244.85,176.02z' />
              <path d='M1252.59,175.53v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1252.59,175.39,1252.59,175.53z' />
              <path d='M1262.61,175.02h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1262.48,175.02,1262.61,175.02z M1264.1,176.02v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1264.1z' />
              <path d='M1273.02,175.74l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1272.4,175.02,1272.79,175.26,1273.02,175.74z M1271.52,176.24 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1271.6,176.08,1271.52,176.24z' />
              <path d='M1276.64,182.32v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1276.64,182.46,1276.64,182.32z M1277.62,177.58h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V177.58z' />
              <path d='M1285.86,178.08v-0.25l-1.02-1.45v5.94c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35v-7.29h1.27l1.21,1.76l1.25-1.76h1.25v7.29c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-5.92l-1,1.41v0.28c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 c-0.14,0-0.25-0.05-0.35-0.15S1285.86,178.21,1285.86,178.08z' />
              <path d='M1242.37,194.22v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1242.37z  M1242.37,192.12v1.11h0.98v-1.11H1242.37z' />
              <path d='M1248.09,196.21h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1247.96,196.21,1248.09,196.21z' />
              <path d='M1259.35,197.2h-4.48v-3.4c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S1259.48,197.2,1259.35,197.2z' />
              <path d='M1266.6,197.19h-0.49c-0.13,0-0.25-0.05-0.35-0.14s-0.15-0.21-0.15-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14v-3.25 h-3.47c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-2.55c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15h3.96 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.79c0,0.14-0.05,0.25-0.14,0.35S1266.73,197.19,1266.6,197.19z M1266.12,191.95 v-1.55h-2.99v1.55H1266.12z' />
              <path d='M1269.41,196.53l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1269.38,196.65,1269.39,196.59,1269.41,196.53z M1271.88,191.7l-0.84,2.82h1.67L1271.88,191.7z' />
            </g>
            <g>
              <path d='M1090.76,279.46v0.43c0,0.54-0.19,1.01-0.58,1.4s-0.85,0.58-1.39,0.58h-3.01v-7.79h3.01c0.54,0,1.01,0.19,1.39,0.58 s0.58,0.85,0.58,1.4v0.42c0,0.58-0.22,1.08-0.67,1.49C1090.53,278.39,1090.76,278.89,1090.76,279.46z M1089.75,276.48v-0.4 c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-2v2.38h2c0.27,0,0.5-0.1,0.69-0.29S1089.75,276.75,1089.75,276.48z  M1089.75,279.9v-0.41c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-1.99v2.39h1.99c0.27,0,0.5-0.1,0.69-0.29 S1089.75,280.17,1089.75,279.9z' />
              <path d='M1093.08,281.19l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1093.05,281.32,1093.06,281.26,1093.08,281.19z M1095.55,276.37l-0.84,2.82h1.67L1095.55,276.37z' />
              <path d='M1100.28,275.43v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1100.28,275.57,1100.28,275.43z' />
              <path d='M1107.52,281.39v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1107.52,281.52,1107.52,281.39z' />
              <path d='M1114.8,281.39v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1114.8,281.52,1114.8,281.39z M1115.79,276.65h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V276.65z' />
              <path d='M1125.68,274.81l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1125.06,274.09,1125.45,274.33,1125.68,274.81z M1124.18,275.3 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1124.26,275.15,1124.18,275.3z' />
              <path d='M1132.93,274.81l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1132.31,274.09,1132.7,274.33,1132.93,274.81z M1131.43,275.3 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1131.51,275.15,1131.43,275.3z' />
              <path d='M1138.52,277.15v-0.25l-1.02-1.45v5.94c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35v-7.29h1.27l1.21,1.76l1.25-1.76h1.25v7.29c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-5.92l-1,1.41v0.28c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 c-0.14,0-0.25-0.05-0.35-0.15S1138.52,277.28,1138.52,277.15z' />
            </g>
            <g>
              <path d='M1138.12,368.43v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.82 c0,0.13,0.05,0.24,0.15,0.34s0.21,0.14,0.35,0.14h2.02c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.34v-5.81 c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v5.79c0,0.41-0.15,0.76-0.44,1.05 s-0.64,0.44-1.04,0.44h-2.02c-0.41,0-0.75-0.14-1.04-0.43S1138.12,368.83,1138.12,368.43z' />
              <path d='M1145.37,363.46v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1145.37,363.6,1145.37,363.46z' />
              <path d='M1153.11,362.12h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1152.97,362.12,1153.11,362.12z' />
              <path d='M1159.87,369.9v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1159.87z' />
              <path d='M1167.6,362.12h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1167.47,362.12,1167.6,362.12z' />
              <path d='M1174.36,363.46v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1174.36,363.6,1174.36,363.46z' />
              <path d='M1184.61,366.17v3.24c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14s-0.15-0.21-0.15-0.35 v-3.24l-1.98-2.51v-1.04c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.36v0.68l1.48,1.9 l1.5-1.9v-0.68c0-0.14,0.05-0.25,0.15-0.35c0.1-0.1,0.21-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.14s0.15,0.21,0.15,0.37v1.03 L1184.61,366.17z' />
              <path d='M1198.81,368.92h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1198.43,368.82,1198.59,368.92,1198.81,368.92z' />
              <path d='M1203.34,369.41v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1203.34,369.55,1203.34,369.41z' />
              <path d='M1210.64,369.22l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1210.61,369.34,1210.62,369.28,1210.64,369.22z M1213.11,364.4l-0.84,2.82h1.67L1213.11,364.4z' />
              <path d='M1218.97,362.12h2.56c0.31,0,0.61,0.13,0.89,0.4c0.28,0.26,0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36 s-0.21,0.15-0.33,0.15c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42s-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.13c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C1218.39,362.23,1218.66,362.12,1218.97,362.12z' />
              <path d='M1229.57,363.11h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1229.71,363.11,1229.57,363.11z' />
              <path d='M1243.74,369.76l-0.59-0.61l-0.76,0.65c-0.05,0.06-0.14,0.09-0.28,0.09h-1.02c-0.41,0-0.76-0.15-1.05-0.44 s-0.44-0.65-0.44-1.06v-0.69c0-0.16,0.06-0.29,0.19-0.4l0.91-0.77l-0.92-1c-0.12-0.13-0.18-0.29-0.18-0.46v-0.62 c0-0.41,0.15-0.76,0.44-1.05s0.64-0.44,1.05-0.44h1c0.41,0,0.76,0.15,1.05,0.44s0.44,0.64,0.44,1.05v0.66 c0,0.18-0.08,0.35-0.25,0.49l-1.18,1l1.08,1.15l0.5-0.43c0.11-0.1,0.24-0.15,0.38-0.15c0.13,0,0.24,0.05,0.34,0.15 s0.14,0.21,0.14,0.35c0,0.14-0.05,0.25-0.15,0.34l-0.52,0.45l0.52,0.58c0.09,0.11,0.14,0.22,0.14,0.36s-0.05,0.25-0.14,0.34 c-0.09,0.09-0.21,0.14-0.35,0.14C1243.95,369.9,1243.83,369.85,1243.74,369.76z M1242.6,364.94v-0.48c0-0.14-0.05-0.25-0.14-0.35 s-0.21-0.15-0.35-0.15h-1.01c-0.14,0-0.25,0.05-0.35,0.15c-0.1,0.1-0.14,0.22-0.14,0.36v0.49l0.87,0.94L1242.6,364.94z  M1240.61,367.94v0.48c0,0.14,0.05,0.25,0.15,0.35s0.21,0.14,0.35,0.14h0.8l0.56-0.49l-1.06-1.16L1240.61,367.94z' />
              <path d='M1255.21,362.12h2.56c0.31,0,0.61,0.13,0.89,0.4c0.28,0.26,0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36 s-0.21,0.15-0.33,0.15c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42s-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.13c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C1254.63,362.23,1254.9,362.12,1255.21,362.12z' />
              <path d='M1261.33,363.46v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1261.33,363.6,1261.33,363.46z' />
              <path d='M1272.24,362.84l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1271.61,362.12,1272,362.36,1272.24,362.84z M1270.74,363.33 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1270.82,363.18,1270.74,363.33z' />
              <path d='M1275.85,369.41v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1275.85,369.55,1275.85,369.41z M1276.84,364.67h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V364.67z' />
              <path d='M1283.12,369.22l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1283.09,369.34,1283.1,369.28,1283.12,369.22z M1285.59,364.4l-0.84,2.82h1.67L1285.59,364.4z' />
              <path d='M1293.19,362.12h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1292.99,362.12,1293.19,362.12z' />
              <path d='M1302.05,363.11h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1302.19,363.11,1302.05,363.11z' />
              <path d='M1312.09,369.41v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1312.09,369.55,1312.09,369.41z M1313.08,364.67h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V364.67z' />
              <path d='M1322.98,362.84l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1322.35,362.12,1322.74,362.36,1322.98,362.84z M1321.48,363.33 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1321.55,363.18,1321.48,363.33z' />
              <path d='M1330.22,362.84l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1329.6,362.12,1329.99,362.36,1330.22,362.84z M1328.72,363.33 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1328.8,363.18,1328.72,363.33z' />
              <path d='M1335.81,365.18v-0.25l-1.02-1.45v5.94c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35v-7.29h1.27l1.21,1.76l1.25-1.76h1.25v7.29c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-5.92l-1,1.41v0.28c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 c-0.14,0-0.25-0.05-0.35-0.15S1335.81,365.31,1335.81,365.18z' />
              <path d='M1227.09,381.32v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1227.09z  M1227.09,379.22v1.11h0.98v-1.11H1227.09z' />
              <path d='M1232.81,383.31h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1232.67,383.31,1232.81,383.31z' />
              <path d='M1244.56,377.5v1.85c0,0.43-0.13,0.78-0.4,1.04c0.27,0.3,0.41,0.64,0.41,1v1.92c0,0.27-0.1,0.5-0.29,0.69 s-0.43,0.29-0.7,0.29h-3.48c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.47 v-1.92c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.15-0.35-0.15h-1.98c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.99c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.9h-3.52 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29 S1244.56,377.23,1244.56,377.5z' />
              <path d='M1248.65,383.31h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1248.49,383.28,1248.57,383.31,1248.65,383.31z' />
            </g>
            <g>
              <path d='M1454.01,212.25v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99H1455v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1454.01,212.39,1454.01,212.25z M1454.99,207.51h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V207.51z' />
              <path d='M1465.71,205.95h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1465.85,205.95,1465.71,205.95z' />
              <path d='M1471.19,211.76h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1470.81,211.66,1470.97,211.76,1471.19,211.76z' />
              <path d='M1475.75,212.25v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1475.75,212.39,1475.75,212.25z M1476.74,207.51h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V207.51z' />
              <path d='M1487.45,205.95h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1487.59,205.95,1487.45,205.95z' />
              <path d='M1490.27,212.06l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1490.24,212.18,1490.25,212.12,1490.27,212.06z M1492.74,207.23l-0.84,2.82h1.67L1492.74,207.23z' />
              <path d='M1497.47,206.3v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1497.47,206.43,1497.47,206.3z' />
              <path d='M1505.21,204.96h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1505.08,204.96,1505.21,204.96z' />
              <path d='M1515.62,205.68l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1515,204.96,1515.39,205.2,1515.62,205.68z M1514.12,206.17 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1514.2,206.02,1514.12,206.17z' />
              <path d='M1524.19,205.46v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1524.19,205.32,1524.19,205.46z' />
              <path d='M1475.77,226.46l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1475.74,226.58,1475.75,226.52,1475.77,226.46z M1478.24,221.63l-0.84,2.82h1.67L1478.24,221.63z' />
              <path d='M1483,226.65v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99H1484v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1483,226.79,1483,226.65z M1483.98,221.91h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57c0-0.14-0.05-0.25-0.14-0.35 s-0.21-0.14-0.35-0.14h-2.5V221.91z' />
              <path d='M1494.7,220.35h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1494.84,220.35,1494.7,220.35z' />
              <path d='M1497.51,226.46l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1497.48,226.58,1497.49,226.52,1497.51,226.46z M1499.99,221.63l-0.84,2.82h1.67L1499.99,221.63z' />
              <path d='M1477.72,238.55v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1477.72z  M1477.72,236.46v1.11h0.98v-1.11H1477.72z' />
              <path d='M1483.44,240.55h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1483.31,240.55,1483.44,240.55z' />
              <path d='M1495.19,234.74v1.85c0,0.43-0.13,0.78-0.4,1.04c0.27,0.3,0.41,0.64,0.41,1v1.92c0,0.27-0.1,0.5-0.29,0.69 s-0.43,0.29-0.7,0.29h-3.48c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.47 v-1.92c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.15-0.35-0.15h-1.98c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.99c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.9h-3.52 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29 S1495.19,234.47,1495.19,234.74z' />
              <path d='M1500.7,241.04v-2.05h-2.96v-4.74c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15 s0.15,0.22,0.15,0.35v3.74h1.97v-2.88c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.35 v2.88c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14v2.06 c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.14S1500.7,241.18,1500.7,241.04z' />
            </g>
            <g>
              <path d='M1759.22,303.13l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1758.6,302.41,1758.99,302.65,1759.22,303.13z M1757.72,303.62 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1757.8,303.47,1757.72,303.62z' />
              <path d='M1762.83,309.7v-7.29h3.49c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v1.85c0,0.41-0.14,0.75-0.43,1.04 s-0.64,0.43-1.04,0.43h-2.5v2.48c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 S1762.83,309.84,1762.83,309.7z M1763.82,306.23h2.5c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.85 c0-0.14-0.05-0.25-0.15-0.35s-0.21-0.14-0.35-0.14h-2.5V306.23z' />
              <path d='M1774.54,303.4h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1774.68,303.4,1774.54,303.4z' />
              <path d='M1782.28,302.91v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1782.28,302.77,1782.28,302.91z' />
              <path d='M1792.3,302.41h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1792.16,302.41,1792.3,302.41z M1793.79,303.4v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1793.79z' />
              <path d='M1802.71,303.13l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1802.09,302.41,1802.48,302.65,1802.71,303.13z M1801.21,303.62 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1801.29,303.47,1801.21,303.62z' />
              <path d='M1806.33,309.7v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1806.33,309.84,1806.33,309.7z M1807.31,304.96h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V304.96z' />
              <path d='M1815.54,305.46v-0.25l-1.02-1.45v5.94c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35v-7.29h1.27l1.21,1.76l1.25-1.76h1.25v7.29c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-5.92l-1,1.41v0.28c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 c-0.14,0-0.25-0.05-0.35-0.15S1815.54,305.6,1815.54,305.46z' />
              <path d='M1775.68,321.61v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1775.68z  M1775.68,319.51v1.11h0.98v-1.11H1775.68z' />
              <path d='M1781.4,323.6h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1781.27,323.6,1781.4,323.6z' />
              <path d='M1793.15,317.79v1.85c0,0.43-0.13,0.78-0.4,1.04c0.27,0.3,0.41,0.64,0.41,1v1.92c0,0.27-0.1,0.5-0.29,0.69 s-0.43,0.29-0.7,0.29h-3.48c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.47 v-1.92c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.15-0.35-0.15h-1.98c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.99c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.9h-3.52 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29 S1793.15,317.52,1793.15,317.79z' />
              <path d='M1799.91,324.58h-4.48v-3.4c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S1800.04,324.58,1799.91,324.58z' />
            </g>
            <g>
              <path d='M1809.42,651.89v0.43c0,0.54-0.19,1.01-0.58,1.4s-0.85,0.58-1.39,0.58h-3.01v-7.79h3.01c0.54,0,1.01,0.19,1.39,0.58 s0.58,0.85,0.58,1.4v0.42c0,0.58-0.22,1.08-0.67,1.49C1809.2,650.82,1809.42,651.32,1809.42,651.89z M1808.41,648.9v-0.4 c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-2v2.38h2c0.27,0,0.5-0.1,0.69-0.29S1808.41,649.17,1808.41,648.9z  M1808.41,652.32v-0.41c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-1.99v2.39h1.99c0.27,0,0.5-0.1,0.69-0.29 S1808.41,652.59,1808.41,652.32z' />
              <path d='M1811.74,653.62l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1811.71,653.74,1811.72,653.68,1811.74,653.62z M1814.21,648.8l-0.84,2.82h1.67L1814.21,648.8z' />
              <path d='M1818.95,647.86v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1818.95,648,1818.95,647.86z' />
              <path d='M1826.18,653.81v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1826.18,653.95,1826.18,653.81z' />
              <path d='M1833.47,653.81v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1833.47,653.95,1833.47,653.81z M1834.45,649.07h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V649.07z' />
              <path d='M1844.35,647.24l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1843.72,646.52,1844.11,646.76,1844.35,647.24z M1842.85,647.73 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1842.93,647.58,1842.85,647.73z' />
              <path d='M1851.6,647.24l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1850.97,646.52,1851.36,646.76,1851.6,647.24z M1850.1,647.73 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1850.17,647.58,1850.1,647.73z' />
              <path d='M1857.18,649.58v-0.25l-1.02-1.45v5.94c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35v-7.29h1.27l1.21,1.76l1.25-1.76h1.25v7.29c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-5.92l-1,1.41v0.28c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 c-0.14,0-0.25-0.05-0.35-0.15S1857.18,649.71,1857.18,649.58z' />
            </g>
            <g>
              <path d='M1632.73,719.07h2.56c0.31,0,0.61,0.13,0.89,0.4c0.28,0.26,0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36 s-0.21,0.15-0.33,0.15c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42s-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.12c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C1632.16,719.18,1632.43,719.07,1632.73,719.07z' />
              <path d='M1638.86,720.41v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1638.86,720.55,1638.86,720.41z' />
              <path d='M1649.77,719.79l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1649.14,719.07,1649.53,719.31,1649.77,719.79z M1648.27,720.28 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1648.35,720.13,1648.27,720.28z' />
              <path d='M1653.38,726.37v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1653.38,726.5,1653.38,726.37z M1654.37,721.63h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V721.63z' />
              <path d='M1660.65,726.17l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1660.62,726.3,1660.63,726.23,1660.65,726.17z M1663.12,721.35l-0.84,2.82h1.67L1663.12,721.35z' />
              <path d='M1670.72,719.07h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1670.52,719.07,1670.72,719.07z' />
              <path d='M1679.58,720.07h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1679.72,720.07,1679.58,720.07z' />
              <path d='M1642.51,740.77v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34c-0.1,0.1-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S1642.51,740.9,1642.51,740.77z M1643.49,736.03h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V736.03z' />
              <path d='M1653.39,734.19l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1652.77,733.47,1653.16,733.71,1653.39,734.19z M1651.89,734.68 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1651.97,734.53,1651.89,734.68z' />
              <path d='M1660.64,734.19l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1660.01,733.47,1660.4,733.71,1660.64,734.19z M1659.14,734.68 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1659.22,734.53,1659.14,734.68z' />
              <path d='M1666.22,736.53v-0.25l-1.02-1.45v5.94c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35v-7.29h1.27l1.21,1.76l1.25-1.76h1.25v7.29c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-5.92l-1,1.41v0.28c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 c-0.14,0-0.25-0.05-0.35-0.15S1666.22,736.66,1666.22,736.53z' />
              <path d='M1644.48,752.67v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1644.48z  M1644.48,750.57v1.11h0.98v-1.11H1644.48z' />
              <path d='M1650.2,754.66h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1650.07,754.66,1650.2,754.66z' />
              <path d='M1661.95,748.86v1.85c0,0.43-0.13,0.78-0.4,1.04c0.27,0.3,0.41,0.64,0.41,1v1.92c0,0.27-0.1,0.5-0.29,0.69 s-0.43,0.29-0.7,0.29h-3.48c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.47 v-1.92c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.15-0.35-0.15h-1.98c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.99c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.9h-3.52 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29 S1661.95,748.59,1661.95,748.86z' />
              <path d='M1664.7,754.66h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1664.56,754.66,1664.7,754.66z' />
            </g>
            <g>
              <path d='M1482.07,483.88v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1482.07,484.01,1482.07,483.88z' />
              <path d='M1492.99,477.3l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1492.36,476.58,1492.75,476.82,1492.99,477.3z M1491.49,477.79 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1491.57,477.64,1491.49,477.79z' />
              <path d='M1496.59,484.37v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1496.59z' />
              <path d='M1504.32,476.58h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1504.18,476.58,1504.32,476.58z M1505.81,477.58v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1505.81z' />
              <path d='M1511.57,476.58h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1511.43,476.58,1511.57,476.58z' />
              <path d='M1523.29,477.08v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1523.29,476.94,1523.29,477.08z' />
              <path d='M1528.44,476.58h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1528.23,476.58,1528.44,476.58z' />
              <path d='M1495.67,497.78h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1495.28,497.68,1495.44,497.78,1495.67,497.78z' />
              <path d='M1504.68,491.98h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1504.82,491.98,1504.68,491.98z' />
              <path d='M1507.46,498.77v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1507.46z' />
              <path d='M1514.71,498.77v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1514.71z' />
              <path d='M1494.95,510.18v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1494.95z  M1494.95,508.08v1.11h0.98v-1.11H1494.95z' />
              <path d='M1500.67,512.17h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1500.54,512.17,1500.67,512.17z' />
              <path d='M1507.97,505.38h0.49c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14 v3.25h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35v2.54c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-3.95 c-0.14,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-6.79c0-0.14,0.05-0.25,0.15-0.35S1507.83,505.38,1507.97,505.38z  M1508.45,510.61v1.56h2.98v-1.56H1508.45z' />
              <path d='M1519.18,513.16h-4.48v-3.4c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S1519.31,513.16,1519.18,513.16z' />
            </g>
            <g>
              <path d='M1486.53,732.98v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1486.53,733.11,1486.53,732.98z' />
              <path d='M1497.45,726.4l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1496.82,725.68,1497.21,725.92,1497.45,726.4z M1495.95,726.9 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1496.02,726.74,1495.95,726.9z' />
              <path d='M1501.05,733.47v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1501.05z' />
              <path d='M1508.78,725.68h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1508.64,725.68,1508.78,725.68z M1510.27,726.68v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1510.27z' />
              <path d='M1516.03,725.68h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1515.89,725.68,1516.03,725.68z' />
              <path d='M1527.75,726.19v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1527.75,726.05,1527.75,726.19z' />
              <path d='M1532.9,725.68h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1532.69,725.68,1532.9,725.68z' />
              <path d='M1500.12,746.89h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1499.74,746.78,1499.9,746.89,1500.12,746.89z' />
              <path d='M1509.14,741.08h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1509.28,741.08,1509.14,741.08z' />
              <path d='M1511.92,747.87v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1511.92z' />
              <path d='M1519.17,747.87v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1519.17z' />
              <path d='M1499.41,759.28v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1499.41z  M1499.41,757.18v1.11h0.98v-1.11H1499.41z' />
              <path d='M1505.13,761.27h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1504.99,761.27,1505.13,761.27z' />
              <path d='M1513.72,761.27h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1513.56,761.25,1513.64,761.27,1513.72,761.27z' />
              <path d='M1523.63,762.25h-0.49c-0.13,0-0.25-0.05-0.35-0.14s-0.15-0.21-0.15-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14v-3.25 h-3.47c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-2.55c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15h3.96 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.79c0,0.14-0.05,0.25-0.14,0.35S1523.77,762.25,1523.63,762.25z  M1523.15,757.01v-1.55h-2.99v1.55H1523.15z' />
            </g>
            <g>
              <path d='M1309.05,735.81v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1309.05,735.95,1309.05,735.81z' />
              <path d='M1319.96,729.23l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1319.34,728.51,1319.73,728.75,1319.96,729.23z M1318.46,729.73 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1318.54,729.57,1318.46,729.73z' />
              <path d='M1323.57,736.3v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1323.57z' />
              <path d='M1331.29,728.51h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1331.16,728.51,1331.29,728.51z M1332.78,729.51v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1332.78z' />
              <path d='M1338.54,728.51h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1338.41,728.51,1338.54,728.51z' />
              <path d='M1350.27,729.02v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1350.27,728.88,1350.27,729.02z' />
              <path d='M1355.41,728.51h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1355.21,728.51,1355.41,728.51z' />
              <path d='M1322.64,749.72h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1322.26,749.61,1322.42,749.72,1322.64,749.72z' />
              <path d='M1331.65,743.91h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1331.79,743.91,1331.65,743.91z' />
              <path d='M1334.44,750.7v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1334.44z' />
              <path d='M1341.69,750.7v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1341.69z' />
              <path d='M1321.92,762.11v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49V760h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1321.92z  M1321.92,760.02v1.11h0.98v-1.11H1321.92z' />
              <path d='M1327.65,764.11h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1327.51,764.11,1327.65,764.11z' />
              <path d='M1336.24,764.11h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1336.07,764.08,1336.15,764.11,1336.24,764.11z' />
              <path d='M1341.65,764.09v-2.4c0-0.27,0.1-0.5,0.29-0.69s0.43-0.29,0.7-0.29v-2.9c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14 h2c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35v2.9c0.27,0,0.5,0.09,0.69,0.28s0.29,0.42,0.29,0.7v2.41 c0,0.27-0.1,0.5-0.29,0.69s-0.43,0.29-0.69,0.29h-2.99c-0.28,0-0.51-0.1-0.71-0.3S1341.65,764.35,1341.65,764.09z M1342.65,761.69 v2.41h2.99v-2.41H1342.65z M1343.63,758.3v2.41h1.02v-2.41H1343.63z' />
            </g>
            <g>
              <path d='M1159.72,736.73v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1159.72,736.87,1159.72,736.73z' />
              <path d='M1170.64,730.16l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1170.01,729.44,1170.4,729.68,1170.64,730.16z M1169.14,730.65 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1169.21,730.5,1169.14,730.65z' />
              <path d='M1174.24,737.22v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1174.24z' />
              <path d='M1181.97,729.44h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1181.83,729.44,1181.97,729.44z M1183.46,730.43v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1183.46z' />
              <path d='M1189.22,729.44h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1189.08,729.44,1189.22,729.44z' />
              <path d='M1200.94,729.94v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1200.94,729.8,1200.94,729.94z' />
              <path d='M1206.08,729.44h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1205.88,729.44,1206.08,729.44z' />
              <path d='M1173.31,750.64h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1172.93,750.54,1173.09,750.64,1173.31,750.64z' />
              <path d='M1182.33,744.83h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1182.46,744.83,1182.33,744.83z' />
              <path d='M1185.11,751.62v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1185.11z' />
              <path d='M1192.36,751.62v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1192.36z' />
              <path d='M1172.6,763.03v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1172.6z  M1172.6,760.94v1.11h0.98v-1.11H1172.6z' />
              <path d='M1178.32,765.03h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1178.18,765.03,1178.32,765.03z' />
              <path d='M1186.91,765.03h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1186.75,765,1186.83,765.03,1186.91,765.03z' />
              <path d='M1192.86,758.23h0.49c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14 v3.25h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35v2.54c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-3.95 c-0.14,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-6.79c0-0.14,0.05-0.25,0.15-0.35S1192.72,758.23,1192.86,758.23z  M1193.34,763.47v1.56h2.98v-1.56H1193.34z' />
            </g>
            <g>
              <path d='M1007.97,735.81v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1007.97,735.95,1007.97,735.81z' />
              <path d='M1018.88,729.23l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1018.26,728.51,1018.65,728.75,1018.88,729.23z M1017.38,729.73 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1017.46,729.57,1017.38,729.73z' />
              <path d='M1022.49,736.3v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1022.49z' />
              <path d='M1030.22,728.51h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1030.08,728.51,1030.22,728.51z M1031.7,729.51v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1031.7z' />
              <path d='M1037.46,728.51h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1037.33,728.51,1037.46,728.51z' />
              <path d='M1049.19,729.02v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1049.19,728.88,1049.19,729.02z' />
              <path d='M1054.33,728.51h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1054.13,728.51,1054.33,728.51z' />
              <path d='M1021.56,749.72h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1021.18,749.61,1021.34,749.72,1021.56,749.72z' />
              <path d='M1030.58,743.91h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1030.71,743.91,1030.58,743.91z' />
              <path d='M1033.36,750.7v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1033.36z' />
              <path d='M1040.61,750.7v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1040.61z' />
              <path d='M1020.84,762.11v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49V760h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1020.84z  M1020.84,760.02v1.11h0.98v-1.11H1020.84z' />
              <path d='M1026.57,764.11h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1026.43,764.11,1026.57,764.11z' />
              <path d='M1035.16,764.11h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1035,764.08,1035.08,764.11,1035.16,764.11z' />
              <path d='M1042.41,764.11h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1042.24,764.08,1042.32,764.11,1042.41,764.11z' />
            </g>
            <g>
              <path d='M881.94,695.81v1.81l-2.01,5.1c-0.1,0.25-0.26,0.37-0.48,0.37c-0.23,0-0.39-0.12-0.49-0.37l-1.99-5.1v-1.81 c0-0.14,0.05-0.26,0.14-0.36c0.1-0.1,0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15c0.1,0.1,0.14,0.22,0.14,0.36v1.6l1.5,3.82l1.5-3.81 v-1.62c0-0.14,0.05-0.26,0.14-0.36c0.1-0.1,0.21-0.15,0.35-0.15c0.14,0,0.25,0.05,0.35,0.15 C881.9,695.55,881.94,695.67,881.94,695.81z' />
              <path d='M884.69,695.31h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S884.55,695.31,884.69,695.31z' />
              <path d='M892.57,695.31h2.56c0.31,0,0.61,0.13,0.89,0.4s0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36s-0.21,0.15-0.33,0.15 c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42c-0.06-0.21-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.12c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C891.99,695.42,892.26,695.31,892.57,695.31z' />
              <path d='M899.19,695.31h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S899.05,695.31,899.19,695.31z' />
              <path d='M905.94,696.65v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S905.94,696.79,905.94,696.65z' />
              <path d='M882.57,714.51v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H882.57z  M882.57,712.41v1.11h0.98v-1.11H882.57z' />
              <path d='M888.29,716.5h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S888.15,716.5,888.29,716.5z' />
              <path d='M898.3,716.99v-2.05h-2.96v-4.74c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15 s0.15,0.22,0.15,0.35v3.74h1.97v-2.88c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.35 v2.88c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14v2.06 c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.14S898.3,717.13,898.3,716.99z' />
              <path d='M906.8,717.47h-0.49c-0.13,0-0.25-0.05-0.35-0.14s-0.15-0.21-0.15-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14v-3.25 h-3.47c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-2.55c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15h3.96 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.79c0,0.14-0.05,0.25-0.14,0.35S906.93,717.47,906.8,717.47z M906.32,712.24 v-1.55h-2.99v1.55H906.32z' />
            </g>
            <g>
              <path d='M803.81,679.48v1.81l-2.01,5.1c-0.1,0.25-0.26,0.37-0.48,0.37c-0.23,0-0.39-0.12-0.49-0.37l-1.99-5.1v-1.81 c0-0.14,0.05-0.26,0.14-0.36c0.1-0.1,0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15c0.1,0.1,0.14,0.22,0.14,0.36v1.6l1.5,3.82l1.5-3.81 v-1.62c0-0.14,0.05-0.26,0.14-0.36c0.1-0.1,0.21-0.15,0.35-0.15c0.14,0,0.25,0.05,0.35,0.15 C803.77,679.22,803.81,679.33,803.81,679.48z' />
              <path d='M806.56,678.97h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S806.42,678.97,806.56,678.97z' />
              <path d='M814.44,678.97H817c0.31,0,0.61,0.13,0.89,0.4s0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36s-0.21,0.15-0.33,0.15 c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42c-0.06-0.21-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.12c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C813.86,679.08,814.13,678.97,814.44,678.97z' />
              <path d='M821.06,678.97h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S820.92,678.97,821.06,678.97z' />
              <path d='M827.81,680.31v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S827.81,680.45,827.81,680.31z' />
              <path d='M804.44,698.17v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H804.44z  M804.44,696.07v1.11h0.98v-1.11H804.44z' />
              <path d='M810.16,700.16h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S810.03,700.16,810.16,700.16z' />
              <path d='M820.17,700.65v-2.05h-2.96v-4.74c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15 s0.15,0.22,0.15,0.35v3.74h1.97v-2.88c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.35 v2.88c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14v2.06 c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.14S820.17,700.79,820.17,700.65z' />
              <path d='M824.17,700.15v-2.4c0-0.27,0.1-0.5,0.29-0.69s0.43-0.29,0.7-0.29v-2.9c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2 c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35v2.9c0.27,0,0.5,0.09,0.69,0.28s0.29,0.42,0.29,0.7v2.41 c0,0.27-0.1,0.5-0.29,0.69s-0.43,0.29-0.69,0.29h-2.99c-0.28,0-0.51-0.1-0.71-0.3S824.17,700.41,824.17,700.15z M825.16,697.75 v2.41h2.99v-2.41H825.16z M826.15,694.36v2.41h1.02v-2.41H826.15z' />
            </g>
            <g>
              <path d='M725.35,803.61v-7.29h4.46c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35 s-0.21,0.15-0.35,0.15h-3.48v1.55h2.5c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14 h-2.5v3.77c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14S725.35,803.74,725.35,803.61z' />
              <path d='M732.61,803.61v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S732.61,803.74,732.61,803.61z M733.6,798.87h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V798.87z' />
              <path d='M743.49,797.03l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C742.87,796.31,743.26,796.55,743.49,797.03z M741.99,797.52 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S742.07,797.37,741.99,797.52z' />
              <path d='M752.06,796.82v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S752.06,796.67,752.06,796.82z' />
              <path d='M754.33,797.65v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S754.33,797.79,754.33,797.65z' />
              <path d='M768.84,804.1v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H768.84z' />
              <path d='M779.74,797.03l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C779.11,796.31,779.5,796.55,779.74,797.03z M778.24,797.52 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S778.31,797.37,778.24,797.52z' />
              <path d='M788.3,801.68v0.43c0,0.54-0.19,1.01-0.58,1.4s-0.85,0.58-1.39,0.58h-3.01v-7.79h3.01c0.54,0,1.01,0.19,1.39,0.58 s0.58,0.85,0.58,1.4v0.42c0,0.58-0.22,1.08-0.67,1.49C788.07,800.61,788.3,801.11,788.3,801.68z M787.29,798.7v-0.4 c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-2v2.38h2c0.27,0,0.5-0.1,0.69-0.29S787.29,798.97,787.29,798.7z M787.29,802.12 v-0.41c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-1.99v2.39h1.99c0.27,0,0.5-0.1,0.69-0.29S787.29,802.39,787.29,802.12z' />
              <path d='M795.54,801.68v0.43c0,0.54-0.19,1.01-0.58,1.4s-0.85,0.58-1.39,0.58h-3.01v-7.79h3.01c0.54,0,1.01,0.19,1.39,0.58 s0.58,0.85,0.58,1.4v0.42c0,0.58-0.22,1.08-0.67,1.49C795.32,800.61,795.54,801.11,795.54,801.68z M794.54,798.7v-0.4 c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-2v2.38h2c0.27,0,0.5-0.1,0.69-0.29S794.54,798.97,794.54,798.7z M794.54,802.12 v-0.41c0-0.27-0.1-0.51-0.29-0.7s-0.42-0.29-0.69-0.29h-1.99v2.39h1.99c0.27,0,0.5-0.1,0.69-0.29S794.54,802.39,794.54,802.12z' />
              <path d='M800.82,800.36v3.24c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14s-0.15-0.21-0.15-0.35 v-3.24l-1.98-2.51v-1.04c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.36v0.68l1.48,1.9 l1.5-1.9v-0.68c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.14s0.15,0.21,0.15,0.37v1.03 L800.82,800.36z' />
            </g>
            <g>
              <path d='M712.9,646.73h1.78c0.14,0,0.26,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C712.51,646.62,712.67,646.73,712.9,646.73z' />
              <path d='M721.91,640.92h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S722.05,640.92,721.91,640.92z' />
              <path d='M729.65,640.43v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S729.65,640.29,729.65,640.43z' />
              <path d='M731.93,641.27v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S731.93,641.4,731.93,641.27z' />
              <path d='M739.2,647.22v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S739.2,647.36,739.2,647.22z M740.19,642.48h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V642.48z' />
              <path d='M746.47,647.03l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C746.44,647.15,746.45,647.09,746.47,647.03z M748.94,642.2l-0.84,2.82h1.67L748.94,642.2z' />
              <path d='M753.69,647.71v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H753.69z' />
              <path d='M712.9,661.13h1.78c0.14,0,0.26,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C712.51,661.02,712.67,661.13,712.9,661.13z' />
              <path d='M721.09,655.04l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C720.46,654.32,720.86,654.56,721.09,655.04z M719.59,655.54 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S719.67,655.38,719.59,655.54z' />
              <path d='M729.65,654.83v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S729.65,654.69,729.65,654.83z' />
              <path d='M731.93,655.67v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S731.93,655.8,731.93,655.67z' />
              <path d='M739.2,661.62v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S739.2,661.76,739.2,661.62z M740.19,656.88h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V656.88z' />
              <path d='M750.08,655.04l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C749.46,654.32,749.85,654.56,750.08,655.04z M748.58,655.54 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S748.66,655.38,748.58,655.54z' />
              <path d='M753.69,662.11v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H753.69z' />
              <path d='M723.05,673.52v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H723.05z  M723.05,671.42v1.11h0.98v-1.11H723.05z' />
              <path d='M728.77,675.51h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S728.64,675.51,728.77,675.51z' />
              <path d='M736.02,675.51h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S735.89,675.51,736.02,675.51z' />
              <path d='M744.61,675.51h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C744.45,675.49,744.53,675.51,744.61,675.51z' />
            </g>
            <g>
              <path d='M599.03,652.25v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v2.9 h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S599.03,652.39,599.03,652.25z' />
              <path d='M606.33,652.06l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C606.3,652.18,606.31,652.12,606.33,652.06z M608.8,647.23l-0.84,2.82h1.67L608.8,647.23z' />
              <path d='M613.55,652.74v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H613.55z' />
              <path d='M620.79,652.74v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H620.79z' />
              <path d='M631.02,648v3.28l0.29,0.48h0.41l0.3-0.49v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.98c0,0.07-0.04,0.18-0.12,0.31l-0.46,0.76c-0.09,0.17-0.24,0.25-0.43,0.25 h-0.93c-0.29,0-0.48-0.13-0.56-0.4c-0.09,0.27-0.27,0.4-0.54,0.4h-0.87c-0.25,0-0.43-0.09-0.54-0.28l-0.42-0.71 c-0.08-0.13-0.12-0.24-0.12-0.33v-5.96c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.15 s0.14,0.22,0.14,0.35v5.83l0.29,0.47h0.42l0.31-0.49V648c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14s0.25,0.05,0.35,0.14 S631.02,647.87,631.02,648z' />
              <path d='M635.32,652.06l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C635.29,652.18,635.3,652.12,635.32,652.06z M637.79,647.23l-0.84,2.82h1.67L637.79,647.23z' />
              <path d='M645.53,649.01v3.24c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14s-0.15-0.21-0.15-0.35 v-3.24l-1.98-2.51v-1.04c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.36v0.68l1.48,1.9 l1.5-1.9v-0.68c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.14s0.15,0.21,0.15,0.37v1.03 L645.53,649.01z' />
              <path d='M608.28,664.15v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H608.28z  M608.28,662.06v1.11h0.98v-1.11H608.28z' />
              <path d='M614,666.15h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14 h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35v2.54 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14H614c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35 c0-0.13,0.05-0.25,0.14-0.34S613.87,666.15,614,666.15z' />
              <path d='M621.25,666.15h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S621.11,666.15,621.25,666.15z' />
              <path d='M633,660.34v1.85c0,0.43-0.13,0.78-0.4,1.04c0.27,0.3,0.41,0.64,0.41,1v1.92c0,0.27-0.1,0.5-0.29,0.69 s-0.43,0.29-0.7,0.29h-3.48c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14H632v-1.92 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.15-0.35-0.15h-1.98c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.99c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.9h-3.52 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29 S633,660.07,633,660.34z' />
              <path d='M635.32,666.46l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C635.29,666.58,635.3,666.52,635.32,666.46z M637.79,661.64l-0.84,2.82h1.67L637.79,661.64z' />
            </g>
            <g>
              <path d='M1267.82,621.15v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1267.82,621.29,1267.82,621.15z' />
              <path d='M1275.12,620.96l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1275.09,621.08,1275.1,621.02,1275.12,620.96z M1277.59,616.14l-0.84,2.82h1.67L1277.59,616.14z' />
              <path d='M1282.34,621.65v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1282.34z' />
              <path d='M1289.59,621.65v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1289.59z' />
              <path d='M1299.81,616.91v3.28l0.29,0.48h0.41l0.3-0.49v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.98c0,0.07-0.04,0.18-0.12,0.31l-0.46,0.76c-0.09,0.17-0.24,0.25-0.43,0.25 h-0.93c-0.29,0-0.48-0.13-0.56-0.4c-0.09,0.27-0.27,0.4-0.54,0.4h-0.87c-0.25,0-0.43-0.09-0.54-0.28l-0.42-0.71 c-0.08-0.13-0.12-0.24-0.12-0.33v-5.96c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.15 s0.14,0.22,0.14,0.35v5.83l0.29,0.47h0.42l0.31-0.49v-3.26c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14s0.25,0.05,0.35,0.14 S1299.81,616.77,1299.81,616.91z' />
              <path d='M1304.11,620.96l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1304.08,621.08,1304.09,621.02,1304.11,620.96z M1306.59,616.14l-0.84,2.82h1.67L1306.59,616.14z' />
              <path d='M1314.32,617.91v3.24c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14s-0.15-0.21-0.15-0.35 v-3.24l-1.98-2.51v-1.04c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.36v0.68l1.48,1.9 l1.5-1.9v-0.68c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.14s0.15,0.21,0.15,0.37v1.03 L1314.32,617.91z' />
              <path d='M1327.81,618.66v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1327.81z  M1327.81,616.56v1.11h0.98v-1.11H1327.81z' />
              <path d='M1333.53,620.65h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1333.4,620.65,1333.53,620.65z' />
              <path d='M1342.12,620.65h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1341.96,620.63,1342.04,620.65,1342.12,620.65z' />
              <path d='M1347.55,620.69v-5.89c0-0.26,0.09-0.48,0.28-0.67s0.41-0.28,0.67-0.28h3.07c0.25,0,0.47,0.09,0.66,0.28 s0.28,0.41,0.28,0.67v5.89c0,0.26-0.09,0.49-0.28,0.67s-0.41,0.28-0.67,0.28h-3.06c-0.27,0-0.49-0.09-0.67-0.28 S1347.55,620.95,1347.55,620.69z M1351.53,620.65v-5.81h-3v5.81H1351.53z' />
            </g>
            <g>
              <path d='M829.08,621.15v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v2.9 h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S829.08,621.29,829.08,621.15z' />
              <path d='M836.38,620.96l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C836.35,621.08,836.36,621.02,836.38,620.96z M838.85,616.14l-0.84,2.82h1.67L838.85,616.14z' />
              <path d='M843.6,621.65v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H843.6z' />
              <path d='M850.84,621.65v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H850.84z' />
              <path d='M861.07,616.91v3.28l0.29,0.48h0.41l0.3-0.49v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.98c0,0.07-0.04,0.18-0.12,0.31l-0.46,0.76c-0.09,0.17-0.24,0.25-0.43,0.25 h-0.93c-0.29,0-0.48-0.13-0.56-0.4c-0.09,0.27-0.27,0.4-0.54,0.4h-0.87c-0.25,0-0.43-0.09-0.54-0.28l-0.42-0.71 c-0.08-0.13-0.12-0.24-0.12-0.33v-5.96c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.15 s0.14,0.22,0.14,0.35v5.83l0.29,0.47h0.42l0.31-0.49v-3.26c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14s0.25,0.05,0.35,0.14 S861.07,616.77,861.07,616.91z' />
              <path d='M865.37,620.96l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C865.34,621.08,865.35,621.02,865.37,620.96z M867.84,616.14l-0.84,2.82h1.67L867.84,616.14z' />
              <path d='M875.58,617.91v3.24c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14s-0.15-0.21-0.15-0.35 v-3.24l-1.98-2.51v-1.04c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.36v0.68l1.48,1.9 l1.5-1.9v-0.68c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.14s0.15,0.21,0.15,0.37v1.03 L875.58,617.91z' />
              <path d='M889.06,618.66v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H889.06z  M889.06,616.56v1.11h0.98v-1.11H889.06z' />
              <path d='M894.79,620.65h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S894.65,620.65,894.79,620.65z' />
              <path d='M904.8,621.14v-2.05h-2.96v-4.74c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15 s0.15,0.22,0.15,0.35v3.74h1.97v-2.88c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.35 v2.88c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14v2.06 c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.14S904.8,621.28,904.8,621.14z' />
              <path d='M908.8,614.79v-0.42c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h4.46v3.02c0,0.16-0.06,0.29-0.18,0.4l-1.81,1.55 v2.29c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-2.49 c0-0.19,0.06-0.33,0.17-0.43l1.82-1.54v-1.83h-2.98c-0.07,0.28-0.23,0.42-0.49,0.42c-0.14,0-0.25-0.05-0.35-0.14 S908.8,614.92,908.8,614.79z' />
            </g>
            <g>
              <path d='M884.57,433.72v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.82 c0,0.13,0.05,0.24,0.15,0.34s0.21,0.14,0.35,0.14h2.02c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.34v-5.81 c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v5.79c0,0.41-0.15,0.76-0.44,1.05 s-0.64,0.44-1.04,0.44h-2.02c-0.41,0-0.75-0.14-1.04-0.43S884.57,434.12,884.57,433.72z' />
              <path d='M891.82,428.75v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S891.82,428.89,891.82,428.75z' />
              <path d='M899.56,427.41h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S899.42,427.41,899.56,427.41z' />
              <path d='M906.33,435.19v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H906.33z' />
              <path d='M914.06,427.41h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S913.92,427.41,914.06,427.41z' />
              <path d='M920.81,428.75v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S920.81,428.89,920.81,428.75z' />
              <path d='M931.06,431.46v3.24c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14s-0.15-0.21-0.15-0.35 v-3.24l-1.98-2.51v-1.04c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.36v0.68l1.48,1.9 l1.5-1.9v-0.68c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.14s0.15,0.21,0.15,0.37v1.03 L931.06,431.46z' />
              <path d='M942.58,434.7v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S942.58,434.84,942.58,434.7z M943.56,429.96h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V429.96z' />
              <path d='M953.46,428.13l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C952.83,427.41,953.23,427.65,953.46,428.13z M951.96,428.62 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S952.04,428.47,951.96,428.62z' />
              <path d='M960.71,428.13l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C960.08,427.41,960.47,427.65,960.71,428.13z M959.21,428.62 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S959.29,428.47,959.21,428.62z' />
              <path d='M966.29,430.47v-0.25l-1.02-1.45v5.94c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35v-7.29h1.27l1.21,1.76l1.25-1.76h1.25v7.29c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-5.92l-1,1.41v0.28c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 c-0.14,0-0.25-0.05-0.35-0.15S966.29,430.6,966.29,430.47z' />
              <path d='M915.56,446.61v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H915.56z  M915.56,444.51v1.11h0.98v-1.11H915.56z' />
              <path d='M921.28,448.6h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S921.14,448.6,921.28,448.6z' />
              <path d='M933.03,442.79v1.85c0,0.43-0.13,0.78-0.4,1.04c0.27,0.3,0.41,0.64,0.41,1v1.92c0,0.27-0.1,0.5-0.29,0.69 s-0.43,0.29-0.7,0.29h-3.48c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.47 v-1.92c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.15-0.35-0.15h-1.98c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.99c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.9h-3.52 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29 S933.03,442.52,933.03,442.79z' />
              <path d='M939.78,449.57h-0.49c-0.13,0-0.25-0.05-0.35-0.14s-0.15-0.21-0.15-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14v-3.25 h-3.47c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-2.55c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15h3.96 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.79c0,0.14-0.05,0.25-0.14,0.35S939.92,449.57,939.78,449.57z M939.3,444.34 v-1.55h-2.99v1.55H939.3z' />
            </g>
            <g>
              <path d='M886.88,505.88v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S886.88,505.74,886.88,505.88z' />
              <path d='M889.16,511.69v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.82 c0,0.13,0.05,0.24,0.15,0.34c0.1,0.1,0.21,0.14,0.35,0.14h2.02c0.14,0,0.25-0.05,0.35-0.14c0.1-0.09,0.14-0.21,0.14-0.34v-5.81 c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v5.79c0,0.41-0.15,0.76-0.44,1.05 s-0.64,0.44-1.04,0.44h-2.02c-0.41,0-0.75-0.14-1.04-0.43S889.16,512.1,889.16,511.69z' />
              <path d='M896.43,512.68v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S896.43,512.81,896.43,512.68z M897.41,507.94h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.14-0.35-0.14h-2.5V507.94z' />
              <path d='M904.78,505.38h2.56c0.31,0,0.61,0.13,0.89,0.4s0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36s-0.21,0.15-0.33,0.15 c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42c-0.06-0.21-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.12c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C904.2,505.49,904.47,505.38,904.78,505.38z' />
              <path d='M915.38,506.38h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S915.52,506.38,915.38,506.38z' />
              <path d='M919.27,505.38h2.56c0.31,0,0.61,0.13,0.89,0.4s0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36s-0.21,0.15-0.33,0.15 c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42c-0.06-0.21-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.12c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C918.7,505.49,918.96,505.38,919.27,505.38z' />
              <path d='M879.41,519.78h2.56c0.31,0,0.61,0.13,0.89,0.4s0.42,0.58,0.42,0.96c0,0.14-0.05,0.26-0.15,0.36s-0.21,0.15-0.33,0.15 c-0.17,0-0.28-0.04-0.35-0.11s-0.12-0.22-0.18-0.42c-0.06-0.21-0.18-0.31-0.38-0.31h-2.44c-0.12,0-0.18,0.04-0.18,0.11 c0,0.05,0.02,0.1,0.07,0.17l3.66,4.67c0.18,0.23,0.26,0.47,0.26,0.73c0,0.29-0.11,0.55-0.32,0.77s-0.45,0.33-0.71,0.33h-2.65 c-0.35,0-0.66-0.13-0.92-0.4s-0.39-0.56-0.39-0.88c0-0.37,0.17-0.55,0.52-0.55c0.13,0,0.25,0.05,0.34,0.14s0.14,0.21,0.14,0.34 c0,0.11,0.04,0.19,0.11,0.26s0.16,0.11,0.27,0.11h2.45c0.11,0,0.16-0.04,0.16-0.12c0-0.04-0.02-0.09-0.07-0.15l-3.65-4.67 c-0.17-0.22-0.26-0.46-0.26-0.73c0-0.3,0.11-0.57,0.33-0.79C878.83,519.89,879.1,519.78,879.41,519.78z' />
              <path d='M885.53,521.12v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S885.53,521.26,885.53,521.12z' />
              <path d='M892.82,526.88l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C892.79,527.01,892.8,526.94,892.82,526.88z M895.3,522.06l-0.84,2.82h1.67L895.3,522.06z' />
              <path d='M900.03,521.12v-1.33H905v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S900.03,521.26,900.03,521.12z' />
              <path d='M907.77,519.78h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S907.63,519.78,907.77,519.78z' />
              <path d='M918.18,520.5l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C917.56,519.78,917.95,520.02,918.18,520.5z M916.68,520.99 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S916.76,520.84,916.68,520.99z' />
              <path d='M926.74,520.28v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S926.74,520.14,926.74,520.28z' />
              <path d='M891.15,538.98v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H891.15z  M891.15,536.88v1.11h0.98v-1.11H891.15z' />
              <path d='M896.88,540.97h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S896.74,540.97,896.88,540.97z' />
              <path d='M908.13,541.96h-4.48v-3.4c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S908.26,541.96,908.13,541.96z' />
              <path d='M911.42,534.18h0.49c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14 v3.25h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35v2.54c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-3.95 c-0.14,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-6.79c0-0.14,0.05-0.25,0.15-0.35S911.28,534.18,911.42,534.18z M911.9,539.41 v1.56h2.98v-1.56H911.9z' />
            </g>
            <g>
              <path d='M1038.53,536.38v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1038.53,536.52,1038.53,536.38z' />
              <path d='M1045.84,536.19l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1045.81,536.31,1045.82,536.25,1045.84,536.19z M1048.31,531.36l-0.84,2.82h1.67L1048.31,531.36z' />
              <path d='M1053.05,536.87v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1053.05z' />
              <path d='M1060.3,536.87v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1060.3z' />
              <path d='M1070.53,532.13v3.28l0.29,0.48h0.41l0.3-0.49v-5.81c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15 c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v5.98c0,0.07-0.04,0.18-0.12,0.31l-0.46,0.76c-0.09,0.17-0.24,0.25-0.43,0.25 h-0.93c-0.29,0-0.48-0.13-0.56-0.4c-0.09,0.27-0.27,0.4-0.54,0.4h-0.87c-0.25,0-0.43-0.09-0.54-0.28l-0.42-0.71 c-0.08-0.13-0.12-0.24-0.12-0.33v-5.96c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.15 s0.14,0.22,0.14,0.35v5.83l0.29,0.47h0.42l0.31-0.49v-3.26c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14s0.25,0.05,0.35,0.14 S1070.53,531.99,1070.53,532.13z' />
              <path d='M1074.83,536.19l1.98-6.74c0.08-0.24,0.25-0.37,0.5-0.37c0.25,0,0.4,0.1,0.46,0.31l1.96,6.84 c0.02,0.05,0.02,0.1,0.02,0.15c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25 l-0.43,1.43c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15 c-0.09-0.1-0.14-0.22-0.14-0.36C1074.8,536.31,1074.81,536.25,1074.83,536.19z M1077.3,531.36l-0.84,2.82h1.67L1077.3,531.36z' />
              <path d='M1085.03,533.13v3.24c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14s-0.15-0.21-0.15-0.35 v-3.24l-1.98-2.51v-1.04c0-0.14,0.05-0.26,0.14-0.36s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.36v0.68l1.48,1.9 l1.5-1.9v-0.68c0-0.14,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15c0.13,0,0.24,0.05,0.34,0.14s0.15,0.21,0.15,0.37v1.03 L1085.03,533.13z' />
              <path d='M1051.41,548.28v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1051.41z  M1051.41,546.19v1.11h0.98v-1.11H1051.41z' />
              <path d='M1057.14,550.28h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1057,550.28,1057.14,550.28z' />
              <path d='M1065.73,550.28h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1065.56,550.25,1065.64,550.28,1065.73,550.28z' />
              <path d='M1071.63,550.28h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1071.49,550.28,1071.63,550.28z' />
            </g>
            <g>
              <path d='M1040.28,443.04v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1040.28,443.17,1040.28,443.04z' />
              <path d='M1051.2,436.46l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1050.58,435.74,1050.97,435.98,1051.2,436.46z M1049.7,436.95 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1049.78,436.8,1049.7,436.95z' />
              <path d='M1054.8,443.53v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31h3.48 c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1054.8z' />
              <path d='M1062.53,435.74h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1062.4,435.74,1062.53,435.74z M1064.02,436.74v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1064.02z' />
              <path d='M1069.78,435.74h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1069.64,435.74,1069.78,435.74z' />
              <path d='M1081.51,436.24v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1081.51,436.1,1081.51,436.24z' />
              <path d='M1086.65,435.74h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1086.45,435.74,1086.65,435.74z' />
              <path d='M1053.88,456.94h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1053.5,456.84,1053.66,456.94,1053.88,456.94z' />
              <path d='M1062.89,451.14h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1063.03,451.14,1062.89,451.14z' />
              <path d='M1065.68,457.93v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1065.68z' />
              <path d='M1072.92,457.93v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1072.92z' />
              <path d='M1053.16,469.34v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1053.16z  M1053.16,467.24v1.11h0.98v-1.11H1053.16z' />
              <path d='M1058.89,471.33h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1058.75,471.33,1058.89,471.33z' />
              <path d='M1067.48,471.33h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1067.31,471.31,1067.39,471.33,1067.48,471.33z' />
              <path d='M1077.39,472.32h-4.48v-3.4c0-0.27,0.1-0.51,0.29-0.7s0.42-0.29,0.69-0.29h2.99v-2.4h-3.52c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29s0.29,0.42,0.29,0.69v2.4 c0,0.28-0.1,0.51-0.29,0.71s-0.43,0.29-0.7,0.29h-2.99v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35 s-0.05,0.25-0.15,0.35S1077.52,472.32,1077.39,472.32z' />
            </g>
            <g>
              <path d='M1169.17,475.95v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1169.17,476.09,1169.17,475.95z' />
              <path d='M1180.09,469.38l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1179.46,468.65,1179.85,468.9,1180.09,469.38z M1178.59,469.87 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1178.67,469.72,1178.59,469.87z' />
              <path d='M1183.69,476.44v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1183.69z' />
              <path d='M1191.42,468.65h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1191.28,468.65,1191.42,468.65z M1192.91,469.65v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1192.91z' />
              <path d='M1198.67,468.65h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1198.53,468.65,1198.67,468.65z' />
              <path d='M1210.39,469.16v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1210.39,469.02,1210.39,469.16z' />
              <path d='M1215.54,468.65h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1215.33,468.65,1215.54,468.65z' />
              <path d='M1182.77,489.86h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1182.38,489.76,1182.54,489.86,1182.77,489.86z' />
              <path d='M1191.78,484.05h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1191.92,484.05,1191.78,484.05z' />
              <path d='M1194.56,490.84v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1194.56z' />
              <path d='M1201.81,490.84v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1201.81z' />
              <path d='M1182.05,502.25v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1182.05z  M1182.05,500.16v1.11h0.98v-1.11H1182.05z' />
              <path d='M1187.77,504.25h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1187.64,504.25,1187.77,504.25z' />
              <path d='M1196.36,504.25h2.28v-2.41h-2.98v-4.38h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.14,0.35 s-0.21,0.14-0.35,0.14h-2.47v2.41h1.98c0.27,0,0.5,0.1,0.69,0.29s0.29,0.43,0.29,0.69v2.41c0,0.27-0.1,0.5-0.29,0.69 s-0.42,0.29-0.69,0.29h-2.28c-0.2,0-0.42-0.05-0.65-0.15l-0.74-0.31c-0.2-0.08-0.3-0.24-0.3-0.47c0-0.14,0.04-0.25,0.13-0.35 s0.2-0.14,0.33-0.14c0.1,0,0.21,0.03,0.34,0.09l0.65,0.28C1196.2,504.22,1196.28,504.25,1196.36,504.25z' />
              <path d='M1206.77,498.44v1.85c0,0.43-0.13,0.78-0.4,1.04c0.27,0.3,0.41,0.64,0.41,1v1.92c0,0.27-0.1,0.5-0.29,0.69 s-0.43,0.29-0.7,0.29h-3.48c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.47 v-1.92c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.15-0.35-0.15h-1.98c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.99c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.9h-3.52 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29 S1206.77,498.17,1206.77,498.44z' />
            </g>
            <g>
              <path d='M1312.12,475.95v-6.8c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35 v2.9h3v-2.9c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.14,0.22,0.14,0.36v6.79 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.34,0.15c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35v-2.92h-2.99v2.92 c0,0.13-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.14S1312.12,476.09,1312.12,475.95z' />
              <path d='M1323.04,469.38l1.08,2.21c0.15,0.3,0.23,0.63,0.23,0.98c0,0.34-0.08,0.67-0.23,0.97l-1.08,2.19 c-0.23,0.48-0.62,0.72-1.17,0.72c-0.26,0-0.5-0.07-0.71-0.21s-0.37-0.31-0.47-0.52l-1.09-2.19c-0.14-0.29-0.22-0.61-0.22-0.97 s0.07-0.69,0.22-0.98l1.09-2.21c0.25-0.48,0.64-0.72,1.18-0.72C1322.41,468.65,1322.81,468.9,1323.04,469.38z M1321.54,469.87 l-1.02,2.07c-0.09,0.21-0.14,0.41-0.14,0.61c0,0.21,0.05,0.42,0.14,0.62l1.05,2.1c0.05,0.13,0.15,0.19,0.3,0.19 c0.12,0,0.22-0.06,0.29-0.19l1.05-2.1c0.1-0.21,0.15-0.41,0.15-0.62c0-0.21-0.05-0.42-0.15-0.61l-1.03-2.07 c-0.07-0.15-0.17-0.23-0.31-0.23S1321.62,469.72,1321.54,469.87z' />
              <path d='M1326.64,476.44v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1326.64z' />
              <path d='M1334.37,468.65h1.95c0.25,0,0.5,0.09,0.74,0.25s0.42,0.35,0.53,0.58l0.97,1.97c0.18,0.36,0.28,0.73,0.28,1.11 c0,0.36-0.1,0.73-0.29,1.11l-0.98,1.95c-0.12,0.25-0.3,0.45-0.54,0.6s-0.5,0.23-0.79,0.23h-1.88c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.52v-5.79h-0.5c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35S1334.23,468.65,1334.37,468.65z M1335.86,469.65v5.81l0.34,0 c0.22,0,0.38-0.11,0.49-0.32l0.97-1.93c0.12-0.25,0.18-0.47,0.18-0.64c0-0.21-0.05-0.42-0.15-0.61l-1.01-2 c-0.11-0.2-0.28-0.3-0.52-0.3H1335.86z' />
              <path d='M1341.62,468.65h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S1341.48,468.65,1341.62,468.65z' />
              <path d='M1353.34,469.16v7.28h-1.32l-2.65-6.05v5.55c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35v-7.29h1.3l2.66,6.04v-5.53c0-0.14,0.05-0.25,0.15-0.35s0.22-0.15,0.35-0.15s0.25,0.05,0.35,0.15 S1353.34,469.02,1353.34,469.16z' />
              <path d='M1358.49,468.65h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.14-0.34,0.14 h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59v2.73c0,0.14,0.05,0.26,0.14,0.36 s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02c-0.14,0-0.26-0.05-0.35-0.14 s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04s-0.64,0.43-1.04,0.43h-2 c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85c0.11-0.14,0.25-0.26,0.44-0.36 S1358.29,468.65,1358.49,468.65z' />
              <path d='M1325.72,489.86h1.78c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-1.84 c-0.23,0-0.48-0.07-0.73-0.22s-0.42-0.31-0.52-0.5l-1.14-2.2c-0.15-0.32-0.23-0.64-0.23-0.97c0-0.37,0.07-0.69,0.22-0.96 l1.15-2.17c0.11-0.2,0.28-0.38,0.51-0.54s0.45-0.23,0.67-0.23h1.95c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35 c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15h-1.87c-0.16,0-0.28,0.09-0.38,0.28l-1.09,2.04c-0.09,0.16-0.13,0.35-0.13,0.57 c0,0.23,0.05,0.43,0.14,0.61l1.04,2C1325.34,489.76,1325.5,489.86,1325.72,489.86z' />
              <path d='M1334.73,484.05h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S1334.87,484.05,1334.73,484.05z' />
              <path d='M1337.52,490.84v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1337.52z' />
              <path d='M1344.76,490.84v-7.29c0-0.13,0.05-0.25,0.15-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v6.31 h3.48c0.14,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14H1344.76z' />
              <path d='M1325,502.25v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H1325z M1325,500.16 v1.11h0.98v-1.11H1325z' />
              <path d='M1330.72,504.25h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S1330.59,504.25,1330.72,504.25z' />
              <path d='M1338.02,497.45h0.49c0.14,0,0.25,0.05,0.35,0.15s0.14,0.21,0.14,0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14 v3.25h3.47c0.13,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35v2.54c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14h-3.95 c-0.14,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.35v-6.79c0-0.14,0.05-0.25,0.15-0.35S1337.88,497.45,1338.02,497.45z  M1338.5,502.69v1.56h2.98v-1.56H1338.5z' />
              <path d='M1349.72,498.44v1.85c0,0.43-0.13,0.78-0.4,1.04c0.27,0.3,0.41,0.64,0.41,1v1.92c0,0.27-0.1,0.5-0.29,0.69 s-0.43,0.29-0.7,0.29h-3.48c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.47 v-1.92c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.15-0.35-0.15h-1.98c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.99c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.9h-3.52 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29 S1349.72,498.17,1349.72,498.44z' />
            </g>
            <g>
              <path d='M1436.24,493.74v2.56c0,0.31-0.13,0.61-0.4,0.89c-0.26,0.28-0.58,0.42-0.96,0.42c-0.14,0-0.26-0.05-0.36-0.15 s-0.15-0.21-0.15-0.33c0-0.17,0.04-0.28,0.11-0.35s0.22-0.12,0.42-0.18s0.31-0.18,0.31-0.38v-2.44c0-0.12-0.04-0.18-0.11-0.18 c-0.05,0-0.1,0.02-0.17,0.07l-4.67,3.66c-0.23,0.18-0.47,0.26-0.73,0.26c-0.29,0-0.55-0.11-0.77-0.32s-0.33-0.45-0.33-0.71v-2.65 c0-0.35,0.13-0.66,0.4-0.92s0.56-0.39,0.88-0.39c0.37,0,0.55,0.17,0.55,0.52c0,0.13-0.05,0.25-0.14,0.34s-0.21,0.14-0.34,0.14 c-0.11,0-0.19,0.04-0.26,0.11s-0.11,0.16-0.11,0.27v2.45c0,0.11,0.04,0.16,0.12,0.16c0.04,0,0.09-0.02,0.15-0.07l4.67-3.65 c0.22-0.17,0.46-0.26,0.73-0.26c0.3,0,0.57,0.11,0.79,0.33C1436.13,493.17,1436.24,493.44,1436.24,493.74z' />
              <path d='M1428.94,499.86h6.8c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-2.9v3h2.9c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.36,0.14h-6.79 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.34c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h2.92v-2.99h-2.92 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35c0-0.13,0.05-0.25,0.14-0.35S1428.81,499.86,1428.94,499.86z' />
              <path d='M1435.52,510.78l-2.21,1.08c-0.3,0.15-0.63,0.23-0.98,0.23c-0.34,0-0.67-0.08-0.97-0.23l-2.19-1.08 c-0.48-0.23-0.72-0.62-0.72-1.17c0-0.26,0.07-0.5,0.21-0.71s0.31-0.37,0.52-0.47l2.19-1.09c0.29-0.14,0.61-0.22,0.97-0.22 s0.69,0.07,0.98,0.22l2.21,1.09c0.48,0.25,0.72,0.64,0.72,1.18C1436.24,510.15,1436,510.54,1435.52,510.78z M1435.03,509.28 l-2.07-1.02c-0.21-0.09-0.41-0.14-0.61-0.14c-0.21,0-0.42,0.05-0.62,0.14l-2.1,1.05c-0.13,0.05-0.19,0.15-0.19,0.3 c0,0.12,0.06,0.22,0.19,0.29l2.1,1.05c0.21,0.1,0.41,0.15,0.62,0.15c0.21,0,0.42-0.05,0.61-0.15l2.07-1.03 c0.15-0.07,0.23-0.17,0.23-0.31S1435.18,509.36,1435.03,509.28z' />
              <path d='M1433.19,517.36h-3.28l-0.48,0.29v0.41l0.49,0.3h5.81c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15h-5.98c-0.07,0-0.18-0.04-0.31-0.12l-0.76-0.46c-0.17-0.09-0.25-0.24-0.25-0.43 v-0.93c0-0.29,0.13-0.48,0.4-0.56c-0.27-0.09-0.4-0.27-0.4-0.54v-0.87c0-0.25,0.09-0.43,0.28-0.54l0.71-0.42 c0.13-0.08,0.24-0.12,0.33-0.12h5.96c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.24-0.15,0.34 s-0.22,0.14-0.35,0.14h-5.83l-0.47,0.29v0.42l0.49,0.31h3.26c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35 s-0.05,0.25-0.14,0.35S1433.33,517.36,1433.19,517.36z' />
              <path d='M1435.24,526.09v-3.48h-2.4v1.5c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-1.5h-2.41v3.49c0,0.13-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35v-4.46h7.79v4.46c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15 S1435.24,526.23,1435.24,526.09z' />
              <path d='M1428.94,528.89h7.29v3.48c0,0.4-0.15,0.75-0.44,1.04s-0.64,0.44-1.05,0.44h-0.56c-0.41,0-0.76-0.14-1.05-0.43 s-0.44-0.64-0.44-1.04v-0.63l-3.46,2.01c-0.1,0.06-0.2,0.09-0.3,0.09c-0.13,0-0.25-0.05-0.34-0.15c-0.1-0.1-0.14-0.22-0.14-0.35 c0-0.2,0.09-0.34,0.26-0.45l3.99-2.3v-0.71h-3.76c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35s0.05-0.25,0.14-0.35 S1428.81,528.89,1428.94,528.89z M1433.68,529.87v2.5c0,0.14,0.05,0.25,0.14,0.35s0.21,0.14,0.35,0.14h0.57 c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-2.5H1433.68z' />
            </g>
            <g>
              <path d='M1436.24,701.45v2.56c0,0.31-0.13,0.61-0.4,0.89c-0.26,0.28-0.58,0.42-0.96,0.42c-0.14,0-0.26-0.05-0.36-0.15 s-0.15-0.21-0.15-0.33c0-0.17,0.04-0.28,0.11-0.35s0.22-0.12,0.42-0.18s0.31-0.18,0.31-0.38v-2.44c0-0.12-0.04-0.18-0.11-0.18 c-0.05,0-0.1,0.02-0.17,0.07l-4.67,3.66c-0.23,0.18-0.47,0.26-0.73,0.26c-0.29,0-0.55-0.11-0.77-0.32s-0.33-0.45-0.33-0.71v-2.65 c0-0.35,0.13-0.66,0.4-0.92s0.56-0.39,0.88-0.39c0.37,0,0.55,0.17,0.55,0.52c0,0.13-0.05,0.25-0.14,0.34s-0.21,0.14-0.34,0.14 c-0.11,0-0.19,0.04-0.26,0.11s-0.11,0.16-0.11,0.27v2.45c0,0.11,0.04,0.16,0.12,0.16c0.04,0,0.09-0.02,0.15-0.07l4.67-3.65 c0.22-0.17,0.46-0.26,0.73-0.26c0.3,0,0.57,0.11,0.79,0.33C1436.13,700.88,1436.24,701.14,1436.24,701.45z' />
              <path d='M1428.94,707.57h6.8c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-2.9v3h2.9c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.36,0.14h-6.79 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.21-0.15-0.34c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h2.92v-2.99h-2.92 c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35c0-0.13,0.05-0.25,0.14-0.35S1428.81,707.57,1428.94,707.57z' />
              <path d='M1435.52,718.48l-2.21,1.08c-0.3,0.15-0.63,0.23-0.98,0.23c-0.34,0-0.67-0.08-0.97-0.23l-2.19-1.08 c-0.48-0.23-0.72-0.62-0.72-1.17c0-0.26,0.07-0.5,0.21-0.71s0.31-0.37,0.52-0.47l2.19-1.09c0.29-0.14,0.61-0.22,0.97-0.22 s0.69,0.07,0.98,0.22l2.21,1.09c0.48,0.25,0.72,0.64,0.72,1.18C1436.24,717.86,1436,718.25,1435.52,718.48z M1435.03,716.98 l-2.07-1.02c-0.21-0.09-0.41-0.14-0.61-0.14c-0.21,0-0.42,0.05-0.62,0.14l-2.1,1.05c-0.13,0.05-0.19,0.15-0.19,0.3 c0,0.12,0.06,0.22,0.19,0.29l2.1,1.05c0.21,0.1,0.41,0.15,0.62,0.15c0.21,0,0.42-0.05,0.61-0.15l2.07-1.03 c0.15-0.07,0.23-0.17,0.23-0.31S1435.18,717.06,1435.03,716.98z' />
              <path d='M1433.19,725.06h-3.28l-0.48,0.29v0.41l0.49,0.3h5.81c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35 c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15h-5.98c-0.07,0-0.18-0.04-0.31-0.12l-0.76-0.46c-0.17-0.09-0.25-0.24-0.25-0.43 v-0.93c0-0.29,0.13-0.48,0.4-0.56c-0.27-0.09-0.4-0.27-0.4-0.54v-0.87c0-0.25,0.09-0.43,0.28-0.54l0.71-0.42 c0.13-0.08,0.24-0.12,0.33-0.12h5.96c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.24-0.15,0.34 s-0.22,0.14-0.35,0.14h-5.83l-0.47,0.29v0.42l0.49,0.31h3.26c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35 s-0.05,0.25-0.14,0.35S1433.33,725.06,1433.19,725.06z' />
              <path d='M1435.24,733.8v-3.48h-2.4v1.5c0,0.13-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-1.5h-2.41v3.49c0,0.13-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35v-4.46h7.79v4.46c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15 S1435.24,733.93,1435.24,733.8z' />
              <path d='M1428.94,736.59h7.29v3.48c0,0.4-0.15,0.75-0.44,1.04s-0.64,0.44-1.05,0.44h-0.56c-0.41,0-0.76-0.14-1.05-0.43 s-0.44-0.64-0.44-1.04v-0.63l-3.46,2.01c-0.1,0.06-0.2,0.09-0.3,0.09c-0.13,0-0.25-0.05-0.34-0.15c-0.1-0.1-0.14-0.22-0.14-0.35 c0-0.2,0.09-0.34,0.26-0.45l3.99-2.3v-0.71h-3.76c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35s0.05-0.25,0.14-0.35 S1428.81,736.59,1428.94,736.59z M1433.68,737.58v2.5c0,0.14,0.05,0.25,0.14,0.35s0.21,0.14,0.35,0.14h0.57 c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-2.5H1433.68z' />
            </g>
          </g>
        )}
        <g id='LINE_00000113355084685275081690000003910489627287298438_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1760.85}
            y1={744.86}
            x2={1920.71}
            y2={744.86}
          />
        </g>
        <g id='LINE_00000036232985848419029710000005203713730546196111_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1797.55}
            y1={731.52}
            x2={1757.52}
            y2={731.52}
          />
        </g>
        <g id='LINE_00000136409082226320033380000009978035197216676532_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1760.85}
            y1={794.07}
            x2={1688.33}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000168826138650347702010000004045023699456807064_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1548.14}
            y1={794.07}
            x2={1643.85}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000132770314823279311520000000917287952690104960_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1577.36}
            y1={781.84}
            x2={1643.85}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000068660467489916250060000006282039742694168223_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1747.51}
            y1={781.84}
            x2={1688.33}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000019653195037065478110000011181045091920148650_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1643.85}
            y1={794.07}
            x2={1643.85}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000091716493203631919350000003910563440032730265_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1688.33}
            y1={794.07}
            x2={1688.33}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000134933746667196029900000017009984502431899786_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1760.85}
            y1={794.07}
            x2={1760.85}
            y2={744.86}
          />
        </g>
        <g id='LINE_00000031172667006949129420000015270758262800808866_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1747.51}
            y1={616.91}
            x2={1747.51}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000159432963288955032560000016018447617456566962_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1757.52}
            y1={731.52}
            x2={1757.52}
            y2={608.01}
          />
        </g>
        <g id='LINE_00000025402320308658823610000004703314461402034853_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1655.78}
            y1={625.8}
            x2={1655.78}
            y2={616.91}
          />
        </g>
        <g id='LINE_00000174595514081239952960000011687240224566706053_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1655.78}
            y1={691.35}
            x2={1655.78}
            y2={663.61}
          />
        </g>
        <g id='LINE_00000143586852982693439840000003401200887838372498_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1646.89}
            y1={625.8}
            x2={1646.89}
            y2={608.01}
          />
        </g>
        <g id='LINE_00000063631487224108124490000008660215826354269619_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1646.89}
            y1={663.61}
            x2={1646.89}
            y2={682.45}
          />
        </g>
        <g id='LINE_00000010286891437245582510000003784279502715657113_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1655.78}
            y1={625.8}
            x2={1646.89}
            y2={625.8}
          />
        </g>
        <g id='LINE_00000169549321741861240840000001936259364088847751_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1655.78}
            y1={663.61}
            x2={1646.89}
            y2={663.61}
          />
        </g>
        <g id='LINE_00000023253011563958774420000007968305804841063093_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1747.51}
            y1={616.91}
            x2={1655.78}
            y2={616.91}
          />
        </g>
        <g id='LINE_00000065045956627044168980000009981651669862743429_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1646.89}
            y1={608.01}
            x2={1683.82}
            y2={608.01}
          />
        </g>
        <g id='LINE_00000020370611334323718860000002814407022346105001_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1693.83}
            y1={608.01}
            x2={1757.52}
            y2={608.01}
          />
        </g>
        <g id='LINE_00000009558325827846034380000006415358944484533642_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1461.91}
            y1={666.95}
            x2={1461.91}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000139998938595659958610000016349442734429559966_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1453.01}
            y1={775.17}
            x2={1453.01}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000163753328785574934460000014585751833108323740_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1412.98}
            y1={666.95}
            x2={1412.98}
            y2={775.17}
          />
        </g>
        <g id='LINE_00000008137992828684171360000007794435433944402081_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1412.98}
            y1={794.07}
            x2={1412.98}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000059298315450185888220000002275093275391992494_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1405.19}
            y1={666.95}
            x2={1405.19}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000116200741360385665740000011522464780265228220_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1412.98}
            y1={840.77}
            x2={1398.58}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000124850532353967357020000009454499610539254407_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1503.33}
            y1={658.05}
            x2={1531.72}
            y2={658.05}
          />
        </g>
        <g id='LINE_00000005242940989934943000000011400138863008923572_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1577.36}
            y1={658.05}
            x2={1549.51}
            y2={658.05}
          />
        </g>
        <g id='LINE_00000125590707818244409660000005864468236339195295_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1465.52}
            y1={658.05}
            x2={1449.61}
            y2={658.05}
          />
        </g>
        <g id='LINE_00000127757404084069178890000002329109606109369785_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1416.24}
            y1={658.05}
            x2={1399.56}
            y2={658.05}
          />
        </g>
        <g id='LINE_00000003816368792217325710000018439666464542527366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1412.98}
            y1={666.95}
            x2={1416.24}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000036973813204558623050000010065429986194679226_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1399.56}
            y1={666.95}
            x2={1405.19}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000005265367810036094760000001830278397858718623_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1461.91}
            y1={666.95}
            x2={1465.52}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000163036446856153760070000004598194620954316476_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1449.61}
            y1={666.95}
            x2={1453.01}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000031189994530407830630000000469276896206057096_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1572.91}
            y1={666.95}
            x2={1559.82}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000019644690583764801270000000342372793102908095_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1555.37}
            y1={666.95}
            x2={1549.51}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000152960711250687245520000000569038989312050849_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1527.5}
            y1={666.95}
            x2={1531.72}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000145017716300729250520000007209686455105024922_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1503.33}
            y1={666.95}
            x2={1521.21}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000165948227269274677800000017786742684159383982_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1465.52}
            y1={658.05}
            x2={1465.52}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000083056122324037874510000015350416244249707705_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1503.33}
            y1={658.05}
            x2={1503.33}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000021831454878030882560000010595504532694464948_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1416.24}
            y1={658.05}
            x2={1416.24}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000077315105278117677120000005177374330792851373_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1449.61}
            y1={658.05}
            x2={1449.61}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000171686328815800784730000007911537998448598959_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1399.56}
            y1={658.05}
            x2={1399.56}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000146469623069770006470000014094906308413573025_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.72}
            y1={658.05}
            x2={1531.72}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000094617235197520956100000003749330234700422791_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1549.51}
            y1={658.05}
            x2={1549.51}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000074409743658689252590000017809862692831379354_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1693.83}
            y1={596.89}
            x2={1693.83}
            y2={608.01}
          />
        </g>
        <g id='LINE_00000133515629599688569170000011033189856071689870_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1683.82}
            y1={596.89}
            x2={1683.82}
            y2={608.01}
          />
        </g>
        <g id='LINE_00000126318905810789656980000002707838580066079629_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1693.83}
            y1={596.89}
            x2={1683.82}
            y2={596.89}
          />
        </g>
        <g id='LINE_00000145768008235144160850000001115747441107368091_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1398.58}
            y1={827.42}
            x2={1405.19}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000024692394124394741760000013724444398291842215_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1398.58}
            y1={840.77}
            x2={1398.58}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000126306661173488579530000013322231323484351159_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1655.78}
            y1={691.35}
            x2={1577.36}
            y2={691.35}
          />
        </g>
        <g id='LINE_00000066499656205675528070000016292544837905587350_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1577.36}
            y1={682.45}
            x2={1646.89}
            y2={682.45}
          />
        </g>
        <g id='LINE_00000057859072379847899000000013001473250536270490_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1577.36}
            y1={658.05}
            x2={1577.36}
            y2={682.45}
          />
        </g>
        <g id='LINE_00000155112315849447258530000003859372641850791552_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1577.36}
            y1={691.35}
            x2={1577.36}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000146466674584322449860000002649479486583369898_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1572.91}
            y1={782.95}
            x2={1572.91}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000047759802007171794880000010277039655293265050_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1461.91}
            y1={782.95}
            x2={1476.97}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000033344538963930704180000008201448260700144777_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1494.76}
            y1={782.95}
            x2={1503.66}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000023268860446118470290000006640162092315329954_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1521.45}
            y1={782.95}
            x2={1530.35}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000109743116187148116580000011876993021477806002_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1548.14}
            y1={782.95}
            x2={1555.37}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000013877842683329992740000017401823537782311574_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1572.91}
            y1={782.95}
            x2={1559.82}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000163776461922590662090000000905858831811863452_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1530.35}
            y1={794.07}
            x2={1521.45}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000121262099795272799270000009435732503080877741_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1503.66}
            y1={794.07}
            x2={1494.76}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000044139916669615968470000004839649637646237058_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1530.35}
            y1={782.95}
            x2={1530.35}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000033368688622148151900000008133670433518618501_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1548.14}
            y1={782.95}
            x2={1548.14}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000021808146389026054980000001790558403407832473_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1476.97}
            y1={782.95}
            x2={1476.97}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000085953268679157911030000017340296031250425256_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1494.76}
            y1={782.95}
            x2={1494.76}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000020395297560562873300000013969453422973493427_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1503.66}
            y1={782.95}
            x2={1503.66}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000124847385638620464840000009760281728680166813_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1521.45}
            y1={782.95}
            x2={1521.45}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000129889897772696100850000015016635172682793385_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1521.21}
            y1={666.95}
            x2={1555.37}
            y2={701.1}
          />
        </g>
        <g id='LINE_00000008121320363536649650000015197484231835233212_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1527.5}
            y1={666.95}
            x2={1555.37}
            y2={694.81}
          />
        </g>
        <g id='LINE_00000090972988194158632250000012096751814326387079_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1559.82}
            y1={666.95}
            x2={1559.82}
            y2={699.26}
          />
        </g>
        <g id='LINE_00000021810797274438341460000016999014608185455250_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1559.82}
            y1={699.65}
            x2={1559.82}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000181801382045974504120000007740403653376831674_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1555.37}
            y1={701.1}
            x2={1555.37}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000150816992363562459520000012030838403152679324_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1555.37}
            y1={666.95}
            x2={1555.37}
            y2={694.81}
          />
        </g>
        <g id='LINE_00000085244285043001759100000001865069426340700585_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1412.98}
            y1={775.17}
            x2={1453.01}
            y2={775.17}
          />
        </g>
        <g id='LINE_00000094619715054492534540000013273952333892255666_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={1.4173}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1476.97}
            y1={794.07}
            x2={1412.98}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000158017328132098122090000004072194994080870529_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1688.33}
            y1={794.07}
            x2={1643.85}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000069379869597723046590000012527553862185909897_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1643.85}
            y1={781.84}
            x2={1688.33}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000171696645710346630190000001969057713466382221_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1711.51}
            y1={731.85}
            x2={1747.51}
            y2={731.85}
          />
        </g>
        <g id='LINE_00000081626155829189250370000010744878902185894791_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1747.51}
            y1={740.75}
            x2={1711.51}
            y2={740.75}
          />
        </g>
        <g id='LINE_00000047036104868403089590000003858952168479828888_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1711.51}
            y1={740.75}
            x2={1711.51}
            y2={731.85}
          />
        </g>
        <g id='LINE_00000111161991109963961450000015160611810567234212_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.72}
            y1={658.05}
            x2={1549.51}
            y2={658.05}
          />
        </g>
        <g id='LINE_00000064349910225187337940000000773950951659383431_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1549.51}
            y1={666.95}
            x2={1531.72}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000139280550116684141920000000130261941790568894_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1398.58}
            y1={840.77}
            x2={1380.78}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000065786816749644628690000000823683276841302428_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1380.78}
            y1={827.42}
            x2={1398.58}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000109005478659485161480000016867050922865219233_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1476.97}
            y1={782.95}
            x2={1494.76}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000041296411312864572630000011180100655204572320_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1503.66}
            y1={782.95}
            x2={1521.45}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000121267336726499916280000015303194798437462929_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1530.35}
            y1={782.95}
            x2={1548.14}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000141446831984647868620000011995602221069628067_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1548.14}
            y1={794.07}
            x2={1530.35}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000131332719591943359820000011099977681867738252_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1521.45}
            y1={794.07}
            x2={1503.66}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000114783210186025423500000009841589529994353836_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1494.76}
            y1={794.07}
            x2={1476.97}
            y2={794.07}
          />
        </g>
        <g id='ARC_00000090973733347116874460000003706375638891624834_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1731.65,594.67c-1.18-20.02-17.76-35.66-37.82-35.66'
          />
        </g>
        <g id='ARC_00000013904484398722856120000014510522735333149329_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1655.78,663.69c20.06,0,36.64-15.64,37.82-35.66'
          />
        </g>
        <g id='ARC_00000138560863840069761660000000859818508635674795_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1467.75,704.77c20.02-1.18,35.66-17.76,35.66-37.82'
          />
        </g>
        <g id='ARC_00000022553383328448281540000002008810975220089736_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1361.68,666.95c0,20.06,15.64,36.64,35.66,37.82'
          />
        </g>
        <g id='ARC_00000141424596806521476710000009705928893622132410_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1549.59,658.05c0-9.01-6.71-16.61-15.64-17.73'
          />
        </g>
        <g id='ARC_00000125594244641080359310000004024524958197748363_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1532.83,577.02c0,9.01,6.71,16.61,15.64,17.73'
          />
        </g>
        <g id='LINE_00000021798249891416158000000004204983402066973099_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1693.83}
            y1={594.67}
            x2={1731.65}
            y2={594.67}
          />
        </g>
        <g id='LINE_00000035515830466612066490000005646277925133121983_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1731.65}
            y1={596.89}
            x2={1731.65}
            y2={594.67}
          />
        </g>
        <g id='LINE_00000070831743859150466380000000538585527032072835_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1693.83}
            y1={596.89}
            x2={1731.65}
            y2={596.89}
          />
        </g>
        <g id='LINE_00000070077948439429737120000014767526576533154488_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1693.83}
            y1={596.89}
            x2={1693.83}
            y2={594.67}
          />
        </g>
        <g id='LINE_00000117666264527255503030000006357357166061298065_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1655.78}
            y1={628.03}
            x2={1693.6}
            y2={628.03}
          />
        </g>
        <g id='LINE_00000052080411165856759870000017877436191627738027_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1693.6}
            y1={625.8}
            x2={1693.6}
            y2={628.03}
          />
        </g>
        <g id='LINE_00000066504820656338894760000006346555978039508866_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1655.78}
            y1={625.8}
            x2={1693.6}
            y2={625.8}
          />
        </g>
        <g id='LINE_00000081616615941744087860000002251317143328900256_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1655.78}
            y1={625.8}
            x2={1655.78}
            y2={628.03}
          />
        </g>
        <g id='LINE_00000183954822669731345410000008074532030890986919_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1467.75}
            y1={666.95}
            x2={1467.75}
            y2={704.77}
          />
        </g>
        <g id='LINE_00000137824010384028818440000012879730350450781837_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1465.52}
            y1={704.77}
            x2={1467.75}
            y2={704.77}
          />
        </g>
        <g id='LINE_00000107568006465082604070000006363980600501346202_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1465.52}
            y1={666.95}
            x2={1465.52}
            y2={704.77}
          />
        </g>
        <g id='LINE_00000029746800711736865120000008288513388681753263_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1465.52}
            y1={666.95}
            x2={1467.75}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000124876540649543078640000009869565207336698791_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.34}
            y1={666.95}
            x2={1397.34}
            y2={704.77}
          />
        </g>
        <g id='LINE_00000165210762536023145590000009263112072258687142_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1399.56}
            y1={704.77}
            x2={1397.34}
            y2={704.77}
          />
        </g>
        <g id='LINE_00000095339774597878352090000004778583182764614315_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1399.56}
            y1={666.95}
            x2={1399.56}
            y2={704.77}
          />
        </g>
        <g id='LINE_00000005235264023874106740000004122990866478481854_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1399.56}
            y1={666.95}
            x2={1397.34}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000121981836002056309530000004806845576965445817_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1533.94}
            y1={658.05}
            x2={1533.94}
            y2={640.32}
          />
        </g>
        <g id='LINE_00000012451590781990875190000008171072501304213928_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.72}
            y1={640.32}
            x2={1533.94}
            y2={640.32}
          />
        </g>
        <g id='LINE_00000080177850032967081280000017158197915333882042_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.72}
            y1={658.05}
            x2={1531.72}
            y2={640.32}
          />
        </g>
        <g id='LINE_00000157283359796627950090000001133139720886798736_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.72}
            y1={658.05}
            x2={1533.94}
            y2={658.05}
          />
        </g>
        <g id='LINE_00000135677953813218051200000010305930244602966432_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1548.47}
            y1={577.02}
            x2={1548.47}
            y2={594.74}
          />
        </g>
        <g id='LINE_00000061442553058627413280000010874335096930019216_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1550.69}
            y1={594.74}
            x2={1548.47}
            y2={594.74}
          />
        </g>
        <g id='LINE_00000000921902658442889010000010663423519266732696_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1550.69}
            y1={577.02}
            x2={1550.69}
            y2={594.74}
          />
        </g>
        <g id='LINE_00000075147121803826587620000006813520520033533876_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.19}
            y1={827.42}
            x2={1398.58}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000097491483029248809590000012122841025911842234_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1395.8}
            y1={838.96}
            x2={1383.56}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000034089753538217040290000009262288409051399096_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1383.56}
            y1={839.93}
            x2={1383.56}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000024680518958365423110000012558084810178617777_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1381.62}
            y1={837.99}
            x2={1383.56}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000165917042248594369280000009974189399606166462_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1381.62}
            y1={839.93}
            x2={1383.56}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000062894535144250251690000016116769413449604279_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1395.8}
            y1={839.93}
            x2={1395.8}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000090293956335022614560000011366685182308868507_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={837.99}
            x2={1395.8}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000038371827823018269460000012160162805920315524_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={839.93}
            x2={1397.74}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000132802327634402414100000007775724146273693618_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={839.93}
            x2={1395.8}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000050633615152554938640000008785586748242825646_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.19}
            y1={827.42}
            x2={1397.19}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000095313381123261444320000012898700142437665458_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={837.99}
            x2={1397.19}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000124854338333184674720000007632901878762217406_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={837.99}
            x2={1397.74}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000124865285920848662880000001835802588251504526_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1398.58}
            y1={840.77}
            x2={1397.74}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000173860520760819979520000010991420395244132286_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1645.24}
            y1={781.84}
            x2={1643.85}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000075150479430724794820000007724932872950750620_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1686.94}
            y1={781.84}
            x2={1688.33}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000140704274236597099280000005652795329779727507_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1685.55}
            y1={792.26}
            x2={1646.63}
            y2={792.26}
          />
        </g>
        <g id='LINE_00000115514230902089617580000009014516187251838364_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1646.63}
            y1={793.24}
            x2={1646.63}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000158017128847382945280000013548592409675073417_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1644.68}
            y1={791.29}
            x2={1646.63}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000026876994892537924890000008236963415369420462_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1644.68}
            y1={793.24}
            x2={1644.68}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000036245927911077829380000017099085200621769137_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1644.68}
            y1={793.24}
            x2={1646.63}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000054232694848360989970000004993949984719944088_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1685.55}
            y1={793.24}
            x2={1685.55}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000120525124673238284990000007631388681599589024_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={791.29}
            x2={1685.55}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000124857691541352879150000018286631949559402381_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={793.24}
            x2={1687.5}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000088110830408527937040000006806756307768538018_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={793.24}
            x2={1685.55}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000075884858513061946730000000642168270727303326_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1644.68}
            y1={791.29}
            x2={1644.68}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000109739300987964267560000003610321397202750099_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1645.24}
            y1={791.29}
            x2={1644.68}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000158739463300039142010000017724642386509814931_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1645.24}
            y1={791.29}
            x2={1645.24}
            y2={781.84}
          />
        </g>
        <g id='LINE_00000031906784104938272840000005158378663442757537_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1643.85}
            y1={794.07}
            x2={1644.68}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000065767503563623724340000002638341927164514198_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1686.94}
            y1={781.84}
            x2={1686.94}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000180339753400212893100000005768341629169120157_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={791.29}
            x2={1686.94}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000108281271440830024570000005690385381120115112_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={791.29}
            x2={1687.5}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000031197908340676228020000005574573599755956884_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1688.33}
            y1={794.07}
            x2={1687.5}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000018944355589462313960000016530707216449332645_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.74}
            y1={782.95}
            x2={1530.35}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000137829789246802565240000014108490351897638810_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1546.75}
            y1={782.95}
            x2={1548.14}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000008132231228719536220000009431497955316990887_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1545.36}
            y1={792.26}
            x2={1533.13}
            y2={792.26}
          />
        </g>
        <g id='LINE_00000161605401155630178570000000530382279207793085_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1533.13}
            y1={793.24}
            x2={1533.13}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000039842918550814195920000009065451568579943869_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.18}
            y1={791.29}
            x2={1533.13}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000049191773391505536230000006555556442297761675_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.18}
            y1={793.24}
            x2={1531.18}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000023280248747810161880000009686956616011627949_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.18}
            y1={793.24}
            x2={1533.13}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000045596263480816528240000004160105981376469162_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1545.36}
            y1={793.24}
            x2={1545.36}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000146474189157267752850000006247249805760858266_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1547.31}
            y1={791.29}
            x2={1545.36}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000119828609368987898530000016216328857620896905_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1547.31}
            y1={793.24}
            x2={1547.31}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000121273648559050738360000016393630909637260470_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1547.31}
            y1={793.24}
            x2={1545.36}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000121263244628243602570000015463093158281073831_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.18}
            y1={791.29}
            x2={1531.18}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000022525209881963646450000005434684277321693597_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.74}
            y1={791.29}
            x2={1531.18}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000017480027263510647950000006280210621132798380_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.74}
            y1={791.29}
            x2={1531.74}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000183952023836680179420000000110136473177645756_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1530.35}
            y1={794.07}
            x2={1531.18}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000040535042937152165670000010842971122266862773_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1546.75}
            y1={782.95}
            x2={1546.75}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000060723593620338772890000014314382189496465797_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1547.31}
            y1={791.29}
            x2={1546.75}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000142152990227814832890000009709784318508936884_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1547.31}
            y1={791.29}
            x2={1547.31}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000019663670393317550770000012710628821979644297_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1548.14}
            y1={794.07}
            x2={1547.31}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000020357995227005089200000014466041625399010176_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1478.36}
            y1={782.95}
            x2={1476.97}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000170280716611221775310000017957432260506982297_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1493.37}
            y1={782.95}
            x2={1494.76}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000098188989900206751970000007917428109222137263_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1491.98}
            y1={792.26}
            x2={1479.75}
            y2={792.26}
          />
        </g>
        <g id='LINE_00000048487253819495626170000007405899158984658863_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1479.75}
            y1={793.24}
            x2={1479.75}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000178915601037826511620000018428003860804269992_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1477.8}
            y1={791.29}
            x2={1479.75}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000155138566703502313720000005090159610008266373_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1477.8}
            y1={793.24}
            x2={1477.8}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000052820495270598987250000002528763194226017944_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1477.8}
            y1={793.24}
            x2={1479.75}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000108991183018480468260000004472383106247382965_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1491.98}
            y1={793.24}
            x2={1491.98}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000120553845457080500060000009390623576092964744_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1493.93}
            y1={791.29}
            x2={1491.98}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000027569571897204844970000016918111461172404873_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1493.93}
            y1={793.24}
            x2={1493.93}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000013155961221831368970000014754839016055038394_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1493.93}
            y1={793.24}
            x2={1491.98}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000100366822346595539800000011224157144607567023_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1477.8}
            y1={791.29}
            x2={1477.8}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000145753121748985873830000012666575465626766247_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1478.36}
            y1={791.29}
            x2={1477.8}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000022541929186671146880000011307373003904811687_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1478.36}
            y1={791.29}
            x2={1478.36}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000178890079791339794190000002690794651343265721_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1476.97}
            y1={794.07}
            x2={1477.8}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000162311889355231740330000016158809802371761057_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1493.37}
            y1={782.95}
            x2={1493.37}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000046316117799918076740000011035073937983845783_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1493.93}
            y1={791.29}
            x2={1493.37}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000005948016520528874290000017960734084436594561_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1493.93}
            y1={791.29}
            x2={1493.93}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000027599848811264629190000013738762824993972657_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1494.76}
            y1={794.07}
            x2={1493.93}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000008833173929090167080000012828593279856788623_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1505.05}
            y1={782.95}
            x2={1503.66}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000010305876109899001850000010559439120297204389_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1520.06}
            y1={782.95}
            x2={1521.45}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000014598100752541447420000010277374428389270969_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1518.67}
            y1={792.26}
            x2={1506.44}
            y2={792.26}
          />
        </g>
        <g id='LINE_00000127034152962821217920000000584317933718409919_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1506.44}
            y1={793.24}
            x2={1506.44}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000164486152903623813300000015676583055276215955_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1504.49}
            y1={791.29}
            x2={1506.44}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000108307303471680907440000012009223690642192292_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1504.49}
            y1={793.24}
            x2={1504.49}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000052086440051792403570000000264662054330700934_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1504.49}
            y1={793.24}
            x2={1506.44}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000012455157021192925580000010131277354174527634_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1518.67}
            y1={793.24}
            x2={1518.67}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000058571560325087754810000002475126036864981919_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1520.62}
            y1={791.29}
            x2={1518.67}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000090287386978836375700000006571024856715731382_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1520.62}
            y1={793.24}
            x2={1520.62}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000059999077121275020510000002903692642382565563_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1520.62}
            y1={793.24}
            x2={1518.67}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000087380885989199890380000008230956688440801157_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1504.49}
            y1={791.29}
            x2={1504.49}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000156577421396365082180000009140079619184057988_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1505.05}
            y1={791.29}
            x2={1504.49}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000067949783465666356850000005585401891004979363_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1505.05}
            y1={791.29}
            x2={1505.05}
            y2={782.95}
          />
        </g>
        <g id='LINE_00000117654501245108472820000005010699305880136857_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1503.66}
            y1={794.07}
            x2={1504.49}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000023988121768435954680000002329551111025632143_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1520.06}
            y1={782.95}
            x2={1520.06}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000133530579200826223180000015355142353169454469_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1520.62}
            y1={791.29}
            x2={1520.06}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000140731490205850490540000002195450697065840815_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1520.62}
            y1={791.29}
            x2={1520.62}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000174566575997352902130000009080212110686416284_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1521.45}
            y1={794.07}
            x2={1520.62}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000075120118374696629680000007642163311917691530_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1739.69}
            y1={770.65}
            x2={1746.63}
            y2={770.49}
          />
        </g>
        <g id='LINE_00000034791533091146293120000004487763390153202320_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.63}
            y1={751.05}
            x2={1739.69}
            y2={751.21}
          />
        </g>
        <g id='ARC_00000027580128666734801100000017845435675190712504_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1739.69,751.21c-0.29,0.01-0.57,0.13-0.78,0.34'
          />
        </g>
        <g id='LINE_00000132792829239088286600000010708842121861959338_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1738.91}
            y1={751.56}
            x2={1733.91}
            y2={756.79}
          />
        </g>
        <g id='ARC_00000038408445614252022240000015959483265221681043_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1733.91,756.79c-0.2,0.21-0.31,0.5-0.31,0.79'
          />
        </g>
        <g id='LINE_00000006668268416459592080000006399555126295945102_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.6}
            y1={757.58}
            x2={1733.78}
            y2={765.01}
          />
        </g>
        <g id='ARC_00000027578856915314243500000009141527621306805695_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1733.78,765.01c0.01,0.29,0.13,0.57,0.34,0.78'
          />
        </g>
        <g id='LINE_00000007395108788558184970000000066437009782681790_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1734.12}
            y1={765.78}
            x2={1738.9}
            y2={770.35}
          />
        </g>
        <g id='ARC_00000098929780468284771330000007847523989792949685_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1738.9,770.35c0.21,0.2,0.5,0.31,0.79,0.31'
          />
        </g>
        <g id='SPLINE_00000049915072945271865380000014425873681677380230_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.48,760.75c0.01,0.3,0,0.6-0.01,0.89c-0.02,0.3-0.04,0.6-0.08,0.89s-0.09,0.59-0.16,0.88c-0.07,0.29-0.14,0.58-0.24,0.86 c-0.09,0.28-0.2,0.56-0.32,0.83s-0.26,0.53-0.43,0.79s-0.34,0.49-0.55,0.71c-0.1,0.11-0.21,0.21-0.33,0.31 c-0.11,0.1-0.23,0.18-0.36,0.26c-0.13,0.08-0.26,0.15-0.4,0.21c-0.07,0.03-0.14,0.06-0.21,0.08c-0.07,0.02-0.14,0.04-0.21,0.06 c-0.07,0.02-0.15,0.03-0.22,0.04s-0.15,0.01-0.22,0.02c-0.08,0-0.15,0-0.22-0.01s-0.15-0.02-0.22-0.03 c-0.07-0.01-0.14-0.03-0.22-0.05c-0.07-0.02-0.14-0.04-0.21-0.07c-0.14-0.05-0.28-0.12-0.41-0.19c-0.13-0.07-0.25-0.16-0.37-0.25 c-0.12-0.09-0.23-0.19-0.34-0.29c-0.21-0.21-0.41-0.44-0.58-0.68s-0.33-0.5-0.46-0.77c-0.14-0.27-0.26-0.54-0.36-0.82 c-0.11-0.28-0.2-0.56-0.28-0.85c-0.08-0.29-0.14-0.58-0.2-0.87s-0.09-0.59-0.12-0.89s-0.05-0.6-0.06-0.89'
          />
        </g>
        <g id='SPLINE_00000052800984320022439270000016008868580365463460_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1736.7,760.93c-0.01-0.3,0-0.6,0.01-0.9c0.02-0.3,0.04-0.6,0.08-0.89c0.04-0.3,0.09-0.59,0.16-0.88 c0.06-0.29,0.14-0.58,0.24-0.86c0.09-0.28,0.2-0.56,0.33-0.84c0.12-0.27,0.27-0.54,0.43-0.79s0.34-0.49,0.55-0.71 c0.1-0.11,0.21-0.21,0.32-0.31c0.11-0.1,0.23-0.18,0.36-0.26c0.13-0.08,0.26-0.15,0.4-0.21c0.07-0.03,0.14-0.06,0.21-0.08 c0.07-0.02,0.14-0.04,0.21-0.06c0.07-0.02,0.15-0.03,0.22-0.04s0.15-0.01,0.22-0.02c0.08,0,0.15,0,0.22,0.01 c0.07,0.01,0.15,0.02,0.22,0.03c0.07,0.01,0.14,0.03,0.22,0.05c0.07,0.02,0.14,0.04,0.21,0.07c0.14,0.05,0.28,0.12,0.41,0.19 c0.13,0.07,0.25,0.16,0.37,0.25c0.12,0.09,0.23,0.19,0.34,0.29c0.21,0.21,0.41,0.44,0.58,0.68s0.33,0.5,0.46,0.77 c0.14,0.26,0.26,0.54,0.36,0.82c0.11,0.28,0.2,0.56,0.28,0.85s0.14,0.58,0.2,0.87c0.05,0.29,0.09,0.59,0.12,0.89 c0.03,0.3,0.05,0.6,0.06,0.89'
          />
        </g>
        <g id='LWPOLYLINE_00000105409380670114342040000015725285834801982600_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1728.7,753.47 1728.03,753.44 1727.36,753.44 1726.69,753.47 1726.03,753.52 1725.36,753.6 1724.7,753.71 1724.04,753.84  1723.39,754 1722.75,754.19 1722.11,754.4 1721.49,754.64 1720.87,754.9 1720.26,755.18 1719.67,755.49 1719.09,755.82  1718.52,756.18 1717.97,756.56 1717.43,756.96 1716.91,757.38 1716.41,757.82 1715.92,758.28 1715.45,758.77 1715.01,759.26  1714.58,759.78 1714.48,759.9  '
          />
        </g>
        <g id='LWPOLYLINE_00000096020108532247076750000012250946738428709260_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1733.5,756.8 1733.29,756.52 1733.06,756.24 1732.82,755.98 1732.58,755.72 1732.32,755.47 1732.05,755.24 1731.77,755.01  1731.49,754.8 1731.19,754.6 1730.89,754.41 1730.58,754.23 1730.26,754.07 1729.94,753.92 1729.61,753.78 1729.28,753.65  1728.94,753.54 1728.7,753.47  '
          />
        </g>
        <g id='LWPOLYLINE_00000007400523518239720780000006743117929904624307_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1728.7,769.12 1729.04,769.01 1729.38,768.9 1729.71,768.77 1730.04,768.62 1730.36,768.47 1730.68,768.3 1730.98,768.12  1731.28,767.93 1731.57,767.72 1731.86,767.5 1732.13,767.28 1732.4,767.04 1732.65,766.79 1732.9,766.53 1733.13,766.26  1733.36,765.98 1733.57,765.7 1733.71,765.5  '
          />
        </g>
        <g id='LWPOLYLINE_00000129928461111441375990000007350740539613160325_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1714.48,762.68 1714.91,763.2 1715.35,763.7 1715.81,764.19 1716.29,764.66 1716.79,765.1 1717.31,765.53 1717.84,765.93  1718.39,766.32 1718.95,766.68 1719.53,767.02 1720.12,767.33 1720.73,767.62 1721.34,767.89 1721.96,768.13 1722.6,768.35  1723.24,768.54 1723.89,768.71 1724.54,768.85 1725.2,768.96 1725.87,769.05 1726.54,769.11 1727.21,769.14 1727.88,769.15  1728.54,769.13 1728.7,769.12  '
          />
        </g>
        <g id='LINE_00000034773790181251240140000001238048642860403866_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1714.06}
            y1={762.14}
            x2={1714.48}
            y2={762.68}
          />
        </g>
        <g id='LINE_00000061472174647014152360000012690645842263306157_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1714.48}
            y1={759.9}
            x2={1714.06}
            y2={760.44}
          />
        </g>
        <g id='LWPOLYLINE_00000160890630232901262950000011123506199611354043_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1730.79,761.64 1730.78,761.46 1730.77,761.28 1730.75,761.1 1730.72,760.96  '
          />
        </g>
        <g id='LWPOLYLINE_00000024706037695891015030000006969298688161228930_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1730.72,760.96 1730.74,760.78 1730.75,760.6 1730.76,760.42 1730.76,760.27  '
          />
        </g>
        <g id='LINE_00000159428033385484699810000001493432002176797598_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.79}
            y1={765.55}
            x2={1733.78}
            y2={765.01}
          />
        </g>
        <g id='LINE_00000113323676893171899130000010767214467441372831_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.6}
            y1={757.58}
            x2={1733.59}
            y2={756.74}
          />
        </g>
        <g id='LINE_00000087383683774228962690000001547822286883829657_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.59}
            y1={756.74}
            x2={1733.46}
            y2={756.83}
          />
        </g>
        <g id='ARC_00000049194959487630342280000008447995257731150234_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1733.46,756.83c-1.17-1.61-2.85-2.77-4.77-3.31'
          />
        </g>
        <g id='ARC_00000066506041566691250160000008825893672922255490_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1728.69,753.53c-5.49-0.33-10.79,2.07-14.16,6.41'
          />
        </g>
        <g id='LINE_00000009570533675273320410000008927589913014554024_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1714.53}
            y1={759.94}
            x2={1714.01}
            y2={760.61}
          />
        </g>
        <g id='ARC_00000007411102088910881730000002861429337792441242_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.01,760.61c-0.13,0.45-0.13,0.92,0,1.37'
          />
        </g>
        <g id='LINE_00000147201539246981496870000008090630814503470268_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1714.01}
            y1={761.98}
            x2={1714.53}
            y2={762.65}
          />
        </g>
        <g id='ARC_00000006665622366645968330000006486893691280725937_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.53,762.65c3.38,4.34,8.67,6.74,14.16,6.41'
          />
        </g>
        <g id='ARC_00000138563915996129426490000009687035939576699808_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1728.69,769.06c2.03-0.57,3.79-1.84,4.97-3.6'
          />
        </g>
        <g id='LINE_00000016790635440261613310000002562553504824191391_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.66}
            y1={765.46}
            x2={1733.79}
            y2={765.55}
          />
        </g>
        <g id='ARC_00000172420972442952133880000012187857895269338775_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1726.54,766.23c2.48-0.13,4.38-2.25,4.24-4.73c-0.01-0.18-0.03-0.36-0.06-0.54'
          />
        </g>
        <g id='ARC_00000120520275789074010360000002320308157567576729_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1716.84,762.03c2.39,2.85,5.99,4.41,9.7,4.2'
          />
        </g>
        <g id='ARC_00000060001876639911229500000011168741796702008983_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1716.51,761.29c0.01,0.28,0.12,0.55,0.33,0.74'
          />
        </g>
        <g id='ARC_00000047028936178046676300000014040645229011430027_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1716.8,760.53c-0.19,0.2-0.3,0.47-0.29,0.75'
          />
        </g>
        <g id='ARC_00000132805204210643239550000013609658997286448024_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1726.3,755.88c-3.72-0.03-7.24,1.69-9.5,4.65'
          />
        </g>
        <g id='ARC_00000123439832458063939500000006899504757155127743_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1730.72,760.96c0.32-2.46-1.41-4.72-3.87-5.04c-0.18-0.02-0.36-0.04-0.55-0.04'
          />
        </g>
        <g id='SPLINE_00000141436373029893304410000002586942522396406197_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.01,760.61c0.02-0.07,0.04-0.11,0.05-0.14c0-0.01,0.01-0.02,0.01-0.02c0,0,0,0,0,0s0,0,0,0.01'
          />
        </g>
        <g id='SPLINE_00000168079188527126808320000009334211189863407271_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.02,760.57c0,0.01,0,0.01-0.01,0.02c0,0.01,0,0.01-0.01,0.02c0,0.02-0.01,0.03-0.01,0.05s-0.01,0.04-0.01,0.05 c0,0.02-0.01,0.04-0.01,0.06c0,0.02-0.01,0.04-0.01,0.06c0,0.02-0.01,0.04-0.01,0.06'
          />
        </g>
        <g id='SPLINE_00000148647689602770643640000015764616120610843305_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1713.92,761.02c-0.01,0.09-0.02,0.18-0.02,0.28'
          />
        </g>
        <g id='SPLINE_00000013896265503095692080000013954536696465265342_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1713.91,761.29c0,0.1,0.01,0.19,0.02,0.28'
          />
        </g>
        <g id='SPLINE_00000160889580261656654150000006900717350997448853_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1713.94,761.7c0,0.02,0.01,0.04,0.01,0.06c0,0.02,0.01,0.04,0.01,0.06s0.01,0.04,0.01,0.06s0.01,0.04,0.01,0.05 c0,0.02,0.01,0.03,0.01,0.05c0,0.01,0,0.02,0.01,0.02c0,0.01,0,0.01,0.01,0.02'
          />
        </g>
        <g id='SPLINE_00000093872741232944916950000012774078529585260702_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.06,762.13C1714.07,762.14,1714.07,762.14,1714.06,762.13c0,0,0,0-0.01-0.02c-0.01-0.03-0.03-0.07-0.05-0.14'
          />
        </g>
        <g id='SPLINE_00000067939989757454273460000005379701593357423271_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1728.69,753.53c0.01-0.04,0.01-0.06,0.01-0.06'
          />
        </g>
        <g id='SPLINE_00000127015343337340978290000012658816202329112752_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1728.69,769.06c0.01,0.04,0.01,0.06,0.01,0.06'
          />
        </g>
        <g id='SPLINE_00000098933893933411053460000000584341796582131388_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1725.35,760.57c0.12,0.11,0.22,0.25,0.29,0.39c0.07,0.15,0.12,0.31,0.13,0.47c0.01,0.08,0.01,0.16,0,0.24 c-0.01,0.08-0.02,0.16-0.04,0.24c-0.01,0.04-0.02,0.08-0.03,0.12c-0.01,0.04-0.03,0.08-0.04,0.11c-0.02,0.04-0.03,0.07-0.05,0.11 c-0.02,0.03-0.04,0.07-0.06,0.1c-0.02,0.03-0.05,0.07-0.07,0.1c-0.03,0.03-0.05,0.06-0.08,0.09s-0.06,0.06-0.09,0.08 c-0.03,0.03-0.06,0.05-0.09,0.07c-0.03,0.02-0.06,0.05-0.1,0.07c-0.03,0.02-0.07,0.04-0.1,0.06c-0.04,0.02-0.07,0.03-0.11,0.05 c-0.04,0.01-0.07,0.03-0.11,0.04s-0.08,0.02-0.12,0.03c-0.04,0.01-0.08,0.01-0.12,0.02c-0.08,0.01-0.16,0.01-0.24,0.01 c-0.16-0.01-0.32-0.05-0.47-0.11c-0.15-0.07-0.29-0.16-0.41-0.27'
          />
        </g>
        <g id='SPLINE_00000024002730617358716170000015329877044979409804_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1723.43,762.58c-0.12-0.11-0.21-0.25-0.29-0.39c-0.07-0.15-0.12-0.3-0.14-0.46c-0.02-0.16-0.01-0.32,0.03-0.48 c0.04-0.16,0.11-0.31,0.2-0.44c0.09-0.13,0.2-0.25,0.34-0.35s0.28-0.17,0.43-0.21c0.16-0.04,0.32-0.06,0.48-0.05 c0.08,0.01,0.16,0.02,0.24,0.04c0.08,0.02,0.15,0.05,0.23,0.08c0.15,0.06,0.28,0.16,0.4,0.27'
          />
        </g>
        <g id='LINE_00000005251136086006995910000017759241055338946744_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1716.8}
            y1={760.53}
            x2={1716.84}
            y2={762.03}
          />
        </g>
        <g id='LWPOLYLINE_00000126313594781967146760000006317964454587094914_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1746.51,760.64 1746.51,760.64 1746.51,760.65 1746.51,760.65 1746.51,760.65 1746.51,760.66 1746.51,760.66 1746.51,760.67  1746.51,760.67 1746.51,760.68 1746.5,760.68 1746.5,760.69 1746.5,760.69 1746.5,760.69 1746.5,760.7 1746.49,760.7  1746.49,760.71 1746.49,760.71 1746.49,760.71 1746.48,760.72 1746.48,760.72 1746.48,760.72 1746.47,760.73 1746.47,760.73  1746.47,760.73 1746.46,760.73 1746.46,760.74 1746.45,760.74 1746.45,760.74 1746.45,760.74 1746.44,760.74 1746.44,760.75  1746.43,760.75 1746.43,760.75 1746.42,760.75 1746.42,760.75 1746.42,760.75 1746.41,760.75 1746.41,760.75 1746.4,760.75  1746.4,760.75  '
          />
        </g>
        <g id='LWPOLYLINE_00000152249075637595108050000000283557812372123788_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1745.76,760.77 1745.8,760.77 1745.84,760.77 1745.88,760.77 1745.92,760.76 1745.96,760.76 1746,760.76 1746.03,760.76  1746.07,760.76 1746.1,760.76 1746.13,760.76 1746.16,760.76 1746.19,760.76 1746.22,760.76 1746.24,760.76 1746.27,760.76  1746.29,760.76 1746.31,760.76 1746.32,760.76 1746.34,760.75 1746.35,760.75 1746.37,760.75 1746.38,760.75 1746.39,760.75  1746.39,760.75 1746.4,760.75 1746.4,760.75 1746.4,760.75  '
          />
        </g>
        <g id='LINE_00000150796819753650946880000008654077722030834316_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.4}
            y1={760.75}
            x2={1746.4}
            y2={760.75}
          />
        </g>
        <g id='LWPOLYLINE_00000165208362370588347500000017136118257913440653_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1746.4,760.53 1746.4,760.53 1746.4,760.53 1746.41,760.53 1746.41,760.53 1746.42,760.53 1746.42,760.53 1746.43,760.53  1746.43,760.53 1746.44,760.53 1746.44,760.53 1746.44,760.54 1746.45,760.54 1746.45,760.54 1746.46,760.54 1746.46,760.54  1746.46,760.55 1746.47,760.55 1746.47,760.55 1746.47,760.55 1746.48,760.56 1746.48,760.56 1746.48,760.56 1746.49,760.57  1746.49,760.57 1746.49,760.58 1746.49,760.58 1746.5,760.58 1746.5,760.59 1746.5,760.59 1746.5,760.6 1746.51,760.6  1746.51,760.6 1746.51,760.61 1746.51,760.61 1746.51,760.62 1746.51,760.62 1746.51,760.63 1746.51,760.63 1746.51,760.64  1746.51,760.64  '
          />
        </g>
        <g id='LINE_00000044888706414582521480000001712334591574504360_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.4}
            y1={760.53}
            x2={1746.4}
            y2={760.53}
          />
        </g>
        <g id='LWPOLYLINE_00000040544025085364010100000015600753268993056421_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1746.4,760.53 1746.39,760.53 1746.39,760.53 1746.39,760.53 1746.38,760.53 1746.37,760.53 1746.36,760.53 1746.35,760.53  1746.33,760.53 1746.32,760.53 1746.3,760.53 1746.28,760.53 1746.26,760.53 1746.23,760.53 1746.21,760.53 1746.18,760.53  1746.15,760.53 1746.12,760.53 1746.09,760.53 1746.06,760.53 1746.02,760.53 1745.99,760.53 1745.95,760.54 1745.91,760.54  1745.87,760.54 1745.83,760.54 1745.79,760.54 1745.75,760.54  '
          />
        </g>
        <g id='LWPOLYLINE_00000141413993478274677450000003586054242997689514_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1744.65,760.79 1744.69,760.79 1744.73,760.79 1744.77,760.79 1744.82,760.79 1744.86,760.79 1744.91,760.79 1744.95,760.79  1745,760.79 1745.04,760.78 1745.09,760.78 1745.14,760.78 1745.18,760.78 1745.23,760.78 1745.28,760.78 1745.32,760.78  1745.37,760.78 1745.42,760.78 1745.46,760.77 1745.51,760.77 1745.55,760.77 1745.6,760.77 1745.64,760.77 1745.68,760.77  1745.72,760.77 1745.76,760.77  '
          />
        </g>
        <g id='LWPOLYLINE_00000076571429596634107760000013135412379382730630_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1745.75,760.54 1745.71,760.54 1745.67,760.54 1745.63,760.54 1745.58,760.54 1745.54,760.55 1745.49,760.55 1745.45,760.55  1745.4,760.55 1745.36,760.55 1745.31,760.55 1745.26,760.55 1745.22,760.55 1745.17,760.55 1745.12,760.55 1745.08,760.56  1745.03,760.56 1744.98,760.56 1744.94,760.56 1744.89,760.56 1744.85,760.56 1744.8,760.56 1744.76,760.56 1744.72,760.56  1744.68,760.57 1744.64,760.57  '
          />
        </g>
        <g id='ELLIPSE_00000115483385759243959270000013621248414510806663_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1746.46,760.74c0.03-0.02,0.06-0.06,0.05-0.1'
          />
        </g>
        <g id='ELLIPSE_00000150065569716735591120000016352364122997633953_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1746.32,760.72c0.01,0.01,0.02,0.01,0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000015315164761360304210000017591401037078824326_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1746.51,760.64c0-0.04-0.02-0.08-0.06-0.1'
          />
        </g>
        <g id='ELLIPSE_00000158748710756423283300000016955445481752158868_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.61,760.58c-0.02,0.02-0.03,0.06-0.02,0.1'
          />
        </g>
        <g id='ELLIPSE_00000036230952597038295250000007849509247438103967_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.66,760.57c-0.01-0.01-0.02,0-0.03,0'
          />
        </g>
        <g id='ELLIPSE_00000027575682103079382320000012347968935242768572_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.7,760.68c0-0.02,0-0.04-0.01-0.06'
          />
        </g>
        <g id='ELLIPSE_00000139267768443847054560000009733717164214042815_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.69,760.74c0-0.02,0.01-0.04,0.01-0.06'
          />
        </g>
        <g id='ELLIPSE_00000028299291753655173400000012709079866005706639_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.63,760.79c0.01,0,0.02,0,0.03,0'
          />
        </g>
        <g id='ELLIPSE_00000058558571375256108610000015736513173901975220_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.59,760.68c0,0.04,0.01,0.08,0.03,0.1'
          />
        </g>
        <g id='LINE_00000084490883788195982480000003363206425911587747_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1747.51}
            y1={751.03}
            x2={1746.63}
            y2={751.05}
          />
        </g>
        <g id='LINE_00000043418479477454216160000017441558774492293549_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.63}
            y1={751.05}
            x2={1746.63}
            y2={770.49}
          />
        </g>
        <g id='LINE_00000132778371615204355320000001284055616651089540_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.63}
            y1={770.49}
            x2={1747.51}
            y2={770.47}
          />
        </g>
        <g id='LINE_00000020383647285181654840000012671695648319751582_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1757.52}
            y1={682.59}
            x2={1797.55}
            y2={682.59}
          />
        </g>
        <g id='LINE_00000061440131260065652950000012204026924556953244_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1757.52}
            y1={690.37}
            x2={1797.55}
            y2={690.37}
          />
        </g>
        <g id='LINE_00000137093361134878064130000002413415190910634890_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1412.98}
            y1={729.57}
            x2={1453.01}
            y2={729.57}
          />
        </g>
        <g id='LINE_00000070118797894086855810000000580070569923093386_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1412.98}
            y1={737.36}
            x2={1453.01}
            y2={737.36}
          />
        </g>
        <g id='LINE_00000075857197528225050850000016205823821851119507_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1539.85}
            y1={696.64}
            x2={1544.64}
            y2={691.62}
          />
        </g>
        <g id='LINE_00000142875234618071569200000012847288852165317042_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1530.89}
            y1={677.87}
            x2={1526.1}
            y2={682.89}
          />
        </g>
        <g id='ARC_00000181808374343971292970000011478683361044161697_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1526.1,682.89c-0.2,0.21-0.31,0.5-0.31,0.79'
          />
        </g>
        <g id='LINE_00000088833686630766745210000009091579492974808502_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1525.79}
            y1={683.69}
            x2={1525.96}
            y2={690.92}
          />
        </g>
        <g id='ARC_00000163753480922762859310000012876396098325398433_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1525.96,690.92c0.01,0.29,0.13,0.57,0.34,0.78'
          />
        </g>
        <g id='LINE_00000168830233463429196200000006250170591479709631_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1526.3}
            y1={691.7}
            x2={1531.68}
            y2={696.83}
          />
        </g>
        <g id='ARC_00000171694473875626612720000015075535705329543564_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1531.68,696.83c0.21,0.2,0.5,0.31,0.79,0.31'
          />
        </g>
        <g id='LINE_00000134953683610985694270000011391309660568013471_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1532.47}
            y1={697.13}
            x2={1539.07}
            y2={696.98}
          />
        </g>
        <g id='ARC_00000023271688027065104570000010350053817863223187_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1539.07,696.98c0.29-0.01,0.57-0.13,0.78-0.34'
          />
        </g>
        <g id='SPLINE_00000027574573731713835330000007459826222527124638_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1536.23,686.24c0.21,0.2,0.42,0.42,0.62,0.64c0.2,0.22,0.39,0.45,0.57,0.69s0.35,0.48,0.51,0.73c0.16,0.25,0.31,0.51,0.44,0.78 s0.26,0.54,0.36,0.82c0.1,0.28,0.19,0.57,0.25,0.86c0.06,0.29,0.11,0.59,0.11,0.89c0,0.15,0,0.3-0.01,0.45 c-0.01,0.15-0.03,0.3-0.07,0.44c-0.03,0.15-0.08,0.29-0.13,0.43c-0.03,0.07-0.06,0.14-0.09,0.2c-0.03,0.07-0.07,0.13-0.11,0.19 c-0.04,0.06-0.08,0.12-0.13,0.18c-0.05,0.06-0.09,0.12-0.15,0.17c-0.05,0.05-0.11,0.11-0.16,0.15c-0.06,0.05-0.12,0.09-0.18,0.14 c-0.06,0.04-0.12,0.08-0.19,0.12c-0.06,0.04-0.13,0.07-0.2,0.1c-0.14,0.06-0.28,0.11-0.42,0.15c-0.14,0.04-0.29,0.07-0.44,0.09 c-0.15,0.02-0.3,0.03-0.45,0.03c-0.3,0-0.6-0.02-0.89-0.07c-0.29-0.05-0.58-0.12-0.87-0.21c-0.28-0.09-0.56-0.2-0.84-0.32 c-0.27-0.12-0.54-0.26-0.8-0.41c-0.26-0.15-0.51-0.31-0.76-0.48c-0.25-0.17-0.48-0.35-0.72-0.54c-0.23-0.19-0.46-0.39-0.67-0.59'
          />
        </g>
        <g id='SPLINE_00000053514564471999725380000000674446263865008791_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1530.86,691.87c-0.21-0.21-0.42-0.42-0.62-0.64s-0.39-0.45-0.57-0.69c-0.18-0.24-0.35-0.48-0.51-0.73s-0.31-0.51-0.44-0.78 c-0.14-0.27-0.26-0.54-0.36-0.82c-0.1-0.28-0.19-0.57-0.25-0.86c-0.06-0.29-0.1-0.59-0.11-0.89c0-0.15,0-0.3,0.01-0.45 c0.01-0.15,0.03-0.29,0.07-0.44c0.03-0.15,0.08-0.29,0.13-0.43c0.03-0.07,0.06-0.14,0.09-0.2c0.03-0.07,0.07-0.13,0.11-0.19 c0.04-0.06,0.08-0.12,0.13-0.18c0.05-0.06,0.1-0.12,0.15-0.17c0.05-0.05,0.11-0.11,0.16-0.15c0.06-0.05,0.12-0.09,0.18-0.14 c0.06-0.04,0.12-0.08,0.19-0.12c0.06-0.04,0.13-0.07,0.2-0.1c0.14-0.06,0.28-0.11,0.42-0.15c0.14-0.04,0.29-0.07,0.44-0.09 c0.15-0.02,0.3-0.03,0.45-0.03c0.3,0,0.6,0.02,0.89,0.07s0.58,0.12,0.87,0.21c0.28,0.09,0.56,0.2,0.83,0.32 c0.27,0.12,0.54,0.26,0.8,0.41c0.26,0.15,0.51,0.31,0.76,0.48s0.48,0.35,0.72,0.54c0.23,0.19,0.46,0.39,0.67,0.59'
          />
        </g>
        <g id='LWPOLYLINE_00000059274967000686767020000015478108897554524860_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1519.93,692.26 1519.44,692.71 1518.96,693.19 1518.51,693.68 1518.08,694.19 1517.66,694.72 1517.27,695.26 1516.9,695.82  1516.55,696.39 1516.23,696.98 1515.93,697.57 1515.65,698.19 1515.4,698.81 1515.17,699.44 1514.97,700.08 1514.8,700.72  1514.65,701.37 1514.53,702.03 1514.43,702.7 1514.36,703.36 1514.31,704.03 1514.3,704.7 1514.31,705.37 1514.34,706.04  1514.41,706.71 1514.43,706.86  '
          />
        </g>
        <g id='LWPOLYLINE_00000061468947576612939040000018039140818090767028_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1525.68,691.22 1525.33,691.17 1524.97,691.14 1524.62,691.12 1524.26,691.11 1523.9,691.12 1523.55,691.14 1523.19,691.18  1522.84,691.23 1522.49,691.3 1522.14,691.38 1521.8,691.47 1521.46,691.58 1521.12,691.7 1520.79,691.83 1520.47,691.98  1520.15,692.14 1519.93,692.26  '
          />
        </g>
        <g id='LWPOLYLINE_00000081643530240304250370000010384223162513029790_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1530.99,703.32 1531.16,703.01 1531.32,702.69 1531.46,702.36 1531.59,702.03 1531.71,701.69 1531.81,701.35 1531.9,701  1531.98,700.66 1532.04,700.3 1532.08,699.95 1532.12,699.6 1532.14,699.24 1532.14,698.88 1532.13,698.53 1532.11,698.17  1532.07,697.81 1532.02,697.46 1531.97,697.22  '
          />
        </g>
        <g id='LWPOLYLINE_00000162324196058998336280000005022616022322905233_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1516.39,708.83 1517.06,708.9 1517.72,708.94 1518.39,708.95 1519.06,708.94 1519.73,708.91 1520.4,708.84 1521.06,708.75  1521.72,708.64 1522.38,708.49 1523.03,708.32 1523.67,708.13 1524.3,707.91 1524.92,707.66 1525.53,707.39 1526.14,707.1  1526.73,706.78 1527.3,706.44 1527.86,706.07 1528.41,705.68 1528.94,705.28 1529.46,704.85 1529.95,704.4 1530.43,703.93  1530.89,703.44 1530.99,703.32  '
          />
        </g>
        <g id='LINE_00000146457693965102815080000004889824989600299951_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1515.71}
            y1={708.74}
            x2={1516.39}
            y2={708.83}
          />
        </g>
        <g id='LINE_00000101069550154454830180000017046188838975541382_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1514.43}
            y1={706.86}
            x2={1514.51}
            y2={707.54}
          />
        </g>
        <g id='LWPOLYLINE_00000060724246203195263980000011352664344461392783_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1527.19,696.56 1527.05,696.44 1526.92,696.32 1526.77,696.21 1526.65,696.13  '
          />
        </g>
        <g id='LWPOLYLINE_00000090973236582606698190000008962908938933685650_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1526.65,696.13 1526.54,695.98 1526.42,695.85 1526.3,695.72 1526.2,695.61  '
          />
        </g>
        <g id='LINE_00000078753149607740709060000002849849446847970217_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1532.07}
            y1={697.21}
            x2={1531.68}
            y2={696.83}
          />
        </g>
        <g id='LINE_00000092430108071477769190000007748727238512392123_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1526.3}
            y1={691.7}
            x2={1525.7}
            y2={691.12}
          />
        </g>
        <g id='LINE_00000028314635976635043600000004418629985265152672_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1525.7}
            y1={691.12}
            x2={1525.67}
            y2={691.27}
          />
        </g>
        <g id='ARC_00000021115596696970163760000005791639736678666394_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1525.67,691.27c-1.96-0.31-3.98,0.06-5.71,1.03'
          />
        </g>
        <g id='ARC_00000010270558510225092480000004518115456906949796_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1519.96,692.3c-4.11,3.65-6.16,9.09-5.48,14.55'
          />
        </g>
        <g id='LINE_00000128464321511286565400000018081783850171586217_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1514.48}
            y1={706.86}
            x2={1514.59}
            y2={707.7}
          />
        </g>
        <g id='ARC_00000152222747072161179850000001292390163397102728_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1514.59,707.7c0.22,0.41,0.56,0.75,0.97,0.97'
          />
        </g>
        <g id='LINE_00000114047401541072267510000018102455478461697422_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1515.56}
            y1={708.66}
            x2={1516.4}
            y2={708.77}
          />
        </g>
        <g id='ARC_00000120534211380831655660000000717976299784477604_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1516.4,708.77c5.46,0.68,10.9-1.37,14.55-5.48'
          />
        </g>
        <g id='ARC_00000007428377098571905020000001326124906064254393_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1530.95,703.29c1.03-1.84,1.38-3.98,0.97-6.05'
          />
        </g>
        <g id='LINE_00000147203068150782108150000010282106732203976894_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.92}
            y1={697.24}
            x2={1532.07}
            y2={697.21}
          />
        </g>
        <g id='ARC_00000028323810919505059080000000559225789261820036_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1527.43,702.81c1.66-1.85,1.5-4.69-0.34-6.34c-0.14-0.12-0.28-0.24-0.43-0.34'
          />
        </g>
        <g id='ARC_00000057120998191932296590000004518170734076757672_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1517.59,706.7c3.71,0.32,7.35-1.12,9.83-3.89'
          />
        </g>
        <g id='ARC_00000044144499155227367800000015245253052983638936_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1516.84,706.41c0.2,0.19,0.47,0.3,0.75,0.29'
          />
        </g>
        <g id='ARC_00000111911663434313589860000001835928269343944847_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1516.51,705.67c0.01,0.28,0.12,0.55,0.33,0.74'
          />
        </g>
        <g id='ARC_00000039107350592831891700000002392881280923138438_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1519.94,695.66c-2.65,2.61-3.92,6.32-3.43,10'
          />
        </g>
        <g id='ARC_00000163051297381268165780000005212314550463048070_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1526.65,696.13c-1.51-1.97-4.33-2.33-6.3-0.82c-0.14,0.11-0.28,0.23-0.41,0.36'
          />
        </g>
        <g id='SPLINE_00000104705873001035434400000000174709509831993241_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1514.59,707.7c-0.03-0.06-0.05-0.11-0.07-0.13c-0.01-0.01-0.01-0.02-0.01-0.02c0,0,0,0,0,0c0,0,0,0,0,0.01'
          />
        </g>
        <g id='SPLINE_00000059287735043827544520000009971274556494666376_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1514.57,707.66c0,0.01,0.01,0.01,0.01,0.02c0,0.01,0.01,0.01,0.01,0.02c0.01,0.01,0.02,0.03,0.02,0.04s0.02,0.03,0.03,0.05 s0.02,0.03,0.03,0.05c0.01,0.02,0.02,0.03,0.03,0.05s0.02,0.03,0.04,0.05'
          />
        </g>
        <g id='SPLINE_00000055681511769107972910000006503367859117547935_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1514.82,708.05c0.05,0.07,0.12,0.14,0.18,0.21'
          />
        </g>
        <g id='SPLINE_00000129902534674004992510000013068044098154279336_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1515,708.25c0.07,0.07,0.14,0.13,0.21,0.18'
          />
        </g>
        <g id='SPLINE_00000148662110869547074300000015283856880242428044_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1515.31,708.51c0.02,0.01,0.03,0.02,0.05,0.04c0.02,0.01,0.03,0.02,0.05,0.03s0.03,0.02,0.05,0.03c0.02,0.01,0.03,0.02,0.05,0.03 c0.02,0.01,0.03,0.02,0.04,0.02c0.01,0,0.01,0.01,0.02,0.01c0.01,0,0.01,0.01,0.02,0.01'
          />
        </g>
        <g id='SPLINE_00000050664991835348181070000007666486680400948653_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1515.7,708.74C1515.71,708.74,1515.71,708.74,1515.7,708.74c0,0,0,0-0.02-0.01c-0.03-0.01-0.07-0.03-0.13-0.07'
          />
        </g>
        <g id='SPLINE_00000051374953647599747590000006354550492845853083_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1519.96,692.3c-0.02-0.03-0.03-0.05-0.03-0.05'
          />
        </g>
        <g id='SPLINE_00000178177972637977640640000013472214667681634217_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1530.95,703.29c0.03,0.02,0.05,0.03,0.05,0.03'
          />
        </g>
        <g id='SPLINE_00000094598133803854468340000008398109480081964217_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1522.58,699.65c0.16,0,0.33,0.02,0.48,0.07c0.15,0.05,0.3,0.13,0.43,0.24c0.06,0.05,0.12,0.11,0.18,0.17 c0.05,0.06,0.1,0.13,0.14,0.2c0.02,0.03,0.04,0.07,0.06,0.11c0.02,0.04,0.03,0.07,0.05,0.11c0.01,0.04,0.03,0.08,0.04,0.11 s0.02,0.08,0.03,0.12c0.01,0.04,0.01,0.08,0.02,0.12c0,0.04,0.01,0.08,0.01,0.12c0,0.04,0,0.08,0,0.12c0,0.04-0.01,0.08-0.01,0.12 c-0.01,0.04-0.01,0.08-0.02,0.12c-0.01,0.04-0.02,0.08-0.03,0.11s-0.03,0.07-0.04,0.11s-0.03,0.07-0.05,0.11 c-0.02,0.04-0.04,0.07-0.06,0.1c-0.02,0.03-0.05,0.07-0.07,0.1c-0.05,0.06-0.11,0.12-0.17,0.18c-0.12,0.11-0.26,0.2-0.41,0.26 c-0.15,0.06-0.31,0.09-0.48,0.1'
          />
        </g>
        <g id='SPLINE_00000090259183926892731330000013185517775288234139_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1522.64,702.43c-0.16,0-0.33-0.02-0.48-0.07s-0.3-0.13-0.42-0.23c-0.13-0.1-0.23-0.22-0.32-0.36c-0.08-0.14-0.14-0.29-0.17-0.45 c-0.03-0.16-0.03-0.32-0.01-0.48c0.03-0.16,0.08-0.32,0.16-0.46s0.18-0.27,0.3-0.37c0.06-0.05,0.13-0.1,0.19-0.14 c0.07-0.04,0.14-0.08,0.22-0.11c0.15-0.06,0.31-0.09,0.47-0.09'
          />
        </g>
        <g id='LINE_00000006676751040330282190000005479251841352924824_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1516.51}
            y1={705.67}
            x2={1517.59}
            y2={706.7}
          />
        </g>
        <g id='LWPOLYLINE_00000052800430199260890550000011915388528607085462_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1537.59,684.73 1537.59,684.74 1537.6,684.74 1537.6,684.74 1537.6,684.75 1537.61,684.75 1537.61,684.75 1537.61,684.76  1537.61,684.76 1537.62,684.77 1537.62,684.77 1537.62,684.77 1537.62,684.78 1537.62,684.78 1537.62,684.79 1537.62,684.79  1537.63,684.8 1537.63,684.8 1537.63,684.8 1537.63,684.81 1537.63,684.81 1537.63,684.82 1537.63,684.82 1537.63,684.83  1537.63,684.83 1537.62,684.84 1537.62,684.84 1537.62,684.85 1537.62,684.85 1537.62,684.85 1537.62,684.86 1537.62,684.86  1537.61,684.87 1537.61,684.87 1537.61,684.87 1537.61,684.88 1537.6,684.88 1537.6,684.89 1537.6,684.89 1537.6,684.89  1537.6,684.89  '
          />
        </g>
        <g id='LWPOLYLINE_00000109712086082193820110000011835846190828480942_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1537.15,685.36 1537.18,685.33 1537.21,685.3 1537.24,685.27 1537.27,685.24 1537.29,685.21 1537.32,685.19 1537.34,685.16  1537.37,685.13 1537.39,685.11 1537.41,685.09 1537.43,685.07 1537.45,685.05 1537.47,685.03 1537.49,685.01 1537.5,684.99  1537.52,684.98 1537.53,684.96 1537.54,684.95 1537.55,684.94 1537.56,684.93 1537.57,684.92 1537.58,684.91 1537.59,684.9  1537.59,684.9 1537.59,684.9 1537.59,684.89 1537.6,684.89  '
          />
        </g>
        <g id='LINE_00000009589960448073689750000000122679218486713729_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1537.6}
            y1={684.89}
            x2={1537.6}
            y2={684.89}
          />
        </g>
        <g id='LWPOLYLINE_00000112632326338703669430000009084081912258251403_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1537.43,684.74 1537.43,684.73 1537.44,684.73 1537.44,684.73 1537.44,684.72 1537.45,684.72 1537.45,684.72 1537.46,684.72  1537.46,684.71 1537.46,684.71 1537.47,684.71 1537.47,684.71 1537.48,684.71 1537.48,684.71 1537.48,684.7 1537.49,684.7  1537.49,684.7 1537.5,684.7 1537.5,684.7 1537.51,684.7 1537.51,684.7 1537.52,684.7 1537.52,684.7 1537.53,684.7 1537.53,684.7  1537.53,684.7 1537.54,684.7 1537.54,684.71 1537.55,684.71 1537.55,684.71 1537.56,684.71 1537.56,684.71 1537.56,684.71  1537.57,684.72 1537.57,684.72 1537.58,684.72 1537.58,684.72 1537.58,684.73 1537.59,684.73 1537.59,684.73 1537.59,684.73  '
          />
        </g>
        <g id='LINE_00000147906009356809032520000012491570176010477208_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1537.43}
            y1={684.74}
            x2={1537.43}
            y2={684.74}
          />
        </g>
        <g id='LWPOLYLINE_00000163772860229139553610000018271289314430574483_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1537.43,684.74 1537.43,684.74 1537.43,684.74 1537.42,684.74 1537.42,684.75 1537.41,684.75 1537.41,684.76 1537.4,684.77  1537.39,684.78 1537.38,684.79 1537.36,684.81 1537.35,684.82 1537.33,684.84 1537.32,684.85 1537.3,684.87 1537.28,684.89  1537.26,684.91 1537.24,684.93 1537.22,684.96 1537.2,684.98 1537.17,685.01 1537.15,685.03 1537.12,685.06 1537.1,685.09  1537.07,685.11 1537.04,685.14 1537.01,685.17 1536.99,685.2  '
          />
        </g>
        <g id='LWPOLYLINE_00000175280824617710002750000005346913359910832545_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1536.38,686.16 1536.41,686.13 1536.44,686.1 1536.47,686.07 1536.5,686.04 1536.53,686.01 1536.56,685.97 1536.59,685.94  1536.63,685.91 1536.66,685.88 1536.69,685.84 1536.72,685.81 1536.75,685.77 1536.79,685.74 1536.82,685.71 1536.85,685.67  1536.88,685.64 1536.91,685.61 1536.95,685.57 1536.98,685.54 1537.01,685.51 1537.04,685.48 1537.07,685.44 1537.1,685.41  1537.13,685.38 1537.15,685.36  '
          />
        </g>
        <g id='LWPOLYLINE_00000124142845429131109300000017439900260799556279_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1536.99,685.2 1536.96,685.23 1536.93,685.26 1536.9,685.29 1536.87,685.32 1536.84,685.36 1536.81,685.39 1536.78,685.42  1536.74,685.46 1536.71,685.49 1536.68,685.52 1536.65,685.56 1536.62,685.59 1536.58,685.62 1536.55,685.66 1536.52,685.69  1536.49,685.72 1536.46,685.76 1536.42,685.79 1536.39,685.82 1536.36,685.86 1536.33,685.89 1536.3,685.92 1536.27,685.95  1536.24,685.98 1536.22,686.01  '
          />
        </g>
        <g id='ELLIPSE_00000021098623265163992340000011527187232487429776_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.62,684.84c0.01-0.04,0-0.08-0.03-0.11'
          />
        </g>
        <g id='ELLIPSE_00000152984397647205795940000014422542938829810331_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.52,684.93c0.01,0,0.02,0,0.03,0'
          />
        </g>
        <g id='ELLIPSE_00000029039415140357697560000003396950143875425205_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.59,684.73c-0.03-0.03-0.07-0.04-0.11-0.03'
          />
        </g>
        <g id='ELLIPSE_00000151501550579358992490000004513326715734900869_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1536.21,686.04c0,0.03,0.02,0.06,0.05,0.09'
          />
        </g>
        <g id='ELLIPSE_00000062168687232088954950000012305969178918001339_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1536.23,686c-0.01,0-0.02,0.01-0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000075842346538700497840000006890426484296451488_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1536.34,686.04c-0.01-0.01-0.03-0.03-0.05-0.03'
          />
        </g>
        <g id='ELLIPSE_00000118368500456241268180000007103178121699746483_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1536.38,686.09c-0.01-0.02-0.02-0.03-0.04-0.04'
          />
        </g>
        <g id='ELLIPSE_00000134947218402837537720000002090723001057064333_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1536.37,686.17c0.01,0,0.02-0.01,0.02-0.02'
          />
        </g>
        <g id='ELLIPSE_00000119104742025519487270000009056962979634184849_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1536.26,686.12c0.03,0.03,0.06,0.05,0.09,0.05'
          />
        </g>
        <g id='LINE_00000168091933106247452830000016615271870296105115_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.5}
            y1={677.24}
            x2={1530.89}
            y2={677.87}
          />
        </g>
        <g id='LINE_00000125603818959710617010000004109077668883247501_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1530.89}
            y1={677.87}
            x2={1544.64}
            y2={691.62}
          />
        </g>
        <g id='LINE_00000085946565900520648830000012973202304225343120_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1544.64}
            y1={691.62}
            x2={1545.25}
            y2={690.98}
          />
        </g>
        <rect x={1559.82} y={665.52} width={13.09} height={119.1} />
      </g>
      <g id='POINTS'>
        <path id='cell159' d='M1508.97,723.45' />
        <path id='cell152' d='M1061.87,453.78' />
        <path id='cell162' d='M1507.22,504.68' />
        <path id='drugTesting120' d='M767.48,69.19' />
        <path id='openDorm132' d='M1789.76,406.59' />
        <path id='waterCloset' d='M472.52,61.84' />
        <path id='cell107' d='M326.77,96.85' />
        <path id='cell108' d='M326.77,246.56' />
        <path id='triage103' d='M87.55,443.53' />
        <path id='storeRoom116' d='M531.29,513.17' />
        <path id='cell124A' d='M954.64,98.68' />
        <path id='hallway129' d='M955.81,216.87' />
        <path id='utilityRoom139' d='M927.16,439.07' />
        <path id='intakeCenter' d='M646.39,246.56' />
        <path id='openDorm129ALeftSide' d='M1116.99,178.55' />
        <path id='openDorm129ARightSide' d='M1258.81,178.55' />
        <path id='recreationArea134' d='M1491.8,257.9' />
        <path id='cell153' d='M1193.35,484.9' />
        <path id='cell155' d='M1032.26,751.62' />
        <path id='cell156' d='M1183.93,751.62' />
        <path id='cell158' d='M1333.61,751.62' />
        <path id='cell163' d='M1336.89,484.9' />
        <path id='utilityChaseAndStorageRoom135' d='M1275.09,374.29' />
        <path id='storageRoom131' d='M1663.81,697.24' />
        <path id='frontLobby' d='M758.65,788.23' />
        <path id='centralControl115' d='M734.97,654.31' />
        <path id='visitation149' d='M894.64,704.1' />
        <path id='visit148' d='M816.71,690.01' />
        <path id='hallway111' d='M281.37,208.74' />
        <path id='hallway113A' d='M624.19,657.23' />
        <path id='hallway147' d='M873.35,618.74' />
        <path id='hallway150' d='M1314.32,617.91' />
        <path id='hallway151' d='M1064.71,539.41' />
        <path id='showerHallway151' d='M979.55,526.29' />
        <path id='cell126' d='M900.97,522.92' />
        <path id='shower162' d='M1432.31,510.04' />
        <path id='shower159' d='M1432.44,717.78' />
        <path id='bathroom132' d='M1833.93,669.76' />
        <path id='bathroom129A' d='M1109.82,297.49' />
        <path id='intakeCenterLower' d='M716.58,489.13' />
        <path id='cell124B' d='M952.58,314.91' />
      </g>
      <g id='A-WALL-_x5F__x5F__x5F__x5F_-OTLN'>
        <g id='LINE_00000090284125610233574330000005411720606401297593_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1920.71}
            y1={427.47}
            x2={1920.71}
            y2={445.26}
          />
        </g>
        <g id='LINE_00000174586340688364764410000002303871223344750744_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1920.71}
            y1={454.72}
            x2={1920.71}
            y2={472.51}
          />
        </g>
        <g id='LINE_00000054973363534799543750000010523940421833465986_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1920.71}
            y1={481.96}
            x2={1920.71}
            y2={499.76}
          />
        </g>
        <g id='LINE_00000029039820147802245890000012854117749790633366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={499.76}
            x2={1907.36}
            y2={481.96}
          />
        </g>
        <g id='LINE_00000073721237880105904820000005312881250628786820_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={472.51}
            x2={1907.36}
            y2={454.72}
          />
        </g>
        <g id='LINE_00000153698176378403911970000004911973540664858498_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={445.26}
            x2={1907.36}
            y2={427.47}
          />
        </g>
        <g id='LINE_00000145777287746790344640000012174031407758823829_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1312.77}
            y1={67.99}
            x2={1312.77}
            y2={23.51}
          />
        </g>
        <g id='LINE_00000083806528953797036600000011427539509614475444_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1326.11}
            y1={23.51}
            x2={1326.11}
            y2={67.99}
          />
        </g>
        <g id='LINE_00000119835954969239548470000006708857736640251530_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1816.45}
            y1={92.17}
            x2={1798.66}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000087391280185855727890000009012726615783646878_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1789.76}
            y1={92.17}
            x2={1771.97}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000016773482686150781750000001634499020922634135_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1763.07}
            y1={92.17}
            x2={1745.28}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000106122664872867323740000002055283525843911835_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1745.28}
            y1={78.83}
            x2={1763.07}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000096740836958658041010000009713102362159535534_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1771.97}
            y1={78.83}
            x2={1789.76}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000047781575357320061440000000256464530744814242_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1798.66}
            y1={78.83}
            x2={1816.45}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000168082266199902785240000017339411592048949390_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1688.33}
            y1={794.07}
            x2={1643.85}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000101095062854267152050000005526993979892805024_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1643.85}
            y1={780.73}
            x2={1688.33}
            y2={780.73}
          />
        </g>
        <g id='LINE_00000075884051120000790890000004699840578690070446_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={711.14}
            y1={840.77}
            x2={680}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000152222407335107683620000000182924247579882167_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={784.54}
            y1={840.77}
            x2={753.4}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000136408344855818459770000008887988112880560575_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={680}
            y1={827.42}
            x2={711.14}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000140005887156049389690000009204055681159113899_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={753.4}
            y1={827.42}
            x2={784.54}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000133508395996342013820000003962335343322678954_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={753.4}
            y1={840.77}
            x2={753.4}
            y2={835.76}
          />
        </g>
        <g id='LINE_00000163770574878533624700000002928922025046043067_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={753.4}
            y1={834.65}
            x2={753.4}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000121248736114630808540000010003271717769374596_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={711.14}
            y1={840.77}
            x2={711.14}
            y2={835.76}
          />
        </g>
        <g id='LINE_00000095306121160742172870000013107467310172602302_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={711.14}
            y1={834.65}
            x2={711.14}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000042726099009126659740000014835713467081307013_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={0.71}
            y1={498.45}
            x2={0.71}
            y2={365}
          />
        </g>
        <g id='LINE_00000062182348903664704140000007969253880102575796_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={14.05}
            y1={365}
            x2={14.05}
            y2={498.45}
          />
        </g>
        <g id='LINE_00000141442950136990878540000016825695293992901510_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1796.99}
            y1={607.49}
            x2={1907.36}
            y2={607.49}
          />
        </g>
        <g id='LINE_00000017475504708171327980000011998501325171639215_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={616.38}
            x2={1796.99}
            y2={616.38}
          />
        </g>
        <g id='LINE_00000169543584332200517130000015676370229467008919_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1796.99}
            y1={616.38}
            x2={1796.99}
            y2={607.49}
          />
        </g>
        <g id='LINE_00000168803436121834387570000001491143777191027898_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1907.36,321.82'
          />
        </g>
        <g id='LINE_00000072276343252130834560000002543431048099534221_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1907.36,331.83'
          />
        </g>
        <g id='LINE_00000017518642237558916430000012961562285435696005_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1658.26,332.25'
          />
        </g>
        <g id='LINE_00000052103503754111926060000015524809433972152714_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1658.26,322.24'
          />
        </g>
        <g id='LINE_00000155143943916473268350000007436380914491456446_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1711.51}
            y1={731.85}
            x2={1747.51}
            y2={731.85}
          />
        </g>
        <g id='LINE_00000081628346995375633480000003171474353208424329_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1747.51}
            y1={740.75}
            x2={1711.51}
            y2={740.75}
          />
        </g>
        <g id='LINE_00000054948957331515177880000003162291821155447182_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1711.51}
            y1={740.75}
            x2={1711.51}
            y2={731.85}
          />
        </g>
        <g id='LINE_00000170978667773146183500000001557205957498096017_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.72}
            y1={658.05}
            x2={1549.51}
            y2={658.05}
          />
        </g>
        <g id='LINE_00000005226533464907366010000006332474358125454490_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1244.87}
            y1={658.05}
            x2={1271.56}
            y2={658.05}
          />
        </g>
        <g id='LINE_00000065056813544744289130000010538925875739677085_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={961.43}
            y1={658.05}
            x2={979.22}
            y2={658.05}
          />
        </g>
        <g id='LINE_00000080189069166910638530000008816177352596289184_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={979.22}
            y1={666.95}
            x2={961.43}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000155858745121240456790000017893071736227972005_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1271.56}
            y1={666.95}
            x2={1244.87}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000062882046951249764790000006768814663939492779_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1549.51}
            y1={666.95}
            x2={1531.72}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000102521213668929023890000003421788800008671931_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1532.9}
            y1={568.12}
            x2={1550.69}
            y2={568.12}
          />
        </g>
        <g id='LINE_00000065753234616540681050000000681459375929555890_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1253.4}
            y1={568.12}
            x2={1277.87}
            y2={568.12}
          />
        </g>
        <g id='LINE_00000116918829162889279880000007999229859507973815_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={757.5}
            y1={568.12}
            x2={788.64}
            y2={568.12}
          />
        </g>
        <g id='LINE_00000059283500594483953020000013724739946349848485_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.65}
            y1={568.12}
            x2={710.79}
            y2={568.12}
          />
        </g>
        <g id='LINE_00000060026868789551061020000011196783984809331625_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={710.79}
            y1={577.02}
            x2={679.65}
            y2={577.02}
          />
        </g>
        <g id='LINE_00000132788113570463819400000002011277836355938437_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={788.64}
            y1={577.02}
            x2={757.5}
            y2={577.02}
          />
        </g>
        <g id='LINE_00000000189097396788465060000001976176483190758331_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1277.87}
            y1={577.02}
            x2={1253.4}
            y2={577.02}
          />
        </g>
        <g id='LINE_00000047765733110408589120000007517698058397121178_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1550.69}
            y1={577.02}
            x2={1532.9}
            y2={577.02}
          />
        </g>
        <g id='LINE_00000067922918274014150490000004468639108082320816_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={757.5}
            y1={577.02}
            x2={757.5}
            y2={574.24}
          />
        </g>
        <g id='LINE_00000103960651879919301960000004577157700876797065_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={757.5}
            y1={573.12}
            x2={757.5}
            y2={568.12}
          />
        </g>
        <g id='LINE_00000182492474136669272050000002564544013660595370_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={710.79}
            y1={577.02}
            x2={710.79}
            y2={574.24}
          />
        </g>
        <g id='LINE_00000012465578166627261250000014172660311668548488_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={710.79}
            y1={573.12}
            x2={710.79}
            y2={568.12}
          />
        </g>
        <g id='LINE_00000121980900143146162620000017682820910003906237_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={960.71}
            y1={625.56}
            x2={960.71}
            y2={656.69}
          />
        </g>
        <g id='LINE_00000070825512376594056170000006060231331771548037_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={947.36}
            y1={656.69}
            x2={947.36}
            y2={625.56}
          />
        </g>
        <g id='LINE_00000112608772034985397800000000597938846799708564_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={864.58}
            y1={742.86}
            x2={926.86}
            y2={742.86}
          />
        </g>
        <g id='LINE_00000157272047542973825540000010514393948857105828_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={926.86}
            y1={747.31}
            x2={864.58}
            y2={747.31}
          />
        </g>
        <g id='LINE_00000066511773962494004980000008898842952542081964_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={795.72}
            y1={713.06}
            x2={835.76}
            y2={713.06}
          />
        </g>
        <g id='LINE_00000045615243609066614780000005179332003288037533_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={835.76}
            y1={719.74}
            x2={795.72}
            y2={719.74}
          />
        </g>
        <g id='LINE_00000116237606579532603230000005314125611824115078_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.65}
            y1={621.65}
            x2={679.65}
            y2={692.82}
          />
        </g>
        <g id='LINE_00000005945560171281743040000014325240943859054482_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={670.76}
            y1={692.82}
            x2={670.76}
            y2={621.65}
          />
        </g>
        <g id='LINE_00000031191455167362878090000002217299823191727489_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={573.85}
            y1={578.35}
            x2={573.85}
            y2={680.66}
          />
        </g>
        <g id='LINE_00000181053737412306990510000011176097591244034713_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={564.95}
            y1={680.66}
            x2={564.95}
            y2={578.35}
          />
        </g>
        <g id='LINE_00000027596592418725437410000009384096228907210642_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1197.92}
            y1={128.32}
            x2={1197.92}
            y2={182.81}
          />
        </g>
        <g id='LINE_00000012436629281062500360000015482298235694024341_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1189.03}
            y1={182.81}
            x2={1189.03}
            y2={128.32}
          />
        </g>
        <g id='LINE_00000104669901820047380660000006711022392849790896_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1189.03}
            y1={182.81}
            x2={1197.92}
            y2={182.81}
          />
        </g>
        <g id='LINE_00000058570965024245188080000009857259774989239445_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={976.53}
            y1={340.86}
            x2={976.53}
            y2={298.6}
          />
        </g>
        <g id='LINE_00000043420186392293548650000004840930768500379304_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={985.42}
            y1={298.6}
            x2={985.42}
            y2={340.86}
          />
        </g>
        <g id='LINE_00000028319565799187461940000000827786700268732819_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={858.74}
            y1={478.44}
            x2={858.74}
            y2={519.19}
          />
        </g>
        <g id='LINE_00000035491329968847061550000006037961870970981551_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={849.85}
            y1={519.19}
            x2={849.85}
            y2={478.04}
          />
        </g>
        <g id='LINE_00000003783251706827495200000000151668042973605536_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={849.85}
            y1={519.19}
            x2={858.74}
            y2={519.19}
          />
        </g>
        <g id='LINE_00000096053444145468602630000007321614981598761880_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1011.57}
            y1={502.25}
            x2={1029.37}
            y2={502.25}
          />
        </g>
        <g id='LINE_00000096765195609055585370000014475571265265034129_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1029.37}
            y1={510.04}
            x2={1011.57}
            y2={510.04}
          />
        </g>
        <g id='LINE_00000066492406532579640670000008343939332090916279_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1474.01}
            y1={437.17}
            x2={1491.8}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000116930245248451673510000005903586547538496443_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1501.81}
            y1={437.17}
            x2={1519.6}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000133495478338962104590000005677474836888767370_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1527.9}
            y1={437.17}
            x2={1545.69}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000016782553278988896690000008675691973442615988_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1491.8}
            y1={453.85}
            x2={1474.01}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000099634713408739500920000003900173793927513777_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1545.69}
            y1={453.85}
            x2={1527.9}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000178899191978546301650000004043761710584770183_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1519.6}
            y1={453.85}
            x2={1501.81}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000174566407625399544430000011263954879993350307_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1022.69}
            y1={391.72}
            x2={1041.6}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000176759126192307197640000012888364554991354551_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1049.74}
            y1={391.72}
            x2={1068.65}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000061435439186434482860000017373164475718346387_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1077.54}
            y1={391.72}
            x2={1096.45}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000076584167722358097600000004774866334990854822_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1130.92}
            y1={391.72}
            x2={1149.83}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000110438465469887227780000005121405155268298901_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1157.61}
            y1={391.72}
            x2={1176.52}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000173155667193529398890000013523145837825381027_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1183.9}
            y1={391.72}
            x2={1202.8}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000090266877523160077600000010339349764369786808_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1211.7}
            y1={391.72}
            x2={1230.61}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000137093783980750920480000017985952456618224302_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1238.39}
            y1={391.72}
            x2={1257.29}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000141452685752389610800000004669212963386141863_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1273.98}
            y1={391.72}
            x2={1292.88}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000116914888713257558250000017572622724096135321_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1300.67}
            y1={391.72}
            x2={1319.57}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000168806934415482666290000001783577925245879221_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1327.44}
            y1={391.72}
            x2={1346.35}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000028302062182601652710000006219736248454227384_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1355.41}
            y1={391.72}
            x2={1374.31}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000124126291573909921580000014820135331040477375_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1382.1}
            y1={391.72}
            x2={1401}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000048459020169713920110000003736872707569363354_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1401}
            y1={408.4}
            x2={1382.1}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000114782276763164149150000009719371276172938922_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1374.31}
            y1={408.4}
            x2={1355.41}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000036248239468568252020000003884302737313178260_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1346.35}
            y1={408.4}
            x2={1327.44}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000055692559267115563460000010982474985311801006_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1319.57}
            y1={408.4}
            x2={1300.67}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000041997480254400216280000000166287302341850541_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.88}
            y1={408.4}
            x2={1273.98}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000061437889757325457550000008565335242774725035_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1257.29}
            y1={408.4}
            x2={1238.39}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000096750944669070951700000014155233978432120493_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1230.61}
            y1={408.4}
            x2={1211.7}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000083773295834441505830000003505839499240958138_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1202.8}
            y1={408.4}
            x2={1183.9}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000098908948264297295760000003985863739438849701_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1176.52}
            y1={408.4}
            x2={1157.61}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000113321093247202861200000018372016832092886662_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1149.83}
            y1={408.4}
            x2={1130.92}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000005224244194202660760000012425679868774896540_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1096.45}
            y1={408.4}
            x2={1077.54}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000082328202515047782270000006515826971100368791_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1068.65}
            y1={408.4}
            x2={1049.74}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000065037035819034727090000004298374657542719155_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1041.6}
            y1={408.4}
            x2={1022.69}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000066489710505307690840000017654274630566238650_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={248.72}
            y1={274.88}
            x2={248.72}
            y2={299.35}
          />
        </g>
        <g id='LINE_00000176002177349851868840000004556800326800551317_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={239.82}
            y1={299.35}
            x2={239.82}
            y2={274.88}
          />
        </g>
        <g id='LINE_00000139271418680933756670000015518063331638150321_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={625.69}
            y1={27.68}
            x2={554.52}
            y2={27.68}
          />
        </g>
        <g id='LINE_00000106126754548713615680000007040876297412967052_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={554.52}
            y1={12.11}
            x2={625.69}
            y2={12.11}
          />
        </g>
        <g id='LINE_00000075879292070244421640000013901808323832558763_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={290.26}
            y1={63.25}
            x2={290.26}
            y2={109.97}
          />
        </g>
        <g id='LINE_00000148642050570540348350000003956637788929903756_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={281.37}
            y1={109.97}
            x2={281.37}
            y2={63.25}
          />
        </g>
        <g id='LINE_00000172418054745018424330000002397936952342079658_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={308.06}
            y1={32.96}
            x2={308.06}
            y2={109.97}
          />
        </g>
        <g id='LINE_00000041280634265370503190000010316504736364955278_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={281.37}
            y1={109.97}
            x2={308.06}
            y2={109.97}
          />
        </g>
        <g id='LINE_00000013914616327696753480000018153616086823170996_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={404.04}
            y1={32.96}
            x2={308.06}
            y2={32.96}
          />
        </g>
        <g id='LINE_00000142140816888715615430000013248487555665530013_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={308.06}
            y1={224.48}
            x2={308.06}
            y2={302.61}
          />
        </g>
        <g id='LINE_00000094611719427253866830000007287452003200737980_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={308.06}
            y1={224.48}
            x2={290.26}
            y2={224.48}
          />
        </g>
        <g id='LINE_00000120560364382229742780000002172440538953403273_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={308.06}
            y1={302.61}
            x2={404.04}
            y2={302.61}
          />
        </g>
        <g id='LINE_00000049209786360820885070000017549250676148848553_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={281.37}
            y1={262.87}
            x2={281.37}
            y2={217.27}
          />
        </g>
        <g id='LINE_00000116221524408119148450000017769643960980346260_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={290.26}
            y1={217.27}
            x2={290.26}
            y2={262.87}
          />
        </g>
        <g id='LINE_00000067954679998109585950000014202411160103941777_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={290.26}
            y1={217.27}
            x2={281.37}
            y2={217.27}
          />
        </g>
        <g id='LINE_00000095329992794457359350000014573641392788448178_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={859.02}
            y1={118.31}
            x2={839}
            y2={118.31}
          />
        </g>
        <g id='LINE_00000056396866485986278900000001938303813230270366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={839}
            y1={110.53}
            x2={859.02}
            y2={110.53}
          />
        </g>
        <g id='LINE_00000092429958067572477430000008678145688656749958_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={839}
            y1={110.53}
            x2={839}
            y2={118.31}
          />
        </g>
        <g id='LINE_00000111154119853713261050000013515597687657248935_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={990.29}
            y1={83.56}
            x2={990.29}
            y2={122.76}
          />
        </g>
        <g id='LINE_00000141443450510445775200000008980484468285504899_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={981.39}
            y1={122.76}
            x2={981.39}
            y2={83.56}
          />
        </g>
        <g id='LINE_00000170986229019811484790000008995332699963983767_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={963.6}
            y1={122.76}
            x2={963.6}
            y2={43.25}
          />
        </g>
        <g id='LINE_00000059297525208925994980000016100538287068725177_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={963.6}
            y1={122.76}
            x2={990.29}
            y2={122.76}
          />
        </g>
        <g id='LINE_00000086691745170083740230000001870981878871086991_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={963.6}
            y1={43.25}
            x2={882.37}
            y2={43.25}
          />
        </g>
        <g id='LINE_00000114795279260827466480000005318336563384990646_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={420.72}
            y1={107.19}
            x2={420.72}
            y2={160.57}
          />
        </g>
        <g id='LINE_00000150099728926090580020000010728866971395502251_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={420.72}
            y1={185.73}
            x2={420.72}
            y2={239.11}
          />
        </g>
        <g id='LINE_00000096021772683551989130000013354142536978737307_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={404.04}
            y1={239.11}
            x2={404.04}
            y2={185.73}
          />
        </g>
        <g id='LINE_00000150820850580336152800000000396166461971753893_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={404.04}
            y1={160.57}
            x2={404.04}
            y2={107.19}
          />
        </g>
        <g id='LINE_00000093140110943189924070000015494839651277613224_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={959.85}
            y1={362.16}
            x2={959.85}
            y2={298.6}
          />
        </g>
        <g id='LINE_00000056419384370420526950000003147591998042166160_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={959.85}
            y1={298.6}
            x2={985.42}
            y2={298.6}
          />
        </g>
        <g id='LINE_00000056413931253816215990000012297522245891680690_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={882.37}
            y1={362.16}
            x2={959.85}
            y2={362.16}
          />
        </g>
        <g id='LINE_00000123402382954788520890000017169209181057098157_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1398.58}
            y1={840.77}
            x2={1380.78}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000131325587791090209940000005097565130103293327_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1371.89}
            y1={840.77}
            x2={1354.1}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000121985889152903789830000003204009595233316228_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1345.2}
            y1={840.77}
            x2={1327.41}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000087391879448477112180000000959015126141014413_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1318.29}
            y1={840.77}
            x2={1300.5}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000049915895041616158720000004482049889989913258_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1291.6}
            y1={840.77}
            x2={1273.81}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000026147338302169802400000008663996676537839274_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1248.6}
            y1={840.77}
            x2={1230.8}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000062898813210224928640000017911509671993787057_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1220.8}
            y1={840.77}
            x2={1203}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000181045521012361331910000018239520965086536846_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1192.99}
            y1={840.77}
            x2={1175.2}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000109749454630372318140000006432462435580125596_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1167.2}
            y1={840.77}
            x2={1149.4}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000021111705253043106780000005569858439182147262_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1139.39}
            y1={840.77}
            x2={1121.6}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000020375806525933043920000016466405563881548464_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.6}
            y1={827.42}
            x2={1139.39}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000097466141476578966060000005632663773817078684_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1149.4}
            y1={827.42}
            x2={1167.2}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000021838789729127101000000008267385292629601709_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1175.2}
            y1={827.42}
            x2={1192.99}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000081642705743923665240000015676866187991827847_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1203}
            y1={827.42}
            x2={1220.8}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000152266642793194717970000017000575224234553490_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1230.8}
            y1={827.42}
            x2={1248.6}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000157307561794804275830000003054607493990395552_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1273.81}
            y1={827.42}
            x2={1291.6}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000170245601980818423410000005809187202311963561_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1300.5}
            y1={827.42}
            x2={1318.29}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000100362237841618545090000001541585783476205440_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1327.41}
            y1={827.42}
            x2={1345.2}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000096034817443939603850000010801611090045320354_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1354.1}
            y1={827.42}
            x2={1371.89}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000065055665948805479200000000573831412225290392_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1380.78}
            y1={827.42}
            x2={1398.58}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000041981517352479788060000012544954861937758614_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1095.57}
            y1={840.77}
            x2={1077.78}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000073696049440887325010000006361987893853560452_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1068.88}
            y1={840.77}
            x2={1051.09}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000034787679997130255820000010392456457375136664_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1042.19}
            y1={840.77}
            x2={1024.4}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000052806222962856684710000003294443117018896515_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1015.29}
            y1={840.77}
            x2={997.49}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000052819095307650523810000014603379909718468232_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={986.93}
            y1={840.77}
            x2={969.13}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000181054513985424969480000001355805443197811376_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1077.78}
            y1={828.54}
            x2={1095.57}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000170978700330432605420000016288652538592759480_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={969.13}
            y1={828.54}
            x2={986.93}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000014604137180727202270000009045938949440731039_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={997.49}
            y1={828.54}
            x2={1015.29}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000021809660301401533400000014916750002071554206_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1024.4}
            y1={828.54}
            x2={1042.19}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000165956431711594760450000009987851489142497674_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.09}
            y1={828.54}
            x2={1068.88}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000119105254660228779870000016527970397522757763_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={795.72}
            y1={577.02}
            x2={795.72}
            y2={657.09}
          />
        </g>
        <g id='LINE_00000093891040334020786050000014254121364612518313_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={795.72}
            y1={669.14}
            x2={795.72}
            y2={709.17}
          />
        </g>
        <g id='LINE_00000145031256441522942300000015289144291009596832_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={789.05}
            y1={709.17}
            x2={789.05}
            y2={669.14}
          />
        </g>
        <g id='LINE_00000049904825965938978320000008201365068474038684_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={789.05}
            y1={657.09}
            x2={789.05}
            y2={577.02}
          />
        </g>
        <g id='LINE_00000018960147813112150650000004846694158521789883_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={873.48}
            y1={336.12}
            x2={873.48}
            y2={295.37}
          />
        </g>
        <g id='LINE_00000095310319127860827280000013612375496916638371_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={882.37}
            y1={295.37}
            x2={882.37}
            y2={336.51}
          />
        </g>
        <g id='LINE_00000088825079823653527440000013319869333496785560_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={882.37}
            y1={295.37}
            x2={873.48}
            y2={295.37}
          />
        </g>
      </g>
      <g id='S-COLS-_x5F__x5F__x5F__x5F_-MCUT'>
        <g id='LINE_00000030473147239032267090000013830641843475891078_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.97}
            y1={443.53}
            x2={662.18}
            y2={443.53}
          />
        </g>
        <g id='LINE_00000098178527646390227670000003299010075860056753_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={662.18}
            y1={443.53}
            x2={662.18}
            y2={461.32}
          />
        </g>
        <g id='LINE_00000123422981442253843700000015494820197215703710_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={662.18}
            y1={461.32}
            x2={679.97}
            y2={461.32}
          />
        </g>
        <g id='LINE_00000044878848419463605440000010236731993488632217_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.97}
            y1={461.32}
            x2={679.97}
            y2={443.53}
          />
        </g>
        <g id='LINE_00000137811995078779131740000011161471545346469513_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.97}
            y1={394.6}
            x2={662.18}
            y2={394.6}
          />
        </g>
        <g id='LINE_00000161600570672445404580000000302845475278728079_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={662.18}
            y1={412.39}
            x2={662.18}
            y2={394.6}
          />
        </g>
        <g id='LINE_00000103954735120811064640000000563542909360110208_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={662.18}
            y1={412.39}
            x2={679.97}
            y2={412.39}
          />
        </g>
        <g id='LINE_00000020390163285762496050000018001734908788350895_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.97}
            y1={412.39}
            x2={679.97}
            y2={394.6}
          />
        </g>
      </g>
      <g id='A-GLAZ-_x5F__x5F__x5F__x5F_-OTLN'>
        <g id='LINE_00000071528793530847390680000017150224978340438457_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={748.51}
            y1={742.18}
            x2={717}
            y2={742.18}
          />
        </g>
        <g id='LINE_00000120531323318664163040000000315850553503887789_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={717}
            y1={744.13}
            x2={748.51}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000142138952866885993110000006751420587656550018_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={682.43}
            y1={744.11}
            x2={713.11}
            y2={744.11}
          />
        </g>
        <g id='LINE_00000174594286009302185670000012065993446789638021_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={713.11}
            y1={742.17}
            x2={682.43}
            y2={742.17}
          />
        </g>
        <g id='LINE_00000044897857475558327230000006495537459174562726_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={783.08}
            y1={742.18}
            x2={752.4}
            y2={742.18}
          />
        </g>
        <g id='LINE_00000079476662835235149870000001375890827190245768_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={752.4}
            y1={744.13}
            x2={783.08}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000156559484288020716170000014110940541159395249_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={681.32}
            y1={738.69}
            x2={714.22}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000082334817670393082350000003168365309895063688_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={715.89}
            y1={738.69}
            x2={749.62}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000168076723011614715170000007747413529689264277_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={751.29}
            y1={738.69}
            x2={784.19}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000133517217139484192070000012142808604766446001_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={784.19}
            y1={747.59}
            x2={751.29}
            y2={747.59}
          />
        </g>
        <g id='LINE_00000017512866279569796880000003894786909619566483_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={749.62}
            y1={747.59}
            x2={715.89}
            y2={747.59}
          />
        </g>
        <g id='LINE_00000062162985910644070280000004104492964533807781_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={714.22}
            y1={747.59}
            x2={681.32}
            y2={747.59}
          />
        </g>
      </g>
      <g id='A-GLAZ-_x5F__x5F__x5F__x5F_-MCUT'>
        <g id='LINE_00000172426853966773332850000011966380241659265932_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={14.05}
            y1={366.39}
            x2={14.05}
            y2={365}
          />
        </g>
        <g id='LINE_00000110428657589099399730000014701765815036091283_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={14.05}
            y1={497.06}
            x2={14.05}
            y2={498.45}
          />
        </g>
        <g id='LINE_00000166659133771980967150000010435584730104891015_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={2.52}
            y1={495.67}
            x2={2.52}
            y2={367.78}
          />
        </g>
        <g id='LINE_00000037675880237945843600000007131756912092490645_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1.54}
            y1={367.78}
            x2={3.49}
            y2={367.78}
          />
        </g>
        <g id='LINE_00000111904012913542425660000002245541077782694078_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={3.49}
            y1={365.84}
            x2={3.49}
            y2={367.78}
          />
        </g>
        <g id='LINE_00000144299031820345920560000009038533226598174135_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1.54}
            y1={365.84}
            x2={3.49}
            y2={365.84}
          />
        </g>
        <g id='LINE_00000041284660984104402210000002414925958711330748_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1.54}
            y1={365.84}
            x2={1.54}
            y2={367.78}
          />
        </g>
        <g id='LINE_00000116938420044198419880000003646036143888868025_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1.54}
            y1={495.67}
            x2={3.49}
            y2={495.67}
          />
        </g>
        <g id='LINE_00000168802853064041318680000006384075744311410816_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={3.49}
            y1={497.62}
            x2={3.49}
            y2={495.67}
          />
        </g>
        <g id='LINE_00000093867272895557775430000009558414624081210285_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1.54}
            y1={497.62}
            x2={3.49}
            y2={497.62}
          />
        </g>
        <g id='LINE_00000004530926745510819030000011855222265070461596_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1.54}
            y1={497.62}
            x2={1.54}
            y2={495.67}
          />
        </g>
        <g id='LINE_00000172421812287566115050000009809875621238451120_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={3.49}
            y1={365.84}
            x2={0.71}
            y2={365.84}
          />
        </g>
        <g id='LINE_00000037686016414484472590000004835162917437042100_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={3.49}
            y1={366.39}
            x2={3.49}
            y2={365.84}
          />
        </g>
        <g id='LINE_00000031918797634841130190000014216339296123211923_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={3.49}
            y1={366.39}
            x2={14.05}
            y2={366.39}
          />
        </g>
        <g id='LINE_00000043431499644190117170000004691646380379075732_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={0.71}
            y1={365}
            x2={0.71}
            y2={365.84}
          />
        </g>
        <g id='LINE_00000088095970971311555400000003081486300266440860_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={14.05}
            y1={497.06}
            x2={3.49}
            y2={497.06}
          />
        </g>
        <g id='LINE_00000047036668764495049470000002291061300160328378_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={3.49}
            y1={497.62}
            x2={3.49}
            y2={497.06}
          />
        </g>
        <g id='LINE_00000049195270301157550990000017236800151972082593_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={3.49}
            y1={497.62}
            x2={0.71}
            y2={497.62}
          />
        </g>
        <g id='LINE_00000168083473212957078200000013266133923068241570_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={0.71}
            y1={498.45}
            x2={0.71}
            y2={497.62}
          />
        </g>
        <g id='LINE_00000122709332681834752190000007688483512742388393_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={404.04}
            y1={159.18}
            x2={404.04}
            y2={160.57}
          />
        </g>
        <g id='LINE_00000042723334117982031650000011984474415739540145_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={404.04}
            y1={108.58}
            x2={404.04}
            y2={107.19}
          />
        </g>
        <g id='LINE_00000121270115991663777650000006096164694706837167_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={418.91}
            y1={109.97}
            x2={418.91}
            y2={157.79}
          />
        </g>
        <g id='LINE_00000080895225506287416470000014699180517487314824_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={157.79}
            x2={417.94}
            y2={157.79}
          />
        </g>
        <g id='LINE_00000062894344053016083380000017596434658747106233_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={159.73}
            x2={417.94}
            y2={157.79}
          />
        </g>
        <g id='LINE_00000029750462925866914250000018322062560517313683_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={159.73}
            x2={417.94}
            y2={159.73}
          />
        </g>
        <g id='LINE_00000018943591636777387890000010543222224643277205_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={159.73}
            x2={419.89}
            y2={157.79}
          />
        </g>
        <g id='LINE_00000117646588784255885170000013445851753587627929_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={109.97}
            x2={417.94}
            y2={109.97}
          />
        </g>
        <g id='LINE_00000163048087792691189070000004487504860205946281_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={108.02}
            x2={417.94}
            y2={109.97}
          />
        </g>
        <g id='LINE_00000047780863011613307480000004727826267529297571_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={108.02}
            x2={417.94}
            y2={108.02}
          />
        </g>
        <g id='LINE_00000070087674030612539250000007733346759754299578_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={108.02}
            x2={419.89}
            y2={109.97}
          />
        </g>
        <g id='LINE_00000131360172036251815480000003798383201742881174_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={159.73}
            x2={420.72}
            y2={159.73}
          />
        </g>
        <g id='LINE_00000108290785508660830860000010144823058301414035_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={159.18}
            x2={417.94}
            y2={159.73}
          />
        </g>
        <g id='LINE_00000070103015752966506740000012995514053701171885_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={159.18}
            x2={404.04}
            y2={159.18}
          />
        </g>
        <g id='LINE_00000026842244859619132080000009645447469122176182_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={420.72}
            y1={160.57}
            x2={420.72}
            y2={159.73}
          />
        </g>
        <g id='LINE_00000095306488786302289790000012529368784620088485_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={404.04}
            y1={108.58}
            x2={417.94}
            y2={108.58}
          />
        </g>
        <g id='LINE_00000145045176004247549170000006116971902427116188_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={108.02}
            x2={417.94}
            y2={108.58}
          />
        </g>
        <g id='LINE_00000058564002400199661880000003068698052099402656_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={108.02}
            x2={420.72}
            y2={108.02}
          />
        </g>
        <g id='LINE_00000098199717281735124800000010434329837310130311_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={420.72}
            y1={107.19}
            x2={420.72}
            y2={108.02}
          />
        </g>
        <g id='LINE_00000126298259940990327250000016345138170732605316_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={404.04}
            y1={237.72}
            x2={404.04}
            y2={239.11}
          />
        </g>
        <g id='LINE_00000081617460690998501420000013934207012870641826_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={404.04}
            y1={187.12}
            x2={404.04}
            y2={185.73}
          />
        </g>
        <g id='LINE_00000062176108238039445590000016866511099662868410_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={418.91}
            y1={188.51}
            x2={418.91}
            y2={236.33}
          />
        </g>
        <g id='LINE_00000176030592373706388600000005274384640542108317_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={236.33}
            x2={417.94}
            y2={236.33}
          />
        </g>
        <g id='LINE_00000023272053846278678970000006478088330068417965_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={238.27}
            x2={417.94}
            y2={236.33}
          />
        </g>
        <g id='LINE_00000141436024359389990360000013525264207334685366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={238.27}
            x2={417.94}
            y2={238.27}
          />
        </g>
        <g id='LINE_00000170964391225793638580000006758865865936369286_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={238.27}
            x2={419.89}
            y2={236.33}
          />
        </g>
        <g id='LINE_00000013883305337481902040000012574015098541890445_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={188.51}
            x2={417.94}
            y2={188.51}
          />
        </g>
        <g id='LINE_00000157283238863552316550000013975078473724254366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={186.56}
            x2={417.94}
            y2={188.51}
          />
        </g>
        <g id='LINE_00000163037082358746492910000001932008180417673863_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={186.56}
            x2={417.94}
            y2={186.56}
          />
        </g>
        <g id='LINE_00000171686961335660142460000002511054215778505356_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={419.89}
            y1={186.56}
            x2={419.89}
            y2={188.51}
          />
        </g>
        <g id='LINE_00000054963628515180707820000003189943749927025576_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={238.27}
            x2={420.72}
            y2={238.27}
          />
        </g>
        <g id='LINE_00000155843040008321854480000003859985636590969256_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={237.72}
            x2={417.94}
            y2={238.27}
          />
        </g>
        <g id='LINE_00000147918240766706498740000018410162978739808694_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={237.72}
            x2={404.04}
            y2={237.72}
          />
        </g>
        <g id='LINE_00000106841757686870673140000013930245845498089914_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={420.72}
            y1={239.11}
            x2={420.72}
            y2={238.27}
          />
        </g>
        <g id='LINE_00000179616518112552727890000004777560211739974329_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={404.04}
            y1={187.12}
            x2={417.94}
            y2={187.12}
          />
        </g>
        <g id='LINE_00000160898756546783668190000010782033882809765014_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={186.56}
            x2={417.94}
            y2={187.12}
          />
        </g>
        <g id='LINE_00000183954696279847647560000014560494696741037984_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={417.94}
            y1={186.56}
            x2={420.72}
            y2={186.56}
          />
        </g>
        <g id='LINE_00000170244957322893860380000002094062960165382060_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={420.72}
            y1={185.73}
            x2={420.72}
            y2={186.56}
          />
        </g>
        <g id='LINE_00000122709310941299822550000015609364660034022044_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={624.3}
            y1={27.68}
            x2={625.69}
            y2={27.68}
          />
        </g>
        <g id='LINE_00000007389306251638099010000012047523611299159208_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={555.91}
            y1={27.68}
            x2={554.52}
            y2={27.68}
          />
        </g>
        <g id='LINE_00000035488838233604558690000003871085017905017511_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={557.3}
            y1={13.91}
            x2={622.91}
            y2={13.91}
          />
        </g>
        <g id='LINE_00000132057515344467996190000014526697943682415239_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={622.91}
            y1={12.94}
            x2={622.91}
            y2={14.89}
          />
        </g>
        <g id='LINE_00000052080959887158553560000001655576776286570658_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={624.85}
            y1={14.89}
            x2={622.91}
            y2={14.89}
          />
        </g>
        <g id='LINE_00000183961913170585303900000013175220554246462873_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={624.85}
            y1={12.94}
            x2={624.85}
            y2={14.89}
          />
        </g>
        <g id='LINE_00000019676190271930277130000006628819432066603925_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={624.85}
            y1={12.94}
            x2={622.91}
            y2={12.94}
          />
        </g>
        <g id='LINE_00000108272968371510100840000010975072886118769032_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={557.3}
            y1={12.94}
            x2={557.3}
            y2={14.89}
          />
        </g>
        <g id='LINE_00000036227312109127589290000013931556453563583625_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={555.35}
            y1={14.89}
            x2={557.3}
            y2={14.89}
          />
        </g>
        <g id='LINE_00000048492611165580437600000017032093268978133948_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={555.35}
            y1={12.94}
            x2={555.35}
            y2={14.89}
          />
        </g>
        <g id='LINE_00000023273609692394983770000017709677133911526313_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={555.35}
            y1={12.94}
            x2={557.3}
            y2={12.94}
          />
        </g>
        <g id='LINE_00000155836080281125760290000018406568981422579089_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={624.85}
            y1={14.89}
            x2={624.85}
            y2={12.11}
          />
        </g>
        <g id='LINE_00000131325933043446716040000018299358818044979358_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={624.3}
            y1={14.89}
            x2={624.85}
            y2={14.89}
          />
        </g>
        <g id='LINE_00000019672652756731243900000016252866265189639048_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={624.3}
            y1={14.89}
            x2={624.3}
            y2={27.68}
          />
        </g>
        <g id='LINE_00000173143566096639434690000012716135358818102928_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={625.69}
            y1={12.11}
            x2={624.85}
            y2={12.11}
          />
        </g>
        <g id='LINE_00000076590682371723200790000017544196911068855970_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={555.91}
            y1={27.68}
            x2={555.91}
            y2={14.89}
          />
        </g>
        <g id='LINE_00000140735177369854924570000005539781719269010595_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={555.35}
            y1={14.89}
            x2={555.91}
            y2={14.89}
          />
        </g>
        <g id='LINE_00000154412302756455509210000018092069569667603130_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={555.35}
            y1={14.89}
            x2={555.35}
            y2={12.11}
          />
        </g>
        <g id='LINE_00000134928936833503967300000003366783497154399128_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={554.52}
            y1={12.11}
            x2={555.35}
            y2={12.11}
          />
        </g>
        <g id='LINE_00000030466378260310681940000000810022047162619567_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={443.87}
            x2={1907.36}
            y2={445.26}
          />
        </g>
        <g id='LINE_00000104696492557142330450000002720330119969526455_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={428.86}
            x2={1907.36}
            y2={427.47}
          />
        </g>
        <g id='LINE_00000163052933606426443810000003185008488193332622_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1918.9}
            y1={430.25}
            x2={1918.9}
            y2={442.48}
          />
        </g>
        <g id='LINE_00000122719556064010276600000002854819191020234377_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={442.48}
            x2={1917.93}
            y2={442.48}
          />
        </g>
        <g id='LINE_00000059282714211654597710000017124014575944417704_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={444.43}
            x2={1917.93}
            y2={442.48}
          />
        </g>
        <g id='LINE_00000127757353688070151340000016450524842122694831_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={444.43}
            x2={1917.93}
            y2={444.43}
          />
        </g>
        <g id='LINE_00000116237834868785311310000017960243169854797221_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={444.43}
            x2={1919.87}
            y2={442.48}
          />
        </g>
        <g id='LINE_00000144337257507361084410000011673228619898599837_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={430.25}
            x2={1917.93}
            y2={430.25}
          />
        </g>
        <g id='LINE_00000040539865045196389110000013181781350798810496_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={428.31}
            x2={1917.93}
            y2={430.25}
          />
        </g>
        <g id='LINE_00000109727030328698323200000017229430802444788635_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={428.31}
            x2={1917.93}
            y2={428.31}
          />
        </g>
        <g id='LINE_00000132798090915730083600000004251011155583410108_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={428.31}
            x2={1919.87}
            y2={430.25}
          />
        </g>
        <g id='LINE_00000102514016594214578680000004390299382811733390_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={444.43}
            x2={1920.71}
            y2={444.43}
          />
        </g>
        <g id='LINE_00000165953963238937014730000011830391562224246463_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={443.87}
            x2={1917.93}
            y2={444.43}
          />
        </g>
        <g id='LINE_00000017488736247284710980000007949473681637365126_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={443.87}
            x2={1907.36}
            y2={443.87}
          />
        </g>
        <g id='LINE_00000004539888707108055970000004722955928137200305_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1920.71}
            y1={445.26}
            x2={1920.71}
            y2={444.43}
          />
        </g>
        <g id='LINE_00000038399280802006558150000005443327523574082225_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={428.86}
            x2={1917.93}
            y2={428.86}
          />
        </g>
        <g id='LINE_00000167365985772377196300000004156772817184099481_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={428.31}
            x2={1917.93}
            y2={428.86}
          />
        </g>
        <g id='LINE_00000084505271914357467650000017307675277468017341_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={428.31}
            x2={1920.71}
            y2={428.31}
          />
        </g>
        <g id='LINE_00000101810887083313461040000001802890759759069359_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1920.71}
            y1={427.47}
            x2={1920.71}
            y2={428.31}
          />
        </g>
        <g id='LINE_00000162354466788633515490000018201427656106513030_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={471.12}
            x2={1907.36}
            y2={472.51}
          />
        </g>
        <g id='LINE_00000032643362262038320990000010976446445867177653_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={456.11}
            x2={1907.36}
            y2={454.72}
          />
        </g>
        <g id='LINE_00000107585462384251728770000017967770410863507637_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1918.9}
            y1={457.5}
            x2={1918.9}
            y2={469.73}
          />
        </g>
        <g id='LINE_00000154422642237048743830000014430159970506495149_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={469.73}
            x2={1917.93}
            y2={469.73}
          />
        </g>
        <g id='LINE_00000053542290960688280940000005389170176411130778_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={471.68}
            x2={1917.93}
            y2={469.73}
          />
        </g>
        <g id='LINE_00000089557209598988223190000011903958936137116333_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={471.68}
            x2={1917.93}
            y2={471.68}
          />
        </g>
        <g id='LINE_00000114040438882133026030000016966007519471317930_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={471.68}
            x2={1919.87}
            y2={469.73}
          />
        </g>
        <g id='LINE_00000005958678748582482180000010828968318080282300_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={457.5}
            x2={1917.93}
            y2={457.5}
          />
        </g>
        <g id='LINE_00000072280906499635219040000005733385844791486100_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={455.55}
            x2={1917.93}
            y2={457.5}
          />
        </g>
        <g id='LINE_00000011709788273020688710000014480537567268698000_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={455.55}
            x2={1917.93}
            y2={455.55}
          />
        </g>
        <g id='LINE_00000085971007960341631930000010003457877898952355_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={455.55}
            x2={1919.87}
            y2={457.5}
          />
        </g>
        <g id='LINE_00000150798917621878430570000018042319281360007085_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={471.68}
            x2={1920.71}
            y2={471.68}
          />
        </g>
        <g id='LINE_00000108308362887051930210000013802744249690533805_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={471.12}
            x2={1917.93}
            y2={471.68}
          />
        </g>
        <g id='LINE_00000026156120523609487470000011813270408643258006_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={471.12}
            x2={1907.36}
            y2={471.12}
          />
        </g>
        <g id='LINE_00000058552100958078768580000013001289205229741998_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1920.71}
            y1={472.51}
            x2={1920.71}
            y2={471.68}
          />
        </g>
        <g id='LINE_00000043459719718752846420000010879111034574803866_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={456.11}
            x2={1917.93}
            y2={456.11}
          />
        </g>
        <g id='LINE_00000029762384272146659360000017691217268797748629_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={455.55}
            x2={1917.93}
            y2={456.11}
          />
        </g>
        <g id='LINE_00000107582978457492343230000006537654445310270120_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={455.55}
            x2={1920.71}
            y2={455.55}
          />
        </g>
        <g id='LINE_00000074414516367786927550000013112538851059241884_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1920.71}
            y1={454.72}
            x2={1920.71}
            y2={455.55}
          />
        </g>
        <g id='LINE_00000036961796762061340780000013502598578444102535_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={498.37}
            x2={1907.36}
            y2={499.76}
          />
        </g>
        <g id='LINE_00000020390547425390957120000002977639541832658564_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={483.35}
            x2={1907.36}
            y2={481.96}
          />
        </g>
        <g id='LINE_00000058579418749518313160000014277814020636705192_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1918.9}
            y1={484.74}
            x2={1918.9}
            y2={496.98}
          />
        </g>
        <g id='LINE_00000138535032007777881370000007982213824002094243_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={496.98}
            x2={1917.93}
            y2={496.98}
          />
        </g>
        <g id='LINE_00000024705728652433828550000018356138073840060560_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={498.92}
            x2={1917.93}
            y2={496.98}
          />
        </g>
        <g id='LINE_00000179632782000819454020000013493925960712355495_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={498.92}
            x2={1917.93}
            y2={498.92}
          />
        </g>
        <g id='LINE_00000059274938111371370340000000351457237452852395_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={498.92}
            x2={1919.87}
            y2={496.98}
          />
        </g>
        <g id='LINE_00000125581891894247573800000004426809615818013318_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={484.74}
            x2={1917.93}
            y2={484.74}
          />
        </g>
        <g id='LINE_00000027568890842043127940000006880893574434362000_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={482.8}
            x2={1917.93}
            y2={484.74}
          />
        </g>
        <g id='LINE_00000023248185398841925190000008202864176204704388_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={482.8}
            x2={1917.93}
            y2={482.8}
          />
        </g>
        <g id='LINE_00000112604094016916623140000016497418957182527646_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1919.87}
            y1={482.8}
            x2={1919.87}
            y2={484.74}
          />
        </g>
        <g id='LINE_00000065036305252133045940000000178158310922215354_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={498.92}
            x2={1920.71}
            y2={498.92}
          />
        </g>
        <g id='LINE_00000045601080161518492020000003129343618008039341_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={498.37}
            x2={1917.93}
            y2={498.92}
          />
        </g>
        <g id='LINE_00000077290486289437507320000014316862743082235321_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={498.37}
            x2={1907.36}
            y2={498.37}
          />
        </g>
        <g id='LINE_00000152970177985548212600000001342995675322393480_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1920.71}
            y1={499.76}
            x2={1920.71}
            y2={498.92}
          />
        </g>
        <g id='LINE_00000111907199768397829370000010007962976493685435_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1907.36}
            y1={483.35}
            x2={1917.93}
            y2={483.35}
          />
        </g>
        <g id='LINE_00000122713180859776364420000005118041383601757111_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={482.8}
            x2={1917.93}
            y2={483.35}
          />
        </g>
        <g id='LINE_00000011745929651576392440000009459985959461451397_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1917.93}
            y1={482.8}
            x2={1920.71}
            y2={482.8}
          />
        </g>
        <g id='LINE_00000091000897546747194590000015165372977705375370_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1920.71}
            y1={481.96}
            x2={1920.71}
            y2={482.8}
          />
        </g>
        <g id='LINE_00000107558733109552056940000017625336484234134406_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1761.68}
            y1={92.17}
            x2={1763.07}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000117648704310264707440000014201608273204488875_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.67}
            y1={92.17}
            x2={1745.28}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000006683737476021000940000002258108882471035288_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1748.06}
            y1={80.63}
            x2={1760.29}
            y2={80.63}
          />
        </g>
        <g id='LINE_00000041269237281558127810000012419606446959927218_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1760.29}
            y1={79.66}
            x2={1760.29}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000000902323718076569320000011291711680208309156_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1762.24}
            y1={81.61}
            x2={1760.29}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000065759184758564966780000012745439238320477858_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1762.24}
            y1={79.66}
            x2={1762.24}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000005951218205073278140000016905666072494695862_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1762.24}
            y1={79.66}
            x2={1760.29}
            y2={79.66}
          />
        </g>
        <g id='LINE_00000106141133411916770950000007113258988160274305_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1748.06}
            y1={79.66}
            x2={1748.06}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000034079648122478505270000016396491831615661228_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.11}
            y1={81.61}
            x2={1748.06}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000114781797931061366160000016011429547360084917_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.11}
            y1={79.66}
            x2={1746.11}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000050650791514551373690000007420419030393739166_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.11}
            y1={79.66}
            x2={1748.06}
            y2={79.66}
          />
        </g>
        <g id='LINE_00000101810340368278231610000013192405520301206428_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1762.24}
            y1={81.61}
            x2={1762.24}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000075871174155706269810000010264249234056138935_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1761.68}
            y1={81.61}
            x2={1762.24}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000170959963498947358550000017857161107234655661_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1761.68}
            y1={81.61}
            x2={1761.68}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000174581225736524730460000010848467290653238663_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1763.07}
            y1={78.83}
            x2={1762.24}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000150792831770006023540000000634642000575762339_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.67}
            y1={92.17}
            x2={1746.67}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000100371758855578237380000018166536048999721916_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.11}
            y1={81.61}
            x2={1746.67}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000021840984743700610270000013734317003565793416_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.11}
            y1={81.61}
            x2={1746.11}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000172429154112756438590000016735046670933819062_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1745.28}
            y1={78.83}
            x2={1746.11}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000082349095631286642350000016629449844747130280_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1788.37}
            y1={92.17}
            x2={1789.76}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000173120611667443473230000000133057061501926032_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1773.36}
            y1={92.17}
            x2={1771.97}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000173841587468790919550000002217644106673940614_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1774.75}
            y1={80.63}
            x2={1786.98}
            y2={80.63}
          />
        </g>
        <g id='LINE_00000142144968833333258630000000569092334746957716_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1786.98}
            y1={79.66}
            x2={1786.98}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000003810242496260087820000007069131701591850684_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1788.93}
            y1={81.61}
            x2={1786.98}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000178167118815971142290000011125447964710074250_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1788.93}
            y1={79.66}
            x2={1788.93}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000020363026541687737630000003897569495429340342_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1788.93}
            y1={79.66}
            x2={1786.98}
            y2={79.66}
          />
        </g>
        <g id='LINE_00000175283314965688040000000013881705788380326333_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1774.75}
            y1={79.66}
            x2={1774.75}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000114786475849866354760000015455699119739801763_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1772.8}
            y1={81.61}
            x2={1774.75}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000011018174364952569060000005929370364191234976_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1772.8}
            y1={79.66}
            x2={1772.8}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000072995726309597322370000011788843489951827605_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1772.8}
            y1={79.66}
            x2={1774.75}
            y2={79.66}
          />
        </g>
        <g id='LINE_00000163074217171818607210000006755939640176265406_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1788.93}
            y1={81.61}
            x2={1788.93}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000183945361688644840440000015259171772105500044_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1788.37}
            y1={81.61}
            x2={1788.93}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000061448190229924338430000012927147775500266885_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1788.37}
            y1={81.61}
            x2={1788.37}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000123417031672628667290000002119837577823467424_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1789.76}
            y1={78.83}
            x2={1788.93}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000070088997156789103650000015440056302123811712_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1773.36}
            y1={92.17}
            x2={1773.36}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000025417574761814397420000015204722886816034462_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1772.8}
            y1={81.61}
            x2={1773.36}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000098923755847953838890000011978386096527156145_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1772.8}
            y1={81.61}
            x2={1772.8}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000092414576500709504960000004957323637551154320_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1771.97}
            y1={78.83}
            x2={1772.8}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000062167421593579086360000002862594372694887082_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1815.06}
            y1={92.17}
            x2={1816.45}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000039816413083479389160000004605166035223141275_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1800.05}
            y1={92.17}
            x2={1798.66}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000044159316555714323270000015633207540183321991_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1801.44}
            y1={80.63}
            x2={1813.67}
            y2={80.63}
          />
        </g>
        <g id='LINE_00000133516205039922035910000008114764752052107964_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1813.67}
            y1={79.66}
            x2={1813.67}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000085971339310981761750000009428596322602576263_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1815.62}
            y1={81.61}
            x2={1813.67}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000123431226729287310670000010554873986942203539_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1815.62}
            y1={79.66}
            x2={1815.62}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000066512764712838480470000015386011460697745028_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1815.62}
            y1={79.66}
            x2={1813.67}
            y2={79.66}
          />
        </g>
        <g id='LINE_00000109743714035278345690000002042381693739846325_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1801.44}
            y1={79.66}
            x2={1801.44}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000182503641338356563210000001529499155645319852_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1799.49}
            y1={81.61}
            x2={1801.44}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000070838084043309291150000016471428646357018241_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1799.49}
            y1={79.66}
            x2={1799.49}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000039102223880466838220000014994566422404608425_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1799.49}
            y1={79.66}
            x2={1801.44}
            y2={79.66}
          />
        </g>
        <g id='LINE_00000063597704059825990590000008416193162064460165_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1815.62}
            y1={81.61}
            x2={1815.62}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000082341016942444169060000014255597349927004054_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1815.06}
            y1={81.61}
            x2={1815.62}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000156564887422850539590000009290781620211771782_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1815.06}
            y1={81.61}
            x2={1815.06}
            y2={92.17}
          />
        </g>
        <g id='LINE_00000160894504933227459960000005982568307909793435_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1816.45}
            y1={78.83}
            x2={1815.62}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000051361415902017258600000013211107711735390883_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1800.05}
            y1={92.17}
            x2={1800.05}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000139293643663341878380000017369156065088292267_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1799.49}
            y1={81.61}
            x2={1800.05}
            y2={81.61}
          />
        </g>
        <g id='LINE_00000074431265955193364860000011201223899199452325_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1799.49}
            y1={81.61}
            x2={1799.49}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000119806192855008036980000012578729073045252249_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1798.66}
            y1={78.83}
            x2={1799.49}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000085221404373528655220000000411364054494368672_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1490.41}
            y1={453.85}
            x2={1491.8}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000081610064879962480240000005554049045024770965_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1475.4}
            y1={453.85}
            x2={1474.01}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000109733916370640487260000015506336462150230703_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1476.79}
            y1={438.98}
            x2={1489.02}
            y2={438.98}
          />
        </g>
        <g id='LINE_00000010293213960374339130000006205339377994121403_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1489.02}
            y1={438.01}
            x2={1489.02}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000061440959221518204270000015103560582673855906_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1490.97}
            y1={439.95}
            x2={1489.02}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000163057762334824250720000000764530468751248824_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1490.97}
            y1={438.01}
            x2={1490.97}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000016759423968059322330000007659825758186437279_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1490.97}
            y1={438.01}
            x2={1489.02}
            y2={438.01}
          />
        </g>
        <g id='LINE_00000021079598448924742710000013259132633580847527_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1476.79}
            y1={438.01}
            x2={1476.79}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000134239066567761265650000013236711923336017330_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1474.84}
            y1={439.95}
            x2={1476.79}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000085232056047852593260000000203514155111681689_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1474.84}
            y1={438.01}
            x2={1474.84}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000129164531735669406780000014040939971415355821_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1474.84}
            y1={438.01}
            x2={1476.79}
            y2={438.01}
          />
        </g>
        <g id='LINE_00000101818549798300155750000005234494825469557644_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1490.97}
            y1={439.95}
            x2={1490.97}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000068659424750209381610000002800308624013384094_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1490.41}
            y1={439.95}
            x2={1490.97}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000102536302551142526750000010206050079970775224_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1490.41}
            y1={439.95}
            x2={1490.41}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000074405840682574335970000011386898287843383479_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1491.8}
            y1={437.17}
            x2={1490.97}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000096776877721902072750000007694934616020940443_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1475.4}
            y1={453.85}
            x2={1475.4}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000084494560595996866040000015229118573274390158_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1474.84}
            y1={439.95}
            x2={1475.4}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000111870590462756276570000018413442540225754271_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1474.84}
            y1={439.95}
            x2={1474.84}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000048492726296096460470000005765748972779479475_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1474.01}
            y1={437.17}
            x2={1474.84}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000028284380027179752260000017433439273393915564_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1518.21}
            y1={453.85}
            x2={1519.6}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000062890805021206896930000014030329878954638212_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1503.2}
            y1={453.85}
            x2={1501.81}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000080897441896632161600000010763530835884626571_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1504.59}
            y1={438.98}
            x2={1516.82}
            y2={438.98}
          />
        </g>
        <g id='LINE_00000163756993477505879020000008641749714907073670_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1516.82}
            y1={438.01}
            x2={1516.82}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000158714603821733694100000013232097739635708851_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1518.77}
            y1={439.95}
            x2={1516.82}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000172412852411067761380000001134055191360168836_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1518.77}
            y1={438.01}
            x2={1518.77}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000170987299024927121960000006878659914048351661_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1518.77}
            y1={438.01}
            x2={1516.82}
            y2={438.01}
          />
        </g>
        <g id='LINE_00000152249747815154063620000012667920090907225525_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1504.59}
            y1={438.01}
            x2={1504.59}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000132800575760190915230000007819098845236411298_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1502.65}
            y1={439.95}
            x2={1504.59}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000019638418802618310610000006193892245300157095_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1502.65}
            y1={438.01}
            x2={1502.65}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000023984749739253121410000011134615632366628528_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1502.65}
            y1={438.01}
            x2={1504.59}
            y2={438.01}
          />
        </g>
        <g id='LINE_00000179629909029089760420000001656500681357076117_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1518.77}
            y1={439.95}
            x2={1518.77}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000018217748641316527500000007066244784757799074_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1518.21}
            y1={439.95}
            x2={1518.77}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000091703081602522674120000009429756275323728289_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1518.21}
            y1={439.95}
            x2={1518.21}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000017482098021369469420000006288300489933075368_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1519.6}
            y1={437.17}
            x2={1518.77}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000061450423712804258350000011379671890851903918_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1503.2}
            y1={453.85}
            x2={1503.2}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000003798252157217330750000005428235712162548146_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1502.65}
            y1={439.95}
            x2={1503.2}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000156589454967673884210000011021442468092455042_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1502.65}
            y1={439.95}
            x2={1502.65}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000121986082905057373350000014498501823449380749_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1501.81}
            y1={437.17}
            x2={1502.65}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000085238461297459372730000016352543867802522756_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1544.3}
            y1={453.85}
            x2={1545.69}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000081639510478762805920000003670459473217983661_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1529.29}
            y1={453.85}
            x2={1527.9}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000145769556937777270920000009093145223074504620_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1530.68}
            y1={438.98}
            x2={1542.91}
            y2={438.98}
          />
        </g>
        <g id='LINE_00000012444944143047468160000017961276919741774252_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1542.91}
            y1={438.01}
            x2={1542.91}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000075158678142757480050000006125531442893131197_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1544.86}
            y1={439.95}
            x2={1542.91}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000082341637428697201710000004236044350712058530_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1544.86}
            y1={438.01}
            x2={1544.86}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000157989794695205019020000010291701585172637341_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1544.86}
            y1={438.01}
            x2={1542.91}
            y2={438.01}
          />
        </g>
        <g id='LINE_00000042720179613951860530000009190624571234331530_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1530.68}
            y1={438.01}
            x2={1530.68}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000034087257631489528880000005168462733865663372_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1528.73}
            y1={439.95}
            x2={1530.68}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000007426816980891834760000006490849450158392199_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1528.73}
            y1={438.01}
            x2={1528.73}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000114070887207725373650000002417502948194643370_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1528.73}
            y1={438.01}
            x2={1530.68}
            y2={438.01}
          />
        </g>
        <g id='LINE_00000120554592277028028330000005891681639496513408_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1544.86}
            y1={439.95}
            x2={1544.86}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000142871593265697444140000012272557202962896523_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1544.3}
            y1={439.95}
            x2={1544.86}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000155852165423517004690000012120772961676599950_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1544.3}
            y1={439.95}
            x2={1544.3}
            y2={453.85}
          />
        </g>
        <g id='LINE_00000163759967007867153630000000663501768601314197_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1545.69}
            y1={437.17}
            x2={1544.86}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000138557367031102777180000007685017088759953079_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1529.29}
            y1={453.85}
            x2={1529.29}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000124149323353253317680000012478484425148774327_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1528.73}
            y1={439.95}
            x2={1529.29}
            y2={439.95}
          />
        </g>
        <g id='LINE_00000132085537836398683350000000131490680531904400_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1528.73}
            y1={439.95}
            x2={1528.73}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000040538272600995068020000012582838840382075269_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1527.9}
            y1={437.17}
            x2={1528.73}
            y2={437.17}
          />
        </g>
        <g id='LINE_00000163058721448997571560000006523155328628678583_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={749.62}
            y1={742.18}
            x2={748.51}
            y2={742.18}
          />
        </g>
        <g id='LINE_00000153671290718980203230000014424324427538366380_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={748.51}
            y1={742.18}
            x2={748.51}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000181807082315774227320000015763430172950334911_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={748.51}
            y1={744.13}
            x2={749.62}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000164492261256781285850000005735471328783720633_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={749.62}
            y1={744.13}
            x2={749.62}
            y2={742.18}
          />
        </g>
        <g id='LINE_00000182516068573473042220000015334843823902909093_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={715.89}
            y1={742.18}
            x2={715.89}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000011724844487145047110000018033833219244994433_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={715.89}
            y1={744.13}
            x2={717}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000089557148154592458140000007189826462438335388_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={717}
            y1={744.13}
            x2={717}
            y2={742.18}
          />
        </g>
        <g id='LINE_00000142165232621402613700000003518185334952027325_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={717}
            y1={742.18}
            x2={715.89}
            y2={742.18}
          />
        </g>
        <g id='LINE_00000055705552213372912540000010355065779009201062_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={682.43}
            y1={744.11}
            x2={682.43}
            y2={742.17}
          />
        </g>
        <g id='LINE_00000021104806281508846460000004829545550072740761_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={682.43}
            y1={742.17}
            x2={681.32}
            y2={742.17}
          />
        </g>
        <g id='LINE_00000167358641059413767230000004293064163504283809_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={681.32}
            y1={742.17}
            x2={681.32}
            y2={744.11}
          />
        </g>
        <g id='LINE_00000057828894927050099890000017248889607916145339_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={681.32}
            y1={744.11}
            x2={682.43}
            y2={744.11}
          />
        </g>
        <g id='LINE_00000165927597732999999210000011051796982717192345_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={713.11}
            y1={742.17}
            x2={713.11}
            y2={744.11}
          />
        </g>
        <g id='LINE_00000169529163986746654490000012429476167906906806_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={713.11}
            y1={744.11}
            x2={714.22}
            y2={744.11}
          />
        </g>
        <g id='LINE_00000013915519453772770950000017489430794941242782_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={714.22}
            y1={744.11}
            x2={714.22}
            y2={742.17}
          />
        </g>
        <g id='LINE_00000114755518648790376310000010470304477645819020_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={714.22}
            y1={742.17}
            x2={713.11}
            y2={742.17}
          />
        </g>
        <g id='LINE_00000039112466098699167320000010694971982045143446_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={784.19}
            y1={744.13}
            x2={784.19}
            y2={742.18}
          />
        </g>
        <g id='LINE_00000062912435289305681220000008756880146923024031_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={784.19}
            y1={742.18}
            x2={783.08}
            y2={742.18}
          />
        </g>
        <g id='LINE_00000094611911282626580390000012873713267265896890_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={783.08}
            y1={742.18}
            x2={783.08}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000119834057898112719860000016065102697979154586_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={783.08}
            y1={744.13}
            x2={784.19}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000016770674452682656820000007281501918730698139_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={751.29}
            y1={742.18}
            x2={751.29}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000052065491158321262550000006755945760894451352_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={751.29}
            y1={744.13}
            x2={752.4}
            y2={744.13}
          />
        </g>
        <g id='LINE_00000016791376983383299240000010992715292813891725_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={752.4}
            y1={744.13}
            x2={752.4}
            y2={742.18}
          />
        </g>
        <g id='LINE_00000136393479353817165630000007288077743662710707_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={752.4}
            y1={742.18}
            x2={751.29}
            y2={742.18}
          />
        </g>
        <g id='HATCH'>
          <path
            fill='none'
            d='M679.65,738.69v8.9h1.67v-8.9H679.65 M714.22,738.69v8.9h1.67v-8.9H714.22 M749.62,738.69v8.9h1.67v-8.9 H749.62 M785.86,738.69h-1.67v8.9h1.67V738.69z'
          />
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.001}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M751.29,739.85l-1.67,1.67 M715.89,746.94l-0.65,0.65 M681.32,739.04l-1.67,1.67'
          />
        </g>
        <g id='LINE_00000044163179978948096960000015170339358732399495_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={785.86}
            y1={738.69}
            x2={784.19}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000129891444551282325240000013695356411870287793_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={784.19}
            y1={738.69}
            x2={784.19}
            y2={747.59}
          />
        </g>
        <g id='LINE_00000142893452139631948480000014026411696634407615_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={784.19}
            y1={747.59}
            x2={785.71}
            y2={747.59}
          />
        </g>
        <g id='LINE_00000056411135345509159930000003739200404495555512_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={749.62}
            y1={747.59}
            x2={751.29}
            y2={747.59}
          />
        </g>
        <g id='LINE_00000036930162231281464190000013404957056233178536_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={751.29}
            y1={747.59}
            x2={751.29}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000137113938705200825980000010940360939385335180_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={751.29}
            y1={738.69}
            x2={749.62}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000152250318764853560770000012016660431625932672_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={749.62}
            y1={738.69}
            x2={749.62}
            y2={747.59}
          />
        </g>
        <g id='LINE_00000155139649067876710500000014902214955756745347_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.65}
            y1={747.59}
            x2={681.32}
            y2={747.59}
          />
        </g>
        <g id='LINE_00000136372758064627149900000011864221180279965119_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={681.32}
            y1={747.59}
            x2={681.32}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000168097265558190726460000007028000108540348546_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={681.32}
            y1={738.69}
            x2={679.65}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000024688174497956453720000007598790773506063284_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={714.22}
            y1={747.59}
            x2={715.89}
            y2={747.59}
          />
        </g>
        <g id='LINE_00000147188969992487961870000005965863294374304138_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={715.89}
            y1={747.59}
            x2={715.89}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000173877568485056712260000009810157271030669190_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={715.89}
            y1={738.69}
            x2={714.22}
            y2={738.69}
          />
        </g>
        <g id='LINE_00000133497867685668183110000006894566892286809268_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={714.22}
            y1={738.69}
            x2={714.22}
            y2={747.59}
          />
        </g>
        <g id='LINE_00000044154064129549513830000009656452256393626294_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1382.17}
            y1={827.42}
            x2={1380.78}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000125578171654755397840000009697390618648633493_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.19}
            y1={827.42}
            x2={1398.58}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000130639083203532180140000004721982988984380546_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1395.8}
            y1={838.96}
            x2={1383.56}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000000943956277774960740000009316455387901586353_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1383.56}
            y1={839.93}
            x2={1383.56}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000001646835946066440310000002012972081944650399_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1381.62}
            y1={837.99}
            x2={1383.56}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000101818289209206100890000006201554454614668443_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1381.62}
            y1={839.93}
            x2={1381.62}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000045585095884099038020000009341521851552634554_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1381.62}
            y1={839.93}
            x2={1383.56}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000087391194042181635260000014633967430718985618_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1395.8}
            y1={839.93}
            x2={1395.8}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000128462290632150804830000014211417033920603787_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={837.99}
            x2={1395.8}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000130630720534302170960000016984928725918895522_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={839.93}
            x2={1397.74}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000142175878114428112970000012633530419641694855_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={839.93}
            x2={1395.8}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000086682079865587124220000010261783440684984971_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1381.62}
            y1={837.99}
            x2={1381.62}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000170991589934777452700000007402683388741927834_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1382.17}
            y1={837.99}
            x2={1381.62}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000098943010239264997980000007660738785187313537_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1382.17}
            y1={837.99}
            x2={1382.17}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000059277165018867260900000003546409930562142344_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1380.78}
            y1={840.77}
            x2={1381.62}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000096771611559425861260000016214288475047469471_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.19}
            y1={827.42}
            x2={1397.19}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000066476144408548122220000010872898423767762622_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={837.99}
            x2={1397.19}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000129199287951571859060000013216643478380018095_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1397.74}
            y1={837.99}
            x2={1397.74}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000117639104801097894840000010536111739756694665_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1398.58}
            y1={840.77}
            x2={1397.74}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000093888890912442124880000010262832531786643871_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1355.49}
            y1={827.42}
            x2={1354.1}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000163070275396750228320000006621268975383374467_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1370.5}
            y1={827.42}
            x2={1371.89}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000153687563855251266290000005889353897785962678_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1369.11}
            y1={838.96}
            x2={1356.88}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000026163386967324293260000011629267574806630803_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1356.88}
            y1={839.93}
            x2={1356.88}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000106149227508775326020000000767438691375374263_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1354.93}
            y1={837.99}
            x2={1356.88}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000165214150265730129210000006998331802605064604_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1354.93}
            y1={839.93}
            x2={1354.93}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000008837544486938193940000007972551386877237639_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1354.93}
            y1={839.93}
            x2={1356.88}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000132792408787586907540000002690268420274406294_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1369.11}
            y1={839.93}
            x2={1369.11}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000111157117813831125170000012945368006834620061_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1371.05}
            y1={837.99}
            x2={1369.11}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000110442457264714559020000004162736090251607725_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1371.05}
            y1={839.93}
            x2={1371.05}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000152251525040796303000000010138945043128147601_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1371.05}
            y1={839.93}
            x2={1369.11}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000031209290121183620360000009639707867063092409_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1354.93}
            y1={837.99}
            x2={1354.93}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000130617792627984935210000010695998248169911201_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1355.49}
            y1={837.99}
            x2={1354.93}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000100355368346463620150000002915705967335512734_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1355.49}
            y1={837.99}
            x2={1355.49}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000029036080971943368660000009899360714976067756_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1354.1}
            y1={840.77}
            x2={1354.93}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000177472974141025992660000013012001271820444600_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1370.5}
            y1={827.42}
            x2={1370.5}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000049193859880548418430000004990305303968955049_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1371.05}
            y1={837.99}
            x2={1370.5}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000022534365329596123540000014234393386196422077_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1371.05}
            y1={837.99}
            x2={1371.05}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000060738169996130863760000004560023024682695344_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1371.89}
            y1={840.77}
            x2={1371.05}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000065047602334820185640000012876768012256656289_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.8}
            y1={827.42}
            x2={1327.41}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000178178094131987959070000017732252166632182666_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1343.81}
            y1={827.42}
            x2={1345.2}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000018942086013259522010000011998852616649216648_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1342.42}
            y1={838.96}
            x2={1330.19}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000145050399353792438940000015733590476063931030_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1330.19}
            y1={839.93}
            x2={1330.19}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000152250097929020596790000009190667266997456808_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.24}
            y1={837.99}
            x2={1330.19}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000132089793678107268580000006031515244426895270_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.24}
            y1={839.93}
            x2={1328.24}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000147184819457446167120000017490398258806346658_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.24}
            y1={839.93}
            x2={1330.19}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000065051460424925713020000002195355328041102519_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1342.42}
            y1={839.93}
            x2={1342.42}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000129912759831470702730000010032491665670982829_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1344.36}
            y1={837.99}
            x2={1342.42}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000026147292186657027430000003534896638690240152_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1344.36}
            y1={839.93}
            x2={1344.36}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000078732874288249115470000015006890546196867261_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1344.36}
            y1={839.93}
            x2={1342.42}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000029011726421618879520000017616913889762569372_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.24}
            y1={837.99}
            x2={1328.24}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000046331529052616807820000017024668155271817365_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.8}
            y1={837.99}
            x2={1328.24}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000118367915523975652420000014102491451356924593_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.8}
            y1={837.99}
            x2={1328.8}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000150796379519685515130000005229934418844492163_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1327.41}
            y1={840.77}
            x2={1328.24}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000028300923772606550440000015526821760013104796_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1343.81}
            y1={827.42}
            x2={1343.81}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000070110090642772832090000008526527719006548107_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1344.36}
            y1={837.99}
            x2={1343.81}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000170248729859007759940000005743355504593111938_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1344.36}
            y1={837.99}
            x2={1344.36}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000108311363333487667570000005016526221394045366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1345.2}
            y1={840.77}
            x2={1344.36}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000181800690039100580200000003247685339845765035_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.89}
            y1={827.42}
            x2={1300.5}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000064333089825632968380000003931837923604593591_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1316.9}
            y1={827.42}
            x2={1318.29}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000170266836157521464800000005455496646449833612_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1315.51}
            y1={838.96}
            x2={1303.28}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000163039567720870083250000000991482506743090344_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1303.28}
            y1={839.93}
            x2={1303.28}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000127033297671074799920000001338709238377100987_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.33}
            y1={837.99}
            x2={1303.28}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000031926282159045294130000010628333993060839321_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.33}
            y1={839.93}
            x2={1301.33}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000003100911414275255770000016099613642481149087_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.33}
            y1={839.93}
            x2={1303.28}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000064347744976261010840000014890357045148322482_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1315.51}
            y1={839.93}
            x2={1315.51}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000034798196138798590830000006142655282283711898_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1317.46}
            y1={837.99}
            x2={1315.51}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000075868552121540980410000007989239274575061683_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1317.46}
            y1={839.93}
            x2={1317.46}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000047028384893981547120000008017295527041122435_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1317.46}
            y1={839.93}
            x2={1315.51}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000021802092561598028870000011810801838667401903_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.33}
            y1={837.99}
            x2={1301.33}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000117665688037563582950000016540568307875701393_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.89}
            y1={837.99}
            x2={1301.33}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000120550254670597478620000012724501861102683573_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.89}
            y1={837.99}
            x2={1301.89}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000174599194058291300230000006594837739263010212_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1300.5}
            y1={840.77}
            x2={1301.33}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000115496905560691655520000001821303270249143474_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1316.9}
            y1={827.42}
            x2={1316.9}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000028285629382850622540000007505616579599192469_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1317.46}
            y1={837.99}
            x2={1316.9}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000116954254643678134670000006875325024814546336_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1317.46}
            y1={837.99}
            x2={1317.46}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000098915408524055371320000017584530766799539594_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1318.29}
            y1={840.77}
            x2={1317.46}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000029036537985840509240000009054060561066806169_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1275.2}
            y1={827.42}
            x2={1273.81}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000074430864913325574310000014555507848184215202_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.21}
            y1={827.42}
            x2={1291.6}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000154404464088654708300000005409036872696139927_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1288.82}
            y1={838.96}
            x2={1276.59}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000031891837336990526130000010481139491931589529_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1276.59}
            y1={839.93}
            x2={1276.59}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000041278625956162199620000016200852296701299601_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1274.64}
            y1={837.99}
            x2={1276.59}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000048468682256348791100000007519075570093409441_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1274.64}
            y1={839.93}
            x2={1274.64}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000052821992462001097740000018159320951522090641_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1274.64}
            y1={839.93}
            x2={1276.59}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000074412875575483376170000012152778750657531822_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1288.82}
            y1={839.93}
            x2={1288.82}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000029012068271525806040000010742775420575263922_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.77}
            y1={837.99}
            x2={1288.82}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000022525004429292343690000006062170003763207319_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.77}
            y1={839.93}
            x2={1290.77}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000158011687030076682670000013032276322018801594_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.77}
            y1={839.93}
            x2={1288.82}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000134971477856605823060000006260497381959946122_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1274.64}
            y1={837.99}
            x2={1274.64}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000173118637886080730660000012512853994049307816_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1275.2}
            y1={837.99}
            x2={1274.64}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000046317299542233706280000002264920719016868481_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1275.2}
            y1={837.99}
            x2={1275.2}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000169525616559342418930000009333738598781195404_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1273.81}
            y1={840.77}
            x2={1274.64}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000173878225032235842400000013569036836341410206_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.21}
            y1={827.42}
            x2={1290.21}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000044159528372499488530000005491224943973360797_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.77}
            y1={837.99}
            x2={1290.21}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000014614789922656114600000006785744401411616440_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.77}
            y1={837.99}
            x2={1290.77}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000090993364134978294860000003455559842744115387_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1291.6}
            y1={840.77}
            x2={1290.77}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000060002877146669982070000003398878880510979215_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1232.19}
            y1={827.42}
            x2={1230.8}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000153667672303598273470000011270562340683933850_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1247.21}
            y1={827.42}
            x2={1248.6}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000052094294470501920130000006659978818877629358_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1245.82}
            y1={838.96}
            x2={1233.58}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000029032799960071492540000010520056373741041810_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1233.58}
            y1={839.93}
            x2={1233.58}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000173849440267594716270000008649588608343678142_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1231.64}
            y1={837.99}
            x2={1233.58}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000183248022200180086690000007835545889035621527_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1231.64}
            y1={839.93}
            x2={1231.64}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000051349641953607672920000014460138570162746758_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1231.64}
            y1={839.93}
            x2={1233.58}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000098934544043695955000000003231150918467251621_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1245.82}
            y1={839.93}
            x2={1245.82}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000106865600596560009890000004158369585088646079_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1247.76}
            y1={837.99}
            x2={1245.82}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000023994145026890335060000016016925449149240758_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1247.76}
            y1={839.93}
            x2={1247.76}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000103975371330510373080000010164618969439755654_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1247.76}
            y1={839.93}
            x2={1245.82}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000085213895166913885310000013067014363030637710_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1231.64}
            y1={837.99}
            x2={1231.64}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000182514440600860134060000006073408231164792210_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1232.19}
            y1={837.99}
            x2={1231.64}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000000202556661777227940000001466773689921522817_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1232.19}
            y1={837.99}
            x2={1232.19}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000080904287424139253270000016402692157467896254_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1230.8}
            y1={840.77}
            x2={1231.64}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000162334930054994746990000017078264271066143410_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1247.21}
            y1={827.42}
            x2={1247.21}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000158727611831395648850000012720198084598871706_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1247.76}
            y1={837.99}
            x2={1247.21}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000090286631133776497180000017096177762592197269_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1247.76}
            y1={837.99}
            x2={1247.76}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000124136681501232550890000003626447945930941357_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1248.6}
            y1={840.77}
            x2={1247.76}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000021078706457992879730000015877304686207498123_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1204.39}
            y1={827.42}
            x2={1203}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000171681984120081795300000008244871926371514787_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1219.41}
            y1={827.42}
            x2={1220.8}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000140723941731644739110000008721646236576663688_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1218.02}
            y1={838.96}
            x2={1205.78}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000104675960771125296400000009072759911391845291_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1205.78}
            y1={839.93}
            x2={1205.78}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000049902772688630712410000014676439608457159593_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1203.84}
            y1={837.99}
            x2={1205.78}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000032612385111361659690000007570876843683376796_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1203.84}
            y1={839.93}
            x2={1203.84}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000073697243436823920370000004907141975482590853_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1203.84}
            y1={839.93}
            x2={1205.78}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000057843401379346344560000003740542361230122128_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1218.02}
            y1={839.93}
            x2={1218.02}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000073001137580103834540000008996203311275054230_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1219.96}
            y1={837.99}
            x2={1218.02}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000039812674838463569590000014047779681702626235_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1219.96}
            y1={839.93}
            x2={1219.96}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000003085080337220854270000016626006587094650026_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1219.96}
            y1={839.93}
            x2={1218.02}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000091016920238351458740000015105796436587459463_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1203.84}
            y1={837.99}
            x2={1203.84}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000162313753742022278590000016058916202321429147_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1204.39}
            y1={837.99}
            x2={1203.84}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000007409953597777741730000002483302824904798095_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1204.39}
            y1={837.99}
            x2={1204.39}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000004529733229332706310000013323940347404224947_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1203}
            y1={840.77}
            x2={1203.84}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000091734287486871342780000014513350254975964841_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1219.41}
            y1={827.42}
            x2={1219.41}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000003809811243029803310000014731235268741032077_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1219.96}
            y1={837.99}
            x2={1219.41}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000108998583558759483960000018174885358202987138_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1219.96}
            y1={837.99}
            x2={1219.96}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000170983008428694889160000015147599048102916009_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1220.8}
            y1={840.77}
            x2={1219.96}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000103263877778870747350000017004122715412592003_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1176.59}
            y1={827.42}
            x2={1175.2}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000009554342637355199530000009023779669160196490_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1191.6}
            y1={827.42}
            x2={1192.99}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000041261706180301139090000010593293013211267732_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1190.21}
            y1={838.96}
            x2={1177.98}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000026875552437036644400000008314610312709088898_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1177.98}
            y1={839.93}
            x2={1177.98}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000052825640189645920090000008989817660893555076_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1176.03}
            y1={837.99}
            x2={1177.98}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000092447838879511694510000006614591177002488961_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1176.03}
            y1={839.93}
            x2={1176.03}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000168836299523179518950000008365317804187135908_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1176.03}
            y1={839.93}
            x2={1177.98}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000056385788182383648830000018358204282929783710_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1190.21}
            y1={839.93}
            x2={1190.21}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000160182266135007958080000001547064873294913465_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1192.16}
            y1={837.99}
            x2={1190.21}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000104692627612145985910000005455106668325909642_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1192.16}
            y1={839.93}
            x2={1192.16}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000011711737004613842080000007437966697484700351_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1192.16}
            y1={839.93}
            x2={1190.21}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000068637559622292174950000015895675704397355188_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1176.03}
            y1={837.99}
            x2={1176.03}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000152973291492310556200000005507181899314497448_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1176.59}
            y1={837.99}
            x2={1176.03}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000002347825412229954490000015464895465210947759_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1176.59}
            y1={837.99}
            x2={1176.59}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000149352639994768290130000000433898451229808553_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1175.2}
            y1={840.77}
            x2={1176.03}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000109019043275797979080000013179637346953626302_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1191.6}
            y1={827.42}
            x2={1191.6}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000003098607918006778580000008786275131735451555_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1192.16}
            y1={837.99}
            x2={1191.6}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000101796454329626778900000002124854835204964239_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1192.16}
            y1={837.99}
            x2={1192.16}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000070101997900479994960000005383605725902257548_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1192.99}
            y1={840.77}
            x2={1192.16}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000008836054278198123980000008283035415397948546_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1150.79}
            y1={827.42}
            x2={1149.4}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000021825552420882950690000007611627913868208034_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1165.81}
            y1={827.42}
            x2={1167.2}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000138539422931702079450000003243492312832238770_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1164.42}
            y1={838.96}
            x2={1152.18}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000087407743181717269320000005435481985129517737_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1152.18}
            y1={839.93}
            x2={1152.18}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000171713516520569775940000017922020269711275951_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1150.24}
            y1={837.99}
            x2={1152.18}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000094601437180465123590000017285023920847779741_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1150.24}
            y1={839.93}
            x2={1150.24}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000175304275585482821350000016383992402579789206_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1150.24}
            y1={839.93}
            x2={1152.18}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000142138772612901249940000001154704667519718800_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1164.42}
            y1={839.93}
            x2={1164.42}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000178204331609641422610000007398935991269154723_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1166.36}
            y1={837.99}
            x2={1164.42}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000083058522268150785700000007682150311579256250_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1166.36}
            y1={839.93}
            x2={1166.36}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000111150169959200840300000009762439577711322504_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1166.36}
            y1={839.93}
            x2={1164.42}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000049911195118452371730000007260841184640960676_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1150.24}
            y1={837.99}
            x2={1150.24}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000023978578150946456130000016628179609981974686_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1150.79}
            y1={837.99}
            x2={1150.24}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000000181188530635940180000009113833317341576600_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1150.79}
            y1={837.99}
            x2={1150.79}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000166669479925664434100000012769358262361076873_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1149.4}
            y1={840.77}
            x2={1150.24}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000092422397240290281340000005956343169068751501_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1165.81}
            y1={827.42}
            x2={1165.81}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000129908030720655337950000010168817143893330327_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1166.36}
            y1={837.99}
            x2={1165.81}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000101814647428039095030000008822338534144563590_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1166.36}
            y1={837.99}
            x2={1166.36}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000181787136873941345430000011293342869525133200_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1167.2}
            y1={840.77}
            x2={1166.36}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000169541376545047149650000009447417172102443402_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1122.99}
            y1={827.42}
            x2={1121.6}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000151511158076477232300000004229240774032489618_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1138}
            y1={827.42}
            x2={1139.39}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000141419145487633082120000012958729825368012702_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1136.61}
            y1={838.96}
            x2={1124.38}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000102521340531349640520000009131600423727368889_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1124.38}
            y1={839.93}
            x2={1124.38}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000051366022120051990070000000873314983247831972_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1122.44}
            y1={837.99}
            x2={1124.38}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000124131822875485089470000017033455289641320121_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1122.44}
            y1={839.93}
            x2={1122.44}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000020388164169686369320000001967271725957632670_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1122.44}
            y1={839.93}
            x2={1124.38}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000183971999734748186750000009480153795165259421_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1136.61}
            y1={839.93}
            x2={1136.61}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000123442708566208842110000001722991204720623005_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1138.56}
            y1={837.99}
            x2={1136.61}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000129165725033019756300000017964909163472768150_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1138.56}
            y1={839.93}
            x2={1138.56}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000128470954015116763770000006896579762809081994_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1138.56}
            y1={839.93}
            x2={1136.61}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000103979592167792655460000011918979584302747802_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1122.44}
            y1={837.99}
            x2={1122.44}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000018937478653330183400000013758552378735757480_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1122.99}
            y1={837.99}
            x2={1122.44}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000022540660142998212920000006777605391906868888_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1122.99}
            y1={837.99}
            x2={1122.99}
            y2={827.42}
          />
        </g>
        <g id='LINE_00000070076946340014525840000011010682368831106238_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.6}
            y1={840.77}
            x2={1122.44}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000149363526969080574880000011426223970403405978_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1138}
            y1={827.42}
            x2={1138}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000160886560884111064490000004929232964624678822_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1138.56}
            y1={837.99}
            x2={1138}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000052799462302335904650000009521410485475398834_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1138.56}
            y1={837.99}
            x2={1138.56}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000101061674333466695890000007579689986562921659_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1139.39}
            y1={840.77}
            x2={1138.56}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000011010043493371001160000007712513834571351713_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1079.17}
            y1={828.54}
            x2={1077.78}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000046339845741256736350000009818953002874407556_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1094.18}
            y1={828.54}
            x2={1095.57}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000018935013541433410470000004986080227802762890_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1092.79}
            y1={838.96}
            x2={1080.56}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000107585056877574028700000018285984258067486084_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1080.56}
            y1={839.93}
            x2={1080.56}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000078753599855895900950000006839551985564456832_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.61}
            y1={837.99}
            x2={1080.56}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000085935895774116793150000008482176850658273933_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.61}
            y1={839.93}
            x2={1078.61}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000105400050295041120500000011854294123640745637_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.61}
            y1={839.93}
            x2={1080.56}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000164477042375528323490000012523625768678746012_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1092.79}
            y1={839.93}
            x2={1092.79}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000056396952792142554470000009616954444569221535_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1094.74}
            y1={837.99}
            x2={1092.79}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000098207841402796178060000015702802476694411936_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1094.74}
            y1={839.93}
            x2={1094.74}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000125579735386003925950000001214701520964752039_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1094.74}
            y1={839.93}
            x2={1092.79}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000027595493827073464720000002399087921298562723_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.61}
            y1={837.99}
            x2={1078.61}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000070112127220105379100000011681425758162329242_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1079.17}
            y1={837.99}
            x2={1078.61}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000076605890985364670200000013553406353833581453_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1079.17}
            y1={837.99}
            x2={1079.17}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000147941521407534556510000013643627896749779119_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1077.78}
            y1={840.77}
            x2={1078.61}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000122683026579258816260000018197448648514613638_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1094.18}
            y1={828.54}
            x2={1094.18}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000062174260016743939760000011216575349352443070_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1094.74}
            y1={837.99}
            x2={1094.18}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000057112440818993402390000016073773811504878492_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1094.74}
            y1={837.99}
            x2={1094.74}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000021100489118927564710000013702083556395147394_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1095.57}
            y1={840.77}
            x2={1094.74}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000000205378517169715490000012621149805231837371_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.48}
            y1={828.54}
            x2={1051.09}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000033362817479447987650000005066805878467992473_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1067.49}
            y1={828.54}
            x2={1068.88}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000047022103842025026310000017354202957954720152_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1066.1}
            y1={838.96}
            x2={1053.87}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000047033050099222961210000014701553571270844853_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1053.87}
            y1={839.93}
            x2={1053.87}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000114795671638609224830000017237471061734576778_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.93}
            y1={837.99}
            x2={1053.87}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000076564154314569368040000016060436732785580984_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.93}
            y1={839.93}
            x2={1051.93}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000030447490730077275140000009634240679068019883_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.93}
            y1={839.93}
            x2={1053.87}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000077324442771047991280000004698050450504008610_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1066.1}
            y1={839.93}
            x2={1066.1}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000165231570444604795700000010009210399801480885_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1068.05}
            y1={837.99}
            x2={1066.1}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000005226302168009847060000000369571761279659455_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1068.05}
            y1={839.93}
            x2={1068.05}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000171689961267910177660000014126560386698064045_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1068.05}
            y1={839.93}
            x2={1066.1}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000050649468927507949750000018003199480718319294_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.93}
            y1={837.99}
            x2={1051.93}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000148627673740379311420000016556204723423737764_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.48}
            y1={837.99}
            x2={1051.93}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000150806247438915477560000004263972850244606854_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.48}
            y1={837.99}
            x2={1052.48}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000165213446493384353330000003080205098998165947_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.09}
            y1={840.77}
            x2={1051.93}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000049901903037467125490000018047680804354965899_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1067.49}
            y1={828.54}
            x2={1067.49}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000124133721163015521980000009890456309325479871_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1068.05}
            y1={837.99}
            x2={1067.49}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000000213837991876693840000010063964884445838219_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1068.05}
            y1={837.99}
            x2={1068.05}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000067932958856152483810000017196223694771928731_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1068.88}
            y1={840.77}
            x2={1068.05}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000044150047910339687090000017807518920660187272_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1025.79}
            y1={828.54}
            x2={1024.4}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000132068249709732048290000009372883296410643377_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1040.8}
            y1={828.54}
            x2={1042.19}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000027586291781334908390000011838077328661532817_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1039.41}
            y1={838.96}
            x2={1027.18}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000089566707806850354700000003442977378607180928_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1027.18}
            y1={839.93}
            x2={1027.18}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000084529683612995179210000005099935902088745656_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1025.24}
            y1={837.99}
            x2={1027.18}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000154398856338741912330000013699146076267965104_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1025.24}
            y1={839.93}
            x2={1025.24}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000114047835874052359480000000809465018138829501_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1025.24}
            y1={839.93}
            x2={1027.18}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000056390666331930136500000016161694120240942515_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1039.41}
            y1={839.93}
            x2={1039.41}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000036960434653475429930000017788563541280961174_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1041.36}
            y1={837.99}
            x2={1039.41}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000165942230742291304790000000861765960797523647_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1041.36}
            y1={839.93}
            x2={1041.36}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000152238489232325812020000007049444671369730192_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1041.36}
            y1={839.93}
            x2={1039.41}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000136395216676069385110000000964157425927425673_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1025.24}
            y1={837.99}
            x2={1025.24}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000152959095669219838320000014033569214419172250_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1025.79}
            y1={837.99}
            x2={1025.24}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000140007953740976555030000002053239831032895404_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1025.79}
            y1={837.99}
            x2={1025.79}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000132087598283758870450000016695325177267484344_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1024.4}
            y1={840.77}
            x2={1025.24}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000109716411457316138050000006494470347301622424_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1040.8}
            y1={828.54}
            x2={1040.8}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000052796948783296390140000016984833682295687596_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1041.36}
            y1={837.99}
            x2={1040.8}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000100383890688807606020000011929733979529365162_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1041.36}
            y1={837.99}
            x2={1041.36}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000114071199287956711950000012784165305514348713_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1042.19}
            y1={840.77}
            x2={1041.36}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000014604676658663743900000011673508513607362996_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={998.88}
            y1={828.54}
            x2={997.49}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000088825977112571529760000016893573074251732115_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1013.9}
            y1={828.54}
            x2={1015.29}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000088129597027185116650000018280899242320053686_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1012.51}
            y1={838.96}
            x2={1000.27}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000044150723963822957430000000562015717424407472_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1000.27}
            y1={839.93}
            x2={1000.27}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000056405199095411425880000010882303225874567097_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={998.33}
            y1={837.99}
            x2={1000.27}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000121269548382814505920000004340285268577506722_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={998.33}
            y1={839.93}
            x2={998.33}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000119832757428815983680000014337355736350505601_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={998.33}
            y1={839.93}
            x2={1000.27}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000092424700959215785280000006826556073278680464_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1012.51}
            y1={839.93}
            x2={1012.51}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000039128278706790848630000008448415625250535589_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1014.45}
            y1={837.99}
            x2={1012.51}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000181782221133515711160000015891392127588495750_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1014.45}
            y1={839.93}
            x2={1014.45}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000137810686142469173890000006704046637477734281_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1014.45}
            y1={839.93}
            x2={1012.51}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000120531616679558588180000004119581144631945375_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={998.33}
            y1={837.99}
            x2={998.33}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000039093136957932019190000004846475423803691925_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={998.88}
            y1={837.99}
            x2={998.33}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000163071049552391259830000000172410453953503912_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={998.88}
            y1={837.99}
            x2={998.88}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000080195864627047246570000012987735855540683157_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={997.49}
            y1={840.77}
            x2={998.33}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000065766839180034105980000007756334725551892155_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1013.9}
            y1={828.54}
            x2={1013.9}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000079475686282346543200000004879660116627298750_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1014.45}
            y1={837.99}
            x2={1013.9}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000103944979917625043460000005554530668097985411_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1014.45}
            y1={837.99}
            x2={1014.45}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000160188218454596269230000005933709969771165843_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1015.29}
            y1={840.77}
            x2={1014.45}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000165218866160189117850000006006337446750395530_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={970.52}
            y1={828.54}
            x2={969.13}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000119827932985682678540000010648814695570074766_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={985.54}
            y1={828.54}
            x2={986.93}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000141425909152872153010000000226636411556613563_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={984.15}
            y1={838.96}
            x2={971.91}
            y2={838.96}
          />
        </g>
        <g id='LINE_00000075879358006909981960000013315811688789606058_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={971.91}
            y1={839.93}
            x2={971.91}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000005258078667205637090000010262501129917805725_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={969.97}
            y1={837.99}
            x2={971.91}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000181055358045494668760000011462188169915022721_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={969.97}
            y1={839.93}
            x2={969.97}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000034802688832125164430000012395525377037498809_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={969.97}
            y1={839.93}
            x2={971.91}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000167378973254964850540000012315190292254560674_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={984.15}
            y1={839.93}
            x2={984.15}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000150799827838579341250000013199745687933504432_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={986.09}
            y1={837.99}
            x2={984.15}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000046326456462275858320000008853074813723208839_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={986.09}
            y1={839.93}
            x2={986.09}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000116199821500123595710000000079737603162660284_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={986.09}
            y1={839.93}
            x2={984.15}
            y2={839.93}
          />
        </g>
        <g id='LINE_00000012444957316351685550000002394115593990548920_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={969.97}
            y1={837.99}
            x2={969.97}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000071544080412642008250000001955610362699058823_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={970.52}
            y1={837.99}
            x2={969.97}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000151524539247097071740000000469986665394037653_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={970.52}
            y1={837.99}
            x2={970.52}
            y2={828.54}
          />
        </g>
        <g id='LINE_00000056426833891168542890000001030887496998994865_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={969.13}
            y1={840.77}
            x2={969.97}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000080908548016981192460000010677988211054683825_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={985.54}
            y1={828.54}
            x2={985.54}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000098217880193871840070000006805377912759160998_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={986.09}
            y1={837.99}
            x2={985.54}
            y2={837.99}
          />
        </g>
        <g id='LINE_00000037654330547731950460000001734249783139142064_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={986.09}
            y1={837.99}
            x2={986.09}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000017512813522175083270000006847680508697885838_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={986.93}
            y1={840.77}
            x2={986.09}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000001631994789477269780000012792782807504014510_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.65}
            y1={623.04}
            x2={679.65}
            y2={621.65}
          />
        </g>
        <g id='LINE_00000183210324018369182320000014531947358655222449_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.65}
            y1={691.43}
            x2={679.65}
            y2={692.82}
          />
        </g>
        <g id='LINE_00000149351259889629807410000015240305786344820353_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={672.56}
            y1={690.04}
            x2={672.56}
            y2={624.43}
          />
        </g>
        <g id='LINE_00000181050735575162593290000008711988455768785326_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={671.59}
            y1={624.43}
            x2={673.54}
            y2={624.43}
          />
        </g>
        <g id='LINE_00000154415632373617121130000016350682525166006173_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={673.54}
            y1={622.48}
            x2={673.54}
            y2={624.43}
          />
        </g>
        <g id='LINE_00000183960655484088231040000008102810595333258898_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={671.59}
            y1={622.48}
            x2={673.54}
            y2={622.48}
          />
        </g>
        <g id='LINE_00000142156517642600693640000002137688847313218951_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={671.59}
            y1={622.48}
            x2={671.59}
            y2={624.43}
          />
        </g>
        <g id='LINE_00000162328381385022222290000008260345299271659967_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={671.59}
            y1={690.04}
            x2={673.54}
            y2={690.04}
          />
        </g>
        <g id='LINE_00000155120016244674422160000014893694084606269375_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={673.54}
            y1={691.99}
            x2={673.54}
            y2={690.04}
          />
        </g>
        <g id='LINE_00000099626168264432820530000009862839713954946995_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={671.59}
            y1={691.99}
            x2={673.54}
            y2={691.99}
          />
        </g>
        <g id='LINE_00000083792278047861240700000014756470858904183937_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={671.59}
            y1={691.99}
            x2={671.59}
            y2={690.04}
          />
        </g>
        <g id='LINE_00000012439713091900736910000006377456901916031627_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={673.54}
            y1={622.48}
            x2={670.76}
            y2={622.48}
          />
        </g>
        <g id='LINE_00000041296255274563345110000013993966489286138286_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={673.54}
            y1={623.04}
            x2={673.54}
            y2={622.48}
          />
        </g>
        <g id='LINE_00000051346204334206900350000002876103124058578074_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={673.54}
            y1={623.04}
            x2={679.65}
            y2={623.04}
          />
        </g>
        <g id='LINE_00000075855863652094717940000016246219932351937677_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={670.76}
            y1={621.65}
            x2={670.76}
            y2={622.48}
          />
        </g>
        <g id='LINE_00000176761938381503767670000007728348739354973072_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={679.65}
            y1={691.43}
            x2={673.54}
            y2={691.43}
          />
        </g>
        <g id='LINE_00000098933175168548786300000009559808865747089333_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={673.54}
            y1={691.99}
            x2={673.54}
            y2={691.43}
          />
        </g>
        <g id='LINE_00000146461377523017603900000017941125105041086339_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={673.54}
            y1={691.99}
            x2={670.76}
            y2={691.99}
          />
        </g>
        <g id='LINE_00000142152478568279375100000013272895175726575796_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={670.76}
            y1={692.82}
            x2={670.76}
            y2={691.99}
          />
        </g>
        <g id='LINE_00000150783031695682928190000003850182216527814019_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={789.05}
            y1={707.78}
            x2={789.05}
            y2={709.17}
          />
        </g>
        <g id='LINE_00000105417094055465799830000015586276519662628487_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={789.05}
            y1={670.53}
            x2={789.05}
            y2={669.14}
          />
        </g>
        <g id='LINE_00000103232491512434172000000000205399814580428947_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={793.92}
            y1={671.92}
            x2={793.92}
            y2={706.39}
          />
        </g>
        <g id='LINE_00000137812302214696819240000017972567059349224104_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={706.39}
            x2={792.94}
            y2={706.39}
          />
        </g>
        <g id='LINE_00000124867399831626181750000002647277092365844907_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={708.34}
            x2={792.94}
            y2={706.39}
          />
        </g>
        <g id='LINE_00000003793408832808525800000008968569363874536636_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={708.34}
            x2={792.94}
            y2={708.34}
          />
        </g>
        <g id='LINE_00000027589473911237943340000001154019274279921559_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={708.34}
            x2={794.89}
            y2={706.39}
          />
        </g>
        <g id='LINE_00000136386460240713816900000006906087795664442537_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={671.92}
            x2={792.94}
            y2={671.92}
          />
        </g>
        <g id='LINE_00000053536342930810481890000016694941869372612481_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={669.97}
            x2={792.94}
            y2={671.92}
          />
        </g>
        <g id='LINE_00000118386224323341524150000011829056865551568825_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={669.97}
            x2={792.94}
            y2={669.97}
          />
        </g>
        <g id='LINE_00000058591387465077827880000015641977495376572571_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={669.97}
            x2={794.89}
            y2={671.92}
          />
        </g>
        <g id='LINE_00000114069632862499221080000000023627063253105576_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={708.34}
            x2={795.72}
            y2={708.34}
          />
        </g>
        <g id='LINE_00000012435721582105226750000012623921136166708363_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={707.78}
            x2={792.94}
            y2={708.34}
          />
        </g>
        <g id='LINE_00000002374889640756413190000015239232878494307761_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={707.78}
            x2={789.05}
            y2={707.78}
          />
        </g>
        <g id='LINE_00000152237594841491192590000008981839493192316075_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={795.72}
            y1={709.17}
            x2={795.72}
            y2={708.34}
          />
        </g>
        <g id='LINE_00000057136810998237549750000010365881662761908612_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={789.05}
            y1={670.53}
            x2={792.94}
            y2={670.53}
          />
        </g>
        <g id='LINE_00000106146640091290469960000013579495250982207385_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={669.97}
            x2={792.94}
            y2={670.53}
          />
        </g>
        <g id='LINE_00000029738174976307961480000008676298682794686640_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={669.97}
            x2={795.72}
            y2={669.97}
          />
        </g>
        <g id='LINE_00000129888755117986117880000008275660855569419689_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={795.72}
            y1={669.14}
            x2={795.72}
            y2={669.97}
          />
        </g>
        <g id='LINE_00000103234977590066049870000007366129138975995302_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={789.05}
            y1={655.7}
            x2={789.05}
            y2={657.09}
          />
        </g>
        <g id='LINE_00000068666116883510838990000015274142296039299212_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={789.05}
            y1={578.41}
            x2={789.05}
            y2={577.02}
          />
        </g>
        <g id='LINE_00000122711899975796023210000008388480019261954706_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={793.92}
            y1={579.8}
            x2={793.92}
            y2={654.31}
          />
        </g>
        <g id='LINE_00000181787999242797916060000001576585645856507582_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={654.31}
            x2={792.94}
            y2={654.31}
          />
        </g>
        <g id='LINE_00000092450928286472132240000016020499650357047965_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={656.25}
            x2={792.94}
            y2={654.31}
          />
        </g>
        <g id='LINE_00000168076350671488938740000007178011357341070257_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={656.25}
            x2={792.94}
            y2={656.25}
          />
        </g>
        <g id='LINE_00000040544935788661350730000003687891972629841298_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={656.25}
            x2={794.89}
            y2={654.31}
          />
        </g>
        <g id='LINE_00000043426598519521224320000007966960425322516144_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={579.8}
            x2={792.94}
            y2={579.8}
          />
        </g>
        <g id='LINE_00000136377621798927167260000008927188088352421267_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={577.85}
            x2={792.94}
            y2={579.8}
          />
        </g>
        <g id='LINE_00000008836187126526736800000016313873696780226196_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={577.85}
            x2={792.94}
            y2={577.85}
          />
        </g>
        <g id='LINE_00000002382511159677352510000006012106577279006389_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={794.89}
            y1={577.85}
            x2={794.89}
            y2={579.8}
          />
        </g>
        <g id='LINE_00000131333105980268957160000004391861703687726252_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={656.25}
            x2={795.72}
            y2={656.25}
          />
        </g>
        <g id='LINE_00000069389739680394723650000016078919928048370867_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={655.7}
            x2={792.94}
            y2={656.25}
          />
        </g>
        <g id='LINE_00000096057822153301735670000000398367316867694770_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={655.7}
            x2={789.05}
            y2={655.7}
          />
        </g>
        <g id='LINE_00000170262412629104563140000015832258478365566862_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={795.72}
            y1={657.09}
            x2={795.72}
            y2={656.25}
          />
        </g>
        <g id='LINE_00000150793095848840537680000001901920520604930232_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={789.05}
            y1={578.41}
            x2={792.94}
            y2={578.41}
          />
        </g>
        <g id='LINE_00000103955837430507332790000012065784958001119407_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={577.85}
            x2={792.94}
            y2={578.41}
          />
        </g>
        <g id='LINE_00000049908211571249820420000014451826073420331179_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={792.94}
            y1={577.85}
            x2={795.72}
            y2={577.85}
          />
        </g>
        <g id='LINE_00000149342805933190709730000003882927703020897939_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={795.72}
            y1={577.02}
            x2={795.72}
            y2={577.85}
          />
        </g>
        <g id='LINE_00000160148166774168491700000013325939824847127218_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={573.85}
            y1={579.74}
            x2={573.85}
            y2={578.35}
          />
        </g>
        <g id='LINE_00000168836677416386248530000002096056334630877875_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={573.85}
            y1={679.27}
            x2={573.85}
            y2={680.66}
          />
        </g>
        <g id='LINE_00000103965531405796977300000009476479255071642010_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={566.76}
            y1={677.88}
            x2={566.76}
            y2={581.13}
          />
        </g>
        <g id='LINE_00000095307030659456130480000000534095422367630512_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={565.78}
            y1={581.13}
            x2={567.73}
            y2={581.13}
          />
        </g>
        <g id='LINE_00000166675403010221334290000013919301615392291237_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={567.73}
            y1={579.18}
            x2={567.73}
            y2={581.13}
          />
        </g>
        <g id='LINE_00000035520351901892019980000017755426019014126508_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={565.78}
            y1={579.18}
            x2={567.73}
            y2={579.18}
          />
        </g>
        <g id='LINE_00000119828932219579256510000017458529448710338436_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={565.78}
            y1={579.18}
            x2={565.78}
            y2={581.13}
          />
        </g>
        <g id='LINE_00000129189983338050032900000007917626321327280534_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={565.78}
            y1={677.88}
            x2={567.73}
            y2={677.88}
          />
        </g>
        <g id='LINE_00000072970607425990007730000007264194138936906682_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={567.73}
            y1={679.83}
            x2={567.73}
            y2={677.88}
          />
        </g>
        <g id='LINE_00000169524776539571111630000008940788676203003297_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={565.78}
            y1={679.83}
            x2={567.73}
            y2={679.83}
          />
        </g>
        <g id='LINE_00000057855559266821806100000014182351854439255948_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={565.78}
            y1={679.83}
            x2={565.78}
            y2={677.88}
          />
        </g>
        <g id='LINE_00000145748414371049225050000014378307929600136091_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={567.73}
            y1={579.18}
            x2={564.95}
            y2={579.18}
          />
        </g>
        <g id='LINE_00000063611936621866516420000014021991685281718968_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={567.73}
            y1={579.74}
            x2={567.73}
            y2={579.18}
          />
        </g>
        <g id='LINE_00000052818430692417166690000000644729503820775580_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={567.73}
            y1={579.74}
            x2={573.85}
            y2={579.74}
          />
        </g>
        <g id='LINE_00000156559243162736333510000013289678038359465901_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={564.95}
            y1={578.35}
            x2={564.95}
            y2={579.18}
          />
        </g>
        <g id='LINE_00000171705551732264964380000014997660801156694405_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={573.85}
            y1={679.27}
            x2={567.73}
            y2={679.27}
          />
        </g>
        <g id='LINE_00000147913059320346345110000012481555308776803741_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={567.73}
            y1={679.83}
            x2={567.73}
            y2={679.27}
          />
        </g>
        <g id='LINE_00000155112110875109271070000018234644979611429028_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={567.73}
            y1={679.83}
            x2={564.95}
            y2={679.83}
          />
        </g>
        <g id='LINE_00000168115497945986739960000003880299819934428831_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={564.95}
            y1={680.66}
            x2={564.95}
            y2={679.83}
          />
        </g>
        <g id='LINE_00000056426962924433597130000012734840868201626541_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1645.24}
            y1={780.73}
            x2={1643.85}
            y2={780.73}
          />
        </g>
        <g id='LINE_00000065768012659216099420000003463945934337828250_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1686.94}
            y1={780.73}
            x2={1688.33}
            y2={780.73}
          />
        </g>
        <g id='LINE_00000146466131100608837730000001813031424410116536_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1685.55}
            y1={792.26}
            x2={1646.63}
            y2={792.26}
          />
        </g>
        <g id='LINE_00000140719995473666580580000015178224849945754802_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1646.63}
            y1={793.24}
            x2={1646.63}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000103254215042228962680000001126245641582291354_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1644.68}
            y1={791.29}
            x2={1646.63}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000173877806536619620980000005233960568337246870_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1644.68}
            y1={793.24}
            x2={1644.68}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000045611246509144937320000002391268516776619922_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1644.68}
            y1={793.24}
            x2={1646.63}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000183234308400041820160000014202637829411330473_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1685.55}
            y1={793.24}
            x2={1685.55}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000072976468933082986400000012165479541030553521_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={791.29}
            x2={1685.55}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000029746304671884809220000013795968796476781741_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={793.24}
            x2={1687.5}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000150083411661884078600000003714978328222779292_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={793.24}
            x2={1685.55}
            y2={793.24}
          />
        </g>
        <g id='LINE_00000137096374665043839280000018330390833436146867_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1644.68}
            y1={791.29}
            x2={1644.68}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000070816885308723859550000007933581414086026423_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1645.24}
            y1={791.29}
            x2={1644.68}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000125567766780086398280000012876310497028860563_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1645.24}
            y1={791.29}
            x2={1645.24}
            y2={780.73}
          />
        </g>
        <g id='LINE_00000178201491537636174620000017917338673532824753_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1643.85}
            y1={794.07}
            x2={1644.68}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000137105792866291558910000005668043769497720242_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1686.94}
            y1={780.73}
            x2={1686.94}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000169536981596058422440000007967706345028984239_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={791.29}
            x2={1686.94}
            y2={791.29}
          />
        </g>
        <g id='LINE_00000172398829208346294320000015732367121900346289_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1687.5}
            y1={791.29}
            x2={1687.5}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000053525630189879273670000009272224253303129246_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1688.33}
            y1={794.07}
            x2={1687.5}
            y2={794.07}
          />
        </g>
        <g id='LINE_00000034790305051852688360000015470228497436997002_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={925.47}
            y1={747.31}
            x2={926.86}
            y2={747.31}
          />
        </g>
        <g id='LINE_00000095327330648101732840000011349984651089168830_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={865.97}
            y1={747.31}
            x2={864.58}
            y2={747.31}
          />
        </g>
        <g id='LINE_00000093859282608280321020000012134758017361134003_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={867.36}
            y1={744.67}
            x2={924.08}
            y2={744.67}
          />
        </g>
        <g id='LINE_00000060712510367848949100000017913405296769558669_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={924.08}
            y1={743.7}
            x2={924.08}
            y2={745.64}
          />
        </g>
        <g id='LINE_00000037671568710495390370000000404925075317192847_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={926.02}
            y1={745.64}
            x2={924.08}
            y2={745.64}
          />
        </g>
        <g id='LINE_00000017517055295110021210000012174541736682185908_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={926.02}
            y1={743.7}
            x2={926.02}
            y2={745.64}
          />
        </g>
        <g id='LINE_00000133523024116165766480000000718554785323794570_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={926.02}
            y1={743.7}
            x2={924.08}
            y2={743.7}
          />
        </g>
        <g id='LINE_00000023248195015093207840000003081391862952183207_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={867.36}
            y1={743.7}
            x2={867.36}
            y2={745.64}
          />
        </g>
        <g id='LINE_00000006709857497506135940000004966750338536636053_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={865.42}
            y1={745.64}
            x2={867.36}
            y2={745.64}
          />
        </g>
        <g id='LINE_00000068663123125408159470000001321020677704390025_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={865.42}
            y1={743.7}
            x2={865.42}
            y2={745.64}
          />
        </g>
        <g id='LINE_00000021080950803842639100000008657333748815448254_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={865.42}
            y1={743.7}
            x2={867.36}
            y2={743.7}
          />
        </g>
        <g id='LINE_00000024694742680997725010000011147686110963710090_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={926.02}
            y1={745.64}
            x2={926.02}
            y2={742.86}
          />
        </g>
        <g id='LINE_00000141454634872876694180000010586237050997148806_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={925.47}
            y1={745.64}
            x2={926.02}
            y2={745.64}
          />
        </g>
        <g id='LINE_00000146473489579835861820000003370856557612140471_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={925.47}
            y1={745.64}
            x2={925.47}
            y2={747.31}
          />
        </g>
        <g id='LINE_00000147904667119739951770000017152850700420053168_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={926.86}
            y1={742.86}
            x2={926.02}
            y2={742.86}
          />
        </g>
        <g id='LINE_00000150100169975729690810000011038662002557146496_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={865.97}
            y1={747.31}
            x2={865.97}
            y2={745.64}
          />
        </g>
        <g id='LINE_00000127739057053830308810000002077187114785028995_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={865.42}
            y1={745.64}
            x2={865.97}
            y2={745.64}
          />
        </g>
        <g id='LINE_00000026865654376850351300000007886979944441454753_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={865.42}
            y1={745.64}
            x2={865.42}
            y2={742.86}
          />
        </g>
        <g id='LINE_00000167390179488338108420000013986229443483321745_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={864.58}
            y1={742.86}
            x2={865.42}
            y2={742.86}
          />
        </g>
        <g id='LINE_00000086671253591902852280000009094016227979410345_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={834.37}
            y1={719.74}
            x2={835.76}
            y2={719.74}
          />
        </g>
        <g id='LINE_00000176029709024282109360000010650169288071824020_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={797.11}
            y1={719.74}
            x2={795.72}
            y2={719.74}
          />
        </g>
        <g id='LINE_00000124158447852881385290000016230918620061059255_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={798.5}
            y1={714.87}
            x2={832.98}
            y2={714.87}
          />
        </g>
        <g id='LINE_00000083801118527125198450000010677358996055300004_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={832.98}
            y1={713.9}
            x2={832.98}
            y2={715.84}
          />
        </g>
        <g id='LINE_00000130632368484030312410000001562620138992043688_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={834.92}
            y1={715.84}
            x2={832.98}
            y2={715.84}
          />
        </g>
        <g id='LINE_00000108281685125822440400000006259098631672474281_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={834.92}
            y1={713.9}
            x2={834.92}
            y2={715.84}
          />
        </g>
        <g id='LINE_00000024680338568275606190000006311490383249796281_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={834.92}
            y1={713.9}
            x2={832.98}
            y2={713.9}
          />
        </g>
        <g id='LINE_00000067917843903122209290000008935088852661494450_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={798.5}
            y1={713.9}
            x2={798.5}
            y2={715.84}
          />
        </g>
        <g id='LINE_00000031908277556493653310000000467902765708182912_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={796.56}
            y1={715.84}
            x2={798.5}
            y2={715.84}
          />
        </g>
        <g id='LINE_00000146487004169325666290000000334223176307167644_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={796.56}
            y1={713.9}
            x2={796.56}
            y2={715.84}
          />
        </g>
        <g id='LINE_00000154384888829054633150000006539492008174482330_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={796.56}
            y1={713.9}
            x2={798.5}
            y2={713.9}
          />
        </g>
        <g id='LINE_00000091700145805489609150000008535037153952680621_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={834.92}
            y1={715.84}
            x2={834.92}
            y2={713.06}
          />
        </g>
        <g id='LINE_00000075132188895051785460000003177308017497140383_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={834.37}
            y1={715.84}
            x2={834.92}
            y2={715.84}
          />
        </g>
        <g id='LINE_00000042730780514740842320000003782485412481206181_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={834.37}
            y1={715.84}
            x2={834.37}
            y2={719.74}
          />
        </g>
        <g id='LINE_00000011749906527653407390000015257501253671702175_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={835.76}
            y1={713.06}
            x2={834.92}
            y2={713.06}
          />
        </g>
        <g id='LINE_00000055694943986562312460000001702367803388093355_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={797.11}
            y1={719.74}
            x2={797.11}
            y2={715.84}
          />
        </g>
        <g id='LINE_00000122700829574410282260000016273410831378123159_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={796.56}
            y1={715.84}
            x2={797.11}
            y2={715.84}
          />
        </g>
        <g id='LINE_00000027571155862341387110000013607405728965551292_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={796.56}
            y1={715.84}
            x2={796.56}
            y2={713.06}
          />
        </g>
        <g id='LINE_00000183952134557337565260000010178341127425562033_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={795.72}
            y1={713.06}
            x2={796.56}
            y2={713.06}
          />
        </g>
        <g id='LINE_00000023239794772818721320000011579887924949847425_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1312.77}
            y1={24.9}
            x2={1312.77}
            y2={23.51}
          />
        </g>
        <g id='LINE_00000173141015324264678950000001055861325789895351_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1312.77}
            y1={66.6}
            x2={1312.77}
            y2={67.99}
          />
        </g>
        <g id='LINE_00000042700934837560058860000009653138683760241299_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1324.31}
            y1={65.21}
            x2={1324.31}
            y2={26.29}
          />
        </g>
        <g id='LINE_00000082327220928548620760000014081336957202280080_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1325.28}
            y1={26.29}
            x2={1323.33}
            y2={26.29}
          />
        </g>
        <g id='LINE_00000076583683298358332350000010506133448525398973_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1323.33}
            y1={24.34}
            x2={1323.33}
            y2={26.29}
          />
        </g>
        <g id='LINE_00000122693895788790995640000010141095896714176910_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1325.28}
            y1={24.34}
            x2={1323.33}
            y2={24.34}
          />
        </g>
        <g id='LINE_00000085243673515010491370000005547619857250578080_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1325.28}
            y1={24.34}
            x2={1325.28}
            y2={26.29}
          />
        </g>
        <g id='LINE_00000022560628212803383000000017237802135236874382_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1325.28}
            y1={65.21}
            x2={1323.33}
            y2={65.21}
          />
        </g>
        <g id='LINE_00000057119775988795240140000005140344986174928276_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1323.33}
            y1={67.15}
            x2={1323.33}
            y2={65.21}
          />
        </g>
        <g id='LINE_00000054968090989699952500000005683514306446326700_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1325.28}
            y1={67.15}
            x2={1323.33}
            y2={67.15}
          />
        </g>
        <g id='LINE_00000158728239686270640890000017793967745574499741_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1325.28}
            y1={67.15}
            x2={1325.28}
            y2={65.21}
          />
        </g>
        <g id='LINE_00000029017175048465485190000012861483897279504272_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1323.33}
            y1={24.34}
            x2={1326.11}
            y2={24.34}
          />
        </g>
        <g id='LINE_00000159454460567416504160000008279382402479799723_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1323.33}
            y1={24.9}
            x2={1323.33}
            y2={24.34}
          />
        </g>
        <g id='LINE_00000007388745170632767350000003478610175573413563_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1323.33}
            y1={24.9}
            x2={1312.77}
            y2={24.9}
          />
        </g>
        <g id='LINE_00000155132934867791741010000017157645013255059104_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1326.11}
            y1={23.51}
            x2={1326.11}
            y2={24.34}
          />
        </g>
        <g id='LINE_00000032639701562172056180000014189567592606716847_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1312.77}
            y1={66.6}
            x2={1323.33}
            y2={66.6}
          />
        </g>
        <g id='LINE_00000132775992970576624160000008456992919439599497_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1323.33}
            y1={67.15}
            x2={1323.33}
            y2={66.6}
          />
        </g>
        <g id='LINE_00000044864723450606157460000010672043205078161308_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1323.33}
            y1={67.15}
            x2={1326.11}
            y2={67.15}
          />
        </g>
        <g id='LINE_00000025427351254452299950000009600389914208181406_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1326.11}
            y1={67.99}
            x2={1326.11}
            y2={67.15}
          />
        </g>
        <g id='LINE_00000072261946271313072270000012561571573252051335_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1383.49}
            y1={391.72}
            x2={1382.1}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000121990283704788868640000003598044015998740150_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1399.61}
            y1={391.72}
            x2={1401}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000050634338487447734140000008281167255719592622_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1398.22}
            y1={406.59}
            x2={1384.88}
            y2={406.59}
          />
        </g>
        <g id='LINE_00000037660912047788173550000016684211915307940780_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1384.88}
            y1={407.57}
            x2={1384.88}
            y2={405.62}
          />
        </g>
        <g id='LINE_00000083069882989886877500000014491192297821942686_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1382.93}
            y1={405.62}
            x2={1384.88}
            y2={405.62}
          />
        </g>
        <g id='LINE_00000117661593395375111150000004347182088015243936_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1382.93}
            y1={407.57}
            x2={1382.93}
            y2={405.62}
          />
        </g>
        <g id='LINE_00000183934014433955712930000017612319572511112637_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1382.93}
            y1={407.57}
            x2={1384.88}
            y2={407.57}
          />
        </g>
        <g id='LINE_00000055673207746102020300000009284577998103432576_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1398.22}
            y1={407.57}
            x2={1398.22}
            y2={405.62}
          />
        </g>
        <g id='LINE_00000132056047012101710900000001041188610767244222_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1400.17}
            y1={405.62}
            x2={1398.22}
            y2={405.62}
          />
        </g>
        <g id='LINE_00000053514964836102267670000003275386653116816012_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1400.17}
            y1={407.57}
            x2={1400.17}
            y2={405.62}
          />
        </g>
        <g id='LINE_00000124147847010904462510000015434977375904632708_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1400.17}
            y1={407.57}
            x2={1398.22}
            y2={407.57}
          />
        </g>
        <g id='LINE_00000112598206469579872870000009388540444076680624_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1382.93}
            y1={405.62}
            x2={1382.93}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000109715742386420444800000010208573739405764758_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1383.49}
            y1={405.62}
            x2={1382.93}
            y2={405.62}
          />
        </g>
        <g id='LINE_00000085964773298059552580000007381319697739440010_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1383.49}
            y1={405.62}
            x2={1383.49}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000052820165120965076400000009480689650888176525_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1382.1}
            y1={408.4}
            x2={1382.93}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000034053967760388159380000009856905712871824060_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1399.61}
            y1={391.72}
            x2={1399.61}
            y2={405.62}
          />
        </g>
        <g id='LINE_00000096759005753352896450000003424764091229345187_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1400.17}
            y1={405.62}
            x2={1399.61}
            y2={405.62}
          />
        </g>
        <g id='LINE_00000177452900523603260560000006611179013200436652_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1400.17}
            y1={405.62}
            x2={1400.17}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000155830332058228580530000000606016795867533223_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1401}
            y1={408.4}
            x2={1400.17}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000075149888950651168320000015585575586400877478_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1356.8}
            y1={408.4}
            x2={1355.41}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000169547563189559158580000009215215344885009566_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1372.92}
            y1={408.4}
            x2={1374.31}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000084504324100468980780000013487295558293921669_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1371.53}
            y1={393.53}
            x2={1358.19}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000158029529636664904810000011132731963388278663_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1358.19}
            y1={392.55}
            x2={1358.19}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000094611762981400799850000002192939747983520441_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1356.24}
            y1={394.5}
            x2={1358.19}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000127750222635738269740000003926190055466694278_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1356.24}
            y1={392.55}
            x2={1356.24}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000182494590041902785970000017556320252739279010_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1356.24}
            y1={392.55}
            x2={1358.19}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000150100716163269503160000015425783875782178996_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1371.53}
            y1={392.55}
            x2={1371.53}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000156554678783413164420000002424418993388768418_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1373.48}
            y1={394.5}
            x2={1371.53}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000072995339603653912250000002001668471073948319_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1373.48}
            y1={392.55}
            x2={1373.48}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000014635161623184574310000008481104049116131718_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1373.48}
            y1={392.55}
            x2={1371.53}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000052102571477199836530000007143669488437088899_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1356.24}
            y1={394.5}
            x2={1356.24}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000060745687304202789110000008850821569676888467_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1356.8}
            y1={394.5}
            x2={1356.24}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000017496189320096630400000006763972125248296844_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1356.8}
            y1={394.5}
            x2={1356.8}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000111161830379548860740000004943340419549123768_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1355.41}
            y1={391.72}
            x2={1356.24}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000024691574104066620200000012842876155696471690_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1372.92}
            y1={408.4}
            x2={1372.92}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000018931928861593641840000001486247890634811273_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1373.48}
            y1={394.5}
            x2={1372.92}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000035523610486906959320000016326322517488862910_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1373.48}
            y1={394.5}
            x2={1373.48}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000183965359724860565450000012732274581103556527_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1374.31}
            y1={391.72}
            x2={1373.48}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000109010710979249416740000001250517692111267252_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.83}
            y1={408.4}
            x2={1327.44}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000090282256128831192580000017395227227862005922_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1344.96}
            y1={408.4}
            x2={1346.35}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000030446547489004215970000000917410201731420564_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1343.57}
            y1={393.53}
            x2={1330.22}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000034780772687410544780000000883077852168079550_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1330.22}
            y1={392.55}
            x2={1330.22}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000114050899049172320180000002083290485347739823_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.27}
            y1={394.5}
            x2={1330.22}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000116222140796722187520000002810189662517530538_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.27}
            y1={392.55}
            x2={1328.27}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000101069320581866734550000015378740682500569235_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.27}
            y1={392.55}
            x2={1330.22}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000147215626048736885830000016866503414009603971_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1343.57}
            y1={392.55}
            x2={1343.57}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000025414133965793659000000006510746422037230481_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1345.51}
            y1={394.5}
            x2={1343.57}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000106836453965187296810000018048987678908904631_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1345.51}
            y1={392.55}
            x2={1345.51}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000075144325566617393330000006614564748737520515_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1345.51}
            y1={392.55}
            x2={1343.57}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000097500092843407078980000016967101360087760521_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.27}
            y1={394.5}
            x2={1328.27}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000056419142268078221400000016579898011794288274_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.83}
            y1={394.5}
            x2={1328.27}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000070114963544195096860000012074785434821975479_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1328.83}
            y1={394.5}
            x2={1328.83}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000090984672386738504460000004875312709942786457_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1327.44}
            y1={391.72}
            x2={1328.27}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000139283702133984328590000002076446510021524900_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1344.96}
            y1={408.4}
            x2={1344.96}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000053545021252450253710000010715960225813004959_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1345.51}
            y1={394.5}
            x2={1344.96}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000176006262425609843140000012740229002143498929_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1345.51}
            y1={394.5}
            x2={1345.51}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000094614378748874492700000017783178038872243116_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1346.35}
            y1={391.72}
            x2={1345.51}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000133530548624319024360000015855688377617806751_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1302.06}
            y1={408.4}
            x2={1300.67}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000157284800906748157570000014097128168152824985_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1318.18}
            y1={408.4}
            x2={1319.57}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000046341076341859225830000006251195968660817064_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1316.79}
            y1={393.53}
            x2={1303.45}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000086657794308452365260000013550356886919668397_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1303.45}
            y1={392.55}
            x2={1303.45}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000009553370364394432510000007271877807389703853_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.5}
            y1={394.5}
            x2={1303.45}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000151546119413558490080000006491010475989101477_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.5}
            y1={392.55}
            x2={1301.5}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000082339314992547077700000002383535704662367671_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.5}
            y1={392.55}
            x2={1303.45}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000075165008608736027050000004010552431129877658_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1316.79}
            y1={392.55}
            x2={1316.79}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000043437205427032024370000013392818980756394399_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1318.74}
            y1={394.5}
            x2={1316.79}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000107587819695016676320000011850498986098218113_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1318.74}
            y1={392.55}
            x2={1318.74}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000064330680446929117790000007921789630644160941_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1318.74}
            y1={392.55}
            x2={1316.79}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000116213601587509156150000012857137494464681349_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.5}
            y1={394.5}
            x2={1301.5}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000181792643600334686460000002071477099852080573_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1302.06}
            y1={394.5}
            x2={1301.5}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000035520852744834838190000007411463031251812483_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1302.06}
            y1={394.5}
            x2={1302.06}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000109713605590556066070000007838520518961350019_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1300.67}
            y1={391.72}
            x2={1301.5}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000054228754458510065600000008682945947966214784_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1318.18}
            y1={408.4}
            x2={1318.18}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000136378133515498124630000003574468143204860317_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1318.74}
            y1={394.5}
            x2={1318.18}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000045620739839292696550000004709911611267622046_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1318.74}
            y1={394.5}
            x2={1318.74}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000011003568327528514130000014161202281619543202_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1319.57}
            y1={391.72}
            x2={1318.74}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000119092921612680550230000004863148782998909091_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1275.37}
            y1={408.4}
            x2={1273.98}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000098929552473931272520000003266399899530159764_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1291.49}
            y1={408.4}
            x2={1292.88}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000165929828744470399480000003989982407313290392_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.1}
            y1={393.53}
            x2={1276.76}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000179628329283463303920000000190930002738882714_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1276.76}
            y1={392.55}
            x2={1276.76}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000076583665800099207120000004324956270496646558_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1274.81}
            y1={394.5}
            x2={1276.76}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000005956596972244572700000007789279889454425232_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1274.81}
            y1={392.55}
            x2={1274.81}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000142140910688601434630000009217176148586344855_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1274.81}
            y1={392.55}
            x2={1276.76}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000080920066725268440970000001734387033965818510_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.1}
            y1={392.55}
            x2={1290.1}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000015354791382664778590000003712258881309652158_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.05}
            y1={394.5}
            x2={1290.1}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000077292558473410643880000008842477570743004842_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.05}
            y1={392.55}
            x2={1292.05}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000024000738121667997040000010369348782751440768_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.05}
            y1={392.55}
            x2={1290.1}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000045612996622677639840000008010381821162833339_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1274.81}
            y1={394.5}
            x2={1274.81}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000066477605246756369820000017993209785416970660_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1275.37}
            y1={394.5}
            x2={1274.81}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000032610833029081696400000016050566769446749372_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1275.37}
            y1={394.5}
            x2={1275.37}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000000210411926206206980000000992662446286994577_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1273.98}
            y1={391.72}
            x2={1274.81}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000178926261667815637600000013916205989169733555_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1291.49}
            y1={408.4}
            x2={1291.49}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000026873026025658874860000012259938898619555508_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.05}
            y1={394.5}
            x2={1291.49}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000098935459507663689230000010961378900046499740_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.05}
            y1={394.5}
            x2={1292.05}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000139295271470000793830000005556272145252357556_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.88}
            y1={391.72}
            x2={1292.05}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000048472250666139717530000000232335396763552945_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.78}
            y1={408.4}
            x2={1238.39}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000114049296744750828790000012344106565594992791_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1255.9}
            y1={408.4}
            x2={1257.29}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000147923097236458466150000001718436084206857397_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1254.51}
            y1={393.53}
            x2={1241.17}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000109015994021702170230000010381318102868571020_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1241.17}
            y1={392.55}
            x2={1241.17}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000091736390107507725090000015486406838215963032_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.22}
            y1={394.5}
            x2={1241.17}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000176039230790121316550000006841412300021489796_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.22}
            y1={392.55}
            x2={1239.22}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000170989855359636799380000007443934853971851447_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.22}
            y1={392.55}
            x2={1241.17}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000178165252828613237120000001017964214411855533_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1254.51}
            y1={392.55}
            x2={1254.51}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000000937969841174314280000017589735219555464833_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1256.46}
            y1={394.5}
            x2={1254.51}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000053540978602822889300000000332821761087820432_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1256.46}
            y1={392.55}
            x2={1256.46}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000179610542722608393710000008197586036129066144_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1256.46}
            y1={392.55}
            x2={1254.51}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000111909134022643021840000005258427730637944741_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.22}
            y1={394.5}
            x2={1239.22}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000127035663714516609550000001662007148469050548_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.78}
            y1={394.5}
            x2={1239.22}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000038373435399778620200000012508483312584497826_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.78}
            y1={394.5}
            x2={1239.78}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000175312419344818154910000010996076494646754218_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1238.39}
            y1={391.72}
            x2={1239.22}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000124158010898923584030000002829781774712949637_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1255.9}
            y1={408.4}
            x2={1255.9}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000143596792994587775920000018124592171836239525_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1256.46}
            y1={394.5}
            x2={1255.9}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000049212386551890911130000012334019732866858892_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1256.46}
            y1={394.5}
            x2={1256.46}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000044173134029081632200000003764943414890901433_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1257.29}
            y1={391.72}
            x2={1256.46}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000016036699453201913280000008448798213021748395_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1213.09}
            y1={408.4}
            x2={1211.7}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000179646541191824511630000001728097625836285843_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1229.22}
            y1={408.4}
            x2={1230.61}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000129922451354033425410000006590978169307213999_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1227.82}
            y1={393.53}
            x2={1214.48}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000033332078585689067440000001030109395732372142_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1214.48}
            y1={392.55}
            x2={1214.48}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000010299537620346359050000017186566072852457120_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1212.53}
            y1={394.5}
            x2={1214.48}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000011023963487567268880000008921583739964051100_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1212.53}
            y1={392.55}
            x2={1212.53}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000023993523080038432710000018190213486202360766_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1212.53}
            y1={392.55}
            x2={1214.48}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000128484828464151258810000001677627989293393851_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1227.82}
            y1={392.55}
            x2={1227.82}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000059996488029799881270000009458485727474488208_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1229.77}
            y1={394.5}
            x2={1227.82}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000084524087956866503220000002229689682931993753_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1229.77}
            y1={392.55}
            x2={1229.77}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000178164653165913501660000009370784496348247964_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1229.77}
            y1={392.55}
            x2={1227.82}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000042003206952556941330000000373452967230302596_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1212.53}
            y1={394.5}
            x2={1212.53}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000054970389244563210640000016436188012189131958_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1213.09}
            y1={394.5}
            x2={1212.53}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000078732828544062882150000007507268719162826687_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1213.09}
            y1={394.5}
            x2={1213.09}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000016065274566812287980000001307370692438816671_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1211.7}
            y1={391.72}
            x2={1212.53}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000158014425817151885060000009480462479900072101_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1229.22}
            y1={408.4}
            x2={1229.22}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000080890980298484348100000010945478920951265692_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1229.77}
            y1={394.5}
            x2={1229.22}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000070801415034855794250000016417546157166233753_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1229.77}
            y1={394.5}
            x2={1229.77}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000100354064264518275810000011946283476456091031_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1230.61}
            y1={391.72}
            x2={1229.77}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000074410991995682552370000009219412289980258482_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1185.29}
            y1={408.4}
            x2={1183.9}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000127038983318646131720000014678225831613876134_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1201.41}
            y1={408.4}
            x2={1202.8}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000135651999275919174370000004469948318473903794_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1200.02}
            y1={393.53}
            x2={1186.68}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000098201042375497576560000016182357167682498191_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1186.68}
            y1={392.55}
            x2={1186.68}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000000930405253994144010000016622394525139474048_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1184.73}
            y1={394.5}
            x2={1186.68}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000155137065685153774460000006404778277206662559_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1184.73}
            y1={392.55}
            x2={1184.73}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000148633668302357842370000007297138288054146737_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1184.73}
            y1={392.55}
            x2={1186.68}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000055699872172306740370000005106456622399799208_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1200.02}
            y1={392.55}
            x2={1200.02}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000137831097625433783100000001564226592469226149_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1201.97}
            y1={394.5}
            x2={1200.02}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000112592642869072584000000012803382693353954720_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1201.97}
            y1={392.55}
            x2={1201.97}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000129192760478689208710000018160945666752618937_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1201.97}
            y1={392.55}
            x2={1200.02}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000109708881082963190220000013942184886999364769_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1184.73}
            y1={394.5}
            x2={1184.73}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000135666131224860823420000004261034814300242098_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1185.29}
            y1={394.5}
            x2={1184.73}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000057831643195482446320000017268908621344740282_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1185.29}
            y1={394.5}
            x2={1185.29}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000049215546127188941890000009604904863462705062_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1183.9}
            y1={391.72}
            x2={1184.73}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000123409128442282249260000001482904142296195773_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1201.41}
            y1={408.4}
            x2={1201.41}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000124128385207854205960000015753655751240832651_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1201.97}
            y1={394.5}
            x2={1201.41}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000076562861720945321550000004004116814006599615_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1201.97}
            y1={394.5}
            x2={1201.97}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000012437124938280642410000017238596478502337157_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1202.8}
            y1={391.72}
            x2={1201.97}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000140730646188386719060000016349171556714796964_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1159}
            y1={408.4}
            x2={1157.61}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000022545278157337964060000005890437977728327864_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1175.13}
            y1={408.4}
            x2={1176.52}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000176018455126524863150000016908495173746589597_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1173.74}
            y1={393.53}
            x2={1160.39}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000065039938214514403860000001245124336792328607_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1160.39}
            y1={392.55}
            x2={1160.39}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000098900217199590832360000006698191637834923940_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1158.45}
            y1={394.5}
            x2={1160.39}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000083059807413541843940000001173421285134755512_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1158.45}
            y1={392.55}
            x2={1158.45}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000034056734343763601530000014116932548567264694_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1158.45}
            y1={392.55}
            x2={1160.39}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000144311905245955323880000013303563536950882711_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1173.74}
            y1={392.55}
            x2={1173.74}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000152981264563351705550000003565612345206383548_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1175.69}
            y1={394.5}
            x2={1173.74}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000130645461772497552320000015459446008791239604_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1175.69}
            y1={392.55}
            x2={1175.69}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000062158009147661395090000000392431989274475934_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1175.69}
            y1={392.55}
            x2={1173.74}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000013155713828686947240000008361401764064434604_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1158.45}
            y1={394.5}
            x2={1158.45}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000102516988754964149360000009914713536350998191_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1159}
            y1={394.5}
            x2={1158.45}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000026126599367099186330000012350648335959222712_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1159}
            y1={394.5}
            x2={1159}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000081619463655547886320000000167051995611960484_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1157.61}
            y1={391.72}
            x2={1158.45}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000039824814703223250810000014548907351474100104_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1175.13}
            y1={408.4}
            x2={1175.13}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000013190151380337148590000004309623783499419040_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1175.69}
            y1={394.5}
            x2={1175.13}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000059302387183437113670000011810445266357203334_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1175.69}
            y1={394.5}
            x2={1175.69}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000063632591244097396990000015214682612861252777_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1176.52}
            y1={391.72}
            x2={1175.69}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000095326761488415585640000011063372169580964778_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1132.31}
            y1={408.4}
            x2={1130.92}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000138530913669535785430000015357339423026865574_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1148.44}
            y1={408.4}
            x2={1149.83}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000083090043250194114640000016367711707583485609_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1147.05}
            y1={393.53}
            x2={1133.7}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000145022974769201117130000015681299472582476451_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1133.7}
            y1={392.55}
            x2={1133.7}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000083059467284403287650000007604590114279943298_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1131.76}
            y1={394.5}
            x2={1133.7}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000052824536273652811110000016195136344332633004_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1131.76}
            y1={392.55}
            x2={1131.76}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000004526266173075932530000013763337545473169587_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1131.76}
            y1={392.55}
            x2={1133.7}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000041295010370806894340000008247947692652074910_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1147.05}
            y1={392.55}
            x2={1147.05}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000111882182980239462460000005214449443398074041_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1149}
            y1={394.5}
            x2={1147.05}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000087395166440966234800000017037256683730157981_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1149}
            y1={392.55}
            x2={1149}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000109015262404067938140000017857093678694966410_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1149}
            y1={392.55}
            x2={1147.05}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000047061009109984759970000015795078278031219332_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1131.76}
            y1={394.5}
            x2={1131.76}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000171709131658936659470000017289465536873107631_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1132.31}
            y1={394.5}
            x2={1131.76}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000006667794613744289690000006032954742450293430_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1132.31}
            y1={394.5}
            x2={1132.31}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000042718329149901956280000002160122522336787358_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1130.92}
            y1={391.72}
            x2={1131.76}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000011017915959902266350000017600091330181650348_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1148.44}
            y1={408.4}
            x2={1148.44}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000070836877927931414320000005032430714083883666_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1149}
            y1={394.5}
            x2={1148.44}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000170247228812527035770000003355340176854733748_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1149}
            y1={394.5}
            x2={1149}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000123405875691512374020000012889497241237242291_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1149.83}
            y1={391.72}
            x2={1149}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000006690841305685443200000014523904371911209106_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1095.06}
            y1={408.4}
            x2={1096.45}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000129172749320537360090000010693163754022116015_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.93}
            y1={408.4}
            x2={1077.54}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000092454073511654334450000010990888482062903941_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1080.32}
            y1={393.53}
            x2={1093.67}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000134217515148442098970000012939991006663911311_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1093.67}
            y1={392.55}
            x2={1093.67}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000124865455295259184900000002971562169404777659_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1095.62}
            y1={394.5}
            x2={1093.67}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000001655716019169169710000013616664495156995740_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1095.62}
            y1={392.55}
            x2={1095.62}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000117670726987472557000000005141781152058959520_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1095.62}
            y1={392.55}
            x2={1093.67}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000120537598122143205050000006819147155010679219_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1080.32}
            y1={392.55}
            x2={1080.32}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000168823502672205816710000007246340083420201621_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.38}
            y1={394.5}
            x2={1080.32}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000006671662885904953180000016859815603815084968_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.38}
            y1={392.55}
            x2={1078.38}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000054988692956642077270000018279566371946047423_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.38}
            y1={392.55}
            x2={1080.32}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000165932293014158730940000010657362117056484247_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1095.62}
            y1={394.5}
            x2={1095.62}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000111168786432008380710000008710290847161243300_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1095.06}
            y1={394.5}
            x2={1095.62}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000039126841784898475910000018004171137657112230_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1095.06}
            y1={394.5}
            x2={1095.06}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000134961522332397881700000018006286728841002422_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1096.45}
            y1={391.72}
            x2={1095.62}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000123421841585357549970000001463986800942245507_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.93}
            y1={408.4}
            x2={1078.93}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000083090812597518285490000006719536650528450450_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.38}
            y1={394.5}
            x2={1078.93}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000106830574185616859400000000743722035119543952_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1078.38}
            y1={394.5}
            x2={1078.38}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000142892676680862865590000004605323660896931240_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1077.54}
            y1={391.72}
            x2={1078.38}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000070082163249833076570000012122711399210699947_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1067.26}
            y1={408.4}
            x2={1068.65}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000106867833611564362390000005118302611397393596_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.13}
            y1={408.4}
            x2={1049.74}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000124152654436188758680000001777477536321809558_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.52}
            y1={393.53}
            x2={1065.87}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000164515364021826509610000004824660207991663526_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1065.87}
            y1={392.55}
            x2={1065.87}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000171708512139462535740000017133178499396264856_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1067.81}
            y1={394.5}
            x2={1065.87}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000144333148629214987810000007041908122541076391_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1067.81}
            y1={392.55}
            x2={1067.81}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000067237229918848120230000010859220102277187482_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1067.81}
            y1={392.55}
            x2={1065.87}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000078731476108031300620000017181181592654601366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.52}
            y1={392.55}
            x2={1052.52}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000121987871383753589670000016482744197293653142_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1050.58}
            y1={394.5}
            x2={1052.52}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000094603061641197839820000010814116418726290318_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1050.58}
            y1={392.55}
            x2={1050.58}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000124846299043668174430000007610914507236434588_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1050.58}
            y1={392.55}
            x2={1052.52}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000132770178064943375500000013703272813363487129_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1067.81}
            y1={394.5}
            x2={1067.81}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000150804150369363280860000001194925925195197338_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1067.26}
            y1={394.5}
            x2={1067.81}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000003806435303717256270000012128203638797119158_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1067.26}
            y1={394.5}
            x2={1067.26}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000031928160418971302920000001817980242258052489_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1068.65}
            y1={391.72}
            x2={1067.81}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000022549062798567786210000002370304509568889998_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.13}
            y1={408.4}
            x2={1051.13}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000062179087365556331630000017658952164641192074_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1050.58}
            y1={394.5}
            x2={1051.13}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000144301837587244524160000008977487985817269635_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1050.58}
            y1={394.5}
            x2={1050.58}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000126287484206903496390000014816395884158043323_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1049.74}
            y1={391.72}
            x2={1050.58}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000140729209762902787510000002868466954834625179_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1040.21}
            y1={408.4}
            x2={1041.6}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000014624982293367952210000010374832996817438360_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1024.08}
            y1={408.4}
            x2={1022.69}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000046331132361429838470000002507220072193499580_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1025.47}
            y1={393.53}
            x2={1038.82}
            y2={393.53}
          />
        </g>
        <g id='LINE_00000182487220772873421270000001807850324749474951_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1038.82}
            y1={392.55}
            x2={1038.82}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000032642115832441860800000012218191449239840442_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1040.76}
            y1={394.5}
            x2={1038.82}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000000908097438243310980000010753880922530096007_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1040.76}
            y1={392.55}
            x2={1040.76}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000104692612398907376440000001774477121185754525_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1040.76}
            y1={392.55}
            x2={1038.82}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000036949785498866080640000015401572240436804760_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1025.47}
            y1={392.55}
            x2={1025.47}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000035525651809706331480000013722998421812625058_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1023.53}
            y1={394.5}
            x2={1025.47}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000048499637862047973870000006696248736250632081_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1023.53}
            y1={392.55}
            x2={1023.53}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000007405906787827671130000013273236819403996587_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1023.53}
            y1={392.55}
            x2={1025.47}
            y2={392.55}
          />
        </g>
        <g id='LINE_00000103226268190646772450000013380460630781280694_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1040.76}
            y1={394.5}
            x2={1040.76}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000084520730393509224780000001300671350530297483_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1040.21}
            y1={394.5}
            x2={1040.76}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000066483098198993403790000012108699511872563347_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1040.21}
            y1={394.5}
            x2={1040.21}
            y2={408.4}
          />
        </g>
        <g id='LINE_00000125567268012424188910000008180786418874072210_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1041.6}
            y1={391.72}
            x2={1040.76}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000092446054571158390310000006553479846039860871_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1024.08}
            y1={408.4}
            x2={1024.08}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000123408568011754295250000002158622637501230478_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1023.53}
            y1={394.5}
            x2={1024.08}
            y2={394.5}
          />
        </g>
        <g id='LINE_00000119076464945731400710000013721031038278073788_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1023.53}
            y1={394.5}
            x2={1023.53}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000114765994255707215300000006700782767762085275_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1022.69}
            y1={391.72}
            x2={1023.53}
            y2={391.72}
          />
        </g>
        <g id='LINE_00000116219586532959074910000017657758111392413056_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={836.96}
            y1={713.06}
            x2={836.96}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000165194708644139752930000008450767761780359073_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={843.63}
            y1={666.95}
            x2={843.63}
            y2={713.06}
          />
        </g>
        <g id='LINE_00000041976065803356463190000013398499603910918790_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={843.63}
            y1={668.55}
            x2={843.63}
            y2={666.95}
          />
        </g>
        <g id='LINE_00000137100624572240185030000002248800169917139390_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={843.63}
            y1={711.46}
            x2={843.63}
            y2={713.06}
          />
        </g>
        <g id='LINE_00000096749655354643690060000000461534825277869977_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.8504}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={838.77}
            y1={709.86}
            x2={838.77}
            y2={670.15}
          />
        </g>
        <g id='LINE_00000049940919224509706330000012407320914511044224_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={837.79}
            y1={670.15}
            x2={839.74}
            y2={670.15}
          />
        </g>
        <g id='LINE_00000082357031495013039260000014720849893825403536_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={839.74}
            y1={667.91}
            x2={839.74}
            y2={670.15}
          />
        </g>
        <g id='LINE_00000151518697953207529240000005508162461335924624_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={837.79}
            y1={667.91}
            x2={839.74}
            y2={667.91}
          />
        </g>
        <g id='LINE_00000051352095976763495850000011898162437083737495_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={837.79}
            y1={667.91}
            x2={837.79}
            y2={670.15}
          />
        </g>
        <g id='LINE_00000012453558465939653900000002869050710295821714_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={837.79}
            y1={709.86}
            x2={839.74}
            y2={709.86}
          />
        </g>
        <g id='LINE_00000060011946136627706790000013036342209884702615_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={839.74}
            y1={712.1}
            x2={839.74}
            y2={709.86}
          />
        </g>
        <g id='LINE_00000011721494602052645640000014672477575617093004_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={837.79}
            y1={712.1}
            x2={839.74}
            y2={712.1}
          />
        </g>
        <g id='LINE_00000008852735455088944770000006394343889782914489_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={837.79}
            y1={712.1}
            x2={837.79}
            y2={709.86}
          />
        </g>
        <g id='LINE_00000074422510558792344980000006656553117622575265_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={839.74}
            y1={667.91}
            x2={836.96}
            y2={667.91}
          />
        </g>
        <g id='LINE_00000108278059830432630880000001150427097196677789_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={839.74}
            y1={668.55}
            x2={839.74}
            y2={667.91}
          />
        </g>
        <g id='LINE_00000168089973057316499870000015324297877508242055_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={839.74}
            y1={668.55}
            x2={843.63}
            y2={668.55}
          />
        </g>
        <g id='LINE_00000135677959654445593280000002295951412180100507_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={836.96}
            y1={666.95}
            x2={836.96}
            y2={667.91}
          />
        </g>
        <g id='LINE_00000036959047931446299000000014988777927643135366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={843.63}
            y1={711.46}
            x2={839.74}
            y2={711.46}
          />
        </g>
        <g id='LINE_00000048474944505368832650000009261354006649853112_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={839.74}
            y1={712.1}
            x2={839.74}
            y2={711.46}
          />
        </g>
        <g id='LINE_00000052809087720858334540000018078754549997966723_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={839.74}
            y1={712.1}
            x2={836.96}
            y2={712.1}
          />
        </g>
        <g id='LINE_00000073695524592294202920000012002871856110066107_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={836.96}
            y1={713.06}
            x2={836.96}
            y2={712.1}
          />
        </g>
      </g>
      <g id='A-FLOR-_x5F__x5F__x5F__x5F_-OTLN'>
        <g id='LINE_00000011714570362136412520000000391907018710397607_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1476.05}
            y1={78.83}
            x2={1438.24}
            y2={78.83}
          />
        </g>
        <g id='LINE_00000023981765539075364400000004902326515948522885_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={219.81}
            y1={0.71}
            x2={182}
            y2={0.71}
          />
        </g>
        <g id='LINE_00000002350461862935078290000006638675591223845049_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={146.06}
            y1={76.82}
            x2={146.06}
            y2={114.63}
          />
        </g>
        <g id='LINE_00000090258537690712958690000006641532960880235157_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={146.06}
            y1={256.02}
            x2={146.06}
            y2={293.83}
          />
        </g>
        <g id='LINE_00000013914051140733313860000003094239578540072857_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={117.55}
            y1={351.66}
            x2={79.74}
            y2={351.66}
          />
        </g>
        <g id='LINE_00000026849653816404623790000008280272495204828607_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={182.86}
            y1={445.93}
            x2={220.67}
            y2={445.93}
          />
        </g>
        <g id='LINE_00000060003246672932450310000004402695509591545004_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M474.47,563.67'
          />
        </g>
        <g id='LINE_00000138538746672221259030000000221988638970087056_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M557.16,694.01'
          />
        </g>
        <g id='LINE_00000142156669580271628690000009088773782573545899_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={597.78}
            y1={840.77}
            x2={637.81}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000183956386522158645420000007729314530570888067_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={711.14}
            y1={840.77}
            x2={753.4}
            y2={840.77}
          />
        </g>
        <g id='LINE_00000031898164981417868280000016649063869029396660_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.3685}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={423.95}
            y1={312.76}
            x2={463.99}
            y2={312.76}
          />
        </g>
      </g>
      <g id='P-SANR-FIXT-OTLN'>
        <g id='LINE_00000054956397916552822530000017926142958322048700_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.46}
            y1={679.37}
            x2={1285.55}
            y2={674.46}
          />
        </g>
        <g id='LINE_00000137097839213932857290000012635662867439813048_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1271.49}
            y1={687.89}
            x2={1276.4}
            y2={692.8}
          />
        </g>
        <g id='ARC_00000118388387724349601350000001413753495745827735_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1276.4,692.8c0.21,0.21,0.49,0.33,0.79,0.33'
          />
        </g>
        <g id='LINE_00000132779809011091672130000013403141173826060942_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1277.18}
            y1={693.12}
            x2={1284.42}
            y2={693.12}
          />
        </g>
        <g id='ARC_00000005224747375543795420000011222727987267896469_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1284.42,693.12c0.29,0,0.58-0.12,0.79-0.33'
          />
        </g>
        <g id='LINE_00000134214339139669433350000015489106821993100186_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1285.21}
            y1={692.8}
            x2={1290.46}
            y2={687.55}
          />
        </g>
        <g id='ARC_00000041993929391388931090000010166601383865969568_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1290.46,687.55c0.21-0.21,0.33-0.49,0.33-0.79'
          />
        </g>
        <g id='LINE_00000092434880863715433000000014443077241865558698_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.78}
            y1={686.76}
            x2={1290.78}
            y2={680.15}
          />
        </g>
        <g id='ARC_00000032643542663016327550000005665864141771190680_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1290.78,680.15c0-0.29-0.12-0.58-0.33-0.79'
          />
        </g>
        <g id='SPLINE'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1279.98,682.74c0.21-0.21,0.43-0.41,0.66-0.61c0.23-0.19,0.46-0.38,0.7-0.56c0.24-0.18,0.49-0.34,0.75-0.49 c0.26-0.15,0.52-0.3,0.79-0.42s0.54-0.24,0.83-0.34s0.57-0.18,0.86-0.23c0.29-0.06,0.59-0.09,0.89-0.09c0.15,0,0.3,0.01,0.45,0.02 c0.15,0.02,0.3,0.04,0.44,0.08c0.14,0.04,0.29,0.08,0.42,0.14c0.07,0.03,0.14,0.06,0.2,0.1c0.07,0.04,0.13,0.07,0.19,0.11 c0.06,0.04,0.12,0.08,0.18,0.13c0.06,0.05,0.11,0.1,0.17,0.15c0.05,0.05,0.1,0.11,0.15,0.17c0.05,0.06,0.09,0.12,0.13,0.18 c0.04,0.06,0.08,0.12,0.11,0.19s0.07,0.13,0.1,0.2c0.06,0.14,0.11,0.28,0.14,0.43c0.04,0.14,0.06,0.29,0.08,0.44 c0.02,0.15,0.02,0.3,0.02,0.45c0,0.3-0.04,0.6-0.09,0.89s-0.14,0.58-0.23,0.86c-0.1,0.28-0.21,0.56-0.34,0.83 s-0.27,0.53-0.42,0.79s-0.32,0.5-0.49,0.74c-0.18,0.24-0.36,0.48-0.56,0.7c-0.19,0.23-0.4,0.45-0.61,0.66'
          />
        </g>
        <g id='SPLINE_00000001650230363790400780000007337528059734666912_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1285.49,688.25c-0.21,0.21-0.43,0.41-0.66,0.61c-0.23,0.19-0.46,0.38-0.7,0.56s-0.49,0.34-0.74,0.49s-0.52,0.3-0.79,0.42 c-0.27,0.13-0.55,0.24-0.83,0.34c-0.28,0.1-0.57,0.18-0.86,0.23s-0.59,0.09-0.89,0.09c-0.15,0-0.3-0.01-0.45-0.02 c-0.15-0.02-0.29-0.04-0.44-0.08s-0.29-0.08-0.43-0.14c-0.07-0.03-0.14-0.06-0.2-0.1c-0.07-0.04-0.13-0.07-0.19-0.11 c-0.06-0.04-0.12-0.09-0.18-0.13s-0.11-0.1-0.17-0.15c-0.05-0.05-0.1-0.11-0.15-0.17s-0.09-0.12-0.13-0.18s-0.08-0.12-0.11-0.19 c-0.04-0.07-0.07-0.13-0.1-0.2c-0.06-0.14-0.11-0.28-0.14-0.42c-0.04-0.14-0.06-0.29-0.08-0.44c-0.02-0.15-0.02-0.3-0.02-0.45 c0-0.3,0.04-0.6,0.09-0.89c0.06-0.29,0.14-0.58,0.23-0.86c0.1-0.28,0.21-0.56,0.34-0.83c0.13-0.27,0.27-0.53,0.42-0.79 c0.15-0.26,0.32-0.5,0.49-0.75c0.18-0.24,0.36-0.48,0.56-0.7c0.19-0.23,0.4-0.45,0.61-0.66'
          />
        </g>
        <g id='LWPOLYLINE'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1285.62,699.18 1286.06,699.68 1286.52,700.17 1287,700.63 1287.5,701.08 1288.02,701.51 1288.56,701.91 1289.11,702.29  1289.67,702.65 1290.25,702.99 1290.84,703.3 1291.44,703.6 1292.06,703.86 1292.68,704.1 1293.32,704.32 1293.96,704.51  1294.61,704.67 1295.26,704.81 1295.93,704.92 1296.59,705.01 1297.26,705.07 1297.93,705.1 1298.6,705.11 1299.27,705.08  1299.93,705.04 1300.09,705.02  '
          />
        </g>
        <g id='LWPOLYLINE_00000072272725575186867840000015299581103631973290_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1284.71,693.41 1284.65,693.76 1284.61,694.11 1284.58,694.47 1284.57,694.83 1284.57,695.18 1284.59,695.54 1284.62,695.89  1284.66,696.25 1284.72,696.6 1284.79,696.95 1284.87,697.3 1284.97,697.64 1285.09,697.98 1285.21,698.31 1285.35,698.64  1285.5,698.96 1285.62,699.18  '
          />
        </g>
        <g id='LWPOLYLINE_00000151501451459303201130000005163911935061121158_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1296.93,688.38 1296.62,688.2 1296.31,688.04 1295.98,687.89 1295.65,687.75 1295.32,687.62 1294.98,687.51 1294.64,687.42  1294.29,687.33 1293.94,687.26 1293.59,687.21 1293.23,687.17 1292.88,687.14 1292.52,687.13 1292.16,687.13 1291.81,687.14  1291.45,687.17 1291.1,687.22 1290.86,687.26  '
          />
        </g>
        <g id='LWPOLYLINE_00000181079658650694656850000003873795144591750563_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1302.1,703.1 1302.18,702.44 1302.24,701.77 1302.27,701.1 1302.28,700.43 1302.26,699.76 1302.21,699.1 1302.13,698.43  1302.03,697.77 1301.9,697.11 1301.75,696.46 1301.57,695.81 1301.36,695.18 1301.13,694.55 1300.88,693.93 1300.59,693.32  1300.29,692.72 1299.96,692.14 1299.61,691.57 1299.24,691.01 1298.84,690.47 1298.42,689.95 1297.98,689.44 1297.53,688.95  1297.05,688.48 1296.93,688.38  '
          />
        </g>
        <g id='LINE_00000142137956718392016080000005201518376820453259_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1302}
            y1={703.78}
            x2={1302.1}
            y2={703.1}
          />
        </g>
        <g id='LINE_00000172407769580899434830000008617947661417569944_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1300.09}
            y1={705.02}
            x2={1300.77}
            y2={704.95}
          />
        </g>
        <g id='LWPOLYLINE_00000049207374747437696180000013174811137006186137_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1290.09,692.03 1289.96,692.16 1289.84,692.29 1289.73,692.43 1289.64,692.55  '
          />
        </g>
        <g id='LWPOLYLINE_00000008112779267889983260000012106931479003539126_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1289.64,692.55 1289.5,692.66 1289.36,692.77 1289.22,692.89 1289.12,692.99  '
          />
        </g>
        <g id='LINE_00000176730062305105960700000015731788447981847696_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.84}
            y1={687.16}
            x2={1290.46}
            y2={687.55}
          />
        </g>
        <g id='LINE_00000069385117351278863960000015292010598931414710_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1285.21}
            y1={692.8}
            x2={1284.61}
            y2={693.39}
          />
        </g>
        <g id='LINE_00000120522090204192753570000005023099462000138379_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1284.61}
            y1={693.39}
            x2={1284.77}
            y2={693.42}
          />
        </g>
        <g id='ARC_00000041273277728612646110000004664528134298071961_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1284.77,693.42c-0.35,1.96-0.04,3.98,0.9,5.73'
          />
        </g>
        <g id='ARC_00000119075747462802384330000010817780951872937390_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1285.66,699.15c3.56,4.2,8.95,6.37,14.42,5.81'
          />
        </g>
        <g id='LINE_00000147190673983419967210000016921686371079177348_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1300.08}
            y1={704.96}
            x2={1300.93}
            y2={704.88}
          />
        </g>
        <g id='ARC_00000058574466014272136850000011314204814887828406_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1300.93,704.88c0.42-0.21,0.76-0.54,0.99-0.94'
          />
        </g>
        <g id='LINE_00000092454162659362115380000007671027395318209703_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1301.92}
            y1={703.93}
            x2={1302.04}
            y2={703.1}
          />
        </g>
        <g id='ARC_00000098908278921403904330000018393697022128227990_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1302.04,703.1c0.81-5.44-1.12-10.93-5.14-14.67'
          />
        </g>
        <g id='ARC_00000159460499213615302220000004304216277975442584_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1296.9,688.42c-1.81-1.08-3.95-1.47-6.03-1.11'
          />
        </g>
        <g id='LINE_00000095339599057710601880000004624431611245176962_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1290.87}
            y1={687.31}
            x2={1290.84}
            y2={687.16}
          />
        </g>
        <g id='ARC_00000171700806837023978720000018416657950525083318_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1296.34,691.93c-1.81-1.7-4.65-1.61-6.35,0.2c-0.12,0.13-0.24,0.27-0.35,0.42'
          />
        </g>
        <g id='ARC_00000033333467347636853040000010453828227907256217_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1300,701.85c0.41-3.7-0.95-7.37-3.66-9.92'
          />
        </g>
        <g id='ARC_00000072997639475786326430000002527903850603672251_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1299.69,702.6c0.2-0.2,0.31-0.47,0.31-0.75'
          />
        </g>
        <g id='ARC_00000006686434389915385500000004233496718798850434_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1298.94,702.91c0.28,0,0.55-0.11,0.75-0.31'
          />
        </g>
        <g id='ARC_00000091707598653318669200000008970673528763993238_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1289.02,699.25c2.55,2.71,6.22,4.07,9.92,3.66'
          />
        </g>
        <g id='ARC_00000129201854002140450820000002933400555645901740_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1289.64,692.55c-2,1.47-2.43,4.28-0.97,6.28c0.11,0.15,0.22,0.29,0.35,0.42'
          />
        </g>
        <g id='SPLINE_00000020365069667134733560000015101646255976178618_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1300.93,704.88c-0.06,0.03-0.11,0.05-0.13,0.06c-0.01,0.01-0.02,0.01-0.02,0.01c0,0,0,0,0,0s0,0,0.01,0'
          />
        </g>
        <g id='SPLINE_00000148640250655098862670000012841597412389545109_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1300.89,704.9c0.01,0,0.01-0.01,0.02-0.01s0.01-0.01,0.02-0.01c0.01-0.01,0.03-0.02,0.05-0.02c0.02-0.01,0.03-0.02,0.05-0.03 c0.02-0.01,0.03-0.02,0.05-0.03c0.02-0.01,0.03-0.02,0.05-0.03c0.02-0.01,0.03-0.02,0.05-0.03'
          />
        </g>
        <g id='SPLINE_00000177443420181543927020000017036237566078730889_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1301.28,704.66c0.07-0.05,0.14-0.11,0.21-0.18'
          />
        </g>
        <g id='SPLINE_00000065771734859782974140000000651985581546248611_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1301.49,704.48c0.07-0.07,0.13-0.13,0.19-0.2'
          />
        </g>
        <g id='SPLINE_00000025407199760762681370000009793250333172045488_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1301.76,704.18c0.01-0.02,0.02-0.03,0.04-0.05c0.01-0.02,0.02-0.03,0.03-0.05c0.01-0.02,0.02-0.03,0.03-0.05 c0.01-0.02,0.02-0.03,0.03-0.05c0.01-0.02,0.02-0.03,0.03-0.04c0-0.01,0.01-0.01,0.01-0.02s0.01-0.01,0.01-0.02'
          />
        </g>
        <g id='SPLINE_00000181781249046375189480000000159079716354451873_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1301.99,703.79C1302,703.78,1302,703.78,1301.99,703.79c0,0,0,0-0.01,0.02c-0.01,0.03-0.03,0.07-0.07,0.13'
          />
        </g>
        <g id='SPLINE_00000179627377942603912370000004080938859307900561_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1285.66,699.15c-0.03,0.02-0.05,0.03-0.05,0.03'
          />
        </g>
        <g id='SPLINE_00000180337746847160277030000013535612364610048447_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1296.9,688.42c0.02-0.03,0.03-0.05,0.03-0.05'
          />
        </g>
        <g id='SPLINE_00000161605184103701745960000000416329893699833733_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1293.07,696.71c0-0.16,0.03-0.33,0.09-0.48s0.14-0.3,0.25-0.42c0.05-0.06,0.11-0.12,0.17-0.17c0.06-0.05,0.13-0.1,0.2-0.14 c0.04-0.02,0.07-0.04,0.11-0.05c0.04-0.02,0.07-0.03,0.11-0.05c0.04-0.01,0.08-0.03,0.11-0.04s0.08-0.02,0.12-0.03 c0.04-0.01,0.08-0.01,0.12-0.01c0.04,0,0.08,0,0.12,0c0.04,0,0.08,0,0.12,0.01c0.04,0,0.08,0.01,0.12,0.02 c0.04,0.01,0.08,0.02,0.12,0.03c0.04,0.01,0.08,0.02,0.11,0.04c0.04,0.01,0.07,0.03,0.11,0.05c0.04,0.02,0.07,0.03,0.11,0.05 s0.07,0.04,0.1,0.06s0.07,0.05,0.1,0.07c0.06,0.05,0.12,0.11,0.17,0.17c0.11,0.12,0.19,0.27,0.25,0.42s0.09,0.32,0.09,0.48'
          />
        </g>
        <g id='SPLINE_00000183237004727409709440000005951010134001555376_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1295.85,696.71c0,0.16-0.03,0.32-0.08,0.48c-0.06,0.15-0.14,0.29-0.24,0.42c-0.1,0.12-0.23,0.23-0.37,0.31 c-0.14,0.08-0.3,0.14-0.46,0.16c-0.16,0.03-0.32,0.03-0.48,0c-0.16-0.03-0.31-0.09-0.45-0.17c-0.14-0.08-0.26-0.19-0.37-0.31 c-0.05-0.06-0.1-0.13-0.14-0.2s-0.07-0.14-0.1-0.22c-0.05-0.15-0.08-0.31-0.08-0.47'
          />
        </g>
        <g id='LINE_00000086691007732229030650000001728745891146241977_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1298.94}
            y1={702.91}
            x2={1300}
            y2={701.85}
          />
        </g>
        <g id='LWPOLYLINE_00000126318226494161152760000005454396526024178316_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1278.5,681.35 1278.51,681.35 1278.51,681.34 1278.51,681.34 1278.52,681.34 1278.52,681.34 1278.52,681.33 1278.53,681.33  1278.53,681.33 1278.54,681.33 1278.54,681.33 1278.54,681.32 1278.55,681.32 1278.55,681.32 1278.56,681.32 1278.56,681.32  1278.57,681.32 1278.57,681.32 1278.58,681.32 1278.58,681.32 1278.58,681.32 1278.59,681.32 1278.59,681.32 1278.6,681.32  1278.6,681.32 1278.61,681.32 1278.61,681.32 1278.62,681.32 1278.62,681.32 1278.62,681.33 1278.63,681.33 1278.63,681.33  1278.64,681.33 1278.64,681.33 1278.64,681.34 1278.65,681.34 1278.65,681.34 1278.66,681.34 1278.66,681.35 1278.66,681.35  1278.66,681.35  '
          />
        </g>
        <g id='LWPOLYLINE_00000145040118327425813340000010661730074854243477_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1279.12,681.81 1279.09,681.78 1279.06,681.75 1279.03,681.72 1279,681.69 1278.97,681.66 1278.95,681.64 1278.92,681.61  1278.9,681.59 1278.88,681.56 1278.85,681.54 1278.83,681.52 1278.81,681.5 1278.79,681.48 1278.78,681.46 1278.76,681.45  1278.74,681.43 1278.73,681.42 1278.72,681.4 1278.71,681.39 1278.7,681.38 1278.69,681.37 1278.68,681.37 1278.67,681.36  1278.67,681.36 1278.67,681.35 1278.66,681.35 1278.66,681.35  '
          />
        </g>
        <g id='LINE_00000112598175891052086460000007775176626116190366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1278.66}
            y1={681.35}
            x2={1278.66}
            y2={681.35}
          />
        </g>
        <g id='LWPOLYLINE_00000010311137580722369090000017856968584864189573_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1278.5,681.51 1278.5,681.51 1278.5,681.51 1278.49,681.5 1278.49,681.5 1278.49,681.49 1278.49,681.49 1278.48,681.49  1278.48,681.48 1278.48,681.48 1278.48,681.47 1278.48,681.47 1278.47,681.47 1278.47,681.46 1278.47,681.46 1278.47,681.45  1278.47,681.45 1278.47,681.44 1278.47,681.44 1278.47,681.43 1278.47,681.43 1278.47,681.43 1278.47,681.42 1278.47,681.42  1278.47,681.41 1278.47,681.41 1278.47,681.4 1278.47,681.4 1278.47,681.39 1278.48,681.39 1278.48,681.39 1278.48,681.38  1278.48,681.38 1278.48,681.37 1278.49,681.37 1278.49,681.37 1278.49,681.36 1278.49,681.36 1278.5,681.36 1278.5,681.35  1278.5,681.35  '
          />
        </g>
        <g id='LINE_00000124127498877539266510000006436805755212021414_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1278.5}
            y1={681.51}
            x2={1278.5}
            y2={681.51}
          />
        </g>
        <g id='LWPOLYLINE_00000047777098754976179230000015648855034627958694_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1278.5,681.51 1278.5,681.51 1278.5,681.51 1278.51,681.52 1278.51,681.52 1278.52,681.53 1278.53,681.54 1278.54,681.55  1278.55,681.56 1278.56,681.57 1278.57,681.58 1278.58,681.59 1278.6,681.61 1278.62,681.63 1278.63,681.64 1278.65,681.66  1278.67,681.68 1278.69,681.71 1278.72,681.73 1278.74,681.75 1278.77,681.78 1278.79,681.8 1278.82,681.83 1278.84,681.85  1278.87,681.88 1278.9,681.91 1278.93,681.94 1278.96,681.97  '
          />
        </g>
        <g id='LWPOLYLINE_00000154410892799815889440000005913051957614919337_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1279.9,682.59 1279.87,682.56 1279.84,682.53 1279.81,682.5 1279.78,682.47 1279.75,682.44 1279.72,682.41 1279.69,682.38  1279.66,682.34 1279.62,682.31 1279.59,682.28 1279.56,682.24 1279.52,682.21 1279.49,682.18 1279.46,682.15 1279.43,682.11  1279.39,682.08 1279.36,682.05 1279.33,682.02 1279.3,681.98 1279.26,681.95 1279.23,681.92 1279.2,681.89 1279.17,681.86  1279.14,681.83 1279.12,681.81  '
          />
        </g>
        <g id='LWPOLYLINE_00000079452528772810975710000004731965866748938379_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1278.96,681.97 1278.99,682 1279.02,682.03 1279.05,682.06 1279.08,682.09 1279.11,682.12 1279.14,682.15 1279.17,682.18  1279.2,682.21 1279.24,682.25 1279.27,682.28 1279.3,682.31 1279.34,682.35 1279.37,682.38 1279.4,682.41 1279.43,682.44  1279.47,682.48 1279.5,682.51 1279.53,682.54 1279.56,682.57 1279.6,682.61 1279.63,682.64 1279.66,682.67 1279.69,682.7  1279.72,682.73 1279.74,682.75  '
          />
        </g>
        <g id='ELLIPSE'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1278.61,681.32c-0.04-0.01-0.08,0-0.11,0.03'
          />
        </g>
        <g id='ELLIPSE_00000155136463799095258070000017135560421551950216_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1278.7,681.43c0-0.01,0-0.02,0-0.03'
          />
        </g>
        <g id='ELLIPSE_00000138569252062451897460000006933882350097730231_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1278.5,681.35c-0.03,0.03-0.04,0.07-0.03,0.11'
          />
        </g>
        <g id='ELLIPSE_00000011719967761632169660000002601328510195570596_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1279.77,682.76c0.03,0,0.06-0.02,0.09-0.05'
          />
        </g>
        <g id='ELLIPSE_00000068669578960535424340000010735122214868477847_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1279.74,682.74c0,0.01,0.01,0.02,0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000144330542563741471630000007774572805083584952_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1279.78,682.63c-0.01,0.01-0.03,0.03-0.04,0.05'
          />
        </g>
        <g id='ELLIPSE_00000145758158917569506210000010271959942147264666_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1279.83,682.6c-0.02,0.01-0.03,0.02-0.05,0.04'
          />
        </g>
        <g id='ELLIPSE_00000016762577923449230950000014021003579407036586_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1279.91,682.6c0-0.01-0.01-0.02-0.02-0.02'
          />
        </g>
        <g id='ELLIPSE_00000065043170305584117500000004403929973845456773_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1279.86,682.71c0.03-0.03,0.05-0.06,0.05-0.09'
          />
        </g>
        <g id='LINE_00000060745982768705336720000004940908129372452018_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1270.87}
            y1={687.26}
            x2={1271.49}
            y2={687.89}
          />
        </g>
        <g id='LINE_00000148628741460892401240000004337985827012838287_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1271.49}
            y1={687.89}
            x2={1285.55}
            y2={674.46}
          />
        </g>
        <g id='LINE_00000094600802766212079610000013607233580972510366_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1285.55}
            y1={674.46}
            x2={1284.93}
            y2={673.84}
          />
        </g>
        <g id='ARC_00000005234662728898236690000000305515913702771126_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1228.12,679.37c-0.21,0.21-0.33,0.49-0.33,0.79'
          />
        </g>
        <g id='LINE_00000169548588393951027260000018427683076198212751_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1227.8}
            y1={680.15}
            x2={1227.8}
            y2={686.76}
          />
        </g>
        <g id='ARC_00000001633071039388624260000016300237715840467343_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1227.8,686.76c0,0.29,0.12,0.58,0.33,0.79'
          />
        </g>
        <g id='LINE_00000089533594342439473190000009933985853439741367_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1228.12}
            y1={687.55}
            x2={1233.37}
            y2={692.8}
          />
        </g>
        <g id='ARC_00000113315284268676397430000005631665275443167399_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1233.37,692.8c0.21,0.21,0.49,0.33,0.79,0.33'
          />
        </g>
        <g id='LINE_00000142154896610029562720000011533315499378051460_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1234.16}
            y1={693.12}
            x2={1241.39}
            y2={693.12}
          />
        </g>
        <g id='ARC_00000036959630225190690440000003811139042895016336_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1241.39,693.12c0.29,0,0.58-0.12,0.79-0.33'
          />
        </g>
        <g id='LINE_00000136399227245015746950000014292903690099077816_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1242.18}
            y1={692.8}
            x2={1247.09}
            y2={687.89}
          />
        </g>
        <g id='LINE_00000103230581870537093420000016509224842596779428_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1233.02}
            y1={674.46}
            x2={1228.12}
            y2={679.37}
          />
        </g>
        <g id='SPLINE_00000031918944907494732610000006688498387000946831_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1238.6,682.74c0.21,0.21,0.41,0.43,0.61,0.66c0.19,0.23,0.38,0.46,0.56,0.7c0.18,0.24,0.34,0.49,0.49,0.75s0.3,0.52,0.42,0.79 c0.13,0.27,0.24,0.54,0.34,0.83s0.18,0.57,0.23,0.86s0.09,0.59,0.09,0.89c0,0.15-0.01,0.3-0.02,0.45 c-0.02,0.15-0.04,0.3-0.08,0.44c-0.04,0.14-0.08,0.29-0.14,0.42c-0.03,0.07-0.06,0.14-0.1,0.2c-0.04,0.07-0.07,0.13-0.11,0.19 s-0.08,0.12-0.13,0.18s-0.1,0.11-0.15,0.17c-0.05,0.05-0.11,0.1-0.17,0.15c-0.06,0.05-0.12,0.09-0.18,0.13 c-0.06,0.04-0.12,0.08-0.19,0.11c-0.07,0.04-0.13,0.07-0.2,0.1c-0.14,0.06-0.28,0.11-0.43,0.14c-0.14,0.04-0.29,0.06-0.44,0.08 c-0.15,0.02-0.3,0.02-0.45,0.02c-0.3,0-0.6-0.04-0.89-0.09c-0.29-0.06-0.58-0.14-0.86-0.23c-0.28-0.1-0.56-0.21-0.83-0.34 c-0.27-0.13-0.53-0.27-0.79-0.42c-0.26-0.15-0.5-0.32-0.74-0.49s-0.48-0.36-0.7-0.56c-0.23-0.19-0.45-0.4-0.66-0.61'
          />
        </g>
        <g id='SPLINE_00000155140308207997214910000004498806076915864247_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1233.09,688.25c-0.21-0.21-0.41-0.43-0.61-0.66c-0.19-0.23-0.38-0.46-0.56-0.7c-0.18-0.24-0.34-0.49-0.49-0.74 s-0.3-0.52-0.42-0.79s-0.24-0.55-0.34-0.83c-0.1-0.28-0.18-0.57-0.23-0.86c-0.06-0.29-0.09-0.59-0.09-0.89 c0-0.15,0.01-0.3,0.02-0.45s0.04-0.29,0.08-0.44c0.04-0.14,0.08-0.29,0.14-0.43c0.03-0.07,0.06-0.14,0.1-0.2s0.07-0.13,0.11-0.19 c0.04-0.06,0.09-0.12,0.13-0.18c0.05-0.06,0.1-0.11,0.15-0.17c0.05-0.05,0.11-0.1,0.17-0.15c0.06-0.05,0.12-0.09,0.18-0.13 c0.06-0.04,0.12-0.08,0.19-0.11s0.13-0.07,0.2-0.1c0.14-0.06,0.28-0.11,0.42-0.14c0.14-0.04,0.29-0.06,0.44-0.08 c0.15-0.02,0.3-0.02,0.45-0.02c0.3,0,0.6,0.04,0.89,0.09c0.29,0.06,0.58,0.14,0.86,0.23c0.28,0.1,0.56,0.21,0.83,0.34 s0.53,0.27,0.79,0.42c0.26,0.15,0.5,0.32,0.75,0.49c0.24,0.18,0.48,0.36,0.7,0.56c0.23,0.19,0.45,0.4,0.66,0.61'
          />
        </g>
        <g id='LWPOLYLINE_00000041269514192439192020000002988459640632308142_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1218.49,705.02 1219.15,705.08 1219.82,705.1 1220.49,705.1 1221.16,705.08 1221.83,705.03 1222.5,704.95 1223.16,704.84  1223.81,704.71 1224.46,704.55 1225.11,704.37 1225.74,704.16 1226.37,703.92 1226.99,703.66 1227.6,703.38 1228.19,703.07  1228.77,702.73 1229.34,702.38 1229.89,702 1230.43,701.6 1230.95,701.18 1231.46,700.74 1231.94,700.28 1232.41,699.8  1232.86,699.3 1232.96,699.18  '
          />
        </g>
        <g id='LWPOLYLINE_00000126305877512500467370000011250134178585822623_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1232.96,699.18 1233.12,698.86 1233.27,698.54 1233.41,698.21 1233.53,697.87 1233.64,697.53 1233.73,697.19 1233.81,696.84  1233.88,696.49 1233.93,696.14 1233.97,695.79 1234,695.43 1234.01,695.07 1234,694.72 1233.99,694.36 1233.95,694  1233.91,693.65 1233.87,693.41  '
          />
        </g>
        <g id='LWPOLYLINE_00000124128454994157373050000013818734392519523216_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1227.72,687.26 1227.36,687.2 1227.01,687.16 1226.65,687.14 1226.3,687.13 1225.94,687.13 1225.58,687.15 1225.23,687.18  1224.87,687.22 1224.52,687.28 1224.17,687.36 1223.83,687.45 1223.49,687.55 1223.15,687.66 1222.82,687.79 1222.49,687.93  1222.17,688.09 1221.85,688.26 1221.64,688.38  '
          />
        </g>
        <g id='LWPOLYLINE_00000041291531862753460200000016596466673761056663_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1221.64,688.38 1221.16,688.84 1220.7,689.33 1220.26,689.83 1219.83,690.35 1219.43,690.89 1219.05,691.44 1218.7,692  1218.36,692.58 1218.05,693.18 1217.77,693.78 1217.5,694.4 1217.27,695.03 1217.05,695.66 1216.87,696.31 1216.71,696.96  1216.57,697.61 1216.47,698.27 1216.38,698.94 1216.33,699.61 1216.3,700.28 1216.3,700.95 1216.32,701.61 1216.38,702.28  1216.46,702.95 1216.48,703.1  '
          />
        </g>
        <g id='LINE_00000124873665027561825330000013359388604821430935_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1216.48}
            y1={703.1}
            x2={1216.58}
            y2={703.78}
          />
        </g>
        <g id='LINE_00000023963600367657066370000011004285002445129878_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1217.81}
            y1={704.95}
            x2={1218.49}
            y2={705.02}
          />
        </g>
        <g id='LWPOLYLINE_00000004536478476507711360000001991345532238766227_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1228.94,692.55 1228.83,692.41 1228.71,692.27 1228.59,692.13 1228.49,692.03  '
          />
        </g>
        <g id='LWPOLYLINE_00000085954438627753279550000005138235036866537373_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1229.46,692.99 1229.33,692.87 1229.2,692.75 1229.06,692.64 1228.94,692.55  '
          />
        </g>
        <g id='LINE_00000163752481889661966310000002628377916633362859_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1227.73}
            y1={687.16}
            x2={1227.71}
            y2={687.31}
          />
        </g>
        <g id='ARC_00000103947158946111903140000000665733213085987214_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1227.71,687.31c-2.08-0.36-4.22,0.03-6.03,1.11'
          />
        </g>
        <g id='ARC_00000009555259286641669760000015427749655160703109_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1221.68,688.42c-4.03,3.75-5.95,9.23-5.14,14.67'
          />
        </g>
        <g id='LINE_00000076564636324554841470000003575021285640762499_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1216.54}
            y1={703.1}
            x2={1216.66}
            y2={703.93}
          />
        </g>
        <g id='ARC_00000132805882636596315160000014995460385817143709_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1216.66,703.93c0.23,0.41,0.57,0.73,0.99,0.94'
          />
        </g>
        <g id='LINE_00000178166098334101549920000005990715482806036633_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1217.65}
            y1={704.88}
            x2={1218.49}
            y2={704.96}
          />
        </g>
        <g id='ARC_00000106860962972657454100000011105110823883485600_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1218.49,704.96c5.47,0.56,10.87-1.62,14.42-5.81'
          />
        </g>
        <g id='ARC_00000062900058507725342770000018019549303588882595_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1232.91,699.15c0.93-1.76,1.25-3.77,0.9-5.73'
          />
        </g>
        <g id='LINE_00000021078099710750436670000000844440653933965186_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1233.81}
            y1={693.42}
            x2={1233.97}
            y2={693.39}
          />
        </g>
        <g id='LINE_00000052084429574291668790000016548488507503554238_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1233.97}
            y1={693.39}
            x2={1233.37}
            y2={692.8}
          />
        </g>
        <g id='LINE_00000048471609844482875080000004921506132486181822_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1228.12}
            y1={687.55}
            x2={1227.73}
            y2={687.16}
          />
        </g>
        <g id='ARC_00000062184995761339762110000005551200134684008328_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1229.56,699.25c1.7-1.81,1.61-4.65-0.2-6.35c-0.13-0.12-0.27-0.24-0.42-0.35'
          />
        </g>
        <g id='ARC_00000090276879400243880840000001292265247619345597_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1219.63,702.91c3.7,0.41,7.37-0.95,9.92-3.66'
          />
        </g>
        <g id='ARC_00000168079708273897356910000008515066482637685136_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1218.89,702.6c0.2,0.2,0.47,0.31,0.75,0.31'
          />
        </g>
        <g id='ARC_00000022529149771306162610000017478550456121413790_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1218.58,701.85c0,0.28,0.11,0.55,0.31,0.75'
          />
        </g>
        <g id='ARC_00000112612067272592550100000009203193134736212106_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1222.24,691.93c-2.71,2.55-4.07,6.22-3.66,9.92'
          />
        </g>
        <g id='ARC_00000148622375852030037280000008412797479717226412_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1228.94,692.55c-1.47-2-4.28-2.43-6.28-0.97c-0.15,0.11-0.29,0.22-0.42,0.35'
          />
        </g>
        <g id='SPLINE_00000018938721079352265460000000437677777885659034_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1216.66,703.93c-0.04-0.06-0.06-0.11-0.07-0.13c-0.01-0.01-0.01-0.02-0.01-0.02c0,0,0,0,0,0c0,0,0,0,0,0.01'
          />
        </g>
        <g id='SPLINE_00000040538562684146442140000005238423498939706522_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1216.64,703.9c0,0.01,0.01,0.01,0.01,0.02s0.01,0.01,0.01,0.02c0.01,0.01,0.02,0.03,0.03,0.04s0.02,0.03,0.03,0.05 c0.01,0.02,0.02,0.03,0.03,0.05c0.01,0.02,0.02,0.03,0.03,0.05c0.01,0.02,0.02,0.03,0.04,0.05'
          />
        </g>
        <g id='SPLINE_00000054946779375391605850000006580491957973311369_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1216.9,704.28c0.06,0.07,0.12,0.14,0.19,0.2'
          />
        </g>
        <g id='SPLINE_00000117638192232369242630000003791269968453531539_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1217.08,704.48c0.07,0.07,0.14,0.13,0.21,0.18'
          />
        </g>
        <g id='SPLINE_00000100368389689500796220000003163091409172829589_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1217.4,704.73c0.02,0.01,0.03,0.02,0.05,0.03c0.02,0.01,0.03,0.02,0.05,0.03c0.02,0.01,0.03,0.02,0.05,0.03 c0.02,0.01,0.03,0.02,0.05,0.03c0.02,0.01,0.03,0.02,0.05,0.02c0.01,0,0.01,0.01,0.02,0.01c0.01,0,0.01,0.01,0.02,0.01'
          />
        </g>
        <g id='SPLINE_00000085951159958255152130000002107277396437714061_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1217.8,704.95C1217.81,704.95,1217.81,704.95,1217.8,704.95c0,0,0,0-0.02-0.01c-0.03-0.01-0.07-0.03-0.13-0.06'
          />
        </g>
        <g id='SPLINE_00000121267157377008534220000011365454548883620001_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1232.96,699.18c0,0-0.02-0.01-0.05-0.03'
          />
        </g>
        <g id='SPLINE_00000013879350636505729560000015519367601629740983_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1221.64,688.38c0,0,0.01,0.02,0.03,0.05'
          />
        </g>
        <g id='SPLINE_00000014618374249682760440000017139126394254924452_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1225.51,696.71c0,0.16-0.03,0.32-0.08,0.47c-0.03,0.08-0.06,0.15-0.1,0.22s-0.09,0.14-0.14,0.2c-0.1,0.12-0.23,0.23-0.37,0.31 c-0.14,0.08-0.29,0.14-0.45,0.17c-0.16,0.03-0.32,0.03-0.48,0c-0.16-0.03-0.32-0.08-0.46-0.16c-0.14-0.08-0.27-0.19-0.37-0.31 c-0.1-0.12-0.19-0.27-0.24-0.42c-0.06-0.15-0.08-0.31-0.08-0.48'
          />
        </g>
        <g id='SPLINE_00000165224757326326175580000017835522593606704533_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1222.73,696.71c0-0.16,0.03-0.33,0.09-0.48s0.14-0.3,0.25-0.42c0.05-0.06,0.11-0.12,0.17-0.17c0.03-0.03,0.06-0.05,0.1-0.07 c0.03-0.02,0.07-0.04,0.1-0.06s0.07-0.04,0.11-0.05c0.04-0.02,0.07-0.03,0.11-0.05s0.08-0.03,0.11-0.04 c0.04-0.01,0.08-0.02,0.12-0.03c0.04-0.01,0.08-0.01,0.12-0.02s0.08-0.01,0.12-0.01c0.04,0,0.08,0,0.12,0 c0.04,0,0.08,0.01,0.12,0.01c0.04,0.01,0.08,0.02,0.12,0.03s0.08,0.02,0.11,0.04c0.08,0.03,0.15,0.06,0.22,0.1 c0.07,0.04,0.14,0.09,0.2,0.14c0.06,0.05,0.12,0.11,0.17,0.17c0.11,0.12,0.19,0.27,0.25,0.42s0.09,0.32,0.09,0.48'
          />
        </g>
        <g id='LINE_00000152222331700915279290000008712656608378756003_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1218.58}
            y1={701.85}
            x2={1219.63}
            y2={702.91}
          />
        </g>
        <g id='LWPOLYLINE_00000095340188061015089820000014485705476629310608_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1239.91,681.35 1239.92,681.35 1239.92,681.34 1239.92,681.34 1239.93,681.34 1239.93,681.34 1239.94,681.33 1239.94,681.33  1239.94,681.33 1239.95,681.33 1239.95,681.33 1239.96,681.32 1239.96,681.32 1239.96,681.32 1239.97,681.32 1239.97,681.32  1239.98,681.32 1239.98,681.32 1239.99,681.32 1239.99,681.32 1240,681.32 1240,681.32 1240.01,681.32 1240.01,681.32  1240.01,681.32 1240.02,681.32 1240.02,681.32 1240.03,681.32 1240.03,681.32 1240.04,681.33 1240.04,681.33 1240.04,681.33  1240.05,681.33 1240.05,681.33 1240.06,681.34 1240.06,681.34 1240.06,681.34 1240.07,681.34 1240.07,681.35 1240.07,681.35  1240.08,681.35  '
          />
        </g>
        <g id='LINE_00000127044709289633369750000009415778256151415204_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.91}
            y1={681.35}
            x2={1239.91}
            y2={681.35}
          />
        </g>
        <g id='LWPOLYLINE_00000108994929352910876320000006096351224729466552_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1239.91,681.35 1239.91,681.35 1239.91,681.35 1239.91,681.36 1239.9,681.36 1239.9,681.37 1239.89,681.38 1239.88,681.38  1239.87,681.39 1239.86,681.41 1239.85,681.42 1239.83,681.43 1239.82,681.45 1239.8,681.47 1239.78,681.48 1239.76,681.5  1239.74,681.52 1239.72,681.54 1239.7,681.57 1239.68,681.59 1239.65,681.61 1239.63,681.64 1239.6,681.67 1239.57,681.69  1239.54,681.72 1239.52,681.75 1239.49,681.78 1239.46,681.81  '
          />
        </g>
        <g id='LWPOLYLINE_00000129917719129443967420000001188915679538841743_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1240.08,681.35 1240.08,681.35 1240.08,681.36 1240.08,681.36 1240.09,681.36 1240.09,681.37 1240.09,681.37 1240.09,681.38  1240.1,681.38 1240.1,681.38 1240.1,681.39 1240.1,681.39 1240.1,681.4 1240.1,681.4 1240.11,681.41 1240.11,681.41  1240.11,681.41 1240.11,681.42 1240.11,681.42 1240.11,681.43 1240.11,681.43 1240.11,681.44 1240.11,681.44 1240.11,681.45  1240.11,681.45 1240.11,681.46 1240.1,681.46 1240.1,681.46 1240.1,681.47 1240.1,681.47 1240.1,681.48 1240.1,681.48  1240.09,681.49 1240.09,681.49 1240.09,681.49 1240.09,681.5 1240.08,681.5 1240.08,681.5 1240.08,681.51 1240.08,681.51  1240.08,681.51  '
          />
        </g>
        <g id='LWPOLYLINE_00000178891055006852345760000011682494431849885355_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1239.62,681.97 1239.65,681.94 1239.68,681.91 1239.71,681.88 1239.74,681.85 1239.76,681.82 1239.79,681.8 1239.82,681.77  1239.84,681.75 1239.86,681.72 1239.89,681.7 1239.91,681.68 1239.93,681.66 1239.95,681.64 1239.96,681.62 1239.98,681.61  1239.99,681.59 1240.01,681.58 1240.02,681.57 1240.03,681.55 1240.04,681.54 1240.05,681.54 1240.06,681.53 1240.06,681.52  1240.07,681.52 1240.07,681.51 1240.07,681.51 1240.08,681.51  '
          />
        </g>
        <g id='LINE_00000040539548373800203140000015167250703067127697_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1240.08}
            y1={681.51}
            x2={1240.07}
            y2={681.51}
          />
        </g>
        <g id='LWPOLYLINE_00000008871791114228087860000016492426011697448096_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1239.46,681.81 1239.43,681.83 1239.4,681.86 1239.37,681.89 1239.34,681.93 1239.31,681.96 1239.28,681.99 1239.24,682.02  1239.21,682.05 1239.18,682.09 1239.15,682.12 1239.11,682.15 1239.08,682.18 1239.05,682.22 1239.01,682.25 1238.98,682.28  1238.95,682.32 1238.92,682.35 1238.88,682.38 1238.85,682.41 1238.82,682.45 1238.79,682.48 1238.76,682.51 1238.73,682.54  1238.7,682.57 1238.67,682.59  '
          />
        </g>
        <g id='LWPOLYLINE_00000008847057247414425150000005837464483286743470_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1238.83,682.75 1238.86,682.72 1238.89,682.69 1238.92,682.66 1238.96,682.63 1238.99,682.6 1239.02,682.57 1239.05,682.54  1239.08,682.5 1239.12,682.47 1239.15,682.44 1239.18,682.41 1239.21,682.37 1239.25,682.34 1239.28,682.31 1239.31,682.27  1239.35,682.24 1239.38,682.21 1239.41,682.18 1239.44,682.14 1239.47,682.11 1239.51,682.08 1239.54,682.05 1239.57,682.02  1239.6,681.99 1239.62,681.97  '
          />
        </g>
        <g id='ELLIPSE_00000176748493621656180870000014982700071337249460_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1240.1,681.46c0.01-0.04,0-0.08-0.03-0.11'
          />
        </g>
        <g id='ELLIPSE_00000064325290532928618660000013246804723183443619_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1239.88,681.4c0,0.01,0,0.02,0,0.03'
          />
        </g>
        <g id='ELLIPSE_00000085941828826903539050000012544341104195796638_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1240.07,681.35c-0.03-0.03-0.07-0.04-0.11-0.03'
          />
        </g>
        <g id='ELLIPSE_00000008131350971283204410000010753258381351670677_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1238.66,682.62c0,0.03,0.02,0.06,0.05,0.09'
          />
        </g>
        <g id='ELLIPSE_00000104683728693300837240000012969097754139873198_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1238.69,682.58c-0.01,0-0.02,0.01-0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000137112514126283862040000010885223646503961787_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1238.79,682.63c-0.01-0.01-0.03-0.03-0.05-0.04'
          />
        </g>
        <g id='ELLIPSE_00000095311066938946923960000015599334396197910422_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1238.83,682.68c-0.01-0.02-0.02-0.03-0.04-0.05'
          />
        </g>
        <g id='ELLIPSE_00000070092610330525936810000015733533478399564975_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1238.82,682.76c0.01,0,0.02-0.01,0.02-0.02'
          />
        </g>
        <g id='ELLIPSE_00000007405895430631249640000003981785920424753062_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1238.72,682.71c0.03,0.03,0.06,0.05,0.09,0.05'
          />
        </g>
        <g id='LINE_00000051366061273675559820000007073325436449914808_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1233.65}
            y1={673.84}
            x2={1233.02}
            y2={674.46}
          />
        </g>
        <g id='LINE_00000071518211267576269830000013120954372579285386_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1233.02}
            y1={674.46}
            x2={1247.09}
            y2={687.89}
          />
        </g>
        <g id='LINE_00000126294948751915611980000017078151273271720378_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1247.09}
            y1={687.89}
            x2={1247.71}
            y2={687.26}
          />
        </g>
        <g id='LINE_00000183970164469720198490000007100816027279393704_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1019.36}
            y1={91.37}
            x2={1019.2}
            y2={84.44}
          />
        </g>
        <g id='LINE_00000040545538062122545290000001985829939003459262_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={999.76}
            y1={84.44}
            x2={999.92}
            y2={91.38}
          />
        </g>
        <g id='ARC_00000172428759563182994600000017540078968043018400_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M999.92,91.38c0.01,0.29,0.13,0.57,0.34,0.78'
          />
        </g>
        <g id='LINE_00000047763606381265756240000005713394578931903126_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1000.26}
            y1={92.16}
            x2={1005.5}
            y2={97.15}
          />
        </g>
        <g id='ARC_00000178892011988942306320000015153491087209582208_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1005.5,97.15c0.21,0.2,0.5,0.31,0.79,0.31'
          />
        </g>
        <g id='LINE_00000029766748136844586750000001931606067214563485_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1006.29}
            y1={97.46}
            x2={1013.71}
            y2={97.29}
          />
        </g>
        <g id='ARC_00000060717136711506106550000003285211594804984460_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1013.71,97.29c0.29-0.01,0.57-0.13,0.78-0.34'
          />
        </g>
        <g id='LINE_00000032631920562909610740000016350475568402314907_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1014.49}
            y1={96.95}
            x2={1019.06}
            y2={92.17}
          />
        </g>
        <g id='ARC_00000001658961422832891100000011258379729737874584_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1019.06,92.17c0.2-0.21,0.31-0.5,0.31-0.79'
          />
        </g>
        <g id='SPLINE_00000011010516653944503890000010801135215367959443_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.46,86.58c0.3-0.01,0.6,0,0.89,0.01c0.3,0.02,0.6,0.04,0.89,0.08c0.3,0.04,0.59,0.09,0.88,0.16 c0.29,0.07,0.58,0.14,0.86,0.24c0.28,0.09,0.56,0.2,0.83,0.32s0.53,0.26,0.79,0.43c0.25,0.16,0.49,0.34,0.71,0.55 c0.11,0.1,0.21,0.21,0.31,0.33c0.1,0.11,0.18,0.23,0.26,0.36s0.15,0.26,0.21,0.4c0.03,0.07,0.06,0.14,0.08,0.21 c0.02,0.07,0.04,0.14,0.06,0.21c0.02,0.07,0.03,0.15,0.04,0.22s0.01,0.15,0.02,0.22s0,0.15-0.01,0.22 c-0.01,0.07-0.02,0.15-0.03,0.22c-0.01,0.07-0.03,0.14-0.05,0.22c-0.02,0.07-0.04,0.14-0.07,0.21c-0.05,0.14-0.12,0.28-0.19,0.41 c-0.07,0.13-0.16,0.25-0.25,0.37c-0.09,0.12-0.19,0.23-0.29,0.34c-0.21,0.21-0.44,0.41-0.68,0.58s-0.5,0.33-0.77,0.46 c-0.27,0.14-0.54,0.26-0.82,0.36c-0.28,0.11-0.56,0.2-0.85,0.28c-0.29,0.08-0.58,0.14-0.87,0.2s-0.59,0.1-0.89,0.12 s-0.6,0.05-0.89,0.06'
          />
        </g>
        <g id='SPLINE_00000009573945673998057010000003413831011964737156_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.63,94.36c-0.3,0.01-0.6,0-0.9-0.01s-0.6-0.04-0.89-0.08c-0.3-0.04-0.59-0.09-0.88-0.16c-0.29-0.06-0.58-0.14-0.86-0.24 c-0.28-0.09-0.56-0.2-0.84-0.33c-0.27-0.12-0.54-0.27-0.79-0.43s-0.49-0.34-0.71-0.55c-0.11-0.1-0.21-0.21-0.31-0.32 s-0.18-0.23-0.26-0.36s-0.15-0.26-0.21-0.4c-0.03-0.07-0.06-0.14-0.08-0.21c-0.02-0.07-0.04-0.14-0.06-0.21 c-0.02-0.07-0.03-0.15-0.04-0.22c-0.01-0.07-0.01-0.15-0.02-0.22c0-0.08,0-0.15,0.01-0.22s0.02-0.15,0.03-0.22 c0.01-0.07,0.03-0.14,0.05-0.22s0.04-0.14,0.07-0.21c0.05-0.14,0.12-0.28,0.19-0.41s0.16-0.25,0.25-0.37 c0.09-0.12,0.19-0.23,0.29-0.34c0.21-0.21,0.44-0.41,0.68-0.58s0.5-0.33,0.77-0.46c0.26-0.14,0.54-0.26,0.82-0.36 s0.56-0.2,0.85-0.28s0.58-0.14,0.87-0.2s0.59-0.1,0.89-0.12s0.6-0.05,0.89-0.06'
          />
        </g>
        <g id='LWPOLYLINE_00000116940963805334830680000004087013292101004684_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1002.18,102.36 1002.15,103.03 1002.15,103.7 1002.18,104.37 1002.23,105.04 1002.31,105.7 1002.42,106.37 1002.55,107.02  1002.71,107.67 1002.9,108.32 1003.11,108.95 1003.35,109.58 1003.61,110.2 1003.89,110.8 1004.2,111.4 1004.53,111.98  1004.89,112.54 1005.27,113.1 1005.67,113.63 1006.09,114.16 1006.53,114.66 1006.99,115.14 1007.47,115.61 1007.97,116.06  1008.49,116.48 1008.61,116.58  '
          />
        </g>
        <g id='LWPOLYLINE_00000041293720051587520030000000113663248461116082_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1005.51,97.56 1005.23,97.78 1004.95,98 1004.69,98.24 1004.43,98.49 1004.18,98.75 1003.95,99.02 1003.72,99.29 1003.51,99.58  1003.31,99.87 1003.12,100.18 1002.94,100.49 1002.78,100.8 1002.63,101.13 1002.49,101.45 1002.36,101.79 1002.25,102.13  1002.18,102.36  '
          />
        </g>
        <g id='LWPOLYLINE_00000023259623750122792410000009935722788814297235_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1017.83,102.36 1017.72,102.02 1017.61,101.68 1017.48,101.35 1017.33,101.03 1017.18,100.7 1017.01,100.39 1016.83,100.08  1016.63,99.78 1016.43,99.49 1016.21,99.21 1015.99,98.93 1015.75,98.67 1015.5,98.41 1015.24,98.17 1014.97,97.93 1014.69,97.71  1014.41,97.5 1014.21,97.36  '
          />
        </g>
        <g id='LWPOLYLINE_00000165942690591476457780000012409318048988495801_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1011.39,116.58 1011.91,116.16 1012.41,115.72 1012.9,115.26 1013.37,114.77 1013.81,114.28 1014.24,113.76 1014.64,113.23  1015.03,112.68 1015.39,112.11 1015.73,111.53 1016.04,110.94 1016.33,110.34 1016.6,109.73 1016.84,109.1 1017.06,108.47  1017.25,107.82 1017.42,107.18 1017.56,106.52 1017.67,105.86 1017.76,105.2 1017.82,104.53 1017.85,103.86 1017.86,103.19  1017.84,102.52 1017.83,102.36  '
          />
        </g>
        <g id='LINE_00000095324819173875381720000016345930124058049956_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1010.85}
            y1={117}
            x2={1011.39}
            y2={116.58}
          />
        </g>
        <g id='LINE_00000061458851815425958950000005509856464750557342_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1008.61}
            y1={116.58}
            x2={1009.15}
            y2={117}
          />
        </g>
        <g id='LWPOLYLINE_00000060727033753697992500000001777261351941178757_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1010.35,100.27 1010.17,100.28 1009.99,100.3 1009.81,100.32 1009.67,100.34  '
          />
        </g>
        <g id='LWPOLYLINE_00000127722619273132297580000008961000883009080967_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1009.67,100.34 1009.49,100.32 1009.31,100.31 1009.13,100.31 1008.98,100.31  '
          />
        </g>
        <g id='LINE_00000053512214063825479780000005248405257113549187_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1014.26}
            y1={97.28}
            x2={1013.71}
            y2={97.29}
          />
        </g>
        <g id='LINE_00000027566226058038457810000005854817182323580069_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1006.29}
            y1={97.46}
            x2={1005.45}
            y2={97.48}
          />
        </g>
        <g id='LINE_00000122720580016565290890000015200959829916709787_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1005.45}
            y1={97.48}
            x2={1005.54}
            y2={97.61}
          />
        </g>
        <g id='ARC_00000004508096413435172400000013551358353100669582_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1005.54,97.61c-1.61,1.17-2.77,2.85-3.31,4.77'
          />
        </g>
        <g id='ARC_00000142176430145564575550000012667088564712311432_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1002.24,102.37c-0.33,5.49,2.07,10.79,6.41,14.16'
          />
        </g>
        <g id='LINE_00000026844236229704230230000010583562329354059678_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1008.65}
            y1={116.54}
            x2={1009.32}
            y2={117.06}
          />
        </g>
        <g id='ARC_00000098184215519863127990000013647707620835582348_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.32,117.06c0.45,0.13,0.92,0.13,1.37,0'
          />
        </g>
        <g id='LINE_00000039845560189830091930000016170508183113532346_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1010.69}
            y1={117.06}
            x2={1011.36}
            y2={116.54}
          />
        </g>
        <g id='ARC_00000172423121215350732160000003337444082350600341_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1011.36,116.54c4.34-3.38,6.74-8.67,6.41-14.16'
          />
        </g>
        <g id='ARC_00000117666080789481706850000002994564020799415958_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1017.77,102.37c-0.57-2.03-1.84-3.79-3.6-4.97'
          />
        </g>
        <g id='LINE_00000109745460474388493340000009820500627088934303_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1014.17}
            y1={97.41}
            x2={1014.26}
            y2={97.28}
          />
        </g>
        <g id='ARC_00000065773533638667773740000015015392444590652561_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1014.94,104.52c-0.13-2.48-2.25-4.38-4.73-4.24c-0.18,0.01-0.36,0.03-0.54,0.06'
          />
        </g>
        <g id='ARC_00000175307685019150922630000002034201510043434635_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1010.74,114.23c2.85-2.39,4.41-5.99,4.2-9.7'
          />
        </g>
        <g id='ARC_00000113349294487925440290000015851147907915170481_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1010,114.55c0.28-0.01,0.55-0.12,0.74-0.33'
          />
        </g>
        <g id='ARC_00000130648861895158947890000007811848069382225034_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.24,114.26c0.2,0.19,0.47,0.3,0.75,0.29'
          />
        </g>
        <g id='ARC_00000103943878403790961910000009168375954993631917_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1004.59,104.76c-0.03,3.72,1.69,7.24,4.65,9.5'
          />
        </g>
        <g id='ARC_00000056420948344933706210000013528341006681929397_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.67,100.34c-2.46-0.32-4.72,1.41-5.04,3.87c-0.02,0.18-0.04,0.36-0.04,0.55'
          />
        </g>
        <g id='SPLINE_00000172398490181789343190000007064042148940240535_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.32,117.06c-0.07-0.02-0.11-0.04-0.14-0.05'
          />
        </g>
        <g id='SPLINE_00000108301351354476390600000004040549549642236326_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.28,117.04c0.01,0,0.01,0,0.02,0.01c0.01,0,0.01,0,0.02,0.01c0.02,0,0.03,0.01,0.05,0.01c0.02,0,0.04,0.01,0.05,0.01 s0.04,0.01,0.06,0.01c0.02,0,0.04,0.01,0.06,0.01s0.04,0.01,0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000121273121418610862420000009377092546299169196_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.73,117.14c0.09,0.01,0.18,0.02,0.28,0.02'
          />
        </g>
        <g id='SPLINE_00000022533185156932806150000004056493609677171336_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1010,117.16c0.1,0,0.19-0.01,0.28-0.02'
          />
        </g>
        <g id='SPLINE_00000048492486596627119230000007492843342894776477_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1010.41,117.12c0.02,0,0.04-0.01,0.06-0.01c0.02,0,0.04-0.01,0.06-0.01c0.02,0,0.04-0.01,0.06-0.01s0.04-0.01,0.05-0.01 c0.02,0,0.03-0.01,0.05-0.01c0.01,0,0.02,0,0.02-0.01c0.01,0,0.01,0,0.02-0.01'
          />
        </g>
        <g id='SPLINE_00000034784854982811649520000015583458227031288481_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1010.83,117.01c-0.03,0.01-0.07,0.03-0.14,0.05'
          />
        </g>
        <g id='SPLINE_00000166672394414635972390000007576696455324127920_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1002.24,102.37c-0.04-0.01-0.06-0.01-0.06-0.01'
          />
        </g>
        <g id='SPLINE_00000181056612040402656570000011906967426790629299_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1017.77,102.37c0.04-0.01,0.06-0.01,0.06-0.01'
          />
        </g>
        <g id='SPLINE_00000021834420215386037940000011338311274384591254_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.28,105.72c0.11-0.12,0.25-0.22,0.39-0.29c0.15-0.07,0.31-0.12,0.47-0.13c0.08-0.01,0.16-0.01,0.24,0 c0.08,0.01,0.16,0.02,0.24,0.04c0.04,0.01,0.08,0.02,0.12,0.03c0.04,0.01,0.08,0.03,0.11,0.04c0.04,0.02,0.07,0.03,0.11,0.05 c0.03,0.02,0.07,0.04,0.1,0.06c0.03,0.02,0.07,0.05,0.1,0.07c0.03,0.02,0.06,0.05,0.09,0.08c0.03,0.03,0.06,0.06,0.08,0.09 c0.03,0.03,0.05,0.06,0.07,0.09s0.05,0.07,0.07,0.1c0.02,0.03,0.04,0.07,0.06,0.1s0.03,0.07,0.05,0.11 c0.01,0.04,0.03,0.07,0.04,0.11c0.01,0.04,0.02,0.08,0.03,0.12c0.01,0.04,0.01,0.08,0.02,0.12c0.01,0.08,0.01,0.16,0.01,0.24 c-0.01,0.16-0.05,0.32-0.11,0.47c-0.07,0.15-0.16,0.29-0.27,0.41'
          />
        </g>
        <g id='SPLINE_00000025406458543477746410000011653225546786128047_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1011.29,107.64c-0.11,0.12-0.25,0.22-0.39,0.29c-0.15,0.07-0.3,0.12-0.46,0.14c-0.16,0.02-0.32,0.01-0.48-0.03 c-0.16-0.04-0.31-0.11-0.44-0.2s-0.25-0.2-0.35-0.34c-0.1-0.13-0.17-0.28-0.21-0.43s-0.06-0.32-0.05-0.48 c0.01-0.08,0.02-0.16,0.04-0.24s0.05-0.15,0.08-0.23c0.06-0.15,0.16-0.28,0.27-0.4'
          />
        </g>
        <g id='LINE_00000050627247441907323720000009765642588764158867_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1009.24}
            y1={114.26}
            x2={1010.74}
            y2={114.23}
          />
        </g>
        <g id='LWPOLYLINE_00000127044514301679583690000003142955337668467883_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1009.35,84.55 1009.35,84.55 1009.36,84.55 1009.36,84.55 1009.36,84.55 1009.37,84.56 1009.37,84.56 1009.38,84.56  1009.38,84.56 1009.39,84.56 1009.39,84.56 1009.4,84.56 1009.4,84.56 1009.4,84.57 1009.41,84.57 1009.41,84.57 1009.41,84.57  1009.42,84.58 1009.42,84.58 1009.43,84.58 1009.43,84.59 1009.43,84.59 1009.44,84.59 1009.44,84.6 1009.44,84.6 1009.44,84.6  1009.45,84.61 1009.45,84.61 1009.45,84.62 1009.45,84.62 1009.45,84.62 1009.46,84.63 1009.46,84.63 1009.46,84.64  1009.46,84.64 1009.46,84.65 1009.46,84.65 1009.46,84.65 1009.46,84.66 1009.46,84.66 1009.46,84.66  '
          />
        </g>
        <g id='LWPOLYLINE_00000146476548689797003350000013393992063944755378_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1009.48,85.31 1009.48,85.26 1009.48,85.22 1009.47,85.18 1009.47,85.14 1009.47,85.1 1009.47,85.07 1009.47,85.03 1009.47,85  1009.47,84.96 1009.47,84.93 1009.47,84.9 1009.47,84.87 1009.47,84.85 1009.47,84.82 1009.47,84.8 1009.47,84.78 1009.47,84.76  1009.46,84.74 1009.46,84.72 1009.46,84.71 1009.46,84.7 1009.46,84.69 1009.46,84.68 1009.46,84.67 1009.46,84.67 1009.46,84.67  1009.46,84.66  '
          />
        </g>
        <g id='LINE_00000070815147458518582320000002422582663306419117_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1009.46}
            y1={84.66}
            x2={1009.46}
            y2={84.67}
          />
        </g>
        <g id='LWPOLYLINE_00000101062748160478557090000000770622042299127174_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1009.24,84.67 1009.24,84.67 1009.24,84.66 1009.24,84.66 1009.24,84.65 1009.24,84.65 1009.24,84.64 1009.24,84.64  1009.24,84.63 1009.24,84.63 1009.24,84.63 1009.24,84.62 1009.25,84.62 1009.25,84.61 1009.25,84.61 1009.25,84.61 1009.26,84.6  1009.26,84.6 1009.26,84.59 1009.26,84.59 1009.27,84.59 1009.27,84.58 1009.27,84.58 1009.28,84.58 1009.28,84.58 1009.29,84.57  1009.29,84.57 1009.29,84.57 1009.3,84.57 1009.3,84.56 1009.31,84.56 1009.31,84.56 1009.31,84.56 1009.32,84.56 1009.32,84.56  1009.33,84.56 1009.33,84.55 1009.34,84.55 1009.34,84.55 1009.35,84.55 1009.35,84.55  '
          />
        </g>
        <g id='LINE_00000090986821009008397620000017243030698422017434_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1009.24}
            y1={84.67}
            x2={1009.24}
            y2={84.67}
          />
        </g>
        <g id='LWPOLYLINE_00000170278750739992472770000014966249692921703597_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1009.24,84.67 1009.24,84.67 1009.24,84.67 1009.24,84.68 1009.24,84.69 1009.24,84.69 1009.24,84.71 1009.24,84.72  1009.24,84.73 1009.24,84.75 1009.24,84.77 1009.24,84.79 1009.24,84.81 1009.24,84.83 1009.24,84.86 1009.24,84.88  1009.24,84.91 1009.24,84.94 1009.24,84.97 1009.24,85.01 1009.24,85.04 1009.24,85.08 1009.25,85.11 1009.25,85.15  1009.25,85.19 1009.25,85.23 1009.25,85.28 1009.25,85.31  '
          />
        </g>
        <g id='LWPOLYLINE_00000178897815985322554070000011669992800518467514_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1009.5,86.42 1009.5,86.38 1009.5,86.33 1009.5,86.29 1009.5,86.25 1009.5,86.2 1009.5,86.16 1009.5,86.11 1009.5,86.07  1009.49,86.02 1009.49,85.98 1009.49,85.93 1009.49,85.88 1009.49,85.84 1009.49,85.79 1009.49,85.74 1009.49,85.7 1009.49,85.65  1009.48,85.6 1009.48,85.56 1009.48,85.51 1009.48,85.47 1009.48,85.43 1009.48,85.38 1009.48,85.34 1009.48,85.31  '
          />
        </g>
        <g id='LWPOLYLINE_00000130614359243462519600000009218741588745652645_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1009.25,85.31 1009.25,85.35 1009.25,85.4 1009.25,85.44 1009.25,85.48 1009.25,85.53 1009.26,85.57 1009.26,85.62 1009.26,85.66  1009.26,85.71 1009.26,85.76 1009.26,85.8 1009.26,85.85 1009.26,85.9 1009.26,85.94 1009.27,85.99 1009.27,86.04 1009.27,86.08  1009.27,86.13 1009.27,86.17 1009.27,86.22 1009.27,86.26 1009.27,86.3 1009.27,86.35 1009.27,86.39 1009.28,86.42  '
          />
        </g>
        <g id='ELLIPSE_00000053546631156223870580000011090173434922631079_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.45,84.61c-0.02-0.03-0.06-0.06-0.1-0.05'
          />
        </g>
        <g id='ELLIPSE_00000127033865452413430970000014278055891915497871_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.43,84.75c0.01-0.01,0.01-0.02,0.02-0.02'
          />
        </g>
        <g id='ELLIPSE_00000150808271882941997900000003636159099036340868_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.35,84.55c-0.04,0-0.08,0.02-0.1,0.06'
          />
        </g>
        <g id='ELLIPSE_00000145026476247273848050000018136330609081895303_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.29,86.45c0.02,0.02,0.06,0.03,0.1,0.02'
          />
        </g>
        <g id='ELLIPSE_00000177469100107693261820000002857327742748509074_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.28,86.41c-0.01,0.01,0,0.02,0,0.03'
          />
        </g>
        <g id='ELLIPSE_00000056392095652885934310000017639117163390172567_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.39,86.37c-0.02,0-0.04,0-0.06,0.01'
          />
        </g>
        <g id='ELLIPSE_00000088103324067295085730000016539115902101671325_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.45,86.37c-0.02,0-0.04-0.01-0.06-0.01'
          />
        </g>
        <g id='ELLIPSE_00000140702941950381533390000014402930827863790248_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.5,86.43c0-0.01,0-0.02,0-0.03'
          />
        </g>
        <g id='ELLIPSE_00000041267144188812788020000011407778100493890203_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1009.39,86.48c0.04,0,0.08-0.01,0.1-0.03'
          />
        </g>
        <g id='LINE_00000142878193563093682110000005250300936623725979_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={999.74}
            y1={83.56}
            x2={999.76}
            y2={84.44}
          />
        </g>
        <g id='LINE_00000135654503399118596490000000032348380249972388_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={999.76}
            y1={84.44}
            x2={1019.2}
            y2={84.44}
          />
        </g>
        <g id='LINE_00000012449215023656887530000011759960261334897061_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1019.2}
            y1={84.44}
            x2={1019.18}
            y2={83.56}
          />
        </g>
        <g id='LINE_00000181784331295614747780000016867158365262946179_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={984.99}
            y1={679.79}
            x2={979.97}
            y2={675.01}
          />
        </g>
        <g id='LINE_00000010997863842390513570000011050163605638239669_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={966.22}
            y1={688.75}
            x2={971.24}
            y2={693.55}
          />
        </g>
        <g id='ARC_00000005947527424639201560000005305144583112630702_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M971.24,693.55c0.21,0.2,0.5,0.31,0.79,0.31'
          />
        </g>
        <g id='LINE_00000178883703245839752500000016007484671673427637_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={972.04}
            y1={693.85}
            x2={979.27}
            y2={693.69}
          />
        </g>
        <g id='ARC_00000126283298732785698580000009454444504667427768_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M979.27,693.69c0.29-0.01,0.57-0.13,0.78-0.34'
          />
        </g>
        <g id='LINE_00000065788333382470264510000012340098028173658241_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={980.05}
            y1={693.34}
            x2={985.18}
            y2={687.97}
          />
        </g>
        <g id='ARC_00000153681701721408063110000000690190381254364350_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M985.18,687.97c0.2-0.21,0.31-0.5,0.31-0.79'
          />
        </g>
        <g id='LINE_00000026881125384994276780000007599742413026992289_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={985.48}
            y1={687.18}
            x2={985.33}
            y2={680.57}
          />
        </g>
        <g id='ARC_00000013153338501382122180000009992598934650355635_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M985.33,680.57c-0.01-0.29-0.13-0.57-0.34-0.78'
          />
        </g>
        <g id='SPLINE_00000178168842687990574520000015846767036923052974_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M974.59,683.41c0.2-0.21,0.42-0.42,0.64-0.62c0.22-0.2,0.45-0.39,0.69-0.57s0.48-0.35,0.73-0.51c0.25-0.16,0.51-0.31,0.78-0.44 c0.27-0.13,0.54-0.26,0.82-0.36c0.28-0.1,0.57-0.19,0.86-0.25s0.59-0.11,0.89-0.11c0.15,0,0.3,0,0.45,0.01s0.3,0.03,0.44,0.07 c0.15,0.03,0.29,0.08,0.43,0.13c0.07,0.03,0.14,0.06,0.2,0.09c0.07,0.03,0.13,0.07,0.19,0.11c0.06,0.04,0.12,0.08,0.18,0.13 c0.06,0.05,0.12,0.1,0.17,0.15c0.05,0.05,0.11,0.11,0.15,0.16c0.05,0.06,0.09,0.12,0.14,0.18c0.04,0.06,0.08,0.12,0.12,0.19 c0.04,0.06,0.07,0.13,0.1,0.2c0.06,0.14,0.11,0.28,0.15,0.42c0.04,0.14,0.07,0.29,0.09,0.44c0.02,0.15,0.03,0.3,0.03,0.45 c0,0.3-0.02,0.6-0.07,0.89s-0.12,0.58-0.21,0.87c-0.09,0.28-0.2,0.56-0.32,0.84c-0.12,0.27-0.26,0.54-0.41,0.8 c-0.15,0.26-0.31,0.51-0.48,0.76c-0.17,0.25-0.35,0.48-0.54,0.72s-0.39,0.46-0.59,0.67'
          />
        </g>
        <g id='SPLINE_00000023960684123398029520000009561610672078817967_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M980.22,688.79c-0.21,0.21-0.42,0.42-0.64,0.62c-0.22,0.2-0.45,0.39-0.69,0.57c-0.24,0.18-0.48,0.35-0.73,0.51 c-0.25,0.16-0.51,0.31-0.78,0.44c-0.27,0.14-0.54,0.26-0.82,0.36c-0.28,0.1-0.57,0.19-0.86,0.25c-0.29,0.06-0.59,0.1-0.89,0.11 c-0.15,0-0.3,0-0.45-0.01s-0.29-0.03-0.44-0.07s-0.29-0.08-0.43-0.13c-0.07-0.03-0.14-0.06-0.2-0.09 c-0.07-0.03-0.13-0.07-0.19-0.11c-0.06-0.04-0.12-0.08-0.18-0.13c-0.06-0.05-0.12-0.1-0.17-0.15c-0.05-0.05-0.11-0.11-0.15-0.16 c-0.05-0.06-0.09-0.12-0.14-0.18c-0.04-0.06-0.08-0.12-0.12-0.19c-0.04-0.06-0.07-0.13-0.1-0.2c-0.06-0.14-0.11-0.28-0.15-0.42 c-0.04-0.14-0.07-0.29-0.09-0.44c-0.02-0.15-0.03-0.3-0.03-0.45c0-0.3,0.02-0.6,0.07-0.89c0.05-0.29,0.12-0.58,0.21-0.87 c0.09-0.28,0.2-0.56,0.32-0.83s0.26-0.54,0.41-0.8c0.15-0.26,0.31-0.51,0.48-0.76c0.17-0.25,0.35-0.48,0.54-0.72 s0.39-0.46,0.59-0.67'
          />
        </g>
        <g id='LWPOLYLINE_00000113324788370207965990000009231426032975427238_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 980.61,699.72 981.06,700.21 981.54,700.68 982.03,701.14 982.54,701.57 983.07,701.98 983.61,702.38 984.17,702.75  984.74,703.09 985.33,703.42 985.92,703.72 986.53,703.99 987.16,704.25 987.79,704.47 988.43,704.67 989.07,704.85 989.72,705  990.38,705.12 991.05,705.22 991.71,705.29 992.38,705.33 993.05,705.35 993.72,705.34 994.39,705.3 995.06,705.24 995.21,705.22  '
          />
        </g>
        <g id='LWPOLYLINE_00000074426091415739573330000007345050427583383702_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 979.57,693.97 979.52,694.32 979.49,694.67 979.47,695.03 979.46,695.39 979.47,695.74 979.49,696.1 979.53,696.45 979.58,696.81  979.65,697.16 979.73,697.51 979.82,697.85 979.93,698.19 980.05,698.53 980.18,698.86 980.33,699.18 980.49,699.5 980.61,699.72  '
          />
        </g>
        <g id='LWPOLYLINE_00000170957378784789948800000015391051577779107237_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 991.67,688.65 991.36,688.48 991.04,688.33 990.71,688.18 990.38,688.05 990.04,687.94 989.7,687.84 989.35,687.75 989.01,687.67  988.65,687.61 988.3,687.56 987.94,687.53 987.59,687.51 987.23,687.51 986.88,687.52 986.52,687.54 986.16,687.58 985.81,687.63  985.57,687.67  '
          />
        </g>
        <g id='LWPOLYLINE_00000043446218656866124700000006443431296155971992_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 997.18,703.26 997.25,702.59 997.29,701.92 997.3,701.25 997.29,700.58 997.26,699.91 997.19,699.25 997.1,698.58 996.99,697.92  996.84,697.27 996.67,696.62 996.48,695.98 996.26,695.35 996.01,694.73 995.74,694.11 995.45,693.51 995.13,692.92  994.79,692.35 994.42,691.78 994.03,691.24 993.63,690.71 993.2,690.19 992.75,689.69 992.28,689.22 991.79,688.76 991.67,688.65  '
          />
        </g>
        <g id='LINE_00000035496005846706098460000017351215001620492678_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={997.09}
            y1={703.93}
            x2={997.18}
            y2={703.26}
          />
        </g>
        <g id='LINE_00000173865260734885182100000006124688710707707028_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={995.21}
            y1={705.22}
            x2={995.89}
            y2={705.14}
          />
        </g>
        <g id='LWPOLYLINE_00000142881960099947518760000000032922348494142903_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 984.91,692.46 984.79,692.59 984.67,692.73 984.56,692.87 984.47,692.99  '
          />
        </g>
        <g id='LWPOLYLINE_00000178205050048082571990000007769861242488958884_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 984.47,692.99 984.33,693.1 984.2,693.22 984.07,693.35 983.96,693.45  '
          />
        </g>
        <g id='LINE_00000162345151980312556100000001999065659094109845_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={985.55}
            y1={687.58}
            x2={985.18}
            y2={687.97}
          />
        </g>
        <g id='LINE_00000129889248161480204000000010721026383232471709_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={980.05}
            y1={693.34}
            x2={979.47}
            y2={693.95}
          />
        </g>
        <g id='LINE_00000099644124911662346660000001939768974880021925_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={979.47}
            y1={693.95}
            x2={979.62}
            y2={693.97}
          />
        </g>
        <g id='ARC_00000085933838324272090640000001329021201858368912_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M979.62,693.97c-0.31,1.96,0.06,3.98,1.03,5.71'
          />
        </g>
        <g id='ARC_00000029041880558863692440000000125002982171007402_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M980.65,699.68c3.65,4.11,9.09,6.16,14.55,5.48'
          />
        </g>
        <g id='LINE_00000096763142036222216870000012294172180276424623_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={995.21}
            y1={705.16}
            x2={996.05}
            y2={705.06}
          />
        </g>
        <g id='ARC_00000075851142503464409510000017921287999091604134_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M996.05,705.06c0.41-0.22,0.75-0.56,0.97-0.97'
          />
        </g>
        <g id='LINE_00000109014168470990112830000005367172868344540063_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={997.01}
            y1={704.09}
            x2={997.12}
            y2={703.25}
          />
        </g>
        <g id='ARC_00000124863523669917531360000010920325214583269055_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M997.12,703.25c0.68-5.46-1.37-10.9-5.48-14.55'
          />
        </g>
        <g id='ARC_00000138559546526015963080000000753811166037957266_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M991.64,688.7c-1.84-1.03-3.98-1.38-6.05-0.97'
          />
        </g>
        <g id='LINE_00000139974127291017517340000012378081342750469823_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={985.59}
            y1={687.73}
            x2={985.55}
            y2={687.58}
          />
        </g>
        <g id='ARC_00000060022101658263661750000011878608081154978196_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M991.16,692.22c-1.85-1.66-4.69-1.5-6.34,0.34c-0.12,0.14-0.24,0.28-0.34,0.43'
          />
        </g>
        <g id='ARC_00000075121259577772974870000011804242341138993295_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M995.05,702.05c0.32-3.71-1.12-7.35-3.89-9.83'
          />
        </g>
        <g id='ARC_00000087409342876445998140000010152708322595243421_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M994.76,702.81c0.19-0.2,0.3-0.47,0.29-0.75'
          />
        </g>
        <g id='ARC_00000183933240056484786740000002201819279100206991_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M994.02,703.14c0.28-0.01,0.55-0.12,0.74-0.33'
          />
        </g>
        <g id='ARC_00000181803218186539079840000015646124976284336304_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M984.01,699.71c2.61,2.65,6.32,3.92,10,3.43'
          />
        </g>
        <g id='ARC_00000177480082955912622900000003950667197179604383_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M984.47,692.99c-1.97,1.51-2.33,4.33-0.82,6.3c0.11,0.14,0.23,0.28,0.36,0.41'
          />
        </g>
        <g id='SPLINE_00000103954163315758096810000008797823989960843967_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M996.05,705.06c-0.06,0.03-0.11,0.05-0.13,0.07c-0.01,0.01-0.02,0.01-0.02,0.01c0,0,0,0,0,0c0,0,0,0,0.01,0'
          />
        </g>
        <g id='SPLINE_00000021824089310427345620000000479812189942802596_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M996.01,705.08c0.01,0,0.01-0.01,0.02-0.01c0.01,0,0.01-0.01,0.02-0.01c0.01-0.01,0.03-0.02,0.04-0.02 c0.02-0.01,0.03-0.02,0.05-0.03s0.03-0.02,0.05-0.03c0.02-0.01,0.03-0.02,0.05-0.03c0.02-0.01,0.03-0.02,0.05-0.04'
          />
        </g>
        <g id='SPLINE_00000048494636886905601780000012848789777999384975_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M996.4,704.83c0.07-0.05,0.14-0.12,0.21-0.18'
          />
        </g>
        <g id='SPLINE_00000027595325079408165930000004437622035400361915_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M996.6,704.65c0.07-0.07,0.13-0.14,0.18-0.21'
          />
        </g>
        <g id='SPLINE_00000053532674602605257520000015907174146548534427_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M996.86,704.34c0.01-0.02,0.02-0.03,0.04-0.05s0.02-0.03,0.03-0.05'
          />
        </g>
        <g id='SPLINE_00000070828214034581317950000004786261067632606606_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M996.94,704.21c0.01-0.01,0.01-0.02,0.02-0.03c0.01-0.02,0.02-0.03,0.03-0.05c0.01-0.02,0.02-0.03,0.02-0.04 c0-0.01,0.01-0.01,0.01-0.02s0.01-0.01,0.01-0.02'
          />
        </g>
        <g id='SPLINE_00000016764538917509581000000016821423416784667582_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M997.09,703.94C997.09,703.93,997.09,703.93,997.09,703.94c0,0,0,0-0.01,0.02c-0.01,0.03-0.03,0.07-0.07,0.13'
          />
        </g>
        <g id='SPLINE_00000168830484093580499460000014094049574202882231_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M980.65,699.68c-0.03,0.02-0.05,0.03-0.05,0.03'
          />
        </g>
        <g id='SPLINE_00000142147589954100765030000007997990261741205132_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M991.64,688.7c0.02-0.03,0.03-0.05,0.03-0.05'
          />
        </g>
        <g id='SPLINE_00000178920679304663571100000007680587830030111873_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M988,697.07c0-0.16,0.02-0.33,0.07-0.48c0.05-0.15,0.13-0.3,0.24-0.43c0.05-0.06,0.11-0.12,0.17-0.18c0.06-0.05,0.13-0.1,0.2-0.14 c0.03-0.02,0.07-0.04,0.11-0.06c0.04-0.02,0.07-0.03,0.11-0.05c0.04-0.01,0.08-0.03,0.11-0.04c0.04-0.01,0.08-0.02,0.12-0.03 c0.04-0.01,0.08-0.01,0.12-0.02c0.04,0,0.08-0.01,0.12-0.01c0.04,0,0.08,0,0.12,0c0.04,0,0.08,0.01,0.12,0.01s0.08,0.01,0.12,0.02 c0.04,0.01,0.08,0.02,0.11,0.03s0.07,0.03,0.11,0.04c0.04,0.02,0.07,0.03,0.11,0.05c0.04,0.02,0.07,0.04,0.1,0.06 c0.03,0.02,0.07,0.05,0.1,0.07c0.06,0.05,0.12,0.11,0.18,0.17c0.11,0.12,0.2,0.26,0.26,0.41c0.06,0.15,0.09,0.31,0.1,0.48'
          />
        </g>
        <g id='SPLINE_00000044141822687211251070000003172878340165677186_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M990.78,697.01c0,0.16-0.02,0.33-0.07,0.48c-0.05,0.15-0.13,0.3-0.23,0.42c-0.1,0.13-0.22,0.23-0.36,0.32 c-0.14,0.08-0.29,0.14-0.45,0.17c-0.16,0.03-0.32,0.03-0.48,0.01c-0.16-0.03-0.32-0.08-0.46-0.16c-0.14-0.08-0.27-0.18-0.37-0.3 c-0.05-0.06-0.1-0.13-0.14-0.19c-0.04-0.07-0.08-0.14-0.11-0.22c-0.06-0.15-0.09-0.31-0.09-0.47'
          />
        </g>
        <g id='LINE_00000040559790992340809790000000585962022299469193_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={994.02}
            y1={703.14}
            x2={995.05}
            y2={702.05}
          />
        </g>
        <g id='LWPOLYLINE_00000079444698266248521080000013430314588803039362_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 973.08,682.06 973.09,682.05 973.09,682.05 973.09,682.05 973.1,682.04 973.1,682.04 973.1,682.04 973.11,682.04 973.11,682.03  973.12,682.03 973.12,682.03 973.12,682.03 973.13,682.03 973.13,682.02 973.14,682.02 973.14,682.02 973.15,682.02  973.15,682.02 973.15,682.02 973.16,682.02 973.16,682.02 973.17,682.02 973.17,682.02 973.18,682.02 973.18,682.02  973.19,682.02 973.19,682.02 973.2,682.02 973.2,682.03 973.2,682.03 973.21,682.03 973.21,682.03 973.22,682.03 973.22,682.03  973.22,682.04 973.23,682.04 973.23,682.04 973.24,682.04 973.24,682.05 973.24,682.05 973.24,682.05  '
          />
        </g>
        <g id='LWPOLYLINE_00000072971001994856762520000013567689378293091721_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 973.71,682.5 973.68,682.47 973.65,682.44 973.62,682.41 973.59,682.38 973.56,682.36 973.53,682.33 973.51,682.31 973.48,682.28  973.46,682.26 973.44,682.24 973.42,682.22 973.4,682.2 973.38,682.18 973.36,682.16 973.34,682.15 973.33,682.13 973.31,682.12  973.3,682.1 973.29,682.09 973.28,682.08 973.27,682.07 973.26,682.07 973.25,682.06 973.25,682.06 973.25,682.05 973.24,682.05  973.24,682.05  '
          />
        </g>
        <g id='LINE_00000047782228459207600900000013764477167618768019_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={973.24}
            y1={682.05}
            x2={973.24}
            y2={682.05}
          />
        </g>
        <g id='LWPOLYLINE_00000145746832776482925890000003277372500276601739_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 973.09,682.22 973.08,682.21 973.08,682.21 973.08,682.21 973.07,682.2 973.07,682.2 973.07,682.2 973.07,682.19 973.06,682.19  973.06,682.18 973.06,682.18 973.06,682.18 973.06,682.17 973.06,682.17 973.05,682.16 973.05,682.16 973.05,682.15  973.05,682.15 973.05,682.14 973.05,682.14 973.05,682.14 973.05,682.13 973.05,682.13 973.05,682.12 973.05,682.12  973.05,682.11 973.05,682.11 973.06,682.1 973.06,682.1 973.06,682.09 973.06,682.09 973.06,682.09 973.06,682.08 973.07,682.08  973.07,682.07 973.07,682.07 973.07,682.07 973.08,682.06 973.08,682.06 973.08,682.06 973.08,682.06  '
          />
        </g>
        <g id='LINE_00000147939481777623952680000016713750814379058050_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={973.09}
            y1={682.22}
            x2={973.09}
            y2={682.22}
          />
        </g>
        <g id='LWPOLYLINE_00000041990773768113636550000016093019013345782157_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 973.09,682.22 973.09,682.22 973.09,682.22 973.09,682.22 973.1,682.23 973.1,682.23 973.11,682.24 973.12,682.25 973.13,682.26  973.14,682.27 973.16,682.28 973.17,682.3 973.19,682.31 973.2,682.33 973.22,682.35 973.24,682.36 973.26,682.38 973.28,682.41  973.31,682.43 973.33,682.45 973.36,682.47 973.38,682.5 973.41,682.52 973.44,682.55 973.46,682.58 973.49,682.61 973.52,682.64  973.55,682.66  '
          />
        </g>
        <g id='LWPOLYLINE_00000053518106791735708550000012433042184428445106_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 974.51,683.26 974.48,683.23 974.45,683.21 974.42,683.18 974.39,683.15 974.36,683.11 974.32,683.08 974.29,683.05  974.26,683.02 974.23,682.99 974.19,682.96 974.16,682.92 974.12,682.89 974.09,682.86 974.06,682.83 974.02,682.8 973.99,682.76  973.96,682.73 973.92,682.7 973.89,682.67 973.86,682.64 973.83,682.61 973.79,682.58 973.76,682.55 973.73,682.52 973.71,682.5  '
          />
        </g>
        <g id='LWPOLYLINE_00000158734022855245871910000002631763814908390578_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 973.55,682.66 973.58,682.69 973.61,682.72 973.64,682.75 973.67,682.78 973.71,682.81 973.74,682.84 973.77,682.87 973.8,682.9  973.84,682.93 973.87,682.97 973.91,683 973.94,683.03 973.97,683.06 974.01,683.1 974.04,683.13 974.07,683.16 974.11,683.19  974.14,683.22 974.17,683.25 974.21,683.29 974.24,683.32 974.27,683.35 974.3,683.38 974.33,683.4 974.36,683.43  '
          />
        </g>
        <g id='ELLIPSE_00000092442594505275107310000017579665645599656074_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M973.19,682.02c-0.04-0.01-0.08,0-0.11,0.03'
          />
        </g>
        <g id='ELLIPSE_00000144327404740703521660000002311852680632755634_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M973.28,682.13c0-0.01,0-0.02,0-0.03'
          />
        </g>
        <g id='ELLIPSE_00000036971219514949953680000004023200504695770541_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M973.08,682.06c-0.03,0.03-0.04,0.07-0.03,0.11'
          />
        </g>
        <g id='ELLIPSE_00000084489916900851599640000014690106023332049580_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M974.39,683.44c0.03,0,0.06-0.02,0.09-0.05'
          />
        </g>
        <g id='ELLIPSE_00000168805463143356430410000007180183020859298705_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M974.35,683.42c0,0.01,0.01,0.02,0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000124139600296209301110000017200948545059780505_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M974.39,683.31c-0.01,0.01-0.03,0.03-0.03,0.05'
          />
        </g>
        <g id='ELLIPSE_00000096020885090160925860000010981824613416659611_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M974.44,683.27c-0.02,0.01-0.03,0.02-0.04,0.04'
          />
        </g>
        <g id='ELLIPSE_00000085250139144365689010000002407960281424229555_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M974.52,683.28c0-0.01-0.01-0.02-0.02-0.02'
          />
        </g>
        <g id='ELLIPSE_00000038414658898199615700000005400140066348281763_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M974.47,683.38c0.03-0.03,0.05-0.06,0.05-0.09'
          />
        </g>
        <g id='LINE_00000019637015622360089450000010653208052171966143_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={965.59}
            y1={688.14}
            x2={966.22}
            y2={688.75}
          />
        </g>
        <g id='LINE_00000158013168023989970210000003864752169396830625_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={966.22}
            y1={688.75}
            x2={979.97}
            y2={675.01}
          />
        </g>
        <g id='LINE_00000168839857756719650990000014129637839999497381_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={979.97}
            y1={675.01}
            x2={979.33}
            y2={674.4}
          />
        </g>
        <g id='LINE_00000038412500834546704080000008152562604427973802_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={996.37}
            y1={333.05}
            x2={996.53}
            y2={339.98}
          />
        </g>
        <g id='LINE_00000051358865008248749730000002873806839492567681_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1015.97}
            y1={339.98}
            x2={1015.81}
            y2={333.04}
          />
        </g>
        <g id='ARC_00000109007258500430894000000007433719007320144518_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1015.81,333.04c-0.01-0.29-0.13-0.57-0.34-0.78'
          />
        </g>
        <g id='LINE_00000161612980786669654890000016637403720375901370_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1015.47}
            y1={332.26}
            x2={1010.24}
            y2={327.27}
          />
        </g>
        <g id='ARC_00000165949050191895300760000004972434820410839944_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1010.24,327.27c-0.21-0.2-0.5-0.31-0.79-0.31'
          />
        </g>
        <g id='LINE_00000011032677464154254580000008423327630870901951_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1009.44}
            y1={326.96}
            x2={1002.02}
            y2={327.13}
          />
        </g>
        <g id='ARC_00000070103572325396562770000008234988006338457277_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1002.02,327.13c-0.29,0.01-0.57,0.13-0.78,0.34'
          />
        </g>
        <g id='LINE_00000042731215296020079720000007954127292321614746_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1001.24}
            y1={327.47}
            x2={996.68}
            y2={332.25}
          />
        </g>
        <g id='ARC_00000062885935496905671220000013203238234009467314_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M996.68,332.25c-0.2,0.21-0.31,0.5-0.31,0.79'
          />
        </g>
        <g id='SPLINE_00000016785030921801339540000014368913556412328066_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.28,337.84c-0.3,0.01-0.6,0-0.89-0.01s-0.6-0.04-0.89-0.08c-0.3-0.04-0.59-0.09-0.88-0.16s-0.58-0.14-0.86-0.24 c-0.28-0.09-0.56-0.2-0.83-0.32c-0.27-0.12-0.53-0.26-0.79-0.43c-0.25-0.16-0.49-0.34-0.71-0.55c-0.11-0.1-0.21-0.21-0.31-0.33 c-0.1-0.11-0.18-0.23-0.26-0.36c-0.08-0.13-0.15-0.26-0.21-0.4c-0.03-0.07-0.06-0.14-0.08-0.21s-0.04-0.14-0.06-0.21 c-0.02-0.07-0.03-0.15-0.04-0.22c-0.01-0.07-0.01-0.15-0.02-0.22s0-0.15,0.01-0.22c0.01-0.07,0.02-0.15,0.03-0.22 c0.01-0.07,0.03-0.14,0.05-0.22s0.04-0.14,0.07-0.21c0.05-0.14,0.12-0.28,0.19-0.41c0.07-0.13,0.16-0.25,0.25-0.37 s0.19-0.23,0.29-0.34c0.21-0.21,0.44-0.41,0.68-0.58s0.5-0.33,0.77-0.46s0.54-0.26,0.82-0.36s0.56-0.2,0.85-0.28 c0.29-0.08,0.58-0.14,0.87-0.2s0.59-0.1,0.89-0.12s0.6-0.05,0.89-0.06'
          />
        </g>
        <g id='SPLINE_00000175294195822590057590000017866138393667392908_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.1,330.06c0.3-0.01,0.6,0,0.9,0.01c0.3,0.02,0.6,0.04,0.89,0.08c0.3,0.04,0.59,0.09,0.88,0.16c0.29,0.06,0.58,0.14,0.86,0.24 c0.28,0.09,0.56,0.2,0.84,0.33s0.54,0.27,0.79,0.43c0.25,0.16,0.49,0.34,0.71,0.55c0.11,0.1,0.21,0.21,0.31,0.32 c0.1,0.11,0.18,0.23,0.26,0.36c0.08,0.13,0.15,0.26,0.21,0.4c0.03,0.07,0.06,0.14,0.08,0.21c0.02,0.07,0.04,0.14,0.06,0.21 c0.02,0.07,0.03,0.15,0.04,0.22c0.01,0.07,0.01,0.15,0.02,0.22c0,0.08,0,0.15-0.01,0.22c-0.01,0.07-0.02,0.15-0.03,0.22 c-0.01,0.07-0.03,0.14-0.05,0.22s-0.04,0.14-0.07,0.21c-0.05,0.14-0.12,0.28-0.19,0.41s-0.16,0.25-0.25,0.37 c-0.09,0.12-0.19,0.23-0.29,0.34c-0.21,0.21-0.44,0.41-0.68,0.58s-0.5,0.33-0.77,0.46c-0.26,0.14-0.54,0.26-0.82,0.36 c-0.28,0.11-0.56,0.2-0.85,0.28c-0.29,0.08-0.58,0.14-0.87,0.2c-0.29,0.05-0.59,0.1-0.89,0.12c-0.3,0.03-0.6,0.05-0.89,0.06'
          />
        </g>
        <g id='LWPOLYLINE_00000026160859999569811300000011091909707237368501_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1013.55,322.06 1013.58,321.39 1013.58,320.72 1013.55,320.05 1013.5,319.38 1013.42,318.72 1013.31,318.05 1013.18,317.4  1013.02,316.75 1012.83,316.1 1012.62,315.47 1012.39,314.84 1012.13,314.22 1011.84,313.62 1011.53,313.02 1011.2,312.44  1010.84,311.88 1010.46,311.32 1010.06,310.79 1009.64,310.26 1009.2,309.76 1008.74,309.28 1008.26,308.81 1007.76,308.36  1007.24,307.94 1007.12,307.84  '
          />
        </g>
        <g id='LWPOLYLINE_00000032635364781956704660000016163224821437908111_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1010.22,326.86 1010.51,326.64 1010.78,326.42 1011.05,326.18 1011.3,325.93 1011.55,325.67 1011.78,325.4 1012.01,325.13  1012.22,324.84 1012.42,324.55 1012.61,324.24 1012.79,323.93 1012.96,323.62 1013.11,323.29 1013.25,322.97 1013.37,322.63  1013.48,322.29 1013.55,322.06  '
          />
        </g>
        <g id='LWPOLYLINE_00000106133990402875016680000007853829731214111421_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 997.91,322.06 998.01,322.4 998.13,322.74 998.26,323.07 998.4,323.39 998.55,323.72 998.72,324.03 998.9,324.34 999.1,324.64  999.3,324.93 999.52,325.21 999.75,325.49 999.99,325.75 1000.24,326.01 1000.49,326.25 1000.76,326.49 1001.04,326.71  1001.33,326.92 1001.53,327.06  '
          />
        </g>
        <g id='LWPOLYLINE_00000004541027315931190740000008323586959602644666_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1004.34,307.84 1003.82,308.26 1003.32,308.7 1002.83,309.16 1002.37,309.64 1001.92,310.14 1001.49,310.66 1001.09,311.19  1000.71,311.74 1000.34,312.31 1000.01,312.89 999.69,313.48 999.4,314.08 999.13,314.69 998.89,315.32 998.67,315.95  998.48,316.59 998.32,317.24 998.18,317.9 998.06,318.56 997.98,319.22 997.92,319.89 997.88,320.56 997.88,321.23 997.9,321.9  997.91,322.06  '
          />
        </g>
        <g id='LINE_00000087400501771292448610000001541950293074235277_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1004.88}
            y1={307.42}
            x2={1004.34}
            y2={307.84}
          />
        </g>
        <g id='LINE_00000016077608350663378020000003708941250290261154_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1007.12}
            y1={307.84}
            x2={1006.58}
            y2={307.42}
          />
        </g>
        <g id='LWPOLYLINE_00000085239173239826293610000015860799136086112420_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1005.38,324.15 1005.56,324.14 1005.74,324.12 1005.92,324.1 1006.06,324.08  '
          />
        </g>
        <g id='LWPOLYLINE_00000070839417013379889610000009269267910450782136_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1006.06,324.08 1006.24,324.1 1006.42,324.11 1006.6,324.11 1006.75,324.11  '
          />
        </g>
        <g id='LINE_00000165946908648357357710000002674183389750689941_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1001.47}
            y1={327.14}
            x2={1002.02}
            y2={327.13}
          />
        </g>
        <g id='LINE_00000062168178713071434250000002613136883699045002_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1009.44}
            y1={326.96}
            x2={1010.28}
            y2={326.94}
          />
        </g>
        <g id='LINE_00000078766942445298321650000010677938039550769822_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1010.28}
            y1={326.94}
            x2={1010.19}
            y2={326.81}
          />
        </g>
        <g id='ARC_00000056398155982035080970000015519284180416335785_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1010.19,326.81c1.61-1.17,2.77-2.85,3.31-4.77'
          />
        </g>
        <g id='ARC_00000116198111825586172970000000062272164229251005_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1013.5,322.05c0.33-5.49-2.07-10.79-6.41-14.16'
          />
        </g>
        <g id='LINE_00000072997744434368017510000017422559220174684321_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1007.08}
            y1={307.88}
            x2={1006.41}
            y2={307.36}
          />
        </g>
        <g id='ARC_00000132070372371607667940000007035568911026247833_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.41,307.36c-0.45-0.13-0.92-0.13-1.37,0'
          />
        </g>
        <g id='LINE_00000024002102821268387540000001599559772073550780_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1005.05}
            y1={307.36}
            x2={1004.38}
            y2={307.88}
          />
        </g>
        <g id='ARC_00000158712436925504875190000015836594504381340583_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1004.38,307.88c-4.34,3.38-6.74,8.67-6.41,14.16'
          />
        </g>
        <g id='ARC_00000119100931805377722760000001978240543981591460_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M997.96,322.05c0.57,2.03,1.84,3.79,3.6,4.97'
          />
        </g>
        <g id='LINE_00000057136632383622009880000004239671193033387933_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1001.56}
            y1={327.01}
            x2={1001.47}
            y2={327.14}
          />
        </g>
        <g id='ARC_00000097502820963218764180000017609821302976585614_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1000.79,319.9c0.13,2.48,2.25,4.38,4.73,4.24c0.18-0.01,0.36-0.03,0.54-0.06'
          />
        </g>
        <g id='ARC_00000101817323054765002660000012603499880834017433_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1005,310.19c-2.85,2.39-4.41,5.99-4.2,9.7'
          />
        </g>
        <g id='ARC_00000097473028923990003250000009754301291123142808_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1005.74,309.87c-0.28,0.01-0.55,0.12-0.74,0.33'
          />
        </g>
        <g id='ARC_00000127759404590996034830000006024404406649707904_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.49,310.16c-0.2-0.19-0.47-0.3-0.75-0.29'
          />
        </g>
        <g id='ARC_00000053518161694494772930000010700143750258389912_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1011.14,319.66c0.03-3.72-1.69-7.24-4.65-9.5'
          />
        </g>
        <g id='ARC_00000010294724428376675920000004444387575657394341_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.06,324.08c2.46,0.32,4.72-1.41,5.04-3.87c0.02-0.18,0.04-0.36,0.04-0.55'
          />
        </g>
        <g id='SPLINE_00000147200788074090533650000007882045590789767598_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.41,307.36c0.07,0.02,0.11,0.04,0.14,0.05'
          />
        </g>
        <g id='SPLINE_00000008134228311000263810000007674272191012109233_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.45,307.38c-0.01,0-0.01,0-0.02-0.01c-0.01,0-0.01,0-0.02-0.01c-0.02,0-0.03-0.01-0.05-0.01c-0.02,0-0.04-0.01-0.05-0.01 c-0.02,0-0.04-0.01-0.06-0.01c-0.02,0-0.04-0.01-0.06-0.01c-0.02,0-0.04-0.01-0.06-0.01'
          />
        </g>
        <g id='SPLINE_00000036229771342807083730000013023936752806229941_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.01,307.28c-0.09-0.01-0.18-0.02-0.28-0.02'
          />
        </g>
        <g id='SPLINE_00000042732809910543141080000000876625403152077190_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1005.73,307.26c-0.1,0-0.19,0.01-0.28,0.02'
          />
        </g>
        <g id='SPLINE_00000023243091479464096830000007721140630759263164_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1005.33,307.3c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.06,0.01 c-0.02,0-0.04,0.01-0.05,0.01c-0.02,0-0.03,0.01-0.05,0.01c-0.01,0-0.02,0-0.02,0.01c-0.01,0-0.01,0-0.02,0.01'
          />
        </g>
        <g id='SPLINE_00000161630230581371756420000004922539022063744666_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1004.91,307.41c0.03-0.01,0.07-0.03,0.14-0.05'
          />
        </g>
        <g id='SPLINE_00000096060686286762401440000014978163070127567284_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1013.5,322.05c0.04,0.01,0.06,0.01,0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000021812443588155700960000000121611518630575548_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M997.96,322.05c-0.04,0.01-0.06,0.01-0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000005262608550074888140000011952438162807857846_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.45,318.7c-0.11,0.12-0.25,0.22-0.39,0.29c-0.15,0.07-0.31,0.12-0.47,0.13c-0.08,0.01-0.16,0.01-0.24,0 c-0.08-0.01-0.16-0.02-0.24-0.04c-0.04-0.01-0.08-0.02-0.12-0.03c-0.04-0.01-0.08-0.03-0.11-0.04c-0.04-0.02-0.07-0.03-0.11-0.05 c-0.03-0.02-0.07-0.04-0.1-0.06c-0.03-0.02-0.07-0.05-0.1-0.07s-0.06-0.05-0.09-0.08c-0.03-0.03-0.06-0.06-0.08-0.09 c-0.03-0.03-0.05-0.06-0.07-0.09c-0.02-0.03-0.05-0.07-0.07-0.1c-0.02-0.03-0.04-0.07-0.06-0.1s-0.03-0.07-0.05-0.11 c-0.01-0.04-0.03-0.07-0.04-0.11c-0.01-0.04-0.02-0.08-0.03-0.12s-0.01-0.08-0.02-0.12c-0.01-0.08-0.01-0.16-0.01-0.24 c0.01-0.16,0.05-0.32,0.11-0.47c0.07-0.15,0.16-0.29,0.27-0.41'
          />
        </g>
        <g id='SPLINE_00000091725232739853484210000008815325688365983117_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1004.44,316.78c0.11-0.12,0.25-0.22,0.39-0.29c0.15-0.07,0.3-0.12,0.46-0.14c0.16-0.02,0.32-0.01,0.48,0.03 c0.16,0.04,0.31,0.11,0.44,0.2c0.13,0.09,0.25,0.2,0.35,0.34c0.1,0.13,0.17,0.28,0.21,0.43c0.04,0.16,0.06,0.32,0.05,0.48 c-0.01,0.08-0.02,0.16-0.04,0.24c-0.02,0.08-0.05,0.15-0.08,0.23c-0.06,0.15-0.16,0.28-0.27,0.4'
          />
        </g>
        <g id='LINE_00000095321344143433645180000006713673930453907591_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1006.49}
            y1={310.16}
            x2={1005}
            y2={310.19}
          />
        </g>
        <g id='LWPOLYLINE_00000061465511466263873700000013362867939048010426_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1006.39,339.87 1006.38,339.87 1006.38,339.87 1006.37,339.87 1006.37,339.87 1006.36,339.86 1006.36,339.86 1006.36,339.86  1006.35,339.86 1006.35,339.86 1006.34,339.86 1006.34,339.86 1006.33,339.85 1006.33,339.85 1006.33,339.85 1006.32,339.85  1006.32,339.85 1006.31,339.84 1006.31,339.84 1006.31,339.84 1006.3,339.83 1006.3,339.83 1006.3,339.83 1006.3,339.82  1006.29,339.82 1006.29,339.82 1006.29,339.81 1006.28,339.81 1006.28,339.8 1006.28,339.8 1006.28,339.8 1006.28,339.79  1006.28,339.79 1006.27,339.78 1006.27,339.78 1006.27,339.77 1006.27,339.77 1006.27,339.77 1006.27,339.76 1006.27,339.76  1006.27,339.75  '
          />
        </g>
        <g id='LWPOLYLINE_00000150817227383447448790000011115940611971467927_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1006.26,339.11 1006.26,339.16 1006.26,339.2 1006.26,339.24 1006.26,339.28 1006.26,339.32 1006.26,339.35 1006.26,339.39  1006.26,339.42 1006.26,339.46 1006.26,339.49 1006.26,339.52 1006.27,339.54 1006.27,339.57 1006.27,339.6 1006.27,339.62  1006.27,339.64 1006.27,339.66 1006.27,339.68 1006.27,339.69 1006.27,339.71 1006.27,339.72 1006.27,339.73 1006.27,339.74  1006.27,339.75 1006.27,339.75 1006.27,339.75 1006.27,339.75  '
          />
        </g>
        <g id='LINE_00000138544625014578870790000007260556816424474240_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1006.27}
            y1={339.75}
            x2={1006.27}
            y2={339.75}
          />
        </g>
        <g id='LWPOLYLINE_00000026140921661317891770000010172008719245037497_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1006.5,339.75 1006.5,339.75 1006.5,339.76 1006.5,339.76 1006.5,339.77 1006.5,339.77 1006.5,339.78 1006.49,339.78  1006.49,339.79 1006.49,339.79 1006.49,339.79 1006.49,339.8 1006.49,339.8 1006.48,339.81 1006.48,339.81 1006.48,339.81  1006.48,339.82 1006.47,339.82 1006.47,339.83 1006.47,339.83 1006.47,339.83 1006.46,339.84 1006.46,339.84 1006.46,339.84  1006.45,339.84 1006.45,339.85 1006.44,339.85 1006.44,339.85 1006.44,339.85 1006.43,339.86 1006.43,339.86 1006.42,339.86  1006.42,339.86 1006.41,339.86 1006.41,339.86 1006.41,339.86 1006.4,339.86 1006.4,339.87 1006.39,339.87 1006.39,339.87  1006.39,339.87  '
          />
        </g>
        <g id='LINE_00000147218430164982189390000000133547345029164438_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1006.5}
            y1={339.75}
            x2={1006.5}
            y2={339.75}
          />
        </g>
        <g id='LWPOLYLINE_00000059303170859295790630000012662610975507103105_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1006.5,339.75 1006.5,339.75 1006.5,339.75 1006.5,339.74 1006.5,339.73 1006.5,339.73 1006.5,339.71 1006.5,339.7 1006.5,339.69  1006.5,339.67 1006.5,339.65 1006.5,339.63 1006.49,339.61 1006.49,339.59 1006.49,339.56 1006.49,339.54 1006.49,339.51  1006.49,339.48 1006.49,339.45 1006.49,339.41 1006.49,339.38 1006.49,339.34 1006.49,339.3 1006.49,339.27 1006.49,339.23  1006.49,339.19 1006.48,339.14 1006.48,339.11  '
          />
        </g>
        <g id='LWPOLYLINE_00000057117306956463659360000014667927296358615708_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1006.23,338 1006.23,338.04 1006.23,338.08 1006.23,338.13 1006.23,338.17 1006.23,338.22 1006.24,338.26 1006.24,338.31  1006.24,338.35 1006.24,338.4 1006.24,338.44 1006.24,338.49 1006.24,338.54 1006.24,338.58 1006.24,338.63 1006.25,338.68  1006.25,338.72 1006.25,338.77 1006.25,338.82 1006.25,338.86 1006.25,338.91 1006.25,338.95 1006.25,338.99 1006.25,339.04  1006.25,339.08 1006.26,339.11  '
          />
        </g>
        <g id='LWPOLYLINE_00000137818257791884163410000010551257590687751091_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1006.48,339.11 1006.48,339.07 1006.48,339.02 1006.48,338.98 1006.48,338.94 1006.48,338.89 1006.48,338.85 1006.48,338.8  1006.48,338.76 1006.47,338.71 1006.47,338.66 1006.47,338.62 1006.47,338.57 1006.47,338.52 1006.47,338.48 1006.47,338.43  1006.47,338.38 1006.47,338.34 1006.46,338.29 1006.46,338.25 1006.46,338.2 1006.46,338.16 1006.46,338.11 1006.46,338.07  1006.46,338.03 1006.46,338  '
          />
        </g>
        <g id='ELLIPSE_00000136399620696593247340000007739711464403403444_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.29,339.81c0.02,0.03,0.06,0.06,0.1,0.05'
          />
        </g>
        <g id='ELLIPSE_00000049938553371132654180000000299001339757283500_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.3,339.67c-0.01,0.01-0.01,0.02-0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000158013201874055181060000012480873499955540412_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.39,339.87c0.04,0,0.08-0.02,0.1-0.06'
          />
        </g>
        <g id='ELLIPSE_00000036237409386813566330000001347556418013297803_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.44,337.97c-0.02-0.02-0.06-0.03-0.1-0.02'
          />
        </g>
        <g id='ELLIPSE_00000045588622482762835580000009838452529211898553_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.45,338.01c0-0.01,0-0.02,0-0.03'
          />
        </g>
        <g id='ELLIPSE_00000027562065239309601120000007047267535415034804_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.34,338.05c0.02,0,0.04,0,0.06-0.01'
          />
        </g>
        <g id='ELLIPSE_00000136386537867023114730000017823560883336480676_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.29,338.05c0.02,0,0.04,0.01,0.06,0.01'
          />
        </g>
        <g id='ELLIPSE_00000012452655982421049680000013039801023510896298_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.23,337.99c-0.01,0.01,0,0.02,0,0.03'
          />
        </g>
        <g id='ELLIPSE_00000039826338823563557300000014340413066898029462_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1006.34,337.94c-0.04,0-0.08,0.01-0.1,0.03'
          />
        </g>
        <g id='LINE_00000095311315196988577590000011892977550427393162_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1015.99}
            y1={340.86}
            x2={1015.97}
            y2={339.98}
          />
        </g>
        <g id='LINE_00000009572401544972854500000015640660306698046093_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1015.97}
            y1={339.98}
            x2={996.53}
            y2={339.98}
          />
        </g>
        <g id='LINE_00000066477083795898297380000001331561093410388354_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={996.53}
            y1={339.98}
            x2={996.55}
            y2={340.86}
          />
        </g>
        <g id='LINE_00000080926183750559303500000011293759540829074086_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1022.15}
            y1={475.81}
            x2={1017.45}
            y2={480.91}
          />
        </g>
        <g id='LINE_00000042694733962799965650000011570735082262667183_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1031.44}
            y1={494.42}
            x2={1036.14}
            y2={489.32}
          />
        </g>
        <g id='ARC_00000127725577719703613230000005209647256611538836_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1036.14,489.32c0.2-0.22,0.31-0.5,0.29-0.8'
          />
        </g>
        <g id='LINE_00000157299285750158726420000015478723757507158153_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1036.44}
            y1={488.52}
            x2={1036.14}
            y2={481.29}
          />
        </g>
        <g id='ARC_00000102508007259082809620000016257926116050596247_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1036.14,481.29c-0.01-0.29-0.14-0.57-0.36-0.77'
          />
        </g>
        <g id='LINE_00000023999647930888696900000003713881423201357196_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1035.79}
            y1={480.52}
            x2={1030.33}
            y2={475.48}
          />
        </g>
        <g id='ARC_00000088832844177978446970000010226693745992509119_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1030.33,475.48c-0.22-0.2-0.5-0.31-0.8-0.29'
          />
        </g>
        <g id='LINE_00000122678739031672606210000004698229153478893478_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1029.53}
            y1={475.19}
            x2={1022.93}
            y2={475.46}
          />
        </g>
        <g id='ARC_00000016054445211374220700000013228138480006751419_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1022.93,475.46c-0.29,0.01-0.57,0.14-0.77,0.36'
          />
        </g>
        <g id='SPLINE_00000075843107378631284750000007026282899495562153_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1025.95,486.14c-0.22-0.2-0.43-0.41-0.63-0.63c-0.2-0.22-0.4-0.45-0.58-0.68c-0.19-0.23-0.36-0.48-0.52-0.73s-0.32-0.5-0.46-0.77 s-0.26-0.53-0.37-0.81s-0.2-0.56-0.27-0.85s-0.12-0.59-0.13-0.89c-0.01-0.15-0.01-0.3,0-0.45c0.01-0.15,0.03-0.3,0.06-0.44 c0.03-0.15,0.07-0.29,0.13-0.43c0.03-0.07,0.06-0.14,0.09-0.2c0.03-0.07,0.07-0.13,0.11-0.19c0.04-0.06,0.08-0.12,0.12-0.18 c0.04-0.06,0.09-0.12,0.14-0.17c0.05-0.06,0.1-0.11,0.16-0.16c0.06-0.05,0.11-0.1,0.17-0.14c0.06-0.04,0.12-0.08,0.19-0.12 c0.06-0.04,0.13-0.07,0.2-0.11c0.14-0.06,0.28-0.12,0.42-0.16c0.14-0.04,0.29-0.07,0.43-0.1s0.3-0.03,0.44-0.04 c0.3-0.01,0.6,0.01,0.89,0.06c0.29,0.04,0.59,0.11,0.87,0.2c0.29,0.09,0.57,0.19,0.84,0.31c0.27,0.12,0.54,0.25,0.8,0.39 c0.26,0.14,0.52,0.3,0.76,0.46c0.25,0.17,0.49,0.34,0.72,0.53c0.23,0.19,0.46,0.38,0.68,0.58'
          />
        </g>
        <g id='SPLINE_00000160182070757598695840000016252630835648414139_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1031.23,480.42c0.22,0.2,0.43,0.41,0.63,0.63c0.2,0.22,0.4,0.45,0.58,0.68c0.18,0.23,0.36,0.48,0.52,0.72 c0.16,0.25,0.32,0.5,0.46,0.77c0.14,0.26,0.27,0.54,0.38,0.81c0.11,0.28,0.2,0.56,0.27,0.85s0.11,0.59,0.13,0.88 c0.01,0.15,0.01,0.3,0,0.45c-0.01,0.15-0.03,0.3-0.06,0.44c-0.03,0.15-0.07,0.29-0.13,0.43c-0.03,0.07-0.06,0.14-0.09,0.21 s-0.07,0.13-0.11,0.19c-0.04,0.06-0.08,0.12-0.13,0.18c-0.04,0.06-0.09,0.12-0.14,0.17c-0.05,0.06-0.1,0.11-0.16,0.16 c-0.06,0.05-0.11,0.1-0.17,0.14c-0.06,0.04-0.12,0.08-0.18,0.12s-0.13,0.07-0.2,0.11c-0.13,0.06-0.28,0.12-0.42,0.16 c-0.14,0.04-0.29,0.07-0.44,0.1c-0.15,0.02-0.3,0.04-0.45,0.04c-0.3,0.01-0.6-0.01-0.89-0.06c-0.3-0.05-0.59-0.11-0.87-0.2 c-0.29-0.09-0.56-0.19-0.84-0.31c-0.27-0.12-0.54-0.25-0.8-0.39c-0.26-0.14-0.52-0.3-0.77-0.46c-0.25-0.17-0.49-0.34-0.72-0.53 c-0.23-0.19-0.46-0.38-0.68-0.58'
          />
        </g>
        <g id='LWPOLYLINE_00000014615984693853304730000006666476455572561801_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1042.15,479.85 1042.63,479.38 1043.1,478.9 1043.54,478.4 1043.97,477.88 1044.37,477.35 1044.76,476.8 1045.12,476.23  1045.45,475.66 1045.77,475.06 1046.06,474.46 1046.32,473.84 1046.56,473.22 1046.78,472.58 1046.97,471.94 1047.13,471.29  1047.27,470.64 1047.38,469.98 1047.47,469.31 1047.53,468.65 1047.56,467.98 1047.56,467.31 1047.54,466.64 1047.49,465.97  1047.42,465.3 1047.4,465.15  '
          />
        </g>
        <g id='LWPOLYLINE_00000060752551105695924290000000727298670795531197_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1036.42,480.99 1036.77,481.03 1037.13,481.05 1037.48,481.07 1037.84,481.07 1038.2,481.05 1038.55,481.02 1038.91,480.98  1039.26,480.92 1039.61,480.85 1039.95,480.76 1040.3,480.67 1040.63,480.55 1040.97,480.43 1041.3,480.29 1041.62,480.13  1041.93,479.97 1042.15,479.85  '
          />
        </g>
        <g id='LWPOLYLINE_00000030446798279396859060000002036097997034429882_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1030.89,468.98 1030.73,469.29 1030.58,469.62 1030.44,469.95 1030.32,470.28 1030.21,470.62 1030.11,470.96 1030.03,471.31  1029.96,471.66 1029.9,472.01 1029.86,472.37 1029.84,472.72 1029.82,473.08 1029.82,473.44 1029.84,473.79 1029.87,474.15  1029.92,474.5 1029.97,474.85 1030.02,475.09  '
          />
        </g>
        <g id='LWPOLYLINE_00000060718850531269165790000005713097508248215182_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1045.4,463.22 1044.73,463.16 1044.06,463.13 1043.39,463.12 1042.72,463.15 1042.06,463.2 1041.39,463.27 1040.73,463.37  1040.07,463.5 1039.42,463.66 1038.77,463.84 1038.14,464.04 1037.51,464.27 1036.89,464.53 1036.28,464.81 1035.69,465.12  1035.1,465.45 1034.53,465.8 1033.98,466.17 1033.44,466.57 1032.91,466.99 1032.41,467.43 1031.92,467.88 1031.45,468.36  1031,468.86 1030.89,468.98  '
          />
        </g>
        <g id='LINE_00000048485425044120259630000013728279118452744079_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1046.08}
            y1={463.29}
            x2={1045.4}
            y2={463.22}
          />
        </g>
        <g id='LINE_00000093861339616757869370000011301523748468050577_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1047.4}
            y1={465.15}
            x2={1047.3}
            y2={464.47}
          />
        </g>
        <g id='LWPOLYLINE_00000016071499811615358580000001490360691273350307_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1034.82,475.67 1034.95,475.79 1035.09,475.9 1035.24,476.01 1035.36,476.1  '
          />
        </g>
        <g id='LWPOLYLINE_00000183218262434064526440000006167528090542820487_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1035.36,476.1 1035.47,476.23 1035.59,476.37 1035.72,476.5 1035.83,476.6  '
          />
        </g>
        <g id='LINE_00000115472567528212712040000008293235333082383494_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1029.92}
            y1={475.11}
            x2={1030.33}
            y2={475.48}
          />
        </g>
        <g id='LINE_00000106859256239784070670000009871503000512813233_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1035.79}
            y1={480.52}
            x2={1036.4}
            y2={481.08}
          />
        </g>
        <g id='LINE_00000133523755592147204510000017507093067594508704_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1036.4}
            y1={481.08}
            x2={1036.42}
            y2={480.93}
          />
        </g>
        <g id='ARC_00000132067012486617970580000002012262609687357568_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1036.42,480.93c1.97,0.27,3.97-0.12,5.69-1.13'
          />
        </g>
        <g id='ARC_00000143616608255593822840000018390584160641838257_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1042.12,479.8c4.05-3.72,6-9.2,5.22-14.64'
          />
        </g>
        <g id='LINE_00000111913264034225226610000014947286515226044849_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1047.34}
            y1={465.15}
            x2={1047.22}
            y2={464.32}
          />
        </g>
        <g id='ARC_00000107560993188262203870000001212307674631388553_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1047.22,464.32c-0.23-0.41-0.57-0.74-0.98-0.95'
          />
        </g>
        <g id='LINE_00000152983068045560465000000002947039689013210542_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1046.24}
            y1={463.37}
            x2={1045.39}
            y2={463.28}
          />
        </g>
        <g id='ARC_00000055671314614516186220000007146111501362595721_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1045.39,463.28c-5.47-0.59-10.87,1.56-14.45,5.73'
          />
        </g>
        <g id='ARC_00000029748529248608553350000002206307236920822163_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1030.94,469.01c-1,1.86-1.31,4.01-0.86,6.07'
          />
        </g>
        <g id='LINE_00000127764424401478010490000016833017520618425509_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1030.08}
            y1={475.08}
            x2={1029.92}
            y2={475.11}
          />
        </g>
        <g id='ARC_00000029008741053928192950000009045324625425206416_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1034.47,469.43c-1.62,1.88-1.42,4.71,0.46,6.34c0.14,0.12,0.28,0.23,0.43,0.33'
          />
        </g>
        <g id='ARC_00000049204341127845049180000017146006113706066562_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1044.23,465.37c-3.71-0.26-7.33,1.25-9.77,4.06'
          />
        </g>
        <g id='ARC_00000131325436561324834900000011047124201024690078_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1044.99,465.65c-0.21-0.19-0.48-0.29-0.76-0.28'
          />
        </g>
        <g id='ARC_00000098189802367892897420000005848856004208343189_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1045.33,466.38c-0.01-0.28-0.13-0.54-0.34-0.73'
          />
        </g>
        <g id='ARC_00000054237547222966300880000011796721462976522646_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1042.08,476.44c2.61-2.66,3.81-6.38,3.25-10.06'
          />
        </g>
        <g id='ARC_00000065782154643818917840000000787170409467312275_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1035.36,476.1c1.55,1.94,4.37,2.26,6.31,0.71c0.14-0.11,0.28-0.24,0.41-0.37'
          />
        </g>
        <g id='SPLINE_00000140735808120608348020000008122088291873849249_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1047.22,464.32c0.03,0.06,0.06,0.11,0.07,0.13c0.01,0.01,0.01,0.02,0.01,0.02c0,0,0,0,0,0c0,0,0,0,0-0.01'
          />
        </g>
        <g id='SPLINE_00000019653549407613868490000013315887780880880286_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1047.24,464.35c0-0.01-0.01-0.01-0.01-0.02c0-0.01-0.01-0.01-0.01-0.02c-0.01-0.01-0.02-0.03-0.03-0.04 c-0.01-0.02-0.02-0.03-0.03-0.05s-0.02-0.03-0.03-0.05c-0.01-0.02-0.02-0.03-0.03-0.05s-0.02-0.03-0.04-0.05'
          />
        </g>
        <g id='SPLINE_00000176009525613908424320000008609838226599169421_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1046.99,463.97c-0.06-0.07-0.12-0.14-0.19-0.2'
          />
        </g>
        <g id='SPLINE_00000013187475766555309290000000890545144921298597_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1046.8,463.77c-0.07-0.07-0.14-0.13-0.21-0.18'
          />
        </g>
        <g id='SPLINE_00000169549310493634504320000001851282177817287851_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1046.48,463.51c-0.02-0.01-0.03-0.02-0.05-0.03c-0.02-0.01-0.03-0.02-0.05-0.03c-0.02-0.01-0.03-0.02-0.05-0.03 c-0.02-0.01-0.03-0.02-0.05-0.03c-0.02-0.01-0.03-0.02-0.05-0.02c-0.01,0-0.01-0.01-0.02-0.01s-0.01-0.01-0.02-0.01'
          />
        </g>
        <g id='SPLINE_00000175323475527362135770000013118224842431801004_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1046.09,463.3C1046.08,463.29,1046.08,463.29,1046.09,463.3c0,0,0,0,0.02,0.01c0.03,0.01,0.07,0.03,0.13,0.06'
          />
        </g>
        <g id='SPLINE_00000170264254264555757600000012558078265253408664_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1042.12,479.8c0.02,0.03,0.03,0.05,0.03,0.05'
          />
        </g>
        <g id='SPLINE_00000054952186531287215190000011207025368494283691_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1030.94,469.01c-0.03-0.02-0.05-0.03-0.05-0.03'
          />
        </g>
        <g id='SPLINE_00000044155675755412688700000015910215166726170529_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1039.37,472.5c-0.16,0.01-0.33-0.02-0.48-0.07s-0.3-0.13-0.43-0.23c-0.06-0.05-0.12-0.11-0.18-0.17 c-0.05-0.06-0.1-0.13-0.15-0.19c-0.02-0.03-0.04-0.07-0.06-0.11c-0.02-0.04-0.03-0.07-0.05-0.11c-0.02-0.04-0.03-0.07-0.04-0.11 c-0.01-0.04-0.02-0.08-0.03-0.12s-0.01-0.08-0.02-0.12c0-0.04-0.01-0.08-0.01-0.12s0-0.08,0-0.12c0-0.04,0.01-0.08,0.01-0.12 c0.01-0.04,0.01-0.08,0.02-0.12c0.01-0.04,0.02-0.08,0.03-0.12c0.01-0.04,0.03-0.08,0.04-0.11c0.02-0.04,0.03-0.07,0.05-0.11 c0.02-0.04,0.04-0.07,0.06-0.1s0.04-0.07,0.07-0.1c0.05-0.06,0.1-0.12,0.16-0.18c0.12-0.11,0.26-0.2,0.41-0.26s0.31-0.1,0.48-0.11 '
          />
        </g>
        <g id='SPLINE_00000106136741659086804970000006380113085377511861_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1039.26,469.72c0.16-0.01,0.33,0.02,0.48,0.07s0.3,0.13,0.43,0.23s0.24,0.22,0.32,0.36c0.09,0.14,0.15,0.29,0.18,0.45 c0.03,0.16,0.04,0.32,0.02,0.48s-0.07,0.32-0.15,0.46c-0.08,0.14-0.18,0.27-0.3,0.38c-0.06,0.05-0.12,0.1-0.19,0.15 c-0.07,0.04-0.14,0.08-0.21,0.11c-0.15,0.06-0.31,0.1-0.47,0.1'
          />
        </g>
        <g id='LINE_00000153701236937155475950000010037054152769288837_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1045.33}
            y1={466.38}
            x2={1044.23}
            y2={465.37}
          />
        </g>
        <g id='LWPOLYLINE_00000007389663079150676020000017867349352441192576_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1024.62,487.68 1024.62,487.67 1024.62,487.67 1024.61,487.67 1024.61,487.66 1024.61,487.66 1024.6,487.66 1024.6,487.65  1024.6,487.65 1024.6,487.65 1024.6,487.64 1024.59,487.64 1024.59,487.63 1024.59,487.63 1024.59,487.62 1024.59,487.62  1024.59,487.61 1024.59,487.61 1024.59,487.61 1024.59,487.6 1024.59,487.6 1024.59,487.59 1024.59,487.59 1024.59,487.58  1024.59,487.58 1024.59,487.57 1024.59,487.57 1024.59,487.57 1024.59,487.56 1024.59,487.56 1024.59,487.55 1024.59,487.55  1024.6,487.54 1024.6,487.54 1024.6,487.54 1024.6,487.53 1024.61,487.53 1024.61,487.52 1024.61,487.52 1024.61,487.52  1024.62,487.52  '
          />
        </g>
        <g id='LWPOLYLINE_00000146476861382886630900000017772728176837581957_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1025.05,487.04 1025.02,487.08 1024.99,487.11 1024.97,487.14 1024.94,487.17 1024.91,487.19 1024.89,487.22 1024.86,487.25  1024.84,487.27 1024.82,487.3 1024.8,487.32 1024.78,487.34 1024.76,487.36 1024.74,487.38 1024.72,487.4 1024.71,487.42  1024.69,487.43 1024.68,487.45 1024.67,487.46 1024.66,487.47 1024.65,487.48 1024.64,487.49 1024.63,487.5 1024.63,487.51  1024.62,487.51 1024.62,487.51 1024.62,487.52 1024.62,487.52  '
          />
        </g>
        <g id='LINE_00000093888345962164361710000013574538180439601550_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1024.62}
            y1={487.52}
            x2={1024.62}
            y2={487.52}
          />
        </g>
        <g id='LWPOLYLINE_00000017483599347732170350000001290779696672233137_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1024.78,487.67 1024.78,487.67 1024.78,487.68 1024.77,487.68 1024.77,487.68 1024.77,487.69 1024.76,487.69 1024.76,487.69  1024.75,487.69 1024.75,487.7 1024.75,487.7 1024.74,487.7 1024.74,487.7 1024.73,487.7 1024.73,487.7 1024.72,487.7  1024.72,487.71 1024.72,487.71 1024.71,487.71 1024.71,487.71 1024.7,487.71 1024.7,487.71 1024.69,487.71 1024.69,487.71  1024.68,487.71 1024.68,487.71 1024.67,487.71 1024.67,487.7 1024.67,487.7 1024.66,487.7 1024.66,487.7 1024.65,487.7  1024.65,487.7 1024.65,487.69 1024.64,487.69 1024.64,487.69 1024.63,487.69 1024.63,487.68 1024.63,487.68 1024.62,487.68  1024.62,487.68  '
          />
        </g>
        <g id='LINE_00000036936989320298982020000018130652956101808769_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1024.78}
            y1={487.67}
            x2={1024.78}
            y2={487.67}
          />
        </g>
        <g id='LWPOLYLINE_00000176724166777154288700000013564428450675372429_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1024.78,487.67 1024.78,487.67 1024.79,487.67 1024.79,487.66 1024.79,487.66 1024.8,487.65 1024.81,487.65 1024.82,487.64  1024.83,487.63 1024.84,487.61 1024.85,487.6 1024.86,487.59 1024.88,487.57 1024.89,487.55 1024.91,487.53 1024.93,487.51  1024.95,487.49 1024.97,487.47 1024.99,487.45 1025.01,487.42 1025.04,487.4 1025.06,487.37 1025.08,487.34 1025.11,487.32  1025.14,487.29 1025.17,487.26 1025.19,487.23 1025.22,487.2  '
          />
        </g>
        <g id='LWPOLYLINE_00000052800964078081810370000016541110512834209689_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1025.8,486.23 1025.78,486.26 1025.75,486.29 1025.72,486.32 1025.69,486.35 1025.66,486.38 1025.63,486.42 1025.6,486.45  1025.57,486.48 1025.54,486.52 1025.5,486.55 1025.47,486.59 1025.44,486.62 1025.41,486.66 1025.38,486.69 1025.35,486.72  1025.31,486.76 1025.28,486.79 1025.25,486.83 1025.22,486.86 1025.19,486.89 1025.16,486.92 1025.13,486.96 1025.1,486.99  1025.07,487.02 1025.05,487.04  '
          />
        </g>
        <g id='LWPOLYLINE_00000023264243075095336390000016074551481628861118_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1025.22,487.2 1025.25,487.17 1025.28,487.14 1025.3,487.11 1025.33,487.07 1025.36,487.04 1025.39,487.01 1025.43,486.97  1025.46,486.94 1025.49,486.91 1025.52,486.87 1025.55,486.84 1025.58,486.8 1025.61,486.77 1025.65,486.74 1025.68,486.7  1025.71,486.67 1025.74,486.63 1025.77,486.6 1025.8,486.57 1025.83,486.53 1025.86,486.5 1025.89,486.47 1025.92,486.44  1025.95,486.41 1025.97,486.38  '
          />
        </g>
        <g id='ELLIPSE_00000073000768715258520140000018065662572123721121_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1024.59,487.57c-0.01,0.04,0,0.08,0.03,0.11'
          />
        </g>
        <g id='ELLIPSE_00000068657629982510475280000000188461518527702689_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1024.69,487.48c-0.01,0-0.02,0-0.03,0.01'
          />
        </g>
        <g id='ELLIPSE_00000091016210860535558110000004705248182372054951_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1024.62,487.68c0.03,0.03,0.07,0.04,0.11,0.03'
          />
        </g>
        <g id='ELLIPSE_00000101086897627987675090000009641093103872169894_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1025.98,486.35c0-0.03-0.02-0.06-0.05-0.09'
          />
        </g>
        <g id='ELLIPSE_00000008833942716510971730000013210697653883921837_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1025.96,486.39c0.01,0,0.02-0.01,0.02-0.02'
          />
        </g>
        <g id='ELLIPSE_00000070106261131944723280000008924524239158293178_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1025.85,486.34c0.01,0.01,0.03,0.03,0.05,0.03'
          />
        </g>
        <g id='ELLIPSE_00000056415714632261768970000004518112653115915695_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1025.81,486.3c0.01,0.02,0.02,0.03,0.04,0.04'
          />
        </g>
        <g id='ELLIPSE_00000029745312072969925140000000839457198045962120_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1025.82,486.22c-0.01,0-0.02,0.01-0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000029020004013663056980000000141559790430622365_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1025.93,486.26c-0.03-0.03-0.06-0.05-0.09-0.05'
          />
        </g>
        <g id='LINE_00000078038964645637964850000009348903659495119529_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1030.84}
            y1={495.07}
            x2={1031.44}
            y2={494.42}
          />
        </g>
        <g id='LINE_00000128474222722006070450000004849505579811465905_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1031.44}
            y1={494.42}
            x2={1017.45}
            y2={480.91}
          />
        </g>
        <g id='LINE_00000011747465606080202380000006292870095213657754_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1017.45}
            y1={480.91}
            x2={1016.85}
            y2={481.56}
          />
        </g>
        <g id='LINE_00000098934969506277854500000004620468053080132484_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={256.56}
            y1={255.05}
            x2={256.72}
            y2={261.98}
          />
        </g>
        <g id='LINE_00000160151429401995228770000009393104357749099665_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={276.17}
            y1={261.98}
            x2={276.01}
            y2={255.04}
          />
        </g>
        <g id='ARC_00000137110154190582538760000001098942792002028705_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M276.01,255.04c-0.01-0.29-0.13-0.57-0.34-0.78'
          />
        </g>
        <g id='LINE_00000116950522672942788730000000332688946846218133_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={275.66}
            y1={254.27}
            x2={270.43}
            y2={249.27}
          />
        </g>
        <g id='ARC_00000003799699715814372140000010800527045301975215_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M270.43,249.27c-0.21-0.2-0.5-0.31-0.79-0.31'
          />
        </g>
        <g id='LINE_00000032629654785924322050000007323474611281894025_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={269.64}
            y1={248.96}
            x2={262.21}
            y2={249.13}
          />
        </g>
        <g id='ARC_00000016048144691514851060000010111759443800912001_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M262.21,249.13c-0.29,0.01-0.57,0.13-0.78,0.34'
          />
        </g>
        <g id='LINE_00000017487884123928988210000002645702985737953425_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={261.43}
            y1={249.48}
            x2={256.87}
            y2={254.26}
          />
        </g>
        <g id='ARC_00000131365936025029286150000015688492135237465261_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M256.87,254.26c-0.2,0.21-0.31,0.5-0.31,0.79'
          />
        </g>
        <g id='SPLINE_00000066475063195291352990000007492349343078731913_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.47,259.84c-0.3,0.01-0.6,0-0.89-0.01c-0.3-0.02-0.6-0.04-0.89-0.08c-0.3-0.04-0.59-0.09-0.88-0.16 c-0.29-0.07-0.58-0.14-0.86-0.24c-0.28-0.09-0.56-0.2-0.83-0.32c-0.27-0.12-0.53-0.26-0.79-0.43c-0.25-0.16-0.49-0.34-0.71-0.55 c-0.11-0.1-0.21-0.21-0.31-0.33s-0.18-0.23-0.26-0.36c-0.08-0.13-0.15-0.26-0.21-0.4c-0.03-0.07-0.06-0.14-0.08-0.21 s-0.04-0.14-0.06-0.21c-0.02-0.07-0.03-0.15-0.04-0.22c-0.01-0.07-0.01-0.15-0.02-0.22c0-0.08,0-0.15,0.01-0.22 c0.01-0.07,0.01-0.15,0.03-0.22c0.01-0.07,0.03-0.14,0.05-0.22c0.02-0.07,0.04-0.14,0.07-0.21c0.05-0.14,0.12-0.28,0.19-0.41 c0.07-0.13,0.16-0.25,0.25-0.37s0.19-0.23,0.29-0.34c0.21-0.21,0.44-0.41,0.68-0.58c0.24-0.17,0.5-0.33,0.77-0.46 c0.27-0.14,0.54-0.26,0.82-0.36c0.28-0.11,0.56-0.2,0.85-0.28s0.58-0.14,0.87-0.2c0.29-0.05,0.59-0.1,0.89-0.12 s0.6-0.05,0.89-0.06'
          />
        </g>
        <g id='SPLINE_00000008134950040879644910000008394890840477720726_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.29,252.06c0.3-0.01,0.6,0,0.9,0.01c0.3,0.02,0.6,0.04,0.89,0.08s0.59,0.09,0.88,0.16c0.29,0.06,0.58,0.14,0.86,0.24 s0.56,0.2,0.84,0.33c0.27,0.12,0.54,0.27,0.79,0.43c0.25,0.16,0.49,0.34,0.71,0.55c0.11,0.1,0.21,0.21,0.31,0.32 c0.1,0.11,0.18,0.23,0.26,0.36c0.08,0.13,0.15,0.26,0.21,0.4c0.03,0.07,0.06,0.14,0.08,0.21c0.02,0.07,0.04,0.14,0.06,0.21 s0.03,0.15,0.04,0.22c0.01,0.07,0.01,0.15,0.02,0.22c0,0.08,0,0.15-0.01,0.22c-0.01,0.07-0.02,0.15-0.03,0.22 c-0.01,0.07-0.03,0.14-0.05,0.22s-0.04,0.14-0.07,0.21c-0.05,0.14-0.12,0.28-0.19,0.41c-0.07,0.13-0.16,0.25-0.25,0.37 c-0.09,0.12-0.19,0.23-0.29,0.34c-0.21,0.21-0.44,0.41-0.68,0.58s-0.5,0.33-0.77,0.46c-0.26,0.14-0.54,0.26-0.82,0.36 c-0.28,0.11-0.56,0.2-0.85,0.28s-0.58,0.14-0.87,0.2c-0.29,0.05-0.59,0.1-0.89,0.12c-0.3,0.03-0.6,0.05-0.89,0.06'
          />
        </g>
        <g id='LWPOLYLINE_00000038383809120691887030000017914608935012968111_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 273.75,244.06 273.77,243.39 273.77,242.72 273.75,242.05 273.69,241.38 273.61,240.72 273.51,240.06 273.37,239.4 273.21,238.75  273.03,238.11 272.82,237.47 272.58,236.84 272.32,236.23 272.03,235.62 271.73,235.03 271.39,234.45 271.04,233.88  270.66,233.33 270.26,232.79 269.84,232.27 269.39,231.77 268.93,231.28 268.45,230.81 267.95,230.37 267.44,229.94  267.31,229.84  '
          />
        </g>
        <g id='LWPOLYLINE_00000126323899061739177640000017950652735666711711_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 270.42,248.86 270.7,248.65 270.98,248.42 271.24,248.18 271.5,247.93 271.74,247.67 271.98,247.41 272.2,247.13 272.42,246.84  272.62,246.55 272.81,246.25 272.98,245.94 273.15,245.62 273.3,245.3 273.44,244.97 273.57,244.64 273.68,244.3 273.75,244.06  '
          />
        </g>
        <g id='LWPOLYLINE_00000158717369614185629620000016404572315891175324_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 258.1,244.06 258.2,244.4 258.32,244.74 258.45,245.07 258.59,245.4 258.75,245.72 258.92,246.03 259.1,246.34 259.29,246.64  259.5,246.93 259.71,247.22 259.94,247.49 260.18,247.76 260.43,248.01 260.69,248.26 260.96,248.49 261.24,248.71 261.52,248.93  261.72,249.06  '
          />
        </g>
        <g id='LWPOLYLINE_00000015323252228771951020000015865543198313095581_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 264.54,229.84 264.02,230.26 263.51,230.71 263.03,231.17 262.56,231.65 262.11,232.15 261.69,232.67 261.28,233.2 260.9,233.75  260.54,234.31 260.2,234.89 259.88,235.48 259.59,236.08 259.33,236.7 259.08,237.32 258.87,237.96 258.68,238.6 258.51,239.25  258.37,239.9 258.26,240.56 258.17,241.23 258.11,241.89 258.08,242.56 258.07,243.23 258.09,243.9 258.1,244.06  '
          />
        </g>
        <g id='LINE_00000111879032182735956650000007550656322978629554_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={265.07}
            y1={229.42}
            x2={264.54}
            y2={229.84}
          />
        </g>
        <g id='LINE_00000145773850202079941390000000434579755453421185_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={267.31}
            y1={229.84}
            x2={266.77}
            y2={229.42}
          />
        </g>
        <g id='LWPOLYLINE_00000073713055747185314170000011861639472075067525_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 265.58,246.15 265.76,246.14 265.93,246.13 266.11,246.1 266.26,246.08  '
          />
        </g>
        <g id='LWPOLYLINE_00000053504223906218788030000009038240444820763818_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.26,246.08 266.44,246.1 266.62,246.11 266.8,246.12 266.94,246.12  '
          />
        </g>
        <g id='LINE_00000040546972409120560060000007109934144463595426_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={261.66}
            y1={249.15}
            x2={262.17}
            y2={249.14}
          />
        </g>
        <g id='LINE_00000152956683767034916390000010718317041038336385_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={269.64}
            y1={248.96}
            x2={270.48}
            y2={248.94}
          />
        </g>
        <g id='LINE_00000106869391300495577060000000240611680791638688_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={270.48}
            y1={248.94}
            x2={270.38}
            y2={248.82}
          />
        </g>
        <g id='ARC_00000170960826516685716930000014282912367643154092_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M270.38,248.82c1.61-1.17,2.77-2.85,3.31-4.77'
          />
        </g>
        <g id='ARC_00000098903330858824054800000001305387443779131325_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M273.69,244.05c0.33-5.49-2.07-10.79-6.41-14.16'
          />
        </g>
        <g id='LINE_00000065786359662020580710000006419901865989783993_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={267.28}
            y1={229.89}
            x2={266.61}
            y2={229.37}
          />
        </g>
        <g id='ARC_00000067220355048234927710000001365366975959320973_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.61,229.37c-0.45-0.13-0.92-0.13-1.37,0'
          />
        </g>
        <g id='LINE_00000173140724555476537690000013260510616905214608_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={265.24}
            y1={229.37}
            x2={264.57}
            y2={229.89}
          />
        </g>
        <g id='ARC_00000007426765885859654490000016445948963543514558_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M264.57,229.89c-4.34,3.38-6.74,8.67-6.41,14.16'
          />
        </g>
        <g id='ARC_00000008837860375546194130000001035333899477782695_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M258.16,244.05c0.57,2.03,1.84,3.79,3.6,4.97'
          />
        </g>
        <g id='LINE_00000144305155599289456960000002926333113037172906_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={261.75}
            y1={249.02}
            x2={261.66}
            y2={249.15}
          />
        </g>
        <g id='ARC_00000129205062601382785570000004422173909028301983_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M260.99,241.9c0.13,2.48,2.25,4.38,4.73,4.24c0.18-0.01,0.36-0.03,0.54-0.06'
          />
        </g>
        <g id='ARC_00000069383199864325532270000012782498441175076022_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.19,232.2c-2.85,2.39-4.41,5.99-4.2,9.7'
          />
        </g>
        <g id='ARC_00000027596523387311284500000008092533174393344676_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.93,231.87c-0.28,0.01-0.55,0.12-0.74,0.33'
          />
        </g>
        <g id='ARC_00000148646862418062620470000003909528580218248350_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.68,232.16c-0.2-0.19-0.47-0.3-0.75-0.29'
          />
        </g>
        <g id='ARC_00000168106371564255248750000002591589469922464421_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M271.33,241.66c0.03-3.72-1.69-7.24-4.65-9.5'
          />
        </g>
        <g id='ARC_00000012439938079516329640000011018631982330620567_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.26,246.08c2.46,0.32,4.72-1.41,5.04-3.87c0.02-0.18,0.04-0.36,0.04-0.55'
          />
        </g>
        <g id='SPLINE_00000096778415494664021850000005320699710680094383_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.61,229.37c0.07,0.02,0.11,0.04,0.14,0.05'
          />
        </g>
        <g id='SPLINE_00000157993797215755481450000008774813193869050783_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.65,229.38c-0.01,0-0.01,0-0.02-0.01c-0.01,0-0.01,0-0.02-0.01c-0.02,0-0.03-0.01-0.05-0.01c-0.02,0-0.04-0.01-0.05-0.01 c-0.02,0-0.04-0.01-0.06-0.01c-0.02,0-0.04-0.01-0.06-0.01c-0.02,0-0.04-0.01-0.06-0.01'
          />
        </g>
        <g id='SPLINE_00000142882204193581900020000005647352092526143414_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.2,229.28c-0.09-0.01-0.18-0.02-0.28-0.02'
          />
        </g>
        <g id='SPLINE_00000129178323989221410730000004105580529559215244_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.92,229.27c-0.1,0-0.19,0.01-0.28,0.02'
          />
        </g>
        <g id='SPLINE_00000056419891077503067870000015890363647965477286_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.52,229.3c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.05,0.01 c-0.02,0-0.03,0.01-0.05,0.01c-0.01,0-0.02,0-0.02,0.01c-0.01,0-0.01,0-0.02,0.01'
          />
        </g>
        <g id='SPLINE_00000108281784663971958090000017711308104356425908_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.1,229.41c0.03-0.01,0.07-0.03,0.14-0.05'
          />
        </g>
        <g id='SPLINE_00000058555543681898864030000000311732021657168544_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M273.69,244.05c0.04,0.01,0.06,0.01,0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000176023089450933449400000018187135493900583088_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M258.16,244.05c-0.04,0.01-0.06,0.01-0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000018942228446198866860000000037697963338908299_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.65,240.7c-0.11,0.12-0.25,0.22-0.39,0.29c-0.15,0.07-0.31,0.12-0.47,0.13c-0.08,0.01-0.16,0.01-0.24,0s-0.16-0.02-0.24-0.04 c-0.04-0.01-0.08-0.02-0.12-0.03c-0.04-0.01-0.08-0.03-0.11-0.04s-0.07-0.03-0.11-0.05c-0.03-0.02-0.07-0.04-0.1-0.06 c-0.03-0.02-0.07-0.05-0.1-0.07s-0.06-0.05-0.09-0.08c-0.03-0.03-0.06-0.06-0.08-0.09c-0.03-0.03-0.05-0.06-0.07-0.09 s-0.05-0.07-0.07-0.1c-0.02-0.03-0.04-0.07-0.06-0.1c-0.02-0.04-0.03-0.07-0.05-0.11c-0.01-0.04-0.03-0.07-0.04-0.11 c-0.01-0.04-0.02-0.08-0.03-0.12c-0.01-0.04-0.01-0.08-0.02-0.12c-0.01-0.08-0.01-0.16-0.01-0.24c0.01-0.16,0.05-0.32,0.11-0.47 c0.07-0.15,0.16-0.29,0.27-0.41'
          />
        </g>
        <g id='SPLINE_00000103966015910278204200000000921476364420310144_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M264.64,238.78c0.11-0.12,0.25-0.22,0.39-0.29c0.15-0.07,0.3-0.12,0.46-0.14c0.16-0.02,0.32-0.01,0.48,0.03s0.31,0.11,0.44,0.2 c0.13,0.09,0.25,0.2,0.35,0.34c0.1,0.13,0.17,0.28,0.21,0.43c0.04,0.16,0.06,0.32,0.05,0.48c-0.01,0.08-0.02,0.16-0.04,0.24 s-0.05,0.15-0.08,0.23c-0.06,0.15-0.16,0.28-0.27,0.4'
          />
        </g>
        <g id='LINE_00000148624589252547804110000004680186268764064911_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={266.68}
            y1={232.16}
            x2={265.19}
            y2={232.2}
          />
        </g>
        <g id='LWPOLYLINE_00000109009405785046800160000001144407853453520561_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.58,261.87 266.58,261.87 266.57,261.87 266.57,261.87 266.56,261.87 266.56,261.87 266.55,261.87 266.55,261.87  266.54,261.87 266.54,261.86 266.54,261.86 266.53,261.86 266.53,261.86 266.52,261.86 266.52,261.85 266.52,261.85  266.51,261.85 266.51,261.85 266.5,261.84 266.5,261.84 266.5,261.84 266.49,261.83 266.49,261.83 266.49,261.83 266.49,261.82  266.48,261.82 266.48,261.82 266.48,261.81 266.48,261.81 266.47,261.8 266.47,261.8 266.47,261.8 266.47,261.79 266.47,261.79  266.47,261.78 266.47,261.78 266.47,261.77 266.46,261.77 266.46,261.76 266.46,261.76 266.46,261.76  '
          />
        </g>
        <g id='LWPOLYLINE_00000150063228575439591070000005220842806673937050_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.45,261.12 266.45,261.16 266.45,261.2 266.45,261.24 266.45,261.28 266.45,261.32 266.45,261.36 266.46,261.39 266.46,261.43  266.46,261.46 266.46,261.49 266.46,261.52 266.46,261.55 266.46,261.58 266.46,261.6 266.46,261.62 266.46,261.65 266.46,261.66  266.46,261.68 266.46,261.7 266.46,261.71 266.46,261.73 266.46,261.74 266.46,261.74 266.46,261.75 266.46,261.76 266.46,261.76  266.46,261.76  '
          />
        </g>
        <g id='LINE_00000034050763603672431960000016894048078680449411_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={266.46}
            y1={261.76}
            x2={266.46}
            y2={261.76}
          />
        </g>
        <g id='LWPOLYLINE_00000036970772093454058300000003555603423074852005_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.69,261.75 266.69,261.76 266.69,261.76 266.69,261.77 266.69,261.77 266.69,261.78 266.69,261.78 266.69,261.79  266.69,261.79 266.69,261.79 266.68,261.8 266.68,261.8 266.68,261.81 266.68,261.81 266.68,261.81 266.67,261.82 266.67,261.82  266.67,261.83 266.67,261.83 266.66,261.83 266.66,261.84 266.66,261.84 266.65,261.84 266.65,261.85 266.65,261.85  266.64,261.85 266.64,261.85 266.63,261.86 266.63,261.86 266.63,261.86 266.62,261.86 266.62,261.86 266.61,261.86  266.61,261.87 266.6,261.87 266.6,261.87 266.6,261.87 266.59,261.87 266.59,261.87 266.58,261.87 266.58,261.87  '
          />
        </g>
        <g id='LINE_00000041286108884647892240000009270487856269507207_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={266.69}
            y1={261.75}
            x2={266.69}
            y2={261.75}
          />
        </g>
        <g id='LWPOLYLINE_00000155114834239313351640000014905032806445276081_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.69,261.75 266.69,261.75 266.69,261.75 266.69,261.74 266.69,261.74 266.69,261.73 266.69,261.72 266.69,261.71  266.69,261.69 266.69,261.68 266.69,261.66 266.69,261.64 266.69,261.62 266.69,261.59 266.69,261.57 266.69,261.54  266.69,261.51 266.69,261.48 266.68,261.45 266.68,261.42 266.68,261.38 266.68,261.35 266.68,261.31 266.68,261.27  266.68,261.23 266.68,261.19 266.68,261.15 266.68,261.11  '
          />
        </g>
        <g id='LWPOLYLINE_00000047774221692422542030000003430948475324132285_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.42,260 266.42,260.05 266.43,260.09 266.43,260.13 266.43,260.18 266.43,260.22 266.43,260.26 266.43,260.31 266.43,260.36  266.43,260.4 266.43,260.45 266.43,260.49 266.44,260.54 266.44,260.59 266.44,260.63 266.44,260.68 266.44,260.73 266.44,260.77  266.44,260.82 266.44,260.86 266.44,260.91 266.45,260.95 266.45,261 266.45,261.04 266.45,261.08 266.45,261.12  '
          />
        </g>
        <g id='LWPOLYLINE_00000083091269907835970500000009467215399323488438_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.68,261.11 266.68,261.07 266.67,261.03 266.67,260.98 266.67,260.94 266.67,260.9 266.67,260.85 266.67,260.81 266.67,260.76  266.67,260.71 266.67,260.67 266.67,260.62 266.66,260.57 266.66,260.53 266.66,260.48 266.66,260.43 266.66,260.39  266.66,260.34 266.66,260.3 266.66,260.25 266.66,260.21 266.65,260.16 266.65,260.12 266.65,260.08 266.65,260.03 266.65,260  '
          />
        </g>
        <g id='ELLIPSE_00000092434358078694920240000009042280914887758467_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.48,261.82c0.02,0.03,0.06,0.06,0.1,0.05'
          />
        </g>
        <g id='ELLIPSE_00000160892405215144107100000010522675101298581431_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.5,261.68c-0.01,0.01-0.01,0.02-0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000117644967043214341080000014925356386236070590_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.58,261.87c0.04,0,0.08-0.02,0.1-0.06'
          />
        </g>
        <g id='ELLIPSE_00000119083088666100449710000011908622841901529253_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.64,259.97c-0.02-0.02-0.06-0.03-0.1-0.02'
          />
        </g>
        <g id='ELLIPSE_00000159440673312985776730000017864972065827182208_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.65,260.01c0-0.01,0-0.02,0-0.03'
          />
        </g>
        <g id='ELLIPSE_00000132797480367571747020000018268875254618826932_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.54,260.06c0.02,0,0.04,0,0.06-0.01'
          />
        </g>
        <g id='ELLIPSE_00000151542670857694859410000017564198191669280928_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.48,260.05c0.02,0,0.04,0.01,0.06,0.01'
          />
        </g>
        <g id='ELLIPSE_00000106138376613780149720000013310844515317775036_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.43,259.99c0,0.01,0,0.02,0,0.03'
          />
        </g>
        <g id='ELLIPSE_00000103969066807030328750000003553740084245659813_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.54,259.95c-0.04,0-0.08,0.01-0.1,0.03'
          />
        </g>
        <g id='LINE_00000157994590706711627590000008977558958660946816_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={276.19}
            y1={262.87}
            x2={276.17}
            y2={261.98}
          />
        </g>
        <g id='LINE_00000131325361837004508920000004403884552398022814_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={276.17}
            y1={261.98}
            x2={256.72}
            y2={261.98}
          />
        </g>
        <g id='LINE_00000025441976685288171310000011042563326533276041_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={256.72}
            y1={261.98}
            x2={256.74}
            y2={262.87}
          />
        </g>
        <g id='ARC_00000062174923867232914320000005746298915062805125_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M256.56,71.07c-0.01,0.29,0.1,0.58,0.31,0.79'
          />
        </g>
        <g id='LINE_00000005979952304523513600000015957808755933105588_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={256.87}
            y1={71.86}
            x2={261.43}
            y2={76.64}
          />
        </g>
        <g id='ARC_00000098219067055658914590000009746449655656509867_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M261.43,76.64c0.2,0.21,0.48,0.34,0.78,0.34'
          />
        </g>
        <g id='LINE_00000165201514378713570670000005333583121832784555_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={262.21}
            y1={76.98}
            x2={269.64}
            y2={77.16}
          />
        </g>
        <g id='ARC_00000108993244156768921190000018066991000229868461_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M269.64,77.16c0.29,0.01,0.58-0.1,0.79-0.31'
          />
        </g>
        <g id='LINE_00000146476806766507636900000002639237153038878850_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={270.43}
            y1={76.85}
            x2={275.66}
            y2={71.85}
          />
        </g>
        <g id='ARC_00000147194632641650759160000013678916793689108397_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M275.66,71.85c0.21-0.2,0.34-0.48,0.34-0.78'
          />
        </g>
        <g id='LINE_00000089548339690813399000000018420231777451621773_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={276.01}
            y1={71.07}
            x2={276.17}
            y2={64.14}
          />
        </g>
        <g id='LINE_00000034052255872955316030000014716510694780971686_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={256.72}
            y1={64.14}
            x2={256.56}
            y2={71.07}
          />
        </g>
        <g id='SPLINE_00000084528728271501032200000014075075338193630094_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.47,66.28c0.3,0.01,0.6,0.03,0.89,0.06c0.3,0.03,0.59,0.07,0.89,0.12c0.29,0.05,0.59,0.12,0.87,0.2s0.57,0.17,0.85,0.28 c0.28,0.11,0.55,0.23,0.82,0.36s0.52,0.29,0.77,0.46s0.47,0.36,0.68,0.58c0.1,0.11,0.2,0.22,0.29,0.34 c0.09,0.12,0.17,0.24,0.25,0.37c0.07,0.13,0.14,0.27,0.19,0.41c0.03,0.07,0.05,0.14,0.07,0.21s0.04,0.14,0.05,0.22 c0.01,0.07,0.02,0.15,0.03,0.22s0.01,0.15,0.01,0.22c0,0.08-0.01,0.15-0.02,0.22c-0.01,0.07-0.02,0.15-0.04,0.22 s-0.04,0.14-0.06,0.21c-0.02,0.07-0.05,0.14-0.08,0.21c-0.06,0.14-0.13,0.27-0.21,0.4s-0.17,0.25-0.26,0.36 c-0.1,0.11-0.2,0.22-0.31,0.32c-0.22,0.2-0.46,0.39-0.71,0.55c-0.25,0.16-0.51,0.3-0.79,0.43c-0.27,0.12-0.55,0.23-0.84,0.33 s-0.57,0.17-0.86,0.24c-0.29,0.06-0.58,0.12-0.88,0.16s-0.59,0.07-0.89,0.08c-0.3,0.02-0.6,0.02-0.9,0.01'
          />
        </g>
        <g id='SPLINE_00000093168724767716404230000005453354843064569745_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.29,74.06c-0.3-0.01-0.6-0.03-0.89-0.06c-0.3-0.03-0.59-0.07-0.89-0.12c-0.29-0.05-0.58-0.12-0.87-0.2s-0.57-0.17-0.85-0.28 c-0.28-0.11-0.55-0.23-0.82-0.36s-0.52-0.29-0.77-0.46c-0.24-0.17-0.47-0.36-0.68-0.58c-0.1-0.11-0.2-0.22-0.29-0.34 c-0.09-0.12-0.17-0.24-0.25-0.37c-0.07-0.13-0.14-0.27-0.19-0.41c-0.03-0.07-0.05-0.14-0.07-0.21s-0.04-0.14-0.05-0.22 c-0.01-0.07-0.02-0.15-0.03-0.22c-0.01-0.07-0.01-0.15-0.01-0.22c0-0.08,0.01-0.15,0.02-0.22c0.01-0.07,0.02-0.15,0.04-0.22 c0.02-0.07,0.04-0.14,0.06-0.21c0.02-0.07,0.05-0.14,0.08-0.21c0.06-0.14,0.13-0.27,0.21-0.4c0.08-0.13,0.17-0.25,0.26-0.36 c0.1-0.11,0.2-0.22,0.31-0.33c0.22-0.21,0.46-0.39,0.71-0.55c0.25-0.16,0.51-0.3,0.79-0.43s0.55-0.23,0.83-0.32 c0.28-0.09,0.57-0.17,0.86-0.24c0.29-0.07,0.59-0.12,0.88-0.16c0.3-0.04,0.59-0.07,0.89-0.08c0.3-0.02,0.6-0.02,0.89-0.01'
          />
        </g>
        <g id='LWPOLYLINE_00000163770832564275642160000002266103129258388413_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 267.31,96.28 267.83,95.86 268.34,95.41 268.82,94.95 269.29,94.47 269.73,93.97 270.16,93.45 270.57,92.92 270.95,92.37  271.31,91.81 271.65,91.23 271.96,90.64 272.26,90.03 272.52,89.42 272.76,88.8 272.98,88.16 273.17,87.52 273.34,86.87  273.48,86.22 273.59,85.56 273.68,84.89 273.74,84.22 273.77,83.56 273.78,82.89 273.76,82.22 273.75,82.06  '
          />
        </g>
        <g id='LWPOLYLINE_00000077281826225007397770000008803172329135179181_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 273.75,82.06 273.65,81.72 273.53,81.38 273.4,81.05 273.26,80.72 273.1,80.4 272.93,80.09 272.75,79.78 272.56,79.48  272.35,79.19 272.13,78.9 271.91,78.63 271.67,78.36 271.42,78.11 271.16,77.86 270.89,77.63 270.61,77.4 270.42,77.26  '
          />
        </g>
        <g id='LWPOLYLINE_00000058589219389307261370000001367644422187542432_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 261.72,77.05 261.43,77.26 261.14,77.48 260.87,77.7 260.6,77.94 260.35,78.19 260.1,78.45 259.87,78.72 259.64,78.99  259.43,79.28 259.23,79.57 259.04,79.88 258.86,80.19 258.7,80.5 258.54,80.83 258.41,81.15 258.28,81.49 258.17,81.83  258.1,82.06  '
          />
        </g>
        <g id='LWPOLYLINE_00000080905696031435595990000000051061457053171335_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 258.1,82.06 258.07,82.73 258.07,83.4 258.1,84.07 258.15,84.73 258.23,85.4 258.34,86.06 258.48,86.72 258.63,87.37  258.82,88.01 259.03,88.65 259.27,89.27 259.53,89.89 259.81,90.5 260.12,91.09 260.46,91.67 260.81,92.24 261.19,92.79  261.59,93.33 262.01,93.85 262.45,94.35 262.92,94.84 263.4,95.31 263.9,95.75 264.41,96.18 264.54,96.28  '
          />
        </g>
        <g id='LINE_00000092437660376970487090000001072831315521636745_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={264.54}
            y1={96.28}
            x2={265.07}
            y2={96.7}
          />
        </g>
        <g id='LINE_00000014614964885622035580000006211026391256188556_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={266.77}
            y1={96.7}
            x2={267.31}
            y2={96.28}
          />
        </g>
        <g id='LWPOLYLINE_00000033360277384270620900000005529748547216459417_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.26,80.04 266.08,80.01 265.9,79.99 265.72,79.98 265.58,79.97  '
          />
        </g>
        <g id='LWPOLYLINE_00000073710598537483326750000006977504793358074016_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.94,80 266.76,80 266.58,80.01 266.41,80.02 266.26,80.04  '
          />
        </g>
        <g id='LINE_00000047783174458175914660000010478917206267242134_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={261.66}
            y1={76.97}
            x2={261.75}
            y2={77.1}
          />
        </g>
        <g id='ARC_00000007408383362609095070000003726586021990821556_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M261.75,77.1c-1.75,1.17-3.03,2.94-3.6,4.97'
          />
        </g>
        <g id='ARC_00000078744792357732393920000003009054812263389373_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M258.16,82.07c-0.33,5.49,2.07,10.79,6.41,14.16'
          />
        </g>
        <g id='LINE_00000158747906668255809500000004376505202337836928_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={264.57}
            y1={96.23}
            x2={265.24}
            y2={96.75}
          />
        </g>
        <g id='ARC_00000164509198262824661480000008874815250630600586_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.24,96.75c0.45,0.13,0.92,0.13,1.37,0'
          />
        </g>
        <g id='LINE_00000003092862654523065670000000764886141444937370_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={266.61}
            y1={96.75}
            x2={267.28}
            y2={96.23}
          />
        </g>
        <g id='ARC_00000053522059424285250550000003596184908120881287_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M267.28,96.23c4.34-3.38,6.74-8.67,6.41-14.16'
          />
        </g>
        <g id='ARC_00000021816501537470311200000007623802285141641112_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M273.69,82.07c-0.54-1.91-1.7-3.59-3.31-4.77'
          />
        </g>
        <g id='LINE_00000036223059117715870100000006145603269086216378_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={270.38}
            y1={77.3}
            x2={270.48}
            y2={77.18}
          />
        </g>
        <g id='LINE_00000003816976231726499750000010617182111776916358_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={270.48}
            y1={77.18}
            x2={269.64}
            y2={77.16}
          />
        </g>
        <g id='LINE_00000042717083186305818450000009910378906081883274_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={262.17}
            y1={76.98}
            x2={261.66}
            y2={76.97}
          />
        </g>
        <g id='ARC_00000009559913434705751110000015933087519582896269_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M271.33,84.46c-0.02-2.48-2.05-4.48-4.53-4.46c-0.18,0-0.36,0.01-0.55,0.04'
          />
        </g>
        <g id='ARC_00000127762505111012529340000008214228899979779000_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.68,93.96c2.96-2.26,4.68-5.78,4.65-9.5'
          />
        </g>
        <g id='ARC_00000022545423819402947040000016107237058449541012_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.93,94.25c0.28,0.01,0.55-0.1,0.75-0.29'
          />
        </g>
        <g id='ARC_00000090260929414280409820000007295626447105930407_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.19,93.92c0.19,0.2,0.46,0.32,0.74,0.33'
          />
        </g>
        <g id='ARC_00000111881299522517360490000001737590119809473447_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M260.99,84.22c-0.2,3.72,1.36,7.31,4.2,9.7'
          />
        </g>
        <g id='ARC_00000050657714827525111660000008139068304017567374_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.26,80.04c-2.44-0.43-4.78,1.19-5.21,3.64c-0.03,0.18-0.05,0.36-0.06,0.54'
          />
        </g>
        <g id='SPLINE_00000127753301538796926330000010209643985104855475_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.24,96.75c-0.07-0.02-0.11-0.04-0.14-0.05'
          />
        </g>
        <g id='SPLINE_00000140004489976839724810000015622042166251731370_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.2,96.74c0.01,0,0.01,0,0.02,0.01s0.01,0,0.02,0.01c0.02,0,0.03,0.01,0.05,0.01c0.02,0,0.04,0.01,0.05,0.01 c0.02,0,0.04,0.01,0.06,0.01c0.02,0,0.04,0.01,0.06,0.01c0.02,0,0.04,0.01,0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000148626337805790171360000015216170386003916461_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.65,96.84c0.09,0.01,0.18,0.02,0.28,0.02'
          />
        </g>
        <g id='SPLINE_00000078747535004331566680000000693209866277297595_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M265.92,96.85c0.1,0,0.19-0.01,0.28-0.02'
          />
        </g>
        <g id='SPLINE_00000101102395977585215900000003826575312628932487_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.36,96.81c0.01,0,0.02,0,0.03-0.01c0.02,0,0.04-0.01,0.06-0.01c0.02,0,0.04-0.01,0.06-0.01c0.02,0,0.04-0.01,0.05-0.01 c0.02,0,0.03-0.01,0.05-0.01c0.01,0,0.02,0,0.02-0.01c0.01,0,0.01,0,0.02-0.01'
          />
        </g>
        <g id='SPLINE_00000179631685222319236910000006910218914983145353_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.75,96.7c-0.03,0.01-0.07,0.03-0.14,0.05'
          />
        </g>
        <g id='SPLINE_00000096758409354481656420000000380487347598198949_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M273.75,82.06c0,0-0.02,0-0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000087378666983998304020000005094865136636288954_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M258.1,82.06c0,0,0.02,0,0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000143595078017252046960000015054152088328595903_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.65,85.42c0.11,0.12,0.2,0.25,0.27,0.4c0.03,0.07,0.06,0.15,0.08,0.23c0.02,0.08,0.03,0.16,0.04,0.24 c0.01,0.16-0.01,0.32-0.05,0.48c-0.04,0.16-0.12,0.3-0.21,0.43c-0.1,0.13-0.21,0.24-0.35,0.34c-0.13,0.09-0.29,0.16-0.44,0.2 c-0.16,0.04-0.32,0.05-0.48,0.03c-0.16-0.02-0.32-0.06-0.46-0.14c-0.15-0.07-0.28-0.17-0.39-0.29'
          />
        </g>
        <g id='SPLINE_00000146496830459574529010000007967976656565712296_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M264.64,87.34c-0.11-0.12-0.2-0.26-0.27-0.41c-0.06-0.15-0.1-0.31-0.11-0.47c0-0.08,0-0.16,0.01-0.24c0-0.04,0.01-0.08,0.02-0.12 c0.01-0.04,0.02-0.08,0.03-0.12s0.02-0.08,0.04-0.11c0.01-0.04,0.03-0.07,0.05-0.11c0.02-0.04,0.04-0.07,0.06-0.1 c0.02-0.03,0.04-0.07,0.07-0.1s0.05-0.06,0.07-0.09s0.05-0.06,0.08-0.09c0.03-0.03,0.06-0.05,0.09-0.08 c0.03-0.02,0.06-0.05,0.1-0.07s0.07-0.04,0.1-0.06c0.03-0.02,0.07-0.04,0.11-0.05c0.07-0.03,0.15-0.06,0.23-0.08 c0.08-0.02,0.16-0.03,0.24-0.04s0.16,0,0.24,0c0.16,0.02,0.32,0.06,0.47,0.13s0.28,0.17,0.39,0.29'
          />
        </g>
        <g id='LINE_00000040535680908775609900000007320369319098167736_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={265.19}
            y1={93.92}
            x2={266.68}
            y2={93.96}
          />
        </g>
        <g id='LWPOLYLINE_00000044865816193689218940000002461815674350961287_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.46,64.36 266.46,64.36 266.46,64.35 266.47,64.35 266.47,64.34 266.47,64.34 266.47,64.33 266.47,64.33 266.47,64.32  266.47,64.32 266.47,64.32 266.48,64.31 266.48,64.31 266.48,64.3 266.48,64.3 266.49,64.3 266.49,64.29 266.49,64.29  266.49,64.29 266.5,64.28 266.5,64.28 266.5,64.28 266.51,64.27 266.51,64.27 266.51,64.27 266.52,64.27 266.52,64.26  266.53,64.26 266.53,64.26 266.53,64.26 266.54,64.26 266.54,64.25 266.55,64.25 266.55,64.25 266.56,64.25 266.56,64.25  266.57,64.25 266.57,64.25 266.57,64.25 266.58,64.25 266.58,64.25  '
          />
        </g>
        <g id='LINE_00000137848126040704968260000003265154161042850991_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={266.46}
            y1={64.36}
            x2={266.46}
            y2={64.36}
          />
        </g>
        <g id='LWPOLYLINE_00000127008027136710025720000005526725012100721538_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.46,64.36 266.46,64.36 266.46,64.36 266.46,64.37 266.46,64.38 266.46,64.38 266.46,64.4 266.46,64.41 266.46,64.42  266.46,64.44 266.46,64.46 266.46,64.48 266.46,64.5 266.46,64.52 266.46,64.55 266.46,64.57 266.46,64.6 266.46,64.63  266.46,64.66 266.46,64.7 266.46,64.73 266.45,64.77 266.45,64.8 266.45,64.84 266.45,64.88 266.45,64.92 266.45,64.97 266.45,65  '
          />
        </g>
        <g id='LWPOLYLINE_00000149341006433853167510000002008929413932081051_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.58,64.25 266.59,64.25 266.59,64.25 266.59,64.25 266.6,64.25 266.6,64.25 266.61,64.25 266.61,64.25 266.62,64.26  266.62,64.26 266.62,64.26 266.63,64.26 266.63,64.26 266.64,64.26 266.64,64.27 266.64,64.27 266.65,64.27 266.65,64.28  266.65,64.28 266.66,64.28 266.66,64.29 266.66,64.29 266.67,64.29 266.67,64.3 266.67,64.3 266.68,64.3 266.68,64.31  266.68,64.31 266.68,64.32 266.68,64.32 266.68,64.32 266.69,64.33 266.69,64.33 266.69,64.34 266.69,64.34 266.69,64.35  266.69,64.35 266.69,64.35 266.69,64.36 266.69,64.36 266.69,64.37  '
          />
        </g>
        <g id='LWPOLYLINE_00000051354408387684948870000004572595648557808280_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.68,65.01 266.68,64.96 266.68,64.92 266.68,64.88 266.68,64.84 266.68,64.8 266.68,64.77 266.68,64.73 266.68,64.7  266.68,64.66 266.69,64.63 266.69,64.6 266.69,64.58 266.69,64.55 266.69,64.52 266.69,64.5 266.69,64.48 266.69,64.46  266.69,64.44 266.69,64.43 266.69,64.41 266.69,64.4 266.69,64.39 266.69,64.38 266.69,64.37 266.69,64.37 266.69,64.37  266.69,64.37  '
          />
        </g>
        <g id='LINE_00000087391598758751745840000010777161339868411287_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={266.69}
            y1={64.37}
            x2={266.69}
            y2={64.37}
          />
        </g>
        <g id='LWPOLYLINE_00000127015181943958862980000004085077096957857410_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.45,65 266.45,65.04 266.45,65.09 266.45,65.13 266.45,65.17 266.44,65.22 266.44,65.26 266.44,65.31 266.44,65.35  266.44,65.4 266.44,65.45 266.44,65.49 266.44,65.54 266.44,65.59 266.43,65.63 266.43,65.68 266.43,65.72 266.43,65.77  266.43,65.82 266.43,65.86 266.43,65.91 266.43,65.95 266.43,65.99 266.43,66.04 266.42,66.08 266.42,66.11  '
          />
        </g>
        <g id='LWPOLYLINE_00000072971649907919379610000000675254740024531080_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 266.65,66.12 266.65,66.08 266.65,66.04 266.65,65.99 266.66,65.95 266.66,65.9 266.66,65.86 266.66,65.81 266.66,65.77  266.66,65.72 266.66,65.68 266.66,65.63 266.66,65.58 266.66,65.54 266.67,65.49 266.67,65.44 266.67,65.4 266.67,65.35  266.67,65.3 266.67,65.26 266.67,65.21 266.67,65.17 266.67,65.13 266.68,65.08 266.68,65.04 266.68,65.01  '
          />
        </g>
        <g id='ELLIPSE_00000085224418591774304840000005426758556846279595_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.68,64.31c-0.02-0.04-0.06-0.06-0.1-0.06'
          />
        </g>
        <g id='ELLIPSE_00000137121819737411604500000001319486400385211520_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.48,64.42c0,0.01,0.01,0.02,0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000018230874871795712520000015924619558482316169_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.58,64.25c-0.04,0-0.08,0.02-0.1,0.05'
          />
        </g>
        <g id='ELLIPSE_00000121989497460993797220000014938214014450748032_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.44,66.14c0.02,0.02,0.06,0.03,0.1,0.03'
          />
        </g>
        <g id='ELLIPSE_00000133513485470246848720000011677657629819000484_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.43,66.1c-0.01,0.01-0.01,0.02,0,0.03'
          />
        </g>
        <g id='ELLIPSE_00000089550534609584111100000014454168842982929559_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.54,66.06c-0.02,0-0.04,0-0.06,0.01'
          />
        </g>
        <g id='ELLIPSE_00000029032294387905785010000004825135375095502518_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.6,66.07c-0.02-0.01-0.04-0.01-0.06-0.01'
          />
        </g>
        <g id='ELLIPSE_00000007424330974768580940000006320154782893071279_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.65,66.13c0.01-0.01,0.01-0.02,0-0.03'
          />
        </g>
        <g id='ELLIPSE_00000011740635654291993950000011464798080346651046_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M266.54,66.17c0.04,0,0.08-0.01,0.1-0.02'
          />
        </g>
        <g id='LINE_00000152966165452404116160000004493658972559906966_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={256.74}
            y1={63.25}
            x2={256.72}
            y2={64.14}
          />
        </g>
        <g id='LINE_00000163791545999453778530000003487060712298513036_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={256.72}
            y1={64.14}
            x2={276.17}
            y2={64.14}
          />
        </g>
        <g id='LINE_00000127740977284945729930000000308641929279642504_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={276.17}
            y1={64.14}
            x2={276.19}
            y2={63.25}
          />
        </g>
        <g id='LINE_00000119840658080220932760000012019188225879824515_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1526.68}
            y1={553.35}
            x2={1531.69}
            y2={558.14}
          />
        </g>
        <g id='LINE_00000148619554861886603350000018219628465808351641_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1545.44}
            y1={544.4}
            x2={1540.42}
            y2={539.6}
          />
        </g>
        <g id='ARC_00000005268936027450574660000010290417963941921454_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1540.42,539.6c-0.21-0.2-0.5-0.31-0.79-0.31'
          />
        </g>
        <g id='LINE_00000137105471803373515160000010068957765110163873_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1539.62}
            y1={539.29}
            x2={1532.39}
            y2={539.46}
          />
        </g>
        <g id='ARC_00000031886608379031212490000015927427743056017295_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1532.39,539.46c-0.29,0.01-0.57,0.13-0.78,0.34'
          />
        </g>
        <g id='LINE_00000173125022471772848350000003506811030219789240_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.61}
            y1={539.81}
            x2={1526.49}
            y2={545.18}
          />
        </g>
        <g id='ARC_00000078765990317466801600000003618207029314825390_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1526.49,545.18c-0.2,0.21-0.31,0.5-0.31,0.79'
          />
        </g>
        <g id='LINE_00000044173463131378697880000010503116696453736107_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1526.18}
            y1={545.97}
            x2={1526.33}
            y2={552.58}
          />
        </g>
        <g id='ARC_00000158710965321493514520000018304686897065210507_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1526.33,552.58c0.01,0.29,0.13,0.57,0.34,0.78'
          />
        </g>
        <g id='SPLINE_00000093866254783461119030000013049599515169427095_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.07,549.74c-0.2,0.21-0.42,0.42-0.64,0.62c-0.22,0.2-0.45,0.39-0.69,0.57c-0.24,0.18-0.48,0.35-0.73,0.51 c-0.25,0.16-0.51,0.31-0.78,0.44c-0.27,0.13-0.54,0.26-0.82,0.36c-0.28,0.1-0.57,0.19-0.86,0.25s-0.59,0.11-0.89,0.11 c-0.15,0-0.3,0-0.45-0.01c-0.15-0.01-0.3-0.03-0.44-0.07s-0.29-0.08-0.43-0.13c-0.07-0.03-0.14-0.06-0.2-0.09 c-0.07-0.03-0.13-0.07-0.19-0.11s-0.12-0.08-0.18-0.13c-0.06-0.05-0.12-0.1-0.17-0.15c-0.05-0.05-0.11-0.11-0.15-0.16 c-0.05-0.06-0.09-0.12-0.14-0.18c-0.04-0.06-0.08-0.12-0.12-0.19c-0.04-0.06-0.07-0.13-0.1-0.2c-0.06-0.14-0.11-0.28-0.15-0.42 c-0.04-0.14-0.07-0.29-0.09-0.44c-0.02-0.15-0.03-0.3-0.03-0.45c0-0.3,0.02-0.6,0.07-0.89c0.05-0.29,0.12-0.58,0.21-0.87 c0.09-0.28,0.2-0.56,0.32-0.84s0.26-0.54,0.41-0.8c0.15-0.26,0.31-0.51,0.48-0.76s0.35-0.48,0.54-0.72s0.39-0.46,0.59-0.67'
          />
        </g>
        <g id='SPLINE_00000047029408974258193370000015945904184487467957_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1531.44,544.36c0.21-0.21,0.42-0.42,0.64-0.62c0.22-0.2,0.45-0.39,0.69-0.57c0.24-0.18,0.48-0.35,0.73-0.51 c0.25-0.16,0.51-0.31,0.78-0.44c0.27-0.13,0.54-0.26,0.82-0.36c0.28-0.1,0.57-0.19,0.86-0.25s0.59-0.1,0.89-0.11 c0.15,0,0.3,0,0.45,0.01c0.15,0.01,0.29,0.03,0.44,0.07c0.15,0.03,0.29,0.08,0.43,0.13c0.07,0.03,0.14,0.06,0.2,0.09 c0.07,0.03,0.13,0.07,0.19,0.11c0.06,0.04,0.12,0.08,0.18,0.13s0.12,0.1,0.17,0.15c0.05,0.05,0.11,0.11,0.15,0.16 c0.05,0.06,0.09,0.12,0.14,0.18c0.04,0.06,0.08,0.12,0.12,0.19c0.04,0.06,0.07,0.13,0.1,0.2c0.06,0.14,0.11,0.28,0.15,0.42 s0.07,0.29,0.09,0.44s0.03,0.3,0.03,0.45c0,0.3-0.02,0.6-0.07,0.89s-0.12,0.58-0.21,0.87s-0.2,0.56-0.32,0.83 c-0.12,0.27-0.26,0.54-0.41,0.8c-0.15,0.26-0.31,0.51-0.48,0.76c-0.17,0.25-0.35,0.48-0.54,0.72c-0.19,0.23-0.39,0.46-0.59,0.67'
          />
        </g>
        <g id='LWPOLYLINE_00000044172483585326309470000014935306109707307952_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1531.06,533.43 1530.6,532.94 1530.13,532.47 1529.63,532.01 1529.12,531.58 1528.6,531.16 1528.05,530.77 1527.5,530.4  1526.92,530.05 1526.34,529.73 1525.74,529.43 1525.13,529.15 1524.51,528.9 1523.88,528.68 1523.24,528.47 1522.59,528.3  1521.94,528.15 1521.28,528.03 1520.62,527.93 1519.95,527.86 1519.28,527.82 1518.61,527.8 1517.94,527.81 1517.27,527.85  1516.61,527.91 1516.45,527.93  '
          />
        </g>
        <g id='LWPOLYLINE_00000068658584614238238740000006803618171123607937_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1532.09,539.18 1532.14,538.83 1532.18,538.47 1532.2,538.12 1532.2,537.76 1532.19,537.41 1532.17,537.05 1532.13,536.69  1532.08,536.34 1532.02,535.99 1531.94,535.64 1531.84,535.3 1531.74,534.96 1531.61,534.62 1531.48,534.29 1531.33,533.97  1531.17,533.65 1531.06,533.43  '
          />
        </g>
        <g id='LWPOLYLINE_00000015319450319223654850000006075470379402508721_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1519.99,544.5 1520.3,544.67 1520.63,544.82 1520.95,544.96 1521.28,545.09 1521.62,545.21 1521.96,545.31 1522.31,545.4  1522.66,545.48 1523.01,545.54 1523.36,545.59 1523.72,545.62 1524.07,545.64 1524.43,545.64 1524.79,545.63 1525.14,545.61  1525.5,545.57 1525.85,545.52 1526.09,545.47  '
          />
        </g>
        <g id='LWPOLYLINE_00000009556964958252094010000017031134798441561989_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1514.49,529.89 1514.42,530.56 1514.37,531.23 1514.36,531.9 1514.37,532.57 1514.41,533.23 1514.47,533.9 1514.56,534.56  1514.68,535.22 1514.82,535.88 1514.99,536.53 1515.19,537.17 1515.41,537.8 1515.65,538.42 1515.92,539.04 1516.22,539.64  1516.54,540.23 1516.88,540.8 1517.24,541.37 1517.63,541.91 1518.04,542.44 1518.47,542.96 1518.92,543.45 1519.39,543.93  1519.87,544.39 1519.99,544.5  '
          />
        </g>
        <g id='LINE_00000114064575032220942220000014861213647158981507_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1514.57}
            y1={529.21}
            x2={1514.49}
            y2={529.89}
          />
        </g>
        <g id='LINE_00000062184857567719981360000002672674732740812952_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1516.45}
            y1={527.93}
            x2={1515.77}
            y2={528.01}
          />
        </g>
        <g id='LWPOLYLINE_00000062871241347540983740000003150552070441473177_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1526.75,540.69 1526.87,540.55 1526.99,540.42 1527.1,540.28 1527.19,540.16  '
          />
        </g>
        <g id='LWPOLYLINE_00000059303004361148698640000018254705273692082354_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1527.19,540.16 1527.33,540.04 1527.46,539.93 1527.59,539.8 1527.7,539.7  '
          />
        </g>
        <g id='LINE_00000046324777902461062380000007508742464403103925_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1526.11}
            y1={545.57}
            x2={1526.49}
            y2={545.18}
          />
        </g>
        <g id='LINE_00000132058272192561789430000007440310464821497242_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.61}
            y1={539.81}
            x2={1532.19}
            y2={539.2}
          />
        </g>
        <g id='LINE_00000042723785005055089110000002212676880694043800_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1532.19}
            y1={539.2}
            x2={1532.04}
            y2={539.17}
          />
        </g>
        <g id='ARC_00000015341504933024587430000011237833117011607469_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1532.04,539.17c0.31-1.96-0.06-3.98-1.03-5.71'
          />
        </g>
        <g id='ARC_00000040543684125101024310000000262787332490411165_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1531.01,533.47c-3.65-4.11-9.09-6.16-14.55-5.48'
          />
        </g>
        <g id='LINE_00000055708518976543315250000016735585942192412551_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1516.46}
            y1={527.99}
            x2={1515.62}
            y2={528.09}
          />
        </g>
        <g id='ARC_00000033358050848916620150000000017837944323549069_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1515.62,528.09c-0.41,0.22-0.75,0.56-0.97,0.97'
          />
        </g>
        <g id='LINE_00000023272032888762236200000001574884010349371046_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1514.65}
            y1={529.06}
            x2={1514.54}
            y2={529.9}
          />
        </g>
        <g id='ARC_00000149365980875295676860000001284921906665123507_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1514.54,529.9c-0.68,5.46,1.37,10.9,5.48,14.55'
          />
        </g>
        <g id='ARC_00000024682969335715386550000004607132966218371492_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1520.02,544.45c1.84,1.03,3.98,1.38,6.05,0.97'
          />
        </g>
        <g id='LINE_00000145756954755130864440000004640988395327912064_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1526.08}
            y1={545.42}
            x2={1526.11}
            y2={545.57}
          />
        </g>
        <g id='ARC_00000133514334286980811590000000878963822405088687_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1520.5,540.93c1.85,1.66,4.69,1.5,6.34-0.34c0.12-0.14,0.24-0.28,0.34-0.43'
          />
        </g>
        <g id='ARC_00000057144341651186121510000009585058933840856502_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1516.61,531.09c-0.32,3.71,1.12,7.35,3.89,9.83'
          />
        </g>
        <g id='ARC_00000162326604044414631340000004324241542113346742_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1516.91,530.34c-0.19,0.2-0.3,0.47-0.29,0.75'
          />
        </g>
        <g id='ARC_00000013185191596711029680000011248903258901631371_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1517.65,530.01c-0.28,0.01-0.55,0.12-0.74,0.33'
          />
        </g>
        <g id='ARC_00000159441117498885015050000009804723916184214698_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1527.65,533.44c-2.61-2.65-6.32-3.92-10-3.43'
          />
        </g>
        <g id='ARC_00000129201890076526875940000015995721404208161470_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1527.19,540.16c1.97-1.51,2.33-4.33,0.82-6.3c-0.11-0.14-0.23-0.28-0.36-0.41'
          />
        </g>
        <g id='SPLINE_00000005224215947749628400000016860179391785258659_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1515.62,528.09c0.06-0.03,0.11-0.05,0.13-0.07c0.01-0.01,0.02-0.01,0.02-0.01c0,0,0,0,0,0c0,0,0,0-0.01,0'
          />
        </g>
        <g id='SPLINE_00000126289160857351550930000012829718284133196950_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1515.65,528.07c-0.01,0-0.01,0.01-0.02,0.01c-0.01,0-0.01,0.01-0.02,0.01c-0.01,0.01-0.03,0.02-0.04,0.02 c-0.02,0.01-0.03,0.02-0.05,0.03c-0.02,0.01-0.03,0.02-0.05,0.03c-0.02,0.01-0.03,0.02-0.05,0.03c-0.02,0.01-0.03,0.02-0.05,0.04'
          />
        </g>
        <g id='SPLINE_00000049939754328543446530000010049476758448056239_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1515.27,528.32c-0.07,0.05-0.14,0.12-0.21,0.18'
          />
        </g>
        <g id='SPLINE_00000150818981527270327600000014897787668830096003_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1515.06,528.5c-0.07,0.07-0.13,0.14-0.18,0.21'
          />
        </g>
        <g id='SPLINE_00000174581758988016336050000000893188682743659444_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1514.8,528.81c-0.01,0.02-0.02,0.03-0.04,0.05c-0.01,0.02-0.02,0.03-0.03,0.05s-0.02,0.03-0.03,0.05 c-0.01,0.02-0.02,0.03-0.03,0.05c-0.01,0.02-0.02,0.03-0.02,0.04c0,0.01-0.01,0.01-0.01,0.02c0,0.01-0.01,0.01-0.01,0.02'
          />
        </g>
        <g id='SPLINE_00000021104265761942537730000006271182045035980428_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1514.58,529.21C1514.57,529.21,1514.57,529.22,1514.58,529.21c0,0,0,0,0.01-0.02c0.01-0.03,0.03-0.07,0.07-0.13'
          />
        </g>
        <g id='SPLINE_00000103961386277434665600000000773908185862239898_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1531.01,533.47c0.03-0.02,0.05-0.03,0.05-0.03'
          />
        </g>
        <g id='SPLINE_00000152266459571736221030000014471882528815924397_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1520.02,544.45c-0.02,0.03-0.03,0.05-0.03,0.05'
          />
        </g>
        <g id='SPLINE_00000152959847553992727890000008084778672762442910_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1523.66,536.08c0,0.16-0.02,0.33-0.07,0.48s-0.13,0.3-0.24,0.43c-0.05,0.06-0.11,0.12-0.17,0.18c-0.06,0.05-0.13,0.1-0.2,0.14 c-0.03,0.02-0.07,0.04-0.11,0.06c-0.04,0.02-0.07,0.03-0.11,0.05c-0.04,0.01-0.08,0.03-0.11,0.04c-0.04,0.01-0.08,0.02-0.12,0.03 c-0.04,0.01-0.08,0.01-0.12,0.02c-0.04,0-0.08,0.01-0.12,0.01c-0.04,0-0.08,0-0.12,0c-0.04,0-0.08-0.01-0.12-0.01 c-0.04-0.01-0.08-0.01-0.12-0.02c-0.04-0.01-0.08-0.02-0.11-0.03c-0.04-0.01-0.07-0.03-0.11-0.04c-0.04-0.02-0.07-0.03-0.11-0.05 c-0.04-0.02-0.07-0.04-0.1-0.06c-0.03-0.02-0.07-0.05-0.1-0.07c-0.06-0.05-0.12-0.11-0.18-0.17c-0.11-0.12-0.2-0.26-0.26-0.41 s-0.09-0.31-0.1-0.48'
          />
        </g>
        <g id='SPLINE_00000072990293291841348070000001839455884577936296_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1520.88,536.14c0-0.16,0.02-0.33,0.07-0.48s0.13-0.3,0.23-0.42c0.1-0.13,0.22-0.23,0.36-0.32c0.14-0.08,0.29-0.14,0.45-0.17 c0.16-0.03,0.32-0.03,0.48-0.01c0.16,0.03,0.32,0.08,0.46,0.16c0.14,0.08,0.27,0.18,0.37,0.3c0.05,0.06,0.1,0.13,0.14,0.19 s0.08,0.14,0.11,0.22c0.06,0.15,0.09,0.31,0.09,0.47'
          />
        </g>
        <g id='LINE_00000031895514653048675710000004230330833471354032_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1517.65}
            y1={530.01}
            x2={1516.61}
            y2={531.09}
          />
        </g>
        <g id='LWPOLYLINE_00000049938095806099493390000011874412496540481721_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1538.58,551.09 1538.58,551.1 1538.57,551.1 1538.57,551.1 1538.57,551.11 1538.56,551.11 1538.56,551.11 1538.56,551.11  1538.55,551.12 1538.55,551.12 1538.54,551.12 1538.54,551.12 1538.53,551.12 1538.53,551.12 1538.53,551.12 1538.52,551.13  1538.52,551.13 1538.51,551.13 1538.51,551.13 1538.5,551.13 1538.5,551.13 1538.49,551.13 1538.49,551.13 1538.49,551.13  1538.48,551.13 1538.48,551.13 1538.47,551.13 1538.47,551.12 1538.46,551.12 1538.46,551.12 1538.45,551.12 1538.45,551.12  1538.45,551.12 1538.44,551.11 1538.44,551.11 1538.43,551.11 1538.43,551.11 1538.43,551.1 1538.42,551.1 1538.42,551.1  1538.42,551.1  '
          />
        </g>
        <g id='LWPOLYLINE_00000132785868321566743160000012386576434850575806_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1537.95,550.65 1537.99,550.68 1538.02,550.71 1538.04,550.74 1538.07,550.77 1538.1,550.79 1538.13,550.82 1538.15,550.84  1538.18,550.87 1538.2,550.89 1538.22,550.91 1538.25,550.93 1538.27,550.95 1538.29,550.97 1538.3,550.99 1538.32,551  1538.34,551.02 1538.35,551.03 1538.36,551.04 1538.38,551.06 1538.39,551.07 1538.39,551.07 1538.4,551.08 1538.41,551.09  1538.41,551.09 1538.42,551.09 1538.42,551.1 1538.42,551.1  '
          />
        </g>
        <g id='LINE_00000020389496774105181220000017228464402444201121_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1538.42}
            y1={551.1}
            x2={1538.42}
            y2={551.1}
          />
        </g>
        <g id='LWPOLYLINE_00000060750776872784712020000008199480418226575271_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1538.58,550.93 1538.58,550.94 1538.58,550.94 1538.59,550.94 1538.59,550.95 1538.59,550.95 1538.59,550.95 1538.6,550.96  1538.6,550.96 1538.6,550.97 1538.6,550.97 1538.6,550.97 1538.61,550.98 1538.61,550.98 1538.61,550.99 1538.61,550.99  1538.61,551 1538.61,551 1538.61,551 1538.61,551.01 1538.61,551.01 1538.61,551.02 1538.61,551.02 1538.61,551.03  1538.61,551.03 1538.61,551.04 1538.61,551.04 1538.61,551.05 1538.61,551.05 1538.6,551.05 1538.6,551.06 1538.6,551.06  1538.6,551.07 1538.6,551.07 1538.59,551.07 1538.59,551.08 1538.59,551.08 1538.59,551.09 1538.58,551.09 1538.58,551.09  1538.58,551.09  '
          />
        </g>
        <g id='LINE_00000018219245099388600950000005866301933183372164_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1538.58}
            y1={550.93}
            x2={1538.58}
            y2={550.93}
          />
        </g>
        <g id='LWPOLYLINE_00000180332724506359133320000004391192113210866598_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1538.58,550.93 1538.58,550.93 1538.57,550.93 1538.57,550.93 1538.57,550.92 1538.56,550.92 1538.55,550.91 1538.54,550.9  1538.53,550.89 1538.52,550.88 1538.51,550.87 1538.49,550.85 1538.48,550.84 1538.46,550.82 1538.44,550.8 1538.42,550.78  1538.4,550.76 1538.38,550.74 1538.36,550.72 1538.33,550.7 1538.31,550.68 1538.28,550.65 1538.25,550.62 1538.23,550.6  1538.2,550.57 1538.17,550.54 1538.14,550.51 1538.11,550.49  '
          />
        </g>
        <g id='LWPOLYLINE_00000062149846707245312180000017684106196486697097_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1537.15,549.89 1537.18,549.91 1537.21,549.94 1537.24,549.97 1537.27,550 1537.31,550.03 1537.34,550.06 1537.37,550.1  1537.4,550.13 1537.44,550.16 1537.47,550.19 1537.5,550.22 1537.54,550.26 1537.57,550.29 1537.61,550.32 1537.64,550.35  1537.67,550.38 1537.71,550.42 1537.74,550.45 1537.77,550.48 1537.8,550.51 1537.84,550.54 1537.87,550.57 1537.9,550.6  1537.93,550.63 1537.95,550.65  '
          />
        </g>
        <g id='LWPOLYLINE_00000128471335133996415330000006243726966083408004_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1538.11,550.49 1538.08,550.46 1538.05,550.43 1538.02,550.4 1537.99,550.37 1537.96,550.34 1537.92,550.31 1537.89,550.28  1537.86,550.25 1537.82,550.21 1537.79,550.18 1537.76,550.15 1537.72,550.12 1537.69,550.09 1537.66,550.05 1537.62,550.02  1537.59,549.99 1537.56,549.96 1537.52,549.93 1537.49,549.89 1537.46,549.86 1537.43,549.83 1537.39,549.8 1537.36,549.77  1537.33,549.74 1537.31,549.72  '
          />
        </g>
        <g id='ELLIPSE_00000134941640721487431590000008079654459940251051_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1538.47,551.12c0.04,0.01,0.08,0,0.11-0.03'
          />
        </g>
        <g id='ELLIPSE_00000085956959335461225760000017126183663527708603_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1538.38,551.02c0,0.01,0,0.02,0,0.03'
          />
        </g>
        <g id='ELLIPSE_00000111913380156213543440000000694583453253522856_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1538.58,551.09c0.03-0.03,0.04-0.07,0.03-0.11'
          />
        </g>
        <g id='ELLIPSE_00000174588895977156818180000006837978049018792634_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.28,549.71c-0.03,0-0.06,0.02-0.09,0.05'
          />
        </g>
        <g id='ELLIPSE_00000061442248568096306850000014629749579096216750_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.32,549.73c0-0.01-0.01-0.02-0.02-0.02'
          />
        </g>
        <g id='ELLIPSE_00000043456140314771947420000004175391243915827336_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.27,549.84c0.01-0.01,0.03-0.03,0.03-0.05'
          />
        </g>
        <g id='ELLIPSE_00000164504423675587131950000014761186520987062932_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.22,549.88c0.02-0.01,0.03-0.02,0.04-0.04'
          />
        </g>
        <g id='ELLIPSE_00000060000519930497999240000010955334506103321237_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.14,549.87c0,0.01,0.01,0.02,0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000168109713904498643820000011449660346013276830_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1537.19,549.77c-0.03,0.03-0.05,0.06-0.05,0.09'
          />
        </g>
        <g id='LINE_00000167366893526564935710000017397391245146924452_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1546.08}
            y1={545}
            x2={1545.44}
            y2={544.4}
          />
        </g>
        <g id='LINE_00000069365394420662535840000012169205846964143029_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1545.44}
            y1={544.4}
            x2={1531.69}
            y2={558.14}
          />
        </g>
        <g id='LINE_00000135685902084072184510000018240713475673952445_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1531.69}
            y1={558.14}
            x2={1532.33}
            y2={558.75}
          />
        </g>
        <g id='LINE_00000151514395919323151730000007543432810140442806_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1234.78}
            y1={555.33}
            x2={1239.71}
            y2={560.21}
          />
        </g>
        <g id='LINE_00000145037311667558897610000014973723868036806029_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1253.69}
            y1={546.7}
            x2={1248.76}
            y2={541.82}
          />
        </g>
        <g id='ARC_00000086649405948219593130000009313040688538764468_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1248.76,541.82c-0.21-0.21-0.49-0.32-0.79-0.32'
          />
        </g>
        <g id='LINE_00000120552427682502668760000011704317448180255622_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1247.97}
            y1={541.5}
            x2={1240.74}
            y2={541.54}
          />
        </g>
        <g id='ARC_00000158741359283011015030000012036653555912293303_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1240.74,541.54c-0.29,0-0.58,0.12-0.78,0.33'
          />
        </g>
        <g id='LINE_00000170996117331295111600000013916928329017597873_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.95}
            y1={541.87}
            x2={1234.73}
            y2={547.15}
          />
        </g>
        <g id='ARC_00000140011044460676429010000000298690989599696259_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1234.73,547.15c-0.21,0.21-0.32,0.49-0.32,0.79'
          />
        </g>
        <g id='LINE_00000047747381240994520070000004838603069928051864_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1234.41}
            y1={547.94}
            x2={1234.45}
            y2={554.55}
          />
        </g>
        <g id='ARC_00000167366548693094994790000004970303348092488597_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1234.45,554.55c0,0.29,0.12,0.58,0.33,0.78'
          />
        </g>
        <g id='SPLINE_00000014615682945836051670000000187468504895121327_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1245.23,551.9c-0.21,0.21-0.43,0.42-0.65,0.61c-0.23,0.2-0.46,0.38-0.7,0.56s-0.49,0.34-0.74,0.5c-0.25,0.16-0.52,0.3-0.78,0.43 c-0.27,0.13-0.54,0.25-0.82,0.34c-0.28,0.1-0.57,0.18-0.86,0.24c-0.29,0.06-0.59,0.09-0.89,0.1c-0.15,0-0.3,0-0.45-0.02 c-0.15-0.02-0.3-0.04-0.44-0.08c-0.14-0.04-0.29-0.08-0.42-0.14c-0.07-0.03-0.14-0.06-0.2-0.1c-0.07-0.03-0.13-0.07-0.19-0.11 s-0.12-0.08-0.18-0.13c-0.06-0.05-0.11-0.1-0.17-0.15c-0.05-0.05-0.1-0.11-0.15-0.17c-0.05-0.06-0.09-0.12-0.13-0.18 c-0.04-0.06-0.08-0.12-0.12-0.19s-0.07-0.13-0.1-0.2c-0.06-0.14-0.11-0.28-0.15-0.42s-0.06-0.29-0.08-0.44 c-0.02-0.15-0.02-0.3-0.02-0.45c0-0.3,0.03-0.6,0.09-0.89c0.06-0.29,0.13-0.58,0.23-0.86c0.1-0.28,0.21-0.56,0.34-0.83 c0.13-0.27,0.27-0.53,0.42-0.79c0.15-0.26,0.32-0.5,0.49-0.75c0.17-0.24,0.36-0.48,0.55-0.71c0.19-0.23,0.4-0.45,0.6-0.66'
          />
        </g>
        <g id='SPLINE_00000150069362805446057260000005009993801415689119_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1239.7,546.42c0.21-0.21,0.43-0.42,0.65-0.61c0.23-0.2,0.46-0.38,0.7-0.56c0.24-0.18,0.49-0.34,0.74-0.5 c0.25-0.16,0.52-0.3,0.78-0.43c0.27-0.13,0.55-0.25,0.83-0.35s0.57-0.18,0.86-0.24c0.29-0.06,0.59-0.09,0.89-0.1 c0.15,0,0.3,0,0.45,0.02c0.15,0.02,0.29,0.04,0.44,0.08s0.29,0.08,0.43,0.14c0.07,0.03,0.14,0.06,0.2,0.1 c0.07,0.04,0.13,0.07,0.19,0.11c0.06,0.04,0.12,0.08,0.18,0.13c0.06,0.05,0.11,0.1,0.17,0.15s0.1,0.11,0.15,0.17 c0.05,0.06,0.09,0.12,0.13,0.18c0.04,0.06,0.08,0.12,0.12,0.19c0.04,0.07,0.07,0.13,0.1,0.2c0.06,0.14,0.11,0.28,0.14,0.42 s0.06,0.29,0.08,0.44c0.02,0.15,0.02,0.3,0.02,0.45c0,0.3-0.03,0.6-0.09,0.89c-0.06,0.29-0.13,0.58-0.23,0.86 c-0.1,0.28-0.21,0.56-0.34,0.83c-0.13,0.27-0.27,0.53-0.42,0.79c-0.15,0.26-0.32,0.51-0.49,0.75c-0.17,0.24-0.36,0.48-0.55,0.71 c-0.19,0.23-0.4,0.45-0.6,0.66'
          />
        </g>
        <g id='LWPOLYLINE_00000147218345260741523600000000508840144575958924_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1239.5,535.49 1239.06,534.99 1238.59,534.51 1238.11,534.05 1237.61,533.6 1237.09,533.18 1236.55,532.78 1236,532.4  1235.43,532.04 1234.85,531.71 1234.26,531.4 1233.65,531.11 1233.04,530.85 1232.41,530.61 1231.77,530.4 1231.13,530.21  1230.48,530.05 1229.82,529.91 1229.16,529.81 1228.5,529.72 1227.83,529.67 1227.16,529.64 1226.49,529.64 1225.82,529.66  1225.15,529.72 1225,529.73  '
          />
        </g>
        <g id='LWPOLYLINE_00000041975148426833301600000002778553995637928890_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1240.44,541.26 1240.5,540.91 1240.54,540.55 1240.56,540.2 1240.58,539.84 1240.57,539.48 1240.56,539.13 1240.52,538.77  1240.48,538.42 1240.42,538.07 1240.35,537.72 1240.26,537.37 1240.16,537.03 1240.04,536.69 1239.91,536.36 1239.77,536.03  1239.62,535.71 1239.5,535.49  '
          />
        </g>
        <g id='LWPOLYLINE_00000160186796789190586140000018226440819405220514_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1228.25,546.36 1228.56,546.53 1228.88,546.7 1229.2,546.84 1229.53,546.98 1229.87,547.1 1230.21,547.21 1230.55,547.31  1230.9,547.39 1231.25,547.46 1231.6,547.51 1231.96,547.55 1232.31,547.57 1232.67,547.58 1233.02,547.58 1233.38,547.56  1233.74,547.53 1234.09,547.48 1234.33,547.44  '
          />
        </g>
        <g id='LWPOLYLINE_00000074425330012400282110000014978808619736732800_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1223,531.66 1222.92,532.33 1222.86,532.99 1222.84,533.66 1222.84,534.33 1222.86,535 1222.91,535.67 1222.99,536.33 1223.1,537  1223.23,537.65 1223.39,538.3 1223.57,538.95 1223.78,539.58 1224.02,540.21 1224.28,540.83 1224.56,541.44 1224.87,542.03  1225.2,542.61 1225.55,543.18 1225.93,543.73 1226.33,544.27 1226.75,544.79 1227.19,545.3 1227.65,545.78 1228.13,546.25  1228.25,546.36  '
          />
        </g>
        <g id='LINE_00000101088836532735891780000003408985823537061561_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1223.1}
            y1={530.99}
            x2={1223}
            y2={531.66}
          />
        </g>
        <g id='LINE_00000128481428670886439740000010599434993227239826_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1225}
            y1={529.73}
            x2={1224.32}
            y2={529.81}
          />
        </g>
        <g id='LWPOLYLINE_00000121263834775739587590000001895280728856766644_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1235.08,542.67 1235.2,542.54 1235.32,542.4 1235.43,542.26 1235.52,542.15  '
          />
        </g>
        <g id='LWPOLYLINE_00000087370983191697897610000008459506537709140662_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1235.52,542.15 1235.66,542.04 1235.8,541.92 1235.93,541.8 1236.04,541.7  '
          />
        </g>
        <g id='LINE_00000098179823591650582650000005218610845826305214_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1234.35}
            y1={547.54}
            x2={1234.73}
            y2={547.15}
          />
        </g>
        <g id='LINE_00000179634467668019915720000010847666873541778086_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.95}
            y1={541.87}
            x2={1240.54}
            y2={541.28}
          />
        </g>
        <g id='LINE_00000019643029961366494240000004742787808773951398_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1240.54}
            y1={541.28}
            x2={1240.39}
            y2={541.25}
          />
        </g>
        <g id='ARC_00000176734807234509610660000014353953611271022742_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1240.39,541.25c0.34-1.96,0.01-3.98-0.93-5.73'
          />
        </g>
        <g id='ARC_00000159451031607264652640000002489740157551146131_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1239.46,535.52c-3.58-4.18-8.99-6.32-14.45-5.73'
          />
        </g>
        <g id='LINE_00000093859182449596352690000002846121557546976179_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1225}
            y1={529.79}
            x2={1224.16}
            y2={529.88}
          />
        </g>
        <g id='ARC_00000103973061033100912480000017520284803687494070_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1224.16,529.88c-0.41,0.21-0.76,0.54-0.98,0.95'
          />
        </g>
        <g id='LINE_00000054231121442116492790000015107426324079590280_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1223.18}
            y1={530.83}
            x2={1223.06}
            y2={531.67}
          />
        </g>
        <g id='ARC_00000080911533305608530460000017245564338625607354_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1223.06,531.67c-0.78,5.44,1.18,10.92,5.22,14.64'
          />
        </g>
        <g id='ARC_00000130646882156723680210000007109216827530225047_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1228.28,546.31c1.82,1.06,3.96,1.45,6.04,1.07'
          />
        </g>
        <g id='LINE_00000100342687610490330500000012286135414020747904_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1234.32}
            y1={547.39}
            x2={1234.35}
            y2={547.54}
          />
        </g>
        <g id='ARC_00000051361253635341584650000004597125064078951605_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1228.82,542.8c1.82,1.69,4.66,1.58,6.35-0.23c0.12-0.13,0.24-0.27,0.35-0.42'
          />
        </g>
        <g id='ARC_00000051357639860596298000000005277892164543120826_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1225.11,532.9c-0.39,3.7,0.99,7.37,3.72,9.9'
          />
        </g>
        <g id='ARC_00000173142899271930095960000001043613199422935230_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1225.41,532.15c-0.2,0.2-0.31,0.47-0.31,0.75'
          />
        </g>
        <g id='ARC_00000144332404821798279450000011513400438762166182_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1226.16,531.84c-0.28,0-0.55,0.11-0.75,0.31'
          />
        </g>
        <g id='ARC_00000078760626620991260350000002446169637030571949_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1236.1,535.44c-2.56-2.7-6.25-4.03-9.94-3.6'
          />
        </g>
        <g id='ARC_00000042004622892843177850000017000720774092830092_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1235.52,542.15c1.99-1.48,2.41-4.29,0.93-6.29c-0.11-0.15-0.23-0.29-0.35-0.42'
          />
        </g>
        <g id='SPLINE_00000052782870192971522060000014626846827887769731_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1224.16,529.88c0.06-0.03,0.11-0.05,0.13-0.06c0.01-0.01,0.02-0.01,0.02-0.01c0,0,0,0,0,0c0,0,0,0-0.01,0'
          />
        </g>
        <g id='SPLINE_00000016776868194186733190000008282473616058436027_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1224.2,529.86c-0.01,0-0.01,0.01-0.02,0.01c-0.01,0-0.01,0.01-0.02,0.01c-0.01,0.01-0.03,0.02-0.05,0.02 c-0.02,0.01-0.03,0.02-0.05,0.03s-0.03,0.02-0.05,0.03s-0.03,0.02-0.05,0.03c-0.02,0.01-0.03,0.02-0.05,0.03'
          />
        </g>
        <g id='SPLINE_00000090293276552465633850000009011097937927197620_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1223.81,530.1c-0.07,0.05-0.14,0.11-0.21,0.18'
          />
        </g>
        <g id='SPLINE_00000013158699083662178170000009813524976112820373_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1223.6,530.28c-0.07,0.07-0.13,0.13-0.19,0.2'
          />
        </g>
        <g id='SPLINE_00000076583882388661604950000015419144163159744437_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1223.33,530.59c-0.01,0.02-0.02,0.03-0.04,0.05c-0.01,0.02-0.02,0.03-0.03,0.05'
          />
        </g>
        <g id='SPLINE_00000054227318642528987280000017785230822416990608_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1223.25,530.71c-0.01,0.01-0.01,0.02-0.02,0.03c-0.01,0.02-0.02,0.03-0.03,0.05c-0.01,0.02-0.02,0.03-0.03,0.04 c0,0.01-0.01,0.01-0.01,0.02c0,0.01-0.01,0.01-0.01,0.02'
          />
        </g>
        <g id='SPLINE_00000089540427722397552520000015781072813438139578_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1223.1,530.98C1223.1,530.99,1223.1,530.99,1223.1,530.98c0,0,0,0,0.01-0.02c0.01-0.03,0.03-0.07,0.07-0.13'
          />
        </g>
        <g id='SPLINE_00000165228929027545229390000014476048792422124949_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1239.46,535.52c0.03-0.02,0.05-0.03,0.05-0.03'
          />
        </g>
        <g id='SPLINE_00000096035792636595846410000012105366615704301459_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1228.28,546.31c-0.02,0.03-0.03,0.05-0.03,0.05'
          />
        </g>
        <g id='SPLINE_00000032626537817095135080000008477306590973700511_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1232.06,538.01c0,0.16-0.03,0.33-0.08,0.48s-0.14,0.3-0.24,0.42c-0.05,0.06-0.11,0.12-0.17,0.17s-0.13,0.1-0.2,0.14 c-0.04,0.02-0.07,0.04-0.11,0.06c-0.04,0.02-0.07,0.03-0.11,0.05c-0.04,0.01-0.08,0.03-0.11,0.04c-0.04,0.01-0.08,0.02-0.12,0.03 c-0.04,0.01-0.08,0.01-0.12,0.02c-0.04,0-0.08,0.01-0.12,0.01c-0.04,0-0.08,0-0.12,0c-0.04,0-0.08-0.01-0.12-0.01 c-0.04-0.01-0.08-0.02-0.12-0.03s-0.08-0.02-0.11-0.03s-0.07-0.03-0.11-0.04c-0.04-0.02-0.07-0.03-0.11-0.05 c-0.03-0.02-0.07-0.04-0.1-0.06c-0.03-0.02-0.07-0.05-0.1-0.07c-0.06-0.05-0.12-0.11-0.17-0.17c-0.11-0.12-0.19-0.27-0.25-0.42 c-0.06-0.15-0.09-0.32-0.09-0.48'
          />
        </g>
        <g id='SPLINE_00000073695798085056964390000009573867601019050907_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1229.28,538.02c0-0.16,0.03-0.32,0.08-0.48c0.05-0.15,0.14-0.3,0.24-0.42c0.1-0.12,0.23-0.23,0.37-0.31 c0.14-0.08,0.29-0.14,0.45-0.17c0.16-0.03,0.32-0.03,0.48,0c0.16,0.03,0.31,0.08,0.45,0.16s0.27,0.19,0.37,0.31 c0.05,0.06,0.1,0.13,0.14,0.2s0.07,0.14,0.1,0.22c0.06,0.15,0.09,0.31,0.09,0.47'
          />
        </g>
        <g id='LINE_00000082367898340852462080000016476979160801931911_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1226.16}
            y1={531.84}
            x2={1225.11}
            y2={532.9}
          />
        </g>
        <g id='LWPOLYLINE_00000053522475843594078500000001868586557718441088_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1246.72,553.28 1246.72,553.28 1246.71,553.29 1246.71,553.29 1246.71,553.29 1246.7,553.29 1246.7,553.3 1246.69,553.3  1246.69,553.3 1246.69,553.3 1246.68,553.31 1246.68,553.31 1246.67,553.31 1246.67,553.31 1246.67,553.31 1246.66,553.31  1246.66,553.31 1246.65,553.31 1246.65,553.31 1246.64,553.31 1246.64,553.31 1246.63,553.31 1246.63,553.31 1246.62,553.31  1246.62,553.31 1246.62,553.31 1246.61,553.31 1246.61,553.31 1246.6,553.31 1246.6,553.31 1246.59,553.3 1246.59,553.3  1246.59,553.3 1246.58,553.3 1246.58,553.3 1246.57,553.29 1246.57,553.29 1246.57,553.29 1246.56,553.28 1246.56,553.28  1246.56,553.28  '
          />
        </g>
        <g id='LWPOLYLINE_00000146458448306736887940000008131326681473116552_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1246.1,552.83 1246.13,552.86 1246.16,552.89 1246.19,552.92 1246.22,552.94 1246.25,552.97 1246.27,553 1246.3,553.02  1246.32,553.05 1246.35,553.07 1246.37,553.09 1246.39,553.11 1246.41,553.13 1246.43,553.15 1246.45,553.17 1246.46,553.19  1246.48,553.2 1246.49,553.21 1246.5,553.23 1246.52,553.24 1246.53,553.25 1246.53,553.26 1246.54,553.26 1246.55,553.27  1246.55,553.28 1246.56,553.28 1246.56,553.28 1246.56,553.28  '
          />
        </g>
        <g id='LINE_00000052066445938540583150000006477113600043924410_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1246.56}
            y1={553.28}
            x2={1246.56}
            y2={553.28}
          />
        </g>
        <g id='LWPOLYLINE_00000004514644790296893200000017201472734770868099_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1246.72,553.12 1246.72,553.12 1246.73,553.13 1246.73,553.13 1246.73,553.13 1246.73,553.14 1246.74,553.14 1246.74,553.14  1246.74,553.15 1246.74,553.15 1246.74,553.16 1246.75,553.16 1246.75,553.16 1246.75,553.17 1246.75,553.17 1246.75,553.18  1246.75,553.18 1246.75,553.19 1246.75,553.19 1246.75,553.2 1246.75,553.2 1246.75,553.21 1246.75,553.21 1246.75,553.21  1246.75,553.22 1246.75,553.22 1246.75,553.23 1246.75,553.23 1246.75,553.24 1246.75,553.24 1246.74,553.25 1246.74,553.25  1246.74,553.25 1246.74,553.26 1246.73,553.26 1246.73,553.27 1246.73,553.27 1246.73,553.27 1246.72,553.28 1246.72,553.28  1246.72,553.28  '
          />
        </g>
        <g id='LINE_00000020357247748859258200000009125677793158244286_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1246.72}
            y1={553.12}
            x2={1246.72}
            y2={553.12}
          />
        </g>
        <g id='LWPOLYLINE_00000111882689228650711910000002150907301120524676_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1246.72,553.12 1246.72,553.12 1246.72,553.12 1246.71,553.11 1246.71,553.11 1246.7,553.1 1246.69,553.09 1246.69,553.09  1246.67,553.08 1246.66,553.06 1246.65,553.05 1246.64,553.04 1246.62,553.02 1246.6,553 1246.59,552.99 1246.57,552.97  1246.55,552.95 1246.53,552.93 1246.5,552.9 1246.48,552.88 1246.45,552.86 1246.43,552.83 1246.4,552.81 1246.38,552.78  1246.35,552.75 1246.32,552.72 1246.29,552.69 1246.26,552.67  '
          />
        </g>
        <g id='LWPOLYLINE_00000091712206253224018550000003091361294462062740_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1245.31,552.05 1245.34,552.08 1245.37,552.11 1245.4,552.14 1245.43,552.17 1245.46,552.2 1245.5,552.23 1245.53,552.26  1245.56,552.29 1245.59,552.33 1245.63,552.36 1245.66,552.39 1245.69,552.42 1245.73,552.46 1245.76,552.49 1245.79,552.52  1245.83,552.56 1245.86,552.59 1245.89,552.62 1245.92,552.65 1245.95,552.68 1245.99,552.71 1246.02,552.75 1246.05,552.78  1246.08,552.81 1246.1,552.83  '
          />
        </g>
        <g id='LWPOLYLINE_00000159448997540513877570000015720088283533349053_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1246.26,552.67 1246.23,552.64 1246.2,552.61 1246.17,552.58 1246.14,552.55 1246.11,552.52 1246.08,552.48 1246.05,552.45  1246.01,552.42 1245.98,552.39 1245.95,552.36 1245.91,552.32 1245.88,552.29 1245.85,552.26 1245.81,552.22 1245.78,552.19  1245.75,552.16 1245.72,552.13 1245.68,552.09 1245.65,552.06 1245.62,552.03 1245.59,552 1245.56,551.97 1245.53,551.94  1245.5,551.91 1245.47,551.89  '
          />
        </g>
        <g id='ELLIPSE_00000181772663542779861700000011375725350375076518_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1246.61,553.31c0.04,0.01,0.08,0,0.11-0.03'
          />
        </g>
        <g id='ELLIPSE_00000060746673136682623240000013960459133503689870_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1246.52,553.2c0,0.01,0,0.02,0,0.03'
          />
        </g>
        <g id='ELLIPSE_00000176008516530954817410000005120356422942741660_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1246.72,553.28c0.03-0.03,0.04-0.07,0.03-0.11'
          />
        </g>
        <g id='ELLIPSE_00000137115142558459298850000014457378828588608652_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1245.44,551.88c-0.03,0-0.06,0.02-0.09,0.05'
          />
        </g>
        <g id='ELLIPSE_00000070090621683323909780000002934380437148200095_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1245.48,551.9c0-0.01-0.01-0.02-0.02-0.02'
          />
        </g>
        <g id='ELLIPSE_00000054248064005761301330000012304556669632255909_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1245.43,552c0.01-0.01,0.03-0.03,0.03-0.05'
          />
        </g>
        <g id='ELLIPSE_00000010272454906694649710000006367610675102506680_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1245.38,552.04c0.02-0.01,0.03-0.02,0.05-0.04'
          />
        </g>
        <g id='ELLIPSE_00000054954246613751150340000003516588271364444054_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1245.3,552.03c0,0.01,0.01,0.02,0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000062193974962833076750000005099107698771328389_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1245.35,551.93c-0.03,0.03-0.05,0.06-0.05,0.09'
          />
        </g>
        <g id='LINE_00000125565208635662398800000005790523387058998949_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1254.32}
            y1={547.32}
            x2={1253.69}
            y2={546.7}
          />
        </g>
        <g id='LINE_00000082326574837407810300000003050142862475820977_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1253.69}
            y1={546.7}
            x2={1239.71}
            y2={560.21}
          />
        </g>
        <g id='LINE_00000018220880898495237570000007658878063386586276_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1239.71}
            y1={560.21}
            x2={1240.34}
            y2={560.83}
          />
        </g>
        <g id='ARC_00000149347630854095998360000006310499758655250069_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1298.05,556.03c0.21-0.21,0.33-0.49,0.33-0.78'
          />
        </g>
        <g id='LINE_00000101103439758406868320000002652783136685106856_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1298.38}
            y1={555.24}
            x2={1298.41}
            y2={548.64}
          />
        </g>
        <g id='ARC_00000111193245700813447030000006083272948271793845_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1298.41,548.64c0-0.29-0.11-0.58-0.32-0.79'
          />
        </g>
        <g id='LINE_00000034076748913173670590000015998702210172016291_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1298.09}
            y1={547.85}
            x2={1292.87}
            y2={542.57}
          />
        </g>
        <g id='ARC_00000089543007658625534550000015661899215299589053_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1292.87,542.57c-0.21-0.21-0.49-0.33-0.78-0.33'
          />
        </g>
        <g id='LINE_00000083801520730713206950000002444050945634055088_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.09}
            y1={542.24}
            x2={1284.85}
            y2={542.2}
          />
        </g>
        <g id='ARC_00000156585591735643394950000013046596764474912185_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1284.85,542.2c-0.29,0-0.58,0.11-0.79,0.32'
          />
        </g>
        <g id='LINE_00000107585132283280647150000014389965469587223736_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1284.07}
            y1={542.52}
            x2={1279.13}
            y2={547.4}
          />
        </g>
        <g id='LINE_00000090258177515979172310000000723214710702007736_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1293.12}
            y1={560.9}
            x2={1298.05}
            y2={556.03}
          />
        </g>
        <g id='SPLINE_00000174600896825840485800000014615966777529963173_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1287.59,552.59c-0.21-0.21-0.41-0.43-0.6-0.66c-0.19-0.23-0.38-0.46-0.55-0.71c-0.17-0.24-0.34-0.49-0.49-0.75 c-0.15-0.26-0.29-0.52-0.42-0.79s-0.24-0.54-0.34-0.83c-0.1-0.28-0.17-0.57-0.23-0.86s-0.09-0.59-0.09-0.89 c0-0.15,0.01-0.3,0.02-0.45s0.04-0.29,0.08-0.44s0.09-0.29,0.15-0.42c0.03-0.07,0.06-0.14,0.1-0.2c0.04-0.07,0.07-0.13,0.12-0.19 c0.04-0.06,0.09-0.12,0.13-0.18c0.05-0.06,0.1-0.11,0.15-0.17c0.05-0.05,0.11-0.1,0.17-0.15c0.06-0.05,0.12-0.09,0.18-0.13 c0.06-0.04,0.13-0.08,0.19-0.11c0.07-0.04,0.13-0.07,0.2-0.1c0.14-0.06,0.28-0.11,0.43-0.14c0.14-0.04,0.29-0.06,0.44-0.08 c0.15-0.02,0.3-0.02,0.45-0.02c0.3,0,0.6,0.04,0.89,0.1s0.58,0.14,0.86,0.24c0.28,0.1,0.56,0.22,0.83,0.35 c0.27,0.13,0.53,0.27,0.78,0.43c0.25,0.16,0.5,0.32,0.74,0.5c0.24,0.18,0.47,0.36,0.7,0.56c0.23,0.2,0.44,0.4,0.65,0.61'
          />
        </g>
        <g id='SPLINE_00000006689246548765611860000017364000009403348368_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1293.13,547.12c0.21,0.21,0.41,0.43,0.6,0.66c0.19,0.23,0.38,0.46,0.55,0.71c0.17,0.24,0.34,0.49,0.49,0.75 c0.15,0.26,0.29,0.52,0.42,0.79c0.13,0.27,0.24,0.55,0.34,0.83c0.1,0.28,0.17,0.57,0.23,0.86c0.06,0.29,0.09,0.59,0.09,0.89 c0,0.15-0.01,0.3-0.02,0.45c-0.02,0.15-0.04,0.29-0.08,0.44c-0.04,0.14-0.09,0.29-0.15,0.42c-0.03,0.07-0.06,0.14-0.1,0.2 s-0.07,0.13-0.12,0.19c-0.04,0.06-0.09,0.12-0.13,0.18s-0.1,0.11-0.15,0.17s-0.11,0.1-0.17,0.15c-0.06,0.05-0.12,0.09-0.18,0.13 c-0.06,0.04-0.12,0.08-0.19,0.11c-0.07,0.03-0.13,0.07-0.2,0.1c-0.14,0.06-0.28,0.1-0.43,0.14c-0.14,0.04-0.29,0.06-0.44,0.08 c-0.15,0.02-0.3,0.02-0.45,0.02c-0.3,0-0.6-0.04-0.89-0.1c-0.29-0.06-0.58-0.14-0.86-0.24c-0.28-0.1-0.56-0.22-0.82-0.34 s-0.53-0.27-0.78-0.43c-0.25-0.16-0.5-0.32-0.74-0.5c-0.24-0.18-0.47-0.36-0.7-0.56s-0.44-0.4-0.65-0.61'
          />
        </g>
        <g id='LWPOLYLINE_00000029034803527446376160000001815171332360794032_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1307.83,530.43 1307.16,530.37 1306.49,530.34 1305.82,530.33 1305.15,530.35 1304.48,530.4 1303.82,530.48 1303.16,530.58  1302.5,530.71 1301.85,530.86 1301.2,531.05 1300.56,531.25 1299.93,531.48 1299.32,531.74 1298.71,532.02 1298.11,532.33  1297.53,532.66 1296.96,533.01 1296.4,533.38 1295.86,533.78 1295.34,534.2 1294.83,534.63 1294.34,535.09 1293.87,535.57  1293.42,536.06 1293.32,536.18  '
          />
        </g>
        <g id='LWPOLYLINE_00000118368141023673791060000016983266598910976701_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1293.32,536.18 1293.16,536.5 1293.01,536.82 1292.87,537.15 1292.74,537.49 1292.63,537.83 1292.54,538.17 1292.45,538.52  1292.38,538.87 1292.33,539.22 1292.29,539.57 1292.26,539.93 1292.25,540.29 1292.25,540.64 1292.27,541 1292.3,541.36  1292.34,541.71 1292.38,541.95  '
          />
        </g>
        <g id='LWPOLYLINE_00000156566916166691819990000001396558932704913590_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1298.5,548.14 1298.85,548.19 1299.2,548.24 1299.56,548.26 1299.92,548.28 1300.27,548.28 1300.63,548.26 1300.98,548.23  1301.34,548.19 1301.69,548.13 1302.04,548.06 1302.39,547.97 1302.73,547.87 1303.07,547.76 1303.4,547.63 1303.73,547.49  1304.05,547.34 1304.37,547.17 1304.58,547.05  '
          />
        </g>
        <g id='LWPOLYLINE_00000011750165320230507560000008134765769788183989_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1304.58,547.05 1305.06,546.59 1305.53,546.11 1305.97,545.61 1306.4,545.09 1306.8,544.56 1307.18,544.01 1307.54,543.44  1307.88,542.86 1308.19,542.27 1308.48,541.67 1308.75,541.05 1308.99,540.43 1309.21,539.79 1309.4,539.15 1309.56,538.5  1309.7,537.85 1309.81,537.19 1309.89,536.52 1309.95,535.85 1309.99,535.18 1309.99,534.52 1309.97,533.85 1309.92,533.18  1309.85,532.51 1309.82,532.36  '
          />
        </g>
        <g id='LINE_00000139976174773291213790000006465770415775619974_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1309.82}
            y1={532.36}
            x2={1309.73}
            y2={531.68}
          />
        </g>
        <g id='LINE_00000005971653266371709860000006166000017263529876_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1308.5}
            y1={530.5}
            x2={1307.83}
            y2={530.43}
          />
        </g>
        <g id='LWPOLYLINE_00000147182455096206256010000002195802593446419355_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1297.3,542.84 1297.41,542.98 1297.53,543.12 1297.65,543.26 1297.75,543.36  '
          />
        </g>
        <g id='LWPOLYLINE_00000024710714723984151770000014020020738205103000_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1296.79,542.39 1296.91,542.52 1297.05,542.64 1297.19,542.75 1297.3,542.84  '
          />
        </g>
        <g id='LINE_00000082327264884624567830000005943661859405734030_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1298.48}
            y1={548.24}
            x2={1298.51}
            y2={548.08}
          />
        </g>
        <g id='ARC_00000129204917783561615820000001084918104242929045_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1298.51,548.08c2.08,0.37,4.22-0.01,6.04-1.07'
          />
        </g>
        <g id='ARC_00000045607271214121860220000009162924513522926253_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1304.54,547.01c4.05-3.72,6-9.2,5.22-14.64'
          />
        </g>
        <g id='LINE_00000145051420539977171930000011082213285316316042_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1309.77}
            y1={532.36}
            x2={1309.65}
            y2={531.52}
          />
        </g>
        <g id='ARC_00000021839277727444404720000000402567734507821704_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1309.65,531.52c-0.23-0.41-0.57-0.74-0.98-0.95'
          />
        </g>
        <g id='LINE_00000161600337036847808390000017346805573806188735_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1308.66}
            y1={530.57}
            x2={1307.82}
            y2={530.48}
          />
        </g>
        <g id='ARC_00000067938979163167958450000017272567660225539774_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1307.82,530.48c-5.47-0.59-10.87,1.56-14.45,5.73'
          />
        </g>
        <g id='ARC_00000153693526305493775670000017947447272070825389_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1293.37,536.22c-0.94,1.75-1.27,3.77-0.93,5.73'
          />
        </g>
        <g id='LINE_00000033368975017268954950000000021405711071878017_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.44}
            y1={541.94}
            x2={1292.28}
            y2={541.97}
          />
        </g>
        <g id='LINE_00000065768108587465564420000010878592679708249500_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.28}
            y1={541.97}
            x2={1292.87}
            y2={542.57}
          />
        </g>
        <g id='LINE_00000083783043191611396930000005111322476187879087_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1298.09}
            y1={547.85}
            x2={1298.48}
            y2={548.24}
          />
        </g>
        <g id='ARC_00000079477084154024632990000002890562071213642654_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1296.72,536.14c-1.71,1.8-1.64,4.64,0.16,6.35c0.13,0.13,0.27,0.24,0.42,0.35'
          />
        </g>
        <g id='ARC_00000137846617424903919130000010320187628727066782_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1306.67,532.53c-3.7-0.43-7.38,0.91-9.94,3.6'
          />
        </g>
        <g id='ARC_00000104670902128274038310000006181990553735985037_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1307.41,532.85c-0.2-0.2-0.47-0.31-0.75-0.31'
          />
        </g>
        <g id='ARC_00000110468507167757831040000015565941240254356152_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1307.72,533.6c0-0.28-0.11-0.55-0.31-0.75'
          />
        </g>
        <g id='ARC_00000008863031304408270730000016815408330336925342_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1304,543.5c2.73-2.53,4.1-6.2,3.72-9.9'
          />
        </g>
        <g id='ARC_00000002368940552391463930000017989285182663986576_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1297.3,542.84c1.46,2.01,4.26,2.46,6.27,1c0.15-0.11,0.29-0.22,0.42-0.35'
          />
        </g>
        <g id='SPLINE_00000053522153224429839360000015290066277803904148_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1309.65,531.52c0.03,0.06,0.06,0.11,0.07,0.13c0.01,0.01,0.01,0.02,0.01,0.02c0,0,0,0,0,0c0,0,0,0,0-0.01'
          />
        </g>
        <g id='SPLINE_00000095333697644128127920000015051083473913991871_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1309.67,531.56c0-0.01-0.01-0.01-0.01-0.02s-0.01-0.01-0.01-0.02c-0.01-0.01-0.02-0.03-0.03-0.04c-0.01-0.02-0.02-0.03-0.03-0.05 s-0.02-0.03-0.03-0.05s-0.02-0.03-0.03-0.05c-0.01-0.02-0.02-0.03-0.04-0.05'
          />
        </g>
        <g id='SPLINE_00000089550317947000717350000005413812738044437906_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1309.41,531.18c-0.06-0.07-0.12-0.14-0.19-0.2'
          />
        </g>
        <g id='SPLINE_00000061449656724437260080000012325840201257221270_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1309.23,530.98c-0.07-0.07-0.14-0.13-0.21-0.18'
          />
        </g>
        <g id='SPLINE_00000015341582208267980360000004204828152095390115_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1308.91,530.72c-0.02-0.01-0.03-0.02-0.05-0.03c-0.02-0.01-0.03-0.02-0.05-0.03c-0.02-0.01-0.03-0.02-0.05-0.03 c-0.02-0.01-0.03-0.02-0.05-0.03c-0.02-0.01-0.03-0.02-0.05-0.02c-0.01,0-0.01-0.01-0.02-0.01s-0.01-0.01-0.02-0.01'
          />
        </g>
        <g id='SPLINE_00000161598656557305842990000017117054759640424124_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1308.51,530.5C1308.5,530.5,1308.5,530.5,1308.51,530.5c0,0,0,0,0.02,0.01c0.03,0.01,0.07,0.03,0.13,0.06'
          />
        </g>
        <g id='SPLINE_00000055696150555582601660000001914640122026574764_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1293.32,536.18c0,0,0.02,0.01,0.05,0.03'
          />
        </g>
        <g id='SPLINE_00000126325354376163577020000013701774356775853484_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1304.58,547.05c0,0-0.01-0.02-0.03-0.05'
          />
        </g>
        <g id='SPLINE_00000130637032672745910930000007244290615656503479_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1300.76,538.7c0-0.16,0.03-0.32,0.09-0.47c0.03-0.08,0.06-0.15,0.1-0.22c0.04-0.07,0.09-0.14,0.14-0.2 c0.1-0.12,0.23-0.23,0.37-0.31s0.29-0.14,0.45-0.16c0.16-0.03,0.32-0.03,0.48,0s0.31,0.09,0.45,0.17 c0.14,0.08,0.27,0.19,0.37,0.31c0.1,0.12,0.19,0.27,0.24,0.42c0.05,0.15,0.08,0.32,0.08,0.48'
          />
        </g>
        <g id='SPLINE_00000059285397062340034250000002866564458543886253_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1303.54,538.72c0,0.16-0.03,0.33-0.09,0.48s-0.14,0.3-0.25,0.42c-0.05,0.06-0.11,0.12-0.17,0.17c-0.03,0.03-0.06,0.05-0.1,0.07 c-0.03,0.02-0.07,0.04-0.1,0.06c-0.03,0.02-0.07,0.04-0.11,0.05c-0.04,0.02-0.07,0.03-0.11,0.04s-0.08,0.02-0.11,0.03 c-0.04,0.01-0.08,0.02-0.12,0.02s-0.08,0.01-0.12,0.01c-0.04,0-0.08,0-0.12,0c-0.04,0-0.08,0-0.12-0.01 c-0.04,0-0.08-0.01-0.12-0.02c-0.04-0.01-0.08-0.02-0.12-0.03c-0.04-0.01-0.08-0.02-0.11-0.04c-0.08-0.03-0.15-0.06-0.22-0.1 s-0.14-0.09-0.2-0.14c-0.06-0.05-0.12-0.11-0.17-0.17c-0.11-0.12-0.19-0.27-0.24-0.42c-0.06-0.15-0.08-0.32-0.08-0.48'
          />
        </g>
        <g id='LINE_00000130625580488946906840000012690061989411635361_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1307.72}
            y1={533.6}
            x2={1306.67}
            y2={532.53}
          />
        </g>
        <g id='LWPOLYLINE_00000176752881062222161370000014202875144237275309_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1286.27,553.98 1286.26,553.98 1286.26,553.98 1286.26,553.98 1286.25,553.99 1286.25,553.99 1286.24,553.99 1286.24,553.99  1286.24,554 1286.23,554 1286.23,554 1286.22,554 1286.22,554 1286.21,554 1286.21,554.01 1286.21,554.01 1286.2,554.01  1286.2,554.01 1286.19,554.01 1286.19,554.01 1286.18,554.01 1286.18,554.01 1286.17,554.01 1286.17,554.01 1286.16,554.01  1286.16,554.01 1286.16,554 1286.15,554 1286.15,554 1286.14,554 1286.14,554 1286.13,554 1286.13,553.99 1286.13,553.99  1286.12,553.99 1286.12,553.99 1286.12,553.98 1286.11,553.98 1286.11,553.98 1286.11,553.98 1286.1,553.97  '
          />
        </g>
        <g id='LINE_00000179605372535032373320000007256721332700952204_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1286.27}
            y1={553.98}
            x2={1286.27}
            y2={553.98}
          />
        </g>
        <g id='LWPOLYLINE_00000054967833639446301140000004843309595644308868_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1286.27,553.98 1286.27,553.97 1286.27,553.97 1286.27,553.97 1286.28,553.96 1286.28,553.96 1286.29,553.95 1286.3,553.94  1286.31,553.93 1286.32,553.92 1286.33,553.91 1286.35,553.89 1286.36,553.88 1286.38,553.86 1286.4,553.84 1286.42,553.82  1286.44,553.8 1286.46,553.78 1286.48,553.76 1286.51,553.74 1286.53,553.71 1286.56,553.69 1286.58,553.66 1286.61,553.64  1286.64,553.61 1286.67,553.58 1286.7,553.55 1286.72,553.52  '
          />
        </g>
        <g id='LWPOLYLINE_00000127741827779796179120000003709022486274161321_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1286.1,553.97 1286.1,553.97 1286.1,553.97 1286.1,553.96 1286.09,553.96 1286.09,553.96 1286.09,553.95 1286.09,553.95  1286.08,553.95 1286.08,553.94 1286.08,553.94 1286.08,553.93 1286.08,553.93 1286.08,553.92 1286.07,553.92 1286.07,553.91  1286.07,553.91 1286.07,553.91 1286.07,553.9 1286.07,553.9 1286.07,553.89 1286.07,553.89 1286.07,553.88 1286.07,553.88  1286.07,553.87 1286.07,553.87 1286.08,553.87 1286.08,553.86 1286.08,553.86 1286.08,553.85 1286.08,553.85 1286.08,553.84  1286.09,553.84 1286.09,553.84 1286.09,553.83 1286.09,553.83 1286.1,553.82 1286.1,553.82 1286.1,553.82 1286.1,553.81  1286.11,553.81  '
          />
        </g>
        <g id='LWPOLYLINE_00000146476568557698133630000008078588753446488453_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1286.56,553.36 1286.53,553.39 1286.5,553.42 1286.47,553.45 1286.45,553.48 1286.42,553.5 1286.39,553.53 1286.37,553.56  1286.34,553.58 1286.32,553.6 1286.3,553.62 1286.27,553.65 1286.25,553.67 1286.24,553.68 1286.22,553.7 1286.2,553.72  1286.19,553.73 1286.17,553.75 1286.16,553.76 1286.15,553.77 1286.14,553.78 1286.13,553.79 1286.12,553.8 1286.12,553.8  1286.11,553.81 1286.11,553.81 1286.11,553.81 1286.11,553.81  '
          />
        </g>
        <g id='LINE_00000142168978097524020020000006052196320304410557_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1286.11}
            y1={553.81}
            x2={1286.11}
            y2={553.81}
          />
        </g>
        <g id='LWPOLYLINE_00000013901658905483037410000017638050589116744351_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1286.72,553.52 1286.75,553.49 1286.78,553.46 1286.81,553.43 1286.84,553.4 1286.88,553.37 1286.91,553.34 1286.94,553.31  1286.97,553.28 1287,553.24 1287.04,553.21 1287.07,553.18 1287.1,553.15 1287.14,553.11 1287.17,553.08 1287.2,553.05  1287.24,553.02 1287.27,552.98 1287.3,552.95 1287.33,552.92 1287.37,552.89 1287.4,552.86 1287.43,552.83 1287.46,552.8  1287.49,552.77 1287.51,552.74  '
          />
        </g>
        <g id='LWPOLYLINE_00000116222654190010660150000001002342114187006615_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1287.35,552.58 1287.32,552.61 1287.29,552.64 1287.26,552.67 1287.23,552.7 1287.2,552.73 1287.17,552.76 1287.14,552.79  1287.1,552.83 1287.07,552.86 1287.04,552.89 1287,552.92 1286.97,552.96 1286.94,552.99 1286.9,553.02 1286.87,553.06  1286.84,553.09 1286.81,553.12 1286.77,553.15 1286.74,553.18 1286.71,553.22 1286.68,553.25 1286.65,553.28 1286.62,553.31  1286.59,553.34 1286.56,553.36  '
          />
        </g>
        <g id='ELLIPSE_00000065775049485778634320000010362530942690426766_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1286.08,553.86c-0.01,0.04,0,0.08,0.03,0.11'
          />
        </g>
        <g id='ELLIPSE_00000121280143551712567800000015203428500374718351_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1286.3,553.92c0-0.01,0-0.02,0-0.03'
          />
        </g>
        <g id='ELLIPSE_00000000199945033964875470000002144830420801214643_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1286.1,553.97c0.03,0.03,0.07,0.04,0.11,0.03'
          />
        </g>
        <g id='ELLIPSE_00000022540287083355134070000004544553305174325137_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1287.52,552.71c0-0.03-0.02-0.06-0.05-0.09'
          />
        </g>
        <g id='ELLIPSE_00000048491314017238338990000006634420460187791010_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1287.5,552.75c0.01,0,0.02-0.01,0.02-0.02'
          />
        </g>
        <g id='ELLIPSE_00000181049974142155012620000004544857049923155638_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1287.39,552.7c0.01,0.01,0.03,0.03,0.05,0.04'
          />
        </g>
        <g id='ELLIPSE_00000127045080167660688050000005636490142051313792_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1287.36,552.65c0.01,0.02,0.02,0.03,0.03,0.05'
          />
        </g>
        <g id='ELLIPSE_00000157291869908536651280000004496168278030979462_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1287.37,552.57c-0.01,0-0.02,0.01-0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000087409397349440925150000016787675159106885021_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1287.47,552.62c-0.03-0.03-0.06-0.05-0.09-0.05'
          />
        </g>
        <g id='LINE_00000031208985351822039950000003026826273439708053_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1292.54}
            y1={561.47}
            x2={1293.12}
            y2={560.9}
          />
        </g>
        <g id='LINE_00000175320763087335585260000014355895251881473930_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1293.12}
            y1={560.9}
            x2={1279.13}
            y2={547.4}
          />
        </g>
        <g id='LINE_00000016780153235193665610000009042983528394922383_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1279.13}
            y1={547.4}
            x2={1278.64}
            y2={547.88}
          />
        </g>
        <g id='LINE_00000035493522763621574030000001283299177752967862_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={460.69}
            y1={73.28}
            x2={458.47}
            y2={71.74}
          />
        </g>
        <g id='LINE_00000089567185003827007960000015006466895983562174_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={461.8}
            y1={74.39}
            x2={460.69}
            y2={73.28}
          />
        </g>
        <g id='LINE_00000139259362837328867100000009821071349652638851_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={462.36}
            y1={75.36}
            x2={461.8}
            y2={74.39}
          />
        </g>
        <g id='LINE_00000003798286943085329290000001398490565178205356_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={462.91}
            y1={76.89}
            x2={462.36}
            y2={75.36}
          />
        </g>
        <g id='LINE_00000003090845492024200860000013737067639739222147_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={462.91}
            y1={78}
            x2={462.91}
            y2={76.89}
          />
        </g>
        <g id='LINE_00000026844566235711499870000017908385780162109851_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={462.36}
            y1={79.53}
            x2={462.91}
            y2={78}
          />
        </g>
        <g id='LINE_00000054987149346261522090000000623108052256967843_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={461.8}
            y1={80.49}
            x2={462.36}
            y2={79.53}
          />
        </g>
        <g id='LINE_00000169538866741983434370000016935217956295356863_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={460.69}
            y1={81.6}
            x2={461.8}
            y2={80.49}
          />
        </g>
        <g id='LINE_00000120536683649097049010000013142527818300827530_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={458.47}
            y1={83.14}
            x2={460.69}
            y2={81.6}
          />
        </g>
        <g id='LINE_00000035525290137138679910000008933066044682907012_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={444.01}
            y1={71.65}
            x2={443.45}
            y2={73.18}
          />
        </g>
        <g id='LINE_00000052800330737415439700000002570517125674138266_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={443.45}
            y1={73.18}
            x2={442.9}
            y2={76.33}
          />
        </g>
        <g id='LINE_00000096044367625311637960000000210969364510020016_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={442.9}
            y1={76.33}
            x2={442.9}
            y2={78.55}
          />
        </g>
        <g id='LINE_00000070098862678210711360000000999216811761282433_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={442.9}
            y1={78.55}
            x2={443.45}
            y2={81.71}
          />
        </g>
        <g id='LINE_00000055683706006433359590000015742062937648882085_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={443.45}
            y1={81.71}
            x2={444.01}
            y2={83.23}
          />
        </g>
        <g id='LINE_00000057831878965267255050000007961434823408016798_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={458.47}
            y1={71.74}
            x2={456.24}
            y2={70.7}
          />
        </g>
        <g id='LINE_00000044870693079757056600000011783043305795217792_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={456.24}
            y1={70.7}
            x2={454.02}
            y2={69.89}
          />
        </g>
        <g id='LINE_00000181048955448380918070000002253591330583873164_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={454.02}
            y1={69.89}
            x2={451.78}
            y2={69.29}
          />
        </g>
        <g id='LINE_00000039846524153291889830000013780560786606851750_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={451.78}
            y1={69.29}
            x2={449.57}
            y2={69.1}
          />
        </g>
        <g id='LINE_00000105428262810972251060000017479330265368642746_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={449.57}
            y1={69.1}
            x2={448.46}
            y2={69.2}
          />
        </g>
        <g id='LINE_00000055698972308474300710000000469776740670507191_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={448.46}
            y1={69.2}
            x2={447.35}
            y2={69.39}
          />
        </g>
        <g id='LINE_00000004504072588520931240000011634517390169235841_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={447.35}
            y1={69.39}
            x2={446.23}
            y2={69.69}
          />
        </g>
        <g id='LINE_00000132792957398811951590000014633868558579686824_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={446.23}
            y1={69.69}
            x2={445.12}
            y2={70.32}
          />
        </g>
        <g id='LINE_00000118375878084890651830000013640876244123764157_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={445.12}
            y1={70.32}
            x2={444.01}
            y2={71.65}
          />
        </g>
        <g id='LINE_00000156564747745392114340000017852523579904847275_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={456.24}
            y1={84.18}
            x2={458.47}
            y2={83.14}
          />
        </g>
        <g id='LINE_00000063608724822348275410000015321426616827586688_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={454.02}
            y1={84.99}
            x2={456.24}
            y2={84.18}
          />
        </g>
        <g id='LINE_00000169545746318507282790000006737238977856993197_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={451.78}
            y1={85.59}
            x2={454.02}
            y2={84.99}
          />
        </g>
        <g id='LINE_00000005975235386207259000000010227285491320542359_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={449.57}
            y1={85.78}
            x2={451.78}
            y2={85.59}
          />
        </g>
        <g id='LINE_00000026871051846963539750000012662883998577943942_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={448.46}
            y1={85.69}
            x2={449.57}
            y2={85.78}
          />
        </g>
        <g id='LINE_00000023261893813048091330000003517360379670421172_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={447.35}
            y1={85.49}
            x2={448.46}
            y2={85.69}
          />
        </g>
        <g id='LINE_00000111882978926646560590000007974619272841773440_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={446.23}
            y1={85.19}
            x2={447.35}
            y2={85.49}
          />
        </g>
        <g id='LINE_00000134233742868312669130000017660770697773166263_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={445.12}
            y1={84.56}
            x2={446.23}
            y2={85.19}
          />
        </g>
        <g id='LINE_00000036219302463271754490000003463430235812319144_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={444.01}
            y1={83.23}
            x2={445.12}
            y2={84.56}
          />
        </g>
        <g id='LINE_00000107568326782727497110000013581109974435506609_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={440.65}
            y1={72.04}
            x2={444.01}
            y2={71.65}
          />
        </g>
        <g id='LINE_00000006674044246386536010000013844229553721436585_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={440.65}
            y1={82.85}
            x2={444.01}
            y2={83.23}
          />
        </g>
        <g id='ARC_00000096778124919127659870000007198494721861484427_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M440.65,70.49c0-2.29-1.86-4.14-4.14-4.14'
          />
        </g>
        <g id='LINE_00000088811781328856522440000002227556371680065696_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={427.91}
            y1={66.35}
            x2={436.5}
            y2={66.35}
          />
        </g>
        <g id='LINE_00000021830222674767119780000006776834286473891474_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={436.5}
            y1={88.54}
            x2={427.91}
            y2={88.54}
          />
        </g>
        <g id='ARC_00000058547676561723150580000013758146640286804909_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M436.5,88.54c2.29,0,4.14-1.86,4.14-4.14'
          />
        </g>
        <g id='LINE_00000083798829303324008320000004501962386123415939_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={440.65}
            y1={70.49}
            x2={440.65}
            y2={84.39}
          />
        </g>
        <g id='LINE_00000003795249695974257330000009399561591777261975_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1061.83}
            y1={340.82}
            x2={1061.83}
            y2={339.89}
          />
        </g>
        <g id='LINE_00000101079840822366838890000008879400147903497657_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.83}
            y1={339.89}
            x2={1051.83}
            y2={340.82}
          />
        </g>
        <g id='LINE_00000083054402891290817940000016971497376702480823_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1051.83}
            y1={339.89}
            x2={1052.91}
            y2={339.89}
          />
        </g>
        <g id='LINE_00000078727662494574365820000005566580072356211078_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.91}
            y1={339.89}
            x2={1055.87}
            y2={339.89}
          />
        </g>
        <g id='LINE_00000172427318593432412640000010764698614416928140_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1055.87}
            y1={339.89}
            x2={1057.78}
            y2={339.89}
          />
        </g>
        <g id='LINE_00000014635921690154289600000007623569207040055466_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1057.78}
            y1={339.89}
            x2={1060.74}
            y2={339.89}
          />
        </g>
        <g id='LINE_00000024694675341635148310000000767956799023962265_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1060.74}
            y1={339.89}
            x2={1061.83}
            y2={339.89}
          />
        </g>
        <g id='LINE_00000088122689962184317800000012836378609520557711_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1061.83}
            y1={340.82}
            x2={1060.74}
            y2={340.82}
          />
        </g>
        <g id='LINE_00000093881782099995060380000001189572848448509585_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1060.74}
            y1={340.82}
            x2={1057.78}
            y2={340.82}
          />
        </g>
        <g id='LINE_00000109738697815556106880000007500884589625501883_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1057.78}
            y1={340.82}
            x2={1055.87}
            y2={340.82}
          />
        </g>
        <g id='LINE_00000183235790926921242250000010683280568202441398_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1055.87}
            y1={340.82}
            x2={1052.91}
            y2={340.82}
          />
        </g>
        <g id='LINE_00000013886917604235267470000002203647010846239165_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.91}
            y1={340.82}
            x2={1051.83}
            y2={340.82}
          />
        </g>
        <g id='LINE_00000181793544216753275120000009487058674003888015_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1060.9}
            y1={332.48}
            x2={1052.26}
            y2={332.48}
          />
        </g>
        <g id='LINE_00000166672342706336333920000002556097971219123642_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.26}
            y1={332.48}
            x2={1052.26}
            y2={339.89}
          />
        </g>
        <g id='LINE_00000157310284958908018430000005145191239027468709_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1060.9}
            y1={339.89}
            x2={1060.9}
            y2={332.48}
          />
        </g>
        <g id='LWPOLYLINE_00000057850689502651661000000008208563924731323327_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1064.42,327.69 1064.45,327.02 1064.46,326.35 1064.45,325.68 1064.4,325.02 1064.33,324.35 1064.24,323.69 1064.11,323.03  1063.96,322.38 1063.79,321.73 1063.59,321.09 1063.36,320.46 1063.11,319.84 1062.83,319.23 1062.53,318.63 1062.21,318.04  1061.86,317.47 1061.49,316.91 1061.1,316.37 1060.69,315.84 1060.25,315.33 1059.8,314.84 1059.33,314.36 1058.83,313.91  1058.32,313.48 1058.2,313.38  '
          />
        </g>
        <g id='LWPOLYLINE_00000062886289173120030130000003133718128367290027_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1061.01,332.44 1061.3,332.23 1061.58,332.01 1061.85,331.77 1062.11,331.53 1062.36,331.28 1062.6,331.01 1062.82,330.74  1063.04,330.45 1063.25,330.16 1063.44,329.86 1063.62,329.56 1063.79,329.24 1063.95,328.92 1064.09,328.6 1064.23,328.27  1064.34,327.93 1064.42,327.69  '
          />
        </g>
        <g id='LWPOLYLINE_00000055677405095252389470000016616039508870027953_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1048.77,327.45 1048.87,327.79 1048.98,328.13 1049.1,328.47 1049.24,328.8 1049.39,329.12 1049.56,329.44 1049.73,329.75  1049.92,330.05 1050.12,330.34 1050.34,330.63 1050.56,330.91 1050.79,331.18 1051.04,331.44 1051.29,331.69 1051.56,331.93  1051.83,332.15 1052.12,332.37 1052.31,332.51  '
          />
        </g>
        <g id='LWPOLYLINE_00000172413627769460372450000001753888728995097490_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1055.43,313.33 1054.9,313.75 1054.39,314.18 1053.9,314.64 1053.42,315.11 1052.97,315.6 1052.53,316.11 1052.12,316.64  1051.73,317.18 1051.36,317.74 1051.01,318.31 1050.69,318.9 1050.39,319.5 1050.11,320.11 1049.86,320.73 1049.63,321.36  1049.43,322 1049.25,322.65 1049.11,323.3 1048.98,323.96 1048.89,324.62 1048.81,325.29 1048.77,325.95 1048.75,326.62  1048.76,327.29 1048.77,327.45  '
          />
        </g>
        <g id='LINE_00000011009772479412039510000011257280950123795372_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1055.97}
            y1={312.92}
            x2={1055.43}
            y2={313.33}
          />
        </g>
        <g id='LINE_00000111185887496697200240000006163609063522780040_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1058.2}
            y1={313.38}
            x2={1057.67}
            y2={312.95}
          />
        </g>
        <g id='LWPOLYLINE_00000014598901512597353040000004918403796139717509_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1056.21,329.65 1056.39,329.65 1056.57,329.64 1056.75,329.62 1056.9,329.6  '
          />
        </g>
        <g id='LWPOLYLINE_00000182524464392679122560000016453317899775178648_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1056.9,329.6 1057.07,329.62 1057.25,329.63 1057.43,329.64 1057.58,329.64  '
          />
        </g>
        <g id='LINE_00000119812656834407827140000005047657505211859618_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.26}
            y1={332.59}
            x2={1061.07}
            y2={332.52}
          />
        </g>
        <g id='LINE_00000001648035667343974670000002907904141380150411_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1061.07}
            y1={332.52}
            x2={1060.98}
            y2={332.4}
          />
        </g>
        <g id='ARC_00000029004492290633974540000000063859894914460321_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1060.98,332.4c1.62-1.15,2.82-2.81,3.38-4.71'
          />
        </g>
        <g id='ARC_00000171721479226653335820000001575094493073601189_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1064.36,327.68c0.41-5.48-1.91-10.82-6.2-14.26'
          />
        </g>
        <g id='LINE_00000090998949164616756230000002559751255819689899_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1058.16}
            y1={313.42}
            x2={1057.5}
            y2={312.89}
          />
        </g>
        <g id='ARC_00000042711468294948143270000013470067565510992828_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1057.5,312.89c-0.44-0.14-0.92-0.15-1.37-0.02'
          />
        </g>
        <g id='LINE_00000171710745888687513440000013418098294890874030_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1056.14}
            y1={312.87}
            x2={1055.46}
            y2={313.38}
          />
        </g>
        <g id='ARC_00000068636914817055074320000012950903345335464871_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1055.46,313.38c-4.39,3.31-6.87,8.57-6.63,14.06'
          />
        </g>
        <g id='ARC_00000083805905439517767500000011098420349975013774_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1048.83,327.44c0.54,2.04,1.79,3.82,3.52,5.02'
          />
        </g>
        <g id='LINE_00000163774103836965442690000003278886876986073730_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1052.35}
            y1={332.46}
            x2={1052.26}
            y2={332.59}
          />
        </g>
        <g id='ARC_00000065059214491132123000000007172515185364056466_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1051.69,325.34c0.1,2.48,2.18,4.41,4.66,4.32c0.18-0.01,0.36-0.03,0.54-0.05'
          />
        </g>
        <g id='ARC_00000134220655883998220130000016983631393084080256_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1056.79,315.14c-3.33,2.3-5.25,6.15-5.1,10.2'
          />
        </g>
        <g id='ARC_00000173127167735526332340000009788899770368621223_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1062.04,325.26c0.1-4.05-1.89-7.86-5.25-10.12'
          />
        </g>
        <g id='ARC_00000042710257403524410280000013099236066862007941_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1056.9,329.6c2.46,0.36,4.74-1.34,5.1-3.8c0.03-0.18,0.04-0.36,0.05-0.54'
          />
        </g>
        <g id='SPLINE_00000068677132036379848330000007096575479822697904_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1057.5,312.89c0.07,0.02,0.11,0.04,0.14,0.05'
          />
        </g>
        <g id='SPLINE_00000098217787548659298780000017920160424753593752_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1057.54,312.9c-0.01,0-0.01,0-0.02-0.01c-0.01,0-0.01,0-0.02-0.01c-0.02,0-0.03-0.01-0.05-0.01s-0.03-0.01-0.05-0.01 c-0.02,0-0.04-0.01-0.06-0.01c-0.02,0-0.04-0.01-0.06-0.01c-0.02,0-0.04-0.01-0.06-0.01'
          />
        </g>
        <g id='SPLINE_00000114767939795072530000000002983252862790699654_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1057.1,312.8c-0.09-0.01-0.18-0.02-0.28-0.02'
          />
        </g>
        <g id='SPLINE_00000081623439054295460100000003748298987545203331_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1056.82,312.78c-0.1,0-0.19,0-0.28,0.01'
          />
        </g>
        <g id='SPLINE_00000019639122233674483120000010815423098763972261_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1056.42,312.81c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.06,0.01s-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.05,0.01 c-0.02,0-0.03,0.01-0.05,0.01c-0.01,0-0.02,0-0.02,0.01s-0.01,0-0.02,0.01'
          />
        </g>
        <g id='SPLINE_00000019671124143344438030000013184480055783651500_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1056,312.91c0.03-0.01,0.07-0.03,0.14-0.05'
          />
        </g>
        <g id='SPLINE_00000154388955975781821310000012885741904879843473_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1064.36,327.68c0.04,0.01,0.06,0.01,0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000057107807935435038790000015219900745840305306_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1048.83,327.44c-0.04,0.01-0.06,0.01-0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000122722250874636808110000012829004592126958989_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1057.37,324.23c-0.11,0.12-0.25,0.21-0.4,0.28s-0.31,0.11-0.47,0.13c-0.08,0.01-0.16,0.01-0.24,0c-0.08-0.01-0.16-0.02-0.24-0.04 c-0.04-0.01-0.08-0.02-0.12-0.04c-0.04-0.01-0.07-0.03-0.11-0.05c-0.04-0.02-0.07-0.04-0.11-0.06c-0.03-0.02-0.07-0.04-0.1-0.06 c-0.03-0.02-0.06-0.05-0.09-0.07c-0.03-0.03-0.06-0.05-0.09-0.08s-0.06-0.06-0.08-0.09s-0.05-0.06-0.07-0.09 c-0.02-0.03-0.04-0.07-0.06-0.1c-0.02-0.03-0.04-0.07-0.06-0.11s-0.03-0.07-0.05-0.11c-0.01-0.04-0.03-0.08-0.04-0.11 c-0.01-0.04-0.02-0.08-0.03-0.12c-0.01-0.04-0.01-0.08-0.02-0.12c-0.01-0.08-0.01-0.16,0-0.24c0.01-0.16,0.05-0.32,0.12-0.47 c0.07-0.15,0.16-0.29,0.28-0.4'
          />
        </g>
        <g id='SPLINE_00000062194370159625908560000004436691870293170336_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1055.39,322.28c0.11-0.12,0.25-0.21,0.39-0.28c0.15-0.07,0.31-0.11,0.47-0.13c0.16-0.02,0.32,0,0.48,0.04s0.31,0.11,0.44,0.2 s0.25,0.21,0.34,0.34s0.16,0.28,0.21,0.44c0.04,0.16,0.06,0.32,0.04,0.48c-0.01,0.08-0.02,0.16-0.04,0.24 c-0.02,0.08-0.05,0.15-0.08,0.23c-0.07,0.15-0.16,0.28-0.27,0.4'
          />
        </g>
        <g id='LINE_00000031174312507500495530000003207146583793528765_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={433.88}
            y1={45.31}
            x2={433.88}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000181790677784633138250000009644383722882912185_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={431.93}
            y1={45.31}
            x2={431.93}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000075128534924683320270000008248397630227442834_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={432.88}
            y1={44.28}
            x2={433.82}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000027575990909641915600000000711499552231371672_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={433.82}
            y1={44.28}
            x2={433.87}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000013192604610851153030000000035314169969848487_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={429.65}
            y1={44.28}
            x2={429.87}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000095297779573385012250000011295887507018434440_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={429.87}
            y1={44.28}
            x2={430.69}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000066482662538194332270000009329608037803033015_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={430.69}
            y1={44.28}
            x2={431.84}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000183218622311771209030000018045170530566523314_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={431.84}
            y1={44.28}
            x2={431.92}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000084510062252759363490000017296866837971093380_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={431.91}
            y1={44.28}
            x2={432.88}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000075125150590865419660000002462706003368827816_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={433.87}
            y1={45.31}
            x2={433.82}
            y2={45.31}
          />
        </g>
        <g id='LINE_00000065035322684415037060000008694362004230123189_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={433.82}
            y1={45.31}
            x2={432.88}
            y2={45.31}
          />
        </g>
        <g id='LINE_00000145758884782061047480000012051846457906256257_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={432.88}
            y1={45.31}
            x2={431.91}
            y2={45.31}
          />
        </g>
        <g id='LINE_00000139975708986019890700000012882129991379599283_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={431.92}
            y1={45.31}
            x2={431.84}
            y2={45.31}
          />
        </g>
        <g id='LINE_00000170969755898952715890000009236130195576518310_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={431.84}
            y1={45.31}
            x2={430.69}
            y2={45.31}
          />
        </g>
        <g id='LINE_00000123412824557500464870000009382611491613093277_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={430.69}
            y1={45.31}
            x2={429.87}
            y2={45.31}
          />
        </g>
        <g id='LINE_00000036233881291618148350000016182843542161272708_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={429.87}
            y1={45.31}
            x2={429.65}
            y2={45.31}
          />
        </g>
        <g id='LINE_00000023255995791358360340000000936262383919010177_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={429.87}
            y1={44.28}
            x2={429.87}
            y2={45.31}
          />
        </g>
        <g id='LINE_00000080894436323132423440000008751336193258140070_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={431.84}
            y1={45.31}
            x2={431.84}
            y2={44.28}
          />
        </g>
        <g id='LINE_00000006691665710202297590000009456980071992475319_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={429.65}
            y1={44.28}
            x2={429.65}
            y2={45.31}
          />
        </g>
        <g id='ARC_00000002351997499191163990000017718030484031608964_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.9,46.52c0,0.36,0.29,0.66,0.66,0.66c0.36,0,0.66-0.29,0.66-0.66c0-0.13-0.04-0.26-0.11-0.37'
          />
        </g>
        <g id='ARC_00000093856064775024154860000008684221285588537777_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M431.02,45.34c-0.17,0.25-0.14,0.6,0.08,0.81'
          />
        </g>
        <g id='ARC_00000178917600178223397310000000759125753184808075_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M431.02,45.34c0.01-0.01,0.01-0.01,0.02-0.02'
          />
        </g>
        <g id='LWPOLYLINE_00000050650788426201613470000017005847407545851788_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 429.65,45.31 429.79,45.59 429.88,45.89 429.92,46.21 429.9,46.52  '
          />
        </g>
        <g id='ARC_00000041995619346162986940000015264778275421257856_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M431.02,43.54c-0.15,0.22-0.15,0.5,0,0.72'
          />
        </g>
        <g id='ARC_00000179610493755945664560000000260504093483153070_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M431.02,43.54c0.26-0.26,0.26-0.67,0-0.93c-0.26-0.26-0.67-0.26-0.93,0c-0.12,0.12-0.19,0.29-0.19,0.46'
          />
        </g>
        <g id='ARC_00000150824369363995338470000015553300566894449078_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.71,44.18c0.18-0.34,0.25-0.73,0.2-1.11'
          />
        </g>
        <g id='ARC_00000084515140509238184480000016929978197706965411_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.71,44.18c-0.25,0.25-0.32,0.62-0.18,0.95'
          />
        </g>
        <g id='LWPOLYLINE_00000039813988105081244750000013071498658997765516_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 429.52,45.13 429.59,45.22 429.65,45.31  '
          />
        </g>
        <g id='ARC_00000045584437469174314310000001376576995803877771_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M431.04,44.28c-0.01-0.01-0.01-0.02-0.02-0.02'
          />
        </g>
        <g id='LWPOLYLINE_00000144333482284488284110000016594673485019028669_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 439.55,36.59 439.87,36.85 440.19,37.12 440.49,37.4 440.78,37.7 441.06,38 441.32,38.32 441.57,38.65 441.81,38.99 442.04,39.34  442.25,39.69 442.45,40.06 442.63,40.43 442.8,40.81 442.95,41.19 443.08,41.58 443.2,41.98 443.31,42.38 443.4,42.79  443.47,43.19 443.53,43.6 443.57,44.02 443.59,44.43 443.59,44.84 443.58,45.26 443.56,45.67 443.51,46.08 443.45,46.49  443.38,46.9 443.29,47.3 443.18,47.7 443.05,48.1 442.91,48.49 442.76,48.87 442.59,49.25 442.4,49.62 442.2,49.98 441.99,50.34  441.76,50.68 441.52,51.02 441.26,51.35 440.99,51.66 440.71,51.97 440.42,52.26 440.12,52.54 439.8,52.81 439.55,53.01  '
          />
        </g>
        <g id='LWPOLYLINE_00000011027396960334268380000000353745307307794616_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 436.16,53.01 435.83,52.75 435.52,52.48 435.22,52.19 434.93,51.9 434.65,51.59 434.38,51.27 434.13,50.94 433.89,50.61  433.67,50.26 433.46,49.9 433.26,49.54 433.08,49.17 432.91,48.79 432.76,48.4 432.62,48.01 432.5,47.61 432.4,47.21  432.31,46.81 432.24,46.4 432.18,45.99 432.14,45.58 432.12,45.31  '
          />
        </g>
        <g id='LWPOLYLINE_00000116231115000924937670000001916319443286899840_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 432.12,44.28 432.15,43.92 432.19,43.51 432.25,43.1 432.33,42.69 432.42,42.29 432.53,41.89 432.65,41.5 432.79,41.11  432.95,40.72 433.12,40.34 433.3,39.97 433.5,39.61 433.72,39.26 433.95,38.91 434.19,38.58 434.44,38.25 434.71,37.93  434.99,37.63 435.28,37.34 435.59,37.05 435.9,36.79 436.16,36.59  '
          />
        </g>
        <g id='LWPOLYLINE_00000113334862998572594340000002693574960396193703_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 438.24,36.21 438.36,36.21 438.47,36.22 438.58,36.24 438.69,36.26 438.8,36.28 438.91,36.31 439.02,36.35 439.12,36.38  439.23,36.43 439.33,36.47 439.43,36.52 439.53,36.58 439.55,36.59  '
          />
        </g>
        <g id='LWPOLYLINE_00000130627602934024231090000014863713825259937172_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 436.16,36.59 436.25,36.53 436.35,36.48 436.46,36.43 436.56,36.39 436.67,36.35 436.78,36.32 436.88,36.29 436.99,36.26  437.11,36.24 437.22,36.23 437.33,36.21 437.44,36.21 437.55,36.2  '
          />
        </g>
        <g id='LWPOLYLINE_00000134219244477407424310000002898885050479825339_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 437.57,36.2 437.58,36.2 437.58,36.2 437.58,36.2 437.58,36.2 437.58,36.2 437.59,36.2 437.59,36.2 437.59,36.2 437.6,36.2  437.6,36.2 437.61,36.2 437.61,36.2 437.62,36.2 437.62,36.2 437.63,36.2 437.64,36.2 437.64,36.2 437.65,36.2 437.66,36.2  437.67,36.2 437.68,36.2 437.68,36.2 437.69,36.2 437.7,36.2 437.71,36.2 437.72,36.2 437.73,36.2 437.74,36.2 437.75,36.2  437.76,36.2 437.77,36.2 437.78,36.2 437.79,36.2 437.81,36.2 437.82,36.2 437.83,36.2 437.84,36.2 437.85,36.2  '
          />
        </g>
        <g id='LWPOLYLINE_00000000211534085362069860000006025643150737348745_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 437.85,36.2 437.86,36.2 437.87,36.2 437.88,36.2 437.89,36.2 437.9,36.2 437.92,36.2 437.93,36.2 437.94,36.2 437.95,36.2  437.96,36.2 437.97,36.2 437.98,36.2 437.99,36.2 438,36.2 438.01,36.2 438.02,36.2 438.02,36.2 438.03,36.2 438.04,36.2  438.05,36.2 438.06,36.2 438.06,36.2 438.07,36.2 438.08,36.2 438.08,36.2 438.09,36.2 438.1,36.2 438.1,36.2 438.11,36.2  438.11,36.2 438.11,36.2 438.12,36.2 438.12,36.2 438.12,36.2 438.13,36.2 438.13,36.2 438.13,36.2 438.13,36.2 438.13,36.2  '
          />
        </g>
        <g id='LWPOLYLINE_00000098198830305250744430000014694763335837279645_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 439.55,53.01 439.45,53.06 439.35,53.11 439.25,53.16 439.14,53.2 439.04,53.24 438.93,53.28 438.82,53.31 438.71,53.33  438.6,53.35 438.49,53.37 438.38,53.38 438.26,53.39 438.15,53.39  '
          />
        </g>
        <g id='LWPOLYLINE_00000085217309198288725090000004500917533018123410_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 437.46,53.39 437.35,53.38 437.24,53.37 437.12,53.35 437.01,53.33 436.9,53.31 436.79,53.28 436.69,53.25 436.58,53.21  436.48,53.17 436.37,53.12 436.27,53.07 436.17,53.02 436.16,53.01  '
          />
        </g>
        <g id='LWPOLYLINE_00000063622493900413276970000007249707530076350359_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 438.13,53.39 438.13,53.39 438.13,53.39 438.13,53.39 438.12,53.39 438.12,53.39 438.12,53.39 438.12,53.39 438.11,53.39  438.11,53.39 438.1,53.39 438.1,53.39 438.09,53.39 438.09,53.39 438.08,53.39 438.08,53.39 438.07,53.39 438.06,53.39  438.05,53.39 438.05,53.39 438.04,53.39 438.03,53.39 438.02,53.39 438.01,53.39 438,53.39 437.99,53.39 437.98,53.39  437.97,53.39 437.96,53.39 437.95,53.39 437.94,53.39 437.93,53.39 437.92,53.39 437.91,53.39 437.9,53.39 437.89,53.39  437.88,53.39 437.87,53.39 437.86,53.39 437.84,53.39 437.83,53.39 437.82,53.39 437.81,53.39 437.8,53.39 437.79,53.39  437.78,53.39 437.77,53.39 437.76,53.39 437.75,53.39 437.74,53.39 437.73,53.39 437.72,53.39 437.71,53.39 437.7,53.39  437.69,53.39 437.68,53.39 437.67,53.39 437.66,53.39 437.66,53.39 437.65,53.39 437.64,53.39 437.63,53.39 437.63,53.39  437.62,53.39 437.61,53.39 437.61,53.39 437.6,53.39 437.6,53.39 437.59,53.39 437.59,53.39 437.59,53.39 437.58,53.39  437.58,53.39 437.58,53.39 437.58,53.39 437.58,53.39 437.57,53.39 437.57,53.39  '
          />
        </g>
        <g id='LINE_00000035508548310220772350000006104760991788747659_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={436.23}
            y1={52.89}
            x2={439.47}
            y2={52.89}
          />
        </g>
        <g id='SPLINE_00000032619797811212566910000008085686404869029253_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M438.07,44.1c0.08,0,0.16,0.01,0.24,0.04c0.08,0.03,0.15,0.07,0.21,0.12c0.06,0.05,0.11,0.11,0.16,0.18s0.07,0.15,0.08,0.23 c0.01,0.08,0.01,0.16,0,0.24s-0.04,0.16-0.08,0.23s-0.09,0.13-0.16,0.18s-0.13,0.09-0.21,0.12c-0.08,0.03-0.16,0.04-0.24,0.04'
          />
        </g>
        <g id='SPLINE_00000011721820411103801540000002235543811109109405_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M438.07,45.49c-0.08,0-0.16-0.01-0.24-0.04c-0.08-0.03-0.15-0.07-0.21-0.12c-0.06-0.05-0.11-0.11-0.16-0.18 c-0.04-0.07-0.07-0.15-0.08-0.23c-0.01-0.08-0.01-0.16,0-0.24c0.01-0.08,0.04-0.16,0.08-0.23c0.04-0.07,0.09-0.13,0.16-0.18 c0.06-0.05,0.13-0.09,0.21-0.12c0.08-0.03,0.16-0.04,0.24-0.04'
          />
        </g>
        <g id='LINE_00000101089245265004334750000011104435096286214283_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={439.47}
            y1={36.7}
            x2={436.23}
            y2={36.7}
          />
        </g>
        <g id='LINE_00000028322081371998176510000012729691770870407063_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={447.93}
            y1={55.6}
            x2={447.93}
            y2={53.44}
          />
        </g>
        <g id='LINE_00000056396685548041695540000014804916331103535232_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={447.93}
            y1={36.32}
            x2={447.93}
            y2={34.16}
          />
        </g>
        <g id='LINE_00000147196286178922957850000012259684517945231025_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={437.9}
            y1={33.27}
            x2={427.91}
            y2={33.27}
          />
        </g>
        <g id='LINE_00000124139954864779569590000010630870379955295916_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={427.91}
            y1={56.49}
            x2={437.9}
            y2={56.49}
          />
        </g>
        <g id='LINE_00000132080985961789796950000016133343604356427446_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={447.93}
            y1={53.44}
            x2={447.93}
            y2={36.32}
          />
        </g>
        <g id='LINE_00000067956055999416201200000004809950419644734870_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={437.9}
            y1={33.27}
            x2={446.84}
            y2={33.27}
          />
        </g>
        <g id='LINE_00000124856153221395580720000017932015067526362299_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={446.84}
            y1={56.49}
            x2={437.9}
            y2={56.49}
          />
        </g>
        <g id='LINE_00000117669915163920634050000014456306700486671025_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={427.91}
            y1={56.49}
            x2={427.91}
            y2={56.3}
          />
        </g>
        <g id='LINE_00000052795417597112058140000013276781442876453794_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={427.91}
            y1={33.47}
            x2={427.91}
            y2={33.27}
          />
        </g>
        <g id='LINE_00000163041364287429831150000001487931310419100577_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={447.82}
            y1={53.9}
            x2={447.82}
            y2={55.91}
          />
        </g>
        <g id='SPLINE_00000038373132702786840070000003891446601065271721_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M447.82,55.91c0.01-0.02,0.02-0.03,0.03-0.05s0.02-0.03,0.02-0.05c0-0.01,0.01-0.02,0.01-0.03c0-0.01,0.01-0.02,0.01-0.03 c0-0.01,0.01-0.02,0.01-0.03c0,0,0-0.01,0-0.01c0,0,0-0.01,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0-0.01,0-0.01 c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01 c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0'
          />
        </g>
        <g id='SPLINE_00000148621945001305217180000010616959810877412502_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M447.93,34.15C447.93,34.15,447.93,34.16,447.93,34.15C447.93,34.16,447.93,34.16,447.93,34.15 C447.93,34.16,447.93,34.16,447.93,34.15C447.93,34.15,447.93,34.15,447.93,34.15C447.93,34.15,447.93,34.15,447.93,34.15 c0-0.01,0-0.01,0-0.02c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01 c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0-0.01,0-0.01c0,0,0,0,0-0.01c0,0,0,0,0-0.01c0,0,0-0.01,0-0.01s0-0.01,0-0.01 c0-0.01-0.01-0.02-0.01-0.03c0-0.01-0.01-0.02-0.01-0.03c0-0.01-0.01-0.02-0.01-0.03c-0.01-0.02-0.01-0.03-0.02-0.05 s-0.02-0.03-0.03-0.05'
          />
        </g>
        <g id='LINE_00000181777968951383129720000010179911961496724652_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={447.82}
            y1={33.85}
            x2={447.82}
            y2={35.86}
          />
        </g>
        <g id='ARC_00000000220626468443763690000001493837145984050089_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M434.64,35.13c-1.66,0.28-3.31,0.62-4.94,1.03'
          />
        </g>
        <g id='ARC_00000033371632861348628810000014891987409394759317_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M439.78,34.48c-1.58,0.14-3.14,0.33-4.71,0.58'
          />
        </g>
        <g id='ARC_00000144323924436228346150000011758486859527503022_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M445.27,34.24c-1.7,0-3.4,0.07-5.1,0.2'
          />
        </g>
        <g id='ARC_00000112623552070288332200000008967718203071167410_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M439.86,55.28c1.7,0.14,3.4,0.22,5.11,0.23'
          />
        </g>
        <g id='ARC_00000049938032484471924320000003095054034896289978_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M434.84,54.66c1.65,0.27,3.31,0.48,4.97,0.62'
          />
        </g>
        <g id='ARC_00000165957037571125536820000006142700548373512872_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.78,53.61c1.65,0.41,3.31,0.76,4.99,1.04'
          />
        </g>
        <g id='ARC_00000065783365731135258700000010237358501634655913_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.44,37.03c-0.17,1.55-0.3,3.1-0.37,4.65'
          />
        </g>
        <g id='ARC_00000119839699298178661990000008333956538244749192_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.04,42.54c-0.05,1.5-0.05,3-0.01,4.49'
          />
        </g>
        <g id='ARC_00000036950828797486174030000013317915414409578163_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.06,47.9c0.07,1.55,0.19,3.11,0.35,4.65'
          />
        </g>
        <g id='ARC_00000124879595528395723500000010889001131452870544_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M444.97,55.51c0.14-0.05,0.28-0.12,0.41-0.2'
          />
        </g>
        <g id='ARC_00000025445003597021255810000007994893649918538890_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M445.5,55.23c0.08-0.06,0.16-0.13,0.24-0.2'
          />
        </g>
        <g id='ARC_00000063626870322306585900000000105998177343767699_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M445.85,54.91c0.07-0.07,0.13-0.15,0.18-0.23'
          />
        </g>
        <g id='ARC_00000035498267102581379330000009879370589160148915_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.13,54.54c0.05-0.09,0.1-0.18,0.14-0.28'
          />
        </g>
        <g id='ARC_00000048489213747714257800000017446878066660434587_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.31,54.13c0.05-0.14,0.08-0.29,0.1-0.44'
          />
        </g>
        <g id='ARC_00000080896753658111687090000014325994549721436813_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.41,53.69c0.23-1.97,0.39-3.94,0.46-5.92'
          />
        </g>
        <g id='ARC_00000042730652296912043710000012198892349386313627_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.88,47.76c0.07-1.98,0.07-3.96-0.01-5.94'
          />
        </g>
        <g id='ARC_00000068648968511867249650000000154290313336348839_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.86,41.82c-0.08-1.98-0.25-3.95-0.49-5.92'
          />
        </g>
        <g id='ARC_00000106113107346232490030000014828119449259709091_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.37,35.9c-0.02-0.16-0.06-0.31-0.11-0.46'
          />
        </g>
        <g id='ARC_00000041273453085941809130000014673661741299318927_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.21,35.3c-0.04-0.1-0.09-0.2-0.15-0.29'
          />
        </g>
        <g id='ARC_00000091002639878665827280000011401015433679600518_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M445.97,34.87c-0.06-0.09-0.13-0.17-0.21-0.25'
          />
        </g>
        <g id='ARC_00000051356110726916286780000001107330643253030560_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M445.65,34.52c-0.12-0.11-0.24-0.2-0.38-0.28'
          />
        </g>
        <g id='ARC_00000074433988441892412700000001308848462119976381_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M439.47,52.89c4.47-3.44,5.3-9.86,1.86-14.33c-0.54-0.7-1.16-1.32-1.86-1.86'
          />
        </g>
        <g id='ARC_00000123416691529746599090000016494805095936952490_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M438.13,53.25c0.47,0,0.93-0.12,1.34-0.36'
          />
        </g>
        <g id='LINE_00000100370324318907389400000016523401623551178913_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={438.13}
            y1={53.25}
            x2={437.57}
            y2={53.25}
          />
        </g>
        <g id='ARC_00000059988542892718482360000007820452536842737839_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M436.23,52.89c0.41,0.24,0.87,0.36,1.34,0.36'
          />
        </g>
        <g id='ARC_00000087374480545188504800000015398299765861556635_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M432.26,45.32c0.15,2.98,1.6,5.75,3.97,7.58'
          />
        </g>
        <g id='ARC_00000027570194018152543350000017464413622220451458_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M436.23,36.7c-2.37,1.82-3.82,4.59-3.97,7.58'
          />
        </g>
        <g id='ARC_00000034083867469126899830000008014357962664992660_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M437.57,36.34c-0.47,0-0.93,0.12-1.34,0.36'
          />
        </g>
        <g id='LINE_00000152263778499566468840000001519215050691746691_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={437.57}
            y1={36.34}
            x2={438.13}
            y2={36.34}
          />
        </g>
        <g id='ARC_00000168838656764796678060000013319876642148830367_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M439.47,36.7c-0.41-0.24-0.87-0.36-1.34-0.36'
          />
        </g>
        <g id='SPLINE_00000141427584773593976550000016499524728448840323_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M427.91,33.47c0.08,0,0.16,0.01,0.25,0.01s0.17,0,0.25,0c0.08,0,0.17,0,0.24-0.01'
          />
        </g>
        <g id='LINE_00000060740103327601811130000013272248812727588019_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={427.91}
            y1={56.3}
            x2={427.91}
            y2={33.47}
          />
        </g>
        <g id='SPLINE_00000133507770964725945350000016365511960997506704_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M428.65,56.3c-0.08,0-0.16-0.01-0.24-0.01s-0.17,0-0.25,0c-0.08,0-0.17,0-0.25,0.01'
          />
        </g>
        <g id='SPLINE_00000032624846713028023200000001164835228012058278_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.78,53.61c1.63,0.41,3.28,0.75,4.95,1.03c1.67,0.28,3.37,0.49,5.08,0.64c1.71,0.15,3.43,0.22,5.16,0.23'
          />
        </g>
        <g id='SPLINE_00000182487729354165232600000002891032817572755856_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M445.27,34.24c-2.28,0-4.55,0.12-6.79,0.36c-1.49,0.16-2.98,0.37-4.44,0.63c-1.46,0.26-2.91,0.57-4.34,0.93'
          />
        </g>
        <g id='SPLINE_00000085211031910803113620000008683147838350133907_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.84,33.27c0.12,0,0.24,0.02,0.35,0.06c0.11,0.04,0.22,0.09,0.32,0.16c0.06,0.05,0.12,0.1,0.17,0.16 c0.05,0.06,0.1,0.12,0.14,0.2'
          />
        </g>
        <g id='SPLINE_00000094592904337899925630000009564754232727744900_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M428.65,33.47c1.01-0.05,2.03-0.09,3.05-0.12c1.02-0.03,2.05-0.05,3.08-0.06s2.07-0.02,3.11-0.02'
          />
        </g>
        <g id='SPLINE_00000099624439123136595800000002632270529759390396_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M437.9,56.49c-1.04,0-2.08,0-3.11-0.02c-1.03-0.01-2.06-0.03-3.08-0.06c-1.02-0.03-2.04-0.07-3.05-0.12'
          />
        </g>
        <g id='SPLINE_00000054984616694152799420000015510560648332769455_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M447.82,55.91c-0.06,0.11-0.13,0.2-0.22,0.28s-0.19,0.15-0.3,0.2c-0.07,0.03-0.15,0.06-0.23,0.08c-0.08,0.02-0.16,0.03-0.24,0.03'
          />
        </g>
        <g id='SPLINE_00000009575184282058915010000001957895780361483171_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.44,37.03c-0.17,1.55-0.3,3.1-0.37,4.65s-0.09,3.11-0.06,4.67c0.02,1.04,0.07,2.07,0.13,3.11s0.16,2.07,0.27,3.1'
          />
        </g>
        <g id='SPLINE_00000023271431426169939390000009370787246225196464_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.7,36.17c-0.07,0.13-0.12,0.27-0.17,0.42c-0.04,0.14-0.07,0.29-0.09,0.44'
          />
        </g>
        <g id='SPLINE_00000109733692805958978360000011634195888685348794_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M429.42,52.56c0.02,0.19,0.06,0.37,0.12,0.55s0.14,0.34,0.24,0.5'
          />
        </g>
        <g id='SPLINE_00000134227064866918085100000016224619005080724119_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.41,53.69c0.23-1.97,0.39-3.94,0.46-5.92c0.07-1.98,0.07-3.96-0.01-5.94c-0.08-1.98-0.25-3.95-0.49-5.92'
          />
        </g>
        <g id='SPLINE_00000132051748851876005530000003492231502073814462_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M444.97,55.51c0.13-0.05,0.25-0.11,0.37-0.17c0.12-0.07,0.23-0.15,0.33-0.24c0.2-0.18,0.38-0.4,0.5-0.64 c0.06-0.12,0.12-0.25,0.16-0.38c0.04-0.13,0.07-0.26,0.09-0.4'
          />
        </g>
        <g id='SPLINE_00000052785390413454201970000004153868834209482389_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M446.37,35.9c-0.01-0.1-0.03-0.21-0.06-0.3c-0.03-0.1-0.06-0.2-0.1-0.29c-0.08-0.19-0.19-0.37-0.32-0.53 c-0.09-0.11-0.18-0.21-0.29-0.29s-0.22-0.17-0.34-0.24'
          />
        </g>
        <g id='LINE_00000108284364105030422450000014603180772392205966_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={447.82}
            y1={53.9}
            x2={447.82}
            y2={35.86}
          />
        </g>
        <g id='LINE_00000061429954331011281060000003913681214098342040_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={334.89}
            x2={1120.83}
            y2={334.89}
          />
        </g>
        <g id='LINE_00000092426389288483940360000008924698928070071945_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={336.85}
            x2={1120.83}
            y2={336.85}
          />
        </g>
        <g id='LINE_00000021840420819670246540000014902248465961184690_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1120.83}
            y1={335.89}
            x2={1120.83}
            y2={334.95}
          />
        </g>
        <g id='LINE_00000148648360757626188520000016589450827680146875_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1120.83}
            y1={334.95}
            x2={1120.83}
            y2={334.91}
          />
        </g>
        <g id='LINE_00000110431813762592446170000012849921965118696074_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1120.83}
            y1={339.12}
            x2={1120.83}
            y2={338.9}
          />
        </g>
        <g id='LINE_00000078754282608233211210000011460037686550879928_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1120.83}
            y1={338.9}
            x2={1120.83}
            y2={338.08}
          />
        </g>
        <g id='LINE_00000035497064773882505220000010156665581281628851_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1120.83}
            y1={338.08}
            x2={1120.83}
            y2={336.93}
          />
        </g>
        <g id='LINE_00000087410543331170915010000000807077684655187886_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1120.83}
            y1={336.93}
            x2={1120.83}
            y2={336.85}
          />
        </g>
        <g id='LINE_00000017510653865573245610000005395705863048210824_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1120.83}
            y1={336.86}
            x2={1120.83}
            y2={335.89}
          />
        </g>
        <g id='LINE_00000031186968414799197950000013138157645419958406_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={334.91}
            x2={1121.86}
            y2={334.95}
          />
        </g>
        <g id='LINE_00000140717841654753391630000018297425650963637396_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={334.95}
            x2={1121.86}
            y2={335.89}
          />
        </g>
        <g id='LINE_00000168803988964714281350000015179708610174989444_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={335.89}
            x2={1121.86}
            y2={336.86}
          />
        </g>
        <g id='LINE_00000132785153661212493020000017031992575774537355_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={336.85}
            x2={1121.86}
            y2={336.93}
          />
        </g>
        <g id='LINE_00000145764385717183536390000001324964808938729889_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={336.93}
            x2={1121.86}
            y2={338.08}
          />
        </g>
        <g id='LINE_00000020378399667571925500000017139012287990638992_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={338.08}
            x2={1121.86}
            y2={338.9}
          />
        </g>
        <g id='LINE_00000137093009834298592220000010888682531437789846_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={338.9}
            x2={1121.86}
            y2={339.12}
          />
        </g>
        <g id='LINE_00000066515436042792783560000007655610316558259355_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1120.83}
            y1={338.9}
            x2={1121.86}
            y2={338.9}
          />
        </g>
        <g id='LINE_00000000197982836392891910000004728519246632795536_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1121.86}
            y1={336.93}
            x2={1120.83}
            y2={336.93}
          />
        </g>
        <g id='LINE_00000024703540610979172650000001893221842653076645_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1120.83}
            y1={339.12}
            x2={1121.86}
            y2={339.12}
          />
        </g>
        <g id='ARC_00000169557353885929934740000016287195176652530856_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1123.07,338.87c0.36,0,0.66-0.29,0.66-0.66s-0.29-0.66-0.66-0.66c-0.13,0-0.26,0.04-0.37,0.11'
          />
        </g>
        <g id='ARC_00000132077476770977220930000016184827583329263780_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1121.89,337.75c0.25,0.17,0.6,0.14,0.81-0.08'
          />
        </g>
        <g id='ARC_00000158024014128639366010000013921108779044216233_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1121.89,337.75c-0.01-0.01-0.01-0.01-0.02-0.02'
          />
        </g>
        <g id='LWPOLYLINE_00000057126526936869560220000000005018414976540333_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1121.86,339.12 1122.14,338.98 1122.44,338.89 1122.76,338.85 1123.07,338.87  '
          />
        </g>
        <g id='ARC_00000028287207636602524720000016922204356496242836_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1120.09,337.75c0.22,0.15,0.5,0.15,0.72,0'
          />
        </g>
        <g id='ARC_00000000220765113089824640000001250548354356678790_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1120.09,337.75c-0.26-0.26-0.67-0.26-0.93,0c-0.26,0.26-0.26,0.67,0,0.93c0.12,0.12,0.29,0.19,0.46,0.19'
          />
        </g>
        <g id='ARC_00000063591114640649748490000012158454842473757839_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1120.74,339.06c-0.34-0.18-0.73-0.25-1.11-0.2'
          />
        </g>
        <g id='ARC_00000141444998284746013080000017658444957985425328_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1120.74,339.06c0.25,0.25,0.62,0.32,0.95,0.18'
          />
        </g>
        <g id='LWPOLYLINE_00000101812322585512859580000001860439834395265683_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1121.68,339.25 1121.77,339.18 1121.86,339.12  '
          />
        </g>
        <g id='ARC_00000049919188081916525790000015983660327632026534_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1120.83,337.73c-0.01,0.01-0.02,0.01-0.02,0.02'
          />
        </g>
        <g id='LWPOLYLINE_00000181769029800522865890000018404297627387062975_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1113.14,329.22 1113.4,328.9 1113.67,328.59 1113.95,328.28 1114.25,327.99 1114.55,327.72 1114.87,327.45 1115.2,327.2  1115.54,326.96 1115.89,326.73 1116.24,326.52 1116.61,326.33 1116.98,326.14 1117.36,325.98 1117.74,325.82 1118.13,325.69  1118.53,325.57 1118.93,325.46 1119.34,325.37 1119.74,325.3 1120.15,325.25 1120.57,325.21 1120.98,325.18 1121.39,325.18  1121.81,325.19 1122.22,325.21 1122.63,325.26 1123.04,325.32 1123.45,325.39 1123.85,325.49 1124.25,325.59 1124.65,325.72  1125.04,325.86 1125.42,326.01 1125.8,326.18 1126.17,326.37 1126.53,326.57 1126.89,326.78 1127.23,327.01 1127.57,327.25  1127.9,327.51 1128.21,327.78 1128.52,328.06 1128.81,328.35 1129.09,328.66 1129.36,328.97 1129.56,329.22  '
          />
        </g>
        <g id='LWPOLYLINE_00000072983808374299380360000017825347273332804500_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1129.56,332.62 1129.3,332.94 1129.03,333.25 1128.74,333.55 1128.45,333.84 1128.14,334.12 1127.82,334.39 1127.49,334.64  1127.16,334.88 1126.81,335.1 1126.45,335.32 1126.09,335.51 1125.72,335.7 1125.34,335.86 1124.95,336.01 1124.56,336.15  1124.16,336.27 1123.76,336.38 1123.36,336.46 1122.95,336.54 1122.54,336.59 1122.13,336.63 1121.86,336.65  '
          />
        </g>
        <g id='LWPOLYLINE_00000074439466303221273120000009745239800031797378_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1120.83,336.65 1120.47,336.62 1120.06,336.58 1119.65,336.52 1119.24,336.45 1118.84,336.35 1118.44,336.25 1118.05,336.12  1117.66,335.98 1117.27,335.83 1116.89,335.66 1116.52,335.47 1116.16,335.27 1115.81,335.05 1115.46,334.83 1115.13,334.58  1114.8,334.33 1114.48,334.06 1114.18,333.78 1113.89,333.49 1113.61,333.18 1113.34,332.87 1113.14,332.62  '
          />
        </g>
        <g id='LWPOLYLINE_00000170242930857541419600000013293342545810027939_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1112.76,330.53 1112.76,330.42 1112.77,330.3 1112.79,330.19 1112.81,330.08 1112.84,329.97 1112.86,329.86 1112.9,329.75  1112.94,329.65 1112.98,329.54 1113.02,329.44 1113.07,329.34 1113.13,329.24 1113.14,329.22  '
          />
        </g>
        <g id='LWPOLYLINE_00000021812167897441724970000006651157087189368742_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1113.14,332.62 1113.08,332.52 1113.03,332.42 1112.98,332.31 1112.94,332.21 1112.9,332.1 1112.87,332 1112.84,331.89  1112.81,331.78 1112.79,331.67 1112.78,331.55 1112.77,331.44 1112.76,331.33 1112.75,331.22  '
          />
        </g>
        <g id='LWPOLYLINE_00000132797393715859043490000000123819760808999609_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1112.75,331.2 1112.75,331.2 1112.75,331.2 1112.75,331.19 1112.75,331.19 1112.75,331.19 1112.75,331.19 1112.75,331.18  1112.75,331.18 1112.75,331.18 1112.75,331.17 1112.75,331.17 1112.75,331.16 1112.75,331.15 1112.75,331.15 1112.75,331.14  1112.75,331.14 1112.75,331.13 1112.75,331.12 1112.75,331.11 1112.75,331.1 1112.75,331.1 1112.75,331.09 1112.75,331.08  1112.75,331.07 1112.75,331.06 1112.75,331.05 1112.75,331.04 1112.75,331.03 1112.75,331.02 1112.75,331.01 1112.75,331  1112.75,330.99 1112.75,330.98 1112.75,330.97 1112.75,330.96 1112.75,330.94 1112.75,330.93 1112.75,330.92 1112.75,330.91  1112.75,330.9 1112.75,330.89 1112.75,330.88 1112.75,330.87 1112.75,330.86 1112.75,330.85 1112.75,330.83 1112.75,330.82  1112.75,330.81 1112.75,330.8 1112.75,330.79 1112.75,330.78 1112.75,330.77 1112.75,330.76 1112.75,330.76 1112.75,330.75  1112.75,330.74 1112.75,330.73 1112.75,330.72 1112.75,330.71 1112.75,330.71 1112.75,330.7 1112.75,330.69 1112.75,330.69  1112.75,330.68 1112.75,330.68 1112.75,330.67 1112.75,330.67 1112.75,330.66 1112.75,330.66 1112.75,330.65 1112.75,330.65  1112.75,330.65 1112.75,330.65 1112.75,330.64 1112.75,330.64 1112.75,330.64 1112.75,330.64  '
          />
        </g>
        <g id='LWPOLYLINE_00000162329659301888812070000015046754812240048810_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1129.56,329.22 1129.61,329.32 1129.66,329.42 1129.71,329.52 1129.75,329.63 1129.79,329.73 1129.83,329.84 1129.86,329.95  1129.88,330.06 1129.9,330.17 1129.92,330.28 1129.93,330.4 1129.94,330.51 1129.94,330.62  '
          />
        </g>
        <g id='LWPOLYLINE_00000088132563282490709650000013113039956819489978_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1129.94,331.31 1129.93,331.42 1129.92,331.54 1129.91,331.65 1129.89,331.76 1129.86,331.87 1129.83,331.98 1129.8,332.09  1129.76,332.19 1129.72,332.3 1129.67,332.4 1129.62,332.5 1129.57,332.6 1129.56,332.62  '
          />
        </g>
        <g id='LWPOLYLINE_00000027586485075942483440000002072957782634654630_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1129.94,330.64 1129.94,330.64 1129.94,330.64 1129.94,330.64 1129.94,330.65 1129.94,330.65 1129.94,330.65 1129.94,330.66  1129.94,330.66 1129.94,330.66 1129.94,330.67 1129.94,330.67 1129.94,330.68 1129.94,330.68 1129.94,330.69 1129.94,330.7  1129.94,330.7 1129.94,330.71 1129.94,330.72 1129.94,330.73 1129.94,330.73 1129.94,330.74 1129.94,330.75 1129.94,330.76  1129.94,330.77 1129.94,330.78 1129.94,330.79 1129.94,330.8 1129.94,330.81 1129.94,330.82 1129.94,330.83 1129.94,330.84  1129.94,330.85 1129.94,330.86 1129.94,330.87 1129.94,330.88 1129.94,330.89 1129.94,330.91 1129.94,330.92 1129.94,330.93  1129.94,330.94 1129.94,330.95 1129.94,330.96 1129.94,330.97 1129.94,330.98 1129.94,330.99 1129.94,331 1129.94,331.01  1129.94,331.02 1129.94,331.04 1129.94,331.05 1129.94,331.05 1129.94,331.06 1129.94,331.07 1129.94,331.08 1129.94,331.09  1129.94,331.1 1129.94,331.11 1129.94,331.12 1129.94,331.12 1129.94,331.13 1129.94,331.14 1129.94,331.15 1129.94,331.15  1129.94,331.16 1129.94,331.16 1129.94,331.17 1129.94,331.17 1129.94,331.18 1129.94,331.18 1129.94,331.18 1129.94,331.19  1129.94,331.19 1129.94,331.19 1129.94,331.19 1129.94,331.2 1129.94,331.2 1129.94,331.2  '
          />
        </g>
        <g id='LINE_00000183251072608972481320000006183390958994215559_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1129.44}
            y1={332.54}
            x2={1129.44}
            y2={329.3}
          />
        </g>
        <g id='SPLINE_00000030485704390671501070000007411707358153525683_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1120.65,330.7c0-0.08,0.01-0.16,0.04-0.24c0.03-0.08,0.07-0.15,0.12-0.21s0.11-0.11,0.18-0.16s0.15-0.07,0.23-0.08 c0.08-0.01,0.16-0.01,0.24,0c0.08,0.01,0.16,0.04,0.23,0.08c0.07,0.04,0.13,0.09,0.18,0.16c0.05,0.06,0.09,0.13,0.12,0.21 c0.03,0.08,0.04,0.16,0.04,0.24'
          />
        </g>
        <g id='SPLINE_00000107583301814203714030000014834852162284274852_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1122.04,330.7c0,0.08-0.01,0.16-0.04,0.24c-0.03,0.08-0.07,0.15-0.12,0.21s-0.11,0.11-0.18,0.16c-0.07,0.04-0.15,0.07-0.23,0.08 c-0.08,0.01-0.16,0.01-0.24,0c-0.08-0.01-0.16-0.04-0.23-0.08s-0.13-0.09-0.19-0.16c-0.05-0.06-0.09-0.13-0.12-0.21 c-0.03-0.08-0.04-0.16-0.04-0.24'
          />
        </g>
        <g id='LINE_00000171695534253124053790000004080106223534390709_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1113.25}
            y1={329.3}
            x2={1113.25}
            y2={332.54}
          />
        </g>
        <g id='LINE_00000035510726350606779330000005654991191491050396_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1132.15}
            y1={320.84}
            x2={1129.99}
            y2={320.84}
          />
        </g>
        <g id='LINE_00000052070899659221682150000017005773079761428621_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1112.87}
            y1={320.84}
            x2={1110.71}
            y2={320.84}
          />
        </g>
        <g id='LINE_00000086669996399633118090000017653593070014021304_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1109.82}
            y1={330.88}
            x2={1109.82}
            y2={340.86}
          />
        </g>
        <g id='LINE_00000112601293300161158340000007071065313139620757_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1133.04}
            y1={340.86}
            x2={1133.04}
            y2={330.88}
          />
        </g>
        <g id='LINE_00000177455429151646141200000012959266546364185769_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1129.99}
            y1={320.84}
            x2={1112.87}
            y2={320.84}
          />
        </g>
        <g id='LINE_00000159448697981678206970000003560638477789116578_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1109.82}
            y1={330.88}
            x2={1109.82}
            y2={321.93}
          />
        </g>
        <g id='LINE_00000173145938747050608250000014821912576093458560_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1133.04}
            y1={321.93}
            x2={1133.04}
            y2={330.88}
          />
        </g>
        <g id='LINE_00000066494794284897194160000000124186483270313651_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1130.45}
            y1={320.96}
            x2={1132.46}
            y2={320.96}
          />
        </g>
        <g id='SPLINE_00000183936028463334092810000014855772276814237835_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1132.46,320.96c-0.02-0.01-0.03-0.02-0.05-0.03s-0.03-0.02-0.05-0.02c-0.01,0-0.02-0.01-0.03-0.01c-0.01,0-0.02-0.01-0.03-0.01 c-0.01,0-0.02-0.01-0.03-0.01c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0s0,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0 c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0 c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0'
          />
        </g>
        <g id='SPLINE_00000114780329288357006450000013908460305094075041_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1110.7,320.84C1110.71,320.84,1110.71,320.84,1110.7,320.84C1110.71,320.84,1110.71,320.84,1110.7,320.84 C1110.71,320.84,1110.71,320.84,1110.7,320.84C1110.7,320.84,1110.7,320.84,1110.7,320.84 C1110.7,320.84,1110.7,320.85,1110.7,320.84c-0.01,0-0.01,0-0.02,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0 c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0s0,0-0.01,0 c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01c-0.01,0-0.02,0.01-0.03,0.01 c-0.02,0.01-0.03,0.01-0.05,0.02c-0.02,0.01-0.03,0.02-0.05,0.03'
          />
        </g>
        <g id='LINE_00000001637118463185238780000017652029020384081560_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1110.4}
            y1={320.96}
            x2={1112.41}
            y2={320.96}
          />
        </g>
        <g id='ARC_00000150803666438339386270000018415388167358366385_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1111.68,334.14c0.28,1.66,0.62,3.31,1.03,4.94'
          />
        </g>
        <g id='ARC_00000111891674397098221030000002211653572026598795_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1111.03,328.99c0.14,1.58,0.33,3.14,0.58,4.71'
          />
        </g>
        <g id='ARC_00000101100441287383683770000012857193518322684606_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1110.79,323.51c0,1.7,0.07,3.4,0.2,5.1'
          />
        </g>
        <g id='ARC_00000046314046614016348400000012696634127694644913_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1131.83,328.91c0.14-1.7,0.22-3.4,0.23-5.11'
          />
        </g>
        <g id='ARC_00000028318336209804058770000014967872296869168045_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1131.21,333.93c0.27-1.65,0.48-3.31,0.62-4.97'
          />
        </g>
        <g id='ARC_00000021094544762145811280000006723683803456673696_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1130.16,338.99c0.41-1.65,0.76-3.31,1.04-4.99'
          />
        </g>
        <g id='ARC_00000058571860249836685940000008923231449104516026_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1113.58,339.33c1.55,0.17,3.1,0.3,4.65,0.37'
          />
        </g>
        <g id='ARC_00000130608482475970160410000010215053819154197685_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1119.1,339.73c1.5,0.05,3,0.05,4.49,0.01'
          />
        </g>
        <g id='ARC_00000178913112481081219080000007531185288940487359_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1124.45,339.71c1.55-0.07,3.11-0.19,4.65-0.35'
          />
        </g>
        <g id='ARC_00000026122847922465295720000009675567241771162815_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1132.06,323.8c-0.05-0.14-0.12-0.28-0.2-0.41'
          />
        </g>
        <g id='ARC_00000047753212087124518260000005262615371222154635_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1131.78,323.27c-0.06-0.08-0.13-0.16-0.2-0.24'
          />
        </g>
        <g id='ARC_00000119821296648903375150000007514299960006513290_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1131.47,322.92c-0.07-0.07-0.15-0.13-0.23-0.18'
          />
        </g>
        <g id='ARC_00000127752405761636797770000000095256662273447298_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1131.09,322.65c-0.09-0.05-0.18-0.1-0.28-0.14'
          />
        </g>
        <g id='ARC_00000174583931633353989000000005884675600766224521_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1130.68,322.46c-0.14-0.05-0.29-0.08-0.44-0.1'
          />
        </g>
        <g id='ARC_00000154405495503463539640000001954227175627661472_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1130.24,322.36c-1.97-0.23-3.94-0.39-5.92-0.46'
          />
        </g>
        <g id='ARC_00000018937567480949194120000002625931522708272777_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1124.31,321.9c-1.98-0.07-3.96-0.07-5.94,0.01'
          />
        </g>
        <g id='ARC_00000151530655017760687940000017737300023912527288_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1118.37,321.91c-1.98,0.08-3.95,0.25-5.92,0.49'
          />
        </g>
        <g id='ARC_00000057132018432663420950000011714641815472695186_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1112.45,322.4c-0.16,0.02-0.31,0.06-0.46,0.11'
          />
        </g>
        <g id='ARC_00000134937112252755352910000007990101077960221828_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1111.86,322.56c-0.1,0.04-0.2,0.09-0.29,0.15'
          />
        </g>
        <g id='ARC_00000069361792343403482970000016981964445686108806_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1111.42,322.81c-0.09,0.06-0.17,0.13-0.25,0.21'
          />
        </g>
        <g id='ARC_00000142864628887057805160000006533002595612282755_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1111.07,323.12c-0.11,0.12-0.2,0.24-0.28,0.38'
          />
        </g>
        <g id='ARC_00000170272282812701000790000003290454687956598962_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1129.44,329.3c-3.44-4.47-9.86-5.3-14.33-1.86c-0.7,0.54-1.32,1.16-1.86,1.86'
          />
        </g>
        <g id='ARC_00000063606391425488170300000001652904149654854065_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1129.8,330.64c0-0.47-0.12-0.93-0.36-1.34'
          />
        </g>
        <g id='LINE_00000079452198523255929540000004253581369044825016_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1129.8}
            y1={330.64}
            x2={1129.8}
            y2={331.2}
          />
        </g>
        <g id='ARC_00000149346703834099749370000016777502999520248204_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1129.44,332.54c0.24-0.41,0.36-0.87,0.36-1.34'
          />
        </g>
        <g id='ARC_00000082350315413404757920000002453033191350242746_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1121.87,336.51c2.98-0.15,5.75-1.6,7.58-3.97'
          />
        </g>
        <g id='ARC_00000124158776608651053660000014827216343362642593_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1113.25,332.54c1.82,2.37,4.59,3.82,7.58,3.97'
          />
        </g>
        <g id='ARC_00000169548682047163783370000012147876496754030469_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1112.89,331.2c0,0.47,0.12,0.93,0.36,1.34'
          />
        </g>
        <g id='LINE_00000183208874803419759990000015815113957948551560_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1112.89}
            y1={331.2}
            x2={1112.89}
            y2={330.64}
          />
        </g>
        <g id='ARC_00000092454633026064720100000000496877621311169169_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1113.25,329.3c-0.24,0.41-0.36,0.87-0.36,1.34'
          />
        </g>
        <g id='SPLINE_00000114063141726454970710000007262560185783485884_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1110.02,340.86c0-0.08,0.01-0.16,0.01-0.25c0-0.08,0-0.17,0-0.25c0-0.08,0-0.17-0.01-0.24'
          />
        </g>
        <g id='SPLINE_00000047738473905168999280000015713682698559261570_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1132.85,340.12c0,0.08-0.01,0.16-0.01,0.24c0,0.08,0,0.17,0,0.25c0,0.08,0,0.17,0.01,0.25'
          />
        </g>
        <g id='SPLINE_00000079456022959064268380000015243964606055335857_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1130.16,338.99c0.41-1.63,0.75-3.28,1.03-4.95c0.28-1.67,0.49-3.37,0.64-5.08c0.15-1.71,0.22-3.43,0.23-5.16'
          />
        </g>
        <g id='SPLINE_00000052104910938481476690000008574913291984041622_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1110.79,323.51c0,2.28,0.12,4.55,0.36,6.79c0.16,1.49,0.37,2.98,0.63,4.44s0.57,2.91,0.93,4.34'
          />
        </g>
        <g id='SPLINE_00000039841734700977232490000005364427847302294941_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1109.82,321.93c0-0.12,0.02-0.24,0.06-0.35c0.04-0.11,0.09-0.22,0.16-0.32c0.05-0.06,0.1-0.12,0.16-0.17 c0.06-0.05,0.12-0.1,0.2-0.14'
          />
        </g>
        <g id='SPLINE_00000061452526958651772030000007230395027828704684_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1110.02,340.12c-0.05-1.01-0.09-2.03-0.12-3.05s-0.05-2.05-0.06-3.08c-0.01-1.03-0.02-2.07-0.02-3.11'
          />
        </g>
        <g id='SPLINE_00000105403866551304495910000011782926993169993657_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1133.04,330.88c0,1.04,0,2.08-0.02,3.11c-0.01,1.03-0.03,2.06-0.06,3.08c-0.03,1.02-0.07,2.04-0.12,3.05'
          />
        </g>
        <g id='SPLINE_00000018215534607775258730000001050323534894493854_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1132.46,320.96c0.11,0.06,0.2,0.13,0.28,0.22c0.08,0.09,0.15,0.19,0.2,0.3c0.03,0.07,0.06,0.15,0.08,0.23 c0.02,0.08,0.03,0.16,0.03,0.24'
          />
        </g>
        <g id='SPLINE_00000098179306978315363850000010148299328625410988_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1113.58,339.33c1.55,0.17,3.1,0.3,4.65,0.37c1.55,0.07,3.11,0.09,4.67,0.06c1.04-0.02,2.07-0.07,3.11-0.13 c1.04-0.07,2.07-0.16,3.1-0.27'
          />
        </g>
        <g id='SPLINE_00000178190903476287795850000011920521077391717039_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1112.72,339.07c0.13,0.07,0.27,0.12,0.42,0.17c0.14,0.04,0.29,0.07,0.44,0.09'
          />
        </g>
        <g id='SPLINE_00000158748967631182087410000013058820768399038135_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1129.11,339.35c0.19-0.02,0.37-0.06,0.55-0.12c0.18-0.06,0.34-0.14,0.5-0.24'
          />
        </g>
        <g id='SPLINE_00000112596625854279992700000015713146403154259125_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1130.24,322.36c-1.97-0.23-3.94-0.39-5.92-0.46c-1.98-0.07-3.96-0.07-5.94,0.01c-1.98,0.08-3.95,0.25-5.92,0.49'
          />
        </g>
        <g id='SPLINE_00000119111732766148403590000009453075520798089400_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1132.06,323.8c-0.05-0.13-0.11-0.25-0.17-0.37c-0.07-0.12-0.15-0.23-0.24-0.33c-0.18-0.2-0.4-0.38-0.64-0.5 c-0.12-0.06-0.25-0.12-0.38-0.16s-0.26-0.07-0.4-0.09'
          />
        </g>
        <g id='SPLINE_00000178890739947832854580000003716795113494648994_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1112.45,322.4c-0.1,0.01-0.21,0.03-0.3,0.06c-0.1,0.03-0.2,0.06-0.29,0.1c-0.19,0.08-0.37,0.19-0.53,0.32 c-0.11,0.09-0.21,0.18-0.29,0.29c-0.09,0.1-0.17,0.22-0.24,0.34'
          />
        </g>
        <g id='LINE_00000115481559891145700980000008930410610046269604_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1130.45}
            y1={320.96}
            x2={1112.41}
            y2={320.96}
          />
        </g>
        <g id='LINE_00000164489447403518648690000004597174035994913920_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1739.69}
            y1={770.65}
            x2={1746.63}
            y2={770.49}
          />
        </g>
        <g id='LINE_00000038380637879067641910000010679357036368272277_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.63}
            y1={751.05}
            x2={1739.69}
            y2={751.21}
          />
        </g>
        <g id='ARC_00000153664093238277936060000016532018804330213520_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1739.69,751.21c-0.29,0.01-0.57,0.13-0.78,0.34'
          />
        </g>
        <g id='LINE_00000115504557436740949670000008301804021758112445_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1738.91}
            y1={751.56}
            x2={1733.91}
            y2={756.79}
          />
        </g>
        <g id='ARC_00000063604217252069368400000010052498172486218631_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1733.91,756.79c-0.2,0.21-0.31,0.5-0.31,0.79'
          />
        </g>
        <g id='LINE_00000050627449629542965470000012005028936141944758_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.6}
            y1={757.58}
            x2={1733.78}
            y2={765.01}
          />
        </g>
        <g id='ARC_00000124150188738339222780000015547391771787387315_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1733.78,765.01c0.01,0.29,0.13,0.57,0.34,0.78'
          />
        </g>
        <g id='LINE_00000042711282953594227220000008794182171554414741_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1734.12}
            y1={765.78}
            x2={1738.9}
            y2={770.35}
          />
        </g>
        <g id='ARC_00000157992908510255041080000004306594389725155205_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1738.9,770.35c0.21,0.2,0.5,0.31,0.79,0.31'
          />
        </g>
        <g id='SPLINE_00000044162991734479812720000003624575054268517046_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.48,760.75c0.01,0.3,0,0.6-0.01,0.89c-0.02,0.3-0.04,0.6-0.08,0.89s-0.09,0.59-0.16,0.88c-0.07,0.29-0.14,0.58-0.24,0.86 c-0.09,0.28-0.2,0.56-0.32,0.83s-0.26,0.53-0.43,0.79s-0.34,0.49-0.55,0.71c-0.1,0.11-0.21,0.21-0.33,0.31 c-0.11,0.1-0.23,0.18-0.36,0.26c-0.13,0.08-0.26,0.15-0.4,0.21c-0.07,0.03-0.14,0.06-0.21,0.08c-0.07,0.02-0.14,0.04-0.21,0.06 c-0.07,0.02-0.15,0.03-0.22,0.04s-0.15,0.01-0.22,0.02c-0.08,0-0.15,0-0.22-0.01s-0.15-0.02-0.22-0.03 c-0.07-0.01-0.14-0.03-0.22-0.05c-0.07-0.02-0.14-0.04-0.21-0.07c-0.14-0.05-0.28-0.12-0.41-0.19c-0.13-0.07-0.25-0.16-0.37-0.25 c-0.12-0.09-0.23-0.19-0.34-0.29c-0.21-0.21-0.41-0.44-0.58-0.68s-0.33-0.5-0.46-0.77c-0.14-0.27-0.26-0.54-0.36-0.82 c-0.11-0.28-0.2-0.56-0.28-0.85c-0.08-0.29-0.14-0.58-0.2-0.87s-0.09-0.59-0.12-0.89s-0.05-0.6-0.06-0.89'
          />
        </g>
        <g id='SPLINE_00000081629716689354716050000007686506760626073761_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1736.7,760.93c-0.01-0.3,0-0.6,0.01-0.9c0.02-0.3,0.04-0.6,0.08-0.89c0.04-0.3,0.09-0.59,0.16-0.88 c0.06-0.29,0.14-0.58,0.24-0.86c0.09-0.28,0.2-0.56,0.33-0.84c0.12-0.27,0.27-0.54,0.43-0.79s0.34-0.49,0.55-0.71 c0.1-0.11,0.21-0.21,0.32-0.31c0.11-0.1,0.23-0.18,0.36-0.26c0.13-0.08,0.26-0.15,0.4-0.21c0.07-0.03,0.14-0.06,0.21-0.08 c0.07-0.02,0.14-0.04,0.21-0.06c0.07-0.02,0.15-0.03,0.22-0.04s0.15-0.01,0.22-0.02c0.08,0,0.15,0,0.22,0.01 c0.07,0.01,0.15,0.02,0.22,0.03c0.07,0.01,0.14,0.03,0.22,0.05c0.07,0.02,0.14,0.04,0.21,0.07c0.14,0.05,0.28,0.12,0.41,0.19 c0.13,0.07,0.25,0.16,0.37,0.25c0.12,0.09,0.23,0.19,0.34,0.29c0.21,0.21,0.41,0.44,0.58,0.68s0.33,0.5,0.46,0.77 c0.14,0.26,0.26,0.54,0.36,0.82c0.11,0.28,0.2,0.56,0.28,0.85s0.14,0.58,0.2,0.87c0.05,0.29,0.09,0.59,0.12,0.89 c0.03,0.3,0.05,0.6,0.06,0.89'
          />
        </g>
        <g id='LWPOLYLINE_00000171700159189500664890000004269913153946428856_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1728.7,753.47 1728.03,753.44 1727.36,753.44 1726.69,753.47 1726.03,753.52 1725.36,753.6 1724.7,753.71 1724.04,753.84  1723.39,754 1722.75,754.19 1722.11,754.4 1721.49,754.64 1720.87,754.9 1720.26,755.18 1719.67,755.49 1719.09,755.82  1718.52,756.18 1717.97,756.56 1717.43,756.96 1716.91,757.38 1716.41,757.82 1715.92,758.28 1715.45,758.77 1715.01,759.26  1714.58,759.78 1714.48,759.9  '
          />
        </g>
        <g id='LWPOLYLINE_00000099637152143162082690000002727770870061461897_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1733.5,756.8 1733.29,756.52 1733.06,756.24 1732.82,755.98 1732.58,755.72 1732.32,755.47 1732.05,755.24 1731.77,755.01  1731.49,754.8 1731.19,754.6 1730.89,754.41 1730.58,754.23 1730.26,754.07 1729.94,753.92 1729.61,753.78 1729.28,753.65  1728.94,753.54 1728.7,753.47  '
          />
        </g>
        <g id='LWPOLYLINE_00000049912590134205501500000011471973931821016755_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1728.7,769.12 1729.04,769.01 1729.38,768.9 1729.71,768.77 1730.04,768.62 1730.36,768.47 1730.68,768.3 1730.98,768.12  1731.28,767.93 1731.57,767.72 1731.86,767.5 1732.13,767.28 1732.4,767.04 1732.65,766.79 1732.9,766.53 1733.13,766.26  1733.36,765.98 1733.57,765.7 1733.71,765.5  '
          />
        </g>
        <g id='LWPOLYLINE_00000089557250997678324940000015526744634266692266_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1714.48,762.68 1714.91,763.2 1715.35,763.7 1715.81,764.19 1716.29,764.66 1716.79,765.1 1717.31,765.53 1717.84,765.93  1718.39,766.32 1718.95,766.68 1719.53,767.02 1720.12,767.33 1720.73,767.62 1721.34,767.89 1721.96,768.13 1722.6,768.35  1723.24,768.54 1723.89,768.71 1724.54,768.85 1725.2,768.96 1725.87,769.05 1726.54,769.11 1727.21,769.14 1727.88,769.15  1728.54,769.13 1728.7,769.12  '
          />
        </g>
        <g id='LINE_00000000925936945186713690000016139685484300646303_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1714.06}
            y1={762.14}
            x2={1714.48}
            y2={762.68}
          />
        </g>
        <g id='LINE_00000158744073694273325390000005283645492877336755_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1714.48}
            y1={759.9}
            x2={1714.06}
            y2={760.44}
          />
        </g>
        <g id='LWPOLYLINE_00000153691543553948483500000013280458776115585461_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1730.79,761.64 1730.78,761.46 1730.77,761.28 1730.75,761.1 1730.72,760.96  '
          />
        </g>
        <g id='LWPOLYLINE_00000155145123983680197000000007637650319557397133_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1730.72,760.96 1730.74,760.78 1730.75,760.6 1730.76,760.42 1730.76,760.27  '
          />
        </g>
        <g id='LINE_00000181072291556552138110000005635466327402367661_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.79}
            y1={765.55}
            x2={1733.78}
            y2={765.01}
          />
        </g>
        <g id='LINE_00000083793141209056123680000002040377380092199610_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.6}
            y1={757.58}
            x2={1733.59}
            y2={756.74}
          />
        </g>
        <g id='LINE_00000010299536645684050450000005940673358914274452_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.59}
            y1={756.74}
            x2={1733.46}
            y2={756.83}
          />
        </g>
        <g id='ARC_00000081622122672800209060000018216620618815774383_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1733.46,756.83c-1.17-1.61-2.85-2.77-4.77-3.31'
          />
        </g>
        <g id='ARC_00000155140028521636059220000004293446236867388586_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1728.69,753.53c-5.49-0.33-10.79,2.07-14.16,6.41'
          />
        </g>
        <g id='LINE_00000001635064866163747220000016232319565840398735_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1714.53}
            y1={759.94}
            x2={1714.01}
            y2={760.61}
          />
        </g>
        <g id='ARC_00000000184472069398582150000015184000838124187573_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.01,760.61c-0.13,0.45-0.13,0.92,0,1.37'
          />
        </g>
        <g id='LINE_00000060750269632989804470000002891478564860442502_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1714.01}
            y1={761.98}
            x2={1714.53}
            y2={762.65}
          />
        </g>
        <g id='ARC_00000153673280838652387800000006039421802455767980_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.53,762.65c3.38,4.34,8.67,6.74,14.16,6.41'
          />
        </g>
        <g id='ARC_00000045602916947017644000000002887046464889480845_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1728.69,769.06c2.03-0.57,3.79-1.84,4.97-3.6'
          />
        </g>
        <g id='LINE_00000092442788442320166850000000517427154749293957_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1733.66}
            y1={765.46}
            x2={1733.79}
            y2={765.55}
          />
        </g>
        <g id='ARC_00000145017935234227435000000018280235274732194229_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1726.54,766.23c2.48-0.13,4.38-2.25,4.24-4.73c-0.01-0.18-0.03-0.36-0.06-0.54'
          />
        </g>
        <g id='ARC_00000169552375169412093530000011423823360283801247_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1716.84,762.03c2.39,2.85,5.99,4.41,9.7,4.2'
          />
        </g>
        <g id='ARC_00000043418289327333696090000003929231585802882460_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1716.51,761.29c0.01,0.28,0.12,0.55,0.33,0.74'
          />
        </g>
        <g id='ARC_00000005230974598100181160000013337346668078479507_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1716.8,760.53c-0.19,0.2-0.3,0.47-0.29,0.75'
          />
        </g>
        <g id='ARC_00000102529253471466281440000001748079992184606085_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1726.3,755.88c-3.72-0.03-7.24,1.69-9.5,4.65'
          />
        </g>
        <g id='ARC_00000127763394465630510310000014165892961312840373_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1730.72,760.96c0.32-2.46-1.41-4.72-3.87-5.04c-0.18-0.02-0.36-0.04-0.55-0.04'
          />
        </g>
        <g id='SPLINE_00000167367872328666178290000002368459457527334788_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.01,760.61c0.02-0.07,0.04-0.11,0.05-0.14c0-0.01,0.01-0.02,0.01-0.02c0,0,0,0,0,0s0,0,0,0.01'
          />
        </g>
        <g id='SPLINE_00000018916524254428348150000004672815854733813376_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.02,760.57c0,0.01,0,0.01-0.01,0.02c0,0.01,0,0.01-0.01,0.02c0,0.02-0.01,0.03-0.01,0.05s-0.01,0.04-0.01,0.05 c0,0.02-0.01,0.04-0.01,0.06c0,0.02-0.01,0.04-0.01,0.06c0,0.02-0.01,0.04-0.01,0.06'
          />
        </g>
        <g id='SPLINE_00000128456740969734390010000015694260529561703080_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1713.92,761.02c-0.01,0.09-0.02,0.18-0.02,0.28'
          />
        </g>
        <g id='SPLINE_00000000184967154736666230000009885387279106304904_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1713.91,761.29c0,0.1,0.01,0.19,0.02,0.28'
          />
        </g>
        <g id='SPLINE_00000092450254425400681500000013227980192586063268_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1713.94,761.7c0,0.02,0.01,0.04,0.01,0.06c0,0.02,0.01,0.04,0.01,0.06s0.01,0.04,0.01,0.06s0.01,0.04,0.01,0.05 c0,0.02,0.01,0.03,0.01,0.05c0,0.01,0,0.02,0.01,0.02c0,0.01,0,0.01,0.01,0.02'
          />
        </g>
        <g id='SPLINE_00000095304342407799340400000008311312445084605068_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1714.06,762.13C1714.07,762.14,1714.07,762.14,1714.06,762.13c0,0,0,0-0.01-0.02c-0.01-0.03-0.03-0.07-0.05-0.14'
          />
        </g>
        <g id='SPLINE_00000075124688736911630770000016675245016352631465_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1728.69,753.53c0.01-0.04,0.01-0.06,0.01-0.06'
          />
        </g>
        <g id='SPLINE_00000094577270787447849490000010232931333350520225_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1728.69,769.06c0.01,0.04,0.01,0.06,0.01,0.06'
          />
        </g>
        <g id='SPLINE_00000161620887781742152890000000506136266101621409_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1725.35,760.57c0.12,0.11,0.22,0.25,0.29,0.39c0.07,0.15,0.12,0.31,0.13,0.47c0.01,0.08,0.01,0.16,0,0.24 c-0.01,0.08-0.02,0.16-0.04,0.24c-0.01,0.04-0.02,0.08-0.03,0.12c-0.01,0.04-0.03,0.08-0.04,0.11c-0.02,0.04-0.03,0.07-0.05,0.11 c-0.02,0.03-0.04,0.07-0.06,0.1c-0.02,0.03-0.05,0.07-0.07,0.1c-0.03,0.03-0.05,0.06-0.08,0.09s-0.06,0.06-0.09,0.08 c-0.03,0.03-0.06,0.05-0.09,0.07c-0.03,0.02-0.06,0.05-0.1,0.07c-0.03,0.02-0.07,0.04-0.1,0.06c-0.04,0.02-0.07,0.03-0.11,0.05 c-0.04,0.01-0.07,0.03-0.11,0.04s-0.08,0.02-0.12,0.03c-0.04,0.01-0.08,0.01-0.12,0.02c-0.08,0.01-0.16,0.01-0.24,0.01 c-0.16-0.01-0.32-0.05-0.47-0.11c-0.15-0.07-0.29-0.16-0.41-0.27'
          />
        </g>
        <g id='SPLINE_00000134949189662431257240000008126077126541883310_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1723.43,762.58c-0.12-0.11-0.21-0.25-0.29-0.39c-0.07-0.15-0.12-0.3-0.14-0.46c-0.02-0.16-0.01-0.32,0.03-0.48 c0.04-0.16,0.11-0.31,0.2-0.44c0.09-0.13,0.2-0.25,0.34-0.35s0.28-0.17,0.43-0.21c0.16-0.04,0.32-0.06,0.48-0.05 c0.08,0.01,0.16,0.02,0.24,0.04c0.08,0.02,0.15,0.05,0.23,0.08c0.15,0.06,0.28,0.16,0.4,0.27'
          />
        </g>
        <g id='LINE_00000155126281259866026460000013219527965526601371_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1716.8}
            y1={760.53}
            x2={1716.84}
            y2={762.03}
          />
        </g>
        <g id='LWPOLYLINE_00000168815284088376919610000000106397672271499150_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1746.51,760.64 1746.51,760.64 1746.51,760.65 1746.51,760.65 1746.51,760.65 1746.51,760.66 1746.51,760.66 1746.51,760.67  1746.51,760.67 1746.51,760.68 1746.5,760.68 1746.5,760.69 1746.5,760.69 1746.5,760.69 1746.5,760.7 1746.49,760.7  1746.49,760.71 1746.49,760.71 1746.49,760.71 1746.48,760.72 1746.48,760.72 1746.48,760.72 1746.47,760.73 1746.47,760.73  1746.47,760.73 1746.46,760.73 1746.46,760.74 1746.45,760.74 1746.45,760.74 1746.45,760.74 1746.44,760.74 1746.44,760.75  1746.43,760.75 1746.43,760.75 1746.42,760.75 1746.42,760.75 1746.42,760.75 1746.41,760.75 1746.41,760.75 1746.4,760.75  1746.4,760.75  '
          />
        </g>
        <g id='LWPOLYLINE_00000095326285631505435180000000480212908604004024_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1745.76,760.77 1745.8,760.77 1745.84,760.77 1745.88,760.77 1745.92,760.76 1745.96,760.76 1746,760.76 1746.03,760.76  1746.07,760.76 1746.1,760.76 1746.13,760.76 1746.16,760.76 1746.19,760.76 1746.22,760.76 1746.24,760.76 1746.27,760.76  1746.29,760.76 1746.31,760.76 1746.32,760.76 1746.34,760.75 1746.35,760.75 1746.37,760.75 1746.38,760.75 1746.39,760.75  1746.39,760.75 1746.4,760.75 1746.4,760.75 1746.4,760.75  '
          />
        </g>
        <g id='LINE_00000085965321289555127620000003108758351052776585_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.4}
            y1={760.75}
            x2={1746.4}
            y2={760.75}
          />
        </g>
        <g id='LWPOLYLINE_00000056386289404481404540000005552648789533009322_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1746.4,760.53 1746.4,760.53 1746.4,760.53 1746.41,760.53 1746.41,760.53 1746.42,760.53 1746.42,760.53 1746.43,760.53  1746.43,760.53 1746.44,760.53 1746.44,760.53 1746.44,760.54 1746.45,760.54 1746.45,760.54 1746.46,760.54 1746.46,760.54  1746.46,760.55 1746.47,760.55 1746.47,760.55 1746.47,760.55 1746.48,760.56 1746.48,760.56 1746.48,760.56 1746.49,760.57  1746.49,760.57 1746.49,760.58 1746.49,760.58 1746.5,760.58 1746.5,760.59 1746.5,760.59 1746.5,760.6 1746.51,760.6  1746.51,760.6 1746.51,760.61 1746.51,760.61 1746.51,760.62 1746.51,760.62 1746.51,760.63 1746.51,760.63 1746.51,760.64  1746.51,760.64  '
          />
        </g>
        <g id='LINE_00000021094917506475767360000018123903592689364659_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.4}
            y1={760.53}
            x2={1746.4}
            y2={760.53}
          />
        </g>
        <g id='LWPOLYLINE_00000010273616466747344700000001121636602033559212_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1746.4,760.53 1746.39,760.53 1746.39,760.53 1746.39,760.53 1746.38,760.53 1746.37,760.53 1746.36,760.53 1746.35,760.53  1746.33,760.53 1746.32,760.53 1746.3,760.53 1746.28,760.53 1746.26,760.53 1746.23,760.53 1746.21,760.53 1746.18,760.53  1746.15,760.53 1746.12,760.53 1746.09,760.53 1746.06,760.53 1746.02,760.53 1745.99,760.53 1745.95,760.54 1745.91,760.54  1745.87,760.54 1745.83,760.54 1745.79,760.54 1745.75,760.54  '
          />
        </g>
        <g id='LWPOLYLINE_00000016787485116690478690000000638836607152593063_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1744.65,760.79 1744.69,760.79 1744.73,760.79 1744.77,760.79 1744.82,760.79 1744.86,760.79 1744.91,760.79 1744.95,760.79  1745,760.79 1745.04,760.78 1745.09,760.78 1745.14,760.78 1745.18,760.78 1745.23,760.78 1745.28,760.78 1745.32,760.78  1745.37,760.78 1745.42,760.78 1745.46,760.77 1745.51,760.77 1745.55,760.77 1745.6,760.77 1745.64,760.77 1745.68,760.77  1745.72,760.77 1745.76,760.77  '
          />
        </g>
        <g id='LWPOLYLINE_00000069365289002663831560000009304218612881324172_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1745.75,760.54 1745.71,760.54 1745.67,760.54 1745.63,760.54 1745.58,760.54 1745.54,760.55 1745.49,760.55 1745.45,760.55  1745.4,760.55 1745.36,760.55 1745.31,760.55 1745.26,760.55 1745.22,760.55 1745.17,760.55 1745.12,760.55 1745.08,760.56  1745.03,760.56 1744.98,760.56 1744.94,760.56 1744.89,760.56 1744.85,760.56 1744.8,760.56 1744.76,760.56 1744.72,760.56  1744.68,760.57 1744.64,760.57  '
          />
        </g>
        <g id='ELLIPSE_00000173839057731151182460000009306175021950049181_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1746.46,760.74c0.03-0.02,0.06-0.06,0.05-0.1'
          />
        </g>
        <g id='ELLIPSE_00000149344461566444696750000003356066609528776108_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1746.32,760.72c0.01,0.01,0.02,0.01,0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000008856362898507363960000004104594082318745509_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1746.51,760.64c0-0.04-0.02-0.08-0.06-0.1'
          />
        </g>
        <g id='ELLIPSE_00000026136343307751963410000010391768044187365785_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.61,760.58c-0.02,0.02-0.03,0.06-0.02,0.1'
          />
        </g>
        <g id='ELLIPSE_00000041292459023745362490000011844281541514279073_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.66,760.57c-0.01-0.01-0.02,0-0.03,0'
          />
        </g>
        <g id='ELLIPSE_00000008865501939955960090000004461199705415438012_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.7,760.68c0-0.02,0-0.04-0.01-0.06'
          />
        </g>
        <g id='ELLIPSE_00000031199553838037813650000003374456974788977570_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.69,760.74c0-0.02,0.01-0.04,0.01-0.06'
          />
        </g>
        <g id='ELLIPSE_00000138559146481144877680000006715581391057479870_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.63,760.79c0.01,0,0.02,0,0.03,0'
          />
        </g>
        <g id='ELLIPSE_00000181768580029743242020000011006317144902591373_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1744.59,760.68c0,0.04,0.01,0.08,0.03,0.1'
          />
        </g>
        <g id='LINE_00000106149444446704710410000004883414815651952292_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1747.51}
            y1={751.03}
            x2={1746.63}
            y2={751.05}
          />
        </g>
        <g id='LINE_00000022555905388396169930000016745530821860725906_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.63}
            y1={751.05}
            x2={1746.63}
            y2={770.49}
          />
        </g>
        <g id='LINE_00000003063307135865749440000001509759379267827376_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1746.63}
            y1={770.49}
            x2={1747.51}
            y2={770.47}
          />
        </g>
        <g id='LINE_00000005256535797857488480000007443896265661331854_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1816.72}
            y1={723.7}
            x2={1816.88}
            y2={730.64}
          />
        </g>
        <g id='LINE_00000009551261455280407940000005372563882268007587_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1836.33}
            y1={730.64}
            x2={1836.17}
            y2={723.7}
          />
        </g>
        <g id='ARC_00000078037857502712396960000000856446646902380432_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1836.17,723.7c-0.01-0.29-0.13-0.57-0.34-0.78'
          />
        </g>
        <g id='LINE_00000148650908810675083610000015172609032610138276_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1835.82}
            y1={722.92}
            x2={1830.59}
            y2={717.92}
          />
        </g>
        <g id='ARC_00000036252777797431111000000000581018881022305442_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1830.59,717.92c-0.21-0.2-0.5-0.31-0.79-0.31'
          />
        </g>
        <g id='LINE_00000057131741804343437240000012290237591853587133_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1829.8}
            y1={717.62}
            x2={1822.37}
            y2={717.79}
          />
        </g>
        <g id='ARC_00000109722714611327933260000012970800860503580071_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1822.37,717.79c-0.29,0.01-0.57,0.13-0.78,0.34'
          />
        </g>
        <g id='LINE_00000042719086509830803700000017933151851773934999_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1821.59}
            y1={718.13}
            x2={1817.03}
            y2={722.91}
          />
        </g>
        <g id='ARC_00000040572341110769341350000007670326441398184321_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1817.03,722.91c-0.2,0.21-0.31,0.5-0.31,0.79'
          />
        </g>
        <g id='SPLINE_00000127039132497627709000000016263602545306944939_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.63,728.49c-0.3,0.01-0.6,0-0.89-0.01c-0.3-0.02-0.6-0.04-0.89-0.08c-0.3-0.04-0.59-0.09-0.88-0.16 c-0.29-0.07-0.58-0.14-0.86-0.24c-0.28-0.09-0.56-0.2-0.83-0.32c-0.27-0.12-0.53-0.26-0.79-0.43s-0.49-0.34-0.71-0.55 c-0.11-0.1-0.21-0.21-0.31-0.33c-0.1-0.11-0.18-0.23-0.26-0.36c-0.08-0.13-0.15-0.26-0.21-0.4c-0.03-0.07-0.06-0.14-0.08-0.21 s-0.04-0.14-0.06-0.21c-0.02-0.07-0.03-0.15-0.04-0.22c-0.01-0.07-0.01-0.15-0.02-0.22c0-0.08,0-0.15,0.01-0.22 c0.01-0.07,0.02-0.15,0.03-0.22s0.03-0.14,0.05-0.22s0.04-0.14,0.07-0.21c0.05-0.14,0.12-0.28,0.19-0.41 c0.07-0.13,0.16-0.25,0.25-0.37c0.09-0.12,0.19-0.23,0.29-0.34c0.21-0.21,0.44-0.41,0.68-0.58c0.24-0.17,0.5-0.33,0.77-0.46 c0.27-0.14,0.54-0.26,0.82-0.36c0.28-0.11,0.56-0.2,0.85-0.28s0.58-0.14,0.87-0.2c0.29-0.05,0.59-0.1,0.89-0.12 s0.6-0.05,0.89-0.06'
          />
        </g>
        <g id='SPLINE_00000118381264867158970140000012408026675563516850_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.45,720.71c0.3-0.01,0.6,0,0.9,0.01c0.3,0.02,0.6,0.04,0.89,0.08s0.59,0.09,0.88,0.16c0.29,0.07,0.58,0.14,0.86,0.24 c0.28,0.09,0.56,0.2,0.84,0.33c0.27,0.12,0.54,0.27,0.79,0.43c0.25,0.16,0.49,0.34,0.71,0.55c0.11,0.1,0.21,0.21,0.31,0.32 s0.18,0.23,0.26,0.36c0.08,0.13,0.15,0.26,0.21,0.4c0.03,0.07,0.06,0.14,0.08,0.21c0.02,0.07,0.04,0.14,0.06,0.21 c0.02,0.07,0.03,0.15,0.04,0.22c0.01,0.07,0.01,0.15,0.02,0.22c0,0.08,0,0.15-0.01,0.22s-0.02,0.15-0.03,0.22 c-0.01,0.07-0.03,0.14-0.05,0.22s-0.04,0.14-0.07,0.21c-0.05,0.14-0.12,0.28-0.19,0.41c-0.07,0.13-0.16,0.25-0.25,0.37 c-0.09,0.12-0.19,0.23-0.29,0.34c-0.21,0.21-0.44,0.41-0.68,0.58c-0.24,0.17-0.5,0.33-0.77,0.46s-0.54,0.26-0.82,0.36 s-0.56,0.2-0.85,0.28c-0.29,0.08-0.58,0.14-0.87,0.2c-0.29,0.05-0.59,0.1-0.89,0.12c-0.3,0.03-0.6,0.05-0.89,0.06'
          />
        </g>
        <g id='LWPOLYLINE_00000012462495465434648970000004669657586925819065_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1833.91,712.71 1833.93,712.04 1833.93,711.37 1833.91,710.7 1833.85,710.04 1833.77,709.37 1833.67,708.71 1833.53,708.05  1833.37,707.4 1833.19,706.76 1832.98,706.12 1832.74,705.5 1832.48,704.88 1832.19,704.27 1831.89,703.68 1831.55,703.1  1831.2,702.53 1830.82,701.98 1830.42,701.44 1830,700.92 1829.55,700.42 1829.09,699.93 1828.61,699.47 1828.11,699.02  1827.6,698.59 1827.47,698.49  '
          />
        </g>
        <g id='LWPOLYLINE_00000102505019825761840350000012378000305811312292_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1830.58,717.52 1830.86,717.3 1831.14,717.07 1831.4,716.83 1831.66,716.59 1831.9,716.33 1832.14,716.06 1832.36,715.78  1832.58,715.5 1832.78,715.2 1832.97,714.9 1833.14,714.59 1833.31,714.27 1833.46,713.95 1833.6,713.62 1833.73,713.29  1833.84,712.95 1833.91,712.71  '
          />
        </g>
        <g id='LWPOLYLINE_00000000905963776088124200000012391480169977786003_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1818.26,712.71 1818.36,713.05 1818.48,713.39 1818.61,713.72 1818.75,714.05 1818.91,714.37 1819.08,714.69 1819.26,714.99  1819.45,715.29 1819.66,715.58 1819.87,715.87 1820.1,716.14 1820.34,716.41 1820.59,716.66 1820.85,716.91 1821.12,717.14  1821.4,717.37 1821.68,717.58 1821.88,717.72  '
          />
        </g>
        <g id='LWPOLYLINE_00000150808968507809196720000010913566195139800736_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1824.7,698.49 1824.18,698.92 1823.67,699.36 1823.19,699.82 1822.72,700.3 1822.27,700.8 1821.85,701.32 1821.44,701.85  1821.06,702.4 1820.7,702.96 1820.36,703.54 1820.04,704.13 1819.75,704.74 1819.49,705.35 1819.24,705.98 1819.03,706.61  1818.84,707.25 1818.67,707.9 1818.53,708.56 1818.42,709.22 1818.33,709.88 1818.27,710.55 1818.24,711.22 1818.23,711.89  1818.25,712.56 1818.26,712.71  '
          />
        </g>
        <g id='LINE_00000171714656940172162180000017791768015607508890_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1825.23}
            y1={698.08}
            x2={1824.7}
            y2={698.49}
          />
        </g>
        <g id='LINE_00000171713428265958840250000000519367017514676390_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1827.47}
            y1={698.49}
            x2={1826.93}
            y2={698.08}
          />
        </g>
        <g id='LWPOLYLINE_00000169537431509536775160000018022269352570740871_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1825.74,714.8 1825.92,714.79 1826.09,714.78 1826.27,714.76 1826.42,714.73  '
          />
        </g>
        <g id='LWPOLYLINE_00000116933701111486810870000008889335739619678624_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1826.42,714.73 1826.6,714.75 1826.78,714.77 1826.96,714.77 1827.1,714.77  '
          />
        </g>
        <g id='LINE_00000091011485595453786190000010278770303690860431_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1821.82}
            y1={717.8}
            x2={1822.37}
            y2={717.79}
          />
        </g>
        <g id='LINE_00000098913633339907887450000009776876712124214943_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1829.8}
            y1={717.62}
            x2={1830.64}
            y2={717.6}
          />
        </g>
        <g id='LINE_00000145019006936188875630000015977366414648323257_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1830.64}
            y1={717.6}
            x2={1830.54}
            y2={717.47}
          />
        </g>
        <g id='ARC_00000090256497313010179260000011149094075657141662_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1830.54,717.47c1.61-1.17,2.77-2.85,3.31-4.77'
          />
        </g>
        <g id='ARC_00000135663422847387815600000007398510248856194741_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1833.85,712.7c0.33-5.49-2.07-10.79-6.41-14.16'
          />
        </g>
        <g id='LINE_00000081648821585710033330000001662024615646394526_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1827.44}
            y1={698.54}
            x2={1826.77}
            y2={698.02}
          />
        </g>
        <g id='ARC_00000163074571742085199060000012911676629944519582_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.77,698.02c-0.45-0.13-0.92-0.13-1.37,0'
          />
        </g>
        <g id='LINE_00000083791802089273754120000004573019047379725980_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1825.4}
            y1={698.02}
            x2={1824.73}
            y2={698.54}
          />
        </g>
        <g id='ARC_00000010269203756671507030000004450782351925146519_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1824.73,698.54c-4.34,3.38-6.74,8.67-6.41,14.16'
          />
        </g>
        <g id='ARC_00000098210372452935682900000017143512261967270794_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1818.32,712.7c0.57,2.03,1.84,3.79,3.6,4.97'
          />
        </g>
        <g id='LINE_00000176007579945456879930000014362865337996317111_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1821.91}
            y1={717.67}
            x2={1821.82}
            y2={717.8}
          />
        </g>
        <g id='ARC_00000076588045031551435180000000867831289392583080_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1821.15,710.55c0.13,2.48,2.25,4.38,4.73,4.24c0.18-0.01,0.36-0.03,0.54-0.06'
          />
        </g>
        <g id='ARC_00000121246540110127616270000000497997112062997933_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1825.35,700.85c-2.85,2.39-4.41,5.99-4.2,9.7'
          />
        </g>
        <g id='ARC_00000142864578682739997880000009664621271475840416_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.09,700.52c-0.28,0.01-0.55,0.12-0.74,0.33'
          />
        </g>
        <g id='ARC_00000013898034448563925350000004196132817507153546_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.84,700.81c-0.2-0.19-0.47-0.3-0.75-0.29'
          />
        </g>
        <g id='ARC_00000114772414989229723710000013469149952829087145_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1831.49,710.31c0.03-3.72-1.69-7.24-4.65-9.5'
          />
        </g>
        <g id='ARC_00000066491121103212946360000018363607185112554387_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.42,714.73c2.46,0.32,4.72-1.41,5.04-3.87c0.02-0.18,0.04-0.36,0.04-0.55'
          />
        </g>
        <g id='SPLINE_00000174569948350619051230000013241086003033238182_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.77,698.02c0.07,0.02,0.11,0.04,0.14,0.05'
          />
        </g>
        <g id='SPLINE_00000130632096681659844580000009473405749404761477_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.81,698.03c-0.01,0-0.01,0-0.02-0.01c-0.01,0-0.01,0-0.02-0.01c-0.02,0-0.03-0.01-0.05-0.01c-0.02,0-0.04-0.01-0.05-0.01 s-0.04-0.01-0.06-0.01'
          />
        </g>
        <g id='SPLINE_00000153705671617063676420000010431471216308135596_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.57,697.97c-0.01,0-0.01,0-0.02,0c-0.02,0-0.04-0.01-0.06-0.01'
          />
        </g>
        <g id='SPLINE_00000067195446954934420470000016059358318239512210_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.36,697.93c-0.09-0.01-0.18-0.02-0.28-0.02'
          />
        </g>
        <g id='SPLINE_00000123411057139518181250000004231458728520154038_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.08,697.92c-0.1,0-0.19,0.01-0.28,0.02'
          />
        </g>
        <g id='SPLINE_00000063609724723213286440000011036546948399985062_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1825.68,697.95c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.06,0.01 c-0.02,0-0.04,0.01-0.05,0.01c-0.02,0-0.03,0.01-0.05,0.01c-0.01,0-0.02,0-0.02,0.01s-0.01,0-0.02,0.01'
          />
        </g>
        <g id='SPLINE_00000084522593164243432930000002472556539520162447_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1825.26,698.07c0.03-0.01,0.07-0.03,0.14-0.05'
          />
        </g>
        <g id='SPLINE_00000096738455076607927970000009791791744965079693_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1833.85,712.7c0.04,0.01,0.06,0.01,0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000181773681838169217920000014790587301144106927_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1818.32,712.7c-0.04,0.01-0.06,0.01-0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000138547993900135798040000011817416811014625462_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.81,709.36c-0.11,0.12-0.25,0.22-0.39,0.29c-0.15,0.07-0.31,0.12-0.47,0.13c-0.08,0.01-0.16,0.01-0.24,0 c-0.08-0.01-0.16-0.02-0.24-0.04c-0.04-0.01-0.08-0.02-0.12-0.03s-0.08-0.03-0.11-0.04c-0.04-0.02-0.07-0.03-0.11-0.05 s-0.07-0.04-0.1-0.06c-0.03-0.02-0.07-0.05-0.1-0.07s-0.06-0.05-0.09-0.08c-0.03-0.03-0.06-0.06-0.08-0.09 c-0.03-0.03-0.05-0.06-0.07-0.09c-0.02-0.03-0.05-0.07-0.07-0.1c-0.02-0.03-0.04-0.07-0.06-0.1c-0.02-0.04-0.03-0.07-0.05-0.11 c-0.01-0.04-0.03-0.07-0.04-0.11c-0.01-0.04-0.02-0.08-0.03-0.12c-0.01-0.04-0.01-0.08-0.02-0.12c-0.01-0.08-0.01-0.16-0.01-0.24 c0.01-0.16,0.05-0.32,0.11-0.47c0.06-0.15,0.16-0.29,0.27-0.41'
          />
        </g>
        <g id='SPLINE_00000157995676219340925640000010299286745518434954_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1824.8,707.44c0.11-0.12,0.25-0.22,0.39-0.29c0.15-0.07,0.3-0.12,0.46-0.14s0.32-0.01,0.48,0.03c0.16,0.04,0.31,0.11,0.44,0.2 c0.13,0.09,0.25,0.2,0.35,0.34c0.1,0.13,0.17,0.28,0.21,0.43c0.04,0.16,0.06,0.32,0.05,0.48c-0.01,0.08-0.02,0.16-0.04,0.24 s-0.05,0.15-0.08,0.23c-0.06,0.15-0.16,0.28-0.27,0.4'
          />
        </g>
        <g id='LINE_00000109001564447614764760000008757856373154645660_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1826.84}
            y1={700.81}
            x2={1825.35}
            y2={700.85}
          />
        </g>
        <g id='LWPOLYLINE_00000165220272773287099330000010019036012469416576_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1826.74,730.52 1826.74,730.52 1826.73,730.52 1826.73,730.52 1826.72,730.52 1826.72,730.52 1826.71,730.52 1826.71,730.52  1826.7,730.52 1826.7,730.52 1826.7,730.51 1826.69,730.51 1826.69,730.51 1826.68,730.51 1826.68,730.51 1826.68,730.5  1826.67,730.5 1826.67,730.5 1826.66,730.5 1826.66,730.49 1826.66,730.49 1826.65,730.49 1826.65,730.48 1826.65,730.48  1826.65,730.48 1826.64,730.47 1826.64,730.47 1826.64,730.46 1826.64,730.46 1826.63,730.46 1826.63,730.45 1826.63,730.45  1826.63,730.44 1826.63,730.44 1826.63,730.43 1826.63,730.43 1826.63,730.43 1826.62,730.42 1826.62,730.42 1826.62,730.41  1826.62,730.41  '
          />
        </g>
        <g id='LWPOLYLINE_00000069371054425985094570000008636048206844160423_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1826.61,729.77 1826.61,729.81 1826.61,729.85 1826.61,729.89 1826.61,729.93 1826.61,729.97 1826.61,730.01 1826.62,730.04  1826.62,730.08 1826.62,730.11 1826.62,730.14 1826.62,730.17 1826.62,730.2 1826.62,730.23 1826.62,730.25 1826.62,730.28  1826.62,730.3 1826.62,730.32 1826.62,730.33 1826.62,730.35 1826.62,730.37 1826.62,730.38 1826.62,730.39 1826.62,730.4  1826.62,730.4 1826.62,730.41 1826.62,730.41 1826.62,730.41  '
          />
        </g>
        <g id='LINE_00000025430084028305905090000009050980789991839655_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1826.62}
            y1={730.41}
            x2={1826.62}
            y2={730.41}
          />
        </g>
        <g id='LWPOLYLINE_00000011740207313885240410000012318932918590916007_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1826.85,730.41 1826.85,730.41 1826.85,730.42 1826.85,730.42 1826.85,730.42 1826.85,730.43 1826.85,730.43 1826.85,730.44  1826.85,730.44 1826.85,730.45 1826.84,730.45 1826.84,730.45 1826.84,730.46 1826.84,730.46 1826.84,730.47 1826.83,730.47  1826.83,730.47 1826.83,730.48 1826.83,730.48 1826.82,730.49 1826.82,730.49 1826.82,730.49 1826.81,730.49 1826.81,730.5  1826.81,730.5 1826.8,730.5 1826.8,730.51 1826.79,730.51 1826.79,730.51 1826.79,730.51 1826.78,730.51 1826.78,730.52  1826.77,730.52 1826.77,730.52 1826.76,730.52 1826.76,730.52 1826.76,730.52 1826.75,730.52 1826.75,730.52 1826.74,730.52  1826.74,730.52  '
          />
        </g>
        <g id='LINE_00000073722781802336384600000014408752547305582222_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1826.85}
            y1={730.41}
            x2={1826.85}
            y2={730.41}
          />
        </g>
        <g id='LWPOLYLINE_00000088116333073642526130000017449339854803397267_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1826.85,730.41 1826.85,730.4 1826.85,730.4 1826.85,730.4 1826.85,730.39 1826.85,730.38 1826.85,730.37 1826.85,730.36  1826.85,730.34 1826.85,730.33 1826.85,730.31 1826.85,730.29 1826.85,730.27 1826.85,730.24 1826.85,730.22 1826.85,730.19  1826.85,730.16 1826.85,730.13 1826.84,730.1 1826.84,730.07 1826.84,730.03 1826.84,730 1826.84,729.96 1826.84,729.92  1826.84,729.88 1826.84,729.84 1826.84,729.8 1826.84,729.76  '
          />
        </g>
        <g id='LWPOLYLINE_00000005245233435937649520000009911383546072017583_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1826.58,728.66 1826.58,728.7 1826.59,728.74 1826.59,728.78 1826.59,728.83 1826.59,728.87 1826.59,728.92 1826.59,728.96  1826.59,729.01 1826.59,729.05 1826.59,729.1 1826.59,729.15 1826.6,729.19 1826.6,729.24 1826.6,729.29 1826.6,729.33  1826.6,729.38 1826.6,729.43 1826.6,729.47 1826.6,729.52 1826.6,729.56 1826.61,729.61 1826.61,729.65 1826.61,729.69  1826.61,729.73 1826.61,729.77  '
          />
        </g>
        <g id='LWPOLYLINE_00000141422021785890740300000012086179238237241263_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1826.84,729.76 1826.84,729.72 1826.83,729.68 1826.83,729.64 1826.83,729.59 1826.83,729.55 1826.83,729.5 1826.83,729.46  1826.83,729.41 1826.83,729.37 1826.83,729.32 1826.83,729.27 1826.82,729.23 1826.82,729.18 1826.82,729.13 1826.82,729.09  1826.82,729.04 1826.82,728.99 1826.82,728.95 1826.82,728.9 1826.82,728.86 1826.81,728.81 1826.81,728.77 1826.81,728.73  1826.81,728.69 1826.81,728.65  '
          />
        </g>
        <g id='ELLIPSE_00000082326964455151477950000011213892584678685842_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.64,730.47c0.02,0.03,0.06,0.06,0.1,0.05'
          />
        </g>
        <g id='ELLIPSE_00000023269074416689493670000002817317113582030977_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.66,730.33c-0.01,0.01-0.01,0.02-0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000118383290545743752200000000725148929638903451_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.74,730.52c0.04,0,0.08-0.02,0.1-0.06'
          />
        </g>
        <g id='ELLIPSE_00000083776202667958463620000009274404248517646015_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.8,728.62c-0.02-0.02-0.06-0.03-0.1-0.02'
          />
        </g>
        <g id='ELLIPSE_00000140700537991874916830000009289885700287727509_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.81,728.67c0.01-0.01,0-0.02,0-0.03'
          />
        </g>
        <g id='ELLIPSE_00000158000250030673832140000000941804094838442139_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.7,728.71c0.02,0,0.04,0,0.06-0.01'
          />
        </g>
        <g id='ELLIPSE_00000145033557715922985720000017144226292674965888_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.64,728.7c0.02,0,0.04,0.01,0.06,0.01'
          />
        </g>
        <g id='ELLIPSE_00000010281584778299198670000017545207867054848190_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.59,728.64c-0.01,0.01,0,0.02,0,0.03'
          />
        </g>
        <g id='ELLIPSE_00000081613979350350256740000017212025789253882275_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1826.7,728.6c-0.04,0-0.08,0.01-0.1,0.03'
          />
        </g>
        <g id='LINE_00000047771544698443693860000014732825210525577616_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1836.35}
            y1={731.52}
            x2={1836.33}
            y2={730.64}
          />
        </g>
        <g id='LINE_00000082347329977327758800000009300891348458053773_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1836.33}
            y1={730.64}
            x2={1816.88}
            y2={730.64}
          />
        </g>
        <g id='LINE_00000052799813181716007890000013000528869754783918_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1816.88}
            y1={730.64}
            x2={1816.9}
            y2={731.52}
          />
        </g>
        <g id='SPLINE_00000169523891227292586130000016100548161034271163_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1866.55,719.06c0.29-0.01,0.59,0,0.88,0.02c0.29,0.02,0.58,0.06,0.87,0.11c0.29,0.05,0.57,0.12,0.85,0.2 c0.28,0.08,0.56,0.18,0.83,0.29c0.27,0.11,0.53,0.24,0.79,0.39c0.25,0.15,0.5,0.31,0.73,0.49c0.23,0.18,0.45,0.38,0.65,0.59 c0.2,0.21,0.38,0.44,0.54,0.69c0.16,0.25,0.29,0.51,0.4,0.78c0.1,0.27,0.18,0.56,0.22,0.85s0.05,0.58,0.02,0.88 c-0.03,0.29-0.09,0.58-0.18,0.86c-0.09,0.28-0.21,0.55-0.36,0.8s-0.32,0.49-0.51,0.72c-0.19,0.22-0.4,0.43-0.62,0.62 s-0.46,0.36-0.71,0.52c-0.25,0.16-0.5,0.3-0.77,0.42c-0.26,0.13-0.54,0.24-0.81,0.33c-0.28,0.09-0.56,0.17-0.84,0.24 c-0.29,0.06-0.57,0.12-0.86,0.15c-0.26,0.03-0.52,0.05-0.77,0.06'
          />
        </g>
        <g id='SPLINE_00000145763197734654715550000008062070309384867469_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1866.68,729.06c-0.26,0-0.52,0-0.78-0.02c-0.29-0.02-0.58-0.06-0.87-0.11c-0.29-0.05-0.57-0.12-0.85-0.2 c-0.28-0.08-0.56-0.18-0.83-0.29c-0.27-0.11-0.53-0.24-0.79-0.39c-0.25-0.15-0.5-0.31-0.73-0.49c-0.23-0.18-0.45-0.38-0.65-0.59 s-0.38-0.44-0.54-0.69c-0.16-0.25-0.29-0.51-0.4-0.78c-0.1-0.27-0.18-0.56-0.22-0.85'
          />
        </g>
        <g id='SPLINE_00000181053123580685477710000018183083943245519015_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1860.03,724.65c-0.04-0.29-0.05-0.58-0.02-0.88s0.09-0.58,0.18-0.86s0.21-0.55,0.36-0.8c0.15-0.25,0.32-0.49,0.51-0.71 s0.4-0.43,0.62-0.62c0.22-0.19,0.46-0.36,0.71-0.52s0.5-0.3,0.77-0.42c0.26-0.13,0.54-0.24,0.81-0.33 c0.28-0.09,0.56-0.17,0.84-0.24s0.57-0.12,0.86-0.15s0.58-0.06,0.88-0.06'
          />
        </g>
        <g id='ARC_00000036962374470215301610000004873596928388521365_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1896.84,714.6c-0.02-1.06-0.89-1.9-1.95-1.9'
          />
        </g>
        <g id='LINE_00000020359875684977347700000016536681835644543656_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1894.9}
            y1={712.7}
            x2={1862.54}
            y2={712.7}
          />
        </g>
        <g id='ARC_00000004510100739029291090000010484295741031242894_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1862.54,712.7c-2.39,0.06-4.56,1.38-5.7,3.48'
          />
        </g>
        <g id='LINE_00000144300780574303181690000005103381159224644790_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1856.83}
            y1={716.18}
            x2={1857.17}
            y2={730.63}
          />
        </g>
        <g id='LINE_00000103234636685414029000000018209874189365189025_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1897.21}
            y1={730.63}
            x2={1896.84}
            y2={714.6}
          />
        </g>
        <g id='LWPOLYLINE_00000039109033570047598100000001302224858688194727_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1866.7,730.05 1866.7,730.09 1866.7,730.13 1866.7,730.18 1866.7,730.21 1866.7,730.25 1866.7,730.29 1866.7,730.33  1866.71,730.36 1866.71,730.39 1866.71,730.42 1866.71,730.45 1866.71,730.48 1866.71,730.51 1866.71,730.53 1866.71,730.56  1866.71,730.58 1866.71,730.6 1866.71,730.62 1866.71,730.63  '
          />
        </g>
        <g id='LWPOLYLINE_00000160172986684017348370000012169717024738239657_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1866.94,730.64 1866.94,730.63 1866.94,730.61 1866.94,730.59 1866.94,730.57 1866.94,730.55 1866.94,730.53 1866.94,730.5  1866.94,730.47 1866.94,730.44 1866.93,730.41 1866.93,730.38 1866.93,730.35 1866.93,730.32 1866.93,730.28 1866.93,730.24  1866.93,730.2 1866.93,730.16 1866.93,730.12 1866.93,730.08 1866.93,730.05  '
          />
        </g>
        <g id='LWPOLYLINE_00000151529358970592681590000014422279051433699483_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1866.67,728.94 1866.67,728.98 1866.67,729.02 1866.68,729.07 1866.68,729.11 1866.68,729.15 1866.68,729.2 1866.68,729.24  1866.68,729.29 1866.68,729.34 1866.68,729.38 1866.68,729.43 1866.68,729.48 1866.69,729.52 1866.69,729.57 1866.69,729.62  1866.69,729.66 1866.69,729.71 1866.69,729.75 1866.69,729.8 1866.69,729.84 1866.69,729.89 1866.7,729.93 1866.7,729.97  1866.7,730.02 1866.7,730.05  '
          />
        </g>
        <g id='LWPOLYLINE_00000112626700374118694160000016234511758189442231_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1866.93,730.05 1866.93,730 1866.92,729.96 1866.92,729.92 1866.92,729.87 1866.92,729.83 1866.92,729.79 1866.92,729.74  1866.92,729.69 1866.92,729.65 1866.92,729.6 1866.91,729.56 1866.91,729.51 1866.91,729.46 1866.91,729.42 1866.91,729.37  1866.91,729.32 1866.91,729.28 1866.91,729.23 1866.91,729.19 1866.91,729.14 1866.9,729.1 1866.9,729.05 1866.9,729.01  1866.9,728.97 1866.9,728.93  '
          />
        </g>
        <g id='ELLIPSE_00000138543991556295492360000008222344359464231300_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1866.74,730.61c-0.01,0.01-0.01,0.02-0.02,0.02'
          />
        </g>
        <g id='ELLIPSE_00000059291676033566121390000016600151866228257713_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1866.73,728.98c0.02,0,0.04,0.01,0.06,0.01'
          />
        </g>
        <g id='ELLIPSE_00000166672336279598399830000002705641344813047228_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1866.68,728.92c0,0.01,0,0.02,0,0.03'
          />
        </g>
        <g id='ELLIPSE_00000116949990215440096500000009530338328543550337_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1866.79,728.88c-0.04,0-0.08,0.01-0.1,0.03'
          />
        </g>
        <g id='ELLIPSE_00000159459600173859973700000007712191919458770578_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1866.89,728.96c0.03-0.03,0.01-0.06-0.04-0.07c-0.02,0-0.04-0.01-0.06-0.01'
          />
        </g>
        <g id='ELLIPSE_00000036964079946270449270000012481216306375350669_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1866.79,728.99c0.01,0,0.02,0,0.03,0'
          />
        </g>
        <g id='LINE_00000116934938732587590210000011035777483479294620_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1897.23}
            y1={731.52}
            x2={1897.21}
            y2={730.63}
          />
        </g>
        <g id='LINE_00000139980595677703477740000015821193578904768945_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1897.21}
            y1={730.63}
            x2={1857.17}
            y2={730.63}
          />
        </g>
        <g id='LINE_00000054258526191574227180000015644387467805115550_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1857.17}
            y1={730.63}
            x2={1857.19}
            y2={731.52}
          />
        </g>
        <g id='LINE_00000023278729163332117730000005721202945125904027_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1857.19}
            y1={731.52}
            x2={1897.23}
            y2={731.52}
          />
        </g>
        <g id='LWPOLYLINE_00000147206683481295502600000004544598891289242524_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1894.58,703.26 1894.6,702.59 1894.6,701.92 1894.58,701.25 1894.52,700.58 1894.44,699.92 1894.34,699.26 1894.2,698.6  1894.04,697.95 1893.86,697.31 1893.65,696.67 1893.41,696.04 1893.15,695.43 1892.86,694.82 1892.55,694.23 1892.22,693.65  1891.87,693.08 1891.49,692.53 1891.09,691.99 1890.67,691.47 1890.22,690.96 1889.76,690.48 1889.28,690.01 1888.78,689.57  1888.27,689.14 1888.14,689.04  '
          />
        </g>
        <g id='LWPOLYLINE_00000163780830231413044000000004524016442124445325_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1891.25,708.06 1891.53,707.85 1891.8,707.62 1892.07,707.38 1892.33,707.13 1892.57,706.87 1892.81,706.61 1893.03,706.33  1893.25,706.04 1893.45,705.75 1893.64,705.45 1893.81,705.14 1893.98,704.82 1894.13,704.5 1894.27,704.17 1894.4,703.84  1894.51,703.5 1894.58,703.26  '
          />
        </g>
        <g id='LWPOLYLINE_00000165195180339466270310000007241580542014264472_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1878.93,703.26 1879.03,703.6 1879.15,703.94 1879.28,704.27 1879.42,704.6 1879.58,704.92 1879.75,705.23 1879.93,705.54  1880.12,705.84 1880.33,706.13 1880.54,706.42 1880.77,706.69 1881.01,706.96 1881.26,707.21 1881.52,707.46 1881.79,707.69  1882.06,707.91 1882.35,708.13 1882.55,708.26  '
          />
        </g>
        <g id='LWPOLYLINE_00000057113527716869563970000010002063676695378312_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1885.37,689.04 1884.85,689.46 1884.34,689.91 1883.86,690.37 1883.39,690.85 1882.94,691.35 1882.52,691.86 1882.11,692.4  1881.73,692.95 1881.37,693.51 1881.03,694.09 1880.71,694.68 1880.42,695.28 1880.16,695.9 1879.91,696.52 1879.7,697.16  1879.51,697.8 1879.34,698.45 1879.2,699.1 1879.09,699.76 1879,700.43 1878.94,701.09 1878.91,701.76 1878.9,702.43  1878.92,703.1 1878.93,703.26  '
          />
        </g>
        <g id='LINE_00000051342713559377331550000014468594019797149846_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1885.9}
            y1={688.62}
            x2={1885.37}
            y2={689.04}
          />
        </g>
        <g id='LINE_00000175311464295011886130000003165292516778548611_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1888.14}
            y1={689.04}
            x2={1887.6}
            y2={688.62}
          />
        </g>
        <g id='LWPOLYLINE_00000018226386597986388040000015756119171137576612_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1886.41,705.35 1886.58,705.34 1886.76,705.33 1886.94,705.3 1887.09,705.28  '
          />
        </g>
        <g id='LWPOLYLINE_00000129904709799162889240000005700985068759708578_'>
          <polyline
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            points=' 1887.09,705.28 1887.27,705.3 1887.45,705.31 1887.63,705.32 1887.77,705.32  '
          />
        </g>
        <g id='ARC_00000047739150941725168050000006761759928334088617_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1894.52,703.25c0.33-5.49-2.07-10.79-6.41-14.16'
          />
        </g>
        <g id='LINE_00000015325264581468614320000002293981134858378911_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1888.11}
            y1={689.09}
            x2={1887.44}
            y2={688.57}
          />
        </g>
        <g id='ARC_00000172422536590038001110000012442908115678021034_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1887.44,688.57c-0.45-0.13-0.92-0.13-1.37,0'
          />
        </g>
        <g id='LINE_00000028326194693402238690000009120542401978954122_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1886.07}
            y1={688.57}
            x2={1885.4}
            y2={689.09}
          />
        </g>
        <g id='ARC_00000057851382729889766870000017376164000168389554_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1885.4,689.09c-4.34,3.38-6.74,8.67-6.41,14.16'
          />
        </g>
        <g id='ARC_00000077319683261782959960000005962919312185939349_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1878.99,703.25c0.57,2.03,1.84,3.79,3.6,4.97'
          />
        </g>
        <g id='LINE_00000108268179737707253620000006795622578431429007_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1882.58}
            y1={708.22}
            x2={1882.55}
            y2={708.26}
          />
        </g>
        <g id='LINE_00000031184772698508981620000001441680807603335577_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1882.55}
            y1={708.26}
            x2={1882.49}
            y2={708.26}
          />
        </g>
        <g id='LINE_00000170990681752608013830000003809617632717391288_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1882.49}
            y1={708.26}
            x2={1882.59}
            y2={712.7}
          />
        </g>
        <g id='LINE_00000062879495223402340790000010675053951578917049_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1891.35}
            y1={712.7}
            x2={1891.25}
            y2={708.14}
          />
        </g>
        <g id='LINE_00000008847409791168042170000017637458688837835159_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1891.25}
            y1={708.14}
            x2={1891.3}
            y2={708.14}
          />
        </g>
        <g id='LINE_00000171720630052438565020000013214116344115155381_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1891.3}
            y1={708.14}
            x2={1891.21}
            y2={708.02}
          />
        </g>
        <g id='ARC_00000051374357329279715780000017687435104709944997_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1891.21,708.02c1.61-1.17,2.77-2.85,3.31-4.77'
          />
        </g>
        <g id='ARC_00000062914506515468997550000004628101662627916209_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1881.81,701.1c0.13,2.48,2.25,4.38,4.73,4.24c0.18-0.01,0.36-0.03,0.54-0.06'
          />
        </g>
        <g id='ARC_00000046339745672299694290000008648363493871955386_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1886.02,691.4c-2.85,2.39-4.41,5.99-4.2,9.7'
          />
        </g>
        <g id='ARC_00000081644431394587429330000006353909759226753930_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1886.76,691.07c-0.28,0.01-0.55,0.12-0.74,0.33'
          />
        </g>
        <g id='ARC_00000182510707352591517190000004645858737540909220_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1887.51,691.36c-0.2-0.19-0.47-0.3-0.75-0.29'
          />
        </g>
        <g id='ARC_00000179611695625015172660000012407197455233383085_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1892.16,700.86c0.03-3.72-1.69-7.24-4.65-9.5'
          />
        </g>
        <g id='ARC_00000157301191951635844610000001108996999782341567_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1887.09,705.28c2.46,0.32,4.72-1.41,5.04-3.87c0.02-0.18,0.04-0.36,0.04-0.55'
          />
        </g>
        <g id='SPLINE_00000129199927135177239140000005172820533090641056_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1887.44,688.57c0.07,0.02,0.11,0.04,0.14,0.05'
          />
        </g>
        <g id='SPLINE_00000129887690474725168860000017302661875204174240_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1887.48,688.58c-0.01,0-0.01,0-0.02-0.01c-0.01,0-0.01,0-0.02-0.01c-0.02,0-0.03-0.01-0.05-0.01c-0.02,0-0.04-0.01-0.05-0.01 c-0.02,0-0.04-0.01-0.06-0.01s-0.04-0.01-0.06-0.01c-0.02,0-0.04-0.01-0.06-0.01'
          />
        </g>
        <g id='SPLINE_00000150071385190150634430000017276471784808158377_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1887.03,688.48c-0.08-0.01-0.16-0.01-0.24-0.02'
          />
        </g>
        <g id='SPLINE_00000023262421063958069440000012619402500710469538_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1886.72,688.47c-0.08,0-0.16,0.01-0.24,0.02'
          />
        </g>
        <g id='SPLINE_00000065046618539111169720000003965983309946232462_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1886.35,688.5c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.06,0.01c-0.02,0-0.04,0.01-0.06,0.01s-0.04,0.01-0.05,0.01 c-0.02,0-0.03,0.01-0.05,0.01c-0.01,0-0.02,0-0.02,0.01c-0.01,0-0.01,0-0.02,0.01'
          />
        </g>
        <g id='SPLINE_00000159437231265698140650000004837695342057420451_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1885.93,688.61c0.03-0.01,0.07-0.03,0.14-0.05'
          />
        </g>
        <g id='SPLINE_00000162326886900066329430000012141977054580439229_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1894.52,703.25c0.04,0.01,0.06,0.01,0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000086664032724928920430000002118000783145768091_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1878.99,703.25c-0.04,0.01-0.06,0.01-0.06,0.01'
          />
        </g>
        <g id='SPLINE_00000130621210840522379820000008729127396117006520_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1887.48,699.9c-0.11,0.12-0.25,0.22-0.39,0.29s-0.31,0.12-0.47,0.13c-0.08,0.01-0.16,0.01-0.24,0c-0.08-0.01-0.16-0.02-0.24-0.04 c-0.04-0.01-0.08-0.02-0.12-0.03s-0.08-0.03-0.11-0.04c-0.04-0.02-0.07-0.03-0.11-0.05c-0.03-0.02-0.07-0.04-0.1-0.06 c-0.03-0.02-0.07-0.05-0.1-0.07s-0.06-0.05-0.09-0.08c-0.03-0.03-0.06-0.06-0.08-0.09s-0.05-0.06-0.07-0.09s-0.05-0.07-0.07-0.1 s-0.04-0.07-0.06-0.1c-0.02-0.04-0.03-0.07-0.05-0.11s-0.03-0.07-0.04-0.11c-0.01-0.04-0.02-0.08-0.03-0.12s-0.01-0.08-0.02-0.12 c-0.01-0.08-0.01-0.16-0.01-0.24c0.01-0.16,0.05-0.32,0.11-0.47c0.07-0.15,0.16-0.29,0.27-0.41'
          />
        </g>
        <g id='SPLINE_00000163038569594305123060000014824926717725696682_'>
          <path
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            d=' M1885.47,697.98c0.11-0.12,0.25-0.22,0.39-0.29c0.15-0.07,0.3-0.12,0.46-0.14c0.16-0.02,0.32-0.01,0.48,0.03 c0.16,0.04,0.31,0.11,0.44,0.2c0.13,0.09,0.25,0.2,0.35,0.34c0.1,0.13,0.17,0.28,0.21,0.43s0.06,0.32,0.05,0.48 c-0.01,0.08-0.02,0.16-0.04,0.24c-0.02,0.08-0.05,0.15-0.08,0.23c-0.06,0.15-0.16,0.28-0.27,0.4'
          />
        </g>
        <g id='LINE_00000114037413344742169310000005873924407176489907_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1887.51}
            y1={691.36}
            x2={1886.02}
            y2={691.4}
          />
        </g>
        <g id='LINE_00000056420598264432569890000000427541957428749244_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1882.14}
            y1={731.52}
            x2={1897.23}
            y2={731.52}
          />
        </g>
        <g id='LINE_00000018232206766754926570000017632493072757276602_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1757.52}
            y1={682.59}
            x2={1797.55}
            y2={682.59}
          />
        </g>
        <g id='LINE_00000182519635653316581400000005845541314322059966_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1757.52}
            y1={690.37}
            x2={1797.55}
            y2={690.37}
          />
        </g>
        <g id='LINE_00000064337149201881336100000012928703946556227758_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1412.42}
            y1={729.44}
            x2={1452.45}
            y2={729.44}
          />
        </g>
        <g id='LINE_00000115500184276035129050000004380974874861531051_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1412.42}
            y1={737.22}
            x2={1452.45}
            y2={737.22}
          />
        </g>
        <g id='LINE_00000121997260692197716140000016885121270689369244_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1452.88}
            y1={497.78}
            x2={1411.73}
            y2={497.78}
          />
        </g>
        <g id='LINE_00000044164234724720378060000013922391529223681430_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1452.88}
            y1={505.56}
            x2={1411.73}
            y2={505.56}
          />
        </g>
        <g id='LINE_00000155824601328468780930000015398555046708806582_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={999.07}
            y1={522.92}
            x2={959.04}
            y2={522.92}
          />
        </g>
        <g id='LINE_00000002360941809623310260000007931890860026887587_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={999.07}
            y1={518.47}
            x2={959.04}
            y2={518.47}
          />
        </g>
        <g id='LINE_00000048467116245446200780000008935387125049283203_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1147.88}
            y1={297.49}
            x2={1189.03}
            y2={297.49}
          />
        </g>
        <g id='LINE_00000060718707498216474210000006268235723882722965_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={1147.88}
            y1={305.28}
            x2={1189.03}
            y2={305.28}
          />
        </g>
        <rect
          x={1398.58}
          y={827.46}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={14.4}
          height={13.07}
        />
        <rect
          x={1399.56}
          y={658.05}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={16.68}
          height={8.9}
        />
        <rect
          x={1405.19}
          y={666.95}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={7.78}
          height={162.73}
        />
        <rect
          x={1412.98}
          y={775.17}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={48.93}
          height={18.91}
        />
        <rect
          x={1461.91}
          y={782.95}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={15.06}
          height={11.12}
        />
        <rect
          x={1452.45}
          y={666.95}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={9.45}
          height={108.22}
        />
        <rect
          x={1449.61}
          y={658.05}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={15.91}
          height={8.9}
        />
        <rect
          x={1494.76}
          y={782.95}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={10.29}
        />
        <rect
          x={1521.45}
          y={782.95}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={11.12}
        />
        <rect
          x={1548.14}
          y={782.95}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={24.77}
          height={10.7}
        />
        <rect
          x={1572.91}
          y={781.84}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={70.38}
          height={12.23}
        />
        <rect
          x={1572.91}
          y={658.07}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={4.45}
          height={123.68}
        />
        <rect
          x={1549.51}
          y={658.05}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={24.39}
          height={8.9}
        />
        <rect
          x={1555.37}
          y={666.95}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={4.45}
          height={116.54}
        />
        <rect
          x={1577.36}
          y={682.45}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={77.74}
          height={8.66}
        />
        <rect
          x={1646.89}
          y={663.61}
          stroke='#000000'
          strokeWidth={0.2551}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          width={8.9}
          height={19.28}
        />
        <polygon
          stroke='#000000'
          strokeWidth={0.1}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          points=' 1521.93,666.95 1555.37,700.66 1555.71,694.81 1526.3,665.52 1521.69,666.95  '
        />
      </g>
      <g id='A-DOOR-FRAM-MCUT'>
        <g id='LINE_00000101791644944222491790000000875024173866634381_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={947.36}
            y1={619.44}
            x2={947.36}
            y2={625.56}
          />
        </g>
        <g id='LINE_00000151532652226552328360000006097833932323353984_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={947.36}
            y1={625.56}
            x2={960.71}
            y2={625.56}
          />
        </g>
        <g id='LINE_00000101792501145846238920000009473398599260447644_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={960.71}
            y1={625.56}
            x2={960.71}
            y2={619.44}
          />
        </g>
        <g id='LINE_00000120545062088395071120000013048509775299421098_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={960.71}
            y1={619.44}
            x2={950.7}
            y2={619.44}
          />
        </g>
        <g id='LINE_00000129893608095504384290000016563829437957229226_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={950.7}
            y1={619.44}
            x2={950.7}
            y2={618.74}
          />
        </g>
        <g id='LINE_00000167395338809536559200000009259906370366924987_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={950.7}
            y1={618.74}
            x2={948.47}
            y2={618.74}
          />
        </g>
        <g id='LINE_00000076570643622741621040000018116115028672121525_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={948.47}
            y1={618.74}
            x2={948.47}
            y2={619.44}
          />
        </g>
        <g id='LINE_00000047031930447523134210000002992374310578776999_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={948.47}
            y1={619.44}
            x2={947.36}
            y2={619.44}
          />
        </g>
        <g id='LINE_00000100366096471143491920000017573199290993006473_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={947.36}
            y1={583.85}
            x2={948.47}
            y2={583.85}
          />
        </g>
        <g id='LINE_00000016780176460677501760000010444705178078832565_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={948.47}
            y1={583.85}
            x2={948.47}
            y2={584.55}
          />
        </g>
        <g id='LINE_00000036211656873341354000000004089793474168792251_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={948.47}
            y1={584.55}
            x2={950.7}
            y2={584.55}
          />
        </g>
        <g id='LINE_00000016772889533653099200000000968970861915767951_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={950.7}
            y1={584.55}
            x2={950.7}
            y2={583.85}
          />
        </g>
        <g id='LINE_00000080196690148846422630000011624695357614637701_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={950.7}
            y1={583.85}
            x2={960.71}
            y2={583.85}
          />
        </g>
        <g id='LINE_00000137133454138036258060000001062418072958229135_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={960.71}
            y1={583.85}
            x2={960.71}
            y2={581.63}
          />
        </g>
        <g id='LINE_00000152226132431123326940000001534085355997303999_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={960.71}
            y1={581.63}
            x2={947.36}
            y2={581.63}
          />
        </g>
        <g id='LINE_00000080925310128425409770000014238455043394005156_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.5669}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={947.36}
            y1={581.63}
            x2={947.36}
            y2={583.85}
          />
        </g>
      </g>
      <g id='S-STRS-_x5F__x5F__x5F__x5F_-OTLN'>
        <g id='LINE_00000023975872554794539250000014295682435359949229_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={465.1}
            y1={325.69}
            x2={465.1}
            y2={328.05}
          />
        </g>
        <g id='LINE_00000183956386594589869390000014563424429102274728_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={465.1}
            y1={328.05}
            x2={420.72}
            y2={328.05}
          />
        </g>
        <g id='LINE_00000034806269281106568150000013370124589626142644_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={423.95}
            y1={312.76}
            x2={463.99}
            y2={312.76}
          />
        </g>
        <g id='LINE_00000034085484356352594100000013791579401499659654_'>
          <line
            fill='none'
            stroke='#000000'
            strokeWidth={0.2551}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            x1={420.72}
            y1={315.82}
            x2={465.1}
            y2={315.82}
          />
        </g>
        <g>
          <path d='M67,432.04v-1.33h4.97v1.34c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.25-0.05-0.35-0.15 s-0.15-0.21-0.15-0.35v-0.36h-1v6.3c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14c-0.14,0-0.26-0.05-0.36-0.14 s-0.15-0.21-0.15-0.35v-6.3h-1v0.35c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14s-0.25-0.05-0.35-0.14S67,432.18,67,432.04z' />
          <path d='M74.28,437.99v-7.29h3.48c0.4,0,0.75,0.15,1.04,0.44s0.44,0.64,0.44,1.05v0.56c0,0.41-0.14,0.76-0.43,1.05 s-0.64,0.44-1.04,0.44h-0.63l2.01,3.46c0.06,0.1,0.09,0.2,0.09,0.3c0,0.13-0.05,0.25-0.15,0.34s-0.22,0.14-0.35,0.14 c-0.2,0-0.34-0.09-0.45-0.26l-2.3-3.99h-0.71v3.76c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14s-0.25-0.05-0.35-0.14 S74.28,438.13,74.28,437.99z M75.26,433.25h2.5c0.14,0,0.25-0.05,0.35-0.14s0.14-0.21,0.14-0.35v-0.57c0-0.14-0.05-0.25-0.14-0.35 s-0.21-0.14-0.35-0.14h-2.5V433.25z' />
          <path d='M81.99,430.7h3.98c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.25-0.15,0.35s-0.21,0.15-0.35,0.15 h-1.49v5.81h1.49c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35s-0.05,0.25-0.15,0.35s-0.21,0.14-0.35,0.14h-3.98 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.51v-5.81h-1.51 c-0.14,0-0.25-0.05-0.35-0.15s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.36S81.86,430.7,81.99,430.7z' />
          <path d='M88.79,437.8l1.98-6.74c0.08-0.24,0.24-0.37,0.5-0.37c0.24,0,0.4,0.1,0.46,0.31l1.96,6.84c0.02,0.05,0.02,0.1,0.02,0.15 c0,0.14-0.05,0.25-0.14,0.35s-0.2,0.14-0.32,0.14c-0.26,0-0.42-0.12-0.49-0.36l-0.39-1.34h-2.25l-0.43,1.43 c-0.01,0.06-0.06,0.12-0.15,0.18c-0.09,0.06-0.18,0.08-0.28,0.08c-0.15,0-0.27-0.05-0.36-0.15c-0.09-0.1-0.14-0.22-0.14-0.36 C88.76,437.92,88.77,437.86,88.79,437.8z M91.26,432.98l-0.84,2.82h1.67L91.26,432.98z' />
          <path d='M98.86,430.7h1.63c0.14,0,0.25,0.05,0.35,0.14s0.14,0.21,0.14,0.35c0,0.14-0.05,0.26-0.16,0.36 c-0.1,0.1-0.22,0.14-0.34,0.14h-1.56c-0.15,0-0.29,0.07-0.4,0.21l-1.38,1.78c-0.12,0.14-0.18,0.34-0.17,0.59V437 c0,0.14,0.05,0.26,0.14,0.36s0.21,0.15,0.35,0.15h2c0.13,0,0.25-0.05,0.35-0.15s0.15-0.21,0.15-0.35v-1.07h-1.02 c-0.14,0-0.26-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h2v2.05c0,0.41-0.14,0.75-0.43,1.04 s-0.64,0.43-1.04,0.43h-2c-0.41,0-0.76-0.14-1.05-0.43s-0.44-0.64-0.44-1.04v-2.72c0-0.49,0.13-0.9,0.39-1.24l1.45-1.85 c0.11-0.14,0.25-0.26,0.44-0.36S98.66,430.7,98.86,430.7z' />
          <path d='M107.72,431.69h-3.48v2.4h1.5c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.14,0.36 s-0.21,0.15-0.35,0.15h-1.5v2.41h3.49c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35s-0.05,0.25-0.15,0.35s-0.22,0.14-0.35,0.14 h-4.46v-7.79h4.46c0.14,0,0.25,0.05,0.35,0.15s0.15,0.22,0.15,0.35c0,0.13-0.05,0.25-0.15,0.35S107.86,431.69,107.72,431.69z' />
          <path d='M76.24,449.9v1.21c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.15-0.35,0.15s-0.25-0.05-0.35-0.15s-0.14-0.22-0.14-0.35v-1.21 h-0.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15h0.49v-1.12h-0.49 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35c0-0.14,0.05-0.26,0.14-0.35s0.21-0.14,0.35-0.14h0.5v-1.21 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14c0.13,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.98v-1.21 c0-0.14,0.05-0.25,0.15-0.35s0.21-0.14,0.35-0.14c0.14,0,0.25,0.05,0.35,0.15s0.15,0.21,0.15,0.35v1.21h0.5 c0.13,0,0.25,0.05,0.35,0.14s0.15,0.21,0.15,0.35c0,0.14-0.05,0.26-0.16,0.36s-0.22,0.15-0.33,0.15h-0.49v1.11h0.49 c0.13,0,0.24,0.05,0.34,0.15s0.15,0.21,0.15,0.35c0,0.13-0.05,0.24-0.14,0.34s-0.21,0.15-0.35,0.15h-0.5v1.21 c0,0.14-0.05,0.25-0.15,0.35s-0.22,0.15-0.35,0.15c-0.13,0-0.25-0.05-0.35-0.15s-0.15-0.22-0.15-0.35v-1.21H76.24z M76.24,447.8 v1.11h0.98v-1.11H76.24z' />
          <path d='M81.97,451.89h1.51v-5.81h-1.49c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35 s0.21-0.14,0.35-0.14h2.47v6.79h0.98v-2.05c0-0.13,0.05-0.25,0.14-0.35s0.21-0.15,0.35-0.15s0.25,0.05,0.35,0.14 s0.14,0.21,0.14,0.35v2.54c0,0.14-0.05,0.25-0.14,0.35s-0.21,0.14-0.35,0.14h-3.97c-0.14,0-0.25-0.05-0.35-0.15 s-0.14-0.22-0.14-0.35c0-0.13,0.05-0.25,0.14-0.34S81.83,451.89,81.97,451.89z' />
          <path d='M88.74,451.92v-5.89c0-0.26,0.09-0.48,0.28-0.67s0.41-0.28,0.67-0.28h3.07c0.25,0,0.47,0.09,0.66,0.28 s0.28,0.41,0.28,0.67v5.89c0,0.26-0.09,0.49-0.28,0.67s-0.41,0.28-0.67,0.28h-3.06c-0.27,0-0.49-0.09-0.67-0.28 S88.74,452.19,88.74,451.92z M92.72,451.89v-5.81h-3v5.81H92.72z' />
          <path d='M100.97,446.08v1.85c0,0.43-0.13,0.78-0.4,1.04c0.27,0.3,0.41,0.64,0.41,1v1.92c0,0.27-0.1,0.5-0.29,0.69 s-0.43,0.29-0.7,0.29H96.5c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.47v-1.92 c0-0.14-0.05-0.25-0.14-0.35s-0.21-0.15-0.35-0.15H97.5c-0.14,0-0.25-0.05-0.35-0.14S97,449.11,97,448.97 c0-0.14,0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h1.99c0.13,0,0.25-0.05,0.35-0.14s0.15-0.21,0.15-0.35v-1.9h-3.52 c-0.14,0-0.25-0.05-0.35-0.14s-0.14-0.21-0.14-0.35s0.05-0.25,0.14-0.35s0.21-0.14,0.35-0.14h3.52c0.27,0,0.51,0.1,0.7,0.29 S100.97,445.81,100.97,446.08z' />
        </g>
      </g>
    </svg>
  )
}
export default FultonNorthSvg
