import {createAsyncThunk} from '@reduxjs/toolkit'
import {request} from '../../../helpers/request'
import {ApiPaginatedResponse} from '../api.types'
import {
  ParticipantByIdResponse,
  CellTrackerQueryParams,
  HeartrateQueryParams,
  HeartRateResponse,
  ParticipantQueryParams,
  ParticipantEditBody,
  ParticipantCreateBody,
  LocationTimeBody,
  ParticipantDeleteResponse,
  LocationTimeListResponse,
  AllocatedCelltimeDurationResponse,
  CellTimeDurationResponse,
  LocationTimeQuery,
  LocationTimeCreateResponse,
  ParticipantType,
} from './participant.types'

//get participant by ID

export const _getParticipantById = createAsyncThunk('participant/_id', async (id: number) => {
  const response: ParticipantByIdResponse = await request({
    url: `v2/participant/${id}`,
    method: 'get',
  })
  return response
})

export const _getParticipants = createAsyncThunk(
  'participant/_list',
  async (params: ParticipantQueryParams) => {
    const response: ApiPaginatedResponse<ParticipantType> = await request({
      url: `v2/participant/paginated`,
      method: 'post',
      data: {page: 1, limit: 1000, ...params},
    })
    return response
  }
)

export const _createParticipant = createAsyncThunk(
  'participant/_create',
  async (payload: ParticipantCreateBody) => {
    const response: ParticipantByIdResponse = await request({
      url: 'v2/participant',
      data: payload,
      method: 'post',
    })
    return response
  }
)

export const _editParticipant = createAsyncThunk(
  'participant/_edit',
  async (payload: ParticipantEditBody) => {
    const id = payload.participantId
    const response: ParticipantByIdResponse = await request({
      url: `v2/participant/${id}`,
      data: payload,
      method: 'patch',
    })
    return response
  }
)

export const _deleteParticipant = createAsyncThunk('participant/_delete', async (id: number) => {
  const response: ParticipantDeleteResponse = await request({
    url: `v2/participant/${id}`,
    method: 'delete',
  })
  return response
})

/**** Cell Time Api's *****/

export const _createParticipantLocation = createAsyncThunk(
  'participant/_saveCellTracker',
  async (data: LocationTimeBody) => {
    const response = await request({
      url: 'v2/participant-location',
      data,
      method: 'post',
    })
    return response
  }
)

export const _getActualCellDurations = createAsyncThunk(
  'participant/_getCellDurations',
  async (params: CellTrackerQueryParams) => {
    let id = params.participantId
    const response: CellTimeDurationResponse = await request({
      url: `v2/participant-location/duration/${id}`,
      method: 'get',
      params: {
        participant_id: params.participantId,
        startTime: params.startTime,
        endTime: params.endTime,
      },
    })
    return response
  }
)

export const _getAllocatedCellOutDuration = createAsyncThunk(
  'participant/_getAllocatedCellOutDurations',
  async (params: CellTrackerQueryParams) => {
    let id = params.cellId
    const response: AllocatedCelltimeDurationResponse = await request({
      url: `v2/cell-history/${id}/duration`,
      method: 'get',
      params: {
        cellId: params.cellId,
        startTime: params.startTime,
        endTime: params.endTime,
      },
    })
    return response
  }
)

/************ Heartrate  ****************/

export const _getHeartRate = createAsyncThunk(
  'participant/_heartrate',
  async (params: HeartrateQueryParams) => {
    const response: HeartRateResponse = await request({
      url: `v2/inmate-alert/heartrate/${params.participantId}`,
      method: 'get',
      params: {
        participantId: params.participantId,
        startTime: params.startTime,
        endTime: params.endTime,
        limit: params.limit ? params.limit : 20,
      },
    })
    return response
  }
)

/************ Location  ****************/

export const _getCellDoorHistory = createAsyncThunk(
  'participant/_getCellDoorHistory',
  async ({participantId, cellId, startTime, endTime}: LocationTimeQuery) => {
    const response: LocationTimeListResponse = await request({
      url: `v2/participant-location/location-with-doors/${participantId}`,
      method: 'get',
      params: {
        cellId,
        startTime,
        endTime,
      },
    })
    return response
  }
)

export const _saveLocationTime = createAsyncThunk(
  'participant/_saveLocationTime',
  async ({participantId, location, entryTime, exitTime}: LocationTimeBody) => {
    const response: LocationTimeCreateResponse = await request({
      url: `v2/participant-location`,
      method: 'post',
      data: {
        participantId,
        entryTime,
        location,
        exitTime,
      },
    })
    return response
  }
)
