import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  _getAlerts,
  _getAlertById,
  _getUnreadCount,
  _updateAlerts,
  _getAlertsByParticipantId,
} from './alerts.actions'
import {Alert} from './alerts.types'
import {addAPICases} from '../api.helpers'
import {
  ApiPaginationRequestState,
  apiRequestPaginationStateDefault,
  ApiRequestState,
  apiRequestStateDefault,
} from '../api.types'

type AlertsState = {
  list: ApiPaginationRequestState<Alert[]>
  participant: ApiPaginationRequestState<Alert[]>
  selected: ApiRequestState<Alert>
  updated: ApiRequestState<Alert>
  unread: ApiRequestState<{count: number}>
  unreadSocket: number
}

const initialState: AlertsState = {
  list: {...apiRequestPaginationStateDefault},
  participant: {...apiRequestPaginationStateDefault},
  unread: {...apiRequestStateDefault},
  unreadSocket: 0,
  selected: {...apiRequestStateDefault},
  updated: {...apiRequestStateDefault},
}

const alerts = createSlice({
  name: 'alerts',
  initialState,
  extraReducers: (builder) => {
    addAPICases(builder, _getAlerts, 'list')
    addAPICases(builder, _getAlertsByParticipantId, 'participant')
    addAPICases(builder, _getAlertById, 'selected')
    addAPICases(builder, _updateAlerts, 'updated')
    addAPICases(builder, _getUnreadCount, 'unread')
  },
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.unreadSocket = action.payload
    },
    addAlert: (state, action: PayloadAction<Alert>) => {
      state.list.data.results?.push(action.payload)
    },
    updateAlert: (state, action: PayloadAction<Alert>) => {
      state.list.data.results
        ?.filter((a) => a.id === action.payload.id)
        .map((v) => (v = action.payload))
    },
    setUnreadCount: (state, action: PayloadAction<{count: number}>) => {
      state.unread.data = action.payload
    },
  },
})

export const alertReducer = alerts.reducer
export const alertActions = alerts.actions
