/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, useCallback, useMemo, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../state/store'
import {TransformComponent, TransformWrapper, ReactZoomPanPinchRef} from 'react-zoom-pan-pinch'
import ThreeN100Top from '../../live-track/assets/Rice-3-North/ThreeN100Top'
import ThreeN100Bot from '../../live-track/assets/Rice-3-North/ThreeN100Bot'
import FultonNorthSVG from '../../live-track/assets/FultonNorthSVG'
import {useIntl} from 'react-intl'

type Props = {
  className: string
}

const Map: React.FC<Props> = ({className}) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)
  const scaleUp = true
  const zoomFactor = 29
  const isLoading = false

  const intl = useIntl()

  const {facilityName} = useSelector((state: RootState) => state.facility.facility)

  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  const [containerWidth, setContainerWidth] = useState<number>(0)
  const [containerHeight, setContainerHeight] = useState<number>(0)
  const [svgWidth] = useState<number>(200) // works with 200 only - need dynamic width
  const [svgHeight] = useState<number>(200)
  const [svgBackgroundWhite, setSvgBackgroundWhite] = useState<boolean>(false)
  const [levelSwitch, setLevelSwitch] = useState<boolean>(false)

  const svgScale = useMemo(() => {
    if (containerWidth === 0 || containerHeight === 0 || svgWidth === 0 || svgHeight === 0) return 0
    const scale = Math.min(containerWidth / svgWidth, containerHeight / svgHeight)
    return scaleUp ? scale : Math.max(scale, 1)
  }, [scaleUp, containerWidth, containerHeight, svgWidth, svgHeight])

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect()
      setContainerWidth(rect.width)
      setContainerHeight(rect.height)
    } else {
      setContainerWidth(0)
      setContainerHeight(0)
    }
  }, [container])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <>
      {isLoading ? (
        <div> Loading... </div>
      ) : (
        <div className={`card ${className}`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              {/* <span className='card-label font-24 mb-1'>MAP OF FACILITY</span> */}
              <span className='card-label font-24 mb-1'>
                {intl.formatMessage({id: 'MENU.MAP'})}
              </span>
            </h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body overflow-auto py-3'>
            {/* begin::Table container */}
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
              ref={(el: HTMLDivElement | null) => setContainer(el)}
            >
              {
                <TransformWrapper
                  key={`${containerWidth}x${containerHeight}`}
                  initialScale={svgScale}
                  minScale={svgScale}
                  maxScale={svgScale * zoomFactor}
                  centerOnInit
                  ref={transformComponentRef}
                  wheel={{step: 0.8}}
                >
                  <TransformComponent
                    wrapperStyle={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: svgBackgroundWhite ? 'white' : '#141414',
                    }}
                  >
                    {/* ID's have changed in staging an prod. Using string instead */}
                    {facilityName === 'Fulton Co. Main Jail' && levelSwitch ? (
                      <ThreeN100Top backgroundColor={svgBackgroundWhite} />
                    ) : facilityName === 'Fulton Co. Main Jail' && !levelSwitch ? (
                      <ThreeN100Bot backgroundColor={svgBackgroundWhite} />
                    ) : (
                      <FultonNorthSVG backgroundColor={svgBackgroundWhite} showLabels />
                    )}

                    {/* <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 100,
                      }}
                    >
                      {precisionMode === PrecisionMode.TRIANGULATION && (
                        <InmateMarkerLayer
                          setSelected={setSelectedInmateId}
                          showRisk={showRisk}
                          showPods={showPods}
                          setAreaOccupancy={setAreaOccupancy}
                          participants={participants}
                          svgBackgroundWhite={svgBackgroundWhite}
                          showImei={showImei}
                          updatedParticipants={updatedParticipants}
                          setUpdatedParticipants={setUpdatedParticipants}
                          transformComponentRef={transformComponentRef}
                          showGhosts={showGhosts}
                        />
                      )}
                    </div> */}
                  </TransformComponent>
                </TransformWrapper>
              }
              {/* {precisionMode === PrecisionMode.PROXIMITY && (
                <InmateListLayer
                  transformComponentRef={transformComponentRef}
                  setSelectedInmate={setSelectedInmateId}
                  lightMode={svgBackgroundWhite}
                  showPods={showPods}
                  showRisk={showRisk}
                />
              )} */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      )}
    </>
  )
}

export default Map

// export default {}
