import {createSlice} from '@reduxjs/toolkit'
import {
  _updateDevice,
  _getDeviceList,
  _getDeviceWithHistory,
  _getInmateDeviceHistory,
} from './device.actions'
import {DeviceHistoryWithDeviceType, DeviceType} from './device.types'
import {addAPICases} from '../api.helpers'
import {
  ApiPaginationRequestState,
  ApiRequestState,
  apiRequestPaginationStateDefault,
  apiRequestStateDefault,
} from '../api.types'

type DeviceStateType = {
  list: ApiPaginationRequestState<DeviceType[]>
  selected: ApiRequestState<DeviceType>
  inmateHistory: ApiPaginationRequestState<DeviceHistoryWithDeviceType[]>
  updated: ApiRequestState<DeviceType>
}

const initialState: DeviceStateType = {
  list: {...apiRequestPaginationStateDefault},
  selected: {...apiRequestStateDefault},
  inmateHistory: {...apiRequestStateDefault},
  updated: {...apiRequestStateDefault},
}

export const devices = createSlice({
  name: 'devices',
  initialState: initialState,
  extraReducers: (builder) => {
    addAPICases(builder, _getDeviceList, 'list')
    addAPICases(builder, _getDeviceWithHistory, 'selected')
    addAPICases(builder, _getInmateDeviceHistory, 'inmateHistory')
    addAPICases(builder, _updateDevice, 'updated')
  },
  reducers: {},
})

export default devices.reducer
