export const capitalizeFirstLetters = (str: string) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase())

export const getType = (type: string | undefined) => {
  if (type !== null) {
    type = type?.toLowerCase()
    switch (type) {
      case 'low_battery_level':
        return 'Low Battery Alert'
      case 'low_heart_rate':
        return 'Low Heart Rate Alert'
      case 'high_heart_rate':
        return 'High Heart Rate Alert'
      case 'band_tamper':
        return 'Band Tamper Alert'
      case 'heart_rate_offline':
        return 'Heart Rate offline Alert'
      case 'band_offline':
        return 'Band Offline Alert'
      case 'out_of_cell_time_compliancy_warning':
        return 'Out of Cell Time Alert'
      default:
        return type
    }
  }
}

// Function to generate a random color
// export const getRandomColor = (light: boolean = true) => {
//   const randomBrightness = () => Math.floor(Math.random() * 155) + 100 // Generate brightness between 100 and 255
//   const randomHex = () =>
//     Math.floor(Math.random() * 256)
//       .toString(16)
//       .padStart(2, '0') // Generate a random 2-digit hexadecimal value

//   const brightnessThreshold = light ? 100 : 160 // Adjust threshold based on the background color

//   let color
//   let luminance
//   do {
//     const red = randomBrightness()
//     const green = randomBrightness()
//     const blue = randomBrightness()
//     color = `#${randomHex()}${randomHex()}${randomHex()}` // Generate a random color

//     luminance = (red * 0.299 + green * 0.587 + blue * 0.114) / 255 // Calculate the luminance of the color

//     // Check if the luminance is above or below the brightness threshold based on the background color
//   } while (
//     (light && luminance > brightnessThreshold / 255) ||
//     (!light && luminance < brightnessThreshold / 255)
//   )

//   return color
// }

export const generateColor = (light: boolean = false) => {
  let color = ''

  for (let i = 0; i < 3; i++) {
    // Generate a random value between 0 and 255.
    let randomValue

    if (light) {
      // For a light background, we want a dark color, so limit the value to the range 0-127.
      randomValue = Math.floor(Math.random() * 128)
    } else {
      // For a dark background, we want a light color, so start the range at 128 and go up to 255.
      randomValue = Math.floor(Math.random() * 128) + 128
    }

    // Convert the value to a hexadecimal string and add it to the color.
    let hexValue = randomValue.toString(16)
    color += hexValue.length === 1 ? '0' + hexValue : hexValue
  }

  // Return the color with a '#' in front to make it a valid color string.
  return '#' + color
}

/**
 * Check if the contents of two arrays are the same. does not consider the order of the elements.
 */
export const matchArrays = (arrayA:any[], arrayB:any[])=>{
if(arrayA.length !== arrayB.length) return false
if (new Set([...arrayA, ...arrayB]).size !== arrayA.length) return false
return true



}