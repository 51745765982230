// /* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UNKNOWN_LOCATION} from '../../../../helpers/config'
import {RootState} from '../../../../state/store'
import {getRiskString} from '../../../../helpers/api-data'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  filter: {
    location?: string
    risk?: number
  }
}

const TotalInmates: React.FC<Props> = ({className, filter}) => {
  const {list: participants, isLoading} = useSelector((state: RootState) => state.participant)

  const intl = useIntl()

  const filteredParticipants = useMemo(() => {
    if (!filter) return participants
    let finalFilter = [...participants] || []
    if (filter.risk !== undefined) {
      finalFilter = finalFilter.filter(({risk}) => risk === filter.risk)
    }
    if (filter.location !== undefined) {
      finalFilter = finalFilter.filter((participant) => {
        const locationCheck = participant.participantLocationTime?.location || 'UNKNOWN LOCATION'
        return locationCheck.toLowerCase() === filter.location?.toLowerCase()
      })
    }

    return finalFilter
  }, [participants, filter])

  const tableLabel = useMemo(() => {
    let label = ''
    if (filter.risk !== undefined) {
      label = getRiskString(filter.risk)?.toUpperCase() + ' RISK'
    }
    if (filter.location !== undefined) label = filter.location + label
    return label
  }, [filter])

  return (
    <>
      {isLoading ? (
        <div> Loading... </div>
      ) : (
        <div className={`card ${className}`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label font-16 mb-1'>
                {' '}
                {tableLabel} {intl.formatMessage({id: 'DASHBOARD.INMATES'})}{' '}
                {`(${filteredParticipants.length})`}
              </span>
            </h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body overflow-auto py-3'>
            {/* begin::Table container */}
            <div className='table-responsive text-capitalize overflow-auto'>
              {/* begin::Table */}
              <table className='table-row-dashed table-row-gray-300 gs-0 gy-4 table align-middle'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px text-gray-700'>Name</th>
                    <th className='min-w-120px text-gray-700'>Pod - Cell</th>
                    <th className='min-w-120px text-gray-700'>Location</th>
                    <th className='min-w-120px text-gray-700'>Risk</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {participants &&
                    participants?.length > 0 &&
                    filteredParticipants.map((participant) => {
                      return (
                        <tr key={participant.id}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <Link
                                  to={`/profile/${participant.id}/overview`}
                                  state={{id: participant.id, cellId: participant.cellId}}
                                  className='text-light-white text-hover-primary fs-6 text-capitalize text-break'
                                >
                                  {participant.firstName} {participant.lastName}
                                </Link>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack'>
                                <span className='text-light-white fs-7 fw-semibold me-2'>
                                  {participant.cell.pod.podName} - {participant.cell.cellNumber}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack'>
                                <span className='text-light-white fs-7 fw-semibold me-2'>
                                  {participant.participantLocationTime?.location
                                    ? participant.participantLocationTime?.location?.toLowerCase()
                                    : UNKNOWN_LOCATION.toLowerCase()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack'>
                                <span className='text-light-white fs-7 fw-semibold text-capitalize me-2'>
                                  {getRiskString(participant.risk)}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      )}
    </>
  )
}

export default TotalInmates
