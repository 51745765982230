import {createAsyncThunk} from '@reduxjs/toolkit'
import {request} from '../../../helpers/request'
import {
  CellQuery,
  CellType,
  CellsResponse,
  FacilityResponse,
  LocationCoordsResponse,
  LocationCountResponse,
  PodType,
  PodsQuery,
  PodsResponse,
} from './facility.types'

/*--------------------- Facility ------------------------*/
export const _getFacilities = createAsyncThunk('facility/_list', async () => {
  const response: FacilityResponse = await request({url: 'v2/facility', method: 'get'})
  return response
})

/*--------------------- Pods ------------------------*/
export const _getPods = createAsyncThunk('pod/_list', async (params: PodsQuery = {limit: 200}) => {
  const response: PodsResponse = await request({url: 'v2/pod', method: 'get', params})
  return response
})
export const getPods = _getPods as any

export const createPod = async (data: Partial<Omit<PodType, 'id'>>) => {
  const response: PodType = await request({url: 'v2/pod', method: 'post', data})
  return response
}

export const updatePod = async (podId: number, data: Partial<PodType>) => {
  const response: PodType = await request({url: `v2/pod/${podId}`, method: 'patch', data})
  return response
}

/*--------------------- Cells ------------------------*/
export const _getCells = createAsyncThunk(
  'cell/_list',
  async (params: CellQuery = {limit: 200}) => {
    const response: CellsResponse = await request({url: 'v2/cell', method: 'get', params})
    return response
  }
)
export const getCells = _getCells as any

export const createCell = async (data: Partial<Omit<CellType, 'id'>>) => {
  const response: CellType = await request({url: 'v2/cell', method: 'post', data})
  return response
}

export const updateCell = async (cellId: number, data: Partial<CellType>) => {
  const response: CellType = await request({url: `v2/cell/${cellId}`, method: 'patch', data})
  return response
}

export const _saveCell = createAsyncThunk('_saveCell', async (payload: any) => {
  const {id, isEdit, ...data} = payload
  const response = await request({
    url: isEdit ? `v2/cell/${id}` : 'v2/cell',
    data: data,
    method: isEdit ? 'put' : 'post',
  })
  return response
})

/*--------------------- Locations ------------------------*/

export const _getLocations = createAsyncThunk('locations/', async () => {
  const response: LocationCoordsResponse = await request({
    url: `v2/location-coordinates`,
    method: 'get',
    params: {limit: 200},
  })
  return response
})

export const _getLocationCount = createAsyncThunk('locations/counts', async () => {
  const response: LocationCountResponse = await request({
    url: `v2/participant-location/count`,
    method: 'get',
  })
  return response
})
