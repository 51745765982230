import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import {orderBy} from 'lodash'
import {useEffect, useState, MouseEvent} from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {AppDispatch, RootState} from '../../../state/store'
import {setFacility} from '../../../state/V2/facility/facility.reducers'
import {_getPods} from '../../../state/V2/facility/facility.actions'
import {_saveUserPods} from '../../../state/V2/supervisor/supervisor.actions'
import {UserPodType} from '../../../state/V2/supervisor/supervisor.types'
import {isApiError} from '../../../state/V2/api.helpers'
import {useAuth} from '../../modules/auth'

interface IPodSelection {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const PodSelection = ({isShown, setIsShown}: IPodSelection) => {
  const dispatch = useDispatch<AppDispatch>()
  const [errorText, setErrorText] = useState('')
  const [selectedPods, setSelectedPods] = useState<number[]>([])
  const [allPodsSelected, setAllPodsSelected] = useState<boolean>(false)
  const [currentFacility, setCurrentFacility] = useState<number>()

  const facilities = useSelector((state: RootState) => state.facility.list)
  const pods = useSelector((state: RootState) => state.pods.facilityPods?.results)
  const podAssignment = useSelector((state: RootState) => state.supervisor.podAssignment)
  const {currentUser} = useAuth()

  useEffect(() => {
    if (!podAssignment) return
    if (!currentFacility && podAssignment.facilityId) {
      setCurrentFacility(podAssignment.facilityId)
      const facility = facilities.find((facility) => facility.id === podAssignment.facilityId)
      dispatch(
        setFacility({
          id: podAssignment.facilityId,
          facilityName: facility?.facilityName,
        })
      )
    }
    setSelectedPods([...podAssignment.podIds])
  }, [facilities, dispatch, podAssignment, currentFacility])

  const handlePodChange = (event: SelectChangeEvent<number[]>) => {
    if (allPodsSelected) {
      setAllPodsSelected(false)
    }
    if (typeof event.target.value !== 'string') setSelectedPods(event.target.value)
  }

  const handleSelectAllPods = () => {
    if (pods) setSelectedPods(pods.map((pod) => pod.id))
  }

  const handleUnselectAllPods = () => {
    setSelectedPods([])
  }

  const handleClose = () => {
    setIsShown(false)
  }

  const onSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const data: Partial<UserPodType> = {
      podIds: selectedPods,
      facilityId: currentFacility || 0,
    }

    const facility = facilities.find((facility) => facility.id === currentFacility)
    if (facility) {
      dispatch(setFacility({id: currentFacility, facilityName: facility.facilityName}))
    }

    const response = await dispatch(_saveUserPods(data))
    if (isApiError(response)) {
      setErrorText('Failed to save Pod Selection.')
      return
    }
    handleClose()
    if (podAssignment?.facilityId !== currentFacility) window.location.href = '/dashboard'
  }

  const handleFacilityChange = async (e: SelectChangeEvent<number>) => {
    setCurrentFacility(e.target.value as number)
    await dispatch(_getPods({facilityId: e.target.value as number}))
    setSelectedPods([])
  }

  const isSuperuser = currentUser?.isSuper

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {background: '#1f1f1f', backgroundImage: 'unset'},
      }}
      open={isShown}
      onClose={handleClose}
      fullWidth={true}
    >
      <DialogTitle>Pod Selection</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          sx={{'& .MuiTextField-root': {m: 1, width: '32.5ch'}}}
          noValidate
          autoComplete='off'
        >
          {isSuperuser ? (
            <FormControl error={errorText ? true : false} style={{width: '100%'}} className='my-4'>
              <InputLabel> Select Facility</InputLabel>
              <Select<number>
                labelId='facility-label'
                id='facility'
                variant='standard'
                label='Select Facility'
                input={<OutlinedInput label='Select Facility' />}
                MenuProps={MenuProps}
                onChange={handleFacilityChange}
                value={currentFacility || ''}
              >
                {facilities
                  ? orderBy(facilities, ['facilityName'], ['asc']).map((data, i) => (
                      <MenuItem key={i} value={data.id}>
                        <ListItemText primary={data.facilityName} />
                      </MenuItem>
                    ))
                  : null}
              </Select>
              <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
          ) : null}
          <FormControl error={errorText ? true : false} style={{width: '100%'}} className='my-4'>
            <InputLabel> Select Pods</InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              variant='standard'
              multiple
              value={selectedPods}
              onChange={handlePodChange}
              input={<OutlinedInput label='Select Pod' />}
              renderValue={(selected) =>
                pods
                  ?.filter((pod) => selected.includes(pod.id))
                  .map((pod) => pod.podName)
                  .join(', ')
              }
              MenuProps={MenuProps}
              disabled={!currentFacility && isSuperuser}
            >
              {pods
                ? orderBy(pods, ['podName'], ['asc']).map((data, i) => (
                    <MenuItem key={i} value={data.id}>
                      <Checkbox checked={selectedPods.includes(data.id)} />
                      <ListItemText primary={data.podName} />
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText>{errorText}</FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions className='justify-content-between flex p-7'>
        {pods?.length === selectedPods.length ? (
          <Button variant='outlined' onClick={handleUnselectAllPods}>
            Unselect All
          </Button>
        ) : (
          <Button variant='outlined' onClick={handleSelectAllPods}>
            Select All
          </Button>
        )}

        <div>
          <Button onClick={handleClose} className='mr-2'>
            Cancel
          </Button>
          <Button
            variant='contained'
            disabled={selectedPods.length === 0}
            className='ml-10 block'
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}
