import {combineReducers} from 'redux'
import {userReducer} from './user/user.reducers'
import devicesReducer from './device/device.reducers'
import {participantReducer} from './participant/participant.reducer'
import {
  facilityReducer,
  podsReducer,
  cellsReducer,
  locationsReducer,
} from './facility/facility.reducers'
import supervisorsReducers from './supervisor/supervisors.reducers'
import {alertReducer} from './alerts/alerts.reducers'
import {systemReducer} from './system/system.reducers'

const reducers = combineReducers({
  user: userReducer,
  participant: participantReducer,
  pods: podsReducer,
  devices: devicesReducer,
  locations: locationsReducer,
  supervisor: supervisorsReducers,
  cells: cellsReducer,
  alerts: alertReducer,
  facility: facilityReducer,
  system: systemReducer,
})

const rootReducer = (state: any, action: {type: string}) => {
  if (action.type === 'user/_logout/fulfilled') {
    return reducers(undefined, action)
  }

  return reducers(state, action)
}

export default rootReducer
