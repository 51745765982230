// actions.ts
import {createAsyncThunk} from '@reduxjs/toolkit'
import {request} from '../../../helpers/request'
import {Alert, AlertPaginationRequestBody, AlertResponse, CountResponse} from './alerts.types'
import {ApiResponse} from '../api.types'

/** GET ALERTS LIST **/
export const _getAlerts = createAsyncThunk(
  'alerts/list',
  async (data: AlertPaginationRequestBody) => {
    const response: AlertResponse = await request({
      url: 'v2/alert/paginated',
      method: 'post',
      data,
    })
    return response
  }
)

/** GET ALERTS LIST **/
export const _getAlertsByParticipantId = createAsyncThunk(
  'alerts/selectedParticipant',
  async (data: AlertPaginationRequestBody & {participantId: number}) => {
    const response: AlertResponse = await request({
      url: `v2/alert/paginated`,
      method: 'post',
      data,
    })
    return response
  }
)

/** ALERT DETAIL BY ID **/
export const _getAlertById = createAsyncThunk('alerts/selected', async (id: any) => {
  const response: ApiResponse<Alert> = await request({
    url: `v2/alert/${id}`,
    method: 'get',
  })
  return response
})

/** GET UNREAD ALERTS COUNT **/
export const _getUnreadCount = createAsyncThunk('alerts/unread', async () => {
  const response: CountResponse = await request({url: 'v2/alert/count', method: 'get'})
  return response
})

/** UPDATE ALERTS **/
export const _updateAlerts = createAsyncThunk('alerts/updated', async (data: Partial<Alert>) => {
  const response = await request({url: `v2/alert/${data.id}`, data, method: 'patch'})
  return response
})
