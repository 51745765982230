// actions.ts
import {createAsyncThunk} from '@reduxjs/toolkit'
import {request} from '../../../helpers/request'
import {
  DeviceListResponse,
  DeviceUpdateParams,
  DeviceHistoryResponse,
  DeviceResponse,
  DeviceCreateResponse,
  DevicePaginationRequestBody,
  DeviceUpdate,
} from './device.types'

/** FULL LIST OF DEVICES **/
export const _getDeviceList = createAsyncThunk(
  '_getDevice',
  async (data: DevicePaginationRequestBody) => {
    data = {page: 1, limit: 1000, ...data}

    const response: DeviceListResponse = await request({
      url: `v2/device/paginated`,
      method: 'post',
      data,
    })
    return response
  }
)

/** HISTORY OF A SINGLE DEVICE **/
export const _getDeviceWithHistory = createAsyncThunk<DeviceResponse, {id: string}>(
  '_getDeviceWithHistory',
  async ({id}) => {
    const response = await request({
      url: `v2/device/${id}`,
      method: 'get',
    })
    return response
  }
)

/** INMATE'S DEVICE HISTORY **/
export const _getInmateDeviceHistory = createAsyncThunk<DeviceHistoryResponse, number>(
  '_getInmateDeviceHistory',
  async (id) => {
    const response = await request({
      url: `v2/device-history`,
      method: 'get',
      params: {
        participantId: id,
        limit: 1000,
      },
    })
    return response
  }
)

/** ASSIGN / UN-ASSIGN A DEVICE **/
export const _updateDevice = createAsyncThunk(
  'devices/update',
  async ({data}: {data: DeviceUpdateParams}) => {
    const response: DeviceUpdate = await request({
      url: `v2/device/${data.id}`,
      method: 'put',
      data,
    })
    return response
  }
)

export const deleteDevice = async (reason: string, id: number) => {
  const response: DeviceResponse = await request({
    url: `v2/device/${id}`,
    method: 'delete',
    data: {
      id,
      reason,
    },
  })
  return response
}

export const createDevice = async (facilityId: number, imei: string) => {
  const response: DeviceCreateResponse = await request({
    url: `v2/device`,
    method: 'post',
    data: {
      facilityId,
      imei,
    },
  })
  return response
}
