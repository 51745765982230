import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {
  resetPasswordRequest,
  resetPassword,
  resetPasswordVerify,
} from '../../../../state/V2/user/user.actions'

export function ForgotPassword() {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [email, setEmail] = useState<string>()
  const [token, setToken] = useState<string>()
  const navigate = useNavigate()

  return (
    <>
      {currentStep === 1 && (
        <PasswordResetRequestForm
          onComplete={(email) => {
            setCurrentStep(2)
            setEmail(email)
          }}
        />
      )}
      {currentStep === 2 && !!email && (
        <PasswordResetCodeVerificationForm
          email={email}
          onComplete={(token: string) => {
            setToken(token)
            setCurrentStep(3)
          }}
        />
      )}
      {currentStep === 3 && token && (
        <PasswordResetForm token={token} onComplete={() => navigate('/auth/login')} />
      )}
    </>
  )
}

/**
 * Form to enter in a user email and trigger the password reset email
 */
const PasswordResetRequestForm = ({onComplete}: {onComplete: (email: string) => void}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const formik = useFormik({
    initialValues: {email: ''},
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Wrong email format')
        .strict()
        .lowercase()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true)
      setHasErrors(false)
      const response = await resetPasswordRequest(values.email)
      setIsLoading(false)
      if (response.status === 200) {
        onComplete(values.email)
        return
      }
      setHasErrors(true)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <FormHeader
        title='Password Reset'
        subtitle="Enter your email address, which you use to login. We'll send the password reset code."
        hasErrors={hasErrors}
        status={formik.status}
      />
      <div className='fv-row mb-5'>
        <input
          type='email'
          placeholder='Email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx('form-control form-control-solid', {
            'is-invalid': formik.touched.email && formik.errors.email,
          })}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <FormFooter isLoading={isLoading} allowSubmit={formik.values.email !== ''} />
    </form>
  )
}

/**
 * Form to enter in the password Reset Code sent to the users email and verify it is valid
 */
const PasswordResetCodeVerificationForm = ({
  email,
  onComplete,
}: {
  email: string
  onComplete: (token: string) => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const formik = useFormik({
    initialValues: {resetCode: ''},
    validationSchema: Yup.object().shape({
      resetCode: Yup.string()
        .min(6, 'Minimum 6 digits')
        .max(6, 'Maximum 6 digits')
        .required('Reset Code is required'),
    }),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true)
      setHasErrors(false)

      const response = await resetPasswordVerify(email, values.resetCode)
      setIsLoading(false)
      if (response.status === 200) {
        setStatus('')
        setHasErrors(false)
        setIsLoading(false)
        onComplete(response.data.token)
        return
      }
      setHasErrors(true)
      setStatus('Code does not match records.')
      setIsLoading(false)
      setSubmitting(false)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <FormHeader
        title='Password Reset Verification'
        subtitle='Use the code you received via email from us.'
        hasErrors={hasErrors}
        status={formik.status}
      />
      <div className='fv-row mb-5'>
        <input
          type='text'
          placeholder='Reset Code'
          autoComplete='off'
          {...formik.getFieldProps('resetCode')}
          className={clsx('form-control form-control-solid', {
            'is-invalid': formik.touched.resetCode && formik.errors.resetCode,
          })}
        />
        {formik.touched.resetCode && formik.errors.resetCode && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.resetCode}</span>
            </div>
          </div>
        )}
      </div>
      <FormFooter isLoading={isLoading} allowSubmit={formik.values.resetCode !== ''} />
    </form>
  )
}

/**
 * Form to enter in a new password and set it as the users password
 */
const PasswordResetForm = ({token, onComplete}: {token: string; onComplete: () => void}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const formik = useFormik({
    initialValues: {password: '', confirmPassword: ''},
    validationSchema: Yup.object().shape({
      password: Yup.string().min(6, 'Minimum 6 characters').required('Password is required'),
      confirmPassword: Yup.string()
        .min(6, 'Minimum 6 characters')
        .required('ConfirmPassword is required'),
    }),
    onSubmit: async (values, {setSubmitting, setFieldError, setStatus}) => {
      setIsLoading(true)
      setHasErrors(false)

      //Check if passwords match
      if (values.password !== values.confirmPassword) {
        setFieldError('confirmPassword', "Password doesn't match")
        setIsLoading(false)
        setHasErrors(true)
        return
      }

      const response = await resetPassword(values.password, token)
      if (response.status === 200) {
        setStatus('')
        setHasErrors(false)
        setIsLoading(false)
        toast.success('Your password has been reset successfully')
        onComplete()
        return
      }

      setHasErrors(true)
      setIsLoading(false)
      setSubmitting(false)
    },
  })
  const allowSumbit =
    formik.values.confirmPassword !== '' &&
    formik.values.password !== '' &&
    formik.values.confirmPassword === formik.values.password

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <FormHeader
        title='Password Reset Verification'
        subtitle="Enter your email address, which you use to login. We'll send the password reset code."
        hasErrors={hasErrors}
        status={formik.status}
      />
      <div className='fv-row mb-5'>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx('form-control form-control-solid', {
            'is-invalid': formik.touched.password && formik.errors.password,
          })}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-5'>
        <input
          type='password'
          placeholder='Confirm Password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx('form-control form-control-solid', {
            'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
          })}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>{' '}
      <FormFooter isLoading={isLoading} allowSubmit={allowSumbit} />
    </form>
  )
}

/**
 * Header for the password reset forms
 */
const FormHeader = ({
  title,
  subtitle,
  hasErrors,
  status,
}: {
  title: string
  subtitle: string
  hasErrors: Boolean
  status: string
}) => {
  return (
    <>
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3'>{title}</h1>
        <div className='fs-7 text-white'>{subtitle}</div>
      </div>
      {hasErrors === true && (
        <div className='mb-lg-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {status ? status : 'Sorry, looks like there are some errors detected, please try again'}
          </div>
        </div>
      )}
      {hasErrors === false && status && (
        <div className='bg-light-info mb-10 rounded p-8'>
          <div className='text-info'>{status}</div>
        </div>
      )}
    </>
  )
}

/**
 * Footer for the password reset forms
 */
const FormFooter = ({isLoading, allowSubmit}: {isLoading: Boolean; allowSubmit: Boolean}) => {
  return (
    <div className='flex flex-wrap justify-center'>
      <button
        type='submit'
        id='kt_password_reset_submit'
        className='btn btn-lg btn-orange btn-height d-flex align-items-center justify-content-center w-100 mb-10'
        disabled={!allowSubmit}
      >
        {!isLoading && <span className='indicator-label'>Submit</span>}
        {isLoading && (
          <span className='indicator-progress d-block'>
            Please wait...
            <span className='spinner-border spinner-border-sm ms-2 align-middle'></span>
          </span>
        )}
      </button>
      <Link to='/auth/login' className='text-orange fs-6'>
        Cancel
      </Link>
    </div>
  )
}
