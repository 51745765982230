import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import moment from 'moment'
import React, {useState, FC, useEffect, useMemo} from 'react'
import {Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasTitle} from 'react-bootstrap'
import TextField from '@mui/material/TextField'
import {useDispatch} from 'react-redux'
import {AppDispatch, RootState} from '../../../state/store'
import {getType} from '../../../helpers/general'
import {ALERTS_ACTION_OPTIONS} from '../../../helpers/config'
import {Alert, AlertNoteHistory} from '../../../state/V2/alerts/alerts.types'
import {_getAlertById, _updateAlerts} from '../../../state/V2/alerts/alerts.actions'
import {createColumnHelper} from '@tanstack/react-table'
import DataTable from '../../components/datatable/DataTable'
import {useAuth} from '../../modules/auth'
import {useSelector} from 'react-redux'

interface IProps {
  alert: Alert | null
  visible: boolean
  onClose: () => void
}

const columnHelper = createColumnHelper<AlertNoteHistory>()

const AlertDetails: FC<IProps> = ({alert, visible, onClose}) => {
  const [accepted, setAccepted] = useState<boolean>(alert?.accepted ? alert?.accepted : false)
  const [note, setNote] = useState<string>(alert?.note ? alert?.note : '')
  const [additionalNotes, setAdditionalNotes] = useState('')
  const [data, setdata] = useState<any>([])
  const [riskOption, setRiskOption] = React.useState<string[]>([])
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()
  const writeAccess = useAuth().hasPermission('alert.update')
  const selectedAlert = useSelector((state: RootState) => state.alerts.selected.data)

  const handleAccept = async () => {
    const action = riskOption.join(', ')
    accepted || alert?.accepted
      ? await dispatch(
          _updateAlerts({
            id: alert?.id,
            action: action,
            note: note,
            notedAt: moment().format(),
          })
        )
      : await dispatch(
          _updateAlerts({id: alert?.id, accepted: true, acceptedAt: moment().format()})
        )

    accepted || alert?.accepted ? handleClose() : setAccepted(true)
    setIsFormValid(false)
  }

  const handleAdditionalNotes = async () => {
    await dispatch(
      _updateAlerts({
        id: alert?.id,
        note: additionalNotes,
        notedAt: moment().format(),
      })
    )
    handleClose()
    setIsFormValid(false)
  }

  const handleClose = () => {
    onClose()
    setAccepted(false)
    setNote('')
    setRiskOption([])
  }

  useEffect(() => {
    const final = () => {
      if (alert && alert?.type?.toLowerCase() === 'high_heart_rate') {
        setdata(ALERTS_ACTION_OPTIONS.highHeartRate)
      } else if (
        (alert && alert?.type?.toLowerCase() === 'low_heart_rate') ||
        (alert && alert?.type?.toLowerCase() === 'low heart rate')
      ) {
        setdata(ALERTS_ACTION_OPTIONS.lowHeartRate)
      } else if (alert && alert?.type?.toLowerCase() === 'band_tamper') {
        setdata(ALERTS_ACTION_OPTIONS.bandTamper)
      } else if (
        (alert && alert?.type?.toLowerCase() === 'low_battery_level') ||
        (alert && alert?.type?.toLowerCase() === 'low battery')
      ) {
        setdata(ALERTS_ACTION_OPTIONS.lowBatery)
      } else if (alert && alert?.type?.toLowerCase() === 'heart_rate_offline') {
        setdata(ALERTS_ACTION_OPTIONS.offline)
      } else if (alert && alert?.type?.toLowerCase() === 'band_offline') {
        setdata(ALERTS_ACTION_OPTIONS.offline)
      } else if (alert && alert?.type?.toLowerCase() === 'out_of_cell_time_compliancy_warning') {
        setdata(ALERTS_ACTION_OPTIONS.outOfCellTime)
      } else return []
    }
    final()
  }, [alert])

  useEffect(() => {
    if (alert?.id) {
      dispatch(_getAlertById(alert.id))
    }
  }, [alert?.id, dispatch])

  const handleChange = (event: SelectChangeEvent<typeof riskOption>) => {
    const {
      target: {value},
    } = event

    setRiskOption(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor('note', {
        header: () => 'Note',
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor(
        (data) => data?.createdByUser?.firstName + ' ' + data?.createdByUser?.lastName,
        {
          id: 'createdByUser',
          header: () => 'Updated By',
          cell: (info) => info.getValue(),
        }
      ),
      columnHelper.accessor((data) => moment(data.createdAt).format('lll'), {
        id: 'createdAt',
        header: () => 'Time',
        cell: (info) => info.getValue(),
      }),
    ]
    return columns
  }, [])

  if (!alert?.id || alert.id !== selectedAlert?.id) return null

  return (
    <Offcanvas
      show={visible && alert !== null}
      onHide={handleClose}
      placement={'end'}
      style={{width: '45vw'}}
    >
      <OffcanvasHeader>
        <OffcanvasTitle>Alert Details</OffcanvasTitle>
      </OffcanvasHeader>
      <OffcanvasBody style={{textTransform: 'capitalize'}}>
        {alert !== null && (
          <>
            <div>
              <p className='font-14 text-gray-800'>Alert: {alert.id}</p>
            </div>
            <div>
              <p className='font-14 text-gray-800'>Type: {getType(alert.type)}</p>
            </div>
            <div>
              <p className='font-14 text-gray-800'>
                Inmate: {alert?.participant?.firstName} {alert?.participant?.lastName}
              </p>
            </div>
            <div>
              <p className='font-14 text-gray-800'>Time: {moment(alert.createdAt).format('lll')}</p>
            </div>
            {alert.heartrate ? (
              <div>
                <p className='font-14 text-gray-800'>Heart Rate: {alert.heartrate} BPM</p>
              </div>
            ) : (
              ''
            )}
            {alert.batteryLevel && (
              <div>
                <p className='font-14 text-gray-800'>Battery Level: {alert.batteryLevel} %</p>
              </div>
            )}
            {(alert.accepted || accepted) && (
              <>
                <hr className='horizontallines'></hr>
                {alert.accepted && (
                  <div className='font-14 text-gray-800'>
                    Accepted by: {alert.acceptedBy?.firstName} {alert.acceptedBy?.lastName} at{' '}
                    {moment(alert.acceptedAt).format('lll')}
                  </div>
                )}
                {alert.note ? (
                  <div>
                    <div>
                      <p style={{marginTop: 10}} className='font-14 text-gray-800'>
                        Action: {alert.action}
                      </p>
                    </div>
                    <div className='font-14 text-gray-800' style={{marginTop: 10}}>
                      Action Taken By: {alert.workedBy?.firstName} {alert.workedBy?.lastName} at{' '}
                      {moment(alert.notedAt).format('lll')}
                    </div>

                    <hr className='horizontallines'></hr>

                    <div className='font-14 text-gray-800' style={{fontWeight: 'bold'}}>
                      Notes History
                    </div>
                    <div className='card-body' style={{height: '60vh'}}>
                      <DataTable<AlertNoteHistory>
                        countLabel='Alerts'
                        options={{
                          data: selectedAlert.notes,
                          columns,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  writeAccess && (
                    <>
                      <FormControl sx={{m: 1}} fullWidth>
                        <InputLabel id='demo-multiple-checkbox-label'>Actions</InputLabel>
                        <Select
                          required
                          labelId='demo-multiple-checkbox-label'
                          id='demo-multiple-checkbox'
                          value={riskOption}
                          onChange={handleChange}
                          input={<OutlinedInput label='Actions' />}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          <MenuItem value='' disabled>
                            Choose Action
                          </MenuItem>
                          {data.map((name: any) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={riskOption.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                        {riskOption.length === 0 && (
                          <FormHelperText>You must select at least one action</FormHelperText>
                        )}
                      </FormControl>

                      <TextField
                        required
                        sx={{m: 1}}
                        fullWidth
                        id='outlined-basic'
                        label='Notes'
                        variant='outlined'
                        onChange={(e) => {
                          setNote(e.target.value)
                          setIsFormValid(e.target.value !== '' && riskOption.length > 0)
                        }}
                      />
                    </>
                  )
                )}
              </>
            )}

            {(alert.accepted || accepted) && alert.note && writeAccess && (
              <>
                <TextField
                  required
                  sx={{m: 1}}
                  fullWidth
                  id='outlined-basic'
                  label='Additional Notes'
                  variant='outlined'
                  onChange={(e) => {
                    setAdditionalNotes(e.target.value)
                    setIsFormValid(e.target.value !== '')
                  }}
                />
              </>
            )}

            {writeAccess && (
              <div style={{marginTop: 20}}>
                <Button style={{marginRight: 20}} onClick={handleClose}>
                  Cancel
                </Button>
                {alert.note ? (
                  <>
                    <Button
                      disabled={!isFormValid}
                      variant='contained'
                      style={{alignSelf: 'right'}}
                      onClick={handleAdditionalNotes}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <Button
                    disabled={(accepted || alert.accepted) && !isFormValid}
                    variant='contained'
                    style={{alignSelf: 'right'}}
                    onClick={handleAccept}
                  >
                    {accepted || alert.accepted ? 'Save' : 'Accept'}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default AlertDetails
