/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {getSocket} from '../../../../helpers/socket'
import {useIntl} from 'react-intl'
import {playAudio} from '../../../components/AudioPlayer'
import {Alert, AlertTypes} from '../../../../state/V2/alerts/alerts.types'

const Toolbar1 = () => {
  const {classes} = useLayout()
  return (
    <>
      <div className='toolbar' id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />
          <AlertBar />
        </div>
        <div id='toolbar-right'></div>
      </div>
    </>
  )
}

type AlertQueueItem = {
  id: number
  message: string
  color: string
  audio: string
}

const AlertBar = () => {
  const [alertQueue, setAlertQueue] = useState<AlertQueueItem[]>([])
  const [alert, setAlert] = useState<AlertQueueItem>()
  const socket = getSocket()
  const intl = useIntl()

  useEffect(() => {
    const newAlert = alertQueue[alertQueue.length - 1]
    if (alert?.id !== newAlert?.id) setAlert(newAlert)
    if (!newAlert) return

    //play alert audio
    if (newAlert.audio) playAudio(newAlert.audio)

    //remove alert from queue when time up
    const removeAlert = () => {
      setAlertQueue((prev) => prev.filter(({id}) => id !== newAlert.id))
    }
    const timeout = setTimeout(removeAlert, 6000)

    return () => {
      clearTimeout(timeout)
    }
  }, [alertQueue, alert?.id])

  useEffect(() => {
    socket.on('notify', ({data: alert}: {data: Alert}) => {
      const alertData = {
        id: alert.id,
        message: `${intl.formatMessage({
          id: `ALERT.${alert.type}`,
        })}: `,
        color: '#1d1d1dff',
        audio: '/media/audio/itw-heartrate-alert-audio.aac',
      }

      switch (alert.type) {
        case AlertTypes.HIGH_HEART_RATE:
        case AlertTypes.LOW_HEART_RATE:
          alertData.color = '#2e2e6bff'
          alertData.message += `${alert.heartrate} BPM - ${alert.participant.firstName} ${alert.participant.lastName}`
          break

        case AlertTypes.HEART_RATE_OFFLINE:
          alertData.color = '#2e2e6bff'
          alertData.message += ` ${alert.participant.firstName} ${alert.participant.lastName}`
          break

        case AlertTypes.LOW_BATTERY_LEVEL:
          alertData.color = '#186358ff'
          alertData.message += `${alert.batteryLevel}% - ${alert.participant.firstName} ${alert.participant.lastName}`
          break

        case AlertTypes.BAND_OFFLINE:
          alertData.color = '#6d2028ff'
          alertData.message += ` ${alert.participant.firstName} ${alert.participant.lastName}`
          break

        case AlertTypes.OUT_OF_CELL_TIME_COMPLIANCY_WARNING:
          alertData.color = '#74467fff'
          alertData.message += ` ${alert.participant.firstName} ${alert.participant.lastName}`
          break

        // Disabled Tamper alert for now as it is triggered too often currently.
        // case AlertTypes.TAMPER:
        //   alertData.color = '#93532cff'
        //   alertData.message += ` ${alert.participant.firstName} ${alert.participant.lastName}`
        //   break
        default:
          return //Return out and do nothing if it is not an alert we are handling
      }
      setAlertQueue((prev) => {
        prev.push(alertData)
        return [...prev]
      })
    })
  }, [intl, socket])

  if (!alert) return null

  return (
    <div
      key={alert.id}
      className='relative ml-2 flex w-full animate-alert-fade items-center justify-center overflow-hidden rounded-md p-1'
    >
      <div
        className='absolute bottom-0 left-0 right-0 top-0 -z-10 animate-alert-pulse'
        style={{backgroundColor: alert.color}}
      ></div>
      <span className='text-xl font-extrabold'>{alert.message}</span>
    </div>
  )
}

export {Toolbar1}
