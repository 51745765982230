/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {_getPods} from '../../../../state/V2/facility/facility.actions'
import {PodSelection} from '../../../layout/components/PodSelection'
import {_getFacilities} from '../../../../state/V2/facility/facility.actions'
import {AppDispatch, RootState} from '../../../../state/store'
import {useSelector} from 'react-redux'
import {_logout} from '../../../../state/V2/user/user.actions'
import {_getUserPods} from '../../../../state/V2/supervisor/supervisor.actions'
import {NavLink} from 'react-router-dom'

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpen] = useState<boolean>(false)
  const {currentUser, logout} = useAuth()
  const {id: facilityId} = useSelector((state: RootState) => state.facility.facility)

  useEffect(() => {
    dispatch(_getUserPods())
    dispatch(_getFacilities())
    dispatch(_getPods({facilityId}))
  }, [dispatch, facilityId])

  const _clearState = () => {
    dispatch(_logout())
    logout()
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.firstName} {currentUser?.lastName}
            </div>
            <a
              href={`mailto:${currentUser?.email}`}
              className='fw-bold text-muted text-hover-primary fs-7'
            >
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <NavLink className='menu-link' to={`/user-profile/${currentUser?.id}`}>
          Profile
        </NavLink>
      </div>
      <div className='menu-item px-5'>
        <a
          onClick={() => {
            setOpen(true)
          }}
          className='menu-link'
        >
          Pod Selection
        </a>
      </div>

      <PodSelection isShown={open} setIsShown={setOpen} />

      <div className='menu-item px-5'>
        <a onClick={_clearState} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
