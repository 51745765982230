import {ActionReducerMapBuilder, AsyncThunk, PayloadAction, SerializedError} from '@reduxjs/toolkit'
import {ApiRequestState, ApiStatus} from './api.types'
import {DataTableState} from '../../app/components/datatable/DataTable.types'
import {ColumnFilter} from '@tanstack/table-core'
import {PaginationRequestBodyType} from './types'

/**
 * Sets the API state given based on the action received.
 */
export const setApiState = <T>(
  subState: ApiRequestState<T>,
  action: PayloadAction<any, string, {requestStatus: ApiStatus; status?: number; data?: T}>
) => {
  //If the response is an error set the error
  if (isApiError(action)) {
    subState.error = action.error
    return
  }

  //otherwise set the status and pull data if fulfilled
  subState.status = action.meta.requestStatus
  switch (action.meta.requestStatus) {
    case 'fulfilled':
      subState.error = null
      subState.data = action.payload.data
      return
    case 'pending':
      subState.error = null
      return
    case 'rejected':
    default:
  }
}

/**
 * Checkis if the api action response is an error
 */
export const isApiError = (action: any): action is {error: SerializedError} => {
  return !!action.error
}

/**
 * adds the pending, fulfilled and rejected cases for the api data in a standard way. received data is put into the state object under the targetState location.
 */
export const addAPICases = <State, RequestBody>(
  builder: ActionReducerMapBuilder<State>,
  asyncThunk: AsyncThunk<any, RequestBody, any>,
  targetState: string
) => {
  builder
    .addCase(asyncThunk.pending, (state, action) =>
      setApiState((state as any)[targetState], action)
    )
    .addCase(asyncThunk.fulfilled, (state, action) =>
      setApiState((state as any)[targetState], action)
    )
    .addCase(asyncThunk.rejected, (state, action) =>
      setApiState((state as any)[targetState], action)
    )
}

/**
 * converts dataTableState into Pagination request body for api pagination.
 */
export const dataTableStateToPaginationRequest = <T>(
  dataTableState: DataTableState,
  filterTypes?: {[key: string]: 'number' | 'string' | 'boolean' | 'date'}
) => {
  const {pagination, sorting, columnFilters} = dataTableState
  const sortingOptions = sorting[0]
  const data: PaginationRequestBodyType = {}

  //convert sorting data to api request sortBy and sortDir
  if (sortingOptions) {
    data.sortBy = sortingOptions.id
    data.sortDir = sortingOptions.desc ? 'desc' : 'asc'
  }

  // convert columnFilters data to api request filter object array
  if (columnFilters.length > 0) {
    data.filters = columnFilters.map((filter: ColumnFilter) => {
      const filterType = filterTypes?.[filter.id] ? filterTypes[filter.id] : 'string'
      const valueString = filter.value as string
      let value: string | number | boolean | Date
      let operation: 'eq' | 'contains' = 'eq'

      //use fitlerType to set the correct comparison and convert the value to the correct type
      switch (filterType) {
        case 'number':
          value = parseInt(valueString)
          break
        case 'boolean':
          value = filter.value as boolean
          break
        case 'date':
          value = new Date(valueString)
          break
        case 'string':
        default:
          operation = 'contains'
          value = valueString
      }

      return {
        field: filter.id,
        value,
        operation,
      }
    })
  }

  // convert the pagination data to api request pagination values
  if (pagination) {
    data.page = pagination.pageIndex + 1
    data.limit = pagination.pageSize
  }

  return data as T
}
