import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../../state/store'
import {_getUnreadCount} from '../../../../state/V2/alerts/alerts.actions'
import {AccessRequirement, useAuth} from '../../../modules/auth'
import {useFeatureFlags} from '../../../../helpers/growthbook.utils'

export type MenuItem = {
  to: string
  title: string
  icon?: any
  fontIcon?: string
  hasBullet?: boolean
  permissionsAllowed: AccessRequirement[]
  featuresRequired?: string[]
}

const availableMenus: MenuItem[] = [
  {
    to: '/dashboard',
    title: 'MENU.DASHBOARD',
    icon: '/media/icons/talitrix/dashboard.svg',
    fontIcon: 'bi-app-indicator',
    permissionsAllowed: ['dashboard.read'],
  },
  {
    to: '/inmates',
    title: 'MENU.INMATES',
    icon: '/media/icons/talitrix/offender.svg',
    fontIcon: 'bi-app-indicator',
    permissionsAllowed: [
      'participant.read',
      'participant.create',
      'participant.update',
      'participant.delete',
    ],
  },
  {
    to: '/devices',
    title: 'MENU.DEVICES',
    icon: '/media/icons/talitrix/device.svg',
    fontIcon: 'bi-app-indicator',
    permissionsAllowed: ['device.read', 'device.create', 'device.update', 'device.delete'],
  },
  {
    to: '/alerts',
    title: 'MENU.ALERTS',
    icon: '/media/icons/talitrix/alerts.svg',
    fontIcon: 'bi-app-indicator',
    permissionsAllowed: ['alert.read', 'alert.update'],
  },
  {
    to: '/supervisors',
    title: 'MENU.SUPERVISORS',
    icon: '/media/icons/talitrix/officer.svg',
    fontIcon: 'bi-app-indicator',
    permissionsAllowed: ['user.read', 'user.create', 'user.update', 'user.delete'],
  },
  {
    to: '/facility',
    title: 'MENU.FACILITY.MANAGEMENT',
    icon: '/media/icons/talitrix/facility.svg',
    fontIcon: 'bi-app-indicator',
    permissionsAllowed: [
      'pod.read',
      'pod.create',
      'pod.update',
      'pod.delete',
      'cell.read',
      'cell.create',
      'cell.update',
      'cell.delete',
    ],
  },
  {
    to: '/live-track',
    title: 'MENU.LIVE.TRACK',
    icon: '/media/icons/talitrix/live_2d.svg',
    fontIcon: 'bi-app-indicator',
    permissionsAllowed: ['live-track.read'],
    featuresRequired: ['live-track'],
  },
  {
    to: '/facility-replay',
    title: 'MENU.FACILITY.REPLAY',
    icon: '/media/icons/talitrix/replay_3d.svg',
    fontIcon: 'bi-app-indicator',
    permissionsAllowed: ['facility-replay.read'],
    featuresRequired: ['facility-replay'],
  },
]

const AsideMenuMain: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch<AppDispatch>()
  const socketCount = useSelector((state: RootState) => state.alerts.unreadSocket)
  const [asideMenus, setAsideMenus] = useState<MenuItem[]>([])
  const podAssignment = useSelector((state: RootState) => state.supervisor.podAssignment)
  const {hasPermission} = useAuth()
  const {hasFeature} = useFeatureFlags()

  useEffect(() => {
    dispatch(_getUnreadCount())
  }, [dispatch, podAssignment])

  useEffect(() => {
    const allowedMenus = availableMenus.filter(
      ({permissionsAllowed, featuresRequired}) =>
        hasPermission(...permissionsAllowed) &&
        (featuresRequired ?? []).every((feature) => hasFeature(feature))
    )
    setAsideMenus(allowedMenus)
  }, [hasPermission, hasFeature])

  return (
    <>
      {asideMenus.map((value) => (
        <AsideMenuItem
          to={value.to}
          icon={value.icon}
          title={intl.formatMessage({id: value.title})}
          fontIcon={value.fontIcon}
          count={socketCount}
          key={value.title}
        />
      ))}
    </>
  )
}

export {AsideMenuMain}
