import {PayloadAction, createSlice, current} from '@reduxjs/toolkit'
import {PaginationType} from '../types'
import {
  _getParticipantById,
  _getParticipants,
  _createParticipant,
  _editParticipant,
  _deleteParticipant,
  _createParticipantLocation,
  _getActualCellDurations,
  _getAllocatedCellOutDuration,
  _getHeartRate,
  _getCellDoorHistory,
} from './participant.actions'
import {
  AllocatedCellTimeDurationType,
  CellTimeDurationsType,
  HeartRateType,
  LocationTimeType,
  ParticipantType,
} from './participant.types'

export interface ParticipantState {
  isLoading: boolean
  isLoadingTracker: boolean
  isLoadingAdd: boolean
  isLoadingEdit: boolean
  isLoadingDelete: boolean
  isLoadingHeart: boolean
  isLoadingDuraton: boolean
  isLoadingAllocation: boolean
  isLoadingLocationTimes: boolean
  error?: string
  pagination?: PaginationType
  list: ParticipantType[]
  selected: ParticipantType | null
  heartrateList: HeartRateType[]
  cellTimeDurations?: CellTimeDurationsType
  allocatedCellTime?: AllocatedCellTimeDurationType
  locationTimes: LocationTimeType[]
  startTime: string
  endTime: string
}

const initialParticipantState: ParticipantState = {
  isLoading: false,
  isLoadingAdd: false,
  isLoadingAllocation: false,
  isLoadingDelete: false,
  isLoadingDuraton: false,
  isLoadingEdit: false,
  isLoadingHeart: false,
  isLoadingTracker: false,
  isLoadingLocationTimes: false,
  list: [],
  selected: null,
  heartrateList: [],
  locationTimes: [],
  startTime: '',
  endTime: '',
}

const participant = createSlice({
  name: 'participant',
  initialState: initialParticipantState,
  reducers: {
    setStartTime: (state: ParticipantState, action: PayloadAction<string>) => {
      state.startTime = action.payload
    },
    setEndTime: (state: ParticipantState, action: PayloadAction<string>) => {
      state.endTime = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // Participant list
      .addCase(_getParticipants.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(_getParticipants.fulfilled, (state, action) => {
        state.isLoading = false
        state.list = action.payload.data.results
      })
      .addCase(_getParticipants.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message
      })

      //Selected Participant
      .addCase(_getParticipantById.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(_getParticipantById.fulfilled, (state, action) => {
        state.isLoading = false
        state.selected = action.payload.data
      })
      .addCase(_getParticipantById.rejected, (state, action) => {
        state.isLoading = false
        state.selected = null
        state.error = action.error.message
      })

      //Create Participant
      .addCase(_createParticipant.pending, (state, action) => {
        state.isLoadingAdd = true
      })
      .addCase(_createParticipant.fulfilled, (state, action) => {
        state.selected = action.payload.data
        state.isLoading = false
        state.list = [...current(state.list), action.payload.data]
      })
      .addCase(_createParticipant.rejected, (state, action) => {
        state.error = action.error.message
      })

      //Edit Participant
      .addCase(_editParticipant.pending, (state, action) => {
        state.isLoadingEdit = true
      })
      .addCase(_editParticipant.fulfilled, (state, action) => {
        state.isLoadingEdit = false
        state.selected = action.payload.data
        state.list = current(state.list).map((participant) => {
          if (participant.id === action.payload.data.id) return action.payload.data
          return participant
        })
      })
      .addCase(_editParticipant.rejected, (state, action) => {
        state.isLoadingEdit = false
        state.error = action.error.message
      })

      //Delete Participant
      .addCase(_deleteParticipant.pending, (state, action) => {
        state.isLoadingDelete = true
      })
      .addCase(_deleteParticipant.fulfilled, (state, action) => {
        state.isLoadingDelete = false
      })
      .addCase(_deleteParticipant.rejected, (state, action) => {
        state.isLoadingDelete = false
        state.error = action.error.message
      })

      //Creat Participant Location Time
      .addCase(_createParticipantLocation.fulfilled, (state, action) => {
        state.locationTimes = [...current(state.locationTimes), action.payload.data]
      })
      .addCase(_createParticipantLocation.rejected, (state, action) => {
        state.error = action.error.message
      })

      //Get Allocated Cell Out Duration
      .addCase(_getAllocatedCellOutDuration.pending, (state, action) => {
        state.isLoadingAllocation = true
      })
      .addCase(_getAllocatedCellOutDuration.fulfilled, (state, action) => {
        state.isLoadingAllocation = false
        state.allocatedCellTime = action.payload.data
      })
      .addCase(_getAllocatedCellOutDuration.rejected, (state, action) => {
        state.isLoadingAllocation = false
        state.error = action.error.message
      })

      // Get Actual Cell Duration
      .addCase(_getActualCellDurations.pending, (state, action) => {
        state.isLoadingDuraton = true
      })
      .addCase(_getActualCellDurations.fulfilled, (state, action) => {
        state.isLoadingDuraton = false
        state.cellTimeDurations = action.payload.data
      })
      .addCase(_getActualCellDurations.rejected, (state, action) => {
        state.isLoadingDuraton = false
        state.error = action.error.message
      })

      //Get heartrate History
      .addCase(_getHeartRate.pending, (state, action) => {
        state.isLoadingHeart = true
      })
      .addCase(_getHeartRate.fulfilled, (state, action) => {
        state.isLoadingHeart = false
        state.heartrateList = action.payload.data
      })
      .addCase(_getHeartRate.rejected, (state, action) => {
        state.isLoadingHeart = false
        state.error = action.error.message
      })

      //Get CellDoor history
      .addCase(_getCellDoorHistory.pending, (state) => {
        state.isLoadingLocationTimes = true
      })
      .addCase(_getCellDoorHistory.fulfilled, (state, action) => {
        state.isLoadingLocationTimes = false
        state.locationTimes = action.payload.data
      })
      .addCase(_getCellDoorHistory.rejected, (state, action) => {
        state.isLoadingLocationTimes = false
        state.error = action.error.message
      })
  },
})

export const participantActions = participant.actions
export const participantReducer = participant.reducer
