import {useSelector} from 'react-redux'
import {RootState} from '../../../../state/store'

export function MenuInner() {
  const {facilityName} = useSelector((state: RootState) => state.facility.facility)

  return (
    <>
      <section style={{padding: '10px'}}>
        <p style={{marginBottom: '0', fontSize: 24}}>{facilityName}</p>
      </section>
    </>
  )
}
