import {ApiResponse} from '../api.types'
import {PaginationRequestBodyType} from '../types'

export type AlertHistoryType = {
  id: number
  alertId: number
  note: string
  createdAt: string
  updatedAt: string
  createdBy: number | null
  updatedBy: number | null
  active: boolean
}

export type AlertPaginationRequestBody = PaginationRequestBodyType & {
  participantId?: number
  sortBy?:
    | 'id'
    | 'type'
    | 'participantName'
    | 'heartrate'
    | 'batteryLevel'
    | 'createdAt'
    | 'accepted'
    | 'action'
    | 'updatedAt'
    | 'acceptedByName'
    | 'acceptedAt'
    | 'workedByName'
    | 'note'
}

export type AlertNoteHistory = {
  id?: number
  createdByUser?: {
    firstName?: string
    lastName?: string
  }
  note?: string
  createdAt?: number
}

export type Alert = {
  id: number
  active: boolean
  batteryLevel: number | null
  heartrate: number | null
  accepted: boolean
  acceptedAt: string | null
  acceptedBy: {
    firstName: string
    lastName: string
  } | null
  action: string | null
  createdAt: string
  note: string | null
  noteId: number | null
  notedAt: string | null
  participant: {
    id: number
    firstName: string
    lastName: string
  }
  participantId: number
  type: AlertType
  updatedAt: string
  workedBy: {
    firstName: string
    lastName: string
  } | null

  alertHistory?: AlertNoteHistory[]
  alertTime?: string
  notes: AlertNoteHistory[]
}

export type AlertResponse = ApiResponse<{
  pagination: {
    page: number
    limit: number
    count: number
  }
  results: Array<Alert>
}>

export type CountResponse = {
  count: number
}

export const AlertTypes = {
  HEART_RATE: 'HEART_RATE',
  HIGH_HEART_RATE: 'HIGH_HEART_RATE',
  LOW_HEART_RATE: 'LOW_HEART_RATE',
  TAMPER: 'TAMPER',
  LOW_BATTERY_LEVEL: 'LOW_BATTERY_LEVEL',
  BAND_OFFLINE: 'BAND_OFFLINE',
  HEART_RATE_OFFLINE: 'HEART_RATE_OFFLINE',
  OUT_OF_CELL_TIME_COMPLIANCY_WARNING: 'OUT_OF_CELL_TIME_COMPLIANCY_WARNING',
}
export type AlertType = (typeof AlertTypes)[keyof typeof AlertTypes]

export const alertsFilterTypes: {[key: string]: 'string' | 'number' | 'boolean' | 'date'} = {
  id: 'number',
  heartrate: 'number',
  batteryLevel: 'number',
  time: 'date',
  accepted: 'boolean',
}

export const alertTypeDataTableOptions = [
  {label: 'High Heart Rate Alert', value: AlertTypes.HIGH_HEART_RATE},
  {label: 'Low Heart Rate Alert', value: AlertTypes.LOW_HEART_RATE},
  {label: 'Low Battery Level Alert', value: AlertTypes.LOW_BATTERY_LEVEL},
  {label: 'Band Offline Alert', value: AlertTypes.BAND_OFFLINE},
  {label: 'Band Tamper Alert', value: AlertTypes.TAMPER},
  {
    label: 'Out of Cell Time Alert',
    value: AlertTypes.OUT_OF_CELL_TIME_COMPLIANCY_WARNING,
  },
]