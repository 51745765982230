import React, {FC, useEffect, useState} from 'react'
import './inmateDetailscss.scss'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../../../state/store'
import {capitalizeFirstLetters} from '../../../../../helpers/strings'
import {_getLocationCount} from '../../../../../state/V2/facility/facility.actions'
import {useIntl} from 'react-intl'

interface IProps {
  setFilter: (filter: {location?: string; risk?: number}) => void
}

interface ResultItem {
  count: number
  location: string
}

const InmateDetails: FC<IProps> = ({setFilter}) => {
  const [totalInmates, setTotalInmates] = useState<number>(0)
  const [zones, setZones] = useState<(string | number)[][]>([])
  const intl = useIntl()

  const dispatch = useDispatch<AppDispatch>()

  const podAssignments = useSelector((state: RootState) => state.supervisor.podAssignment)
  const inmateLocationCounts = useSelector((state: RootState) => state.locations.counts)

  useEffect(() => {
    dispatch(_getLocationCount())
  }, [dispatch, podAssignments])

  useEffect(() => {
    setTotalInmates(inmateLocationCounts?.total || 0)
    if (!inmateLocationCounts?.locations) return

    const zonesArray = inmateLocationCounts.locations.map((item: ResultItem) => {
      return [item.location, item.count]
    })
    setZones(zonesArray)
  }, [inmateLocationCounts, podAssignments])

  return (
    <section className='row inmateDetails justify-content-around align-items-center my-4'>
      <div className='col-12 col-lg-2 total'>
        <h1 className='text-uppercase'>{intl.formatMessage({id: 'DASHBOARD.TOTAL_INMATES'})}</h1>
        <h2>{!totalInmates ? '0' : totalInmates}</h2>
      </div>
      <div className='col-12 col-lg-10'>
        <div className='d-flex justify-content-start align-items-start flex-row flex-wrap'>
          {zones?.map((zone: any, index: number) => {
            return (
              <div
                key={index}
                className='col align-content-stretch inmateDetails--pod m-2 rounded border border-white p-3 text-center'
                onClick={() => setFilter({location: zone[0].toUpperCase()})}
              >
                <p>{capitalizeFirstLetters(zone[0])}</p>
                <span id='inmateZone'>{zone[1]}</span>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default InmateDetails
