import {Column, Table} from '@tanstack/react-table'
import DebouncedInput from '../DebouncedInput'
import {useMemo} from 'react'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'

const ColumnFilter = ({column, table}: {column: Column<any, unknown>; table: Table<any>}) => {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column, firstValue]
  )

  return typeof firstValue === 'number' ? (
    <div className='relative flex w-full items-center'>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <div className='absolute left-2 flex h-full items-center'>
        <SearchIcon className=' text-table-lightgray' />
      </div>{' '}
      <DebouncedInput
        type='text'
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(value) => column.setFilterValue((old: [number, number]) => [value, value])}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className='w-full bg-transparent p-2 px-8 '
        list={column.id + 'list'}
      />
      {column.getFilterValue() !== undefined && column.getFilterValue() !== '' && (
        <div className='absolute right-2 flex h-full items-center'>
          <CancelIcon
            onClick={() => column.setFilterValue('')}
            className=' cursor-pointer text-table-lightgray'
          />
        </div>
      )}
    </div>
  ) : (
    <div className='relative flex w-full items-center'>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <div className='absolute left-2 flex h-full items-center'>
        <SearchIcon className=' text-table-lightgray' />
      </div>{' '}
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className='w-full bg-transparent p-2 px-8 '
        list={column.id + 'list'}
      />
      {column.getFilterValue() !== undefined && column.getFilterValue() !== '' && (
        <div className='absolute right-2 flex h-full items-center'>
          <CancelIcon
            onClick={() => column.setFilterValue('')}
            className=' cursor-pointer text-table-lightgray'
          />
        </div>
      )}
    </div>
  )
}
export default ColumnFilter
