import {GrowthBook, GrowthBookProvider, useGrowthBook} from '@growthbook/growthbook-react'
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {useAuth, UserClaims} from '../app/modules/auth'

enum FeatureFlagKey {
  PACKAGES = 'packages',
}

type FeaturePackage = {package: string; features: string[]}

type GrowthBookFeatureTypes = {
  [FeatureFlagKey.PACKAGES]: FeaturePackage | null
}

type GrowthBookClient = GrowthBook<GrowthBookFeatureTypes>

type FeatureFlagsContextType = {
  hasFeature: (feature: string) => boolean
}

const getGrowthBookClient = (
  attributes: Record<string, string | number | undefined>
): GrowthBookClient | undefined => {
  if (process.env.REACT_APP_GROWTHBOOK_ACTIVE === 'true') {
    return new GrowthBook<GrowthBookFeatureTypes>({
      apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST!,
      clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY!,
      subscribeToChanges: true,
      enableDevMode: false,
      attributes,
    })
  }
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  hasFeature: () => true,
})

export const useFeatureFlags = () => {
  return useContext(FeatureFlagsContext)
}

const getAttributesFromUser = (user?: UserClaims) => ({
  facilityId: user?.facility?.slug,
})

export const FeatureFlagsProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <GrowthBookProvider growthbook={getGrowthBookClient({})}>
      <FeatureFlagsContextProvider>{children}</FeatureFlagsContextProvider>
    </GrowthBookProvider>
  )
}

export const FeatureFlagsContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const growthbook = useGrowthBook<GrowthBookFeatureTypes>()

  const {currentUser} = useAuth()
  const [attributes, setAttributes] = useState<Record<string, string | number | undefined>>({})
  const [featurePackage, setFeaturePackage] = useState<FeaturePackage | null>(null)

  useEffect(() => {
    if (growthbook) {
      growthbook.setAttributes(attributes)
      growthbook.loadFeatures().then(() => {
        setFeaturePackage(growthbook.evalFeature(FeatureFlagKey.PACKAGES).value)
      })
    }
  }, [growthbook, attributes])

  useEffect(() => {
    setAttributes(getAttributesFromUser(currentUser))
  }, [currentUser])

  const hasFeature = useCallback(
    (feature: string): boolean => {
      return !growthbook || !!featurePackage?.features.includes(feature)
    },
    [growthbook, featurePackage]
  )

  return (
    <FeatureFlagsContext.Provider value={{hasFeature}}>{children}</FeatureFlagsContext.Provider>
  )
}
