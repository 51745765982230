import {createAsyncThunk} from '@reduxjs/toolkit'
import {request} from '../../../helpers/request'
import {
  LoginRequestBody,
  LoginRequestResponse,
  PasswordResetCheckResponse,
  PasswordResetResponse,
  UserRoleResponse,
} from './user.types'

export const _login = createAsyncThunk('user/_login', async (data: LoginRequestBody) => {
  const response: LoginRequestResponse = await request({url: 'v2/auth', method: 'post', data})
  return response
})

export const _logout = createAsyncThunk('user/_logout', async () => {
  return {}
})

export const _getRoles = createAsyncThunk('roles/_list', async () => {
  const response: UserRoleResponse = await request({url: 'v2/user-role', method: 'get'})
  return response
})

export const resetPasswordRequest = async (email: string) => {
  const response: PasswordResetResponse = await request({
    url: 'v2/auth/password-reset',
    method: 'post',
    data: {email},
  })
  return response
}

export const resetPasswordVerify = async (email: string, resetCode: string) => {
  const response: PasswordResetCheckResponse = await request({
    url: 'v2/auth/password-reset/check',
    method: 'post',
    data: {email, resetCode},
  })
  return response
}

export const resetPassword = async (password: string, token: string) => {
  const response: PasswordResetResponse = await request({
    url: 'v2/auth/password',
    method: 'put',
    data: {password},
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}
