import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'
import {LayoutSplashScreen} from '../../../layout/core'
import {AccessRequirement, AuthModel, UserClaims} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../helpers'
import {ThemeProvider, createTheme} from '@mui/material/styles'
import {PodSelection} from '../../../layout/components/PodSelection'

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#d87a16',
    },
    mode: 'dark',
  },
})

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserClaims | undefined
  setCurrentUser: Dispatch<SetStateAction<UserClaims | undefined>>
  logout: () => void
  hasPermission: (...allowedPermissions: AccessRequirement[]) => boolean
}

const _currentUser: UserClaims = JSON.parse(localStorage.getItem('currentUser')!)

const hasPermission =
  (user?: UserClaims) =>
  (...permissionsAllowed: string[]) =>
    user?.isSuper ||
    !permissionsAllowed.length ||
    !!user?.permissions.some((permission) => permissionsAllowed.includes(permission))

const initAuthContextPropsState: AuthContextProps = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: _currentUser || undefined,
  setCurrentUser: () => {},
  logout: () => {},
  hasPermission: hasPermission(_currentUser),
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

//TODO: move auth context over to redux user state for consistancy in state management
const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserClaims | undefined>(_currentUser)
  const [isShown, setIsShown] = useState(false)

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
      setIsShown(true)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = useCallback(() => {
    saveAuth(undefined)
    authHelper.removeAuth()
    setCurrentUser(undefined)
  }, [])

  return (
    <ThemeProvider theme={darkTheme}>
      <AuthContext.Provider
        value={{
          auth,
          saveAuth,
          currentUser,
          setCurrentUser,
          logout,
          hasPermission: hasPermission(currentUser),
        }}
      >
        {children}
        <PodSelection isShown={isShown} setIsShown={setIsShown} />
      </AuthContext.Provider>
    </ThemeProvider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(false)

  useEffect(() => {
    if (auth?.api_token) {
      const currentUser = localStorage.getItem('currentUser')
      if (currentUser !== null) {
        setCurrentUser(JSON.parse(currentUser))
      }
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [auth?.api_token, logout, setCurrentUser])

  return <>{showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>}</>
}

export {AuthProvider, AuthInit, useAuth}
